import { ApplicationState } from "../store/app";
import { createSelector } from "./common.selectors";

const getFeatureState = (state: ApplicationState) => state.feature;

/**
 * Get the list of feature flag items.
 */
export const getFeatureItems = createSelector(
    getFeatureState,
    feature => feature.response && feature.response.features || []
);

/**
 * Get whether or not the system features is currently being updated.
 */
export const getIsUpdatingFeatures = createSelector(
    getFeatureState,
    feature => feature.response && feature.response.isUpdating,
);

/**
 * Checks to determine if a specific feature is enabled in the system.
 * @param featureKey The unique key of the feature.
 */
export const getIsFeatureEnabled = (featureKey: string) => createSelector(
    getFeatureItems,
    features => {
        if (features.some(value => value.key === featureKey)) {
            return features.find(value => value.key === featureKey).isEnabled;
        }
        return false;
    }
);
