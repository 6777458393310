import { connectRouter } from "connected-react-router";
import { History } from "history";
import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers, ReducersMapObject } from "redux";

import { combineObservers } from "../actions/store.actions";
import * as Auth from "../reducers/auth.reducer";
import * as Account from "../reducers/account";
import * as Awards from "../reducers/awards.reducer";
import * as ContactUs from "../reducers/contactUs.reducer";
import * as Dialog from "../reducers/dialog.reducer";
import * as Feature from "../reducers/feature.reducer";
import * as Forum from "../reducers/forum.reducer";
import * as Confirmation from "../reducers/confirmation.reducer";
import * as Contractor from "../reducers/contractor";
import * as Crr from "../reducers/crr.reducer";
import * as Executive from "../reducers/executive.reducer";
import * as LimitsAndExclusions from "../reducers/limitsAndExclusions.reducer";
import * as Location from "../reducers/location.reducer";
import * as Order from "../reducers/order.reducer";
import * as Offers from "../reducers/offers.reducer";
import * as Reference from "../reducers/reference.reducer";
import * as Review from "../reducers/review.reducer";
import * as SalesRep from "../reducers/salesRep.reducer";
import * as ServiceRequest from "../reducers/serviceRequest.reducer";
import * as Store from "../reducers/store.reducer";
import * as ToastrHistory from "../reducers/toastrHistory.reducer";
import * as Wait from "../reducers/wait.reducer";

/**
 * The top-level application state.
 */
export interface ApplicationState {
    account: Account.AccountState;
    auth: Auth.AuthState;
    awards: Awards.AwardsState;
    confirmation: Confirmation.ConfirmationState;
    contactUs: ContactUs.ContactUsState;
    contractor: Contractor.ContractorState;
    crr: Crr.CrrState;
    dialog: Dialog.DialogState;
    executive: Executive.ExecutiveState;
    feature: Feature.FeatureState;
    forum: Forum.ForumState;
    limitsAndExclusions: LimitsAndExclusions.LimitsAndExclusionsState;
    location: Location.LocationState;
    order: Order.OrderState;
    offers: Offers.OffersState;
    reference: Reference.ReferenceState;
    review: Review.ReviewState;
    salesRep: SalesRep.SalesRepState;
    serviceRequest: ServiceRequest.ServiceRequestState;
    store: Store.StoreState;
    toastrHistory: ToastrHistory.ToastrHistoryState;
    wait: Wait.WaitState;
}

export function createRootReducer (history: History, additionalReducers?: ReducersMapObject) {
    return combineReducers({
        router: connectRouter(history),
        ...reducers,
        ...additionalReducers
    });
}

/**
 * The reducers for each area of application.
 *
 * Whenever an action is dispatched, Redux will update each top-level application state property using
 * the reducer with the matching name. It's important that the names match exactly, and that the reducer
 * acts on the corresponding ApplicationState property type.
 */
const reducers = {
    account: Account.AccountReducer,
    auth: Auth.AuthReducer,
    awards: Awards.AwardReducer,
    contactUs: ContactUs.ContactUsReducer,
    contractor: Contractor.ContractorReducer,
    confirmation: Confirmation.ConfirmationReducer,
    crr: Crr.CrrReducer,
    dialog: Dialog.DialogReducer,
    executive: Executive.ExecutiveReducer,
    feature: Feature.FeatureReducer,
    forum: Forum.ForumReducer,
    limitsAndExclusions: LimitsAndExclusions.LimitsAndExclusionsReducer,
    location: Location.LocationReducer,
    offers: Offers.OffersReducer,
    order: Order.OrderReducer,
    reference: Reference.ReferenceReducer,
    review: Review.ReviewReducer,
    salesRep: SalesRep.SalesRepReducer,
    serviceRequest: ServiceRequest.ServiceRequestReducer,
    store: Store.StoreReducer,
    toastr: toastrReducer,
    toastrHistory: ToastrHistory.ToastrHistoryReducer,
    wait: Wait.WaitReducer,
};

/**
 * Store observers, used to observe changes in the store and react appropriately.
 */
export const observe = combineObservers(
    Auth.observer,
    Order.observer,
    Reference.observer,
);
