import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "actions/defs";
import { ClosingItemActionTypes } from "actions/contractor/closingItem.actions";

/**
 * State of the closing item information.
 *
 * @export
 * @interface ClosingItemState
 */
export interface ClosingItemState {
    closingItemCategories: Landmark.ClosingItemCategory[];
}

/**
 * Default state. Categories will start out empty.
 */
const defaultState: ClosingItemState = {
    closingItemCategories: []
};

export const ClosingItemReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<ClosingItemState>
) => {
    switch (action.type) {
        case ClosingItemActionTypes.GetCategories.SUCCESS: {
            const { payload } = action as PayloadAction<Landmark.ClosingItemCategoryResponse>;
            return update(state,{
                closingItemCategories: { $set: payload.closingItemCategories}
            });
        }
    }
    return state;
};
