
import update from "immutability-helper";
import { Action } from "redux";

import { PayloadAction } from "../actions/defs";
import { LocationResponse, LocationActionTypes } from "../actions/location.actions";

export interface LocationState extends LocationResponse { }

const defaultState: LocationState = {
    google: null,
    position: null,
    stateCode: null,
};

export function LocationReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<void | LocationResponse>
) {
    switch (action.type) {
        case LocationActionTypes.Get.SUCCESS: {
            const response = (action as PayloadAction<LocationResponse>).payload;
            let stateCode = null;
            if (response.google) {
                const results = response.google;
                if (results && results.length) {
                    // Google gives the most-likely result first
                    const firstResult = results[0];
                    // Find the 'state' component of the address (which is always administrative_area_level_1 in the US)
                    const stateAddress = firstResult.address_components.find(address => address.types.indexOf("administrative_area_level_1") >= 0);
                    if (stateAddress) {
                        stateCode = stateAddress.short_name;
                    }
                }
            }

            return update(state, {
                // Set the response
                $set: update(response, {
                    // Add the state code to the response
                    stateCode: { $set: stateCode }
                })
            });
        }
    }

    // If nothing changed, return the original state
    return state;
}
