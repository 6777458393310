import * as React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { createDialogActions } from "../actions/dialog.actions";
import { OrderActionTypes } from "../actions/order.actions";
import { createWaitActions } from "../actions/wait.actions";
import { CallUs } from "../components/CallUs";
import { Areas } from "../constants/Areas";
import { LandmarkApiService } from "../services/landmarkApi.service";

const waitActions = createWaitActions();
const partnerDialogActions = createDialogActions(Areas.Order.PartnerSearch);

function* handleCreatePartner(action) {
    let waitBegin = waitActions.begin();
    yield put(waitBegin);

    try {
        const response = yield call(() => LandmarkApiService
            .post("partners")
            .body(action.payload)
            .fetch()
        );

        // Notify of success
        yield put({
            type: OrderActionTypes.CreatePartner.SUCCESS,
            payload: {
                data: response.json,
                partnerType: action.payload.role,
            }
        });

        toastr.success("Save Complete", "Agent information saved successfully.", { transitionIn: "fadeIn", transitionOut: "fadeOut" });

        // Close the dialog
        yield put(partnerDialogActions.close());
    }
    catch (error) {

        // Notify of failure
        yield put({
            type: OrderActionTypes.CreatePartner.FAILURE,
            error
        });

        toastr.error(
            "Save Failed",
            "",
            {
                transitionIn: "fadeIn",
                transitionOut: "fadeOut",
                component:
                <p>
                    Please call us for assistance at <CallUs />.
                </p>
            }
        );
    }
    finally {
        // Stop waiting
        yield put(waitActions.end(waitBegin.payload.id));
    }
}

function* watchCreatePartner() {
    yield takeEvery(OrderActionTypes.CreatePartner.BEGIN, handleCreatePartner);
}

export default function* root() {
    yield all([
        fork(watchCreatePartner),
    ]);
}
