import * as React from "react";

import { DropDown } from "./DropDown";

interface Props {
    disabled?: boolean;
    id?: string;
    name?: string;
    onChange?: React.FormEventHandler<HTMLSelectElement>;
    style?: React.CSSProperties;
    value: number;
}

const squareFootageOptions = [6, 7, 8, 9, 10, 11].map((i, index, arr) => ({
    name: `${i === 1 ? "0" : `${i - 1}001`}${index === arr.length - 1 ? " or more sq. ft." : ` - ${i}000 sq. ft.`}`,
    value: i * 1000
}));

export class SquareFootage extends React.Component<Props, any> {

    static defaultProps = {
        value: 1000,
    };

    // handleChange = (val: string) => {
        // TODO: this should be accomplished with an epic.

        // if (this.props.onChange) {
        //     const value = parseInt(val);
        //     if (value === parseInt("11000")) {
        //         toastr.error("Error", "Please contact Landmark at 866-306-2999 option #1, if your house is over 10,000 Sq. Ft. ", { transitionIn: "fadeIn", transitionOut: "fadeOut" });
        //     } else {
        //         this.props.onChange(value);
        //     }
        // }
    // };

    renderListItems = () => {
        const list = [];
        list.push(<option key={0} value={"1"}>1 - 5000 sq.ft.</option>);

        squareFootageOptions.map((option, index) =>
            list.push(<option key={index + 1} value={option.value.toString()}>{option.name}</option>)
        );
        return list;
    }

    render() {
        const { disabled, id, name, onChange, style, value } = this.props;
        return (
            <DropDown
                id={id}
                disabled={disabled}
                listItemsFormatted={this.renderListItems()}
                name={name}
                onChange={onChange}
                style={style}
                value={value ? value.toFixed(0) : null}
            />
        );
    }
}
