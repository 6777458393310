import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { appId, AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const ContactUsActionTypes = {
    Send: AsyncAction(`contactUs/send`),
    StreemSignup: AsyncAction(`contactUs/streemsignup`),
    CLEAR: `${appId}/contactUs/CLEAR`,
    SET_VALUES: `${appId}/contactUs/SET_VALUES`,
};

export function createContactUsActions(): ContactUsActionCreators {
    return {
        clear: () => ({ type: ContactUsActionTypes.CLEAR }),
        send: createAsyncActions(ContactUsActionTypes.Send),
        streemSignup: createAsyncActions(ContactUsActionTypes.StreemSignup),
        setValues: (values: any) => ({ type: ContactUsActionTypes.SET_VALUES, payload: values})
    };
}

export interface ContactUsActionCreators extends ActionCreatorMap {
    /**
     * Clears sent flag after showing info in the view.
     */
    clear: () => Action;

    /**
     * Performs basic registration.
     * @param {Landmark.ContactUs} request A contact us request.
     */
    send: AsyncActionCreators<Landmark.ContactUs, Landmark.EmailSent>;

    /**
     * Performs streem signup.
     * @param {Landmark.StreemSignupRequest} request A contact us request.
     */
     streemSignup: AsyncActionCreators<Landmark.StreemSignupRequest, Landmark.EmailSent>;
    /**
     * Sets values in state.
     */
    setValues: (values: any) => PayloadAction<any>;
}
