import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { ClosingItemActionTypes, createClosingItemActions } from "../../actions/contractor/closingItem.actions";
import { PayloadAction } from "../../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const closingItemActions = createClosingItemActions();

/**
 * Handles getting closing item categories from the API.
 *
 * @param action$ - The action observable watching actions being dispatched.
 */
const handleGetCategories: Epic<
    PayloadAction<string | undefined | Landmark.ClosingItemCategoryResponse | Error>
> = action$ => action$.pipe(
    ofType(ClosingItemActionTypes.GetCategories.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string | undefined>) => {
            const query = action.payload ?
                `?dispatchId=${action.payload}` :
                "";

            return LandmarkApiService
                .get(`/contractor/closingItems/categories${query}`)
                .withAuthentication()
                .fetch()
                .then(response => response.json);
        },
        closingItemActions.getCategories.success,
        closingItemActions.getCategories.failure
    ),
);

/**
 * Handles getting closing items from the API.
 *
 * @param action$ - The action observable watching actions being dispatched.
 */
const handleGetItems: Epic<
    PayloadAction<string | undefined | Landmark.ClosingItemResponse | Error>
> = action$ => action$.pipe(
    ofType(ClosingItemActionTypes.GetItems.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string | undefined>) =>
            {
                const query = action.payload ?
                `?closingItemCategoryId=${action.payload}` :
                "";

                return LandmarkApiService
                    .get(`/contractor/closingItems${query}`)
                    .withAuthentication()
                    .fetch()
                    .then(response => response.json);
            },
        closingItemActions.getItems.success,
        closingItemActions.getItems.failure
    ),
);

/**
 * Creates wait epic for the closing item, which may take time depending on how many are in the DB.
 */
const waitGetItems = createWaitEpic(ClosingItemActionTypes.GetItems);

const epic = combineEpics(
    handleGetItems,
    handleGetCategories,
    waitGetItems
);
export default epic;
