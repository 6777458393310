import * as Landmark from "models/landmark-api";

import { appId, PayloadAction, AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const BillingInfoActionTypes = {
    CheckForFailedPayment: AsyncAction("account/billingInfo/checkForFailedPayment"),
    Load: AsyncAction(`account/billingInfo/load`),
    MakePendingPayment: AsyncAction(`account/billingInfo/makePendingPayment`),
    ProcessPayment: AsyncAction(`account/billingInfo/processPayment`),
    ProcessFailedPayment: AsyncAction(`account/billingInfo/processFailedPayment`),
    SaveCC: AsyncAction(`account/billingInfo/cc/save`),
    SET_VALUES: `${appId}/account/billingInfo/SET_VALUES`,
};

export interface BillingInfoActionCreators extends ActionCreatorMap {
    /**
     * Checks to see if the user failed their last payment on their contract
     */
    checkFailedPayments: AsyncActionCreators<Landmark.FailedPaymentRequest, Landmark.FailedPaymentListResponse>;

    /**
     * Loads the user's billing information into state.
     */
    load: AsyncActionCreators<string, Landmark.BillingInfo>;

    /**
     * Creates a pending payment.
     */
    makePendingPayment: AsyncActionCreators<Landmark.CreatePendingServiceRequestPaymentRequest, Landmark.CreatePendingServiceRequestPaymentResponse>;

    /**
     * Processes 1 payment at the current payment amount if they missed their last payment.
     */
    processFailedPayment: AsyncActionCreators<Landmark.FailedPaymentRequest, Landmark.FailedPaymentListResponse>;

    /**
     * Processes a credit card payment.
     */
    processPayment: AsyncActionCreators<Landmark.MakeCreditCardPaymentRequest, Landmark.MakeCreditCardPaymentResponse>;

    /**
     * Sets one or more values on the user's billing information state.
     * @param {any} values The values to be set.
     */
    setValues: (values: any) => PayloadAction<any>;

    /**
     * Updates/Inserts credit card info into database and sets the selected card
     * @param {Landmark.UpdateCreditCardInfoRequest} request the request to update/create credit card info
     */
    saveCc: AsyncActionCreators<Landmark.UpdateCreditCardInfoRequest, Landmark.UpdateCreditCardInfoResponse>;
}

export function createBillingInfoActions() {
    return {
        checkFailedPayments: createAsyncActions(BillingInfoActionTypes.CheckForFailedPayment),
        load: createAsyncActions(BillingInfoActionTypes.Load),
        makePendingPayment: createAsyncActions(BillingInfoActionTypes.MakePendingPayment),
        processFailedPayment: createAsyncActions(BillingInfoActionTypes.ProcessFailedPayment),
        processPayment: createAsyncActions(BillingInfoActionTypes.ProcessPayment),
        setValues: (values: any) => {
            return {
                type: BillingInfoActionTypes.SET_VALUES,
                payload: values,
            };
        },
        saveCc: createAsyncActions(BillingInfoActionTypes.SaveCC),
    } as BillingInfoActionCreators;
}
