import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { CategoryState, defaultCategoryState, createCategoryReducer } from "../category.reducer";
import { PayloadAction } from "../../actions/defs";
import { ContractorDashboardActionTypes } from "../../actions/contractor/dashboard.actions";
import { Areas } from "../../constants/Areas";

export interface ContractorDashboardState {
    categories: CategoryState;
    scores: Landmark.DispatchStatisticsResponse;
    vocComments?: Landmark.VOCCommentsResponse[];
}

const defaultState: ContractorDashboardState = {
    categories: defaultCategoryState,
    scores: null,
    vocComments: [],
};

const categoryReducer = createCategoryReducer(Areas.Contractor.Dashboard.Page);

export function ContractorDashboardReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.DispatchStatisticsResponse |
            Landmark.VOCCommentsResponse[]
        >
) {
    // React to changes in the selected category
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action ) }
    });

    switch (action.type) {
        case ContractorDashboardActionTypes.LoadScores.SUCCESS: {
            const payload = (action as PayloadAction<Landmark.DispatchStatisticsResponse>).payload;
            return update(state, {
                scores: { $set: payload }
            });
        }
        case ContractorDashboardActionTypes.LoadComments.SUCCESS: {
            const payload = (action as PayloadAction<Landmark.VOCCommentsResponse[]>).payload;
            return update(state, {
                vocComments: { $set: payload }
            });
        }
        case ContractorDashboardActionTypes.CLEAR_COMMENTS: {
            return update(state, {
                vocComments: { $set: [] }
            });
        }
    }
    return state;
}
