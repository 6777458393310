import * as React from "react";
import environment from "environment";
/**
 * Adds a message to be sent to AdWords to mark a user as a ad conversion.
 *
 * @export
 * @returns Script tag that adds a message for adwords to
 */
export function getSendConversionScript() {
    return (
        <script>
            {`
                window.conversionTracked = window.conversionTracked || !gtag('event', 'conversion', {'send_to': 'AW-966088449/LXEQCK2axYEBEIGu1cwD'});
            `}
        </script>
    );
}

/**
 * Gets the Script tag that downloads the conversion async script.
 *
 * @returns Script tag referencing the adwords script for making ajax conversion updates.
 */
function getAdWordsConversionScript() {
    return [
        <script key="AdWords-1" async src="https://www.googletagmanager.com/gtag/js?id=AW-966088449"></script>,
        <script key="AdWords-2">
            {`
                gtag('js', new Date());

                gtag('config', 'AW-966088449');
            `}
        </script>
    ];
}

/**
 * Gets the Google tag manager script tag.
 *
 * @returns Script that sets up Google Tag Manager.
 */
function getGoogleTagManagerScript() {
    return [
        <script async key="GTM-1" src="https://www.googletagmanager.com/gtm.js?id=GTM-W2R6TPP"></script>,
        <script async key="GTM-2">
            {`
                window.dataLayer = window.dataLayer || [];
                gtag('gtm.start', new Date());
            `}
        </script>
    ];
}

/**
 * Gets the Munchkin scripts required for Marketo tracking.
 *
 * @returns - List of scripts used for marketing tracking.
 */
function getMunchkinScript() {
    return (
        <script async key="Munchkin" >
            {`
                window.munchkinTracked = false;
                function initMunchkin() {
                    window.munchkinTracked = window.munchkinTracked || !Munchkin.init('204-GIU-640');
                }

                // Adds a script tag that will download the munchkin.js file and execute the init function when it is downloaded.
                const script = document.createElement("script");
                script.async = true;
                script.src = "https://munchkin.marketo.net/munchkin.js";
                script.onload = initMunchkin;
                document.head.appendChild(script);
            `}
        </script>
    );
}

/**
 * Gets the Tealium scripts required for Marketo tracking.
 * @export
 * @returns - List of scripts used for marketing tracking.
 */
export function getTealiumScript() {
    let src = environment.isProduction ? "prod" : "qa";
    return (
        <script async key="Tealium" >
            {`
                window.utag_data = {
                // data layer elements to be populated
                };

                (function(a,b,c,d){
                a='https://tags.tiqcdn.com/utag/americanhomeshield/landmark/${src}/utag.js';
                b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
                a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
                })();
            `}
        </script>
    );
}
/**
 * Gets the Script tag for jquery.
 * @export
 * @returns Script tag for jquery for dynamic analytics tracking .
 */
//export function getJQueryScript() {
//    return (
//        <script async key="JQuery" src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
//    );
//}
/**
 * Gets tracking scripts and puts them in a list.
 *
 * @export
 * @returns - List of scripts used for marketing tracking.
 */
export function getTrackingScripts() {
    return [
        <script key="dataLayer">
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
            `}
        </script>,
        getAdWordsConversionScript(),
        getGoogleTagManagerScript(),
        getMunchkinScript()
    ];
}

/**
 * Gets A/B testing scripts.
 *
 * @export
 * @returns - Scripts used for marketing A/B Testing.
 */
export function getABtestingScripts() {
    return (
        <script defer type='text/javascript'>
            {`
                var _vwo_code=(function(){
                var account_id=339046,
                settings_tolerance=2000,
                library_tolerance=2500,
                use_existing_jquery=false,
                is_spa = 1,
                /* DO NOT EDIT BELOW THIS LINE */
                f=false,d=document;return{use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b='body{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('//dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer;}};}());_vwo_settings_timer=_vwo_code.init();
            `}
        </script>
        // End Visual Website Optimizer Asynchronous Code
    );
}

