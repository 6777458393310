import * as React from "react";
import * as Landmark from "models/landmark-api";
import { RouteComponentProps } from "react-router";
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";
import { TextBox } from "../TextBox";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "store/componentBindings";
import { createServiceRequestActions, ServiceRequestActionCreators, ServiceRequestActionTypes } from "actions/serviceRequest.actions";
import { actionPromise } from "../../utils/redux-promise";
import { toastr } from "react-redux-toastr";
import { PayloadAction } from "../../actions/defs";


interface Props extends Partial<RouteComponentProps<any>> {
    workorderId: string;
    timeSlot: string;

    actions?: {
        serviceRequest: ServiceRequestActionCreators;
        router: RouterActionCreators;
    };
}

interface State {
    selectedDate: Moment;
    selectedStartTime: string;
    selectedEndTime: string;
    isCancelDialogOpen?: boolean;
    cancelledReason: string;
}

@connect(
    state => ({
    }),
    bindActionCreatorsToProps({
        serviceRequest: createServiceRequestActions(),
        router: createRouterActions()
    })
)

export class StatusUpdate extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: moment(),
            selectedStartTime: "",
            selectedEndTime: "",
            isCancelDialogOpen: false,
            cancelledReason: ""
        };
    }

    handleScheduledSubmit = async (workorderId: string) => {

        const request: Landmark.WorkOrderStatusSummary = {
            ScheduleDateTime: this.state.selectedDate.format('L'),
            ScheduledStartWindow: this.state.selectedStartTime,
            ScheduledEndWindow: this.state.selectedEndTime,
            Id: workorderId,
            Status: "Scheduled",
            Tenant: "e4820751-1a2a-4705-92a9-eabbb1215574"
        };

        try {
            const response = await actionPromise<PayloadAction<boolean>>(
                () => this.props.actions.serviceRequest.statusUpdate.begin(request),
                ServiceRequestActionTypes.StatusUpdate.SUCCESS,
                ServiceRequestActionTypes.StatusUpdate.FAILURE
            );

            if (response.payload === true) {
                toastr.success("Thank you", "Scheduled status has been successfully submitted.", { showCloseButton: true, transitionIn: "fadeIn", transitionOut: "fadeOut" });
                this.props.actions.router.push(`/`);
            }
            else {
                toastr.error("Error", `Failed to complete status update. Please call for assistance.`, { showCloseButton: true, transitionIn: "fadeIn", transitionOut: "fadeOut" });
            }
        } catch (e) {
            toastr.error("Error", `Failed to complete status update. Please call for assistance.`, { showCloseButton: true, transitionIn: "fadeIn", transitionOut: "fadeOut" });
        }
    }

    handleSubmitCancel = async (cancelledReason: string, workorderId: string) => {       

        const cancelRequest: Landmark.WorkOrderCancelModel = {
            WorkOrderId: workorderId,
            CancellationNotes: cancelledReason,
        };

        try {
            const response = await actionPromise<PayloadAction<boolean>>(
                () => this.props.actions.serviceRequest.cancel.begin(cancelRequest),
                ServiceRequestActionTypes.Cancel.SUCCESS,
                ServiceRequestActionTypes.Cancel.FAILURE
            );

            if (response.payload === true) {
                toastr.success("Confirm", "Cancellation request has been submitted.", { showCloseButton: true, transitionIn: "fadeIn", transitionOut: "fadeOut" });
                this.props.actions.router.push(`/`);
            }
            else {
                toastr.error("Error", `Failed to cancel work order. Please call for assistance.`, { showCloseButton: true, transitionIn: "fadeIn", transitionOut: "fadeOut" });
            }
        } catch (e) {
            toastr.error("Error", `Failed to cancel work order. Please call for assistance.`, { showCloseButton: true, transitionIn: "fadeIn", transitionOut: "fadeOut" });
        }
    }

    render() {
        let workorderId = this.props.match.params.workorderId;

        const timeSlots = ["", "12AM", "1AM", "2AM", "3AM", "4AM", "5AM", "6AM", "7AM", "8AM", "9AM", "10AM", "11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM"];
        return (
            <main style={{ paddingTop: 150 }}>
                <div>
                    <div>
                        <h2 className="homeowner" style={{ paddingLeft: 5 }}>Update Work Order Status</h2>
                    </div>
                    <div className="column third">
                        <h2>Choose Scheduled Date</h2>
                        Scheduled Date:
                        <DatePicker
                            maxDate={moment().add(6, "month")}
                            minDate={moment().startOf("day")}
                            onChange={(date: Moment) => this.setState({ selectedDate: date })}
                            selected={moment(this.state.selectedDate)}
                        />
                        <div style={{ marginTop: "1em" }}>
                            <div>
                                Start Window: 
                                <select style={{ marginLeft: "5px", marginRight: "15px" }}
                                    onChange={(e: any) => this.setState({ selectedStartTime: e.target.value })}>
                                    {timeSlots
                                        .map((timeSlot) => <option>{timeSlot}</option>)}
                                </select>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                End Window: 
                                <select style={{ marginLeft: "12px" }} onChange={(e: any) => this.setState({ selectedEndTime: e.target.value })}>
                                    {timeSlots
                                        .map((timeSlot) => <option>{timeSlot}</option>)}
                                </select>
                            </div>
                        </div>
                        <button style={{ minWidth: "100px", width: "135px", marginTop: "5px"}}
                            type="button"
                            disabled={this.state.selectedStartTime === "" || this.state.selectedEndTime === ""}
                            onClick={() => this.handleScheduledSubmit(workorderId)}
                            className="btn-stroke-green">Submit</button>
                    </div>
                    <h2 className="homeowner column seventh" style={{ fontSize: 25 }}>-Or-</h2>
                    <div className="column half" style={{marginLeft:"35px"}}>
                        <h2>Cancel Work Order</h2>
                        <TextBox
                            type="text"
                            placeholder={`Cancellation reason...`}
                            value={this.state.cancelledReason}
                            onChange={(e: any) => this.setState({ cancelledReason: e.target.value })}
                            />
                        <button style={{ minWidth: "100px", width: "250px", marginTop: "5px" }}
                            disabled={this.state.cancelledReason === ""}
                            type="button"
                            onClick={() => this.handleSubmitCancel(this.state.cancelledReason, workorderId) }
                            className="btn-stroke-orange">Submit Cancel</button>
                    </div>
                </div>
            </main>
        );
    }
}
