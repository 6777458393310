import { createRouterActions, RouterAction } from "actions/router.actions";
import * as Landmark from "models/landmark-api";
import { actions as toastrActions } from "react-redux-toastr";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { concat, map, switchMap } from "rxjs/operators";
import { AuthActionTypes, createAuthActions, RefreshAction } from "../actions/auth.actions";
import { createCategoryActions } from "../actions/category.actions";
import { PayloadAction } from "../actions/defs";
import { createDialogActions } from "../actions/dialog.actions";
import { Areas } from "../constants/Areas";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const accountPageActions = createCategoryActions(Areas.Account.Page);
const authActions = createAuthActions();
const loginDialogActions = createDialogActions(Areas.Account.Login);
const routerActions = createRouterActions();

const handleImpersonationStart: Epic<
    PayloadAction<Landmark.AuthenticationResponse | Error>
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Impersonate.Start.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .post(`/authenticate/as/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => authActions.startImpersonation.success(payload),
        err => authActions.startImpersonation.failure(err),
    ),
);

const handleImpersonationEnd: Epic<
    PayloadAction<Landmark.AuthenticationResponse | Error>
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Impersonate.End.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .post(`/authenticate/as/me`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => authActions.endImpersonation.success(payload),
        err => authActions.endImpersonation.failure(err),
    ),
);

const handleImpersonationFailure: Epic<
    Action
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Impersonate.Start.FAILURE, AuthActionTypes.Impersonate.End.FAILURE),
    switchMap(action => of(toastrActions.add({
        type: "error",
        title: "Error",
        message: `An error occurred while either attempting to impersonate or ending impersonation.`
    }))),
);

const handleAuthenticate: Epic<
    PayloadAction<
        Landmark.AuthenticationRequest |
        Landmark.AuthenticationResponse |
        Error
    >
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Authenticate.BEGIN),
    switchMapWithPromiseToActions(
        async (action: PayloadAction<Landmark.AuthenticationRequest>) => {
            // Handle token-based auth in a special way here
            let url = action.payload.grant_type === "code" ?
                "/authenticate/withToken" :
                "/authenticate";

            url = action.payload.grant_type === "user" ?
            "/authenticate/withUser"
            :url;

            return LandmarkApiService
                .post(url)
                .body(action.payload)
                .fetch()
                .then(response => response.json);
        },
        (payload: Landmark.AuthenticationResponse) => {
            if (payload.isAuthenticated) {
                // Only successful if we are authenticated
                return authActions.authenticate.success(payload);
            }
            else {
                // Otherwise, it's a failure
                return authActions.authenticate.failure(payload);
            }
        },
        err => authActions.authenticate.failure(err),
    ),
);

const closeLoginDialogOnSuccess: Epic<
    Action
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Authenticate.SUCCESS),
    switchMap(action =>
        // Successful login, close the dialog
        of(loginDialogActions.close())
    ),
);

const handleLogin: Epic<
    PayloadAction<Landmark.AuthenticationResponse> |
    RouterAction
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Authenticate.SUCCESS),
    // Redirect to the account page
    map((action: PayloadAction<Landmark.AuthenticationResponse>) => {
        if (action.payload.user.userType === "contractor") {

            window.location.replace("https://www.oneguardhomewarranty.com/Authentication/Account/LogInWithId?webUserId=" + action.payload.user.userId);

        }

        let url = "/account";
        if (action.payload.forgotPassword) {
            url += "?passwordReset=true";
        }
        return routerActions.push(url);
    }),
);

const handleLogout: Epic<
    Action
> = action$ => action$.pipe(
    ofType(AuthActionTypes.LOGOUT),
    switchMap(action =>
        // Return to the main page
        of(routerActions.push("/")).pipe(
            // Deselect the tab on the account page
            concat(of(accountPageActions.select(null)))
        )
    ),
);

const handleRefresh: Epic<
    Action
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Refresh.BEGIN),
    switchMapWithPromiseToActions(
        async (action: PayloadAction<RefreshAction>) => LandmarkApiService
            .post("/authenticate")
            .body({
                fingerprint: action.payload.fingerprint,
                grant_type: Landmark.AuthenticationGrantType.RefreshToken,
                password: action.payload.refreshToken,
                username: action.payload.username,
            } as Landmark.AuthenticationRequest)
            .fetch()
            .then(response => response.json),
        (payload: Landmark.AuthenticationResponse) => {
            if (payload.isAuthenticated) {
                // Only successful if we are refreshed
                return authActions.refresh.success(payload);
            }
            else {
                // Otherwise, it's a failure
                return authActions.refresh.failure(payload);
            }
        },
        err => authActions.refresh.failure(err),
    ),
);

const notifyUserOfAuthFailure: Epic<
    Action
> = action$ => action$.pipe(
    ofType(AuthActionTypes.Authenticate.FAILURE),
    switchMap(action =>
        of(toastrActions.add({
            type: "error",
            title: "Login Error",
            message: "The username or password you entered were incorrect.  Please try again.",
            options: {
                showCloseButton: true,
                timeOut: 10000,
            }
        }))
    ),
);

const waitDuringImpersonationStart = createWaitEpic(AuthActionTypes.Impersonate.Start);
const waitDuringImpersonationEnd = createWaitEpic(AuthActionTypes.Impersonate.End);
const waitDuringLogin = createWaitEpic(AuthActionTypes.Authenticate);

const epic = combineEpics(
    closeLoginDialogOnSuccess,
    handleAuthenticate,
    handleImpersonationStart,
    handleImpersonationEnd,
    handleImpersonationFailure,
    handleLogin,
    handleLogout,
    handleRefresh,
    notifyUserOfAuthFailure,
    waitDuringImpersonationEnd,
    waitDuringImpersonationStart,
    waitDuringLogin,
);
export default epic;
