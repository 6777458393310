import * as React from "react";
import { RouteComponentProps } from "react-router";
import Scroll from "react-scroll";

import { CallUs } from "../../components/CallUs";
import { EmailUs } from "../../components/EmailUs";
import { Footer } from "../../components/Footer";
import { Meta } from "../../components/Meta";
import { BasicRegistration } from "../../components/sections/BasicRegistration";

interface Props extends Partial<RouteComponentProps<any>> {
}

const style = {
    emailCare: {
        color: "White",
        marginleft: "-15px",
    }
};

export class ContactPage extends React.Component<Props, any> {
    componentDidMount() {
        let scroller = Scroll.animateScroll;
        if (!this.props.location.hash.length) {
            scroller.scrollToTop();
        }
    }
    /**
     * A react standard method to render this component.
     */
    render() {
        return (
            <div>
                <Meta
                    title="Contact Your Home Warranty Provider | Landmark Home Warranty"
                    description="Need to get in touch with a customer care representative? Visit our website to contact us via phone, email or form. Expedited service available 24/7."
                    canonical="/contact-us"
                />
                <main className="contact">
                    <section className="section-intro contact">
                        <div className="container">
                            <div className="row intro-content">
                                <h1>Contact Information</h1>
                            </div>
                            <hr className="light" />
                            <div className="row intro-content-contact">
                                <div className="column third">
                                    <div className="meta" data-icon="O"><CallUs /></div>
                                </div>
                                <div className="column third">
                                    <div className="meta" data-icon="M"><a style={style.emailCare} href="mailto:customercare@landmarkhw.com">customercare@landmarkhw.com</a></div>
                                </div>
                                <div className="column third">
                                    <div className="meta" data-icon="N">{/*P.O.Box 29650 <br />*/} Phoenix, AZ 85038-9650</div> {/*Remove PO Box number for ticket PHX-1566*/}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section-intro contact">
                        <div className="container">
                            <div className="row intro-content">
                                <h1>Media Contact Information</h1>
                            </div>
                            <hr className="light" />
                            <div className="row intro-content-contact">
                                <div className="column third">
                                    <div className="meta" data-icon="O"><CallUs /></div>
                                </div>
                                <div className="column third">                                    
                                    <div className="meta" data-icon="M"><a style={style.emailCare} href="mailto:mediacenter@landmarkhw.com">mediacenter@landmarkhw.com</a></div>
                                </div>
                                <div className="column third">
                                    <div className="meta" data-icon="N">{/*P.O.Box 29650 <br /> */} Phoenix, AZ 85038-9650</div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <EmailUs />
                    <BasicRegistration
                        largeTitle="Get a Free Home Warranty Quote!"
                        subTitle="Input your information and get a free, personalized home warranty quote. You can customize the coverage and pricing to fit your needs. Experience what over 70,000 homeowners already know: A Landmark Home Warranty helps provide protection for your home and budget!" />
                    <Footer />
                </main>
            </div>
        );
    }
}
