import * as React from "react";
import Scroll, { Link } from "react-scroll";
import { createDialogActions, DialogActionCreators } from "../../actions/dialog.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { OrderingParty } from "../../components/OrderingParty";
import { Areas } from "../../constants/Areas";
import { getIsAuthenticated } from "../../selectors/auth.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { getCanOrder } from "selectors/order.selectors";

const styles = {
    Hero: {
        height: "950px",
        paddingTop: "225px",
    } as React.CSSProperties
};

interface Props {
    store?: {
        isAuthenticated?: boolean;
        canOrder: boolean;
    };
    actions?: {
        loginDialog: DialogActionCreators;
        router: RouterActionCreators;
        srDialog: DialogActionCreators;
    };
    marketingSourceId?: string;
}

@connect(
    state => ({
        store: {
            isAuthenticated: getIsAuthenticated(state),
            canOrder: getCanOrder(state),
        }
    }),
    bindActionCreatorsToProps({
        loginDialog: createDialogActions(Areas.Account.Login),
        router: createRouterActions(),
        srDialog: createDialogActions(Areas.Account.ServiceRequest.Create),
    })
)
export class Homeowner extends React.Component<Props, any> {

    handleRequestRepair = (e) => {
        e.preventDefault();
        if (this.props.store.isAuthenticated) {
            this.props.actions.srDialog.open();
            this.props.actions.router.push("/account");
        }
        else {
            this.props.actions.srDialog.open();
            this.props.actions.loginDialog.open();
        }
    }

    handleCanOrder = (e) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            let scroller = Scroll.scroller;
            this.props.actions.router.push(`/#quote`);
            scroller.scrollTo("quote", {
                duration: 500,
                delay: 0,
                offset: -75,
                smooth: true,
            });
        }
        else {
              window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    render() {
        const today = new Date();
        console.log(today);
        return (
            <section style={styles.Hero} className="section-hero">
                <div className="container">
                    <div className="hero-content">
                        <div style={{ position: "absolute", maxWidth: "770px", zIndex: 11 }}>
                            <i title="Home icon for the homeowner warranty page" className="section-icon icon-homeowner" style={{ fontFamily: "landmark", fontStyle: "normal" }}></i>
                            <h1>Help Protect Your Home &amp; Budget with a Home Warranty</h1>
                            <p>It's inevitable: your home's major appliances and system components will eventually break down and aren't covered with homeowner insurance. Landmark Home Warranty
                                can help protect your home from these expensive repairs and replacements each year.</p>
                            <div className="btn-group" style={{ marginTop: 30, marginBottom: 20 }}>

                                <OrderingParty
                                    color="orange"
                                    marketingSourceId={this.props.marketingSourceId}
                                />

                                <a
                                    className="btn-green"
                                    href="/account"
                                    type="button"
                                    onClick={e => this.handleRequestRepair(e)}>Request Repair<i data-icon="j"></i>
                                </a>

                                <a
                                    href="/#quote"
                                    className="btn-gray btn-stroke-light btn-homeowner"
                                    onClick={e => this.handleCanOrder(e)}>See Plans & Pricing <i data-icon="j"></i></a>
                            </div>
                        </div>
                        <div className="hiddenIfSmallerThan750" style={{ opacity: .85, backgroundColor: "white", position: "absolute", maxWidth: "810px", width: "810px", height: "480px", zIndex: 10, left: -20, top: -20, borderRadius: "6px" }} />
                    </div>
                </div>
                <Link to="professional" className="scroll-down" data-icon="m" smooth={true} offset={-90} duration={500}></Link>
            </section>
        );
    }
}
