import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as Scroll from "react-scroll";

import { Homeowner } from "./Homeowner";
import { RealEstate } from "./RealEstate";
//import { Resources } from "./Resources";
import { Footer } from "../../components/Footer";
import { LogoBanner } from "../../components/LogoBanner";
import { Meta } from "../../components/Meta";
import { ScrollPosition, withScrollTracking } from "../../components/ScrollingTracker";
import { BasicRegistration } from "../../components/sections/BasicRegistration";
import { Reviews } from "../../components/sections/Reviews";
import { Contractors } from "../HomePage/Contractors";
import { createOrderActions, OrderActionCreators } from "../../actions/order.actions";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import * as Landmark from "models/landmark-api";
import { getOrderRequest, getMarketingSourceId} from "../../selectors/order.selectors";

interface Props extends Partial<RouteComponentProps<any>> {
    store?: {
        request: Landmark.OrderRequest;
        marketingSourceId: string;
    };
    actions?: {
        order: OrderActionCreators;
    };
    type?: "customer";
    scroll?: ScrollPosition;
}

@connect(
    state => ({
        store: {
            request: getOrderRequest(state),
            marketingSourceId: getMarketingSourceId(state)
        }
    }),
    bindActionCreatorsToProps({
        order: createOrderActions(),
    })
)

@withScrollTracking(200)
export class HomePage extends React.Component<Props, any> {

    constructor(props: Props) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search);

        const marketingSourceIdURL = queryParams.get('MarketingSourceId');

        if (marketingSourceIdURL) {
            this.props.actions.order.setMarketingSourceId(marketingSourceIdURL);
        }
    }

    componentDidMount() {
        let scroller = Scroll.animateScroll;
        if (!this.props.location.hash.length) {
            scroller.scrollToTop();
        }
    }

    render() {
        return (
            <main>
                <Meta
                    title="Home Warranty Service Provider | Landmark Home Warranty"
                    description="Landmark provides affordable and comprehensive home warranty service in Arizona, Idaho, Nevada, Oregon, Texas, and Utah. Request a quote today."
                />
                <Homeowner marketingSourceId={this.props.store.marketingSourceId} />
                <LogoBanner />
                <RealEstate />
                <Contractors />
                {/*<Resources count={3} />*/}
                <Reviews type={"customer"} />
                <BasicRegistration
                    largeTitle="Get a Free Home Warranty Quote!"
                    subTitle="Input your information and get a free, personalized home warranty quote. You can customize the coverage and pricing to fit your needs. Experience what over 70,000 homeowners already know: A Landmark Home Warranty helps provide protection for your home and budget!" />
                <Footer />
            </main>
        );
    }
}
