import * as Landmark from "models/landmark-api";

import { ActionCreatorMap } from "../store/componentBindings";
import { AsyncAction, AsyncActionCreators, createAsyncActions } from "./defs";

export const FeatureActionTypes = {
    LoadFeatures: AsyncAction("feature/load"),
    setFeature: AsyncAction("feature/set"),
};
Object.freeze(FeatureActionTypes);

export interface FeatureActionCreators extends ActionCreatorMap {
    /**
     * Loads enabled/disabled application features.
     */
    loadFeatures: AsyncActionCreators<void, Landmark.FeatureResponse>;

    /**
     * Enables/disables application features.
     */
    setFeature: AsyncActionCreators<Landmark.Feature, void>;
}

export function createFeatureActions(): FeatureActionCreators {
    return {
        loadFeatures: createAsyncActions(FeatureActionTypes.LoadFeatures),
        setFeature: createAsyncActions(FeatureActionTypes.setFeature),
    };
}
