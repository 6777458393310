import { Action } from "redux";
import { from, of, pipe } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

/**
 * Switches to a new observable, based on the results of the promise.
 * @param promiseFn A function that returns a promise.
 * @param successActionFn A function that will return an observable when the promise is successfully resolved.
 * @param failureActionFn A function that will return an observable when the promise is rejected.
 */
export function switchMapWithPromiseToActions<TInput, TPayload, TSuccess extends Action, TFailure extends Action>(
    promiseFn: (action: TInput) => Promise<TPayload>,
    successActionFn: (payload: TPayload) => TSuccess,
    failureActionFn: (err: Error) => TFailure) {
    return pipe(
        switchMap((action: TInput) =>
            // Wait for the promise
            from(promiseFn(action)).pipe(
                // Success condition
                map((payload: TPayload) => successActionFn(payload)),
                // Failure condition
                catchError(err => of(failureActionFn(err))),
            )
        )
    );
}
