import { GetContractOffersResponse } from "models/landmark-api";
import { Action } from "redux";
import { ActionCreatorMap } from "../store/componentBindings";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions } from "./defs";


export interface GetContractOffersRequest {
    dwellingTypeId: string;
    contractId?: string;
    isConvertingFromListingCoverage?: boolean;
    isListingCoverage?: boolean;
    isUpgrading?: boolean;
    planTypeId: string;
    stateCode: string;
}

export const OffersActionTypes = {
    CLEAR: `${appId}/offers/CLEAR`,
    Load: AsyncAction(`offers/load`),
};
Object.freeze(OffersActionTypes);

export interface OffersActionCreators extends ActionCreatorMap {
    /**
     * Clears out the offers
     */
    clear: () => Action;

    /**
     * Gets contract offers for the state, transaction type, and property type combination.
     * @param {GetContractOffersRequest} request The request to get contract offers.
     */
    load: AsyncActionCreators<GetContractOffersRequest, GetContractOffersResponse>;
}

export function createOffersActions(): OffersActionCreators {
    return {
        clear: () => ({ type: OffersActionTypes.CLEAR }),
        load: createAsyncActions(OffersActionTypes.Load),
    };
}
