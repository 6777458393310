import * as Landmark from "models/landmark-api";

import { appId, AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const UsersActionTypes = {
    Load: AsyncAction(`account/users/load`),
    MergeAccounts: AsyncAction(`account/users/merge`),
    SELECT_USER: `${appId}/account/users/SELECT_USER`,
};

export interface MergeUserRequest {
    /**
     * The request used to merge accounts.
     */
    request: Landmark.MergeUserRequest;

    /**
     * True if the user was prompted to merge user accounts, false if there was no interaction required from the user.
     */
    userInteractive: boolean;
}

/**
 * Then, define an interface that represents how our actions will look when other
 * portions of the app consume them.  This is where intellisense will look for
 * documentation.
 */
export interface UsersActionCreators extends ActionCreatorMap {
    /**
     * Loads users associated with the authenticated user.
     */
    load: AsyncActionCreators<string, Landmark.User[]>;

    /**
     * Merges users accounts.
     */
    mergeAccounts: AsyncActionCreators<MergeUserRequest, void>;

    /**
     * Selects the user with the given user id.
     * @param {number} userId The user id to select.
     */
    selectUser: (userId: string) => PayloadAction<string>;
}

export function createUsersActions() {
    return {
        load: createAsyncActions(UsersActionTypes.Load),
        mergeAccounts: createAsyncActions(UsersActionTypes.MergeAccounts),
        selectUser: (userId: string) => ({
            type: UsersActionTypes.SELECT_USER,
            payload: userId
        }),
    } as UsersActionCreators;
}
