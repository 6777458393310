import update from "immutability-helper";
import { Action } from "redux";

import { PayloadAction } from "../actions/defs";
import { CompleteCategoryAction, SelectCategoryAction, buildCategoryActionTypes } from "../actions/category.actions";

/**
 * State data for a categories.
 */
export interface CategoryState {
    selected: string;
    completed: string[];
}

/**
 * Default state for categories.
 */
export const defaultCategoryState: CategoryState = {
    selected: null,
    completed: [],
};

/**
 * Creates a category reducer for a given area.
 * @param area The area that the categories belong to.
 */
export function createCategoryReducer(area: string) {
    const types = buildCategoryActionTypes(area);

    return function(
        state = defaultCategoryState,
        action:
            Action |
            PayloadAction<any> |
            CompleteCategoryAction |
            SelectCategoryAction
    ) {
        switch (action.type) {
            case types.COMPLETE:
                const completeAction = action as CompleteCategoryAction;
                const index = state.completed.findIndex(category => category === completeAction.payload.category);
                if (completeAction.payload.isCompleted && index === -1) {
                    // Push the category on the end of the array
                    return update(state, {
                        completed: { $push: [completeAction.payload.category] }
                    });
                }
                // Remove the category from the array
                else if (!completeAction.payload.isCompleted && index >= 0) {
                    return update(state, {
                        completed: { $splice: [[index, 1]] }
                    });
                }
                return state;

            case types.SELECT:
                const selectAction = action as SelectCategoryAction;
                return update(state, {
                    selected: { $set: selectAction.payload.category }
                });

            default: break;
        }
        return state;
    };
}
