import * as React from "react";
import Scroll from "react-scroll";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";

interface Props {
    actions?: {
        router: RouterActionCreators
    };
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class RequestBrochure extends React.Component<Props, any> {
    handledownloadClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push("/home-buyers-warranty#brochure");
        scroller.scrollTo("brochure", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    render() {
        return (
            <div>
                <h4><a className="open-modal"
                    href="/home-buyers-warranty#brochure"
                    onClick={e => this.handledownloadClick(e)}
                    style={{ fontSize: 20 }}
                >Request a Brochure <i data-icon="j"></i></a></h4>
            </div>
        );
    }
}
