import { FeatureActionTypes } from "actions/feature.actions";
import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { PayloadAction } from "../actions/defs";

export interface FeatureState {
    response: Landmark.FeatureResponse;
}

const defaultState: FeatureState = {
    response: null,
};
Object.freeze(defaultState);

export const FeatureReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.FeatureResponse>
) => {
    switch (action.type) {
        case FeatureActionTypes.LoadFeatures.SUCCESS: {
            const { payload: response } = action as PayloadAction<Landmark.FeatureResponse>;
            return update(state, { response: { $set: response } });
        }
    }

    // If nothing changed, return the original state
    return state;
};
