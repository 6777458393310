import moment from "moment";

import { createSelector } from "../common.selectors";
import { ApplicationState } from "../../store/app";

const getAccountState = (state: ApplicationState) => state.account;
const getContractsState = (state: ApplicationState) => state.account.contracts;

/**
 * Gets a list of contracts on the account.
 */
export const getContracts = createSelector(
    getAccountState,
    account => account.contracts.list || []
);

/**
 * Gets all active contracts
*/
export const getActiveContracts = createSelector(
    getContracts,
    contracts => contracts.filter(contract => contract.contractStatus.description === "Active"),
);

/**
 * Gets the first contract on the account, or the contract matching the 'contractId' on props.
 */
export const getContract = createSelector(
    (state: ApplicationState, props: any = null) => {
        const contracts = state.account.contracts.list;
        if (contracts && contracts.length > 0) {
            if (props && props.contractId !== undefined) {
                return contracts.find(c => c.contractId === props.contractId);
            }
            return contracts[0];
        }
        return null;
    },
    contract => contract
);

/**
 * Gets the state code on the first contract on the account.
 */
export const getContractStateCode = createSelector(
    getContract,
    contract => contract.address && contract.address.state,
);

/**
 * Gets coverages for the contract.
 */
export const getCoverages = createSelector(
    getContractsState,
    contracts => contracts.coverages || [],
);

/**
 * Gets all pending contracts
*/
export const getPendingContracts = createSelector(
    getContracts,
    contracts => contracts.filter(contract => contract.contractStatus.description === "Pending"),
);

/**
 * Gets all pending contracts that will become active in the future (paid for, just waiting for
 * the homeowner wait period). Some pending contracts haven't been paid for, and won't become
 * active until they are paid for. We don't want to include those here.
*/
export const getPendingContractsWithEffectiveDate = createSelector(
    getPendingContracts,
    contracts => {
        const now = moment();
        return contracts.filter(contract =>
            moment(contract.effectiveDate).isAfter(now) &&
            moment(contract.expireDate).isAfter(now)
        );
    }
);

/**
 * Gets the selected contract id.
 */
export const getSelectedContractId = createSelector(
    getAccountState,
    account => account.contracts.selectedContractId
);

/**
 * Gets the selected contract effective date.
 */
export const getSelectedContractEffectiveDate = createSelector(
    (state: ApplicationState) => state.order,
    getContracts,
    (order, contracts) => {
        let [effectiveDate = null] =
        contracts
            .filter(contract => contract.contractId === order.contractId)
            .map(contract => contract.effectiveDate);
        return effectiveDate;
    }
);

/**
 * Gets the selected contract expire date.
 */
export const getSelectedContractExpireDate = createSelector(
    (state: ApplicationState) => state.order,
    getContracts,
    (order, contracts) => {
        let [expireDate = null] =
        contracts
            .filter(contract => contract.contractId === order.contractId)
            .map(contract => contract.expireDate);
        if (expireDate === null) {
            expireDate = order.expireDate;
        }
        return expireDate;
    }
);

/**
 * Gets unpaid contracts. If the contract is pending, and it doesn't have an effective date
 * then it hasn't been paid for yet.
 */
export const getUnpaidContracts = createSelector(
    getPendingContracts,
    contracts => contracts.filter(contract =>
        !(contract.effectiveDate)
    ),
);

/**
 * Gets unpaid RE contracts. These have a special condition that allow them to make service requests.
 */
export const getUnpaidReContracts = createSelector(
    getUnpaidContracts,
    contracts => {
        const now = moment();
        const ReContractTypeId = "4736A67B-08BD-49E0-90EC-78CE03BE0A60";
        return contracts.filter(contract =>
            (
                contract.planTypeId.toUpperCase() === ReContractTypeId &&
                contract.estimatedClosingDate &&
                moment(contract.estimatedClosingDate).isSameOrAfter(now, "day") &&
                moment(contract.estimatedClosingDate).isSameOrBefore(now.add(14, "days"))
            )
        );
    }
);
