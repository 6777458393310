import * as React from "react";
import Loading from "react-loading";

import { getIsWaiting } from "../selectors/wait.selectors";
import { connect } from "../store/componentBindings";
import * as Styles from "../styles";

type Options = "spokes" | "spinningBubbles";

interface StateProps {
    isWaiting?: boolean;
}

interface MyProps {
    color?: string;
    delay?: number;
    type?: Options;
}

type Props = StateProps & MyProps;

@connect<StateProps, any, MyProps>(
    state => ({
        isWaiting: getIsWaiting(state),
    })
)
export default class WaitIndicator extends React.Component<Props, any> {
    static defaultProps?: Props = {
        color: "#eaeaea",
        delay: 500,
        type: "spinningBubbles",
    };

    /**
     * A react standard method to render this component.
     */
    render() {
        const style = Styles.Wait.FullScreen;
        let { loader, outer } = style;
        let container = style.container(this.props);

        return (
            <div style={outer}>
                {/* Always show the loader container, so it animates in-out properly */}
                <div style={container}>
                    {this.props.isWaiting ? (
                        <div style={loader}>
                            <Loading type={this.props.type} color={this.props.color} delay={this.props.delay} />
                        </div>
                    ) : null}
                </div>
                {/* Always show the children behind the loader container */}
                {this.props.children}
            </div>
        );
    }
}
