import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { AuthActionTypes } from "../../actions/auth.actions";
import { ContractorStatisticsActionTypes } from "../../actions/contractor/dispatchStatistics.actions";

export interface ContractorDispatchStatisticsState {
    dispatchStatistics: Landmark.DispatchStatisticsResponse;
}

const defaultState: ContractorDispatchStatisticsState = {
    dispatchStatistics: {},
};

export const ContractorDispatchStatisticsReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<ContractorDispatchStatisticsState>
) => {
    switch (action.type) {
        case ContractorStatisticsActionTypes.Load.SUCCESS: {
            const loadAction = action as PayloadAction<ContractorDispatchStatisticsState>;
            return update(state, {
                dispatchStatistics: { $set: loadAction.payload }
            });
        }
        case AuthActionTypes.LOGOUT: {
            return defaultState;
        }
    }
    return state;
};
