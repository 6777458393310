import { ParsedUrlQuery } from "querystring";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as url from "url";

import { ForgotPassword } from "./ForgotPassword";
import { Modal } from "./Modal";
import { TextBox } from "./TextBox";
import { AuthActionCreators, createAuthActions } from "../actions/auth.actions";
import { createDialogActions, DialogActionCreators } from "../actions/dialog.actions";
import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { Areas } from "../constants/Areas";
import { getIsAuthenticated, getLastUsername } from "../selectors/auth.selectors";
import { createGetIsDialogOpen } from "../selectors/dialog.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { getFingerprintAsync } from "../utils/fingerprint";
import * as Styles from "../styles";

const styles = {
    button: {
        display: "inline",
    },
    social: {
        container: {
            display: "block",
            margin: "0 auto 20px auto",
            textAlign: "center",
        },
        li: {
            display: "inline-block",
            margin: "0 5px",
        },
        icon: extra => Object.assign(
            {
                borderRadius: "100%",
                color: "white",
                display: "inline-block",
                fontSize: "20px",
                height: 42,
                padding: "9px 0 0 1px",
                textDecoration: "none",
                width: 42,
            },
            extra
        ),
    },
    submitLink: {
        display: "block",
        fontSize: "12px",
        fontFamily: Styles.Fonts.HelveticaNeueLight,
        margin: "10px auto 0 auto",
        textDecoration: "underline",
    }
};

class LoginControls {
    username: TextBox;
    password: TextBox;
    rememberMe: HTMLInputElement;
}

interface Props extends Partial<RouteComponentProps<any>> {
    store?: {
        isAuthenticated: boolean;
        isLoginDialogOpen: boolean;
        isSrDialogOpen: boolean;
        lastUsername: string;
    };
    actions?: {
        auth: AuthActionCreators;
        loginDialog: DialogActionCreators;
        srDialog: DialogActionCreators;
        router: RouterActionCreators;
    };
}

interface QueryParams extends ParsedUrlQuery {
    login: string;
}

interface ParsedLocation {
    query?: QueryParams;
}

@connect(
    state => ({
        store: {
            isAuthenticated: getIsAuthenticated(state),
            isSrDialogOpen: createGetIsDialogOpen(Areas.Account.ServiceRequest.Create)(state),
            isLoginDialogOpen: createGetIsDialogOpen(Areas.Account.Login)(state),
            lastUsername: getLastUsername(state),
        }
    }),
    bindActionCreatorsToProps({
        auth: createAuthActions(),
        loginDialog: createDialogActions(Areas.Account.Login),
        srDialog: createDialogActions(Areas.Account.ServiceRequest.Create),
        router: createRouterActions()
    })
)
export class Login extends React.Component<Props, any> {
    controls = new LoginControls();

    componentDidMount() {
        const { pathname = "", search = "" } = this.props.location || {};
        const { query } = url.parse(pathname + search, !!search) as ParsedLocation;

        if (query && query.login) {
            this.props.actions.loginDialog.open();
        }
    }

    handleLogin = async () => {
        const fingerprint = await getFingerprintAsync();
        this.props.actions.auth.withPassword(
            this.controls.username.value,
            this.controls.password.value,
            fingerprint,
            this.controls.rememberMe.checked
        );
    }

    render() {
        const lastLoginUsername = this.props.store.lastUsername;

        const loginButton = (
            <a
                href="/login"
                className="btn-stroke-orange loginbtn btn-orange btn-stroke-orange login"
                onClick={e => {
                    e.preventDefault();
                    this.props.actions.srDialog.close();
                    this.props.actions.loginDialog.open();
                }}>Login</a>
        );

        const accountButton = (
            <a
                href="/account"
                className="btn-stroke-orange loginbtn btn-orange btn-stroke-orange login"
                onClick={e => {
                    e.preventDefault();
                    this.props.actions.router.push("/account");
                }}>Account</a>
        );

        return (
            <div style={styles.button}>
                {this.props.store.isAuthenticated ? accountButton : loginButton}
                <Modal
                    isOpen={this.props.store.isLoginDialogOpen || this.props.store.isSrDialogOpen}
                    onAfterOpen={() => {
                        if (lastLoginUsername && lastLoginUsername.length) {
                            // If a previous username was provided, then focus password
                            this.controls.password.focus();
                        }
                        else {
                            // Otherwise, focus the username
                            this.controls.username.focus();
                        }
                    }}
                    onRequestClose={() => {
                        this.props.actions.loginDialog.close();
                        this.props.actions.srDialog.close();
                    }}
                    style={Styles.Modal.AutoSized}>
                    <div style={{ maxWidth: 500 }} className="modal modal-admin">
                        <h3>{this.props.store.isSrDialogOpen ? "Please login to request repair" : "Login to Your Account"}</h3>
                        <div style={{ marginBottom: 30, fontSize: 15 }}>
                            <span>Your user name is the email address you used to place an order.<br />
                                If you don't have a password, </span>
                            <ForgotPassword>click here.</ForgotPassword><br />
                            <span> If you need further assistance, please email <br /> <a href="mailto:customercare@landmarkhw.com">customercare@landmarkhw.com</a>.</span>
                        </div>
                        <form>
                            <fieldset>
                                <div className="row">
                                    <div className="column full">
                                        <label className="field-label">Username</label>
                                        <div className="field-wrap">
                                            <TextBox
                                                ref={input => this.controls.username = input}
                                                defaultValue={lastLoginUsername ? lastLoginUsername : ""}
                                                placeholder="Email Address"
                                                onEnterPressed={this.handleLogin} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column full">
                                        <label className="field-label">Password</label>
                                        <div className="field-wrap">
                                            <TextBox
                                                ref={input => this.controls.password = input}
                                                type="password"
                                                placeholder="Password"
                                                onEnterPressed={this.handleLogin} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column full">
                                        <div className="field-wrap">
                                            <input
                                                ref={input => this.controls.rememberMe = input}
                                                id="remember-me"
                                                type="checkbox"
                                                name="remember-me" />
                                            <label htmlFor="remember-me">Remember Me?</label>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <button type="button" className="btn-orange" onClick={this.handleLogin}>Login<i data-icon="j"></i></button>
                                </div>
                                <div style={{ marginTop: 5, textAlign: "center" }}>
                                    <ForgotPassword>Forgot Password?</ForgotPassword>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}

