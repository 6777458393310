import * as Landmark from "models/landmark-api";

import { AsyncAction, createAsyncActions, AsyncActionCreators, appId, PayloadAction } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const InvoiceActionTypes = {
    Search: AsyncAction(`account/invoice/search`),
    SELECT: `${appId}/account/invoice/SELECT`,
};

export function createInvoiceActions(): InvoiceActionCreators {
    return {
        search: createAsyncActions(InvoiceActionTypes.Search),
        select: (invoice: Landmark.ContractorDispatchSearchResponseItem) => ({
            type: InvoiceActionTypes.SELECT,
            payload: invoice,
        }),
    };
}

export interface InvoiceActionCreators extends ActionCreatorMap {
    /**
     * Searches for invoices.
     */
    search: AsyncActionCreators<Landmark.ContractorDispatchSearchRequest, Landmark.ContractorDispatchSearchResponse>;

    /**
     * Selects an invoice.
     */
    select: (invoice: Landmark.ContractorDispatchSearchResponseItem) => PayloadAction<Landmark.ContractorDispatchSearchResponseItem>;
}
