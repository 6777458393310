import { withFormik, InjectedFormikProps } from "formik";
import * as Landmark from "models/landmark-api";
import * as React from "react";
import ReactGA from "react-ga";
import { toastr } from "react-redux-toastr";
import { isEmail, isEmpty } from "validator";

import { CallUs } from "../CallUs";
import { DropDown } from "../DropDown";
import { TextBox } from "../TextBox";
import { ValidationModal } from "../ValidationModal";
import { PayloadAction } from "../../actions/defs";
import { BrochureActionCreators, BrochureActionTypes, createBrochureActions } from "../../actions/lead/brochure.actions";
import { createReferenceActions, ReferenceActionCreators, ReferenceActionTypes } from "../../actions/reference.actions";
import { PlanTypeAbbreviation } from "../../constants/TransactionType";
import { getStates } from "../../selectors/reference.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { actionPromise } from "../../utils/redux-promise";

interface Props {
    store?: {
        states: Landmark.State[];
    };
    actions?: {
        brochure: BrochureActionCreators;
        reference: ReferenceActionCreators;
    };
}

interface FormProps {
    actions?: {
        brochure: BrochureActionCreators;
        reference: ReferenceActionCreators;
    };
}

interface FormState {
    hasAttemptedSubmit: boolean;
}

class Form extends React.Component<InjectedFormikProps<FormProps, Landmark.BrochureRequest>, FormState> {
    constructor(props) {
        super(props);

        this.state = {
            hasAttemptedSubmit: false,
        };
    }

    componentWillReceiveProps(newProps: InjectedFormikProps<FormProps, Landmark.BrochureRequest>) {
        if (newProps.isSubmitting && this.props.isSubmitting) {
            return this.setState({ hasAttemptedSubmit: true });
        }
        if(!newProps.isSubmitting && !this.props.isSubmitting) {
            return this.setState({ hasAttemptedSubmit: false });
        }
    }

    render() {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <fieldset style={{ maxWidth: "1020px", marginLeft: "auto", marginRight: "auto" }}>
                    <div className="row">
                        <div className="column half">
                            <label className="field-label light">Name</label>
                            <div className="field-wrap">
                                <TextBox
                                    error={touched.contactName && errors.contactName}
                                    name="contactName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Name..."
                                    value={values.contactName} />
                            </div>
                        </div>
                        <div className="column third">
                            <label className="field-label light">Email</label>
                            <div className="field-wrap">
                                <TextBox
                                    error={touched.email && errors.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Email..."
                                    value={values.email} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column full">
                            <label className="field-label light">Address</label>
                            <div className="field-wrap">
                                <TextBox
                                    error={touched.address && errors.address}
                                    name="address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Address..."
                                    value={values.address} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column third">
                            <label className="field-label light">Zip</label>
                            <div className="field-wrap">
                                <TextBox
                                    error={touched.postalCode && errors.postalCode}
                                    name="postalCode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Zip..."
                                    type="zip"
                                    value={values.postalCode} />
                            </div>
                        </div>
                        <div className="column third">
                            <label className="field-label light">Phone</label>
                            <div className="field-wrap">
                                <TextBox
                                    error={touched.homePhone && errors.homePhone}
                                    name="homePhone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Phone..."
                                    type="tel"
                                    value={values.homePhone} />
                            </div>
                        </div>
                        <div className="column third">
                            <label className="field-label light">Is this a real estate transaction?</label>
                            <div className="field-wrap">
                                <DropDown
                                    listItemsFormatted={[
                                        <option key={0} value={PlanTypeAbbreviation.RealEstate.toString()}>{"Yes"}</option>,
                                        <option key={1} value={PlanTypeAbbreviation.Homeowner.toString()}>{"No"}</option>
                                        // TODO: re-add support for property manager
                                        // <option key={2} value={PlanType.PropertyManager.toString()}>{"Property Manager"}</option>,
                                    ]}
                                    name="leadBrochureTypeId"
                                    onChange={(e: any) => setFieldValue("leadBrochureTypeId", e.target.value)}
                                    style={{ width: "100%", height: "43px" }}
                                    value={values.leadBrochureTypeId} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="row center">
                    <button
                        className="btn-orange"
                        disabled={isSubmitting}
                        onClick={() => {
                            this.props.setFieldValue("language", "en-US");
                            this.setState({ hasAttemptedSubmit: true });
                            window.requestAnimationFrame(this.props.submitForm);
                        }}
                        type="button"
                    >Download</button>
                    <br />
                    <a style={{ color: "white", fontSize: "12px" }}
                        onClick={() => {
                            if (!isSubmitting) {
                                this.props.setFieldValue("language", "es-MX");
                                this.setState({ hasAttemptedSubmit: true });
                                window.requestAnimationFrame(this.props.submitForm);
                            }
                    }} data-icon="j">Descargar la versión española</a>
                </div>
                <ValidationModal
                    errors={errors}
                    isOpen={!isValid && this.state.hasAttemptedSubmit}
                    onRequestClose={() => this.setState({ hasAttemptedSubmit: false })} />
            </form>
        );
    }
}

const RequestBrochureForm = withFormik<FormProps, Landmark.BrochureRequest>({
    mapPropsToValues: props => {
        return {
            leadBrochureTypeId: PlanTypeAbbreviation.RealEstate.toString(),
        };
    },
    // Add a custom validation function
    validate: (values: Landmark.BrochureRequest, props) => {
        let errors: any = {};

        if (!values.contactName || isEmpty(values.contactName)) {
            errors.contactName = "Name is required.";
        }

        if (!values.email) {
            errors.email = "Email address is required.";
        }
        else if (!isEmail(values.email)) {
            errors.email = "Email address is invalid.";
        }

        if (!values.postalCode || values.postalCode.length < 5) {
            errors.postalCode = "Zip code is required.";
        }

        return errors;
    },
    // Submission handler
    handleSubmit: async (
        values: Landmark.BrochureRequest,
        {
            props,
            resetForm,
            setSubmitting,
        }
    ) => {
        // TODO: the GA integration should happen in an epic
        // Send a Google Analytics event that a brochure was requested
        ReactGA.event({
            category: "Lead",
            action: "Brochure Request",
            label: values.leadBrochureTypeId,
        });

        try{
            // Check to see if we even service that zip code.
            await actionPromise<PayloadAction<Landmark.State>>(
                () => props.actions.reference.getStateByZipCode.begin(values.postalCode),
                ReferenceActionTypes.GetStateByZipCode.SUCCESS,
                ReferenceActionTypes.GetStateByZipCode.FAILURE,
            );
        }
        catch (err) {
            toastr.error(
                "No Service", "We're sorry, but we don't (yet) provide service to your zip code.",
                { showCloseButton: true, timeOut: 10000 });
            setSubmitting(false);
            return;
        }

        try {
            const successAction = await actionPromise<PayloadAction<Landmark.BrochureResponse>>(
                () => props.actions.brochure.request.begin(values),
                BrochureActionTypes.Request.SUCCESS,
                BrochureActionTypes.Request.FAILURE,
            );

            // Create a hidden iframe for the content download
            // See http://stackoverflow.com/a/8394118/162907
            let iframe = document.createElement("iframe");
            iframe.setAttribute("src", successAction.payload.url);
            iframe.setAttribute("style", "display: none");
            document.body.appendChild(iframe);

            // Reset the form
            // Setting this one value clears all the fields
            resetForm(values.firstName = "");
        }
        catch (err) {
            // TODO: the toastr integration should happen in an epic
            toastr.error(
                "Download Brochure Error",
                "",
                {
                    timeOut: 10000,
                    component:
                    <p>
                        Error occurred trying to download the brochure. Please call us for assistance at <CallUs />.
                    </p>
                }
            );
        }
        finally {
            setSubmitting(false);
            () => this.setState({ hasAttemptedSubmit: false });
        }
    },
})(Form);

@connect(
    state => ({
        store: {
            states: getStates(state),
        }
    }),
    bindActionCreatorsToProps({
        brochure: createBrochureActions(),
        reference: createReferenceActions(),
    })
)
export class RequestBrochure extends React.Component<Props, any> {
    renderMarketStates = () => {
        const states = this.props.store.states.filter(s => s.isMarketState);
        return states
            .map((s, i) => {
                if (i === 0) {
                    return s.description;
                }
                else {
                    if (i === states.length - 1) {
                        return `, and ${s.description}`;
                    }
                    return `, ${s.description}`;
                }
            });
    }

    renderTitle() {
        return <h1>Download a brochure!</h1>;
    }

    renderSubTitle() {
        return <p>
            Input your information and we'll email you our contract and brochure for homes in {this.renderMarketStates()}.
        </p>;
    }

    render() {
        return (
            <section className="section-cta" id="brochure">
                <div className="container">
                    <div className="row">
                        {this.renderTitle()}
                        {this.renderSubTitle()}
                    </div>
                    <RequestBrochureForm
                        actions={this.props.actions}
                    />
                </div>
            </section>
        );
    }
}
