import * as Landmark from "models/landmark-api";
import * as React from "react";

import { PayloadAction } from "../../actions/defs";
import { createDialogActions, DialogActionCreators } from "../../actions/dialog.actions";
import { createLimitsAndExclusionsActions, LimitsAndExclusionsActionCreators, LimitsAndExclusionsActionTypes } from "../../actions/limitsAndExclusions.actions";
import { Areas } from "../../constants/Areas";
import { createGetIsDialogOpen } from "../../selectors/dialog.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { actionPromise } from "../../utils/redux-promise";
import { scrollTo } from "../../utils/scroll";
import { getIsFeatureEnabled } from "../../selectors/feature.selectors";
import "./ContractOffer.scss";




const styles = {
    pricePerMoYear: {
        color: "#58585B",
        fontFamily: "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', $helvetica !default",
        fontSize: "14px",
        letterSpacing: 1,
    } as React.CSSProperties
    ,
    sellingCoverageAmount: {
        color: "#F2693E",
        fontFamily: "'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', $helvetica !default",
        fontSize: "14px",
        letterSpacing: 1,
    } as React.CSSProperties
};

interface State {
    detailsDisplay: React.CSSProperties;
    detailsHide: React.CSSProperties;
}

interface Props {
    availableCoverages: { [key: string]: Landmark.ContractCoverage[] };
    contractOffer: Landmark.ContractOffer;
    onButtonClick?: (contractOffer: Landmark.ContractOffer) => void;
    button?: React.ReactNode;
    columns?: number;
    href?: string;
    isListingCoverage?: boolean;
    isMonthlyPrice?: boolean;
    isSelected?: boolean;
    loopId?: number;
    stateCode?: string;
    store?: {
        isLimitsAndExclusionsFeatureEnabled: boolean;
        isLimitsAndExclusionsOpen: boolean;
    };
    actions?: {
        limitsAndExclusionsActions: DialogActionCreators;
        limitsAndExclusions: LimitsAndExclusionsActionCreators;
    };
}

@connect(
    state => ({
        store: {
            isLimitsAndExclusionsFeatureEnabled: getIsFeatureEnabled(Landmark.FeatureKey.LimitsAndExclusions.details)(state),
            isLimitsAndExclusionsOpen: createGetIsDialogOpen(Areas.LimitsAndExclusions.Details)(state),
        }
    }),
    bindActionCreatorsToProps({
        limitsAndExclusionsActions: createDialogActions(Areas.LimitsAndExclusions.Details),
        limitsAndExclusions: createLimitsAndExclusionsActions(),
    })
)

export class ContractOffer extends React.Component<Props, State> {
    state = {
        detailsDisplay: {
            display: "inline"
        },
        detailsHide: {
            display: "none"
        }
    };

    static defaultProps = {
        availableCoverages: null,
        contractOffer: null,
        coverages: {},
        button: "ORDER NOW",
        isLimitsAndExclusionsFeatureEnabled: false,
        isListingCoverage: false,
        isMonthlyPrice: true,
    } as Props;

    handleDetailsDisplay = (e) => {
        e.preventDefault();

        if (this.state.detailsDisplay.display === "none") {
            this.setState({
                detailsDisplay: {
                    display: "inline"
                },
                detailsHide: {
                    display: "none"
                }
            });
        }
        else {
            this.setState({
                detailsDisplay: {
                    display: "none"
                },
                detailsHide: {
                    display: "inline"
                }
            });
        }
    }

    handleDetailsDisplayScroll = (e, topAnchorName) => {
        e.preventDefault();
        this.handleDetailsDisplay(e);
        scrollTo(topAnchorName);
    }

    handleLimitsModalPopup = async (e, coverage: Landmark.ContractCoverage) => {
        e.preventDefault();
        await actionPromise<PayloadAction<Landmark.LimitsAndExclusions>>(
            () => this.props.actions.limitsAndExclusions.get.begin(coverage.salesItemCoverageId),
            LimitsAndExclusionsActionTypes.Get.SUCCESS,
            LimitsAndExclusionsActionTypes.Get.FAILURE
        );
        this.props.actions.limitsAndExclusionsActions.open();
    }
    handleOrder = (e) => {
        e.preventDefault();

        if (this.props.onButtonClick) {
            this.props.onButtonClick(this.props.contractOffer);
        }
    }

    renderButton = (contractOffer: Landmark.ContractOffer) => {
        const pricingAndButtonClassName = this.props.columns > 2 ? "column full" : "column half";
        const priceContainerStyle: React.CSSProperties = this.props.columns > 2 ? { textAlign: "center" } : null;
        const price = this.props.isMonthlyPrice ? contractOffer.monthlyCost : contractOffer.cost;
        const precision = this.props.isMonthlyPrice ? 2 : 0;
        let listingCost = 0;
        if(this.props.isListingCoverage){
            switch (this.props.stateCode) {
                case "AZ":
                case "NV":
                    listingCost = 425;
                    break;
                case "TX":
                    listingCost = 425;
                    break;
                case "UT":
                case "ID":
                case "OR":
                    listingCost = 395;
                    break;

            }

        }
        return (
            <div className="row">
                <div className={pricingAndButtonClassName}>
                    <a href={this.props.href} onClick={e => this.handleOrder(e)} className="btn btn-stroke-light">{this.props.button}<i data-icon="j"></i></a>
                </div>
                <div className={`${pricingAndButtonClassName} price-container`} style={priceContainerStyle}>
                    <div className="price-tag standard">
                        {this.props.isListingCoverage ? listingCost : price.toFixed(precision)}
                        {this.props.isListingCoverage ? <span style={styles.sellingCoverageAmount}>/year (not due until COE)</span>:
                        <span style={styles.pricePerMoYear}>/ {this.props.isMonthlyPrice ? "mo" : "year"}</span> }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { availableCoverages, contractOffer, loopId } = this.props;
        const isLimitsAndExclusionsFeatureEnabled = this.props.store.isLimitsAndExclusionsFeatureEnabled;

        if (availableCoverages) {
            const coverageCategories = Object.keys(availableCoverages);

            if (coverageCategories.length > 0) {
                let className: string;
                switch (this.props.columns) {
                    case 1:
                        className = "column full";
                        break;
                    case 2:
                        className = "column half";
                        break;
                    case 3:
                        className = "column third";
                        break;
                    case 4:
                        className = "column fourth";
                        break;
                    case 5:
                        className = "column fifth";
                        break;
                    case 6:
                        className = "column sixth";
                        break;
                }

                let dataIcon = "v";
                if (this.props.isMonthlyPrice) {
                    if (this.props.loopId === 0) {
                        dataIcon = "a";
                    }
                    else if (this.props.loopId === 1) {
                        dataIcon = "<";
                    }
                } else {
                    if (this.props.loopId === 0) {
                        dataIcon = "a";
                    }
                    else if (this.props.loopId === 1) {
                        dataIcon = "q";
                    }
                    else if (this.props.loopId === 2) {
                        dataIcon = "?";
                    }
                    else if (this.props.loopId === 3) {
                        dataIcon = "[";
                    }
                }

                if (this.props.isSelected) {
                    className += " selected";
                }
                let topAnchorName = "topAnchorName" + (loopId ? loopId.toString() : "");
                return (
                    <div className={className}>
                        <div id={topAnchorName} className="pricing-title">
                            <div className="row">
                                <i className="icon standard" data-icon={dataIcon}></i>
                            </div>
                            <div className="row">
                                <h3 className="standard" style={{ margin: 0 }}>{contractOffer.name} {(this.props.isMonthlyPrice ? "PLAN" : null)}</h3>
                            </div>
                            {this.renderButton(contractOffer)}
                        </div>
                        <div className="pricing-details">
                            <a onClick={(e) => this.handleDetailsDisplay(e)} className="detailsDisplayAnchor" style={ this.state.detailsDisplay }>
                                <p style={{ color: "#f2693e", fontSize: "1em", textAlign: "center" }}>
                                    See coverage details
                                    <span data-icon="h" style={{ paddingLeft: "10px", verticalAlign: "-3px" }}></span>
                                </p>
                            </a>
                            <a onClick={(e) => this.handleDetailsDisplay(e)} style={ this.state.detailsHide }>
                                <p style={{ color: "#f2693e", fontSize: "1em", textAlign: "center", marginBottom: "15px" }}>
                                    Hide coverage details
                                    <span data-icon="k" style={{ paddingLeft: "10px", verticalAlign: "-3px" }}></span>
                                </p>
                            </a>
                            {contractOffer.serviceCallPrices.length === 1 ?
                                (
                                    <dl className="detailsDisplayItems" style={ this.state.detailsHide }>
                                        {/* the &#8225; is the double dagger symbol. Could only get to work this way. Will not work when set in a variable. */}
                                        <dt>Service Call Fee<span>&#8225;</span></dt>
                                        <dd>
                                            <span className="cost">${contractOffer.serviceCallPrices[0].fee}</span>
                                        </dd>
                                    </dl>
                                ) :
                                null
                            }
                            {coverageCategories.map(description => {
                                return (
                                    <dl key={description} className="detailsDisplayItems" style={ this.state.detailsHide }>
                                        <dt>{description}</dt>
                                        {availableCoverages[description].map((coverage, index) => {
                                            // If the offer covers the coverage from the overall list, then put a check next to it.
                                            const coverageItem = contractOffer.coverages.find(c => c.id === coverage.id);
                                            const isCovered = coverageItem && (coverageItem.isIncludedByDefault || !coverageItem.isOptional);
                                            if (isCovered) {
                                                return (
                                                    <dd key={index}
                                                        className="item-covered"
                                                        onClick={(e) => isLimitsAndExclusionsFeatureEnabled ? this.handleLimitsModalPopup(e, coverageItem) : "" }
                                                        style={ isLimitsAndExclusionsFeatureEnabled ? { cursor: "pointer" } : { cursor: "text" }}
                                                    >
                                                        <span className="status" data-icon="C"></span>
                                                        <span className="item">{coverage.description}</span>
                                                    </dd>
                                                );
                                            }
                                            else {
                                                return (
                                                    <dd key={index}>
                                                        <span className="status" data-icon="B"></span>
                                                        <span className="item">{coverage.description}</span>
                                                    </dd>
                                                );
                                            }
                                        })}
                                    </dl>
                                );
                            })}
                            <div className="pricing-title detailsDisplayItems" style={ this.state.detailsHide }>
                                {this.renderButton(contractOffer)}
                            </div>
                            <a onClick={(e) => this.handleDetailsDisplayScroll(e, topAnchorName)} style={ this.state.detailsHide }>
                                <p style={{ color: "#f2693e", fontSize: "1em", textAlign: "center",  }}>
                                    Hide coverage details
                                    <span data-icon="k" style={{ paddingLeft: "10px", verticalAlign: "-3px" }}></span>
                                </p>
                            </a>
                        </div>
                    </div>
                );
            }
        }

        return <div></div>;
    }
}
