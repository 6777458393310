import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { AuthActionTypes } from "../actions/auth.actions";

import { PayloadAction } from "../actions/defs";
import { OffersActionTypes } from "../actions/offers.actions";

export interface OffersState  {
    response: Landmark.GetContractOffersResponse;
}

const defaultState: OffersState = {
    response: null,
};

export const OffersReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.GetContractOffersResponse>
) => {
    switch (action.type) {
        case OffersActionTypes.CLEAR: {
            return update(state, {
                response: { $set: null }
            });
        }
        case OffersActionTypes.Load.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.GetContractOffersResponse>;

            return update(state, {
                response: { $set: loadAction.payload }
            });
        }
        case AuthActionTypes.LOGOUT: {
                // Clear our state on logout
                return defaultState;
        }
        default: break;
    }

    // If nothing changed, return the original state
    return state;
};
