import * as Landmark from "models/landmark-api";

import { AsyncActionCreators, createAsyncActions, AsyncAction } from "./defs";
import { ActionCreatorMap } from "store/componentBindings";

export const AwardsActionTypes = {
    LoadAwards: AsyncAction(`awards/load`),
};
Object.freeze(AwardsActionTypes);

export function createAwardsActions() {
    return {
        loadAwards: createAsyncActions(AwardsActionTypes.LoadAwards),
    } as AwardsActionCreators;
}

export interface AwardsActionCreators extends ActionCreatorMap {
    /**
     * Load Landmark awards data from the server.
     */
    loadAwards: AsyncActionCreators<void, Landmark.Award[]>;
}
