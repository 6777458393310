import * as Landmark from "models/landmark-api";
import { LimitsAndExclusions } from "models/landmark-api";

import {
    AsyncAction,
    AsyncActionCreators,
    createAsyncActions,
} from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export interface LimitsAndExclusions {
    name: string;
    factoids?: Landmark.Factoids;
    coveredCoverageList?: Landmark.CoveredCoverageList;
    notCovered?: Landmark.CoverageList;
    limits?: string[];
    legal?: string[];
}

export const LimitsAndExclusionsActionTypes = {
    Get: AsyncAction(`limitsAndExclusions/get`),
};
Object.freeze(LimitsAndExclusionsActionTypes);

export interface LimitsAndExclusionsActionCreators extends ActionCreatorMap {
    /**
     * Gets limits and exclusions for the specified sales item coverage id.
     * @param {LimitsAndExclusions} request The request to get limits and exclusions.
     */
    get: AsyncActionCreators<string, LimitsAndExclusions>;
}

export function createLimitsAndExclusionsActions(): LimitsAndExclusionsActionCreators {
    return {
        get: createAsyncActions(LimitsAndExclusionsActionTypes.Get),
    };
}
