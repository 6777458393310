import * as React from "react";

const styles = {
    center: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
    } as React.CSSProperties,
    divColumnLeftVerticalRule: {
        borderLeft: "2px solid #AAAAAA",
        minHeight: "450px",
    } as React.CSSProperties,
    divColumnLeftInvisibleVerticalRule: {
        borderLeft: "2px solid #E0E0E0",
        minHeight: "450px",
    } as React.CSSProperties,
    imageFloatLeft: {
        float: "left",
    } as React.CSSProperties,
    textHeaderJustifyCenterLast: {
        textAlign: "justify", /* For Edge */
        textAlignLast: "center",
        fontSize: "10pt",
    } as React.CSSProperties,
    textJustifyCenterLast: {
        textAlign: "justify", /* For Edge */
        textAlignLast: "center",
        fontSize: "10pt",
        display: "block",
        verticalAlign: "middle",
        marginBottom: "0px",
        marginLeft: "10px",
    } as React.CSSProperties,
    divImageVerticalCenter: {
        display: "table-cell",
        width: "30%",
        verticalAlign: "middle",
    } as React.CSSProperties,
    divParagraphVerticalCenter: {
        display: "table-cell",
        width: "70%",
        verticalAlign: "middle",
    } as React.CSSProperties,
};

export class WhyRealtorsChooseLandmark extends React.Component<any, any> {
    getImage = (imageName: string) => {
        const image = require(`../../content/images/reviews/${imageName}`);
        return image;
    }

    render() {
        return (
            <div className="container">
                <h1>Why Real Estate Professionals Choose Landmark:</h1>
                <p className="hiddenIfTablet">Why do real estate agents prefer Landmark Home Warranty over other home warranty companies? We’re a licensed residential service contract company through the Texas Real Estate Commission,  the Texas Home Service Contract Association, and the National Home Service Contract Association. On top of that, Landmark Home Warranty is dedicated to providing the best customer service possible, and it shows through our awards!</p>
                <p className="visibleIfTablet" style={styles.textHeaderJustifyCenterLast}>Why do real estate agents prefer Landmark Home Warranty over other home warranty companies? We’re a licensed residential service contract company through the Texas Real Estate Commission,  the Texas Home Service Contract Association, and the National Home Service Contract Association. On top of that, Landmark Home Warranty is dedicated to providing the best customer service possible, and it shows through our awards!</p>
                <div className="row wrapper hiddenIfTablet" style={{ backgroundColor: "#E0E0E0", padding: "30px 30px 10px 0px" }}>
                    <div className="column fourth">
                        <div style={styles.divColumnLeftInvisibleVerticalRule}>
                            <div style={{marginLeft: "33px"}}>
                                <img style={styles.center} src={this.getImage("national-home-contract-association.png")} alt="Regulated by the National Home Service Contract Association." />
                                <p style={{textAlign: "justify"}}>
                                    Landmark Home Warranty is a licensed member of the National Home Service Contract Association. NHSCA provides clear standards for its members as well as education for the public about home warranties.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column fourth">
                        <div style={styles.divColumnLeftVerticalRule}>
                            <div style={{marginLeft: "33px"}}>
                                <img style={styles.center} src={this.getImage("texas-home-services-contract-association.png")} alt="Regulated by Texas Real Estate Commission." />
                                <p style={{textAlign: "justify"}}>
                                    Landmark Home Warranty is also a licensed member of the Texas Home Service Contract Association. THSCA provides standards for residential service contract companies in Texas.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column fourth">
                        <div style={styles.divColumnLeftVerticalRule}>
                            <div style={{marginLeft: "33px"}}>
                                <img style={styles.center} src={this.getImage("residential-service-contract-license.png")} alt="Regulated by the Texas Home Services Contract Association." />
                                <p style={{textAlign: "justify"}}>
                                    Landmark Home Warranty is a licensed residential service contract company in Texas through the Texas Real Estate Commission.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column fourth">
                        <div style={styles.divColumnLeftVerticalRule}>
                            <div style={{marginLeft: "33px"}}>
                                <img style={styles.center} src={this.getImage("best-regional-home-warranty.png")} alt="Landmark has been awarded Best Regional Home Warranty since 2013." />
                                <p style={{textAlign: "justify"}}>
                                    Landmark Home Warranty was awarded the Best Home Warranty in the Region in 2014, 2015, and 2016 by Home Warranty Reviews. They base this award on customer service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="visibleIfTablet" style={{ backgroundColor: "#E0E0E0", padding: "20px 20px 20px 20px" }}>
                    <div style={{ marginBottom: "15px" }}>
                        <div style={{ overflow: "auto" }}>
                            <div style={styles.divImageVerticalCenter}>
                                <img style={styles.imageFloatLeft} src={this.getImage("national-home-contract-association.png")} alt="Regulated by the National Home Service Contract Association." />
                            </div>
                            <div style={styles.divParagraphVerticalCenter}>
                                <p style={styles.textJustifyCenterLast}>
                                    Landmark Home Warranty is a licensed member of the National Home Service Contract Association. NHSCA provides clear standards for its members as well as education for the public about home warranties.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: "2px solid #AAAAAA" }}>
                        <div style={{ overflow: "auto", marginTop: "20px", marginBottom: "15px" }}>
                            <div style={styles.divImageVerticalCenter}>
                                <img style={styles.imageFloatLeft} src={this.getImage("texas-home-services-contract-association.png")} alt="Regulated by Texas Real Estate Commission." />
                            </div>
                            <div style={styles.divParagraphVerticalCenter}>
                                <p style={styles.textJustifyCenterLast}>
                                    Landmark Home Warranty is also a licensed member of the Texas Home Service Contract Association. THSCA provides standards for residential service contract companies in Texas.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: "2px solid #AAAAAA" }}>
                        <div style={{ overflow: "auto", marginTop: "5px" }}>
                            <div style={styles.divImageVerticalCenter}>
                                <img style={styles.imageFloatLeft} src={this.getImage("residential-service-contract-license.png")} alt="Regulated by the Texas Home Services Contract Association." />
                            </div>
                            <div style={styles.divParagraphVerticalCenter}>
                                <p style={styles.textJustifyCenterLast}>
                                    Landmark Home Warranty is a licensed residential service contract company in Texas through the Texas Real Estate Commission.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderTop: "2px solid #AAAAAA" }}>
                        <div style={{ overflow: "auto", marginTop: "10px" }}>
                            <div style={styles.divImageVerticalCenter}>
                                <img style={styles.imageFloatLeft} src={this.getImage("best-regional-home-warranty.png")} alt="Landmark has been awarded Best Regional Home Warranty since 2013." />
                            </div>
                            <div style={styles.divParagraphVerticalCenter}>
                                <p style={styles.textJustifyCenterLast}>
                                    Landmark Home Warranty was awarded the Best Home Warranty in the Region in 2014, 2015, and 2016 by Home Warranty Reviews. They base this award on customer service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 style={{ color: "#F2693E", marginTop: "20px" }}>Real Estate Reviews</h2>
                <p>Still need convincing? Here's what other real estate professionals are saying about Landmark Home Warranty:</p>
            </div>
        );
    }
}
