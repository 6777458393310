import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { OrderActionTypes } from "../../actions/order.actions";
import { ContractActionTypes } from "../../actions/account/contracts.actions";

export interface ContractsState {
    coverages: Landmark.ContractCoverage[];
    list: Landmark.Contract[];
    orderResponse: Landmark.OrderResponse;
    selectedContractId?: number;
}

const defaultState: ContractsState = {
    coverages: null,
    list: [],
    orderResponse: null,
    selectedContractId: null,
};

export function ContractsReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.ContractResponse |
            Landmark.ContractCoverage[]
        >
) {
    switch (action.type) {

        case ContractActionTypes.Load.SUCCESS: {
            const response = (action as PayloadAction<Landmark.ContractResponse>).payload;
            return update(state, {
                list: { $set: response.contracts },
            });
        }

        case ContractActionTypes.LoadCoverages.SUCCESS: {
            const response = (action as PayloadAction<Landmark.CoverageResponse>).payload;
            return update(state, {
                coverages: { $set: response.coverages }
            });
        }

        case OrderActionTypes.Submit.SUCCESS: {
            return update(state, {
                orderResponse: { $set: (action as PayloadAction<Landmark.OrderResponse>).payload }
            });
        }

        case ContractActionTypes.SELECT: {
            return update(state, {
                selectedContractId: { $set: (action as PayloadAction<number>).payload }
            });
        }
    }

    // If nothing changed, return the original state
    return state;
}
