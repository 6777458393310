import { map, filter } from "rxjs/operators";
import { pipe } from "rxjs";

/**
 * Takes a mapping function and filters empty results.
 * Equivalant to switchMap to an empty observable.
 *
 * @export
 * @template T - The input value of the operator.
 * @template U - The output value of the operator.
 * @param {(action: T, index: number) => U} mapFunction - Function that maps the input type to the output type.
 * @returns - The result of the mapping if the value is not empty. If it is empty, the stream is cancelled.
 */
export function mapWithEmptyFilter<T, U>(mapFunction: (input: T, index: number) => U) {
    return pipe(
        map<T, U>(mapFunction),
        filter<U>(Boolean)
    );
}
