import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { ContractorSearchActionTypes } from "../../actions/contractor/search.actions";

export interface ContractorSearchState {
    searchResults: Landmark.ContractorSearchResponse[];
    selectedContractorId: any;
}

const defaultState: ContractorSearchState = {
    searchResults: [],
    selectedContractorId: null,
};

/**
 * A reducer, which takes results of actions and updates the application state accordingly.
 */
export const ContractorSearchReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.ContractorSearchResponse> |
        PayloadAction<Landmark.ContractorSearchResponse[]>
) => {
    switch (action.type) {
        case ContractorSearchActionTypes.RESET: {
            return defaultState;
        }

        case ContractorSearchActionTypes.SELECT: {
            const contractor = (action as PayloadAction<Landmark.ContractorSearchResponse>).payload;
            return update(state, {
                selectedContractorId: { $set: contractor.contractorId }
            });
        }

        case ContractorSearchActionTypes.Search.SUCCESS: {
            const contractors = (action as PayloadAction<Landmark.ContractorSearchResponse[]>).payload;
            return update(state, {
                searchResults: { $set: contractors }
            });
        }
    }

    return state;
};
