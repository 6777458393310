import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { LoadContractorStatisticsPayload } from "../actions/contractor/dispatchStatistics.actions";
import { ContractorSearchActionTypes, createContractorSearchActions } from "../actions/contractor/search.actions";
import { createCrrActions, CrrActionTypes, LoadContractorRelationsStatisticsPayload } from "../actions/crr.actions";
import { PayloadAction } from "../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const crrActions = createCrrActions();
const contractorSearchActions = createContractorSearchActions();

const handleLoadCrrStatistics: Epic<
    PayloadAction<LoadContractorRelationsStatisticsPayload | Landmark.DispatchStatisticsResponse | Error>
> = action$ => action$.pipe(
    ofType(CrrActionTypes.loadDispatchStatistics.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<LoadContractorRelationsStatisticsPayload>) => LandmarkApiService
            .get(`/contractorRelations/statistics/${action.payload.username}?year=${action.payload.year}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => crrActions.loadDispatchStatistics.success(payload),
        err => crrActions.loadDispatchStatistics.failure(err),
    ),
);

const handleLoadContractorStatistics: Epic<
    PayloadAction<LoadContractorStatisticsPayload | Landmark.DispatchStatisticsResponse | Error>
> = action$ => action$.pipe(
    ofType(CrrActionTypes.loadContractorDispatchStatistics.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<LoadContractorStatisticsPayload>) => LandmarkApiService
            .get(`/contractorRelations/contractorStatistics/${action.payload.contractorId}?year=${action.payload.year}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => crrActions.loadContractorDispatchStatistics.success(payload),
        err => crrActions.loadContractorDispatchStatistics.failure(err),
    ),
);

const handleLoadContractorSearch: Epic<
    PayloadAction<string | Error | Landmark.ContractorSearchResponse[]>
> = action$ => action$.pipe(
    ofType(ContractorSearchActionTypes.Search.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .post(`/contractorRelations/search`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        contractorSearchActions.search.success,
        contractorSearchActions.search.failure,
    ),
);

// TODO - We brought this down from 6 minutes to 3 minutes to just around 30 seconds; Bring the wait timeout to a minute just in case.
const waitLoadCrrStatistics = createWaitEpic(CrrActionTypes.loadDispatchStatistics, undefined, 60000);
const waitLoadContractorStatistics = createWaitEpic(CrrActionTypes.loadContractorDispatchStatistics);
const waitSearchContractor = createWaitEpic(ContractorSearchActionTypes.Search);

const epic = combineEpics(
    handleLoadCrrStatistics,
    handleLoadContractorStatistics,
    handleLoadContractorSearch,
    waitLoadCrrStatistics,
    waitLoadContractorStatistics,
    waitSearchContractor,
);
export default epic;
