import * as React from "react";

import "./LogoBanner.scss";

const styles = {
    pointer: {
        cursor: "pointer",
    }
};

export class LogoBanner extends React.Component {

    render() {
        return (
            <section className="section-logos">
                <div className="container">
                    <div className="item-row">
                        <div className="item active">
                            <img
                                    className="grayscale bannerimage bbb"
                                    src={require("../content/images/consumers-choice.png")}
                                />
                        </div>
                        <div className="item active">
                            <a
                                href="http://www.homewarrantyreviews.com/awards"
                                rel="nofollow"
                                style={styles.pointer}
                                target="_blank"
                            >
                                <img
                                    className="grayscale bannerimage bbb"
                                    src={require("../content/images/img-best-regional-home-warranty-award-color-years.png")}
                                    title="Landmark has been named the best home warranty company in the region for many consecutive years"
                                />
                            </a>
                        </div>
                        <div className="item active">
                            <img
                                    className="grayscale bannerimage bbb"
                                    src={require("../content/images/best-localized.png")}
                                />
                        </div>
                        <div className="item active">
                            <img
                                    className="grayscale bannerimage bbb"
                                    src={require("../content/images/awards/home-advisor.png")}
                                />
                        </div>
                        {/*<div className="item active">
                            <a
                                href="http://www.bbb.org/utah/business-reviews/home-warranty-plans/landmark-home-warranty-llc-in-riverton-ut-22241912/"
                                rel="nofollow"
                                style={styles.pointer}
                                target="_blank"
                            >
                                <img
                                    className="grayscale bannerimage bbb"
                                    src={require("../content/images/img-bbb-color.png") }
                                    title="Landmark Home Warranty is a Better Business Bureau Accredited Company"
                                />
                            </a>
                        </div>*/}
                    </div>
                </div>
            </section>
        );
    }
}
