import * as Landmark from "models/landmark-api";

import { AsyncAction, AsyncActionCreators, createAsyncActions, appId, PayloadAction } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const AccountInvoicesActionTypes = {
    Cancel: AsyncAction(`account/invoice`),
    Complete: AsyncAction(`account/invoice/complete`),
    Load: AsyncAction(`account/invoice/load`),
    SelectInvoice: `${appId}/account/invoice/SELECT`,
    SET_PAYMENT_INFO: `${appId}/account/invoice/paymentInfo/SET`,
    Payment: AsyncAction("account/invoices/payment"),
};

export interface AccountInvoicesActionCreators extends ActionCreatorMap {
    /**
     * Cancels an invoice, given an invoice number.
     */
    cancel: AsyncActionCreators<string, Landmark.InvoiceResult>;

    /**
     * Completing and un-paid invoice
     */
    complete: AsyncActionCreators<Landmark.CompleteInvoiceRequest, Landmark.CompleteInvoiceResponse>;

    /**
     * Loads partner's invoice information.
     */
    load: AsyncActionCreators<string[], Landmark.Invoice[]>;

    /**
     *  Sets the selected invoice for viewing coverage.
     */
    selectInvoice: (invoice: Landmark.Invoice) => PayloadAction<Landmark.Invoice>;

    /**
     * Sets the payment info for the service request.
     */
    setPaymentInfo: (paymentInfo: Landmark.PaymentInfo) => PayloadAction<Landmark.PaymentInfo>;

    /**
     * Processes a credit card payment.
     */
    payment: AsyncActionCreators<Landmark.PaymentRequest, Landmark.PaymentResponse>;
}

export function createAccountInvoicesActions(): AccountInvoicesActionCreators {
    return {
        cancel: createAsyncActions(AccountInvoicesActionTypes.Cancel),
        complete: createAsyncActions(AccountInvoicesActionTypes.Complete),
        load: createAsyncActions(AccountInvoicesActionTypes.Load),
        payment: createAsyncActions(AccountInvoicesActionTypes.Payment),
        selectInvoice: (invoice: Landmark.Invoice) => {
            return {
                type: AccountInvoicesActionTypes.SelectInvoice,
                payload: invoice,
            };
        },
        setPaymentInfo: (paymentInfo: Landmark.PaymentInfo) => {
            return {
                type: AccountInvoicesActionTypes.SET_PAYMENT_INFO,
                payload: paymentInfo,
            };
        }
    };
}
