import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { ContractorDashboardActionTypes, createContractorDashboardActions, LoadCommentsPayload, LoadContractorsOwnStatisticsPayload } from "../../actions/contractor/dashboard.actions";
import { PayloadAction } from "../../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const contractorDashboardActions = createContractorDashboardActions();

const handleLoadContractorsOwnStatistics: Epic<
    PayloadAction<LoadContractorsOwnStatisticsPayload | Landmark.DispatchStatisticsResponse | Error>
> = action$ => action$.pipe(
    ofType(ContractorDashboardActionTypes.LoadScores.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<LoadContractorsOwnStatisticsPayload>) => LandmarkApiService
            .get(`/contractor/dispatchStatistics/scores/${action.payload.webUserId}?year=${action.payload.year}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => contractorDashboardActions.loadScores.success(payload),
        err => contractorDashboardActions.loadScores.failure(err),
    ),
);

const handleLoadVOCComments: Epic<
    PayloadAction<LoadCommentsPayload | Landmark.VOCCommentsResponse[] | Error>
> = action$ => action$.pipe(
    ofType(ContractorDashboardActionTypes.LoadComments.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<LoadCommentsPayload>) => LandmarkApiService
            .get(`/contractor/dispatchStatistics/voc/${action.payload.userId}?year=${action.payload.year}&month=${action.payload.month}&trade=${encodeURI(action.payload.trade)}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        contractorDashboardActions.loadComments.success,
        contractorDashboardActions.loadComments.failure,
    ),
);

const waitLoadContractorsOwnStatistics = createWaitEpic(ContractorDashboardActionTypes.LoadScores);
const waitLoadVOCComments = createWaitEpic(ContractorDashboardActionTypes.LoadComments);

const epic = combineEpics(
    handleLoadVOCComments,
    handleLoadContractorsOwnStatistics,
    waitLoadVOCComments,
    waitLoadContractorsOwnStatistics,

);
export default epic;
