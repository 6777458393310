import { all, fork, takeEvery } from "redux-saga/effects";
import ReactGA from "react-ga";

import environment from "environment";

let isInitialized = false;
const googleAnalyticsKey = environment.googleAnalyticsKey;
const isGoogleDebugEnabled = environment.isGoogleAnalyticsDebugEnabled;

// Any time we change routes (url location) through the router we want to track
// that change in Google Analytics
function* handleRouteChange(action) {
    if (!isInitialized) {
        isInitialized = true;

        // Initialize the 'react google analytics' component that will handle
        // all of the magic for us.
        // See: https://github.com/react-ga/react-ga
        ReactGA.initialize(
            googleAnalyticsKey, // Google Analytics tracking number
            { debug: isGoogleDebugEnabled }
        );
    }

    ReactGA.set({ page: action.payload.location.pathname });
    // Add the search (query string) or hash (anchor) to the end of the path to include these in our tracking
    // See: http://mediacause.org/track-anchor-tags-google-analytics-2/
    ReactGA.pageview(action.payload.location.pathname + action.payload.location.search + action.payload.location.hash);
}

function* watchRouteChanges() {
    yield takeEvery("@@router/LOCATION_CHANGE", handleRouteChange);
}

export default function* root() {
    yield all([
        fork(watchRouteChanges),
    ]);
}
