import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { ContractorStatisticsActionTypes, createContractorDispatchStatisticsActions, LoadContractorStatisticsPayload } from "../../actions/contractor/dispatchStatistics.actions";
import { PayloadAction } from "../../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const contractorStatisticsActions = createContractorDispatchStatisticsActions();

const handleLoadContractorStatistics: Epic<
    PayloadAction<LoadContractorStatisticsPayload | Landmark.DispatchStatisticsResponse | Error>
> = action$ => action$.pipe(
    ofType(ContractorStatisticsActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<LoadContractorStatisticsPayload>) => LandmarkApiService
            .get(`/contractorRelations/contractorStatistics/${action.payload.contractorId}?year=${action.payload.year}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => contractorStatisticsActions.load.success(payload),
        err => contractorStatisticsActions.load.failure(err),
    ),
);

const waitLoadContractorStatistics = createWaitEpic(ContractorStatisticsActionTypes.Load);

const epic = combineEpics(
    handleLoadContractorStatistics,
    waitLoadContractorStatistics,
);
export default epic;
