import * as React from "react";
import * as ReactRedux from "react-redux";

export interface ScrollPosition {
    currentStep?: number;
    top?: number;
}

interface ScrollState {
    scroll?: ScrollPosition;
}

export function withScrollTracking<TOriginalProps, TOwnProps>(steps: number = 10): ReactRedux.InferableComponentDecorator {
    return <P extends ScrollState>(DecoratedComponent: React.ComponentClass<P> | any): React.ComponentClass<P> => {
        return class extends React.Component<P, ScrollState> {
            constructor(props) {
                super(props);

                this.state = {
                    scroll: {
                        currentStep: 0,
                        top: 0,
                    }
                };

                window.addEventListener("scroll", this.handleScroll);
            }

            componentWillUnmount() {
                window.removeEventListener("scroll", this.handleScroll);
            }

            handleScroll = (e) => {
                const top = window.document.body.scrollTop || window.document.documentElement.scrollTop;
                const currentStep = Math.floor(top / steps);
                if (currentStep !== this.state.scroll.currentStep) {
                    this.setState({
                        scroll: { currentStep, top }
                    });
                }
            }

            render() {
                return (
                    <DecoratedComponent {...this.props} scroll={this.state.scroll} />
                );
            }
        };
    };
}
