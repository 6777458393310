import { Wait } from "components/Wait";
import * as Landmark from "models/landmark-api";
import * as React from "react";
import { createGetIsWaitingForArea } from "selectors/wait.selectors";
import { CategoryActionCreators, createCategoryActions } from "../../actions/category.actions";
import { createLocationActions, LocationActionCreators } from "../../actions/location.actions";
import { createOffersActions, OffersActionCreators } from "../../actions/offers.actions";
import { createOrderActions, OrderActionCreators } from "../../actions/order.actions";
import { createReferenceActions, ReferenceActionCreators } from "../../actions/reference.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { Areas } from "../../constants/Areas";
import { PlanType, PlanTypeName } from "../../constants/TransactionType";
import { getAvailableCoverages, getOffers } from "../../selectors/offers.selectors";
import {
    getIsConvertingFromListingCoverage,
    getIsListingCoverage,
    getOrderContractId,
    getOrderProperty,
    getSelectedOfferId,
    getSelectedOrderArea
} from "../../selectors/order.selectors";
import { ApplicationState } from "../../store/app";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { ChooseState } from "../ChooseState";
import { ContractOffer } from "./ContractOffer";


interface Props {
    store?: {
        availableCoverages: { [key: string]: Landmark.ContractCoverage[] };
        contractId: string;
        isConvertingFromListingCoverage: boolean;
        isListingCoverage: boolean;
        isWaitingFor: {
            offers: boolean;
        };
        offers: Landmark.ContractOffer[];
        property: Landmark.Property;
        selectedOfferId: string;
        tab: string;
    };

    actions?: {
        location: LocationActionCreators;
        offers: OffersActionCreators;
        order: OrderActionCreators;
        reference: ReferenceActionCreators;
        router: RouterActionCreators;
        tab: CategoryActionCreators;
    };

    isMonthlyPrice?: boolean;
    planTypeId: string;
    onOrder?: (contractOfferId: string) => void;
}

class Controls {
    state: HTMLSelectElement;
}

@connect(
    (state: ApplicationState) => ({
        store: {
            availableCoverages: getAvailableCoverages(state),
            contractId: getOrderContractId(state),
            isConvertingFromListingCoverage: getIsConvertingFromListingCoverage(state),
            isListingCoverage:getIsListingCoverage(state),
            isWaitingFor: {
                offers: createGetIsWaitingForArea(Areas.Offer.OfferPackages)(state),
            },
            offers: getOffers(state),
            property: getOrderProperty(state),
            selectedOfferId: getSelectedOfferId(state),
            tab: getSelectedOrderArea(state),
        }
    }),
    bindActionCreatorsToProps({
        location: createLocationActions(),
        offers: createOffersActions(),
        order: createOrderActions(),
        reference: createReferenceActions(),
        router: createRouterActions(),
        tab: createCategoryActions(Areas.Order.Page),
    })
)
export class OfferPackages extends React.Component<Props, any> {
    static defaultProps = {
        isMonthlyPrice: true,
    };

    controls = new Controls();

    constructor(props: Props) {
        super(props);

        const { address, dwellingTypeId } = props.store.property;

        this.loadContractOffers(address.state, props.planTypeId, dwellingTypeId);

        props.actions.reference.loadStates.begin();
        props.actions.location.get.begin();
    }

    componentWillReceiveProps(newProps: Props) {
        const { property: newProperty } = newProps.store;
        const { property: oldProperty } = this.props.store;

        const stateCodeChanged = newProperty.address.state !== oldProperty.address.state;
        const planTypeIdChanged = newProps.planTypeId !== this.props.planTypeId;
        const dwellingTypeIdChanged = newProperty.dwellingTypeId !== oldProperty.dwellingTypeId;

        if (stateCodeChanged || planTypeIdChanged || dwellingTypeIdChanged) {
            this.loadContractOffers(newProperty.address.state, newProps.planTypeId, newProperty.dwellingTypeId);
        }
    }

    handleOrder = (contractOfferId: string) => {
        // Need to set the package tab to complete in order for the tabs for work correctly.
        this.props.actions.tab.complete("package", true);
        this.props.onOrder(contractOfferId);
    }

    loadContractOffers = (stateCode: string, planTypeId: string, dwellingTypeId: string) => {
        const contractId = this.props.store.contractId;
        if (stateCode && dwellingTypeId && planTypeId) {
            // Only load contract offers if we have enough information to do so!
            this.props.actions.offers.load.begin({
                contractId: contractId,
                dwellingTypeId,
                isConvertingFromListingCoverage: this.props.store.isConvertingFromListingCoverage,
                isListingCoverage: this.props.store.isListingCoverage,
                planTypeId,
                stateCode
            });
        }
        else {
            // We need to clear out any offers that maybe hanging around.
            this.props.actions.offers.clear();
        }
    }

    renderContractPackages = () => {
        const { offers, availableCoverages } = this.props.store;
        if (offers) {
            const planTypeId = this.props.planTypeId;
            let url = `/order/${PlanTypeName.Homeowner}`;
            if (planTypeId === PlanType.RealEstate) {
                url = `/order/${PlanTypeName.RealEstate}`;
            }
            return (
                <div className="row border">
                    {offers.map((offer, index, array) => (
                        <ContractOffer
                            key={index}
                            availableCoverages={availableCoverages}
                            contractOffer={offer}
                            columns={offers.length}
                            href={url}
                            isMonthlyPrice={this.props.isMonthlyPrice}
                            stateCode = {this.props.store.property.address.state}
                            isSelected={this.props.store.selectedOfferId === offer.contractOfferId}
                            onButtonClick={() => this.handleOrder(offer.contractOfferId)}
                        />
                    ))}
                </div>
            );
        }
        return null;
    }

    render() {
        const realEstateText = <p>These plans are for home buyers who are purchasing their home through a real estate
            transaction within 30 days. For homeowners who have owned their home for more than 30 days,
            please go to our <a onClick={() => this.props.actions.router.push(`/homeowner-warranty#home-warranty-plans`)}>homeowner plans page here</a>.
            Cost based on a single-family residence under 5,000 square feet. Continue onto our order page for more property options and prices.</p>;

        const consumerText = <p>These plans are for homeowners who have lived in their homes for more than 30 days.
            If you are a real estate agent or have lived in your home less than 30 days, please see our new <a onClick={() => this.props.actions.router.push(`/home-buyers-warranty#home-warranty-plans`)}>home buyer
            plans here</a>. Cost based on a
            single-family residence under 5,000 square feet. Continue onto our order page for more property options and prices.</p>;

        return (
            <Wait isWaiting={this.props.store.isWaitingFor.offers}>
                <section id="home-warranty-plans" className="section-plans">
                    <div className="container">
                        <form>
                            <fieldset>
                                <div className="pricing-copy">
                                    <h1 className="homeowner">{this.props.planTypeId === PlanType.RealEstate ? "Home Buyer Warranty Protection Plans" : "Compare Our Home Warranty Plans "}</h1>
                                    {this.props.planTypeId === PlanType.RealEstate ? realEstateText : consumerText}
                                    <ChooseState
                                        value={this.props.store.property.address.state || ""}
                                        showAllStates={false}
                                        onChange={(e: any) => this.props.actions.order.setValues({
                                            property: {
                                                address: { state: e.target.value }
                                            }
                                        })} />
                                </div>

                                {this.renderContractPackages()}
                            </fieldset>
                        </form>
                    </div>
                </section>
            </Wait>
        );
    }
}
