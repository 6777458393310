import * as React from "react";

const styles = {
    container: {
        height: "100%",
        position: "relative",
        overflow: "hidden",
    } as React.CSSProperties,
    panel: translate => ({
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        transitionDuration: "0.3s",
        transitionProperty: "transform",
        transitionTimingFunction: "ease-in-out",
        transform: translate
    }) as React.CSSProperties
};

interface Props {
    children?: any;
    onChange?: (selectedIndex: number) => void;
    selectedIndex?: number;
}

interface State {
    selectedIndex: number;
}

export class Slider extends React.Component<Props, State> {
    static defaultProps: Props = {
        selectedIndex: 0
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIndex: props.selectedIndex || 0
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        // Determine if the selected index changed in the store/props
        if (this.props.selectedIndex !== nextProps.selectedIndex) {
            this.setState({
                selectedIndex: nextProps.selectedIndex
            });
        }
    }

    get count() {
        return this.props.children.length || (this.props.children as any).count();
    }

    get selectedIndex() {
        return this.state.selectedIndex;
    }

    set selectedIndex(value: number) {
        // Ensure the selected index is actually changing
        if (this.state.selectedIndex !== value) {
            if (this.props.onChange) {
                this.props.onChange(value);
            }
            else {
                this.setState({
                    selectedIndex: value
                });
            }
        }
    }

    next = () => {
        if (this.state.selectedIndex < this.count - 1) {
            // Move to the next panel
            this.selectedIndex++;
        }
        else {
            this.selectedIndex = 0;
        }
    }

    prev = () => {
        if (this.state.selectedIndex > 0) {
            // Move to the previous panel
            this.selectedIndex--;
        }
        else {
            this.selectedIndex = this.count - 1;
        }
    }

    renderPanel = (panel, index) => {
        let translate = "translate(0, 0)";
        if (index < this.state.selectedIndex) {
            translate = "translate(-100%, 0)";
        }
        else if (index > this.state.selectedIndex) {
            translate = "translate(100%, 0)";
        }

        return (
            <div style={styles.panel(translate)}>{panel}</div>
        );
    }

    render() {
        return (
            <div style={styles.container}>
                {React.Children.map(this.props.children, this.renderPanel)}
            </div>
        );
    }
}
