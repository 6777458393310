import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { withLatestFrom } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { createReferenceActions, ReferenceActionTypes } from "../actions/reference.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { getPropertyTypes, getStates, getTrades } from "../selectors/reference.selectors";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { ApplicationState } from "../store/app";
import { createWaitEpic } from "./wait.epic";

const referenceActions = createReferenceActions();

const handleGetStateByZipCode: Epic<
    PayloadAction<string | Error | Landmark.State>
> = action$ => action$.pipe(
    ofType(ReferenceActionTypes.GetStateByZipCode.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/reference/stateByZipCode/${action.payload}`)
            .fetch()
            .then(response => response.json),
        referenceActions.getStateByZipCode.success,
        referenceActions.getStateByZipCode.failure,
    ),
);

const handleLoadPropertyTypes: Epic<
    PayloadAction<void | Error | Landmark.PropertyType[]>
> = (action$, state$) => action$.pipe(
    ofType(ReferenceActionTypes.LoadPropertyTypes.BEGIN),
    withLatestFrom(state$),
    switchMapWithPromiseToActions(
        async ([action, state]: [Action, ApplicationState]) => {
            // Determine if we've already loaded data.  If so, skip the API call...
            const propertyTypes = getPropertyTypes(state);
            if (propertyTypes && propertyTypes.length > 0) {
                return propertyTypes;
            }
            return await LandmarkApiService
                .get("/reference/propertyTypes")
                .fetch()
                .then(response => response.json);
        },
        referenceActions.loadPropertyTypes.success,
        referenceActions.loadPropertyTypes.failure,
    ),
);

const handleLoadStates: Epic<
    PayloadAction<void | Error | Landmark.State[]>
> = (action$, state$) => action$.pipe(
    ofType(ReferenceActionTypes.LoadStates.BEGIN),
    withLatestFrom(state$),
    switchMapWithPromiseToActions(
        async ([action, state]: [Action, ApplicationState]) => {
            // Determine if we've already loaded data.  If so, skip the API call...
            const states = getStates(state);
            if (states && states.length > 0) {
                return states;
            }
            return await LandmarkApiService
                .get("/reference/states")
                .fetch()
                .then(response => response.json);
        },
        referenceActions.loadStates.success,
        referenceActions.loadStates.failure,
    ),
);

const handleLoadTrades: Epic<
    PayloadAction<void | Error | Landmark.Trade[]>
> = (action$, state$) => action$.pipe(
    ofType(ReferenceActionTypes.LoadTrades.BEGIN),
    withLatestFrom(state$),
    switchMapWithPromiseToActions(
        async ([action, state]: [Action, ApplicationState]) => {
            // Determine if we've already loaded data.  If so, skip the API call...
            const trades = getTrades(state);
            if (trades && trades.length > 0) {
                return trades;
            }
            return await LandmarkApiService
                .get("/reference/trades")
                .fetch()
                .then(response => response.json);
        },
        referenceActions.loadTrades.success,
        referenceActions.loadTrades.failure,
    ),
);

const handleLoadZipCodes: Epic<
    PayloadAction<string | Error | Landmark.ZipCode[]>
> = action$ => action$.pipe(
    ofType(ReferenceActionTypes.LoadZipCodes.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`zipcode/${action.payload}`)
            .fetch()
            .then(response => response.json),
        referenceActions.loadZipCodes.success,
        referenceActions.loadZipCodes.failure,
    ),
);

const waitForGetStateByZipCode = createWaitEpic(ReferenceActionTypes.GetStateByZipCode);
const waitForLoadPropertyTypes = createWaitEpic(ReferenceActionTypes.LoadPropertyTypes);
const waitForLoadStates = createWaitEpic(ReferenceActionTypes.LoadStates);
const waitForLoadTrades = createWaitEpic(ReferenceActionTypes.LoadTrades);
const waitForLoadZipCodes = createWaitEpic(ReferenceActionTypes.LoadZipCodes);

const epic = combineEpics(
    handleGetStateByZipCode,
    handleLoadPropertyTypes,
    handleLoadStates,
    handleLoadTrades,
    handleLoadZipCodes,
    waitForGetStateByZipCode,
    waitForLoadPropertyTypes,
    waitForLoadStates,
    waitForLoadTrades,
    waitForLoadZipCodes,
);
export default epic;
