import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import moment from "moment";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { ContractorProfileActionTypes } from "../../actions/contractor/profile.actions";

export interface ContractorProfileState {
    info: Landmark.Contractor;
    serviceAgreementDate?: Date;
}

export const defaultState: ContractorProfileState = {
    info: null,
    serviceAgreementDate: undefined,
};

export const ContractorProfileReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.Contractor
        >
) => {
    switch (action.type) {
        case ContractorProfileActionTypes.AgreeToServiceAgreement.SUCCESS: {
            const serviceAgreementDate = moment();
            return update(state, {
                serviceAgreementDate: { $set: serviceAgreementDate.format() }
            });
        }

        case ContractorProfileActionTypes.CheckContractorServiceAgreement.SUCCESS: {
            const payload = (action as PayloadAction<Landmark.ContractorServiceAgreementResponse>).payload;
            return update(state, {
                // If no value was provided, set as NULL to indicate
                // a different value from 'undefined' - meaning, we just
                // don't have a date for this.
                serviceAgreementDate: { $set: payload.serviceAgreementDate || null }
            });
        }

        case ContractorProfileActionTypes.Load.SUCCESS: {
            const payload = (action as PayloadAction<Landmark.Contractor>).payload;
            return update(state, {
                info: { $set: payload }
            });
        }
    }

    // If nothing changed, return the original state
    return state;
};
