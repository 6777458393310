// Original code at https://stackoverflow.com/a/37164538/162907

export function isObject(item) {
    return (item && typeof item === "object" && !Array.isArray(item));
}

/**
 * Returns an object suitable for use with immutability-helper's $merge action.
 * Since $merge only does a shallow merge, we could lose data if we don't
 * prepare the object for merge ahead of time.
 * @param current The current data.
 * @param next The next data.
 * @param copy True to copy data from current before merging.
 */
export default function makeMergeable(current, next, copy = false) {
    if (isObject(current) && isObject(next)) {
        const result = Object
            .keys(next)
            .reduce(
                (output, key) => {
                    if (isObject(next[key])) {
                        if (!(key in current)) {
                            output[key] = next[key];
                        }
                        else {
                            output[key] = makeMergeable(current[key], next[key], true);
                        }
                    }
                    else {
                        output[key] = next[key];
                    }
                    return output;
                },
                copy ? Object.assign({}, current) : {}
            );
        return result;
    }
    return next;
}
