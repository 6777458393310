import * as React from "react";
import { toastr } from "react-redux-toastr";
import { isEmail } from "validator";

import { TextBox } from "./TextBox";
import { createForumActions, ForumActionCreators } from "../actions/forum.actions";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";

interface Props {
    actions?: ForumActionCreators;
}

interface State {
    isValid?: boolean;
}

class Controls {
    email: TextBox;
}

@connect(
    state => ({}),
    bindActionCreatorsToProps(createForumActions()),
)
export class Subscribe extends React.Component<Props, State>{
    controls = new Controls();

    constructor(props) {
        super(props);

        this.state = {
            isValid: true
        };
    }

    handleEmailChanged = (e: any) => {
        const isValid = isEmail(e.target.value);
        this.setState({ isValid });

        if (!isValid) {
            toastr.error("Invalid Email", "The email address you entered is incorrect.  Please try again.");
        }
    }

    handleSubscribe = async () => {
        const email = this.controls.email.value;
        this.props.actions.subscribe.begin(email);
    }

    render() {
        return (
            <section id="subscribe" className="section-cta">
                <div className="container">
                    <div className="row">
                        <h1>Subscribe to our Resources Newsletter</h1>
                        <p>
                            Don't miss out on our monthly newsletter! Sign up to get tips on everything about home ownership, from maintenance tips to home selling advice delivered to your email once a month.
                        </p>
                    </div>
                    <form>
                        <fieldset>
                            <div className="row items">
                                <div className="column full">
                                    <div className="field-wrap">
                                        <TextBox
                                            ref={control => this.controls.email = control}
                                            placeholder="Email..."
                                            error={!this.state.isValid && "Email is required."}
                                            onBlur={this.handleEmailChanged}
                                            type="text" />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                    <div className="row center">
                        <a onClick={e => this.handleSubscribe()} className="btn-orange">Subscribe<i data-icon="j"></i></a>
                    </div>
                </div>
            </section>
        );
    }
}
