import * as React from "react";

export class RedirectPage extends React.PureComponent {
    constructor() {
        super({});

        window.location.href = "https://contractor.landmarkhw.com/userFiles/files/" + this.getParameterByName("file", "");
    }

    getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, "\\$&");

        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
        const results = regex.exec(url);

        if (!results || !results[2]) {
            return "";
        }

        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    render() { return null; }
}
