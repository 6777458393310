import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { ActionCreatorMap } from "store/componentBindings";
import { appId, AsyncAction, PayloadAction, AsyncActionCreators, createAsyncActions } from "./defs";

export const OrderActionTypes = {
    ADD_ADDON: `${appId}/order/ADD_ADDON`,
    ADD_BUYERS_CREDIT: `${appId}/order/ADD_BUYERS_CREDIT`,
    ADD_CONTACT: `${appId}/order/ADD_CONTACT`,
    ADD_PLAN: `${appId}/order/ADD_PACKAGE`,
    ADD_PAYMENT_EMAIL: `${appId}/order/PAYMENT_EMAIL_ADD`,
    ADD_SERVICE_CALL_FEE: `${appId}/order/ADD_SERVICE_CALL_FEE`,
    ConvertContract: AsyncAction(`order/convert`),
    CreatePartner: AsyncAction(`order/partner/create`),
    CLEAR: `${appId}/order/CLEAR`,
    CLEAR_PARTNER_SEARCH_RESULTS: `${appId}/order/CLEAR_PARTNER_SEARCH_RESULTS`,
    CLEAR_SELECTED_ROLES: `${appId}/order/CLEAR_SELECTED_ROLES`,
    GetPriceBreakdown: AsyncAction(`order/priceBreakdown`),
    GeocodeAddress: AsyncAction(`/order/GeocodeAddress`),
    NEW_ORDER: `${appId}/order/NEW_ORDER`,
    ORDERING_PARTY_SET_TYPE: `${appId}/order/ORDERING_PARTY_SET_TYPE`,
    PARTNER_SELECT: `${appId}/order/PARTNER_SELECT`,
    QUICK_ORDER: `${appId}/order/QUICK_ORDER`,
    RegisterLead: AsyncAction(`order/lead/register`),
    REMOVE_AC: `${appId}/order/REMOVE_AC`,
    REMOVE_CONTACT: `${appId}/order/REMOVE_CONTACT`,
    REMOVE_PAYMENT_EMAIL: `${appId}/order/PAYMENT_EMAIL_REMOVE`,
    REMOVE_PLAN: `${appId}/order/REMOVE_PACKAGE`,
    REMOVE_QUOTE_ADDONS: `${appId}/order/REMOVE_QUOTE_ADDONS`,
    REMOVE_QUOTE_ITEM: `${appId}/order/REMOVE_QUOTE_ITEM`,
    RequestBrochure: AsyncAction(`order/brochure/request`),
    SearchPartner: AsyncAction(`order/partner/search`),
    SELECT_OFFER: `${appId}/order/SELECT_OFFER`,
    SELECT_SERVICE_CALL_PRICE: `${appId}/order/SELECT_SERVICE_CALL_PRICE`,
    SET_ADDRESS: `${appId}/order/SET_ADDRESS`,
    SET_AUTO_RENEWAL: `${appId}/order/SET_AUTO_RENEWAL`,
    SET_CONTACT_VALUE: `${appId}/order/SET_CONTACT_VALUE`,
    SET_COVERAGE_LENGTH: `${appId}/order/SET_COVERAGE_LENGTH`,
    SET_COUPON_CODE: `${appId}/order/SET_COUPON_CODE`,
    SET_DWELLING_TYPE_ID: `${appId}/order/SET_DWELLING_TYPE_ID`,
    SET_PAYMENT: `${appId}/order/SET_PAYMENT`,
    SET_PAYMENT_EMAIL: `${appId}/order/SET_PAYMENT_EMAIL`,
    SET_PAYMENT_TYPE: `${appId}/order/SET_PAYMENT_TYPE`,
    SET_PLAN_TYPE: `${appId}/order/SET_PLAN_TYPE`,
    SET_PURCHASED_RECENTLY: `${appId}/order/SET_PURCHASED_RECENTLY`,
    SET_STATE: `${appId}/order/SET_STATE`,
    SET_USER_AS_PARTNER: `${appId}/order/partner/SET_USER_AS_PARTNER`,
    SET_VALUES: `${appId}/order/SET_VALUES`,
    SearchExistingPartner: AsyncAction(`/order/findExistingPartner`),
    SearchExistingParent: AsyncAction(`/order/findExistingParent`),
    SetQuickOrderPlanId: AsyncAction(`order/SetQuickOrderPlanId`),
    START_RENEWAL: `${appId}/order/START_RENEWAL`,
    START_UPGRADE: `${appId}/order/START_UPGRADE`,
    Submit: AsyncAction(`order/submit`),
    UPGRADE: `${appId}/order/UPGRADE`,
    ValidateCouponCode: AsyncAction(`order/couponCode/validate`),
    GetProfileToken: AsyncAction('order/getCustomerProfileToken'),
    GetCustomerPaymentProfile: AsyncAction('order/getCustomerPaymentProfile'),
    SET_IS_ADDRESS_VERIFIED: `${appId}/order/SET_IS_ADDRESS_VERIFIED`,
    SET_MARKETING_SOURCE_ID: `${appId}/order/SET_MARKETING_SOURCE_ID`
};

export interface PartnerSearchRequest {
    partnerType: Landmark.PartyType;
    searchTerm: string;
}

export function createOrderActions(): OrderActionCreators {
    return {
        addContact: () => {
            return { type: OrderActionTypes.ADD_CONTACT };
        },

        addPaymentEmail: () => {
            return { type: OrderActionTypes.ADD_PAYMENT_EMAIL };
        },

        addPlan: (planId: string) => {
            return {
                type: OrderActionTypes.ADD_PLAN,
                payload: planId,
            };
        },

        clear: () => {
            return {
                type: OrderActionTypes.CLEAR
            };
        },

        clearPartnerSearchResults: () => {
            return {
                type: OrderActionTypes.CLEAR_PARTNER_SEARCH_RESULTS
            };
        },

        clearSelectedRoles: () => {
            return {
                type: OrderActionTypes.CLEAR_SELECTED_ROLES
            };
        },

        convertContract: (contractId: string) => {
            return {
                type: OrderActionTypes.ConvertContract.BEGIN,
                payload: contractId,
            };
        },

        createPartner: (request: Landmark.CreatePartnerRequest) => {
            return {
                type: OrderActionTypes.CreatePartner.BEGIN,
                payload: request,
            };
        },

        getPriceBreakdown: createAsyncActions(OrderActionTypes.GetPriceBreakdown),

        GeocodeAddress: createAsyncActions(OrderActionTypes.GeocodeAddress),

        newOrder: (planTypeName: string) => {
            return {
                type: OrderActionTypes.NEW_ORDER,
                payload: planTypeName,
            };
        },

        quickOrder: (budgetAmount: number, dwellingTypeId: string, squareFootage: number) => {
            return {
                type: OrderActionTypes.QUICK_ORDER,
                budgetAmount,
                dwellingTypeId,
                squareFootage
            };
        },

        registerLead: createAsyncActions(OrderActionTypes.RegisterLead),

        removeAC: (remove: boolean) => {
            return { type: OrderActionTypes.REMOVE_AC, remove };
        },

        removeAddonsFromQuote: () => {
            return {
                type: OrderActionTypes.REMOVE_QUOTE_ADDONS
            };
        },

        removeContact: (index: number) => {
            return {
                type: OrderActionTypes.REMOVE_CONTACT,
                index,
            };
        },

        removeItemFromQuote: (item: Landmark.ContractItem) => {
            return {
                type: OrderActionTypes.REMOVE_QUOTE_ITEM,
                item
            };
        },

        removePlan: (planId: string) => {
            return {
                type: OrderActionTypes.REMOVE_PLAN,
                payload: planId,
            };
        },

        removePaymentEmail: (index: number) => {
            return { type: OrderActionTypes.REMOVE_PAYMENT_EMAIL, index };
        },

        searchPartner: createAsyncActions(OrderActionTypes.SearchPartner),

        searchExistingPartner: createAsyncActions(OrderActionTypes.SearchExistingPartner),

        searchExistingParent: createAsyncActions(OrderActionTypes.SearchExistingParent),

        selectOffer: (offer: Landmark.ContractOffer) => {
            return {
                type: OrderActionTypes.SELECT_OFFER,
                payload: offer,
            };
        },

        selectPartner: (partnerType: string, partner: Landmark.PartnerSearchResponse) => {
            return {
                type: OrderActionTypes.PARTNER_SELECT,
                partnerType,
                partner,
            };
        },

        selectServiceCallPrice: (serviceCallPrice: Landmark.ServiceCallPrice) => {
            return {
                type: OrderActionTypes.SELECT_SERVICE_CALL_PRICE,
                payload: serviceCallPrice,
            };
        },

        setAddon: (addon: Landmark.ContractAddon) => {
            return {
                type: OrderActionTypes.ADD_ADDON,
                payload: addon,
            };
        },

        setAddress: (address: Landmark.Address) => {
            return {
                type: OrderActionTypes.SET_ADDRESS,
                payload: address,
            };
        },

        setAutoRenewal: () => {
            return {
                type: OrderActionTypes.SET_AUTO_RENEWAL
            };
        },

        setBuyersCredit: (amount: number) => {
            return {
                type: OrderActionTypes.ADD_BUYERS_CREDIT,
                amount,
            };
        },

        setContactValues: (index: number, values: any) => {
            const setValueAction = {
                type: OrderActionTypes.SET_CONTACT_VALUE,
                index,
                values
            };
            return setValueAction;
        },

        setCouponCode: (couponCode: string) => {
            return {
                type: OrderActionTypes.SET_COUPON_CODE,
                payload: couponCode,
            };
        },

        setMarketingSourceId: (marketingSourceId: string) => {
            return {
                type: OrderActionTypes.SET_MARKETING_SOURCE_ID,
                payload: marketingSourceId,
            };
        },

        setPPSCF: (quantity: number) => {
            return {
                type: OrderActionTypes.ADD_SERVICE_CALL_FEE,
                quantity,
            };
        },

        setOrderQuantity: (quantity: number) => {
            return {
                type: OrderActionTypes.SET_COVERAGE_LENGTH,
                quantity,
            };
        },

        setPurchasedRecently: (isRecent: boolean) => {
            return {
                type: OrderActionTypes.SET_PURCHASED_RECENTLY,
                isRecent
            };
        },

        setPayment: (payment: Landmark.OrderPayment) => {
            return {
                type: OrderActionTypes.SET_PAYMENT,
                payment,
            };
        },

        setPaymentEmail: (email: string, index: number) => {
            return {
                type: OrderActionTypes.SET_PAYMENT_EMAIL,
                email,
                index,
            };
        },

        setPaymentType: (paymentType: Landmark.PaymentType) => {
            return {
                type: OrderActionTypes.SET_PAYMENT_TYPE,
                payload: paymentType,
            };
        },

        setDwellingTypeId: (dwellingTypeId: string) => {
            return {
                type: OrderActionTypes.SET_DWELLING_TYPE_ID,
                payload: dwellingTypeId
            };
        },

        setPlanTypeId: (planTypeId: string) => {
            return {
                type: OrderActionTypes.SET_PLAN_TYPE,
                payload: planTypeId
            };
        },

        setStateCode: (stateCode: string) => {
            return {
                type: OrderActionTypes.SET_STATE,
                value: stateCode
            };
        },

        setQuickOrderPlanId: createAsyncActions(OrderActionTypes.SetQuickOrderPlanId),

        setUserAsPartner: (partnerType: string, partnerInfo: Landmark.PartnerSearchResponse) => {
            return {
                type: OrderActionTypes.SET_USER_AS_PARTNER,
                payload: {
                    partnerInfo: partnerInfo,
                    partnerType: partnerType,
                }
            };
        },

        setValues: (values: any) => {
            return {
                type: OrderActionTypes.SET_VALUES,
                payload: values,
            };
        },

        startRenewal: (contract: Landmark.Contract, user: Landmark.User) => {
            return {
                type: OrderActionTypes.START_RENEWAL,
                contract,
                user,
            };
        },

        startUpgrade: (contract: Landmark.Contract, user: Landmark.User) => {
            return {
                type: OrderActionTypes.START_UPGRADE,
                contract,
                user,
            };
        },

        setIsAddressVerified: (isAddressVerified: boolean) => {
            return {
                type: OrderActionTypes.SET_IS_ADDRESS_VERIFIED,
                value: isAddressVerified
            };
        },

        submit: createAsyncActions(OrderActionTypes.Submit),

        validateCouponCode: createAsyncActions(OrderActionTypes.ValidateCouponCode),

        getProfileToken: createAsyncActions(OrderActionTypes.GetProfileToken),

        getCustomerPaymentProfile: createAsyncActions(OrderActionTypes.GetCustomerPaymentProfile),

        upgrade: (contractId: string) => ({
            type: OrderActionTypes.UPGRADE,
            payload: contractId,
        }),
    };
}

export interface OrderActionCreators extends ActionCreatorMap {
    /**
     * Adds a contact to the contract to be created.
     */
    addContact: () => Action;

    /**
     * Adds a plan to the contract.
     */
    addPlan: (planId: string) => Action;

    /**
     * Adds an email field to the payment tab "confirmation email" section
     */
    addPaymentEmail: () => Action;

    /**
     * Clears the loaded order.
     *
     * @memberof OrderActionCreators
     */
    clear: () => Action;

    /**
     * Clears out the search results window.
    */
    clearPartnerSearchResults: () => Action;

    /**
     * Clears out the value for the selected party type.
     */
    clearSelectedRoles: () => Action;

    /**
     * Listing Coverage policy id
     */
    convertContract: (contractId: string) => Action;

    createPartner: (request: Landmark.CreatePartnerRequest) => Action;

    searchExistingPartner: AsyncActionCreators<Landmark.ExistingPartnerRequest, Landmark.PartnerSearchResponse[]>;

    searchExistingParent: AsyncActionCreators<Landmark.ExistingPartnerRequest, Landmark.PartnerSearchResponse[]>;

    /**
     * Gets the current price breakdown of the order.
     */
    getPriceBreakdown: AsyncActionCreators<Landmark.OrderRequest, Landmark.ContractItemResponse>;

    /**
     * Get the verified addres from Geocode Data.
     */
    GeocodeAddress : AsyncActionCreators<Landmark.Address>;

    /**
     * Begins a new order.
     */
    newOrder: (planTypeName: string) => PayloadAction<string>;

    /**
     * Starts a quick order.
     * @param {number} budgetAmount The budget amount for the order.
     * @param {string} dwellingTypeId The dwelling type id being ordered.
     * @param {number} squareFootage The square footage of the property.
     */
    quickOrder: (budgetAmount: number, dwellingTypeId: string, squareFootage: number) => Action;

    /**
    * Removes the AC coverage.
    * @param {boolean} remove True to remove, false to keep.
    */
    removeAC: (remove: boolean) => Action;

    /**
     * Removes the contact at the given index.
     * @param {number} index The index of the contact to be removed.
     */
    removeContact: (index: number) => Action;

    /**
     * Removes a plan from the contract.
     */
    removePlan: (planId: string) => Action;

    /**
     * Removes addons from the quote.
     */
    removeAddonsFromQuote: () => Action;

    /**
     * Removes the specified contract item from the quote.
     * @param {Landmark.ContractItem} item The item to be removed.
     */
    removeItemFromQuote: (item: Landmark.ContractItem) => Action;

    /**
     * Removes an email field from the payment tab "confirmation email" section
     * @param {number} index The index of the email address to be removed.
     */
    removePaymentEmail: (index: number) => Action;

    /**
     * Registers a lead.
     */
    // TODO: move to lead portion of state
    registerLead: AsyncActionCreators<Landmark.LeadRegistrationRequest, void>;

    /**
     * Setting when they are going to auto renew.
     */
    setAddress: (address: Landmark.Address) => Action;

    /**
     * Setting when they are going to auto renew.
     */
    setAutoRenewal: () => Action;
    /**
     * Sets values on a contact.
     * @param {number} index The index of the contact to set values on.
     * @param {any} payload The payload of data to be set on the contact.
     */
    setContactValues: (index: number, payload: any) => Action;

    /**
     * Sets the dwelling type for the order.
     * @param {string} dwellingTypeId The ID of the dwelling type for the order.
     */
	setDwellingTypeId: (dwellingTypeId: string) => Action;

    /**
     * Selects a contract offer to order.
     * @param {Landmark.ContractOffer} offer The contract offer to be ordered.
     */
    selectOffer: (offer: Landmark.ContractOffer) => PayloadAction<Landmark.ContractOffer>;

    /**
     * Selects a service call fee & price.
     */
    selectServiceCallPrice: (serviceCallPrice: Landmark.ServiceCallPrice) => PayloadAction<Landmark.ServiceCallPrice>;

    /**
     * Sets whether or not the contract was purchased recently.
     */
    setPurchasedRecently: (isRecent: boolean) => Action;

    /**
     * Sets the transaction type ID for the contract.
     * @param {string} planTypeId The ID of the plan type.
     */
    setPlanTypeId: (planTypeId: string) => Action;

    /**
     * Sets the state for the contract property.
     * @param {string} stateCode The state code of the contract property.
     */
    setStateCode: (stateCode: string) => Action;

    /**
     * Sets the marketing Source Id for the contract.
     * @param {string} marketingSourceId The addon to set.
     */
    setMarketingSourceId: (marketingSourceId: string) => Action;

    /**
     * Sets the currently authenticated user as the selected partner type on a new order.
     */
    setUserAsPartner: (partnerType: string, partnerInfo: Landmark.PartnerSearchResponse) => Action;

    /**
     * Sets an addon for the contract.
     * @param {Landmark.ContractAddon} addon The addon to be set.
     */
    setAddon: (addon: Landmark.ContractAddon) => PayloadAction<Landmark.ContractAddon>;

    /**
     * Sets the selected coupon code.
     * @param {string} couponCode The coupon code to set.
     */
    setCouponCode: (couponCode: string) => Action;

    /**
     * Sets the payment information.
     * @param {Landmark.OrderPayment} payment The payment information to update/set.
     */
    setPayment: (payment: Landmark.OrderPayment) => Action;

    /**
     * Sets the type of payment that will be made.
     * @param {Landmark.PaymentType} paymentType The type of payment that will be made.
     */
    setPaymentType: (paymentType: Landmark.PaymentType) => PayloadAction<Landmark.PaymentType>;

    /**
     * Sets the payment email address.
     * @param {string} email The email address to set.
     * @param {number} index The index to set.
     */
    setPaymentEmail: (email: string, index: number) => Action;

    /**
     * Starts renewal for the given contract.
     * @param {Landmark.Contract} contract Contract to renew.
     * @param {Landmark.User} user Owner of the contract to renew.
    */
   startRenewal: (contract: Landmark.Contract, user: Landmark.User) => Action;

    /**
     * Starts upgrade for the given contract.
     * @param {Landmark.User} user Owner of the contract to upgrade.
    */
   startUpgrade: (contract: Landmark.Contract, user: Landmark.User) => Action;

    /**
    * Sets an PPSCF for the contract.
    * @param {Landmark.OrderRequest} PPSCF The addon to set.
    */
    setBuyersCredit: (amount: number) => Action;

    /**
    * Sets an PPSCF for the contract.
    * @param {Landmark.OrderRequest} PPSCF The addon to set.
    */
    setPPSCF: (quantity: number) => Action;

    /**
    * Sets coverage length for the contract.
    */
    setOrderQuantity: (quantity: number) => Action;


    /**
     * Submits the order.
     */
    submit: AsyncActionCreators<Landmark.OrderRequest, Landmark.OrderResponse>;

    /**
     * set the plan id from quick order.
     */
    setQuickOrderPlanId: AsyncActionCreators<Landmark.QuickOrderRequest>;

    /**
     * Sets values in this area of the store.
     */
    setValues: (values: any) => Action;

    /**
     * Validates the coupon code.
     * @param {Landmark.OrderRequest} request The order request containing the coupon to be validated.
     */
    validateCouponCode: AsyncActionCreators<Landmark.OrderRequest, Landmark.ApiResponse<Landmark.ContractCoupon>>;


    /**
     * Performs a search for Landmark partners.
     * @param {Landmark.OrderingPartyType} partnerType
     * @param {string} searchTerm The text to search for.
     */
    searchPartner: AsyncActionCreators<PartnerSearchRequest, Landmark.PartnerSearchResponse[]>;

    getProfileToken: AsyncActionCreators<Landmark.GetProfileTokenRequest, string>;

    getCustomerPaymentProfile: AsyncActionCreators<Landmark.GetProfileTokenRequest, string>;



    selectPartner: (partnerType: Landmark.PartyType, partner: Landmark.PartnerSearchResponse) => Action;


    /**
     *  Sets the order for upgrading.
     */
    upgrade: (contractId: string) => PayloadAction<string>;

    setIsAddressVerified: (isAddressVerified: boolean) => Action;
}
