import * as Landmark from "models/landmark-api";
import { ActionCreatorMap } from "../../store/componentBindings";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";

export const ProfileActionTypes = {
    Save: AsyncAction("account/profile/save"),
    SetSmsOptIn: AsyncAction(`${appId}/account/profile/setSmsOptIn`),
};

export interface ProfileActionCreators extends ActionCreatorMap {
    save: AsyncActionCreators<Landmark.EditProfileRequest, Landmark.EditProfileResponse>;

    /**
     * Sets the contact preferences info for the profile.
     */
    setSmsOptIn: AsyncActionCreators<Landmark.SmsOptIn, void>;
}

export function createProfileActions(): ProfileActionCreators {
    return {
        save: createAsyncActions(ProfileActionTypes.Save),
        setSmsOptIn: createAsyncActions(ProfileActionTypes.SetSmsOptIn),
    };
}
