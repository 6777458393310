import * as React from "react";
import ReactModal from "react-modal";

import "./Modal.scss";

interface Props {
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    isOpen?: boolean;
    onAfterOpen?: Function;
    onRequestClose?: Function;
    style?: {
        overlay?: React.CSSProperties,
        container?: React.CSSProperties,
        content?: React.CSSProperties,
        close?: React.CSSProperties,
    };
}

interface State {
    /**
     * Flag representing whether this currently being rendered on client vs server.
     *
     * @type {boolean}
     * @memberof State
     */
    isClientSide?: boolean;
}

export class Modal extends React.Component<Props, State> {
    static openCount = 0;
    constructor(props: Props) {
        super(props);

        this.state = {
            isClientSide: false
        };
    }

    /**
     * This function is only ran on client side, so we can no for certain that client side rendered components are ready to render after this function is called.
     *
     * @memberof Footer
     */
    componentDidMount() {
        this.setState({ isClientSide: true });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isOpen !== this.props.isOpen) {
            if (newProps.isOpen) {
                if (Modal.openCount === 0) {
                    document.body.classList.add("noscroll");
                }
                Modal.openCount++;
            }
            else {
                Modal.openCount--;
                if (Modal.openCount === 0) {
                    document.body.classList.remove("noscroll");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.props.isOpen) {
            Modal.openCount--;
            if (Modal.openCount === 0) {
                document.body.classList.remove("noscroll");
            }
        }
    }

    handleClose = e => {
        if (this.props.onRequestClose) {
            this.props.onRequestClose(e);
        }
    }

    render() {
        return (
            this.state.isClientSide ?
                <ReactModal
                    shouldCloseOnEsc={this.props.shouldCloseOnEsc}
                    shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
                    isOpen={this.props.isOpen}
                    onAfterOpen={this.props.onAfterOpen}
                    onRequestClose={this.props.onRequestClose}
                    className={`modal-open-close-animation ${this.props.isOpen ? "modal-open-close-animation-on" : ""}`}
                    contentLabel=""
                    style={this.props.style}>
                    <div style={this.props.style.close} onClick={this.handleClose}>l</div>
                    <div style={this.props.style.container}>
                        {this.props.children}
                    </div>
                </ReactModal> :
                <div />
        );
    }
}
