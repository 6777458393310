import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../actions/defs";
import { SalesRepActionTypes } from "../actions/salesRep.actions";

export interface SalesRepState {
    list: Landmark.SalesRep[];
}

const defaultState: SalesRepState = {
    list: []
};

export const SalesRepReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.SalesRep[]>
) => {
    switch (action.type) {
        case SalesRepActionTypes.CLEAR: {
            return defaultState;
        }
        case SalesRepActionTypes.Load.SUCCESS:
            const loadAction = action as PayloadAction<Landmark.SalesRep[]>;
            return update(state, {
                list: { $set: loadAction.payload }
            });

        default: break;
    }

    // If nothing changed, return the original state
    return state;
};
