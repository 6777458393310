import * as Landmark from "models/landmark-api";
import * as React from "react";

import { LocationActionCreators, createLocationActions } from "../../actions/location.actions";
import { createSalesRepActions, SalesRepActionCreators } from "../../actions/salesRep.actions";
import { FontIcon } from "../../components/FontIcon";
import { getFullName } from "../../selectors/auth.selectors";
import { getUserLocationStateCode } from "../../selectors/location.selectors";
import { getOrderProperty } from "../../selectors/order.selectors";
import { getStates } from "../../selectors/reference.selectors";
import { getSalesReps } from "../../selectors/salesRep.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";

interface Props {
    store?: {
        property: Landmark.Property;
        salesReps: Landmark.SalesRep[];
        states: Landmark.State[];
        userStateCode: string;
    };
    actions?: {
        location: LocationActionCreators;
        salesRep: SalesRepActionCreators;
    };
}

interface State {
    selectedState: string;
}

@connect(
    state => ({
        store: {
            property: getOrderProperty(state),
            salesReps: getSalesReps(state),
            states: getStates(state),
            userStateCode: getUserLocationStateCode(state),
        }
    }),
    bindActionCreatorsToProps({
        location: createLocationActions(),
        salesRep: createSalesRepActions(),
    }),
)
export class MeetYourRep extends React.Component<Props, State> {
    state = {
        selectedState: null,
    };

    constructor(props) {
        super(props);

        this.handleStateChange = this.handleStateChange.bind(this);
        this.renderSalesReps = this.renderSalesReps.bind(this);
    }

    componentDidMount() {
        if (!!this.props.store.userStateCode &&
            this.props.store.userStateCode.length > 0) {
            // Auto-select the user's state
            this.handleStateChange(this.props.store.userStateCode);
        }

        // Ensure we have asked the user for their location
        if (!this.props.store.userStateCode) {
            this.props.actions.location.get.begin();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (// Selected state changed
            this.state.selectedState !== prevState.selectedState &&
            // Non-null, non-undefined
            !!this.state.selectedState &&
            // Non-empty
            this.state.selectedState.length > 0) {
            this.props.actions.salesRep.load.begin(this.state.selectedState);
        }

        if (// Browser location changed
            this.props.store.userStateCode !== prevProps.store.userStateCode &&
            (
                // We didn't already have a state selected
                !this.state.selectedState ||
                this.state.selectedState.length === 0
            )) {
            // Set the selected state
            this.handleStateChange(this.props.store.userStateCode);
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return this.state.selectedState === null || this.props.store.salesReps.length > 0;
    }

    handleStateChange(stateCode: string) {
        this.props.actions.salesRep.clear();
        this.setState({ selectedState: stateCode });
    }

    /**
     * Renders sales reps in rows of 4 reps each.
     */
    renderSalesReps() {
        let arrays = [];
        let arr = [];
        this.props.store.salesReps.forEach((salesRep, index) => {
            if (index % 4 === 0) {
                if (arr && arr.length) {
                    arrays.push(arr);
                }
                arr = [salesRep];
            }
            else {
                arr.push(salesRep);
            }
        });
        arrays.push(arr);

        return (
            <div className="reps">
                {arrays.map((arr, i) => (
                    <div key={i} className="row textCenter">
                        {arr.map((salesRep: Landmark.SalesRep, j) => (
                            <Rep key={j} row={i} column={j} salesRep={salesRep} />
                        ))}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        if (!this.props.store.salesReps) {
            return null;
        }
        return (
            <section id="your-local-representative" className="section-reps">
                <div className="container">
                    <div className="row">
                        <h1>Meet Your Local Landmark Home Warranty Representative!</h1>
                        <p>
                            Our Landmark Home Warranty representatives work and live near you. They provide personalized and quality service on all things Landmark. Our representatives proudly
                            serve Arizona, Idaho, Nevada, Oregon, Texas, and Utah. Reach out to them about ordering a home warranty for your new home buyers today!
                        </p>
                    </div>
                    <div className="row">
                        <div className="field-wrap">
                            <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                                Choose Your State:
                            </p>
                            <div className="select dark" style={{ width: "150px" }}>
                                <select
                                    onChange={(e: any) => this.handleStateChange(e.target.value)}
                                    value={this.state.selectedState || ""}>
                                    <option key={0} value="">Choose A State</option>
                                    {this.props.store
                                        .states
                                        .filter(stateItem => stateItem.isMarketState)
                                        .map((state, index) => <option key={index + 1} value={state.stateCode}>{state.description}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    {this.renderSalesReps()}
                </div>
            </section>
        );
    }
}

interface RepProps {
    column: number;
    row: number;
    salesRep: Landmark.SalesRep;
}

interface RepState {
    isTooltipActive: boolean;
}

class Rep extends React.Component<RepProps, RepState> {

    render() {
        const tag = this.props.salesRep.titleTag;
        let imageUrl = "";
        try {
            imageUrl = require(`../../content/images/localReps/${this.props.salesRep.salesRepId}.png`);
        }
        catch (ex) {
            console.warn(`Could not find image for rep: ${this.props.salesRep.salesRepId}.png`);
        }

        return (
            <div key={this.props.column} className="column fourth floating" >
                <div id={`rep-${this.props.row}-${this.props.column}`}>
                    <figure >
                        <img title={tag} src={imageUrl} />
                    </figure>
                    <div className="name">{getFullName(this.props.salesRep)}</div>
                    <div className="meta">
                        <div className="row">{this.props.salesRep.territory}</div>
                        <div className="row">
                            <FontIcon className="meta-icon">z</FontIcon>
                            <a href={`tel:${this.props.salesRep.cellPhone.replace(/[\D]/g, "")}`}>{this.props.salesRep.cellPhone}</a>
                        </div>
                        <div className="row">
                            <FontIcon className="meta-icon">A</FontIcon>
                            <a href={`mailto:${this.props.salesRep.email}`}>{this.props.salesRep.email}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
