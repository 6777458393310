// Load all the injected code before loading the application.
function loadReactApp() {
    const { App, isDebugEnabled, React, ReactDOM } = preloadScripts();

    applyErrorHandling(isDebugEnabled);

    const root: HTMLElement = document.getElementById("root");
    const isSsr = root.getElementsByTagName("ssr").length === 0;

    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    if (isSsr) {
        ReactDOM.hydrate(<App />, root);
    }
    else {
        ReactDOM.render(<App />, root);
    }

}

function preloadScripts() {
    require("./content/css/app.scss");
    require("./content/react-redux-toastr/index.scss");
    require("./index.scss");

    const { isDebugEnabled } = require("./environment");
    const { App } = require("./App");
    return {
        App,
        isDebugEnabled,
        React: require("react"),
        ReactDOM: require("react-dom")
    };
}

function applyErrorHandling(isDebugEnabled: boolean) {
    // Set up error handling in the application.
    window.onerror = function (message, file, line, col, error) {
        if (isDebugEnabled) {
            console.error(message, "from", error && error.stack);
        }
    };

    const getReason = (event: any) => {
        if (event.stack) {
            return event.stack;
        }
        else if (event.message) {
            return event.message;
        }
        else if (event.reason) {
            return getReason(event.reason);
        }
        else if (typeof event === "object") {
            return JSON.stringify(event);
        }
        return event;
    };

    window.addEventListener("unhandledrejection", (event: any) => {
        // Prevent error output on the console:
        try {
            event.preventDefault();
            if (isDebugEnabled) {
                console.error(`Reason: ${getReason(event)}`);
            }
        }
        catch (err) {
        }
    });

    if (process) {
        process.on("uncaughtException", err => {
            // Prevent error output on the console:
            if (isDebugEnabled) {
                console.error(`Error: ${err.message}`);
            }
        });
    }
}

// Get Missing features;
function getMissingFeatures() {
    let features = [];
    // Check default features.
    (
        ("assign" in Object) &&
        ("from" in Array) &&
        ("of" in Array) &&
        ("includes" in Array.prototype) &&
        ("fill" in Array.prototype) &&
        ("after" in Element.prototype) &&
        ("append" in Element.prototype) &&
        ("before" in Element.prototype) &&
        ("classList" in Element.prototype) &&
        ("matches" in Element.prototype) &&
        ("closest" in Element.prototype) &&
        ("prepend" in Element.prototype) &&
        ("remove" in Element.prototype) &&
        ("replaceWith" in Element.prototype) &&
        (
            "Map" in this &&
            new Map([[1, 1], [2,2]]).size === 2
        ) &&
        ("Event" in global) &&
        ("URL" in global) &&
        ("contains" in Node.prototype) &&
        (
            "Set" in this &&
            new Set([1,2]).size === 2
        ) &&
        ("assign" in Object) &&
        ("endsWith" in String.prototype) &&
        ("startsWith" in String.prototype) &&
        ("iterator" in Symbol) &&
        ("CustomEvent" in this) &&
        ("DOMTokenList" in this) &&
        ("Promise" in this) &&
        ("Symbol" in this)
    ) || features.push("default");

    // Extra array polyfills.
    ("find" in Array.prototype) || features.push("Array.prototype.find");
    ("findIndex" in Array.prototype) || features.push("Array.prototype.findIndex");
    ("copyWithin" in Array.prototype) || features.push("Array.prototype.copyWithin");
    ("entries" in Array.prototype) || features.push("Array.prototype.entries");

    // Extra object
    ("entries" in Object) || features.push("Object.entries");
    ("freeze" in Object) || features.push("Object.freeze");
    ("is" in Object) || features.push("Object.is");

    // Check for fetch.
    ("fetch" in this) || features.push("fetch");

    return features;
}

// IE test retrieved from https://codepen.io/gapcode/pen/vEJNZN
function detectIE() {
    var userAgent = window.navigator.userAgent;
    var microsoftInternetExplorer = userAgent.indexOf("MSIE ");

    if (microsoftInternetExplorer > 0) {
        // IE 10 or older => return version number
        return parseInt(userAgent.substring(microsoftInternetExplorer + 5, userAgent.indexOf(".", microsoftInternetExplorer)), 10);
    }

    var trident = userAgent.indexOf("Trident/");
    if (trident > 0) {
        // IE 11 => return version number
        var rv = userAgent.indexOf("rv:");
        return parseInt(userAgent.substring(rv + 3, userAgent.indexOf(".", rv)), 10);
    }

    var edge = userAgent.indexOf("Edge/");
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(userAgent.substring(edge + 5, userAgent.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
}

const missingFeatures = getMissingFeatures();

// If we support all the necessary features, load the react app.
if (missingFeatures.length === 0) {
    loadReactApp();
} else {
    var js = document.createElement("script");
    var internetExplorerVersion = detectIE();
    var userAgentText = internetExplorerVersion ? `&ua=ie/${internetExplorerVersion}` : "";

    // This CDN will look at the User Agent of the request and only provide these polyfills if they are needed. We can add or remove features as needed;
    // But these are a bunch of polyfills that are typically needed for IE.
    js.src = `https://cdn.polyfill.io/v2/polyfill.min.js?flags=gated,always&features=${missingFeatures.join(",")}${userAgentText}`;

    js.async = true;
    js.defer = true;

    js.onload = function() {
        console.log("Loaded polyfills needed for this browser.");
        loadReactApp();
    };
    js.onerror = function() {
        alert("Failed to load essential scripts needed to make this site work on your browser. Please try again or try a different browser.");
        loadReactApp();
    };
    document.head.appendChild(js);
}


