import * as Landmark from "models/landmark-api";
import * as React from "react";

import { DropDown } from "./DropDown";
import { createReferenceActions, ReferenceActionCreators } from "../actions/reference.actions";
import { getPropertyTypes } from "../selectors/reference.selectors";
import { connect, bindActionCreatorsToProps } from "../store/componentBindings";

const styles = {
    title: {
        display: "inline-block",
    },
};

interface Props {
    actions?: ReferenceActionCreators;
    className?: string;
    id?: string;
    name?: string;
    onBlur?: React.FormEventHandler<HTMLSelectElement>;
    onChange?: React.FormEventHandler<HTMLSelectElement>;
    propertyTypes?: Landmark.PropertyType[];
    style?: React.CSSProperties;
    title?: React.ReactNode;
    value?: string;
}

@connect(
    state => ({
        propertyTypes: getPropertyTypes(state),
    }),
    bindActionCreatorsToProps(createReferenceActions())
)
export class ChoosePropertyType extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);

        if (!(props.propertyTypes && props.propertyTypes.length)) {
            props.actions.loadPropertyTypes.begin();
        }
    }

    renderItems = () => {
        const list = [];
        this.props.propertyTypes.map((propertyType, index) => {
            list.push(
                <option
                    key={index}
                    value={propertyType.dwellingTypeId}>
                    {propertyType.description}
                </option>
            );
        });
        return list;
    }

    render() {
        return (
            <div className={this.props.className}>
                {this.props.title ? <div style={styles.title}>{this.props.title}</div> : ""}<br />
                <div className="field-wrap">
                    <div className="select dark">
                        <DropDown
                            id={this.props.id}
                            listItemsFormatted={this.renderItems()}
                            name={this.props.name}
                            onBlur={this.props.onBlur}
                            onChange={this.props.onChange}
                            style={this.props.style ? this.props.style : null}
                            value={this.props.value ? this.props.value.toString() : ""}/>
                    </div>
                </div>
            </div>
        );
    }
}
