import { combineEpics, Epic, ofType } from "redux-observable";
import { throttleTime } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { createLocationActions, LocationActionTypes, LocationResponse } from "../actions/location.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LocationService } from "../services/location.service";

const locationActions = createLocationActions();

/**
 * Gets the location from the location service
 */
const handleGetLocation: Epic<
    PayloadAction<void | Error | LocationResponse>
> = action$ => action$.pipe(
    ofType(LocationActionTypes.Get.BEGIN),
    // Only query for location at most every second
    throttleTime(1000),
    switchMapWithPromiseToActions(
        (action: PayloadAction<void>) => LocationService.getLocation(),
        locationActions.get.success,
        locationActions.get.failure,
    ),
);

const epic = combineEpics(
    handleGetLocation,
);
export default epic;
