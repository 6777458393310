import { Action as ReduxAction, ActionCreatorsMapObject } from "redux";

export interface PayloadAction<T> extends ReduxAction {
    payload: T;
}

export interface AsyncActionType {
    BEGIN: string;
    SUCCESS: string;
    FAILURE: string;
    UPDATE: string;
}

export const appId = "@landmark";

export function Action(id) {
    return `${appId}/${id}`;
}

export function AsyncAction(id) {
    return {
        BEGIN: `${appId}/${id}/BEGIN`,
        SUCCESS: `${appId}/${id}/SUCCESS`,
        FAILURE: `${appId}/${id}/FAILURE`,
        UPDATE: `${appId}/${id}/UPDATE`,
    } as AsyncActionType;
}

export interface AsyncActionCreators<TRequestPayloadType, TResponsePayloadType = TRequestPayloadType, TUpdatePayloadType = TResponsePayloadType> extends ActionCreatorsMapObject {
    begin(payload?: TRequestPayloadType): PayloadAction<TRequestPayloadType>;
    failure(payload: TResponsePayloadType | Error): PayloadAction<Error>;
    success(payload?: TResponsePayloadType): PayloadAction<TResponsePayloadType>;
    update(payload: TUpdatePayloadType): PayloadAction<TUpdatePayloadType>;
}

export function createAsyncActions<TRequestPayloadType, TResponsePayloadType, TUpdatePayloadType = any>(asyncActionType: AsyncActionType) {
    return {
        begin: (payload?: TRequestPayloadType) => ({
            type: asyncActionType.BEGIN,
            payload,
        }),
        failure: (payload: TResponsePayloadType | Error) => ({
            type: asyncActionType.FAILURE,
            payload,
        }),
        success: (payload?: TResponsePayloadType) => ({
            type: asyncActionType.SUCCESS,
            payload,
        }),
        update: (payload: TUpdatePayloadType) => ({
            type: asyncActionType.UPDATE,
            payload,
        }),
    } as AsyncActionCreators<TRequestPayloadType, TResponsePayloadType, TUpdatePayloadType>;
}
