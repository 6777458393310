import * as React from "react";

import { createDialogActions, DialogActionCreators } from "../actions/dialog.actions";
import { createOrderActions, OrderActionCreators } from "../actions/order.actions";
import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { OrderingPartyModal } from "../components/OrderingPartyModal";
import { Areas } from "../constants/Areas";
import { createGetIsDialogOpen } from "../selectors/dialog.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { getCanOrder } from "selectors/order.selectors";


interface Props {
    store?: {
        isDialogOpen: boolean;
        canOrder: boolean;
    };
    actions?: {
        dialog: DialogActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
    };
    router?: any;
    color?: "orange" | "white";
    marketingSourceId?: string;
}

@connect(
    state => ({
        store: {
            isDialogOpen: createGetIsDialogOpen(Areas.Order.OrderingParty)(state),
            canOrder: getCanOrder(state),
        }
    }),
    bindActionCreatorsToProps({
        dialog: createDialogActions(Areas.Order.OrderingParty),
        order: createOrderActions(),
        router: createRouterActions()
    })
)
export class OrderingParty extends React.Component<Props, any> {
    handleOrderClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            this.props.actions.dialog.open();
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }
    render() {
        let btnClass = "btn-gray btn-stroke-light btn-homeowner";
        if (this.props.color === "orange") {
            btnClass = "btn-orange";
        }

        return (
            <div>
                <a
                    style={{ marginRight: 30, marginBottom: 20}}
                    href="/order"
                    className={btnClass}
                    onClick={e => this.handleOrderClick(e)} >Order Now</a>
                <OrderingPartyModal marketingSourceId={this.props.marketingSourceId}/>
            </div>
        );
    }
}
