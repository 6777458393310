import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, delay, map, retryWhen, switchMap, take, timeout } from "rxjs/operators";
import { ContractActionTypes, createContractsActions } from "../../actions/account/contracts.actions";
import { PayloadAction } from "../../actions/defs";
import { Areas } from "../../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const contractActions = createContractsActions();

/**
 * Handles loading contracts for a user.
 * NOTES:
 * 1. Times out after 10 seconds
 * 2. Retries every second, failing after 3 retry failures
 */
const handleLoadContracts: Epic<
    PayloadAction<string | Error | Landmark.ContractResponse>
> = action$ => action$.pipe(
    ofType(ContractActionTypes.Load.BEGIN),
    switchMap((action: PayloadAction<string>) => {
        return from(
            LandmarkApiService
                .get(`/account/contract/${action.payload}`)
                .withAuthentication()
                .fetch()
                .then(response => response.json)
        ).pipe(
            timeout(10000),
            map((response: Landmark.ContractResponse) => contractActions.load.success(response)),
            retryWhen(errors => errors.pipe(delay(1000), take(3))),
            catchError(err => of(contractActions.load.failure(err))),
        );
    }),
);

const handleLoadCoverages: Epic<
    PayloadAction<string | Error | Landmark.ContractCoverage[]>
> = action$ => action$.pipe(
    ofType(ContractActionTypes.LoadCoverages.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/account/contract/coverage/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        contractActions.loadCoverages.success,
        contractActions.loadCoverages.failure,
    ),
);

const waitDuringLoadContracts = createWaitEpic(ContractActionTypes.Load, Areas.Account.Contract.Page);
const waitDuringLoadCoverages = createWaitEpic(ContractActionTypes.LoadCoverages);

const epic = combineEpics(
    handleLoadContracts,
    handleLoadCoverages,
    waitDuringLoadContracts,
    waitDuringLoadCoverages,
);
export default epic;
