import * as React from "react";

import { Meta } from "../components/Meta";

interface Props { }

export class TermsOfUsePage extends React.Component<Props, any> {
    render() {
        return (
            <main className="misc">
                <Meta
                    title="Terms Of Use | Landmark Home Warranty"
                    description="Landmark Home Warranty's terms of use."
                    canonical="/terms-of-use"
                />
                <section className="section-intro misc">
                    <div className="container">
                        <div className="row intro-content">
                            <h1>Terms of Use</h1>
                            <p>www.landmarkhw.com  |  Updated 10/14/2015</p>
                        </div>
                    </div>
                </section>
                <section className="section-misc" style={{ backgroundColor: "rgb(251,251,251)" }}>
                    <div className="container">
                        <p>Your use of this website is governed by the terms and conditions set forth below (the &ldquo;Terms of Use&rdquo;). Please read them carefully. By using this website, you agree to comply with and to be bound by these Terms of Use. If you do not agree to these Terms of Use, you may not use this website and should exit from it now. Landmark Home Warranty, LLC, which owns and operates this website on behalf of itself and its affiliates (collectively, &ldquo;Company,&rdquo; &ldquo;we&rdquo; or &ldquo;us&rdquo;), reserves the right to change this website and these Terms of Use at any time without notice to you by posting such changes to this website.</p>
                        <p className="title">Usage Restrictions</p>
                        <p>You may not copy, distribute, or transmit any of the content of this website without our prior written consent. You may, however, make one copy of any material on this website for personal, non-commercial use (including ensuring compliance with these Terms of Use), provided that you neither modify nor alter the material in any way, nor delete or change any copyright or trademark notice. We reserve complete title and full intellectual property rights for materials copied from this website. We provide the content of this website for lawful purposes only.</p>
                        <p>You may not use any &ldquo;deep-link&rdquo;, &ldquo;page-scrape&rdquo;, &ldquo;robot&rdquo;, &ldquo;spider&rdquo; or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of this website or any of its content, or in any way reproduce or circumvent the navigational structure or presentation of this website or any of its content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through this website. We reserve the right to bar any such activity.</p>
                        <p>You may not attempt to gain unauthorized access to any portion or feature of this website, or any other systems or networks connected to this website or to any of our servers, or to any of the services offered on or through this website, by hacking, password &ldquo;mining&rdquo; or any other illegitimate means.</p>
                        <p>You may not probe, scan or test the vulnerability of this website or any network connected to this website, nor breach the security or authentication measures on this website or any network connected to this website. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this website or any transaction being conducted on this website, or with any other person&rsquo;s use of this website.</p>
                        <p>You may not use this website or any of its content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes our rights or the rights of others.</p>
                        <p>We cannot and do not guarantee or warrant that files made available for downloading through this website will be free of infection or viruses, worms, Trojan horses or other code that manifests contaminating or destructive properties. By accessing this website, you acknowledge and agree that it is your responsibility to implement sufficient safeguards and procedures to ensure that any files obtained through this website are free from such contamination.</p>
                        <p className="title">Links to Other Sites</p>
                        <p>This website contains links to third-party websites. These links are provided solely as a convenience to you and not as an endorsement by us of the companies or the content of any linked third-party websites. We do not control nor are we responsible for the content of linked third-party websites, and we make no representations regarding the content or accuracy of materials on the linked third-party websites. If you access any linked third-party websites, then you do so at your own risk.</p>
                        <p className="title">Privacy and Protection of Personal Information</p>
                        <p>We are committed to protecting your privacy and security. For more information, you should review our <u>Privacy Policy</u>, which is incorporated into these Terms of Use by reference. Additionally, by using this website, you acknowledge and agree that Internet transmissions are never completely private or secure. You understand that any message or information you send to this website may be read or intercepted by others, even if there is a special notice that a particular transmission (for example, credit card information) is encrypted.</p>
                        <p className="title">Accounts, Profiles, Passwords and Security</p>
                        <p>Certain features or services offered on or through this website may require you to open an account (including setting up a username and password) or create a profile for use in applying for something (for example, a job). You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of you failing to keep this information secure and confidential. You agree to notify us immediately of any unauthorized use of your account or password, or any other breach of security. You may be held liable for losses incurred by us or any other user of or visitor to this website due to someone else using your username, password or account as a result of your failing to keep your account information secure and confidential.</p>
                        <p>You may not use anyone else&rsquo;s username, password or account at any time without the express permission and consent of the holder of that username, password or account. We cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations.</p>
                        <p className="title">Trademarks</p>
                        <p>Landmark Home Warranty&reg; and certain other trademarks used on this website are trademarks or registered trademarks of Company or its affiliates. Absent a written license agreement, you may not use these trademarks or any other trademarks belonging to Company or its affiliates except for descriptive purposes. Other trademarks used on this website are the property of their respective owners and are used in this website solely for descriptive purposes. Mention on this website of trademarks held by other parties should not be construed as a challenge to such trademarks' status or ownership.</p>
                        <p className="title">Copyright</p>
                        <p>The entire contents of this website, including but not limited to: text, graphics, logos, button icons, Flash movies, images, audio clips, digital downloads, data compilations and software, are the property of Company, its content suppliers, affiliates or its clients and are protected by domestic and international trade dress, patent, copyright and trademark laws and various other intellectual property rights and unfair competition laws. You may not modify, copy, reproduce, republish, upload, post, transmit or distribute any portion of the website contents without our prior express written consent.</p>
                        <p>Your submissions of files, including, but not limited to: resumes, email, flash movies, images, logos, audio loops and other software hereby constitutes your agreement to grant us a nonexclusive, royalty-free, worldwide, sublicensable, perpetual license to reproduce, distribute, transmit, modify, adapt, sublicense and publicly display any such submissions. You also grant us the right to utilize your name in connection with all advertising, marketing and promotional materials related thereto.</p>
                        <p className="title">International Use</p>
                        <p>Given the global nature of the Internet, you agree to comply with all local rules, including, without limitation, rules about the Internet, data, email, privacy, copyright and trademark infringement. Additionally, you agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside.</p>
                        <p className="title">No Warranty</p>
                        <p>WE PROVIDE THIS WEBSITE AND ITS CONTENT "AS IS" AND WITHOUT ANY REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED, OR STATUTORY. WE SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE DO NOT REPRESENT OR WARRANT THAT THIS WEBSITE WILL BE ACCESSIBLE WITHOUT INTERRUPTION OR THAT THIS WEBSITE, ANY CONTENT FROM THIS WEBSITE, OR THE SERVER THAT MAKES THIS WEBSITE AVAILABLE ARE FREE FROM ERRORS, DEFECTS, DESIGN FLAWS, OMISSIONS, VIRUSES, OR OTHER HARMFUL COMPONENTS. YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK. SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, IN WHICH CASE PORTIONS OF THIS DISCLAIMER MAY NOT APPLY TO YOU.</p>
                        <p className="title">Limitation of Liability</p>
                        <p>IN NO EVENT WILL WE BE LIABLE, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE, OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER THEORY, TO YOU OR ANY OTHER PERSON FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES), ARISING OUT OF OR IN CONNECTION WITH ANY USE OF, THE INABILITY TO USE, OR THE RESULTS OF USE OF THIS WEBSITE OR ITS CONTENT, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN WHICH CASE PORTIONS OF THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT WILL WE BE LIABLE OR RESPONSIBLE FOR ANY ERRORS OR OMISSIONS IN THE CONTENT OF THIS WEBSITE, INCLUDING, WITHOUT LIMITATION, ERRORS IN PRICING OR AVAILABILITY OF SERVICES AND PRODUCTS, OR DAMAGES THAT MAY RESULT FROM MISREPRESENTATION OF AGE BY A USER OF THIS WEBSITE.</p>
                        <p className="title">Indemnification</p>
                        <p>You agree to indemnify, defend and hold harmless Company, its parent, affiliates, shareholders, agents, licensors, suppliers and any third-party information provider to this website, together with their officers, directors, owners, partners and employees, from and against all losses, expenses, damages and costs, including attorney&rsquo;s fees, resulting from: (a) any violation of these Terms of Use (including negligent or wrongful conduct) by you; or (b) your use and access of this website.</p>
                        <p className="title">Violations of these Terms of Use</p>
                        <p>You agree that we, in our sole discretion and without prior notice, may terminate your access to this website and/or block your future access to this website if we determine that you have violated these Terms of Use or other agreements or guidelines which may be associated with your use of this website. You also agree that any violation by you of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to us, for which monetary damages would be inadequate, and you consent to our obtaining any injunctive or equitable relief that we deem necessary or appropriate in such circumstances. These remedies are in addition to any other remedies we may have at law or in equity.</p>
                        <p>You agree that we, in our sole discretion and without prior notice, may terminate your access to this website for cause, which includes: (1) requests by law enforcement or other government agencies, (2) a request by you (self-initiated account deletions), (3) discontinuance or material modification of this website or any service offered on or through this website, or (4) unexpected technical issues or problems.</p>
                        <p className="title">Resolution of Disputes</p>
                        <p><strong>Governing Law/Disputes.</strong>&nbsp;These Terms of Use shall be governed by and construed in accordance with the laws of the State of Tennessee without application of conflict of laws rules. Venue for all Claims (defined below) not subject to arbitration shall be in state or federal courts located in Shelby County, Tennessee.</p>
                        <p><strong>Resolution of any Dispute.</strong>&nbsp;In the event a dispute arises between you and us, we want to provide you with an efficient, neutral and cost-effective means of resolving the dispute. Most concerns can be resolved quickly by contacting us, which may be initiated by clicking on the "Contact Us" link in the side menu. If, however, there is an issue that needs to be resolved, the following terms describe how both of us will proceed:</p>
                        <p><strong>Limitation of Legal Remedies.</strong>&nbsp;INSTEAD OF SUING IN COURT, YOU AND WE EACH AGREE TO ARBITRATE DISPUTES ON A BILATERAL (INDIVIDUAL) BASIS. You and we agree that any dispute, controversy or claim arising out of your use of this website or its content ("Claims") which cannot be settled by mutual agreement of the parties shall be resolved by one arbitrator through binding arbitration. This agreement to arbitrate is intended to be broadly interpreted. It includes Claims based in contract, tort, statute, fraud, misrepresentation or any other legal theory. The arbitrator&rsquo;s decision and award is final and binding, with some exceptions under the Federal Arbitration Act, 9 U.S.C. 1, et seq., and judgment on the award may be entered in any court with jurisdiction.</p>
                        <p><strong>Class Action Waiver.</strong>&nbsp;You and we each agree that each of us is waiving the right to a trial by jury and may bring claims against the other only in an individual capacity and not in a class action or representative proceeding. All arbitrations shall be conducted on an individual (and not a class-wide) basis and an arbitrator shall have no authority to award class-wide relief. You acknowledge and agree that these terms specifically prohibit you from commencing any legal proceedings as a representative of others or joining in any arbitration proceedings brought by any other person.</p>
                        <p><strong>Arbitration Procedures.</strong>&nbsp; A party who intends to seek arbitration must first send to the other a written Notice of Dispute describing the nature and basis of the Claim and setting forth the specific relief sought.All notices to us shall be sent to the following address: Landmark Home Warranty, LLC, 3400 Players Club Parkway, Suite 300, Memphis, TN, 38125, ATTN: Legal Department &ndash; Administrator, Alternative Dispute Resolution. All notices to you will be sent to the email or physical address you have provided. Upon receipt of such notice, the other party shall have a sixty (60) day period in which it may satisfy the Claim against it by fully curing the Claim and/or providing all the relief requested in the notice, and/or entering into a settlement to resolve the Claim to the mutual satisfaction of the parties. After the expiration of such sixty-day cure period, you or we may commence an arbitration proceeding. The arbitration of any claim or dispute under this Agreement shall be referred to the American Arbitration Association (&ldquo;AAA&rdquo;) under its rules and procedures, including the AAA's Commercial Dispute Resolution Procedures and Supplementary Procedures for Consumer-Related Disputes. These rules and procedures are available by calling the AAA or by visiting its website at&nbsp;<a href="https://www.ahs.com/terms-of-use/www.adr.org">www.adr.org</a>. The arbitration of any Claim shall be conducted in the State of Tennessee, and for any non-frivolous claim that does not exceed $10,000, Company will: (1) pay all costs of the arbitration; (2) if you prefer, conduct the arbitration by telephone, and (3) will not seek attorney&rsquo;s fees in the event Company prevails. Each party shall pay the fees and costs of its own counsel, experts and witnesses.</p>
                        <p><strong>Choice of Law.</strong>&nbsp;This Agreement concerns a transaction in interstate commerce, and therefore shall be governed by the United States Federal Arbitration Act (the &ldquo;FAA&rdquo;), 9 U.S.C. &sect; 1 et seq. The FAA&rsquo;s provisions, not state law, govern all questions of whether a dispute is subject to arbitration.</p>
                        <p><strong>Exception.</strong>&nbsp;Any claim or action for indemnification, contribution, interpleader or injunctive relief arising out of a Claim shall not be subject to arbitration.</p>
                        <p className="title">Additional Terms</p>
                        <p>Our failure to insist upon or enforce strict performance of any provision of these Terms of Use shall not be construed as a waiver of any provision or right. Neither the course of conduct between the parties nor trade practice shall act to modify any provision of these Terms of Use. We may assign our rights and duties under these Terms of Use to any party at any time without notice to you. If any of these terms shall be deemed invalid, void or, for any reason, unenforceable, that term shall be deemed severable and shall not affect the validity or enforceability of any remaining term. Any rights not expressly granted herein are reserved.</p>
                        <p className="title">Entire Agreement</p>
                        <p>These Terms of Use constitute the entire agreement between you and us regarding this website. These Terms of Use supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between you and us regarding this website. No modification of these Terms of Use will be effective unless we authorize it.</p>
                        <p className="title">Address of Record</p>
                        <p>Landmark Home Warranty, LLC<br /> 3400 Players Club Parkway, Suite 300<br /> Memphis, TN 38125<br /> United States of America<br /> <br /> Last Updated: 10/15/2020</p>
                    </div>
                </section>
            </main>
        );
    }
}
