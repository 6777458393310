import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { createDispatchActions, DispatchActionTypes } from "../../actions/account/dispatch.actions";
import { PayloadAction } from "../../actions/defs";
import { LoadDispatchDetailsPayload } from "../../reducers/account/dispatch.reducer";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const dispatchActions = createDispatchActions();

const handleCreateNewNote: Epic<
    PayloadAction<Landmark.AddDispatchNoteRequest | Landmark.AddDispatchNoteResponse | Error>
> = action$ => action$.pipe(
    ofType(DispatchActionTypes.CreateNote.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.AddDispatchNoteRequest>) => LandmarkApiService
            .post("/contractor/dispatch/createNote")
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        payload => dispatchActions.createNote.success(payload),
        err => dispatchActions.createNote.failure(err)
    ),
);

const handleLoadDispatchDetails: Epic<
    PayloadAction<LoadDispatchDetailsPayload | Landmark.ContractorDispatchSearchResponseItem | Error>
> = action$ => action$.pipe(
    ofType(DispatchActionTypes.LoadDetails.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<LoadDispatchDetailsPayload>) => LandmarkApiService
            .get(`/contractor/dispatch/${action.payload.workOrderId}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => dispatchActions.loadDetails.success(payload),
        err => dispatchActions.loadDetails.failure(err),
    ),
);

const waitLoadDispatchDetails = createWaitEpic(DispatchActionTypes.LoadDetails);
const waitCreateNewNote = createWaitEpic(DispatchActionTypes.CreateNote);

const epic = combineEpics(
    handleCreateNewNote,
    handleLoadDispatchDetails,
    waitCreateNewNote,
    waitLoadDispatchDetails
);
export default epic;
