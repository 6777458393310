import * as Landmark from "models/landmark-api";
import moment from "moment";

import { ApplicationState } from "../../store/app";
import { createSelector } from "../common.selectors";


const getPaymentState = (state: ApplicationState) => state.account.paymentDetails;

/**
 * Retrieves the payment info from the store.
 */
export const getPaymentInfos = createSelector(
    getPaymentState,
    paymentState => {
        return paymentState.paymentInfos;
    }
);

/**
 * Retrieves the credit card info for those assigned to contracts from the store.
 */
export const getCreditCards = createSelector(
    getPaymentInfos,
    paymentInfos =>
        paymentInfos
            .filter(paymentInfo => paymentInfo.creditCard.policyNumber.length > 0)
            .map(paymentInfo => paymentInfo.creditCard)
);

/**
 * Retrieves the selected payment index info from the store.
 */
export const getSelectedPaymentInfoId = createSelector(
    getPaymentState,
    paymentState => paymentState.selectedPaymentInfoId
);

/**
 * Retrieves the selected payment id from the store.
 */
export const getSelectedPolicyId = createSelector(
    getPaymentState,
    paymentState => paymentState.selectedPolicyId
);

/**
 * Retrieves the selected payment number from the store.
 */
export const getSelectedPolicyNumber = createSelector(
    getPaymentState,
    paymentState => paymentState.selectedPolicyNumber
);

export const getDefaultPaymentInfo = createSelector(
    getPaymentInfos,
    getSelectedPaymentInfoId,
    (paymentInfos, paymentId) => {
        let paymentInfo = null;
        // if they are adding a new payment method
        if (paymentId === null) {
            paymentInfo =  {} as Landmark.PaymentInfo;
        }
        // only one item, return it - more than one, get the one at the selected index
        else {
            paymentInfo = (paymentInfos.length === 1 ?
                paymentInfos[0] :
                paymentInfos
                    .find(pmt => pmt.creditCard.creditCardId === paymentId)
            );
        }

        return paymentInfo;
    }
);

// used for this selector
export interface PolicyDefaultCreditCard {
    policyNumber?: number;
    message: string;
    cardType?: string;
    lastFour?: string;
    expirationDate?: string;
}

export const getDefaultPaymentInfoPerContractNumber = createSelector(
    getCreditCards,
    (creditCards) => {
        const midDot = "\u00b7\u00b7\u00b7\u00b7 ";
        let messagesFinal: PolicyDefaultCreditCard[] = [];
        creditCards
            .map(card => {
                card.policyNumber
                    .map(cardFinal => {
                        const newObject: PolicyDefaultCreditCard = {
                            policyNumber: cardFinal,
                            message: 'Default Payment Method:',
                            cardType: card.cardType,
                            lastFour: midDot + card.lastFour,
                            expirationDate: moment(card.expirationDate).format("MM/YY"),
                        };
                        messagesFinal.push(newObject);
                    });
            });
        return messagesFinal;
    }
);

/**
 * Retrieves details about failed payments and card status from the store.
 */
export const getFailedPaymentDetails = createSelector(
    getPaymentState,
    paymentDetails => paymentDetails.failedPaymentDetails
);

export const getShouldShowPaymentInfo = createSelector(
    getDefaultPaymentInfo,
    getPaymentInfos,
    (defaultPaymentInfo, paymentInfos) => (
            // If we have no payment infos we want to show the "payment info" section
            paymentInfos.length === 0 ||
            // If we have more than one payment info, make sure we have a "default payment info"
            // (this means we were able to to determine which one of the payment infos is the
            // "winner" and should be shown)
            defaultPaymentInfo && paymentInfos.length > 0
    )
);
