import { createSelector } from "./common.selectors";
import { ApplicationState } from "../store/app";

const getForumState = (state: ApplicationState) => state.forum;

/**
 * Gets the selected forum entry.
 */
export const getForumEntry = createSelector(
    getForumState,
    forum => forum.forumEntry
);

/**
 * Gets the selected forum ID.
 */
export const getSelectedForumId = createSelector(
    getForumState,
    forum => forum.selectedForumId
);

/**
 * Returns loaded forum entries for the selected category, or all entries if
 * no category has been selected.
 */
export const getForumEntries = createSelector(
    getForumState,
    getSelectedForumId,
    (forum, selectedForumId) => {
        const entries = forum.forumEntries || [];
        if (selectedForumId) {
            return entries.filter(e => e.categoryForumId === selectedForumId);
        }
        return entries;
    }
);
