import * as React from "react";
import Loading from "react-loading";

import * as Styles from "../styles";

type Options = "spokes" | "spinningBubbles";

interface WaitProps {
    children?: any;
    color?: string;
    delay?: number;
    isWaiting: boolean;
    type?: Options;
    mode?: "fullscreen" | "default";
    style?: {
        outer?: React.CSSProperties;
        container?: React.CSSProperties;
        loader?: React.CSSProperties;
    };
}

export class Wait extends React.Component<WaitProps, any> {
    static defaultProps: WaitProps = {
        color: "#eaeaea",
        delay: 500,
        isWaiting: false,
        mode: "default",
        type: "spinningBubbles",
    };

    /**
     * A react standard method to render this component.
     */
    render() {
        const style = this.props.mode === "fullscreen" ? Styles.Wait.FullScreen : Styles.Wait.Default;
        let { loader, outer } = style;
        let container = style.container(this.props);

        if (this.props.style) {
            // container = Object.assign(container, this.props.style.container);
            // loader = Object.assign(loader, this.props.style.loader);
            // outer = Object.assign(outer, this.props.style.outer);
            container = {...(container || {}), ...(this.props.style.container || {})};
            loader = {...(loader || {}), ...(this.props.style.loader || {})};
            outer = {...(outer || {}), ...(this.props.style.outer || {})};
        }

        return (
            <div style={outer as React.CSSProperties}>
                {/* Always show the loader container, so it animates in-out properly */}
                <div style={container}>
                    {this.props.isWaiting ? (
                        <div style={loader}>
                            <Loading type={this.props.type} color={this.props.color} delay={this.props.delay} />
                        </div>
                    ) : null}
                </div>
                {/* Always show the children behind the loader container */}
                {this.props.children}
            </div>
        );
    }
}
