import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";

import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { createWaitEpic } from "./wait.epic";
import { PayloadAction } from "../actions/defs";
import { createOrderActions, OrderActionTypes } from "../actions/order.actions";
import { LandmarkApiService } from "../services/landmarkApi.service";

const orderActions = createOrderActions();

const handleGeocodeAddressVerification: Epic<
    PayloadAction<Landmark.Address | Landmark.GeocodingResponse | Error >
> = action$ => action$.pipe(
    ofType(OrderActionTypes.GeocodeAddress.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.Address>) => LandmarkApiService
            .post("/geocoding")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.GeocodeAddress.success,
        orderActions.GeocodeAddress.failure
    )
);

const waitForGeocodeAddressVerification = createWaitEpic(OrderActionTypes.GeocodeAddress);

const epic = combineEpics(
    handleGeocodeAddressVerification,
    waitForGeocodeAddressVerification,
);
export default epic;
