import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { filter, map, withLatestFrom, } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { createOrderActions, OrderActionTypes } from "../actions/order.actions";
import { Areas } from "../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { getOrderRequest } from "../selectors/order.selectors";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { ApplicationState } from "../store/app";
import { createToastrEpic } from "./toastr.epic";
import { createWaitEpic } from "./wait.epic";

const orderActions = createOrderActions();

/**
 * Calls the API to validate a coupon code.
 */
const handleValidateCouponCode: Epic<
    PayloadAction<Landmark.OrderRequest | Landmark.ApiResponse<Landmark.ContractCoupon> | Error>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.ValidateCouponCode.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.OrderRequest>) => LandmarkApiService
            .post(`/offers/coupons/validate`)
            // 404 is normal here, ignore it
            .setErrorHandler(null)
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.validateCouponCode.success,
        orderActions.validateCouponCode.failure,
    ),
);

/**
 * Validates the coupon code when it is set.
 */
const handleValidateCouponCodeWhenSet: Epic<
    PayloadAction<string | Error | Landmark.OrderRequest>
> = (action$, state$) => action$.pipe(
    ofType(OrderActionTypes.SET_COUPON_CODE),
    withLatestFrom(state$),
    // Don't validate or apply coupons if no planId has been set.
    // This will make it possible to preload a coupon code before a package is selected.
    // In this case, validation will happen after the package has been selected.
    filter(([action, state]: [PayloadAction<string>, ApplicationState]) => {
        const { planId, coupon } = getOrderRequest(state);
        const { payload: couponCode } = action;

        return planId &&
            couponCode &&
            couponCode.length > 1 &&
            (
                !coupon ||
                coupon.couponCode !== couponCode
            );
    }),
    map(([action, state]: [PayloadAction<string>, ApplicationState]) => {
        const request = getOrderRequest(state);
        const couponCode = action.payload;
        const { addons, planId, property } = request;

        // Build a simple order for coupon validation
        const orderRequest = {
            coupon: {
                couponCode
            },
            addons,
            planId,
            property,
        } as Landmark.OrderRequest;

        // Validate the coupon
        return orderActions.validateCouponCode.begin(orderRequest);
    }),
);

const notifyUserOnValidCoupon = createToastrEpic(
    [OrderActionTypes.ValidateCouponCode.SUCCESS],
    (action: PayloadAction<Landmark.ApiResponse<Landmark.ContractCoupon>>) => {
        return {
            type: "success",
            title: "Coupon Code",
            message: `You just saved $${action.payload.result.amount.toFixed(0)}!`,
            options: { timeOut: 10000 },
        };
    }
);

const notifyUserOnInvalidCoupon = createToastrEpic(
    [OrderActionTypes.ValidateCouponCode.FAILURE],
    action => ({
        type: "error",
        title: "Coupon Code",
        message: "Sorry, we couldn't find the coupon code you provided.",
        options: { timeOut: 10000 },
    })
);

const waitForValidateCoupon = createWaitEpic(OrderActionTypes.ValidateCouponCode, Areas.Order.Coupon);

const epic = combineEpics(
    handleValidateCouponCode,
    handleValidateCouponCodeWhenSet,
    notifyUserOnInvalidCoupon,
    notifyUserOnValidCoupon,
    waitForValidateCoupon,
);
export default epic;
