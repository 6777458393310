import update from "immutability-helper";
import * as Landmark from "models/landmark-api";

import { createSelector } from "./common.selectors";
import { PlanType } from "../constants/TransactionType";
import { ApplicationState } from "../store/app";

const getOrderState = (state: ApplicationState) => state.order;

export const getCurrentOrderState = createSelector(
    getOrderState,
    orderState => orderState,
);
/**
 * Gets the address for the upgrading account.
 */
export const getUpgradingAddress = createSelector(
    getOrderState,
    (state: ApplicationState) => state.account,
    (order, account) => {
        order != null &&
            order.upgradeContractId !=null &&
            account.contracts.list != null &&
            account.contracts.list.length > 0 ?
                account.contracts.list
                    .filter(
                        contract => contract.contractId === order.upgradeContractId
                    )[0].address :
                null;
    }
);
/**
 * Gets the budget amount for the order.
 */
export const getBudgetAmount = createSelector(
    getOrderState,
    order => order.budgetAmount,
);

/**
 * Gets a list of completed areas (tabs) for the order.
 */
export const getCompletedOrderAreas = createSelector(
    getOrderState,
    (state: ApplicationState) => state.order,
    order => order.categories.completed,
);

/**
 * Returns true if the user has acknowledged the contract wait period.
 */
export const getHasAcknowledgedWaitPeriod = createSelector(
    getOrderState,
    order => order.hasAcknowledgedWaitPeriod,
);

/**
 * Returns true if the order requires that the user accept the claims disclosure.
 */
export const getRequiresAcceptanceOfClaimsDisclosure = createSelector(
    getOrderState,
    order => {
        // Is this a renewal?
        if (order.contractId && !order.isConvertingFromListingCoverage) {
            return false;
        }
        // Is this an RE order?
        else if (order.planTypeId === PlanType.RealEstate) {
            return false;
        }
        return true;
    }
);

export const getIsConvertingFromListingCoverage = createSelector(
    getOrderState,
    order => order.isConvertingFromListingCoverage
);

export const getIsListingCoverage = createSelector(
    getOrderState,
    order => order.isListingCoverage
);

export const getIsWebPolicy = createSelector(
    getOrderState,
    order => order.isWebPolicy
);

/**
 * Returns true if the payment is monthly.
 */
export const getIsPayingMonthly = createSelector(
    getOrderState,
    order => order.payment && order.payment.type === "monthly"
);

/**
 * Returns true if the order is a new consumer order.
 */
export const getIsNewOrderConsumer = createSelector(
    getOrderState,
    order => {
        return (
            (
                // A consumer transaction type
                order.planTypeId === PlanType.Homeowner ||
                // A real-estate transaction type, but the ordering party IS the homeowner
                order.orderingPartyType === "homeBuyer"
            ) &&
            // The consumer is not renewing
            order.contractId == null
        );
    }
);

/**
 * Returns true if current date is less than 02-01-2023
*/
export const getCanOrder = createSelector(
    getOrderState,
    order => order.canOrder === Date.parse(new Date().toJSON().slice(0, 10)) < Date.parse("2023-02-01")
);

/**
 * Returns true if the user is renewing an existing policy.
 */
export const getIsRenewing = createSelector(
    getOrderState,
    order => !!order.contractId  && !order.isConvertingFromListingCoverage
);

/**
 * Returns true if the user is renewing an existing policy.
 */
export const getIsUpgrading = createSelector(
    (state: ApplicationState) => state.order,
    order => order  && order.isUpgrading
);

/**
 * Gets the property currently on the order.
 */
export const getOrderProperty = createSelector(
    getOrderState,
    order => {
        if (order && order.property) {
            // Ensure a minimum amount of information is available on the property
            update(order, {
                address: { $set: order.property.address || {} }
            });
            return update(order.property, {
                address: { $set: order.property.address || {} }
            }) as Landmark.Property;
        }
        return {
            address: {}
        } as Landmark.Property;
    }
);

/**
 * Gets the contract id for the (renewal) order.
 */
export const getOrderContractId = createSelector(
    getOrderState,
    order => order && order.contractId,
);

export const getCurrentCoverageLength = createSelector(
    getOrderState,
    order => order && order.coverageLength,
);

/**
 * Gets the new contract id for the order.
 */
export const getNewlyOrderedContractId = createSelector(
    getOrderState,
    order => order && order.newlyOrderedContractId,
);

// TODO: this should be moved to the API
export interface PartnerType {
    isSelectable: boolean;
    value: Landmark.PartyType;
    display: string;
}
const partnerTypes: PartnerType[] = [
    {
        isSelectable: false,
        display: "Home Buyer",
        value: Landmark.PartyType.HomeBuyer,
    },
    {
        isSelectable: true,
        display: "Buyer's Agent",
        value: Landmark.PartyType.BuyerAgent,
    },
    {
        isSelectable: true,
        display: "Listing Agent",
        value: Landmark.PartyType.SellerAgent,
    },
    {
        isSelectable: true,
        display: "Escrow Officer",
        value: Landmark.PartyType.TitleCompany,
    }
    //{
    //    isSelectable: true,
    //    display: "Assistant",
    //    value: Landmark.PartyType.Assistant,
    //},
    // TODO: This stuff probably needs to be added back in... not sure how we handle for these scenarios...
    //{
    //    type: "propertyManager",
    //    specific: "propertyManager",
    //    display: "Property Manager",
    //},
    //{
    //    type: "mortgageProfessional",
    //    specific: "mortgageProfessional",
    //    display: "Mortgage Professional",
    //},
];

/**
 * Gets the current coupon code if there is one, or null if there is not one.
 */
export const getCurrentCouponCode = createSelector(
    getOrderState,
    order => (order.coupon && order.coupon.couponCode) || null
);

export const getPartnerTypes = createSelector(
    getOrderState,
    order => partnerTypes,
);

export const getPartnerSearchResults = createSelector(
    getOrderState,
    order => order.searchResults || [],
);

/**
 * Gets the plan type ID for the order.
 */
export const getOrderPlanTypeId = createSelector(
    getOrderState,
    order => order.planTypeId,
);

/**
 * Gets the marketing source ID for the order.
 */
export const getMarketingSourceId = createSelector(
    getOrderState,
    order => order.marketingSourceId,
);

/**
 * Gets the payment information for the order.
 */
export const getOrderPayment = createSelector(
    getOrderState,
    order => order.payment,
);

/**
 * Gets the price breakdown of the order quote.
 */
export const getPriceBreakdown = createSelector(
    getOrderState,
    order => {
        // This will enforce that we don't give back invalid data. Too many places expected items to be an array, which failed sometimes.
        if (!order.priceBreakdown || !Array.isArray(order.priceBreakdown.items) ) {
            return {
                items: [],
                monthlyTotal: 0,
                planTypeId: null,
                timestamp: null,
                total: 0,
            } as Landmark.ContractItemResponse;
        }

        return order.priceBreakdown;
    }
);

/**
 * Gets partners selected for the order.
 */
export const getSelectedPartners = createSelector(
    getOrderState,
    order => order.selectedPartners || {},
);

/**
 * Gets the selected offer.
 */
export const getSelectedOfferId = createSelector(
    getOrderState,
    order => order.selectedOfferId,
);

/**
 * Gets the selected area (tab) of the order.
 */
export const getSelectedOrderArea = createSelector(
    getOrderState,
    order => order.categories.selected,
);

/**
 * Gets the total difference between the policy current total to the new offers total.
 */
export const getTotalDue = createSelector(
    getOrderState,
    (state: ApplicationState) => state.offers,
    (orders, offers) => {
        if (
            orders.isUpgrading &&
            orders.priceBreakdown &&
            orders.priceBreakdown.items &&
            offers &&
            offers.response &&
            offers.response.current
        ) {

            const sumOfItems = (sum, item) => sum + (item.cost * item.quantity);

            // See if we have any buyers credit and subtract it from the total
            let buyersCredit = orders.priceBreakdown.items
                .filter(item => item.type === "credit")
                .reduce(sumOfItems, 0);

            const offerTotalMonthly = orders.priceBreakdown.monthlyTotal;
            const currentTotalMonthly = offers.response.current.priceBreakdown.monthlyTotal;

            const offerTotalFull = orders.priceBreakdown.total;
            const currentTotalFull = offers.response.current.priceBreakdown.total;

            let totalDifferenceMonthly = offerTotalMonthly - currentTotalMonthly;
            let totalDifferenceFull = offerTotalFull - currentTotalFull;

            if (
                (totalDifferenceMonthly - (buyersCredit/12) <= 0) ||
                (totalDifferenceFull - buyersCredit <= 0)
            ) {
                totalDifferenceMonthly = 0;
                totalDifferenceFull = 0;
            }
            else {
                totalDifferenceMonthly -= (buyersCredit/12);
                totalDifferenceFull -= buyersCredit;
            }

            return {
                monthlyTotal: totalDifferenceMonthly,
                total: totalDifferenceFull
            };
        }
        return {
            monthlyTotal: 0,
            total: 0
        };
    }
);

/**
 * Gets the order request.
 */
export const getOrderRequest = createSelector(
    getOrderState,
    getSelectedPartners,
    getTotalDue,
    (order, selectedPartners, totalDue) => {
        const request = {
            addons: order.addons,
            assistant: selectedPartners.assistant,
            buyerAgent: selectedPartners.buyerAgent,
            buyersCredit: order.buyersCredit,
            canOrder: order.canOrder,
            contactPreferences: order.contactPreferences,
            contacts: order.contacts,
            contractId: order.contractId,
            coverageLength: order.coverageLength,
            creditAmountToBeConsumed: order.creditAmountToBeConsumed,
            estimatedCloseDate: order.estimatedCloseDate,
            homeServicesInterest: order.homeServicesInterest,
            isConvertingFromListingCoverage: order.isConvertingFromListingCoverage,
            isAutoRenew: order.isAutoRenew,
            isKeepingCurrentCoverage: order.isKeepingCurrentCoverage,
            isListingCoverage: order.isListingCoverage,
            isRecentPurchase: order.isRecentPurchase,
            isRenewing: order.isRenewing,
            isUpgrading: order.isUpgrading,
            loanOfficer: selectedPartners.loanOfficer,
            orderingPartyType: order.orderingPartyType,
            owner: order.owner,
            planId: order.planId,
            payment: order.payment,
            planTypeId: order.planTypeId,
            marketingSourceId: order.marketingSourceId,
            prepaidServiceCallFees: order.prepaidSCFFees,
            property: order.property,
            removeACCoverage: order.removeACCoverage,
            seller: order.seller,
            sellerAgent: selectedPartners.sellerAgent,
            serviceCallPrice: order.selectedServiceCallPrice,
            shouldUseCredit: order.shouldUseCredit,
            titleCompany: selectedPartners.titleCompany,
            isAddressVerified: order.isAddressVerified
        } as Landmark.OrderRequest;

        if (!!order.coupon &&
            !!order.coupon.contractCouponId &&
            order.coupon.contractCouponId.length > 0) {
            // Only include the coupon if a valid code was provided
            request.coupon = order.coupon;
        }

        const payment = order.payment;
        if (payment) {
            if (order.isUpgrading) {
                payment.paymentAmount = payment.type === "monthly" ?
                        totalDue.monthlyTotal :
                        totalDue.total;
            }
            else {
                if (order.priceBreakdown) {
                    payment.paymentAmount = payment.type === "monthly" ?
                        order.priceBreakdown.monthlyTotal :
                        order.priceBreakdown.total;
                }
                else {
                    payment.paymentAmount = null;
                }
            }

            if (payment.billingAddressSameAsProperty) {
                // If the billing address is the same, remove the billing address
                delete request.payment.billingAddress;
            }
        }

        return request;
    }
);

export const getBaseOrderRequest = createSelector(
    getOrderState,
    getSelectedPartners,
    getTotalDue,
    (order, selectedPartners, totalDue) => {
        const request = {
            addons: order.addons,
            assistant: selectedPartners.assistant,
            buyerAgent: selectedPartners.buyerAgent,
            buyersCredit: order.buyersCredit,
            canOrder: order.canOrder,
            contactPreferences: order.contactPreferences,
            contacts: order.contacts,
            contractId: order.contractId,
            coverageLength: 1,
            creditAmountToBeConsumed: order.creditAmountToBeConsumed,
            estimatedCloseDate: order.estimatedCloseDate,
            homeServicesInterest: order.homeServicesInterest,
            isConvertingFromListingCoverage: order.isConvertingFromListingCoverage,
            isAutoRenew: order.isAutoRenew,
            isKeepingCurrentCoverage: order.isKeepingCurrentCoverage,
            isListingCoverage: order.isListingCoverage,
            isRecentPurchase: order.isRecentPurchase,
            isRenewing: order.isRenewing,
            isUpgrading: order.isUpgrading,
            loanOfficer: selectedPartners.loanOfficer,
            orderingPartyType: order.orderingPartyType,
            owner: order.owner,
            planId: order.planId,
            payment: order.payment,
            planTypeId: order.planTypeId,
            marketingSourceId: order.marketingSourceId,
            prepaidServiceCallFees: order.prepaidSCFFees,
            property: order.property,
            removeACCoverage: order.removeACCoverage,
            seller: order.seller,
            sellerAgent: selectedPartners.sellerAgent,
            serviceCallPrice: order.selectedServiceCallPrice,
            shouldUseCredit: order.shouldUseCredit,
            titleCompany: selectedPartners.titleCompany,
            isAddressVerified: order.isAddressVerified
        } as Landmark.OrderRequest;

        if (!!order.coupon &&
            !!order.coupon.contractCouponId &&
            order.coupon.contractCouponId.length > 0) {
            // Only include the coupon if a valid code was provided
            request.coupon = order.coupon;
        }

        const payment = order.payment;
        if (payment) {
            if (order.isUpgrading) {
                payment.paymentAmount = payment.type === "monthly" ?
                    totalDue.monthlyTotal :
                    totalDue.total;
            }
            else {
                if (order.priceBreakdown) {
                    payment.paymentAmount = payment.type === "monthly" ?
                        order.priceBreakdown.monthlyTotal :
                        order.priceBreakdown.total;
                }
                else {
                    payment.paymentAmount = null;
                }
            }

            if (payment.billingAddressSameAsProperty) {
                // If the billing address is the same, remove the billing address
                delete request.payment.billingAddress;
            }
        }

        return request;
    }
);

/**
 * Gets the payment type for the order.
 */
export const getOrderPaymentType = createSelector(
    getOrderState,
    order => order.payment && order.payment.type
);

/**
 * Gets the upgrade contract id.
 */
export const getUpgradingContractId = createSelector(
    getOrderState,
    order => order.upgradeContractId
);

/**
 * Gets the upgrade contract payment method.
 */
export const getUpgradingPaymentMethod = createSelector(
    getOrderState,
    order => order.contractPaymentType
);

/**
 * If they have selected to use the suggested address from geo code.
 */
export const getUsingSuggestedAddress = createSelector(
    getOrderState,
    order => order.usingSuggestedAddress,
);

export const getAuthNetProfileId = createSelector(
    getOrderState,
    order => order.authNetProfileId,
);
