import * as Landmark from "models/landmark-api";
import * as React from "react";
import { combineEpics, Epic, ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { createProfileActions, ProfileActionTypes } from "../actions/account/profile.actions";
import { PayloadAction } from "../actions/defs";
import { createDialogActions } from "../actions/dialog.actions";
import { CallUs } from "../components/CallUs";
import { Areas } from "../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createToastrEpic } from "./toastr.epic";

const editProfileDialog = createDialogActions(Areas.Account.Profile.Edit);
const profileActions = createProfileActions();

const handleCloseDialogOnEditProfileSuccess: Epic<
    PayloadAction<string>
> = action$ => action$.pipe(
    ofType(ProfileActionTypes.Save.SUCCESS),
    map(editProfileDialog.close),
);

const handleSaveContactPreferences: Epic<
    PayloadAction<Landmark.SmsOptIn | void | Error>
> = action$ => action$.pipe(
    ofType(ProfileActionTypes.SetSmsOptIn.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.SmsOptIn>) => LandmarkApiService
            .post("/account/user/smsOptIn")
            .withAuthentication()
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        profileActions.setSmsOptIn.success,
        profileActions.setSmsOptIn.failure
    ),
);

const handleSaveProfile: Epic<
    PayloadAction<Landmark.EditProfileRequest | Landmark.EditProfileResponse | Error>
> = action$ => action$.pipe(
    ofType(ProfileActionTypes.Save.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.EditProfileRequest>) => LandmarkApiService
            .post("/account/user")
            .withAuthentication()
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        profileActions.save.success,
        profileActions.save.failure
    ),
);

const notifyUserOnFailure = createToastrEpic(
    [ProfileActionTypes.Save.FAILURE],
    action => ({
        type: "error",
        title: "Edit Profile Error",
        message: "",
        options: {
            component:
                React.createElement(
                    "p",
                    null,
                    "Error occurred while trying to update your profile. Please call us for assistance at ",
                    React.createElement(CallUs)
                )
        }
    })
);

const notifyUserSmsOptInOnSuccess = createToastrEpic(
    [ProfileActionTypes.SetSmsOptIn.SUCCESS],
    action => ({
        type: "success",
        title: "Sms Text",
        message: "You have successfully been enrolled to recieve updates through text messaging.",
    })
);

const notifyUserSmsOptInOnFailure = createToastrEpic(
    [ProfileActionTypes.SetSmsOptIn.FAILURE],
    action => ({
        type: "error",
        title: "Sms Text Error",
        message: "",
        options: {
            component:
                React.createElement(
                    "p",
                    null,
                    "Error occurred while trying opt into Text messaging. Please call us for assistance at ",
                    React.createElement(CallUs)
                )
        }
    })
);

const epic = combineEpics(
    handleCloseDialogOnEditProfileSuccess,
    handleSaveContactPreferences,
    handleSaveProfile,
    notifyUserOnFailure,
    notifyUserSmsOptInOnFailure,
    notifyUserSmsOptInOnSuccess,
);
export default epic;
