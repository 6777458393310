import * as Landmark from "models/landmark-api";
import { toastr } from "react-redux-toastr";

import { Api, ApiContext, ApiService, ArrayBufferResponse, joinUrl, JsonResponse } from "./api.service";
import environment from "../environment";

export class ApiAuthActions {
    clear: () => Promise<void>;
    get: () => Landmark.AuthenticationResponse;
    isExpired: () => boolean;
    refresh: () => Promise<Landmark.AuthenticationResponse>;
}

let checkStatusPromise;
const isUp = async () => {
    if (checkStatusPromise) {
        return await checkStatusPromise;
    }
    else {
        checkStatusPromise = ApiService.get(`${LandmarkApiService.url}/status`).fetch();
    }

    try {
        let response = await checkStatusPromise;
        if (response &&
            response.response) {
            if (response.response.status === 404 || response.response.status === 502) {
                return false;
            }
            return true;
        }
    }
    catch (err) {
        return false;
    }
    finally {
        checkStatusPromise = null;
    }
};

type FetchResponse = Error | JsonResponse | ArrayBufferResponse;
type FetchErrorHandler = (handler: FetchResponse) => void;

const defaultErrorHandler = async (response: FetchResponse) => {
    if (// An exception was thrown along the way
        response instanceof Error ||
        // HTTP request didn't even happen
        !response.response ||
        // Not found or server error
        response.response.status === 404 ||
        response.response.status === 502) {
        if (!(response as JsonResponse).json) {
            isUp().then(isUp => {
                // If the server status also returns 404 then we notify the user that we're down.
                // FIXME: this should be dispatched (or perhaps published as an observable) so anyone can handle as they will.
                if (!isUp) {
                    toastr.error("Server Error", "Error communicating with the server. Please try again later.", { timeOut: 10000, transitionIn: "fadeIn", transitionOut: "fadeOut" });
                }
            });
        }
    }
};

export class LandmarkApiContext extends ApiContext {
    private authenticate = false;
    public auth: ApiAuthActions;
    private handler: FetchErrorHandler;

    setErrorHandler(handler: FetchErrorHandler) {
        this.handler = handler;
        return this;
    }

    withAuthentication() {
        this.authenticate = true;
        return this;
    }

    async fetch() {
        // NOTE: super keyword doesn't work in async methods in Babel (yet)
        const self = this;

        if (this.authenticate) {
            if (this.auth && this.auth.isExpired()) {
                // Refresh the token and get the response
                const r = await self.auth.refresh();
                if (r) {
                    // Use the token for authorization
                    self.headers({"Authorization": `Bearer ${r.access_token}`});
                }
                return await ApiContext.prototype.fetch.call(self);
            }
            else {
                // Get the current token
                const response = self.auth.get();
                if (response) {
                    // Use the token for authorization
                    self.headers({"Authorization": `Bearer ${response.access_token}`});
                }
            }
        }

        try {
            return await ApiContext.prototype.fetch.call(self);
        }
        catch (err) {
            if (self.handler) {
                self.handler(err);
            }
            throw err;
        }
    }
}

export class LandmarkApi extends Api<LandmarkApiContext> {
    private auth: ApiAuthActions;
    private handler: FetchErrorHandler;

    get url() {
        return environment.webApiUrl;
    }

    constructor() {
        super();

        this.setErrorHandler(defaultErrorHandler);
    }

    configure = (auth: ApiAuthActions) => {
        this.auth = auth;
    }

    setErrorHandler = (handler: FetchErrorHandler) => {
        this.handler = handler;
    }

    buildContext(url, method): LandmarkApiContext {
        let { auth, handler } = this;
        const api = this;
        return new LandmarkApiContext((context: LandmarkApiContext) => {
            context.auth = auth;
            context.method(method);
            context.setErrorHandler(handler);
            // Join the base URL with the desired URL
            context.url(joinUrl(api.url, url));
        });
    }
}

export const LandmarkApiService = new LandmarkApi();
