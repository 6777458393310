import * as Landmark from "models/landmark-api";
import update from "immutability-helper";
import { Action } from "redux";

import { PayloadAction } from "../actions/defs";
import { ReviewActionTypes } from "../actions/review.actions";

export interface ReviewState {
    customer: Landmark.Review[];
    contractor: Landmark.Review[];
    homeowner: Landmark.Review[];
    realEstate: Landmark.Review[];
}

const defaultState: ReviewState = {
    customer: [],
    contractor: [],
    homeowner: [],
    realEstate: [],
};

export function ReviewReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.Review[]>
) {
    switch (action.type) {
        case ReviewActionTypes.LoadCustomers.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.Review[]>;
            return update(state, {
                customer: { $set: loadAction.payload }
            });
        }
        case ReviewActionTypes.LoadContractors.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.Review[]>;
            return update(state, {
                contractor: { $set: loadAction.payload }
            });
        }
        case ReviewActionTypes.LoadHomeowners.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.Review[]>;
            return update(state, {
                homeowner: { $set: loadAction.payload }
            });
        }
        case ReviewActionTypes.LoadRealEstate.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.Review[]>;
            return update(state, {
                realEstate: { $set: loadAction.payload }
            });
        }
        default: break;
    }

    // If nothing changed, return the original state
    return state;
}
