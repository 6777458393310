import * as React from "react";
import * as ReactDOM from "react-dom";

interface Props {
    autoFocus?: boolean;
    disabled?: boolean;
    id?: string;
    getDisplayName?: (item: any) => string;
    getValue?: (item: any) => string;
    name?: string;
    onBlur?: React.ChangeEventHandler<HTMLSelectElement>;
    onChange?: React.FormEventHandler<HTMLSelectElement>;
    listItems?: any;
    listItemsFormatted?: any;
    style?: React.CSSProperties;
    value?: any;
}

const styles = {
    select: {
        //border: "1px solid blue"
    }
};

class DropDownControls {
    input: HTMLSelectElement;
}

//README: DropDown needs to either have a listItems or a listItemsFormatted object provided to render.
export class DropDown extends React.Component<Props, any> {
    static defaultProps: Props = {
        disabled: false,
        getDisplayName: item => item,
        getValue: item => item,
        id: "",
        listItems: [],
        listItemsFormatted: [],
    };

    controls = new DropDownControls();

    /**
     * Moves focus to the drop down.
     */
    focus = () => {
        const node = ReactDOM.findDOMNode(this.controls.input) as HTMLSelectElement;
        if (node) {
            node.focus();
        }
    }

    handleBlur = e => {
        if (this.props.onBlur) {
            this.props.onChange(e);
        }
    }

    handleChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    get value() {
        return this.controls.input.value;
    }

    renderListItems = () => {
        if (this.props.listItemsFormatted.length > 0) {
            return this.props.listItemsFormatted.map((item) => (item));
        }
        return this.props.listItems.map((item, i) => (
            <option key={i} value={this.props.getValue(item)}>{this.props.getDisplayName(item)}</option>
        ));
    }

    render() {
        const events = {
            onBlur: this.handleBlur,
            onChange: this.handleChange,
        };

        return (
            <div className="dropdown">
                <select
                    {...events}
                    disabled={this.props.disabled}
                    id={this.props.id}
                    name={this.props.name}
                    ref={input => this.controls.input = input}
                    autoFocus={this.props.autoFocus}
                    style={this.props.style ? this.props.style : styles.select}
                    value={this.props.value}>
                    { this.renderListItems() }
                </select>
            </div>
        );
    }
}
