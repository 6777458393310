import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";

import { createPaymentActions, PaymentActionTypes } from "../../actions/account/payment.actions";
import { PayloadAction } from "../../actions/defs";
import { createDialogActions } from "../../actions/dialog.actions";
import { Areas } from "../../constants/Areas";
import { getSelectedPolicyId } from "../../selectors/account/payment.selectors";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createToastrEpic } from "../toastr.epic";
import { createWaitEpic } from "../wait.epic";
import { ApplicationState } from "store/app";

const paymentActions = createPaymentActions();
const editProfileDialog = createDialogActions(Areas.Account.Profile.EditPaymentInfo);
const selectDefaultPaymentMethodDialog = createDialogActions(Areas.Account.Profile.SelectDefaultPaymentMethod);

/**
 * Epic that makes an API call to request failed payments.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.GetFailedPayments.BEGIN, then the epic will call the API and
 * dispatch the result as a Success action. If an error is recieved, then dispatch a failure action.
 *
 * @see PaymentActionTypes.GetFailedPayments
 */
const handleGetFailedPayments: Epic<PayloadAction<Landmark.FailedPaymentListResponse | Error>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.GetFailedPayments.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/account/payment/failed")
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => paymentActions.getFailedPayments.success(payload),
        err => paymentActions.getFailedPayments.failure(err)
    ),
);

/**
 * Epic that makes an API call to retrieve payment info.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.GetPaymentInfo.BEGIN, then the epic will call the API and
 * dispatch the result as a Success action. If an error is recieved, then dispatch a failure action.
 *
 * @see PaymentActionTypes.GetPaymentInfo
 */
const handleGetPaymentInfo: Epic<PayloadAction<Landmark.PaymentInfo[] | Error>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.GetPaymentInfos.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/account/payment/info")
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        payload => paymentActions.getPaymentInfos.success(payload),
        err => paymentActions.getPaymentInfos.failure(err)
    ),
);

/**
 * Epic that makes an API call to process a payment.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.ProcessPayment.BEGIN, then the epic will call the API and
 * dispatch the result as a Success action. If an error is recieved, then dispatch a failure action.
 *
 * @see PaymentActionTypes.ProcessPayment
 */
const handleProcessPayment: Epic<PayloadAction<Landmark.PaymentResponse | Error>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.ProcessPayment.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .put("/account/payment")
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        payload => paymentActions.processPayment.success(payload),
        err => paymentActions.processPayment.failure(err)
    ),
);

/**
 * Epic that makes an API call to update payment information.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.UpdatePaymentInfo.BEGIN, then the epic will call the API and
 * dispatch a Success action. If an error is recieved, then dispatch a failure action.
 *
 * @see PaymentActionTypes.UpdatePaymentInfo
 */
const handleUpdatePaymentInfo: Epic<PayloadAction<Landmark.PaymentInfo | Error | Landmark.CreditCard>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.UpdatePaymentInfo.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService.post("/account/payment/info")
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        paymentActions.updatePaymentInfo.success,
        paymentActions.updatePaymentInfo.failure
    ),
);

/**
 * Epic that makes an API call to delete a credit card.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.DeleteCreditCard.BEGIN, then the epic will call the API and
 * dispatch a Success action. If an error is recieved, then dispatch a failure action.
 *
 * @see PaymentActionTypes.DeleteCreditCard
 */
const handleDeleteCreditCard: Epic<PayloadAction<string | Error | boolean>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.DeleteCreditCard.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService.delete(`/account/payment/${action.payload}`)
            .withAuthentication()
            .fetch(),
        payload => paymentActions.deleteCreditCard.success(),
        err => paymentActions.deleteCreditCard.failure(err)
    )
);


/**
 * Epic that makes an API call to set a credit card default for a policy.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.SetPolicyDefaultPaymentMethod.BEGIN, then the epic will call the API and
 * dispatch a Success action. If an error is recieved, then dispatch a failure action.
 *
 * @see PaymentActionTypes.SetPolicyDefaultPaymentMethod
 */
const handleSetPolicyDefaultPaymentMethod: Epic<PayloadAction<Landmark.SetPolicyDefaultPaymentMethodRequest | Error | number>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.SetPolicyDefaultPaymentMethod.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.SetPolicyDefaultPaymentMethodRequest>) => LandmarkApiService
            .post(`/account/payment/setDefaultPaymentMethod`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(),
        payload => paymentActions.setPolicyDefaultPaymentMethod.success(payload),
        err => paymentActions.setPolicyDefaultPaymentMethod.failure(err),
    ),
);

/**
 * Epic that will map a successful payment info edit to close the open dialog.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.UpdatePaymentInfo.SUCCESS, then the epic dispatch a
 * editProfileDialog.Close action.
 *
 * @see PaymentActionTypes.UpdatePaymentInfo
 * @see PaymentActionTypes.GetPaymentInfo
 */
const handleCloseDialogOnEditPaymentInfoSuccess: Epic<Action> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.UpdatePaymentInfo.SUCCESS),
    map(editProfileDialog.close),
);

/**
 * Epic that will map a successful payment info update to request getting payment info.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.UpdatePaymentInfo.SUCCESS, then the epic dispatch a
 * PaymentActionTypes.GetPaymentInfo.BEGIN action.
 *
 * @see PaymentActionTypes.UpdatePaymentInfo
 * @see PaymentActionTypes.GetPaymentInfo
 */
const handleUpdatePaymentInfoSuccessful: Epic<Action> = (action$, state$) => action$
.pipe(
    ofType(PaymentActionTypes.UpdatePaymentInfo.SUCCESS),
    withLatestFrom(state$),
    map(([action, state]: [PayloadAction<Landmark.CreditCard>, ApplicationState]) => {
        const policyId = getSelectedPolicyId(state);
        if (policyId !== null && action.payload.creditCardId) {
            const creditCardId = action.payload.creditCardId;
            return paymentActions.setPolicyDefaultPaymentMethod.begin({
                policyId: policyId,
                creditCardId: creditCardId,
            });
        }
        return paymentActions.getPaymentInfos.begin();
    }),
);

/**
 * Epic that will map a successful credit card deletion to request getting payment info.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.DeleteCreditCard.SUCCESS, then the epic dispatch a
 * PaymentActionTypes.GetPaymentInfo.BEGIN action.
 *
 * @see PaymentActionTypes.DeleteCreditCard
 * @see PaymentActionTypes.GetPaymentInfo
 */
const handleDeleteCreditCardSuccessful: Epic<Action> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.DeleteCreditCard.SUCCESS),
    map(() => paymentActions.getPaymentInfos.begin()),
);

/**
 * Epic that will map a successful default credit card assignment for a policy.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.SetPolicyDefaultPaymentMethod.SUCCESS, then the epic dispatch a
 * PaymentActionTypes.GetPaymentInfo.BEGIN action.
 *
 * @see PaymentActionTypes.SetPolicyDefaultPaymentMethod
 * @see PaymentActionTypes.GetPaymentInfo
 */
const handleSetPolicyDefaultPaymentMethodSuccesful: Epic<Action> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.SetPolicyDefaultPaymentMethod.SUCCESS),
    mergeMap(() => [selectDefaultPaymentMethodDialog.close(), paymentActions.getPaymentInfos.begin()]),
);

/**
 * Epic that creates a toast when the default payment method is successfully set.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.SetPolicyDefaultPaymentMethod.SUCCESS, then the epic will create a toast.
 *
 * @see PaymentActionTypes.SetPolicyDefaultPaymentMethod
*/
const notifySetDefaultPaymentMethodSuccessful = createToastrEpic(
    [PaymentActionTypes.SetPolicyDefaultPaymentMethod.SUCCESS],
    (action: PayloadAction<boolean>) => {
        return {
            type: "success",
            title: "Default Payment Method",
            message: `Default payment method successfully set. All past due premium invoices will be processed using the default payment method.`,
            options: {
                showCloseButton: true,
                timeOut: 10000,
            },
        };
    }
);

/**
 * Epic that creates a toast when the attempt to set the default payment method is unsuccessful.
 *
 * @param action$ - The action dispatched; If the action is of type PaymentActionTypes.SetPolicyDefaultPaymentMethod.FAILURE, then the epic will create a toast.
 *
 * @see PaymentActionTypes.SetPolicyDefaultPaymentMethod
 */
const notifySetDefaultPaymentMethodFailure = createToastrEpic(
    [PaymentActionTypes.SetPolicyDefaultPaymentMethod.FAILURE],
    (action: PayloadAction<boolean>) => {
        return {
            type: "error",
            title: "Error",
            message: `Unable to set the default payment method`,
            options: {
                showCloseButton: true,
                timeOut: 10000,
            },
        };
    }
);

const handleSyncPaymentProfiles: Epic<PayloadAction<void | Error | number>> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.SyncPaymentProfiles.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/account/payment/syncPaymentProfiles")
            .withAuthentication()
            .fetch()
            .then(),
        payload => paymentActions.syncPaymentProfiles.success(payload),
        err => paymentActions.syncPaymentProfiles.failure(err)
    ),
);

/**
 * Create an Epic that will display a wait spinner while ProcessPayment action is being made.
 */
const waitForProcessPayment = createWaitEpic(PaymentActionTypes.ProcessPayment);

/**
 * Create an Epic that will display a wait spinner while UpdatePaymentInfo action is being made.
 */
const waitForUpdatePaymentInfo = createWaitEpic(PaymentActionTypes.UpdatePaymentInfo);

/**
 * Create an Epic that will display a wait spinner while delete action is being made.
 */
const waitForDeleteCreditCard = createWaitEpic(PaymentActionTypes.DeleteCreditCard);

/**
 * Create an Epic that will display a wait spinner while set default action is being made.
 */
const waitForSetDefaultPaymentMethod = createWaitEpic(PaymentActionTypes.SetPolicyDefaultPaymentMethod);

const handleSyncProfilesSuccessful: Epic<Action> = action$ => action$
.pipe(
    ofType(PaymentActionTypes.SyncPaymentProfiles.SUCCESS),
    map(() => paymentActions.getPaymentInfos.begin()),
);

const epic = combineEpics(
    handleCloseDialogOnEditPaymentInfoSuccess,
    handleGetFailedPayments,
    handleGetPaymentInfo,
    handleProcessPayment,
    handleUpdatePaymentInfo,
    handleUpdatePaymentInfoSuccessful,
    handleDeleteCreditCard,
    handleDeleteCreditCardSuccessful,
    handleSetPolicyDefaultPaymentMethod,
    handleSetPolicyDefaultPaymentMethodSuccesful,
    handleSyncPaymentProfiles,
    notifySetDefaultPaymentMethodSuccessful,
    notifySetDefaultPaymentMethodFailure,
    waitForDeleteCreditCard,
    waitForProcessPayment,
    waitForSetDefaultPaymentMethod,
    waitForUpdatePaymentInfo,
    handleSyncProfilesSuccessful,
);

export default epic;
