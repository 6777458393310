import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "actions/defs";
import { createAwardsActions, AwardsActionTypes } from "actions/awards.actions";
import { Areas } from "constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const awardsActions = createAwardsActions();

const handleLoadAwards: Epic<
    PayloadAction<void | Error | Landmark.Award[]>
> = action$ => action$.pipe(
    ofType(AwardsActionTypes.LoadAwards.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/awards/12")
            .fetch()
            .then(response => response.json),
        awardsActions.loadAwards.success,
        awardsActions.loadAwards.failure,
    ),
);

const waitForAwards = createWaitEpic(AwardsActionTypes.LoadAwards, Areas.Awards);

const epic = combineEpics(
    handleLoadAwards,
    waitForAwards,
);
export default epic;
