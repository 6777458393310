import * as React from "react";
import { Redirect, Route, Switch } from "react-router";

export function redirects() {
    // Note - this section has been done the old site urls that need to be rerouted
    return (
        <Switch>
            <Redirect exact from="/leadgen/rent" to="/resources/buying-selling-house/should-you-rent-or-buy-your-house-a-free-downloadable-workbook/7/87" />
            <Redirect exact from="/Warranty/Why-Choose-Landmark" to="/homeowner-warranty/why-landmark" />
            <Redirect exact from="/Warranty/value-of-a-home-warranty" to="/resources/home-warranty-education" />
            <Redirect exact from="/Warranty/sample-contract" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/Warranty/re-pro-reviews" to="/home-buyers-warranty/residential-service-contract" />
            <Redirect exact from="/Warranty/Homeowner-Reviews" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Warranty/Coverage-Details" to="/home-warranty-plans" />
            <Redirect exact from="/Warranty/contact-landmark" to="/contact-us" />
            <Redirect exact from="/Warranty/about-landmark" to="/about-us" />
            <Redirect exact from="/Warranty/home-warranty-faq" to="/resources" />
            <Redirect exact from="/frmNewAccount" to="/login" />
            <Redirect exact from="/frmClaim" to="/claim" />
            <Redirect exact from="/employment" to="https://frontdoor.jobs/" />
            <Redirect exact from="/Compare" to="/home-warranty-plans" />
            <Redirect exact from="/Compare-Plans" to="/home-warranty-plans" />
            <Redirect exact from="/ContractorApplicationTermsConditions" to="/contractors/apply" />
            <Redirect exact from="/contractorapplication" to="/contractors/apply" />
            <Redirect exact from="/Privacy-Policy" to="/terms-of-use" />
            <Redirect exact from="/osrContentGroup" to="/order" />
            <Redirect exact from="/neworderform" to="/order" />
            <Redirect exact from="/accountRecovery" to="/login" />
            <Redirect exact from="/loginPage" to="/login" />
            <Redirect exact from="/errorpage" to="/page-not-found" />

            <Redirect exact from="/homepage" to="/" />
            <Redirect exact from="/home-new" to="/" />
            <Redirect exact from="/best-home-warranty-companies-in-texas" to="/" />
            <Redirect exact from="/home-warranty-companies-in-arizona" to="/" />
            <Redirect exact from="/top-rated-home-warranty-companies-in-nevada" to="/" />
            <Redirect exact from="/top-rated-home-warranty-companies-in-texas" to="/" />
            <Redirect exact from="/warranty-info/best-home-warranty" to="/" />
            <Redirect exact from="/what-is-the-best-home-warranty-company" to="/" />

            <Redirect exact from="/errorpage" to="/page-not-found" />

            <Redirect exact from="/Warranty/contact-landmark" to="/contact-us" />
            <Redirect exact from="/Home-warranty/contact-landmark" to="/contact-us" />

            <Redirect exact from="/Warranty/about-landmark" to="/about-us" />

            <Redirect exact from="/employment" to="https://frontdoor.jobs/" />

            <Redirect exact from="/Privacy-Policy" to="/terms-of-use" />

            <Redirect exact from="/contractordashboard" to="/login" />
            <Redirect exact from="/frmClaim" to="/login" />

            <Redirect exact from="/accountRecovery" to="/account" />
            <Redirect exact from="/frmNewAccount" to="/account" />
            <Redirect exact from="/loginPage" to="/account" />
            <Redirect exact from="/Warranty/renew-your-warranty" to="/account" />
            <Redirect exact from="/claims" to="/claim" />
            <Redirect exact from="/Register" to="/account" />

            <Redirect exact from="/neworderform" to="/order" />

            <Redirect exact from="/Warranty/Coverage-Details" to="/home-warranty-plans" />
            <Redirect exact from="/Compare-Plans" to="/home-warranty-plans" />
            <Redirect exact from="/best-home-warranty-protection-plans" to="/home-warranty-plans" />
            <Redirect exact from="/Compare" to="/home-warranty-plans" />
            <Redirect exact from="/Home-Warranty/Coverage-Details" to="/home-warranty-plans" />
            <Redirect exact from="/Warranty/compare-plans" to="/home-warranty-plans" />

            <Redirect exact from="/contractorapplication" to="/contractors/apply" />
            <Redirect exact from="/ContractorApplicationTermsConditions" to="/contractors/apply" />

            <Redirect exact from="/Warranty/RE-Pro-Reviews" to="/home-buyers-warranty/residential-service-contract" />
            <Redirect exact from="/right-menu/Home-warranty/real-estate-pros/re-pro-testimonials" to="/home-buyers-warranty/residential-service-contract" />
            <Redirect exact from="/Home-Warranty/RE-Pro-Reviews" to="/home-buyers-warranty/residential-service-contract" />

            <Redirect exact from="/right-menu/Home-warranty/why-choose-landmark" to="/homeowner-warranty/why-landmark" />
            <Redirect exact from="/Warranty/Why-Choose-Landmark" to="/homeowner-warranty/why-landmark" />

            <Redirect exact from="/Home-Warranty/Rekey-Service" to="/home-buyers-warranty" />


            <Redirect exact from="/Warranty/Homeowner-Reviews" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Home-warranty/Homeowner-Reviews" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Home-Warranty/Homeowner-Testimonials" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/home-warranty-insurance-companies" to="/" />

            {/* Note: The <Route> is required instead of a <Redirect> when redirecting to a hashed url */}
            <Route exact path="/Warranty/sample-contract" onEnter={(nextState, replace) => replace("/home-buyers-warranty#brochure")} />
            <Route exact path="/Home-warranty/sample-contract" onEnter={(nextState, replace) => replace("/home-buyers-warranty#brochure")} />
            {/*  End section  */}


            <Redirect exact from="/Amado" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Eric" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Gabriel" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Joe" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Joshua" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Linda" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Michelle" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Nate" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Paul" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Rich" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Sarah" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Shawna" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Stayce" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Summer" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Tyler" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Brett" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Chelsey" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/David" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Dona" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/My-Landmark-Rep" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/osrContentGroup" to="/home-buyers-warranty/your-local-representative" />


            {/* Blog redirects */}
            {/* Misc */}
            <Redirect exact from="/?page=10" to="/resources" />
            <Redirect exact from="/?page=2" to="/resources" />
            <Redirect exact from="/?page=25" to="/resources" />
            <Redirect exact from="/?page=26" to="/resources" />
            <Redirect exact from="/?page=3" to="/resources" />
            <Redirect exact from="/?page=4" to="/resources" />
            <Redirect exact from="/?page=5" to="/resources" />
            <Redirect exact from="/?page=6" to="/resources" />
            <Redirect exact from="/?page=7" to="/resources" />
            <Redirect exact from="/?page=8" to="/resources" />
            <Redirect exact from="/?page=9" to="/resources" />
            <Redirect exact from="/author/Admin" to="/resources" />
            <Redirect exact from="/author/Kristen-Bullock" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=10" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=2" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=24" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=25" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=3" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=4" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=5" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=6" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=7" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=8" to="/resources" />
            <Redirect exact from="/author/Whitney-Bennett?page=9" to="/resources" />
            <Redirect exact from="/page/articles-by-category" to="/resources" />
            <Redirect exact from="/post/nottinghamrealtygroup.com" to="/resources" />
            <Redirect exact from="/post/sandrakirkland.com" to="/resources" />
            <Redirect exact from="/post/summer-home-projects" to="/resources" />
            <Redirect exact from="/?tag=/+Fridge+Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/?tag=/+Washer+and+Dryer+Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/?tag=/Appliance+Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/?tag=/Oven+Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/?tag=/Range+Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/?tag=/Washer+and+Dryer+Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/post/diy-how-to-clean-your-range-home-warranty-help" to="/resources/appliance-warranty/how-to-clean-a-range/5/57" />
            <Redirect exact from="/post/get-the-most-out-of-your-home-warranty-oven-maintenance-tips" to="/resources/appliance-warranty/oven-maintenance-tips-to-get-the-most-out-of-your-home-warranty/5/50" />
            <Redirect exact from="/?tag=/Buying+a+Home+Articles" to="/resources/buying-selling-house" />
            <Redirect exact from="/?tag=/Buying+or+Selling+a+Home" to="/resources/buying-selling-house" />
            <Redirect exact from="/?tag=/Homeowner+Articles" to="/resources/buying-selling-house" />
            <Redirect exact from="/?tag=/Mortgage+Articles" to="/resources/buying-selling-house" />
            <Redirect exact from="/?tag=/Renting+a+Home+Articles" to="/resources/buying-selling-house" />
            <Redirect exact from="/?tag=/Selling+a+Home+Articles" to="/resources/buying-selling-house" />
            <Redirect exact from="/?tag=/Articles+for+Contractors" to="/resources/contractors" />
            <Redirect exact from="/?tag=/Electrical+Articles" to="/resources/electrical" />
            <Redirect exact from="/?tag=/Repairs+and+Maintenance+Articles" to="/resources/home-repairs-maintenance" />
            <Redirect exact from="/?tag=/Home+Warranty+Education" to="/resources/home-warranty-education" />
            <Redirect exact from="/?tag=/Home+Warranty+Holiday+Articles" to="/resources/home-warranty-education" />
            <Redirect exact from="/?tag=/+Heating+and+Cooling+Articles" to="/resources/hvac" />
            <Redirect exact from="/?tag=/About+Landmark+Articles" to="/resources/landmark-home-warranty-news" />
            <Redirect exact from="/?tag=/Pest+Control+Articles" to="/resources/pest-control" />
            <Redirect exact from="/?tag=/Plumbing+Articles" to="/resources/plumbing" />
            <Redirect exact from="/post/how-to-replace-or-maintain-a-sink-pop-up-drain" to="/resources/plumbing/how-to-replace-or-maintain-a-sink-pop-up-drain-assembly/2/19" />
            <Redirect exact from="/?tag=/Property+Management+Articles" to="/resources/property-management" />
            <Redirect exact from="/?tag=/Tips+for+Real+Estate+Professionals" to="/resources/real-estate-tips" />
            <Redirect exact from="/post/decoding-your-water-heater-s-signs-that-it-needs-professional-maintenance" to="/resources/plumbing/how-to-know-if-your-water-heater-needs-repairs/2/283" />

            <Redirect exact from="/Warranty/Rekey-Service" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/Warranty/home-rekey-a-great-closing-gift" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/Warranty/home-warranty-faq" to="/resources/home-warranty-education" />
            <Redirect exact from="/category/Home-Warranty-Education" to="/resources/home-warranty-education" />
            <Redirect exact from="/category/Home-Warranty-Holiday-Articles" to="/resources/home-warranty-education" />
            {/*<Redirect exact from="/Warranty/value-of-a-home-warranty" to="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" />*/}
            <Redirect exact from="/Warranty/air-conditioner-tune-up" to="/resources/hvac/ac-tune-up" />
            <Redirect exact from="/Warranty/Heater-Tune-up" to="/resources/hvac/furnace-tune-up" />
            <Redirect exact from="/Warranty/home-rekey-a-great-closing-gift" to="/resources/real-estate-tips/tax-deductible-closing-gifts-for-re-pros" />
            <Redirect exact from="/Warranty/Heater-Tune-up" to="/resources/hvac/why-should-you-do-a-furnace-tune-up" />
            <Redirect exact from="/Warranty/listing-coverage" to="/resources/real-estate-tips/listing-coverage" />
            <Redirect exact from="/category/Heating-and-Cooling-Articles" to="/resources/hvac" />
            <Redirect exact from="/category/About-Landmark-Articles" to="/resources/landmark-home-warranty-news" />
            <Redirect exact from="/category/Electrical-Articles" to="/resources/electrical" />
            <Redirect exact from="/category/Appliance-Articles" to="/resources/appliance-warranty" />
            <Redirect exact from="/category/Articles-for-Contractors" to="/resources/contractors" />
            <Redirect exact from="/category/Buying-or-Selling-a-Home-Articles" to="/resources/buying-selling-house" />
            <Redirect exact from="/category/Pest-Control-Articles" to="/resources/pest-control" />
            <Redirect exact from="/category/Property-Management-Articles" to="/resources/property-management" />
            <Redirect exact from="/category/Repairs-and-Maintenance-Articles" to="/resources/home-repairs-maintenance" />
            <Redirect exact from="/Warranty/diy-videos" to="/resources/home-repairs-maintenance" />
            <Redirect exact from="/Warranty/resources/home-repairs-maintenance" to="/resources/home-repairs-maintenance" />
            <Redirect exact from="/category/Tips-for-Real-Estate-Professionals" to="/resources/real-estate-tips" />
            <Redirect exact from="/errorpage.aspx?aspxerrorpath=/Account/login.aspx" to="/account" />
            <Redirect exact from="/downloads/rekey-certificate" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/Heater-Tune-up" to="/resources/hvac/furnace-tune-up" />
            <Redirect exact from="/Handy-Home-Blog" to="/resources" />
            <Redirect exact from="/co-branded-marketing/rekey-certificate" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/best-home-warranty-company-reviews/page/5" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/b14/Space-Heater-Consideration" to="/resources" />
            <Redirect exact from="/b12/b" to="/resources" />
            <Redirect exact from="/b1/3-Tips-to-Add-Years-to-your-Water-Heater" to="/resources/plumbing/how-to-know-if-your-water-heater-needs-repairs/2/283" />
            <Redirect exact from="/App_Themes/LandmarkHW/" to="/" />
            <Redirect exact from="/Why-Choose-Landmark" to="/homeowner-warranty/why-landmark" />
            <Redirect exact from="/Warranty/archive" to="/" />
            {/*<Redirect exact from="/value-of-a-home-warranty" to="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" />*/}
            <Redirect exact from="/sample-contract" to="/home-warranty-plans" />
            <Redirect exact from="/RestApi/session" to="/" />
            <Redirect exact from="/RestApi/comments-api" to="/" />
            <Redirect exact from="/Representatives/s52/Utah" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Representatives/s51/Texas" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Representatives/s5/Arizona" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Representatives/s17/Idaho" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Representatives/c7/James-Hughes" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/renew-your-warranty" to="/" />
            <Redirect exact from="/Rekey-Service" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/register-login" to="/account" />
            <Redirect exact from="/Mike" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Menu/Home-warranty/homeowners/register" to="/" />
            <Redirect exact from="/landmark-merchandise" to="/" />
            <Redirect exact from="/Landmark-Home-Warranty-Handy-Home-Blog" to="/resources" />
            <Redirect exact from="/karl" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Homeowners/handy-home-blog" to="/resources" />
            <Redirect exact from="/homeowner-brochure" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/green-business-energy.htm" to="/" />
            <Redirect exact from="/files/lhw-policy-website.pdf" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/files/landmark_brochure.pdf" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/downloads/real-estate-brochure" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/docs/default-source/Infographics/steps-to-prep-infographic.pdf" to="/resources/buying-selling-house/5-steps-to-prepare-for-home-tours/7/138" />
            <Redirect exact from="/docs/default-source/Infographics/research-before-making-an-offer-infographic.pdf" to="/resources/buying-selling-house/5-things-you-should-research-before-making-an-offer/7/96" />
            <Redirect exact from="/docs/default-source/Infographics/is-your-furnace-sick.pdf" to="/resources/hvac/some-telltale-signs-your-furnace-needs-repairs/4/46" />
            <Redirect exact from="/docs/default-source/Infographics/how-long-do-your-appliances-last.pdf" to="/resources/home-repairs-maintenance/how-long-do-your-homes-systems-and-appliances-last/6/76" />
            <Redirect exact from="/docs/default-source/Infographics/home-warranty-flow-chart.pdf" to="/resources/home-warranty-education/should-i-get-a-home-warranty-flow-chart/10/190" />
            <Redirect exact from="/docs/default-source/Infographics/home-warranty-and-home-insurance.pdf" to="/resources/home-warranty-education/a-home-warranty-and-home-insurance-whats-the-difference/10/184" />
            <Redirect exact from="/docs/default-source/Infographics/guide-to-renting-out-your-house.pdf" to="/resources/buying-selling-house/how-to-prep-your-house-for-renting/7/97" />
            <Redirect exact from="/docs/default-source/Infographics/fall-in-love-with-a-home-warranty.pdf" to="/resources/home-warranty-education/fall-in-love-with-the-best-home-warranty/10/233" />
            <Redirect exact from="/docs/default-source/Infographics/extend-oven-39-s-lifespan-infographic.pdf" to="/resources/appliance-warranty/a-heat-free-way-to-clean-your-oven-and-extend-its-lifespan/5/53" />
            <Redirect exact from="/docs/default-source/Infographics/earth-day-with-a-home-warranty.pdf" to="/resources/home-warranty-education/celebrate-earth-day-with-a-home-warranty/10/235" />
            <Redirect exact from="/docs/default-source/Infographics/bugs!.pdf" to="/resources/pest-control/infographic-the-ultimate-guide-to-household-pests/9/175" />
            <Redirect exact from="/docs/default-source/Infographics/blast-off-with-a-landmark-home-warranty.pdf" to="/resources/home-warranty-education/shoot-for-the-moon-with-the-best-home-warranty-company/10/199" />
            <Redirect exact from="/docs/default-source/Infographics/best-christmas-present.pdf" to="/" />
            <Redirect exact from="/docs/default-source/Infographics/2weeks.pdf" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
            <Redirect exact from="/docs/default-source/Infographics/2months.pdf" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
            <Redirect exact from="/docs/default-source/Infographics/1week.pdf" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
            <Redirect exact from="/docs/default-source/Infographics/1month.pdf" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
            <Redirect exact from="/docs/default-source/default-document-library/don-39-t-gamble-with-your-home.pdf" to="/contact-us" />
            <Redirect exact from="/diy-videos" to="/resources" />
            <Redirect exact from="/default-source/PDF-Downloads/utah-brochure.pdf" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/default-source/Infographics/on-moving-day3c7befe45f4765d7934aff0000b6a4f0.pdf" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
            <Redirect exact from="/contact.asp" to="/contact-us" />
            <Redirect exact from="/contact-landmark" to="/contact-us" />
            <Redirect exact from="/Contact" to="/contact-us" />
            <Redirect exact from="/comparePlans2013.asp" to="/home-warranty-plans" />
            <Redirect exact from="/co-branded-marketing/realtor-door-hanger---dark-blue" to="/" />
            <Redirect exact from="/co-branded-marketing/real-estate-brochure" to="/" />
            <Redirect exact from="/co-branded-marketing/business-card-stickers" to="/" />
            <Redirect exact from="/best-home-warranty-company-reviews/page/6" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/b9/Get-Rid-of-those-Nasty-Roaches" to="/resources/pest-control/learn-about-cockroaches-and-how-to-get-rid-of-an-infestation/9/168" />
            <Redirect exact from="/b11/DIY-How-to-Unclog-Your-Drain" to="/resources/plumbing/how-to-fix-a-clogged-sink/2/10" />
            <Redirect exact from="/archive" to="/" />
            <Redirect exact from="/air-conditioner-tune-up" to="/resources/hvac/ac-tune-up" />
            <Redirect exact from=".com/rekey-closing-gift" to="/resources/buying-selling-house/re-key-gift" />

            <Redirect exact from="/HomeWarranty/Coverage-Plans-UT" to="/home-warranty-plans" />
            <Redirect exact from="/HomeWarranty/Coverage-Plans-ID" to="/home-warranty-plans" />
            <Redirect exact from="/Home-Warranty/Don-Glasgow-St.-George-Utah-1" to="/" />
            <Redirect exact from="/Home-Warranty/Mary-W.-Grand-Prairie-TX" to="/" />
            <Redirect exact from="/Home-warranty/why-choose-landmark" to="/homeowner-warranty/why-landmark" />
            <Redirect exact from="/Home-Warranty/Values-in-Action" to="/" />
            {/*<Redirect exact from="/Home-warranty/value-of-a-home-warranty" to="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" />*/}
            <Redirect exact from="/Home-Warranty/The-Truth-About-Home-Warranties" to="/" />
            <Redirect exact from="/Home-Warranty/Thank-You-for-Registering" to="/" />
            <Redirect exact from="/Home-Warranty/renew-your-warranty" to="/account" />
            <Redirect exact from="/Home-warranty/real-estate-pros/rekey-closing-gift" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/Home-warranty/real-estate-pros/re-pro-testimonials" to="/home-buyers-warranty/residential-service-contract" />
            <Redirect exact from="/Home-warranty/real-estate-pros/listing-coverage" to="/resources/real-estate-tips/listing-coverage" />
            <Redirect exact from="/Home-Warranty/RE-Pro-Testimonials" to="/home-buyers-warranty/residential-service-contract" />
            <Redirect exact from="/Home-Warranty/RE-Pro-Reviews/page/*" to="/home-buyers-warranty/residential-service-contract" />
            <Redirect exact from="/Home-Warranty/Props-and-Awards" to="/" />
            <Redirect exact from="/Home-warranty/place-an-order" to="/order" />
            <Redirect exact from="/Home-Warranty/Our-Commitment" to="/" />
            <Redirect exact from="/Home-warranty/My-Landmark-Rep" to="/home-buyers-warranty/your-local-representative" />
            <Redirect exact from="/Home-Warranty/Marketing-Materials" to="/" />
            <Redirect exact from="/Home-Warranty/Marketing-Fun" to="/" />
            <Redirect exact from="/home-warranty/listing-coverage" to="/" />
            <Redirect exact from="/Home-warranty/landmark-store/real-estate-brochure" to="/" />
            <Redirect exact from="/Home-Warranty/landmark-store/landmark-logo" to="/" />
            <Redirect exact from="/Home-warranty/landmark-store" to="/" />
            <Redirect exact from="/Home-Warranty/Landmark-Family" to="/" />
            <Redirect exact from="/Home-warranty/homeowners/homeowner-reviews" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Home-warranty/homeowners/air-conditioner-tune-up" to="/resources/hvac/ac-tune-up" />
            <Redirect exact from="/Home-Warranty/Homeowner-Testimonials/page/*" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Home-Warranty/Homeowner-Testimonial" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Home-Warranty/Homeowner-Reviews/page/*" to="/homeowner-warranty/reviews" />
            <Redirect exact from="/Home-Warranty/Home-Warranty-FAQs" to="/" />
            <Redirect exact from="/Home-Warranty/home-warranty-faq" to="/" />
            <Redirect exact from="/Home-Warranty/Home-Warranty-Brochure" to="/home-buyers-warranty#brochure" />
            <Redirect exact from="/Home-Warranty/Home-Owner-Benefits" to="/" />
            <Redirect exact from="/Home-warranty/Heater-Tune-up" to="/resources/hvac/furnace-tune-up" />
            <Redirect exact from="/Handy-Home-Blog/Blogs/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/a-guide-to-ordering-the-best-home-warranty" to="/resources/home-warranty-education/a-guide-to-ordering-the-best-home-warranty-plan/10/189" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/hard-water-vs.-soft-water-home-warranty-help" to="/resources" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/" to="/resources" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/" to="/resources" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/page/4" to="/resources/appliance-warranty/a-heat-free-way-to-clean-your-oven-and-extend-its-lifespan/5/53" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/handy-home-blog/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/tax-deductible-closing-gifts-for-re-pros" to="/resources/real-estate-tips/tax-deductible-closing-gifts-for-re-pros/8/158" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/handy-home-blog/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-re-key-a-great-closing-gift" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/handy-home-blog/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/air-filters-delivered" to="/resources/hvac/air-filters-delivered/4/48" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/what-is-a-water-softener-home-warranty-help" to="/resources#plumbing" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/mobile-phone-camera-lens" to="/" />
            <Redirect exact from="/Home-Warranty/Fred-Shaffer-Realtor" to="/" />
            <Redirect exact from="/Home-Warranty/FAQ" to="/resources" />
            <Redirect exact from="/Home-warranty/Employment" to="https://frontdoor.jobs/" />
            <Redirect exact from="/Home-Warranty/DIY-Videos" to="/resources" />
            <Redirect exact from="/Home-Warranty/air-conditioner-tune-up" to="/resources/hvac/ac-tune-up" />
            <Redirect exact from="/Home-warranty/about-landmark" to="/about-us" />
            <Redirect exact from="/Home-Warranty/5-Reasons-to-Choose-Landmark-Home-Warranty" to="/homeowner-warranty/why-landmark" />
            <Redirect exact from="/home-warranty/" to="/" />
            <Redirect exact from="/home-warranty" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/default" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/default" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/default" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/default" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/default" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/default" to="/" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/devastating-dishwasher-difficulties-home-warranty-help" to="/resources/appliance-warranty/devastating-dishwasher-difficulties/5/61" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/diagnose-your-plumbing-problems-home-warranty-help" to="/resources/plumbing/diagnose-your-plumbing-problems/2/28" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/decoding-your-water-heater-s-signs-that-it-needs-professional-maintenance" to="/resources/plumbing/how-to-know-if-your-water-heater-needs-repairs/2/283" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/april-showers-bring-flooded-gutters-home-warranty-help" to="/resources/home-repairs-maintenance/april-showers-bring-flooded-gutters/6/77" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/celebrate-earth-day-with-a-home-warranty" to="/resources/home-warranty-education/celebrate-earth-day-with-a-home-warranty/10/235" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-inspections-8-mistakes-home-buyers-make" to="/resources/buying-selling-house/home-inspections-8-mistakes-home-buyers-make/7/101" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/shoot-for-the-moon-with-the-best-home-warranty-company" to="/resources/home-warranty-education/shoot-for-the-moon-with-the-best-home-warranty-company/10/199" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/6-signs-your-air-conditioner-needs-repairs-is-your-home-sick-home-warranty-help" to="/resources/hvac/6-signs-your-air-conditioner-needs-repairs/4/45" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/some-telltale-signs-your-furnace-needs-repairs-is-your-home-sick-home-warranty-help" to="/resources/hvac/some-telltale-signs-your-furnace-needs-repairs/4/46" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-long-do-your-home-s-systems-and-appliances-last-home-warranty-help" to="/resources/home-repairs-maintenance/how-long-do-your-homes-systems-and-appliances-last/6/76" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/best-of-state-announces-landmark-home-warranty-as-2015-winner-press-release" to="/resources/landmark-home-warranty-news/best-of-state-announces-landmark-home-warranty-as-winner/11/205" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-clean-your-washer-and-dryer-home-warranty-tips" to="/resources/appliance-warranty/how-to-clean-your-washer-and-dryer/5/59" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/unlocking-the-secrets-of-laundry-home-warranty-help" to="/resources/appliance-warranty/unlocking-the-secrets-of-laundry/5/58" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/printable-all-inclusive-moving-checklist-home-warranty-help" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/budgeting-for-home-ownership-home-warranty-help" to="/resources/buying-selling-house/budgeting-for-homeownership/7/99" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/don-t-gamble-with-your-home-how-to-protect-your-investment-home-warranty-help" to="/resources/home-warranty-education/dont-gamble-with-your-home-how-to-protect-your-investment/10/198" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/diy-how-to-clean-your-range-home-warranty-help" to="/resources/appliance-warranty/how-to-clean-a-range/5/57" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/the-winner-of-the-ring-is" to="/resources/appliance-warranty/the-winner-of-the-ring-is/5/56" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/6-gadgets-to-furturize-your-kitchen-\(giveaway\)" to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-extend-your-oven-s-lifespan-home-warranty-help-infographic" to="/resources/appliance-warranty/tips-to-extend-your-ovens-lifespan/5/282" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/spring-maintenance-checklist-home-warranty-help-infographic" to="/resources/home-repairs-maintenance/spring-maintenance-checklist/6/75" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/six-applications-that-are-perfect-for-real-estate-agents" to="/resources/real-estate-tips/six-applications-that-are-perfect-for-real-estate-agents/8/160" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/rewrite-your-listing-description-and-sell-a-home-for-more-money" to="/resources/real-estate-tips/rewrite-your-listing-description-and-sell-a-home-for-more-money/8/159" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/your-guide-to-renting-out-a-house--home-warranty-help" to="/resources/buying-selling-house/your-guide-to-renting-out-a-house/7/98" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/5-things-you-should-research-before-making-an-offer" to="/resources/buying-selling-house/5-things-you-should-research-before-making-an-offer/7/96" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/landmark-home-warranty-presents-the-3-best-smart-gadgets-for-home-protection-infographic-and-giveaway" to="/resources/appliance-warranty/the-3-best-smart-gadgets-for-home-protection/5/51" />
			<Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-diy-toilet-repairs" to="/resources/plumbing/do-it-yourself-toilet-repairs/2/27" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-how-a-toilet-works" to="/resources/plumbing/how-a-toilet-works/2/26" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-reviews-names-landmark-best-home-warranty-company-for-region" to="/resources/landmark-home-warranty-news/landmark-named-best-home-warranty-company-for-region/11/204" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranties-5-ways-to-take-care-of-home-repairs-without-breaking-the-bank" to="/resources/home-repairs-maintenance/5-ways-to-take-care-of-home-repairs-without-going-broke/6/74" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/do-you-make-these-3-costly-homeowner-mistakes" to="/resources/home-warranty-education/do-you-make-these-3-costly-homeowner-mistakes/10/197" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/sleep-better-at-night-with-a-home-warranty" to="/resources/home-warranty-education/sleep-better-at-night-with-a-home-warranty/10/196" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/what-is-a-home-warranty-infographic" to="/resources/home-warranty-education/what-is-a-home-warranty/10/195" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/the-home-warranty-based-on-a-true-story-a-movie" to="/resources/home-warranty-education/the-home-warranty-based-on-a-true-story-a-movie-poster/10/194" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-valentines" to="/resources/home-warranty-education/home-warranty-valentines-2015/10/234" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/get-a-home-warranty-5-ways-to-save-money-when-purchasing-a-new-home" to="/resources/buying-selling-house/5-ways-to-save-money-when-purchasing-a-new-home/7/95" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/get-a-home-warranty-and-protect-your-home!-three-ways-to-protect-your-house" to="/resources/home-warranty-education/get-a-home-warranty-and-two-other-ways-to-protect-your-house/10/193" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/navigating-the-world-of-home-warranty-reviews" to="/resources/home-warranty-education/navigating-the-world-of-home-warranty-reviews/10/192" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranties-three-misguided-excuses-people-make-when-they-say-they-don-t-need-one" to="/resources/home-warranty-education/three-excuses-people-make-when-they-dont-get-a-home-warranty/10/191" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/should-i-get-a-home-warranty-flow-chart" to="/resources/home-warranty-education/should-i-get-a-home-warranty-flow-chart/10/190" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/a-guide-to-ordering-the-best-home-warranty" to="/resources/home-warranty-education/a-guide-to-ordering-the-best-home-warranty-plan/10/189" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/press-release-landmark-home-warranty's-owner-named-utah-entrepreneur-of-the-year" to="/resources/landmark-home-warranty-news/alma-jeppson-named-utah-entrepreneur-of-the-year/11/203" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/infographic-what-your-home-repairs-cost-with-and-without-a-home-warranty" to="/resources/home-warranty-education/what-your-home-repairs-cost-with-and-without-a-home-warranty/10/188" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/press-release-landmark-home-warranty-to-host-open-house" to="/resources/landmark-home-warranty-news/press-release-landmark-home-warranty-to-host-open-house/11/202" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-home-warranty-reviews-can-help-you-get-rid-of-termites" to="/resources/pest-control/all-about-termites-and-how-to-get-rid-of-an-infestation/9/174" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/do-you-have-a-spider-infestation-use-home-warranties-to-get-rid-of-them" to="/resources/pest-control/all-about-spiders-and-how-to-get-rid-of-them/9/173" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/the-best-home-warranty-can-help-you-get-rid-of-sow-bugs" to="/resources/pest-control/all-about-sow-bugs-and-how-to-get-rid-of-an-infestation/9/172" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/get-a-home-warranty-and-get-rid-of-an-infestation-of-silverfish" to="/resources/pest-control/educational-information-about-silverfish-and-infestations/9/280" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-companies-can-rid-your-home-of-a-millipede-infestation" to="/resources/pest-control/learn-about-millipedes-and-infestations-of-them/9/281" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranties-can-rid-you-of-beetles" to="/resources/pest-control/learn-about-beetles-and-get-rid-of-them-in-your-home/9/171" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/getting-the-best-home-warranty-can-get-rid-of-an-earwig-infestation" to="/resources/pest-control/all-about-earwigs-and-how-to-get-rid-of-an-infestation/9/170" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-home-warranties-can-help-you-get-rid-of-crickets" to="/resources/pest-control/learn-more-about-crickets-and-how-to-get-rid-of-an-infestation/9/169" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/read-home-warranty-reviews-they-can-get-rid-of-an-infestation-of-cockroaches" to="/resources/pest-control/learn-about-cockroaches-and-how-to-get-rid-of-an-infestation/9/168" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/have-clover-mites-get-rid-of-them-with-the-best-home-warranty" to="/resources/pest-control/learn-more-about-clover-mites-and-infestations/9/167" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-a-home-warranty-can-help-you-get-rid-of-ants" to="/resources/pest-control/learn-more-about-ants-and-how-to-get-rid-of-an-infestation/9/165" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/5-ways-to-simplify-your-move-home-warranty-help" to="/resources/buying-selling-house/5-ways-to-simplify-your-move/7/92" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/5-things-you-should-do-before-moving-into-your-new-home-home-warranty-help" to="/resources/buying-selling-house/5-things-you-should-do-before-moving-into-your-new-home/7/94" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/merry-christmas-from-landmark-home-warranty" to="/resources/landmark-home-warranty-news/merry-christmas-from-landmark-home-warranty-2014/11/236" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-a-home-warranty-can-save-you-from-holiday-horrors" to="/resources/home-warranty-education/how-a-home-warranty-can-save-you-from-holiday-horrors/10/229" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-de-scale-your-pipes-home-warranty-help" to="/resources/plumbing/descale-your-pipes-overnight/2/24" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/why-you-should-remove-hard-water-scale-from-your-pipes-home-warranty-help" to="/resources/plumbing/why-you-should-remove-hard-water-scale-from-your-pipes/2/23" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/hard-water-vs-soft-water-home-warranty-help" to="/resources/plumbing/the-difference-between-hard-and-soft-water/2/21" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-close-is-your-water-heater-to-becoming-a-bomb-home-warranty-help" to="/resources/plumbing/how-close-is-your-water-heater-to-becoming-a-bomb/2/15" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/diy-clean-and-rid-smells-from-a-garbage-disposal" to="/resources/plumbing/diy-clean-and-rid-smells-from-a-garbage-disposal/2/16" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/diy-fix-a-jammed-garbage-disposal-home-warranty-help" to="/resources/plumbing/diy-fix-a-jammed-garbage-disposal/2/17" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-getting-a-home-warranty-is-better-than-shopping-on-black-Friday" to="/resources/home-warranty-education/getting-a-home-warranty-is-better-than-shopping-on-black-friday/10/231" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/things-you-can-t-make-for-thanksgiving-dinner-if-your-oven-is-broken-home-warranty-help" to="/resources/appliance-warranty/things-you-cant-make-for-thanksgiving-if-your-oven-is-broken/5/232" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/5-parts-of-thanksgiving-dinner-that-can-ruin-your-pipes-home-warranty-help" to="/resources/plumbing/5-parts-of-dinner-that-can-clog-your-pipes/2/228" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/landmark-goes-above-and-beyond-for-home-warranty-customers" to="/resources/landmark-home-warranty-news/landmark-goes-above-and-beyond-for-home-warranty-customers/11/201" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/day-by-day-prep-for-thanksgiving-from-landmark-home-warranty-infographic" to="/resources/home-warranty-education/how-to-take-charge-of-home-repairs-with-a-home-warranty/10/185" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-take-charge-of-home-repairs-with-a-home-warranty" to="/resources/home-warranty-education/how-to-take-charge-of-home-repairs-with-a-home-warranty/10/185" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-choose-the-best-home-warranty-company" to="/resources/home-warranty-education/how-to-choose-the-best-home-warranty-company/10/186" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-save-your-thanksgiving-dinner-with-a-home-warranty" to="/resources/home-warranty-education/how-to-save-your-thanksgiving-dinner-with-a-home-warranty/10/227" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-how-safe-is-your-home-from-a-burst-pipe" to="/resources/plumbing/how-safe-is-your-home-from-a-burst-pipe/2/13" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-5-ways-to-sell-your-home-for-more-money-infographic" to="/resources/buying-selling-house/5-ways-to-sell-your-home-for-more-money/7/93" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-3-things-you-need-to-know-before-buying-a-home" to="/resources/buying-selling-house/3-things-you-need-to-know-before-buying-a-home/7/90" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-5-things-every-homeowner-needs" to="/resources/buying-selling-house/5-things-every-homeowner-needs/7/91" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-3-more-ways-to-insulate-your-home-this-winter" to="/resources/home-repairs-maintenance/3-more-ways-to-insulate-your-home-this-winter/6/70" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-3-ways-to-insulate-your-home-this-winter" to="/resources/home-repairs-maintenance/3-ways-to-insulate-your-home-this-winter/6/71" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-why-flush-sediment-out-of-a-water-heater" to="/resources/plumbing/how-to-flush-sediment-out-of-a-water-heater/2/279" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-help-how-your-water-heater-works" to="/resources/plumbing/how-a-water-heater-works/2/12" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/a-home-warranty-and-home-insurance-what-s-the-difference-infographic" to="/resources/home-warranty-education/a-home-warranty-and-home-insurance-whats-the-difference/10/184" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/6-steps-to-maintain-your-heater-for-fall-by-landmark-home-warranty" to="/resources/hvac/6-steps-to-maintain-your-heater-for-fall/4/47" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/tax-deductible-closing-gifts-for-re-pros" to="/resources/real-estate-tips/tax-deductible-closing-gifts-for-re-pros/8/158" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/real-estate-investors-is-a-home-warranty-a-wise-choice" to="/resources/real-estate-tips/real-estate-investors-is-a-home-warranty-a-wise-choice/8/157" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/air-filters-delivered" to="/resources/hvac/air-filters-delivered/4/48" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/summer-home-projects" to="/resources" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/get-a-home-warranty-and-get-rid-of-an-infestation-of-silverfish!" to="/resources/pest-control/educational-information-about-silverfish-and-infestations/9/280" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/have-clover-mites-get-rid-of-them-with-the-best-home-warranty!" to="/resources/pest-control/learn-more-about-clover-mites-and-infestations/9/167" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/6-steps-to-maintain-your-heater-for-fall-from-landmark-home-warranty" to="/resources/hvac/6-steps-to-maintain-your-heater-for-fall/4/47" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/*/*/*/a-home-warranty-and-home-insurance-what's-the-difference-infographic" to="/resources/home-warranty-education/a-home-warranty-and-home-insurance-whats-the-difference/10/184" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/*/*/*/a-home-warranty-and-home-insurance-what%27s-the-difference-infographic" to="/resources/home-warranty-education/a-home-warranty-and-home-insurance-whats-the-difference/10/184" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-how-to-flushing-sediment-out-of-a-water-heater" to="/resources/plumbing/how-to-flush-sediment-out-of-a-water-heater/2/279" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/home-warranty-why-flush-sediment" to="/resources/plumbing/why-flush-sediment-out-of-a-water-heater/2/11" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/save-your-thanksgiving-dinner-with-a-home-warranty" to="/resources/home-warranty-education/how-to-save-your-thanksgiving-dinner-with-a-home-warranty/10/227" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/*/*/*/get-the-most-out-of-your-home-warranty-oven-maintenance" to="/resources/appliance-warranty/oven-maintenance-tips-to-get-the-most-out-of-your-home-warranty/5/50" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-does-a-sink-pop-up-mechanism-work" to="/resources/plumbing/how-does-a-sink-pop-up-mechanism-work/2/20" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-replace-or-maintain-a-sink-pop-up-drain" to="/resources/plumbing/how-to-replace-or-maintain-a-sink-pop-up-drain-assembly/2/19" />
            <Redirect exact from="/Home-warranty/Handy-Home-Blog/Blogs/*/*/*/5-parts-of-thanksgiving-dinner-that-can-ruin-your-pipes" to="/resources/plumbing/5-parts-of-dinner-that-can-clog-your-pipes/2/228" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/diy-fix-a-jammed-garbage-disposal" to="/resources/plumbing/diy-fix-a-jammed-garbage-disposal/2/17" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-does-a-garbage-disposal-work-home-warranty-help" to="/resources/plumbing/how-does-a-garbage-disposal-work/2/278" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-close-is-your-water-heater-to-becoming-a-bomb" to="/resources/plumbing/how-close-is-your-water-heater-to-becoming-a-bomb/2/15" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-test-your-temperature-and-pressure-relief-valve-home-warranty-help" to="/resources/plumbing/how-to-test-your-temperature-and-pressure-relief-valve/2/14" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/hard-water-vs.-soft-water-home-warranty-help" to="/resources/plumbing/the-difference-between-hard-and-soft-water/2/21" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-make-your-showerhead-like-new-home-warranty-help" to="/resources/plumbing/how-to-descale-your-showerhead/2/22" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/why-you-should-remove-hard-water-scale-from-your-pipes-home-warranty-help" to="/resources/plumbing/why-you-should-remove-hard-water-scale-from-your-pipes/2/23" />
			<Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-descale-your-pipes-home-warranty-help" to="/resources/plumbing/descale-your-pipes-overnight/2/24" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-a-home-warranty-is-the-best-gift-you-can-give-this-holiday-season" to="/resources/home-warranty-education/a-home-warranty-is-the-best-gift-you-can-give-this-holiday/10/230" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-home-warranty-companies-can-get-rid-of-centipedes" to="/resources/pest-control/educational-information-on-centipedes-and-infestations/9/166" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/infographic-the-ultimate-guide-to-household-pests-and-how-a-home-warranty-can-help-debug-your-house" to="/resources/pest-control/infographic-the-ultimate-guide-to-household-pests/9/175" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/why-a-home-warranty-is-worth-it-a-cautionary-illustrated-fable" to="/resources/home-warranty-education/why-a-home-warranty-is-worth-it-a-cautionary-illustrated-fable/10/187" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/fall-in-love-with-the-best-home-warranty" to="/resources/home-warranty-education/fall-in-love-with-the-best-home-warranty/10/233" />
			<Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/enter-to-win-a-ring-for-free-from-landmark-home-warranty" to="/resources/appliance-warranty/the-3-best-smart-gadgets-for-home-protection/5/51" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/prepping-your-home-for-renting-a-how-to-from-landmark-home-warranty" to="/resources/buying-selling-house/how-to-prep-your-house-for-renting/7/97" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/how-to-clean-your-oven-to-extend-its-lifespan-home-warranty-help" to="/resources/appliance-warranty/a-heat-free-way-to-clean-your-oven-and-extend-its-lifespan/5/53" />
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/6-gadgets-to-furturize-your-kitchen-\(giveaway!\)" to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />
			<Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/*/*/*/enter-to-win-a-kitchen-gadget-from-landmark-home-warranty" to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />
            {/*<Redirect exact from="/Home-Warranty/Handy-Home-Blog/Blogs/2014/03/18/remodeling-your-home-know-about-vocs" to="/resources/home-repairs-maintenance/remodeling-your-home-know-about-vocs/6/73" />*/}
			<Redirect exact from="ndy-Home-Blog/Blogs/2014/03/18/remodeling-your-home-know-about-vocs" to="/resources/home-repairs-maintenance/" />

            <Redirect exact from="/blog" to="/resources" />
            <Redirect exact from="/blog.landmarkhw" to="/resources" />
            <Redirect exact from="/content1.asp" to="/" />
            <Redirect exact from="/docs/iop-assocjobprofile.doc" to="/" />
            <Redirect exact from="/author/sandrakirkland.com" to="/" />
            <Redirect exact from="/search.asp?key=heater%20maintenance" to="/" />
            <Redirect exact from="/search.asp" to="/" />
            <Redirect exact from="/orderPathSpecial.asp" to="/order" />
            <Redirect exact from="/search.asp?key=Buying%20a%20Home" to="/" />
            <Redirect exact from="/orderPathSpecial.asp?p=5&pkg=Deluxe" to="/order" />
            <Redirect exact from="/content1.asp?cat=8&id=27" to="/" />
            <Redirect exact from="/orderPathDecisions.asp?type=listing" to="/order" />
            <Redirect exact from="/content1.asp?cat=1&id=58" to="/" />
            <Redirect exact from="/content1.asp?cat=22&id=72" to="/" />
            <Redirect exact from="/image.axd" to="/" />
            <Redirect exact from="/file.axd" to="/" />
            <Redirect exact from="/App_Themes/LandmarkHW/css/style.css?r=cdxywvlx2w2go1g223yf5bm01" to="/" />
            <Redirect exact from="/author/nottinghamrealtygroup.com" to="/" />
            <Redirect exact from="/App_Themes/LandmarkHW/js/jquery-1.7.2.min.js;527216" to="/" />
            <Redirect exact from="/author/htttp:/" to="/" />
            <Redirect exact from="/OsrListHandler.ashx" to="/" />
            <Redirect exact from="/b20/Want-leads-Here's-how-to-get-them." to="/resources" />
            <Redirect exact from="/Home-Warranty/Jaime-H.-Fort-Worth-Texas" to="/" />
            <Redirect exact from="/Warranty/Rekey-Service.aspx" to="/resources/buying-selling-house/re-key-gift" />
            <Redirect exact from="/Home-Warranty/Jeff-and-Megan-J.-Sandy-Utah" to="/" />
            <Redirect exact from="/content1.asp?cat=12&id=74" to="/" />
            <Redirect exact from="/blog.asp?id=15&r=1&r=1&r=1&CAPTCHA_Postback=true" to="/" />
            <Redirect exact from="/userFiles/images/vcard/karl%20paulson.vcf" to="/" />
            <Redirect exact from="/warranty-info/" to="/" />
            <Redirect exact from="/PhotoHandler.ashx" to="/" />
            <Redirect exact from="/userFiles/images/vcard/stayce%20blake.vcf" to="/" />
            <Redirect exact from="/userFiles/images/vcard/summer%20sweaney.vcf" to="/home-warranty-plans" />
            {/* Appliances*/}
            <Redirect exact from="/post/6-gadgets-to-furturize-your-kitchen-giveaway" to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />
            <Redirect exact from="/post/how-to-clean-your-oven-to-extend-its-lifespan-home-warranty-help" to="/resources/appliance-warranty/a-heat-free-way-to-clean-your-oven-and-extend-its-lifespan/5/53" />
            <Redirect exact from="/post/devastating-dishwasher-difficulties-home-warranty-help" to="/resources/appliance-warranty/devastating-dishwasher-difficulties/5/61" />
            <Redirect exact from="/post/enter-to-win-a-kitchen-gadget-from-landmark-home-warranty" to="/resources/appliance-warranty/enter-to-win-a-kitchen-gadget-from-landmark-home-warranty/5/54" />
            <Redirect exact from="/post/enter-to-win-a-ring-for-free-from-landmark-home-warranty" to="/resources/appliance-warranty/enter-to-win-a-ring-for-free-from-landmark-home-warranty/5/52" />
            <Redirect exact from="/post/how-and-why-glass-oven-doors-shatter" to="/resources/appliance-warranty/how-and-why-glass-oven-doors-shatter/5/66" />
            <Redirect exact from="/post/how-does-a-dishwasher-work" to="/resources/appliance-warranty/how-does-a-dishwasher-work/5/62" />
            <Redirect exact from="/post/how-to-clean-a-fridge-s-evaporative-coils" to="/resources/appliance-warranty/how-to-clean-a-fridges-evaporative-coils/5/67" />
            <Redirect exact from="/post/how-to-clean-repair-and-maintain-your-dishwasher" to="/resources/appliance-warranty/how-to-clean-repair-and-maintain-your-dishwasher/5/63" />
            <Redirect exact from="/post/how-to-clean-your-washer-and-dryer-home-warranty-tips" to="/resources/appliance-warranty/how-to-clean-your-washer-and-dryer/5/59" />
			<Redirect exact from="/post/kitchen-gadget-giveaway-winner-from-landmark-home-warranty" to="/resources/appliance-warranty/6-gadgets-to-furturize-your-kitchen/5/55" />
            <Redirect exact from="/post/landmark-home-warranty-giveaway-the-3-best-smart-gadgets-for-home-protection-infographic" to="/resources/appliance-warranty/the-3-best-smart-gadgets-for-home-protection/5/51" />
			<Redirect exact from="/post/the-winner-of-the-ring-is" to="/resources/appliance-warranty/the-3-best-smart-gadgets-for-home-protection/5/51" />
            <Redirect exact from="/post/things-you-can-t-make-for-thanksgiving-dinner-if-your-oven-is-broken-home-warranty-help" to="/resources/appliance-warranty/things-you-cant-make-for-thanksgiving-if-your-oven-is-broken/5/232" />
            <Redirect exact from="/post/how-to-extend-your-oven-s-lifespan-home-warranty-help-infographic" to="/resources/appliance-warranty/tips-to-extend-your-ovens-lifespan/5/282" />
            <Redirect exact from="/post/troubleshooting-electric-oven-problems" to="/resources/appliance-warranty/troubleshooting-electric-oven-problems/5/65" />
            <Redirect exact from="/post/troubleshooting-gas-oven-problems" to="/resources/appliance-warranty/troubleshooting-gas-oven-problems/5/64" />
            <Redirect exact from="/post/unlocking-the-secrets-of-laundry-home-warranty-help" to="/resources/appliance-warranty/unlocking-the-secrets-of-laundry/5/58" />
            {/* Buying and Selling */}
            <Redirect exact from="/post/10-questions-to-ask-a-real-estate-agent-when-looking-at-a-home" to="/resources/buying-selling-house/10-questions-to-ask-a-real-estate-agent-when-looking-at-homes/7/112" />
            <Redirect exact from="/post/10-ways-to-increase-your-home-value-on-a-budget" to="/resources/buying-selling-house/10-ways-to-increase-your-home-value-on-a-budget/7/107" />
            <Redirect exact from="/post/home-warranty-help-3-things-you-need-to-know-before-buying-a-home" to="/resources/buying-selling-house/3-things-you-need-to-know-before-buying-a-home/7/90" />
            <Redirect exact from="/post/4-tips-to-sell-a-home-during-the-holidays" to="/resources/buying-selling-house/4-tips-to-sell-a-home-during-the-holidays/7/222" />
            <Redirect exact from="/post/5-scariest-things-new-homeowners-have-found-after-moving-in" to="/resources/buying-selling-house/5-scariest-things-new-homeowners-have-found-after-moving-in/7/214" />
            <Redirect exact from="/post/5-steps-to-prepare-for-home-tours" to="/resources/buying-selling-house/5-steps-to-prepare-for-home-tours/7/138" />
            <Redirect exact from="/post/home-warranty-help-5-things-every-homeowner-needs" to="/resources/buying-selling-house/5-things-every-homeowner-needs/7/91" />
            <Redirect exact from="/post/5-things-you-should-do-before-moving-into-your-new-home-home-warranty-help" to="/resources/buying-selling-house/5-things-you-should-do-before-moving-into-your-new-home/7/94" />
            <Redirect exact from="/post/5-things-you-should-research-before-making-an-offer" to="/resources/buying-selling-house/5-things-you-should-research-before-making-an-offer/7/96" />
            <Redirect exact from="/post/get-a-home-warranty-5-ways-to-save-money-when-purchasing-a-new-home" to="/resources/buying-selling-house/5-ways-to-save-money-when-purchasing-a-new-home/7/95" />
            <Redirect exact from="/post/home-warranty-help-5-ways-to-sell-your-home-for-more-money-infographic" to="/resources/buying-selling-house/5-ways-to-sell-your-home-for-more-money/7/93" />
            <Redirect exact from="/docs/default-source/Infographics/5-ways-to-sell-your-home-for-more-money" to="/resources/buying-selling-house/5-ways-to-sell-your-home-for-more-money/7/93" />
            <Redirect exact from="/post/5-ways-to-simplify-your-move-home-warranty-help" to="/resources/buying-selling-house/5-ways-to-simplify-your-move/7/92" />
            <Redirect exact from="/post/8-low-cost-summer-diy-projects-to-increase-your-home-s-value" to="/resources/buying-selling-house/8-low-cost-summer-diy-projects-to-increase-your-homes-value/7/133" />
            <Redirect exact from="/post/update-your-home-to-sell-to-millennials-add-value-to-your-home-for-the-next-generation" to="/resources/buying-selling-house/add-value-and-update-your-home-to-sell-to-millennials/7/143" />
            <Redirect exact from="/post/avoid-being-house-poor-with-a-home-warranty" to="/resources/buying-selling-house/avoid-being-house-poor-with-a-home-warranty/7/140" />
            <Redirect exact from="/post/avoid-these-real-estate-pitfalls-home-warranty-help" to="/resources/buying-selling-house/avoid-these-real-estate-pitfalls/7/108" />
            <Redirect exact from="/post/differences-between-pre-qualified-pre-approved-and-commitment-for-a-mortgage" to="/resources/buying-selling-house/being-prequalified-preapproved-vs-commited-to-a-mortgage/7/132" />
            <Redirect exact from="/post/best-real-estate-sites-for-house-hunting-home-warranty-help" to="/resources/buying-selling-house/best-real-estate-sites-for-house-hunting/7/105" />
            <Redirect exact from="/post/between-the-offer-and-closing-what-happens-next" to="/resources/buying-selling-house/between-the-offer-and-closing-what-happens-next/7/150" />
            <Redirect exact from="/post/budgeting-for-home-ownership-home-warranty-help" to="/resources/buying-selling-house/budgeting-for-homeownership/7/99" />
			<Redirect exact from="/post/calculating-how-much-you-can-afford-to-spend-on-a-mortgage-payment" to="/resources/buying-selling-house/calculate-how-much-to-spend-on-a-mortgage-payment/7/103" />
            <Redirect exact from="/post/how-much-you-can-afford-to-spend-on-a-home-conflicting-advice" to="/resources/buying-selling-house/conflicting-advice-on-how-much-to-spend-on-a-home/7/104" />
            <Redirect exact from="/post/decoding-listing-lingo-home-warranty-real-estate-help" to="/resources/buying-selling-house/decoding-real-estate-listing-lingo/7/106" />
            <Redirect exact from="/post/don-t-fall-into-money-traps-in-a-new-home" to="/resources/buying-selling-house/dont-fall-into-the-money-pits-of-a-new-home/7/86" />
            <Redirect exact from="/post/home-buying-myths-busted" to="/resources/buying-selling-house/home-buying-myths-busted/7/89" />
            <Redirect exact from="/post/home-inspections-8-mistakes-home-buyers-make" to="/resources/buying-selling-house/home-inspections-8-mistakes-home-buyers-make/7/101" />
            <Redirect exact from="/post/how-and-why-to-get-pre-approved-for-a-mortgage" to="/resources/buying-selling-house/how-and-why-to-get-pre-approved-for-a-mortgage/7/147" />
            <Redirect exact from="/post/how-can-you-invest-in-real-estate" to="/resources/buying-selling-house/how-can-you-invest-in-real-estate/7/116" />
            <Redirect exact from="/post/Finding-best-inspector-best-home-warranty-company" to="/resources/buying-selling-house/how-to-find-the-best-home-inspector/7/114" />
            <Redirect exact from="/post/how-to-find-the-best-realtor-for-you" to="/resources/buying-selling-house/how-to-find-the-best-realtor-for-you/7/142" />
            <Redirect exact from="/post/how-to-get-your-home-ready-for-an-open-house-in-5-steps" to="/resources/buying-selling-house/how-to-get-your-home-ready-for-an-open-house-in-5-steps/7/129" />
            <Redirect exact from="/post/prepping-your-home-for-renting-a-how-to-from-landmark-home-warranty" to="/resources/buying-selling-house/how-to-prep-your-house-for-renting/7/97" />
            <Redirect exact from="/post/how-to-spot-online-listing-rental-scams-and-what-to-do-if-you-ve-been-scammed" to="/resources/buying-selling-house/how-to-spot-online-rental-listing-scams/7/130" />
            <Redirect exact from="/post/make-moving-relaxing-five-moving-mishaps-to-avoid" to="/resources/buying-selling-house/make-moving-relaxing-five-moving-mishaps-to-avoid/7/83" />
            <Redirect exact from="/post/mistakes-you-don-t-want-to-make-with-a-home-inspection" to="/resources/buying-selling-house/mistakes-you-dont-want-to-make-with-a-home-inspection/7/128" />
			{/*<Redirect exact from="/post/nine-clever-money-making-tricks-to-offset-your-mortgage" to="/resources/buying-selling-house/nine-clever-money-making-tricks-to-offset-your-mortgage/7/122" />*/}
			<Redirect exact from="/post/nine-clever-money-making-tricks-to-offset-your-mortgage" to="/resources/buying-selling-house/" />
            <Redirect exact from="/post/parts-of-an-offer-on-a-home" to="/resources/buying-selling-house/parts-of-an-offer-on-a-home/7/152" />
            <Redirect exact from="/post/printable-all-inclusive-moving-checklist-home-warranty-help" to="/resources/buying-selling-house/printable-all-inclusive-moving-checklist/7/100" />
			<Redirect exact from="/post/reasons-why-you-might-not-be-pre-approved-for-a-mortgage" to="/resources/buying-selling-house/reasons-why-you-might-not-be-pre-approved-for-a-mortgage/7/146" />
            <Redirect exact from="/post/reasonshttp://post/reasons-why-you-might-not-be-pre-approved-for-a-mortgage-why-you-might-not-be-pre-approved-for-a-mortgage" to="/resources/buying-selling-house/reasons-why-you-might-not-be-pre-approved-for-a-mortgage/7/146" />
            <Redirect exact from="/post/selling-a-home-is-no-piece-of-cake-what-goes-into-your-home-s-sale" to="/resources/buying-selling-house/selling-a-home-is-no-piece-of-cake-what-goes-into-it/7/109" />
            <Redirect exact from="/post/selling-your-home-with-a-short-sale" to="/resources/buying-selling-house/selling-your-home-with-a-short-sale/7/135" />
            <Redirect exact from="/post/should-you-rent-or-buy-your-house-a-free-downloadable-workbook-home-warranty-money-help" to="/resources/buying-selling-house/should-you-rent-or-buy-your-house-a-free-downloadable-workbook/7/87" />
            <Redirect exact from="/post/should-you-take-your-realtor-s-recommendations" to="/resources/buying-selling-house/should-you-take-your-realtors-recommendations/7/117" />
            <Redirect exact from="/post/staging-design-trends-for-2016" to="/resources/buying-selling-house/staging-design-trends-for-2016/7/139" />
            <Redirect exact from="/post/the-complete-guide-to-buying-a-home" to="/resources/buying-selling-house/the-complete-guide-to-buying-a-home/7/123" />
            <Redirect exact from="/post/the-complete-guide-to-due-diligence" to="/resources/buying-selling-house/the-complete-guide-to-due-diligence/7/120" />
            <Redirect exact from="/post/the-parts-of-a-mortgage" to="/resources/buying-selling-house/the-parts-of-a-mortgage/7/82" />
            <Redirect exact from="/post/the-true-cost-of-a-home" to="/resources/buying-selling-house/the-true-cost-of-a-home/7/84" />
            <Redirect exact from="/post/things-i-learned-about-staging-while-touring-homes" to="/resources/buying-selling-house/things-i-learned-about-staging-while-touring-homes/7/149" />
            <Redirect exact from="/post/three-mortgage-scams-to-stay-away-from-when-buying-a-home" to="/resources/buying-selling-house/three-mortgage-scams-to-stay-away-from-when-buying-a-home/7/85" />
            <Redirect exact from="/post/ups-and-downs-of-buying-new-construction-vs-resell-homes-with-home-repair-insurance" to="/resources/buying-selling-house/ups-and-downs-of-buying-new-construction-versus-resell-homes/7/115" />
            <Redirect exact from="/post/using-a-realtor-vs-using-for-sale-by-owner" to="/resources/buying-selling-house/using-a-realtor-vs-using-for-sale-by-owner/7/110" />
            <Redirect exact from="/post/wants-and-needs-when-buying-a-home-create-your-own-customized-file" to="/resources/buying-selling-house/wants-and-needs-when-buying-a-home-create-your-own-file/7/144" />
            <Redirect exact from="/post/watch-out-for-these-3-types-of-online-rental-scams" to="/resources/buying-selling-house/watch-out-for-these-3-types-of-online-rental-scams/7/131" />
            <Redirect exact from="/post/what-are-the-different-types-of-mortgages" to="/resources/buying-selling-house/what-are-the-different-types-of-mortgages/7/125" />
            <Redirect exact from="/post/what-does-escrow-mean" to="/resources/buying-selling-house/what-does-escrow-mean/7/148" />
            <Redirect exact from="/post/what-is-a-foreclosure" to="/resources/buying-selling-house/what-is-a-foreclosure/7/155" />
            <Redirect exact from="/post/what-is-a-homeowner-s-association" to="/resources/buying-selling-house/what-is-a-homeowner-association/7/119" />
            <Redirect exact from="/post/what-is-a-mortgage-broker" to="/resources/buying-selling-house/what-is-a-mortgage-broker/7/118" />
            <Redirect exact from="/post/what-is-a-property-survey-and-why-is-it-important" to="/resources/buying-selling-house/what-is-a-property-survey-and-why-is-it-important/7/121" />
            <Redirect exact from="/post/what-is-a-short-sale" to="/resources/buying-selling-house/what-is-a-short-sale/7/134" />
            <Redirect exact from="/post/what-is-a-title-company-why-do-they-choose-my-home-repair-insurance" to="/resources/buying-selling-house/what-is-a-title-company/7/113" />
            <Redirect exact from="/post/what-is-an-as-is-property" to="/resources/buying-selling-house/what-is-an-as-is-property/7/141" />
            <Redirect exact from="/post/what-is-an-earnest-money-deposit" to="/resources/buying-selling-house/what-is-an-earnest-money-deposit/7/137" />
            <Redirect exact from="/post/what-is-home-equity" to="/resources/buying-selling-house/what-is-home-equity/7/126" />
            <Redirect exact from="/post/you%20can%20increase%20the%20equity" to="/resources/buying-selling-house/what-is-home-equity/7/126" />
            <Redirect exact from="/post/what-kind-of-home-should-you-buy" to="/resources/buying-selling-house/what-kind-of-home-should-you-buy/7/154" />
            <Redirect exact from="/post/what-makes-up-housing-costs-the-best-home-warranty-can-help-keep-them-down" to="/resources/buying-selling-house/what-makes-up-housing-costs/7/102" />
            <Redirect exact from="/post/what-price-should-you-offer-on-a-home" to="/resources/buying-selling-house/what-price-should-you-offer-on-a-home/7/151" />
            <Redirect exact from="/post/what-to-do-before-during-and-after-a-home-inspection" to="/resources/buying-selling-house/what-to-do-before-during-and-after-a-home-inspection/7/153" />
            <Redirect exact from="/post/what-you-need-to-know-about-buying-a-short-sale-property" to="/resources/buying-selling-house/what-you-need-to-know-about-buying-a-short-sale-property/7/136" />
            <Redirect exact from="/post/what-s-the-average-timeframe-for-buying-a-home" to="/resources/buying-selling-house/whats-the-average-timeframe-for-buying-a-home/7/145" />
            <Redirect exact from="/post/why-does-a-home-stay-on-the-market-for-a-long-time" to="/resources/buying-selling-house/why-does-a-home-stay-on-the-market-for-a-long-time/7/111" />
            <Redirect exact from="/post/why-pre-paying-your-mortgage-can-save-you-money" to="/resources/buying-selling-house/why-pre-paying-your-mortgage-can-save-you-money/7/124" />
            <Redirect exact from="/post/your-guide-to-renting-out-a-house-home-warranty-help" to="/resources/buying-selling-house/your-guide-to-renting-out-a-house/7/98" />
            {/* Contractors */}
            <Redirect exact from="/post/contractors-why-work-with-a-home-warranty-company" to="/resources/contractors/contractors-why-work-with-a-home-warranty-company/14/239" />
            <Redirect exact from="/post/join-our-team-of-elite-service-representatives-and-grow-your-business" to="/resources/contractors/join-our-team-of-elite-service-representatives-and-grow/14/240" />
            {/* Electrical */}
            <Redirect exact from="/post/different-types-of-electrical-outlets-and-how-they-work" to="/resources/electrical/different-types-of-electrical-outlets-and-how-they-work/3/244" />
            <Redirect exact from="/post/do-it-yourself-changing-a-normal-outlet-to-a-gfci-outlet" to="/resources/electrical/do-it-yourself-changing-a-normal-outlet-to-a-gfci-outlet/3/245" />
            <Redirect exact from="/post/why-is-my-air-conditioner-tripping-the-circuit-breaker-home-warranty-help" to="/resources/electrical/why-is-my-air-conditioner-tripping-the-circuit-breaker/3/32" />
            {/* Home Repair Maintenance */}
            <Redirect exact from="/post/home-warranty-help-3-more-ways-to-insulate-your-home-this-winter" to="/resources/home-repairs-maintenance/3-more-ways-to-insulate-your-home-this-winter/6/70" />
            <Redirect exact from="/post/home-warranty-help-3-ways-to-insulate-your-home-this-winter" to="/resources/home-repairs-maintenance/3-ways-to-insulate-your-home-this-winter/6/71" />
            <Redirect exact from="/post/home-warranties-5-ways-to-take-care-of-home-repairs-without-breaking-the-bank" to="/resources/home-repairs-maintenance/5-ways-to-take-care-of-home-repairs-without-going-broke/6/74" />
            <Redirect exact from="/post/april-showers-bring-flooded-gutters-home-warranty-help" to="/resources/home-repairs-maintenance/april-showers-bring-flooded-gutters/6/77" />
            <Redirect exact from="/post/back-to-school-fall-home-maintenance-checklist" to="/resources/home-repairs-maintenance/back-to-school-fall-home-maintenance-checklist/6/68" />
            <Redirect exact from="/post/do-it-yourself-garage-door-maintenance" to="/resources/home-repairs-maintenance/do-it-yourself-garage-door-maintenance/6/69" />
            <Redirect exact from="/post/home-improvements-with-the-highest-roi" to="/resources/home-repairs-maintenance/home-improvements-with-the-highest-roi/6/79" />
            <Redirect exact from="/post/how-long-do-your-home-s-systems-and-appliances-last-home-warranty-help" to="/resources/home-repairs-maintenance/how-long-do-your-homes-systems-and-appliances-last/6/76" />
            <Redirect exact from="/post/new-year-s-resolutions-to-get-your-home-in-shape" to="/resources/home-repairs-maintenance/new-years-resolutions-to-get-your-home-in-shape/6/216" />
            <Redirect exact from="/post/remodeling-your-home-know-about-vocs" to="/resources/home-repairs-maintenance/remodeling-your-home-know-about-vocs/6/73" />
            <Redirect exact from="/post/spring-maintenance-checklist-home-warranty-help-infographic" to="/resources/home-repairs-maintenance/spring-maintenance-checklist/6/75" />
            <Redirect exact from="/post/three-home-repairs-every-homeowner-should-expect" to="/resources/home-repairs-maintenance/three-home-repairs-every-homeowner-should-expect/6/78" />
            <Redirect exact from="/post/weekly-home-maintenance-checklist" to="/resources/home-repairs-maintenance/weekly-home-maintenance-checklist/6/81" />
            <Redirect exact from="/post/why-home-maintenance-is-important" to="/resources/home-repairs-maintenance/why-home-maintenance-is-important/6/80" />
            {/* Home Warranty Education */}
            <Redirect exact from="/post/5-hacks-for-a-stress-free-holiday1" to="/resources/home-warranty-education/5-hacks-for-a-stress-free-holiday/10/218" />
            <Redirect exact from="/post/5-things-homeowners-repair-with-a-home-warranty" to="/resources/home-warranty-education/5-things-homeowners-repair-with-a-home-warranty/10/179" />
            <Redirect exact from="/post/a-guide-to-ordering-the-best-home-warranty" to="/resources/home-warranty-education/a-guide-to-ordering-the-best-home-warranty-plan/10/189" />
            <Redirect exact from="/post/a-home-warranty-and-home-insurance-what-s-the-difference-infographic" to="/resources/home-warranty-education/a-home-warranty-and-home-insurance-whats-the-difference/10/184" />
            <Redirect exact from="/post/how-a-home-warranty-is-the-best-gift-you-can-give-this-holiday-season" to="/resources/home-warranty-education/a-home-warranty-is-the-best-gift-you-can-give-this-holiday/10/230" />
            <Redirect exact from="/post/a-home-warranty-on-a-condo-protection-options-for-multi-family-housing" to="/resources/home-warranty-education/a-home-warranty-on-a-condo/10/183" />
            <Redirect exact from="/post/why-a-home-warranty-sells-your-home-faster-and-for-more-money" to="/resources/home-warranty-education/a-home-warranty-sells-your-home-faster-and-for-more-money/10/182" />
            <Redirect exact from="/post/celebrate-earth-day-with-a-home-warranty" to="/resources/home-warranty-education/celebrate-earth-day-with-a-home-warranty/10/235" />
            <Redirect exact from="/post/day-by-day-prep-for-thanksgiving-from-landmark-home-warranty-infographic" to="/resources/home-warranty-education/daybyday-prep-for-thanksgiving/10/213" />
            <Redirect exact from="/docs/default-source/Infographics/day-by-day-prep-for-thanksgiving-from-landmark" to="/resources/home-warranty-education/daybyday-prep-for-thanksgiving/10/213" />
            <Redirect exact from="/post/do-you-make-these-3-costly-homeowner-mistakes-not-getting-a-home-warranty" to="/resources/home-warranty-education/do-you-make-these-3-costly-homeowner-mistakes/10/197" />
            {/*<Redirect exact from="/post/don-t-gamble-with-your-home-how-to-protect-your-investment-home-warranty-help" to="/resources/home-warranty-education/dont-gamble-with-your-home-how-to-protect-your-investment/10/198" />*/}
            <Redirect exact from="/post/fall-in-love-with-the-best-home-warranty" to="/resources/home-warranty-education/fall-in-love-with-the-best-home-warranty/10/233" />
            <Redirect exact from="/post/five-of-the-scariest-things-covered-by-a-home-warranty" to="/resources/home-warranty-education/five-of-the-scariest-things-covered-by-a-home-warranty/10/261" />
            <Redirect exact from="/post/five-questions-to-ask-before-buying-a-home-warranty" to="/resources/home-warranty-education/five-questions-to-ask-before-buying-a-home-warranty/10/200" />
            <Redirect exact from="/post/get-a-home-warranty-and-protect-your-home-three-ways-to-protect-your-house" to="/resources/home-warranty-education/get-a-home-warranty-and-two-other-ways-to-protect-your-house/10/193" />
            <Redirect exact from="/post/how-getting-a-home-warranty-is-better-than-shopping-on-black-friday" to="/resources/home-warranty-education/getting-a-home-warranty-is-better-than-shopping-on-black-friday/10/231" />
            <Redirect exact from="/post/home-warranty-haunting-a-not-so-scary-ghost-story" to="/resources/home-warranty-education/home-warranty-haunting-a-notsoscary-ghost-story/10/215" />
            <Redirect exact from="/post/home-warranty-valentines" to="/resources/home-warranty-education/home-warranty-valentines-2015/10/234" />
            <Redirect exact from="/post/home-warranty-valentines-2016" to="/resources/home-warranty-education/home-warranty-valentines-2016/10/223" />
            <Redirect exact from="/post/how-a-home-warranty-can-save-you-from-holiday-horrors" to="/resources/home-warranty-education/how-a-home-warranty-can-save-you-from-holiday-horrors/10/229" />
            <Redirect exact from="/post/how-a-home-warranty-works" to="/resources/home-warranty-education/how-a-home-warranty-works/10/180" />
            <Redirect exact from="/post/how-to-choose-the-best-home-warranty-company" to="/resources/home-warranty-education/how-to-choose-the-best-home-warranty-company/10/186" />
            <Redirect exact from="/post/how-to-save-your-thanksgiving-dinner-with-a-home-warranty" to="/resources/home-warranty-education/how-to-save-your-thanksgiving-dinner-with-a-home-warranty/10/227" />
            <Redirect exact from="/post/how-to-take-charge-of-home-repairs-with-a-home-warranty" to="/resources/home-warranty-education/how-to-take-charge-of-home-repairs-with-a-home-warranty/10/185" />
            {/*<Redirect exact from="/post/is-a-home-warranty-worth-it" to="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" />*/}
            {/*<Redirect exact from="/Warranty/value-of-a-home-warranty.aspx" to="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" />*/}
            <Redirect exact from="/post/year-in-review-landmark-home-warranty-s-top-10-posts-in-2015" to="/resources/home-warranty-education/landmark-home-warrantys-top-10-posts-in-2015/10/127" />
            <Redirect exact from="/post/navigating-the-world-of-home-warranty-reviews" to="/resources/home-warranty-education/navigating-the-world-of-home-warranty-reviews/10/192" />
            <Redirect exact from="/post/see-more-green-with-a-home-warranty" to="/resources/home-warranty-education/see-more-green-with-a-home-warranty/10/226" />
            <Redirect exact from="/post/shoot-for-the-moon-with-the-best-home-warranty-company" to="/resources/home-warranty-education/shoot-for-the-moon-with-the-best-home-warranty-company/10/199" />
            <Redirect exact from="/post/should-i-get-a-home-warranty-flow-chart" to="/resources/home-warranty-education/should-i-get-a-home-warranty-flow-chart/10/190" />
            <Redirect exact from="/docs/default-source/Infographics/home-warranty-flow-chart" to="/resources/home-warranty-education/should-i-get-a-home-warranty-flow-chart/10/190" />
            <Redirect exact from="/post/special-christmas-additions-to-your-home-warranty-contract" to="/resources/home-warranty-education/special-christmas-additions-to-your-home-warranty-contract/10/217" />
            <Redirect exact from="/post/the-12-days-of-home-warranties" to="/resources/home-warranty-education/the-12-days-of-home-warranties/10/219" />
            <Redirect exact from="/post/the-home-warranty-based-on-a-true-story-a-movie" to="/resources/home-warranty-education/the-home-warranty-based-on-a-true-story-a-movie-poster/10/194" />
            <Redirect exact from="/post/the-home-warranty-diagnosis-process4" to="/resources/home-warranty-education/the-home-warranty-diagnosis-process/10/176" />
            <Redirect exact from="/post/the-new-homeowner-practical-holiday-gift-guide" to="/resources/home-warranty-education/the-new-homeowner-practical-holiday-gift-guide/10/221" />
            <Redirect exact from="/post/this-halloween-treat-yourself-to-a-landmark-hallo-warranty" to="/resources/home-warranty-education/this-halloween-treat-yourself-to-a-landmark-hallowarranty/10/262" />
            <Redirect exact from="/post/home-warranties-three-misguided-excuses-people-make-when-they-say-they-don-t-need-one" to="/resources/home-warranty-education/three-excuses-people-make-when-they-dont-get-a-home-warranty/10/191" />
            <Redirect exact from="/post/understanding-your-home-warranty-contract" to="/resources/home-warranty-education/understanding-your-home-warranty-contract/10/247" />
            <Redirect exact from="/post/unique-gifts-for-new-homeowners" to="/resources/home-warranty-education/unique-gifts-for-new-homeowners/10/220" />
            <Redirect exact from="/post/why-should-you-use-a-home-security-system-to-protect-your-family" to="/resources/home-warranty-education/use-a-home-security-system-to-protect-your-family/10/263" />
            <Redirect exact from="/post/what-does-a-home-warranty-cover" to="/resources/home-warranty-education/what-does-a-home-warranty-cover/10/181" />
            <Redirect exact from="/post/what-is-a-home-warranty-infographic" to="/resources/home-warranty-education/what-is-a-home-warranty/10/195" />
            <Redirect exact from="/docs/default-source/Infographics/what-is-a-home-warranty-outlines_edited" to="/resources/home-warranty-education/what-is-a-home-warranty/10/195" />
            <Redirect exact from="/post/infographic-what-your-home-repairs-cost-with-and-without-a-home-warranty" to="/resources/home-warranty-education/what-your-home-repairs-cost-with-and-without-a-home-warranty/10/188" />
            <Redirect exact from="/post/who-buys-the-home-warranty" to="/resources/home-warranty-education/who-buys-the-home-warranty/10/243" />
            <Redirect exact from="/post/why-a-home-warranty-is-worth-it-a-cautionary-illustrated-fable" to="/resources/home-warranty-education/why-a-home-warranty-is-worth-it-a-cautionary-illustrated-fable/10/187" />
            <Redirect exact from="/post/understanding-home-warranties-why-does-the-contractor-have-to-come-back-to-my-home-to-make-the-repair-or-replacement" to="/resources/home-warranty-education/why-does-the-home-warranty-contractor-leave-before-making-repairs/10/177" />
            {/* HVAC */}
            <Redirect exact from="/post/4-ways-to-keep-your-heating-bills-down" to="/resources/hvac/4-ways-to-keep-your-heating-bills-down/4/49" />
            <Redirect exact from="/post/6-signs-your-air-conditioner-needs-repairs-is-your-home-sick-home-warranty-help" to="/resources/hvac/6-signs-your-air-conditioner-needs-repairs/4/45" />
            <Redirect exact from="/post/6-steps-to-maintain-your-heater-for-fall-by-landmark-home-warranty" to="/resources/hvac/6-steps-to-maintain-your-heater-for-fall/4/47" />
            <Redirect exact from="/post/air-conditioning-service-and-maintenance" to="/resources/hvac/air-conditioning-service-and-maintenance/4/35" />
            <Redirect exact from="/post/air-duct-cleaning-scams-or-splendid-service" to="/resources/hvac/air-duct-cleaning-scam-or-splendid-service/4/40" />
            <Redirect exact from="/post/air-filters-delivered" to="/resources/hvac/air-filters-delivered/4/48" />
            <Redirect exact from="/post/get-to-know-your-central-heating-system" to="/resources/hvac/get-to-know-your-central-heating-system/4/249" />
            <Redirect exact from="/post/home-warranties-and-cracks-in-your-furnace-s-heat-exchanger" to="/resources/hvac/home-warranties-and-cracks-in-your-furnaces-heat-exchanger/4/37" />
            <Redirect exact from="/post/how-does-a-swamp-cooler-work" to="/resources/hvac/how-does-a-swamp-cooler-work/4/39" />
            <Redirect exact from="/post/How-Home-Warranties-can-Help-You-Prepare-your-Air-Conditioner-for-Summer" to="/resources/hvac/how-home-warranties-can-help-you-prepare-your-ac-for-summer/4/43" />
			<Redirect exact from="/post/how-to-clean-or-replace-your-furnace-filter" to="/resources/hvac/how-to-clean-or-replace-your-furnace-filter/4/42" />
			<Redirect exact from="/post/how-to-pick-filters-for-HVAC-from-home-warranty-company" to="/resources/hvac/how-to-pick-the-best-filter-for-your-hvac-unit/4/41" />
			<Redirect exact from="/post/how-to-winterize-your-central-air-conditioner" to="/resources/hvac/how-to-winterize-your-central-air-conditioner/4/38" />
            <Redirect exact from="/post/how-to-winterize-your-swamp-cooler" to="/resources/hvac/how-to-winterize-your-swamp-cooler/4/36" />
            <Redirect exact from="/post/hvac-maintenance-best-practices" to="/resources/hvac/hvac-maintenance-best-practices/4/34" />
            <Redirect exact from="/post/Keep-Cool-with-a-Home-Warranty" to="/resources/hvac/keep-cool-with-a-home-warranty/4/44" />
            <Redirect exact from="/post/some-telltale-signs-your-furnace-needs-repairs-is-your-home-sick-home-warranty-help" to="/resources/hvac/some-telltale-signs-your-furnace-needs-repairs/4/46" />
            <Redirect exact from="/post/what-is-a-leak-detection-test-and-other-frequently-asked-questions" to="/resources/hvac/what-is-a-leak-detection-test-and-other-frequently-asked-questions/4/33" />
            <Redirect exact from="/post/why-should-you-do-a-furnace-tune-up" to="/resources/hvac/why-should-you-do-a-furnace-tuneup/4/251" />
            {/* Landmark Home Warranty News */}
            <Redirect exact from="/post/press-release-landmark-home-warranty-s-owner-named-utah-entrepreneur-of-the-year" to="/resources/landmark-home-warranty-news/alma-jeppson-named-utah-entrepreneur-of-the-year/11/203" />
            <Redirect exact from="/post/best-of-state-announces-landmark-home-warranty-as-2015-winner-press-release" to="/resources/landmark-home-warranty-news/best-of-state-announces-landmark-home-warranty-as-winner/11/205" />
            <Redirect exact from="/post/introducing-the-landmark-bistro" to="/resources/landmark-home-warranty-news/introducing-the-landmark-bistro/11/224" />
            <Redirect exact from="/post/landmark-goes-above-and-beyond-for-home-warranty-customers" to="/resources/landmark-home-warranty-news/landmark-goes-above-and-beyond-for-home-warranty-customers/11/201" />
            <Redirect exact from="/post/landmark-home-warranty-grows-724-percent-in-three-years-landing-inc-5000-position" to="/resources/landmark-home-warranty-news/landmark-home-warranty-announced-as-634-on-inc-5000-list/11/206" />
            <Redirect exact from="/post/landmark-home-warranty-asks-community-to-help-fight-hunger-this-november" to="/resources/landmark-home-warranty-news/landmark-home-warranty-asks-community-to-help-fight-hunger/11/207" />
            <Redirect exact from="/post/utah-company-gives-back-to-employees-in-big-ways" to="/resources/landmark-home-warranty-news/landmark-home-warranty-gives-back-to-employees-in-big-ways/11/246" />
            <Redirect exact from="/post/landmark-home-warranty-lands-on-list-of-top-growing-companies-for-4th-year" to="/resources/landmark-home-warranty-news/landmark-home-warranty-lands-on-inc-5000-list-for-4th-year/11/212" />
            <Redirect exact from="/post/press-release-landmark-home-warranty-named-best-of-state-in-home-and-appliance-services-for-two-years-in-a-row" to="/resources/landmark-home-warranty-news/landmark-home-warranty-named-best-of-state-for-2nd-year/11/266" />
            <Redirect exact from="/post/landmark-home-warranty-ranked-22-in-utah-s-fast-50-awards-for-2016" to="/resources/landmark-home-warranty-news/landmark-home-warranty-ranked-22-in-utahs-fast-50-awards/11/211" />
            <Redirect exact from="/post/landmark-home-warranty-opens-one-stop-shop-for-internet-satellite-television-and-security-systems" to="/resources/landmark-home-warranty-news/landmark-home-warranty-opens-landmark-home-services/11/210" />
            <Redirect exact from="/post/landmark-home-warranty-rolls-out-new-contract-with-increased-coverage" to="/resources/landmark-home-warranty-news/landmark-home-warranty-rolls-out-new-contract/11/209" />
            <Redirect exact from="/post/home-warranty-reviews-names-landmark-best-home-warranty-company-for-region" to="/resources/landmark-home-warranty-news/landmark-named-best-home-warranty-company-for-region/11/204" />
            <Redirect exact from="/post/landmark-wins-best-home-warranty-in-region-for-the-third-year" to="/resources/landmark-home-warranty-news/landmark-wins-best-home-warranty-in-region-for-3rd-year/11/208" />
            <Redirect exact from="/post/merry-christmas-from-landmark-home-warranty" to="/resources/landmark-home-warranty-news/merry-christmas-from-landmark-home-warranty-2014/11/236" />
            <Redirect exact from="/post/press-release-landmark-home-warranty-to-host-open-house" to="/resources/landmark-home-warranty-news/press-release-landmark-home-warranty-to-host-open-house/11/202" />
            {/* Pest Control */}
            <Redirect exact from="/post/getting-the-best-home-warranty-can-get-rid-of-an-earwig-infestation" to="/resources/pest-control/all-about-earwigs-and-how-to-get-rid-of-an-infestation/9/170" />
            <Redirect exact from="/post/the-best-home-warranty-can-help-you-get-rid-of-sow-bugs" to="/resources/pest-control/all-about-sow-bugs-and-how-to-get-rid-of-an-infestation/9/172" />
            <Redirect exact from="/post/do-you-have-a-spider-infestation-use-home-warranties-to-get-rid-of-them" to="/resources/pest-control/all-about-spiders-and-how-to-get-rid-of-them/9/173" />
            <Redirect exact from="/post/how-home-warranty-reviews-can-help-you-get-rid-of-termites" to="/resources/pest-control/all-about-termites-and-how-to-get-rid-of-an-infestation/9/174" />
            <Redirect exact from="/post/how-home-warranty-companies-can-get-rid-of-centipedes" to="/resources/pest-control/educational-information-on-centipedes-and-infestations/9/166" />
            <Redirect exact from="/post/get-a-home-warranty-and-get-rid-of-an-infestation-of-silverfish" to="/resources/pest-control/educational-information-about-silverfish-and-infestations/9/280" />
            <Redirect exact from="/post/infographic-the-ultimate-guide-to-household-pests-and-how-a-home-warranty-can-help-debug-your-house" to="/resources/pest-control/infographic-the-ultimate-guide-to-household-pests/9/175" />
            <Redirect exact from="/post/home-warranties-can-rid-your-home-of-beetles" to="/resources/pest-control/learn-about-beetles-and-get-rid-of-them-in-your-home/9/171" />
            <Redirect exact from="/post/read-home-warranty-reviews-a-home-warranty-can-get-rid-of-a-cockroach-infestation" to="/resources/pest-control/learn-about-cockroaches-and-how-to-get-rid-of-an-infestation/9/168" />
            <Redirect exact from="/post/how-a-home-warranty-can-help-you-get-rid-of-ants" to="/resources/pest-control/learn-more-about-ants-and-how-to-get-rid-of-an-infestation/9/165" />
            <Redirect exact from="/post/home-warranty-companies-can-rid-your-home-of-a-millipede-infestation" to="/resources/pest-control/learn-about-millipedes-and-infestations-of-them/9/281" />
            <Redirect exact from="/post/have-clover-mites-get-rid-of-them-with-the-best-home-warranty" to="/resources/pest-control/learn-more-about-clover-mites-and-infestations/9/167" />
            <Redirect exact from="/post/how-home-warranties-can-help-you-get-rid-of-crickets" to="/resources/pest-control/learn-more-about-crickets-and-how-to-get-rid-of-an-infestation/9/169" />
            {/* Plumbing */}
            <Redirect exact from="/post/5-parts-of-thanksgiving-dinner-that-can-ruin-your-pipes-home-warranty-help" to="/resources/plumbing/5-parts-of-dinner-that-can-clog-your-pipes/2/228" />
            <Redirect exact from="/post/ask-a-plumber-best-way-to-clean-your-drains" to="/resources/plumbing/ask-a-plumber-the-best-way-to-clean-your-drains/2/6" />
			<Redirect exact from="/post/how-to-de-scale-your-pipes-home-warranty-help" to="/resources/plumbing/descale-your-pipes-overnight/2/24" />
            <Redirect exact from="/post/diy-clean-and-rid-smells-from-a-garbage-disposal-home-warranty-help" to="/resources/plumbing/diy-clean-and-rid-smells-from-a-garbage-disposal/2/16" />
			<Redirect exact from="/post/diagnose-your-plumbing-problems-home-warranty-help" to="/resources/plumbing/diagnose-your-plumbing-problems/2/28" />
            <Redirect exact from="/post/diy-fix-a-jammed-garbage-disposal-home-warranty-help" to="/resources/plumbing/diy-fix-a-jammed-garbage-disposal/2/17" />
			<Redirect exact from="/post/home-warranty-help-diy-toilet-repairs" to="/resources/plumbing/do-it-yourself-toilet-repairs/2/27" />
            <Redirect exact from="/post/home-warranty-help-how-a-toilet-works" to="/resources/plumbing/how-a-toilet-works/2/26" />
			<Redirect exact from="/post/home-warranty-help-how-your-water-heater-works" to="/resources/plumbing/how-a-water-heater-works/2/12" />
            <Redirect exact from="/post/how-close-is-your-water-heater-to-becoming-a-bomb-home-warranty-help" to="/resources/plumbing/how-close-is-your-water-heater-to-becoming-a-bomb/2/15" />
            <Redirect exact from="/post/how-does-a-sink-pop-up-mechanism-work" to="/resources/plumbing/how-does-a-sink-pop-up-mechanism-work/2/20" />
            <Redirect exact from="/post/how-does-a-garbage-disposal-work-home-warranty-help" to="/resources/plumbing/how-does-a-garbage-disposal-work/2/278" />
            <Redirect exact from="/post/home-warranty-help-how-safe-is-your-home-from-a-burst-pipe" to="/resources/plumbing/how-safe-is-your-home-from-a-burst-pipe/2/13" />
            <Redirect exact from="/post/how-to-clean-a-faucet-aerator" to="/resources/plumbing/how-to-clean-a-faucet-aerator/2/7" />
			<Redirect exact from="/post/how-to-make-your-showerhead-like-new-home-warranty-help" to="/resources/plumbing/how-to-descale-your-showerhead/2/22" />
            <Redirect exact from="/post/how-to-fix-a-clogged-sink" to="/resources/plumbing/how-to-fix-a-clogged-sink/2/10" />
            <Redirect exact from="/post/how-to-maintain-your-water-softener-home-warranty-help" to="/resources/plumbing/how-to-maintain-your-water-softener/2/25" />
            <Redirect exact from="/post/home-warranty-how-to-flushing-sediment-out-of-a-water-heater" to="/resources/plumbing/how-to-flush-sediment-out-of-a-water-heater/2/279" />
            <Redirect exact from="/post/how-to-replace-a-shower-diverter-valve" to="/resources/plumbing/how-to-replace-a-shower-diverter-valve/2/9" />
            <Redirect exact from="/post/how-to-replace-or-maintain-a-sink-pop-up-drain" to="/resources/plumbing/how-to-replace-or-maintain-a-sink-pop-up-drain-assembly/19" />
            <Redirect exact from="/post/how-to-test-your-temperature-and-pressure-relief-valve-home-warranty-help" to="/resources/plumbing/how-to-test-your-temperature-and-pressure-relief-valve/2/14" />
            <Redirect exact from="/post/pros-and-cons-of-tankless-water-heaters" to="/resources/plumbing/pros-and-cons-of-tankless-water-heaters/2/31" />
            <Redirect exact from="/post/the-difference-between-boilers-and-water-heaters" to="/resources/plumbing/the-difference-between-boilers-and-water-heaters/2/5" />
            <Redirect exact from="/post/hard-water-vs-soft-water-home-warranty-help" to="/resources/plumbing/the-difference-between-hard-and-soft-water/2/21" />
            <Redirect exact from="/post/what-is-a-sacrificial-anode-rod-and-why-is-it-in-my-water-heater" to="/resources/plumbing/what-is-a-sacrificial-anode-rod-and-why-is-it-in-my-water-heater/2/29" />
            <Redirect exact from="/post/why-does-my-tub-faucet-keep-running-when-i-turn-on-my-shower" to="/resources/plumbing/why-does-my-faucet-keep-running-when-i-turn-on-my-shower/2/8" />
            <Redirect exact from="/post/why-does-the-shower-heat-up-when-the-toilet-s-flushed" to="/resources/plumbing/why-does-the-shower-heat-up-when-the-toilet-is-flushed/2/30" />
            <Redirect exact from="/post/home-warranty-help-why-flush-sediment-out-of-a-water-heater" to="/resources/plumbing/why-flush-sediment-out-of-a-water-heater/2/11" />
            <Redirect exact from="/post/why-you-should-remove-hard-water-scale-from-your-pipes-home-warranty-help" to="/resources/plumbing/why-you-should-remove-hard-water-scale-from-your-pipes/2/23" />
            {/* Property Management */}
            <Redirect exact from="/post/property-management-maintenance-tips-to-save-money" to="/resources/property-management/property-management-maintenance-tips-to-save-money/13/237" />
            <Redirect exact from="/post/the-many-hats-of-a-property-manager-what-is-property-management" to="/resources/property-management/what-is-property-management/13/238" />
            {/* Real Estate Tips */}
            <Redirect exact from="/post/Best-Home-Warranty-Explains-TILA-RESPA" to="/resources/real-estate-tips/changes-to-tila-and-respa/8/164" />
            <Redirect exact from="/post/real-estate-industry-s-structure-intact-after-court-ruling" to="/resources/real-estate-tips/real-estate-industrys-structure-intact-after-court-ruling/8/163" />
            <Redirect exact from="/post/real-estate-investors-is-a-home-warranty-a-wise-choice" to="/resources/real-estate-tips/real-estate-investors-is-a-home-warranty-a-wise-choice/8/157" />
            <Redirect exact from="/post/rewrite-your-listing-description-and-sell-a-home-for-more-money" to="/resources/real-estate-tips/rewrite-your-listing-description-and-sell-a-home-for-more-money/8/159" />
            <Redirect exact from="/post/sleep-better-at-night-with-a-home-warranty" to="/resources/home-warranty-education/sleep-better-at-night-with-a-home-warranty/8/196" />
			<Redirect exact from="/post/snip-snip-win-a-trip-april-2016" to="/resources/real-estate-tips/snip-snip-win-a-trip-april-2016/8/225" />
			{/*<Redirect exact from="/post/tax-deductible-closing-gifts-for-re-pros" to="/resources/real-estate-tips/tax-deductible-closing-gifts-for-re-pros/8/158" />*/}
			<Redirect exact from="/post/tax-deductible-closing-gifts-for-re-pros" to="/resources/real-estate-tips/" />
            <Redirect exact from="/post/tell-tale-signs-your-buyer-is-ready-to-make-an-offer" to="/resources/real-estate-tips/telltale-signs-your-buyer-is-ready-to-make-an-offer/8/156" />
            <Redirect exact from="/post/the-5-things-real-estate-agents-wish-their-clients-knew" to="/resources/real-estate-tips/the-5-things-real-estate-agents-wish-their-clients-knew/8/161" />
            <Redirect exact from="/post/who-s-who-real-estate-titles-and-their-meanings" to="/resources/real-estate-tips/whos-who-real-estate-titles-and-their-meanings/8/162" />
            <Redirect exact from="/post/six-applications-that-are-perfect-for-real-estate-agents" to="/resources/real-estate-tips/six-applications-that-are-perfect-for-real-estate-agents/8/160" />

            {/* Routes bad blog urls to resources. */}
            <Redirect exact from="/Home-Warranty/Handy-Home-Blog*" to="/resources" />
            <Redirect exact from="/post*" to="/resources" />

            <Route exact path="/category/:category" render={({match}) => <Redirect to={`/resources/${match.params.category}`} />} />
            {/*Attempt to catch any routes with what seems like they have extensions; Remove the extension and try the route again.*/}
            <Route
                path="*.:ext"
                render={
                    ({location, match}) => <Redirect to={`${location.pathname.replace(`.${match.params.ext}`, "")}`} />
                }
            />

            <Redirect from="*" to="/page-not-found" />
        </Switch>
    );
}
