import { combineEpics } from "redux-observable";

import closingItemEpic from "./closingItem.epic";
import dashboardEpic from "./dashboard.epic";
import dispatchEpic from "./dispatch.epic";
import dispatchStatisticsEpic from "./dispatchStatistics.epic";
import profileEpic from "./profile.epic";

const epic = combineEpics(
    dispatchStatisticsEpic,
    dashboardEpic,
    dispatchEpic,
    profileEpic,
    closingItemEpic,
);
export default epic;
