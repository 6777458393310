import * as Landmark from "models/landmark-api";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import Scroll from "react-scroll";
import { createOrderActions, OrderActionCreators } from "../../actions/order.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { Footer } from "../../components/Footer";
import { Meta } from "../../components/Meta";
import { OfferDetails } from "../../components/offers/OfferDetails";
import { QuickOrder } from "../../components/QuickOrder";
import { RequestBrochure } from "../../components/sections/RequestBrochure";
import { Reviews } from "../../components/sections/Reviews";
import { PlanType, PlanTypeName } from "../../constants/TransactionType";
import { getOffers } from "../../selectors/offers.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { scrollToLocationHash } from "../../utils/scroll";
import { Benefits } from "./Benefits";
import { MeetYourRep } from "./MeetYourRep";
import { REPros } from "./REPros";
import { REProsSubnav } from "./REProsSubnav";
import { LimitsAndExclusions } from "../../components/LimitsAndExclusions";
import { createDialogActions, DialogActionCreators } from "../../actions/dialog.actions";
import { createLimitsAndExclusionsActions, LimitsAndExclusionsActionCreators } from "../../actions/limitsAndExclusions.actions";
import { Areas } from "../../constants/Areas";
import { createGetIsDialogOpen } from "../../selectors/dialog.selectors";

const styles = {
    hr: {
        borderBottom: "1px solid #5b4562",
        lineHeight:".1em",
        margin: "40px 0 20px",
        textAlign: "center",
        width: "100%"
    } as React.CSSProperties,
    span: {
        color: "#5b4562",
        background:"#fff",
        padding:"0 10px"
    }
};

interface Props extends Partial<RouteComponentProps<any>> {
    type?: "realEstate";
    store?: {
        isLimitsAndExclusionsOpen: boolean;
        offers: Landmark.ContractOffer[];
    };
    actions?: {
        limitsAndExclusionsActions: DialogActionCreators;
        limitsAndExclusions: LimitsAndExclusionsActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
    };

}

@connect(
    state => ({
        store: {
            isLimitsAndExclusionsOpen: createGetIsDialogOpen(Areas.LimitsAndExclusions.Details)(state),
            offers: getOffers(state),
        }
    }),
    bindActionCreatorsToProps({
        limitsAndExclusionsActions: createDialogActions(Areas.LimitsAndExclusions.Details),
        limitsAndExclusions: createLimitsAndExclusionsActions(),
        order: createOrderActions(),
        router: createRouterActions(),
    })
)
export class ProfessionalsPage extends React.Component<Props, any> {
    componentDidMount() {
        let scroller = Scroll.animateScroll;
        if (!this.props.location.hash.length) {
            scroller.scrollToTop();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.location.hash.length &&
            prevProps.location.hash !== this.props.location.hash
        ) {
            scrollToLocationHash(this.props);
        }
    }

    /**
     * Gets the meta data for the page based on the current has value of the current url.
     *
     * @memberof HomeownerPage
     */
    getMetaData = () => {
        const { hash = "" } = this.props.location || {} as Location;

        switch(hash.toLowerCase().trim()) {
            case "#home-warranty-plans":
                return (
                    <Meta
                        title="Home Buyers' Warranty Plans | Landmark Home Warranty"
                        description="Compare coverage for your home buyers across Landmark's warranty plans that service homes in Arizona, Idaho, Nevada, Oregon, Texas, and Utah."
                        canonical="/home-buyers-warranty/protection-plans"
                    />
                );
            case "#reviews":
                return (
                    <Meta
                        title="Residential Service Contract Company | Landmark Home Warranty"
                        description="From being a licensed residential service contract company to our award-winning service, this is why real estate professionals choose Landmark."
                        canonical="/home-buyers-warranty/residential-service-contract"
                    />
                );
            case "#your-local-representative":
                return (
                    <Meta
                        title="Your Local Home Warranty Rep | Landmark Home Warranty"
                        description="Need local, personalized service from your home warranty company? Our local reps will help you get the best coverage for your real estate clients."
                        canonical="/home-buyers-warranty/your-local-representative"
                    />
                );
            case "#benefits":
                return (
                    <Meta
                        title="Real Estate Education Tools | Landmark Home Warranty"
                        description="Landmark provides you with real estate and home warranty education tools to help teach your clients about every step in the real estate process."
                        canonical="/home-buyers-warranty/real-estate-education-tools"
                    />
                );
            default:
                return (
                    <Meta
                        title="Recommend a Home Buyer's Warranty | Landmark Home Warranty"
                        description="Real estate professionals use Landmark Home Warranty to protect their home buyers with great coverage and award-winning service."
                        canonical="/home-buyers-warranty"
                    />
                );
        }
    }

    startOrder = (contractOfferId: string) => {
        this.props.actions.order.newOrder(PlanTypeName.RealEstate);

        // Select the offer
        const offer = this.props.store.offers.find(offer => offer.contractOfferId === contractOfferId);
        this.props.actions.order.selectOffer(offer);

        // Move to the order page and scroll to the top
        this.props.actions.router.push(`/order/${PlanTypeName.RealEstate}`);
        window.scrollTo(0, 0);
    }

    /**
     * A react standard method to render this component.
     */
    render() {
        return (
            <main className="professionals" style={{ paddingTop: "45px" }}>
                {this.getMetaData()}
                <REPros
                    onOrder={() => {
                        this.props.actions.order.newOrder(PlanTypeName.RealEstate);
                        this.props.actions.router.push(`/order/${PlanTypeName.RealEstate}`);
                    }} />
                <REProsSubnav />
                <div style={{ backgroundColor: "rgb(251,251,251)" }}>
                    <QuickOrder />
                </div>
                <div style={styles.hr}><span style={styles.span }>OR</span></div>
                <OfferDetails
                    isMonthlyPrice={false}
                    planTypeId={PlanType.RealEstate}
                    onOrder={contractOfferId => this.startOrder(contractOfferId)} />
                <RequestBrochure />
                <Reviews
                    type={"realEstate"} />
                <MeetYourRep />
                <Benefits />
                <Footer />
                <LimitsAndExclusions
                    dialogActions={this.props.actions.limitsAndExclusionsActions}
                    isDialogOpen={this.props.store.isLimitsAndExclusionsOpen}
                />
            </main>
        );
    }
}
