import * as Landmark from "models/landmark-api";

import { ActionCreatorMap } from "../store/componentBindings";
import { AsyncActionCreators, createAsyncActions, AsyncAction } from "./defs";

export const ReferenceActionTypes = {
    GetStateByZipCode: AsyncAction("reference/state/getByZipCode"),
    LoadPropertyTypes: AsyncAction("reference/propertyTypes/load"),
    LoadStates: AsyncAction("reference/states/load"),
    LoadTrades: AsyncAction("reference/trades/load"),
    LoadZipCodes: AsyncAction("reference/zipCodes/load"),
};
Object.freeze(ReferenceActionTypes);

export interface ReferenceActionCreators extends ActionCreatorMap {
    /**
     * Get state by zip code.
     */
    getStateByZipCode: AsyncActionCreators<string, Landmark.State>;

    /**
     * Loads property types from the server.
     */
    loadPropertyTypes: AsyncActionCreators<void, Landmark.PropertyType[]>;

    /**
     * Load states from the server.
     */
    loadStates: AsyncActionCreators<void, Landmark.State[]>;

    /**
     * Loads all trades from the server.
     */
    loadTrades: AsyncActionCreators<void, Landmark.Trade[]>;

    /**
     * Loads zip codes from the server.
     * @param {string} state The state whose zip codes should be loaded.
     */
    loadZipCodes: AsyncActionCreators<string, Landmark.ZipCode[]>;
}

export function createReferenceActions(): ReferenceActionCreators {
    return {
        getStateByZipCode: createAsyncActions(ReferenceActionTypes.GetStateByZipCode),
        loadPropertyTypes: createAsyncActions(ReferenceActionTypes.LoadPropertyTypes),
        loadStates: createAsyncActions(ReferenceActionTypes.LoadStates),
        loadTrades: createAsyncActions(ReferenceActionTypes.LoadTrades),
        loadZipCodes: createAsyncActions(ReferenceActionTypes.LoadZipCodes),
    };
}
