import { actions as toastrActions } from "react-redux-toastr";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { empty, from, of } from "rxjs";
import { debounceTime, filter, map, mapTo, switchMap, switchMapTo, take, timeoutWith } from "rxjs/operators";
import { mapWithEmptyFilter } from "rxjs/custom-operators";
import { getIsWaitingForAnything } from "selectors/wait.selectors";
import { createStoreActions, ErrorAction, HydrateAction, StoreActionTypes } from "../actions/store.actions";
import { StorageKeys } from "../constants/Storage";
import environment from "../environment";

const storeActions = createStoreActions();

/**
 * Displays any global notices that have been defined in the static notices.js file.
 */
const handleDisplayGlobalNotices: Epic<Action> = action$ => action$.pipe(
    ofType(StoreActionTypes.Init.BEGIN),
    switchMap(action => {
        const global: any = window;
        const notices = global.getGlobalLandmarkNotices && global.getGlobalLandmarkNotices() || [];
        // Reverse order for UI to look correct
        return from(notices.reverse());
    }),
    map(toastrActions.add),
);

/**
 * Fires a hydrate action for each storage key set in local/session storage.
 */
const handleHydrateState: Epic<
    Action | HydrateAction
> = action$ => action$.pipe(
    ofType(StoreActionTypes.Init.BEGIN),
    switchMap(action => from(Object.keys(StorageKeys)).pipe(
        mapWithEmptyFilter(key => {
            const storageKey = StorageKeys[key];

            // Retrieve state from storage for each storage key
            const state = JSON.parse(window.sessionStorage.getItem(storageKey) || window.localStorage.getItem(storageKey));
            if (state) {
                // Hydrate that piece of state
                return storeActions.hydrate(storageKey, state);
            }
            return null;
        })
    )),
);

/**
 * Handles unexpected errors by clearing local/session storage,
 * in case either was the culprit for the error.
 */
const handleUnexpectedErrors: Epic<
    Action | ErrorAction
> = action$ => action$.pipe(
    ofType(StoreActionTypes.UNEXPECTED_ERROR),
    switchMap(action => {
        // Only clear keys if debug is disabled
        if (!environment.isDebugEnabled) {
            Object
                .keys(StorageKeys)
                .forEach(key => {
                    const storageKey = StorageKeys[key];

                    // An unexpected error occurred, clear out storage
                    // in case it was the culprit.
                    window.sessionStorage.removeItem(storageKey);
                    window.localStorage.removeItem(storageKey);
                });
        }

        return empty();
    }),
);

// /**
//  * Handles flagging the store as initialization being completed.
//  */
const handleCompleteInitialization: Epic<
    Action
> = (action$, state$) => action$.pipe(
    ofType(StoreActionTypes.Init.BEGIN),
    switchMapTo(state$),
    // Give the store a moment to catch up
    debounceTime(1),
    // Wait until we're not waiting for anything in state
    filter(state => !getIsWaitingForAnything(state)),
    // When we're no longer waiting, only trigger once
    take(1),
    // When that happens, success!
    mapTo(storeActions.init.success()),
    // We timeout at 45 seconds with initialization failure
    timeoutWith(45000, of(storeActions.init.failure(new Error("Initialization timed out."))))
);

const epic = combineEpics(
    handleCompleteInitialization,
    handleDisplayGlobalNotices,
    handleHydrateState,
    handleUnexpectedErrors,
);
export default epic;
