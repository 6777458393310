
import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { UsersActionTypes } from "../../actions/account/users.actions";
import { PayloadAction } from "../../actions/defs";

export interface UsersState {
    selectedUserId: string;
    list: Landmark.User[];
}

const defaultState: UsersState = {
    selectedUserId: null,
    list: null,
};

export const UsersReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<string | Error | Landmark.User[]>
) => {
    switch (action.type) {
        case UsersActionTypes.Load.SUCCESS: {
            return update(state, {
                list: { $set: (action as PayloadAction<Landmark.User[]>).payload }
            });
        }
        case UsersActionTypes.SELECT_USER: {
            return update(state, {
                selectedUserId: { $set: (action as PayloadAction<string>).payload }
            });
        }
        // FIXME: reimplement
        // case UsersActionTypes.SAVE.SUCCESS: {
        //     const response = action as AsyncActionResponse<Landmark.EditProfileResponse>;
        //     return state.set("primaryUser", Landmark.createUser(response.result.json.user));
        // }
    }

    // If nothing changed, return the original state
    return state;
};
