import * as Landmark from "models/landmark-api";

import { AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const PasswordActionTypes = {
    Change: AsyncAction(`account/password/change`),
    Forgot: AsyncAction(`account/password/forgot`),
};

/**
 * Action creators for login actions, used to simplify working with and dispatching actions.
 */
export function createPasswordActions(): PasswordActionCreators {
    return {
        change: createAsyncActions(PasswordActionTypes.Change),
        forgot: createAsyncActions(PasswordActionTypes.Forgot),
    };
}

export interface PasswordActionCreators extends ActionCreatorMap {
    /**
     * Changes the user's password.
     */
    change: AsyncActionCreators<Landmark.ChangePasswordRequest, void>;

    /**
     * Send a forgot password email to the email provided.
     */
    forgot: AsyncActionCreators<string, Landmark.ForgotPasswordResponse>;
}
