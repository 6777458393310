import { createSelector } from "./common.selectors";
import { ApplicationState } from "../store/app";

/**
 * Returns true if there are any global waits.
 */
export const getIsWaiting = createSelector(
    (state: ApplicationState) => state.wait,
    wait => {
        return Object.keys(wait.globalWaits).length > 0;
    }
);

export const createGetIsWaitingForArea = (areaName: string) => createSelector(
    (state: ApplicationState) => state.wait,
    wait => !!(wait.globalWaits[areaName]) || !!(wait.localWaits[areaName])
);

export const getIsWaitingForAnything = createSelector(
    (state: ApplicationState) => state.wait,
    getIsWaiting,
    (wait, isGlobalWaiting) => {
        return isGlobalWaiting || Object.keys(wait.localWaits).length > 0;
    }
);
