import {
    connect as reduxConnect,
    InferableComponentDecorator,
    MapDispatchToPropsFunction,
    MapDispatchToPropsObject,
    MapStateToProps,
} from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "./app";

/**
 * Binds the redux store to the component's properties.
 */
export function bindStoreToProps(storeMap: (state: ApplicationState, props?: any) => any) {
    return (store, props) => {
        let mappedStore = storeMap(store, props);
        return { store: mappedStore };
    };
}

function iterateActions(actionCreators: any, dispatch: Dispatch<any>) {
    const keys = Object.keys(actionCreators);
    let actions = {};
    for (let k of keys) {
        const result = bindActions(actionCreators[k], dispatch);
        actions[k] = result;
    }
    return actions;
}

function bindActions(actionCreators: any, dispatch: Dispatch<any>) {
    if (typeof actionCreators === "function") {
        return bindActionCreators(actionCreators, dispatch);
    }
    else {
        return iterateActions(actionCreators, dispatch);
    }
}

/**
 * A map of keys to action creators.
 */
export type ActionCreatorMap = { [key: string]: Function | ActionCreatorMap };

/**
 * Binds all actions creators for each action within the app.
 * @param actionCreators A map of actions to be mapped to props.
 */
export function bindActionCreatorsToProps(actionCreators: ActionCreatorMap) {
    return (dispatch: Dispatch<any>) => ({ actions: bindActions(actionCreators, dispatch) });
}

/**
 * A wrapper around the redux @connect method with proper TypeScript typings.
 * @param mapStateToProps A method to map state to props.
 * @param mapDispatchToProps A method to map dispatch to props.
 */
export function connect<TStateProps, TDispatchProps, TOwnProps>(
    mapStateToProps: MapStateToProps<TStateProps, TOwnProps>,
    mapDispatchToProps?: MapDispatchToPropsFunction<TDispatchProps, TOwnProps> | MapDispatchToPropsObject
) {
    return reduxConnect(mapStateToProps, mapDispatchToProps) as InferableComponentDecorator;
}
