
import { Action } from "redux";

import { appId, PayloadAction } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const ConfirmationActionTypes = {
    SHOW: `${appId}/confirmation/SHOW`,
    CONFIRM: `${appId}/confirmation/CONFIRM`,
    CANCEL: `${appId}/confirmation/CANCEL`,
};

export interface ConfirmationActionCreators extends ActionCreatorMap {
    show: (title:string, content: string) => PayloadAction<{
        title: string;
        content: string;
    }>;
    confirm: () => Action;
    cancel: () => Action;
}

export function createConfirmationActions(): ConfirmationActionCreators {
    return {
        show: (content: string, title: string) => ({
            type: ConfirmationActionTypes.SHOW,
            payload: {
                content,
                title,
            }
        }),
        confirm: () => ({ type: ConfirmationActionTypes.CONFIRM }),
        cancel: () => ({ type: ConfirmationActionTypes.CANCEL }),
    };
}
