import * as React from "react";
import Scroll from "react-scroll";

import { RequestBrochure } from "./RequestBrochure";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { PlanTypeName } from "../../constants/TransactionType";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { getCanOrder } from "selectors/order.selectors";

interface Props {
    onOrder: Function;
    store?: {
        canOrder: boolean;
    };
    actions?: {
        router: RouterActionCreators;
    };
}

@connect(
    state => ({
        store: {
            canOrder: getCanOrder(state),
        }
    }),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class REPros extends React.Component<Props, any> {

    componentDidMount() {
        if (!this.props.store.canOrder) {
            window.location.replace("https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/");
        }
    }

    handleOrder = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        if (this.props.onOrder && this.props.store.canOrder) {
            this.props.onOrder();
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    handleReviewsClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty#residential-service-contract`);
        scroller.scrollTo("reviews", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }
    handleRepsClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty#your-local-representative`);
        scroller.scrollTo("your-local-representative", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }
    handleBenefitsClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty/real-estate-education-tools`);
        scroller.scrollTo("benefits", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    render() {
        return (
            <section className="section-intro professionals">
                <div className="container">
                    <div className="row intro-content">
                        <i title=" Icon for the real estate professionals page." className="section-icon-inverted icon-professional"></i>
                        <h1>Protect Your Home Buyers</h1>
                        <p>As a real estate professional, you want to help protect your home buyers with a home warranty company that has comprehensive coverage, great customer service, and a quick and easy ordering process. Landmark Home
                        Warranty goes above and beyond to help you help your clients. Learn more about Landmark’s local representatives in your area, free educational materials, and our warranty plans for your home buyers today.</p>
                        <div className="btn-group">
                            <a className="btn-white btn-professional"
                                href={`/order/${PlanTypeName.RealEstate}`}
                                onClick={e => this.handleOrder(e)}>Place an Order</a>
                        </div>
                    </div>
                    <div className="row intro-callouts">
                        <div className="column fourth">
                            <h4><a className="open-modal"
                                href="/home-buyers-warranty/real-estate-education-tools"
                                onClick={e => this.handleBenefitsClick(e)}
                                style={{ fontSize: 20 }}
                            >Educational Tools<i data-icon="j"></i></a></h4>
                            <p>Landmark Home Warranty works closely with real estate professionals like you and provides free educational materials to help <b>you</b> help your clients.</p>
                        </div>
                        <div className="column fourth">
                            <RequestBrochure />
                            <p>Download a Landmark Home Warranty contract from your state to view our coverage and pricing on warranties for your home buyers.</p>
                        </div>
                        <div className="column fourth">
                            <h4><a className="open-modal"
                                href="/home-buyers-warranty/residential-service-contract"
                                onClick={e => this.handleReviewsClick(e)}
                                style={{ fontSize: 20 }}
                            >Why Choose Us?<i data-icon="j"></i></a></h4>
                            <p>Landmark prides itself in affordable  pricing for your clients. We provide local reps as well as in-house support teams for you.</p>
                        </div>
                        <div className="column fourth">
                            <h4><a className="open-modal"
                                href="/home-buyers-warranty/your-local-representative"
                                onClick={e => this.handleRepsClick(e)}
                                style={{ fontSize: 20 }}
                            >Local Landmark Reps<i data-icon="j"></i></a></h4>
                            <p>Landmark provides reps for RE Pros wherever you are located! Contact your local rep for ordering, claims management, and more.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
