
import * as Landmark from "models/landmark-api";

import { createSelector } from "./common.selectors";
import { ApplicationState } from "../store/app";

const getAuthState = (state: ApplicationState) => state.auth;

export const getAuthToken = createSelector(
    getAuthState,
    auth => auth.response && auth.response.access_token
);
export const getAuthRoles = createSelector(
    getAuthState,
    auth => auth.response && auth.response.roles
);
// Creates a selector that retrieves the authenticated user
export const getAuthenticatedUser = createSelector(
    getAuthState,
    auth => {
        if (auth.response && auth.response.user) {
            return auth.response.user;
        }
        return {
            isAnonymous: true,
            isAuthenticated: false
        } as Landmark.User;
    }
);
export const getIsAuthenticated = createSelector(
    getAuthState,
    auth => auth.response && auth.response.isAuthenticated
);
export const getIsImpersonated = createSelector(
    getAuthState,
    auth => auth.response && auth.response.isImpersonated
);

export const getIsOptedInToSms = createSelector(
    getAuthState,
    auth => {
        let user = auth && auth.response.user;
        if (!user) {
            return false;
        }
        return user.isOptedInToSms;
    }
);


export const getForgotPassword = createSelector(
    getAuthState,
    auth => auth.response && auth.response.forgotPassword
);
export const getLastUsername = createSelector(
    getAuthState,
    auth => auth.lastUsername
);

/**
 * Gets the full name (first and last name)
 */
export const getFullName = (name: Landmark.Name) => name && `${name.firstName || ""} ${name.lastName || ""}`.trim() || "";

