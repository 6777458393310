import * as React from "react";
import environment from "../../environment";
import { RouteComponentProps } from "react-router";


interface Props extends Partial<RouteComponentProps<any>>{
    policyId: string;
}

export class WarrantyLink extends React.Component<Props> {

    getUrl() {
        let policyId = this.props.match.params.policyId;
        let baseUrl = environment.isProduction ? "https://realestate.oneguardhomewarranty.com/warranty-link/lm/" : "http://localhost:4200/warranty-link/lm/";
        return baseUrl + policyId;
    }
    render() {
        return (
            <iframe src={this.getUrl()} height="1350px" width="970px" scrolling="no">
                </iframe>
        );
    }

}
