import * as Landmark from "models/landmark-api";
import * as React from "react";

import { createReviewActions, ReviewActionCreators } from "../../actions/review.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { Slider } from "../Slider";
import { Wait } from "../Wait";
import { Areas } from "../../constants/Areas";
import { getReviews } from "../../selectors/review.selectors";
import { createGetIsWaitingForArea } from "../../selectors/wait.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { WhyRealtorsChooseLandmark } from "./WhyRealtorsChooseLandmark";

type ReviewType = "customer" | "contractor" | "homeowner" | "realEstate";

interface Props {
    store?: {
        isWaiting: boolean;
        reviews: Landmark.Review[];
    };
    actions?: {
        review: ReviewActionCreators;
        router: RouterActionCreators;
    };
    type: ReviewType;
}

interface State {
    selectedIndex: number;
}

class Controls {
    slider: Slider;
}

@connect(
    (state, props) => ({
        store: {
            isWaiting: createGetIsWaitingForArea(Areas.Reviews)(state),
            reviews: getReviews(state, props)
        }
    }),
    bindActionCreatorsToProps({
        review: createReviewActions(),
        router: createRouterActions(),
    })
)
export class Reviews extends React.Component<Props, State> {
    controls = new Controls();


    constructor(props: Props) {
        super(props);

        if (!props.store.reviews.length) {
            this.state = {
                selectedIndex: 0,
            };

            // Load reviews if they haven't already been loaded
            this.loadReviews(props.type);
        } else {
            this.state = {
                selectedIndex: Math.floor(props.store.reviews.length / 2)
            };
        }
    }

    loadReviews = (type: ReviewType) => {
        switch (type) {
            case "contractor":
                this.props.actions.review.loadContractorReviews.begin();
                break;
            case "realEstate":
                this.props.actions.review.loadRealEstateReviews.begin();
                break;
            case "homeowner":
                this.props.actions.review.loadHomeownerReviews.begin();
                break;
            case "customer":
            default:
                this.props.actions.review.loadCustomerReviews.begin();
                break;
        }
    }

    componentWillReceiveProps(newProps: Props) {
        this.setState({
            selectedIndex: Math.floor(newProps.store.reviews.length / 2),
        });
    }

    renderHeader = () => {
        let reviewHeader;
        switch (this.props.type) {
            case "contractor":
                reviewHeader = <h1>Contractor Reviews</h1>;
                break;
            case "homeowner":
                reviewHeader =
                    <a href="/homeowner-warranty#reviews"
                        onClick={e => {
                            e.preventDefault();
                            this.props.actions.router.push("/homeowner-warranty#reviews");
                        }}><h1>Homeowner Warranty Reviews</h1></a>;
                break;
            case "realEstate":
                reviewHeader = <WhyRealtorsChooseLandmark />;
                break;
            case "customer":
            default:
                reviewHeader = <h1>Landmark Home Warranty Reviews</h1>;
                break;
        }

        return reviewHeader;
    }

    renderContentParagraph = () => {
        let contentParagraph;
        switch (this.props.type) {
            case "contractor":
                contentParagraph = <p>Still need convincing? See what other contractors are saying about working with Landmark Home Warranty: </p>;
                break;
            case "homeowner":
                contentParagraph = <p>Not sure if a home warranty from Landmark is right for you?<br />Read what homeowners just like you have said about our service.</p>;
                break;
            case "realEstate":
                contentParagraph = null;
                break;
            case "customer":
            default:
                contentParagraph = <p>See why homeowners and real estate professionals are raving about Landmark’s home warranty service by reading their reviews.</p>;
                break;
        }
        return contentParagraph;
    }

    render() {
        return (
            <Wait isWaiting={this.props.store.isWaiting}>
                <section id="reviews" className="section-reviews">
                    <div className="container">
                        {this.renderHeader()}
                        {this.renderContentParagraph()}
                        <div style={{ height: "575px" }}>
                            <Slider
                                ref={control => this.controls.slider = control}
                                selectedIndex={this.state.selectedIndex}
                                onChange={selectedIndex => this.setState({ selectedIndex })}>
                                {this.props.store.reviews.map((review, index) => {
                                    return (<div
                                        className="slider-content"
                                        key={index}
                                        style={this.state.selectedIndex === index ? {} : { visibility: "hidden" }}>
                                            <a data-icon="i" style={{ position: "absolute", left: "5%", top: "0.5%" }} onClick={() => this.controls.slider.prev()}></a>
                                            <div className="name" style={{ display: "flex", flexFlow: "wrap", textAlign: "center", padding: "0 15%" }}>
                                                <p>{review.name}</p>
                                            </div>
                                            <a data-icon="j" style={{ position: "absolute", right: "5%", top: "0.5%" }} onClick={() => this.controls.slider.next()}></a>
                                        <p>{review.contents}</p>
                                    </div>);
                                })}
                            </Slider>
                        </div>
                    </div>
                </section>
            </Wait>
        );
    }
}
