import { createSelector } from "./common.selectors";
import { ApplicationState } from "../store/app";

/**
 * Get sales reps.
 */
export const getSalesReps = createSelector(
    (state: ApplicationState) => state.salesRep,
    salesRep => salesRep.list
);
