import * as React from "react";

export const FullScreen = {
    outer: {
        display: "block",
    } as React.CSSProperties,
    container: props => ({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.5)",
        transition: `opacity 0.5s${props.isWaiting ? ` ${props.delay}ms` : ""}, z-index 0.5s`,
        opacity: props.isWaiting ? 1 : 0,
        zIndex: props.isWaiting ? 1000 : -1,
    }) as React.CSSProperties,
    loader: {
        position: "fixed",
        top: "50%",
        left: "50%",
        margin: "-32px 0 0 -32px"
    } as React.CSSProperties,
};

export const Default = {
    outer: {
        position: "relative",
    } as React.CSSProperties,
    container: props => ({
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: 10,
        transition: `opacity 0.5s${props.isWaiting ? ` ${props.delay}ms` : ""}, z-index 0.5s`,
        opacity: props.isWaiting ? 1 : 0,
        zIndex: props.isWaiting ? 1000 : -1,
    }) as React.CSSProperties,
    loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "-32px 0 0 -32px"
    } as React.CSSProperties,
};
