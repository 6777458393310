import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { AuthActionTypes } from "../actions/auth.actions";

import { PayloadAction } from "../actions/defs";
import { LimitsAndExclusionsActionTypes } from "../actions/limitsAndExclusions.actions";

export interface LimitsAndExclusionsState  {
    response: Landmark.LimitsAndExclusions;
}

const defaultState: LimitsAndExclusionsState = {
    response: null,
};

export const LimitsAndExclusionsReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.GetContractOffersResponse>
) => {
    switch (action.type) {
        case LimitsAndExclusionsActionTypes.Get.SUCCESS: {
            const getAction = action as PayloadAction<Landmark.LimitsAndExclusions>;

            return update(state, {
                response: { $set: getAction.payload }
            });
        }
        case LimitsAndExclusionsActionTypes.Get.FAILURE: {
            return update(state, {
                response: { $set: null }
            });
        }
        case AuthActionTypes.LOGOUT: {
                // Clear our state on logout
                return defaultState;
        }
        default: break;
    }

    // If nothing changed, return the original state
    return state;
};
