import * as Landmark from "models/landmark-api";
import  * as React from "react";

import { DropDown } from "./DropDown";
import { ReferenceActionCreators, createReferenceActions } from "../actions/reference.actions";
import { getStates } from "../selectors/reference.selectors";
import { connect, bindActionCreatorsToProps } from "../store/componentBindings";

const styles = {
    title: {
        display: "inline-block",
    },
};

interface Props {
    actions?: {
        reference: ReferenceActionCreators;
    };
    store?: {

        states?: Landmark.State[];
    };
    className?: string;
    disabled?: boolean;
    id?: string;
    label?: string;
    name?: string;
    onChange?: React.FormEventHandler<HTMLSelectElement>;
    // True to show all states in drop down, false to display only states that we provide coverage in.
    showAllStates?: boolean;
    style?: React.CSSProperties;
    title?: React.ReactNode;
    value?: string;
}

@connect(
    (state, props: Props) => ({
        store: {
            states: getStates(state, props)
        }
    }),
    bindActionCreatorsToProps({
        reference: createReferenceActions(),
    })
)
export class ChooseState extends React.Component<Props, any>{
    constructor(props: Props) {
        super(props);

        if (!props.store.states.length) {
            props.actions.reference.loadStates.begin();
        }
    }

    handleStateChange = async (e: React.FormEvent<HTMLSelectElement>) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    renderWhichStates = () => {
        const statesOptions = [];
        statesOptions.push(<option key={-1} value="">{this.props.label || "Choose A State"}</option>);
        for (let state of this.props.store.states) {
            statesOptions.push(<option key={state.stateCode} value={state.stateCode}>{state.description}</option>);
        }
        return statesOptions;
    }

    render() {
        let cls: string = (this.props.className && this.props.className.toLowerCase().indexOf("select") >= 0) ?
            this.props.className :
            `select${this.props.className ? ` ${this.props.className}` : "" }`;

        return (
            <div>
                {this.props.title ? <div style={styles.title}>{this.props.title}</div> : ""}
                <div className="field-wrap">
                    <div className={cls}>
                        <DropDown
                            disabled={this.props.disabled}
                            id={this.props.id || ""}
                            listItemsFormatted={this.renderWhichStates()}
                            name={this.props.name}
                            onChange={e => this.handleStateChange(e)}
                            value={this.props.value || ""}
                            style={this.props.style}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
