import * as Landmark from "models/landmark-api";

import { appId, AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const DispatchActionTypes = {
    ADD_PART: `${appId}/account/dispatch/ADD_PARTS`,
    CreateNote: AsyncAction(`account/dispatch/createNote`),
    LoadDetails: AsyncAction(`account/dispatch/details/load`),
    REMOVE: `${appId}/account/dispatch/REMOVE`,
    REMOVE_PART: `${appId}/account/dispatch/REMOVE_PART`,
    Save: AsyncAction(`account/dispatch/save`),
    Search: AsyncAction(`account/dispatch/search`),
    SELECT: `${appId}/account/dispatch/SELECT`,
    SET_DISCOUNT: `${appId}/account/dispatch/SET_DISCOUNT`,
    SET_VALUES: `${appId}/account/dispatch/SET_VALUES`,
    SET_VIEW: `${appId}/account/dispatch/SET_VIEW`,
    SET_SERVICE_FEE_COLLECTED: `${appId}/account/dispatch/SET_SERVICE_FEE_COLLECTED`
};

export function createDispatchActions(): DispatchActionCreators {
    return {
        addPart: (dispatchPart: Landmark.DispatchPartDetails) => ({
            type: DispatchActionTypes.ADD_PART,
            payload: dispatchPart
        }),
        createNote: createAsyncActions(DispatchActionTypes.CreateNote),
        loadDetails: createAsyncActions(DispatchActionTypes.LoadDetails),
        remove: (dispatchTicketId: string) => ({
            type: DispatchActionTypes.REMOVE,
            payload: dispatchTicketId,
        }),
        removePart: (dispatchPart: Landmark.DispatchPartDetails) => ({
            type: DispatchActionTypes.REMOVE_PART,
            payload: dispatchPart
        }),
        save: createAsyncActions(DispatchActionTypes.Save),
        search: createAsyncActions(DispatchActionTypes.Search),
        select: (dispatchTicketId: string) => ({
            type: DispatchActionTypes.SELECT,
            payload: dispatchTicketId,
        }),
        setDiscount: (discount: number) => ({
            type: DispatchActionTypes.SET_DISCOUNT,
            payload: discount,
        }),
        setServiceFeeCollected: (amountCollected: number) => ({
            type: DispatchActionTypes.SET_SERVICE_FEE_COLLECTED,
            payload: amountCollected
        }),
        setValues: (values: any) => ({
            type: DispatchActionTypes.SET_VALUES,
            payload: values,
        }),
        setView: (view: string) => ({
            type: DispatchActionTypes.SET_VIEW,
            payload: view,
        }),
    };
}

export interface DispatchActionCreators extends ActionCreatorMap {
    /**
     * Adds a part to the selected dispatch.
     */
    addPart: (part: Landmark.DispatchPartDetails) => PayloadAction<Landmark.DispatchPartDetails>;

    /**
     * Adds a note to a dispatch.
     */
    createNote: AsyncActionCreators<Landmark.AddDispatchNoteRequest, Landmark.AddDispatchNoteResponse>;

    /**
     * Loads details for a dispatch.
     */
    loadDetails: AsyncActionCreators<{ workOrderId: string }, Landmark.ContractorDispatchSearchResponseItem>;

    /**
     * Removes the dispatch with the matching id.
     */
    remove: (dispatchTicketId: string) => PayloadAction<string>;

    /**
     * Removes a part from the selected dispatch.
     */
    removePart: (part: Landmark.DispatchPartDetails) => PayloadAction<Landmark.DispatchPartDetails>;

    /**
     * Saves a dispatch.
     */
    save: AsyncActionCreators<{ dispatchId: string, updateValue: Landmark.ContractorDispatchUpdateRequest}, void>;

    /**
     * Searches for dispatches.
     */
    search: AsyncActionCreators<Landmark.ContractorDispatchSearchRequest, Landmark.ContractorDispatchSearchResponse>;

    /**
     * Selects a dispatch.
     */
    select: (dispatchTicketId: string) => PayloadAction<string>;

    /**
     * Sets a discount on the currently-selected dispatch.
     */
    setDiscount: (discount: number) => PayloadAction<number>;

    /**
     * Sets one or more values on the currently-selected dispatch.
     */
    setValues: (values: any) => PayloadAction<any>;

    /**
     * Sets the selected view in the dispatch area.
     */
    setView: (view: string) => PayloadAction<string>;

    /**
     * Sets the collected service fee
     *
     * @memberof DispatchActionCreators
     */
    setServiceFeeCollected: (amountCollected: number) => PayloadAction<number>;
}
