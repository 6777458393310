import { combineEpics } from "redux-observable";

import contractsEpic from "./contracts.epic";
import noticesEpic from "./notices.epic";
import ordersEpic from "./orders.epic";
import passwordEpic from "./password.epic";
import paymentEpic from "./payment.epic";
import serviceRequestHistoryEpic from "./serviceRequestHistory.epic";
import invoices from "./invoices.epic";
import userEpic from "./users.epic";

const epic = combineEpics(
    contractsEpic,
    noticesEpic,
    ordersEpic,
    passwordEpic,
    serviceRequestHistoryEpic,
    invoices,
    userEpic,
    paymentEpic
);
export default epic;
