import * as React from "react";
import { toastr } from "react-redux-toastr";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { createWaitActions } from "../actions/wait.actions";
import { createDialogActions } from "../actions/dialog.actions";
import { BillingInfoActionTypes } from "../actions/account/billingInfo.actions";
import { createAccountOrdersActions } from "../actions/account/orders.actions";
import { CallUs } from "../components/CallUs";
import { Areas } from "../constants/Areas";
import { LandmarkApiService } from "../services/landmarkApi.service";

const ordersActions = createAccountOrdersActions();
const paymentDialogActions = createDialogActions(Areas.Agent.Orders.Payment);
const waitActions = createWaitActions();

function* handleProcessPayment(action) {
    let waitBegin = waitActions.begin();
    yield put(waitBegin);

    try {
        const { billingInfo: { contractId } } = action.payload;

        const response = yield call(() => LandmarkApiService
            .post(`/order/processPayment`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
        );

        // Notify of success
        yield (put as any).resolve({
            type: BillingInfoActionTypes.ProcessPayment.SUCCESS,
            payload: {
                contractId,
                response,
            }
        });
    }
    catch (error) {
        const err = error;

        // Notify of failure
        yield put({
            type: BillingInfoActionTypes.ProcessPayment.FAILURE,
            error: err
        });
    }
    finally {
        // Stop waiting
        yield put(waitActions.end(waitBegin.payload.id));
    }
}

function* handleProcessPaymentSuccess(action) {
    const { contractId, response } = action.payload;

    if (response && response.json.policyPaymentId > 0) {
        toastr.success("Payment Successful", `Successfully processed payment for contract: ${contractId}`, { timeOut: 10000 });

        // Close the dialog, if open
        yield put(paymentDialogActions.close());

        // Reload the details about orders
        yield put(ordersActions.load.begin());
    }
}

function* handleProcessPaymentFailure(action) {
    toastr.error(
        "Error Processing Payment",
        "",
        {
            component: (
                <p>There was an error while processing your payment. Please ensure that billing information is correct and if you continue to experience issues, please call us right away at <CallUs />, option 2.}</p>
            ),
            transitionIn: "fadeIn",
            transitionOut: "fadeOut"
        });
}

function* watchProcessPayment() {
    yield takeEvery(BillingInfoActionTypes.ProcessPayment.BEGIN, handleProcessPayment);
}

function* watchProcessPaymentSuccess() {
    yield takeEvery(BillingInfoActionTypes.ProcessPayment.SUCCESS, handleProcessPaymentSuccess);
}

function* watchProcessPaymentFailure() {
    yield takeEvery(BillingInfoActionTypes.ProcessPayment.FAILURE, handleProcessPaymentFailure);
}

export default function* root() {
    yield all([
        fork(watchProcessPayment),
        fork(watchProcessPaymentSuccess),
        fork(watchProcessPaymentFailure),
    ]);
}
