import * as Landmark from "models/landmark-api";

import {
    AsyncAction,
    AsyncActionCreators,
    createAsyncActions
} from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

/**
 * Actions available for interacting with closing items.
 */
export const ClosingItemActionTypes = {
    GetCategories: AsyncAction(`contractor/closingItems/categories`),
    GetItems: AsyncAction(`contractor/closingItems`)
};

/**
 * Creates actions available for closing items.
 */
export function createClosingItemActions(): ClosingItemActionCreators {
    return {
        getCategories: createAsyncActions(ClosingItemActionTypes.GetCategories),
        getItems: createAsyncActions(ClosingItemActionTypes.GetItems)
    };
}

/**
 * Interface defining the dispatchable actions available for interacting with closing items.
 *
 * @export
 * @interface ClosingItemActionCreators
 * @extends {ActionCreatorMap}
 */
export interface ClosingItemActionCreators extends ActionCreatorMap {
    /**
     * Gets the categories of closing items. If a dispatch GUID is provided, it will get associated closing item categories.
     *
     * @type {(AsyncActionCreators<string | undefined, Landmark.ClosingItemCategoryResponse>)}
     * @memberof ClosingItemActionCreators
     */
    getCategories: AsyncActionCreators<string | undefined, Landmark.ClosingItemCategoryResponse> ;

    /**
     * Gets the closing items. If a category ID is provided, it will get items associated with that category.
     *
     * @type {(AsyncActionCreators<string | undefined, Landmark.ClosingItemResponse>)}
     * @memberof ClosingItemActionCreators
     */
    getItems: AsyncActionCreators<string | undefined, Landmark.ClosingItemResponse>;
}
