import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { createUsersActions, UsersActionTypes } from "../../actions/account/users.actions";
import { PayloadAction } from "../../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const usersActions = createUsersActions();

const handleLoadUsers: Epic<
    PayloadAction<string | Error | Landmark.User[]>
> = action$ => action$.pipe(
    ofType(UsersActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/account/user/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        usersActions.load.success,
        usersActions.load.failure,
    ),
);

const waitDuringLoadUsers = createWaitEpic(UsersActionTypes.Load);

const epic = combineEpics(
    handleLoadUsers,
    waitDuringLoadUsers,
);
export default epic;
