import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { defaultCategoryState, CategoryState, createCategoryReducer } from "./category.reducer";
import { PayloadAction } from "../actions/defs";
import { DialogActionTypes, DialogAction } from "../actions/dialog.actions";
import { ServiceRequestActionTypes } from "../actions/serviceRequest.actions";
import { BillingInfoActionTypes } from "../actions/account/billingInfo.actions";
import { Areas } from "../constants/Areas";

export interface ServiceRequestState {
    categories: CategoryState;
    contactPreferences: Landmark.ContactPreferences;
    contractId: string;
    isContractAuthorized: boolean;
    isFlowComplete: boolean;
    paymentInfo: Landmark.PaymentInfo;
    questionFlowchart: Landmark.Flowchart;
    questionsAndAnswers: Landmark.ClaimQuestionAndAnswer[];
    warrantyItemId: string;
    warrantyItems: Landmark.WarrantyItem[];
}

const defaultState: ServiceRequestState = {
    categories: update(defaultCategoryState, {
        selected: { $set: "default" },
    }),
    contactPreferences: null,
    contractId: null,
    isContractAuthorized: false,
    isFlowComplete: false,
    paymentInfo: null,
    questionFlowchart: null,
    questionsAndAnswers: [],
    warrantyItemId: null,
    warrantyItems: null,
};

const categoryReducer = createCategoryReducer(Areas.Account.ServiceRequest.Create);

export function ServiceRequestReducer(
    state = defaultState,
    action:
        Action |
        DialogAction |
        PayloadAction<
            string |
            Landmark.ApiResponse<Landmark.WarrantyItem[]> |
            Landmark.ApiResponse<Landmark.Flowchart> |
            Landmark.AuthorizationResponse |
            Landmark.CreatePendingServiceRequestPaymentResponse |
            Landmark.ContractResponse
        >
) {
    // Update the categories state
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action) }
    });

    switch (action.type) {
        case DialogActionTypes.OPEN: {
            const dialogAction = action as DialogAction;
            if (dialogAction.payload === Areas.Account.ServiceRequest.Create) {
                // When the dialog is opened, reset the state
                return defaultState;
            }
            break;
        }

        case ServiceRequestActionTypes.ANSWER_QUESTION: {
            const answerAction = action as PayloadAction<Landmark.ClaimQuestionAndAnswer>;
            const q_and_a = state.questionsAndAnswers || [];
            const index = q_and_a.findIndex(item => item.questionId === answerAction.payload.questionId);
            if (index >= 0) {
                // Replace the existing item in the list
                return update(state, {
                    questionsAndAnswers: {
                        [index]: { $set: answerAction.payload }
                    }
                });
            }
            else {
                // Push the item onto the end of the list
                return update(state, {
                    questionsAndAnswers: { $push: [answerAction.payload] }
                });
            }
        }

        case ServiceRequestActionTypes.Authorize.SUCCESS: {
            const authAction = action as PayloadAction<Landmark.AuthorizationResponse>;
            return update(state, {
                isContractAuthorized: { $set: authAction.payload.isAuthorized }
            });
        }

        case ServiceRequestActionTypes.BEGIN: {
            const contractAction = action as PayloadAction<string>;

            // Start over
            return update(defaultState, {
                contractId: { $set: contractAction.payload },
                isContractAuthorized: { $set: false },
            });
        }

        case ServiceRequestActionTypes.COMPLETE_FLOW: {
            const completeAction = action as PayloadAction<boolean>;

            return update(state, {
                isFlowComplete: { $set: completeAction.payload },
            });
        }

        case ServiceRequestActionTypes.LoadWarrantyItemQuestionFlow.BEGIN: {
            // Clear the flowchart
            return update(state, {
                questionFlowchart: { $set: null },
                questionsAndAnswers: { $set: [] }
            });
        }

        case ServiceRequestActionTypes.LoadWarrantyItemQuestionFlow.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.ApiResponse<Landmark.Flowchart>>;
            return update(state, {
                isFlowComplete: { $set: false },
                questionFlowchart: { $set: loadAction.payload.result },
            });
        }

        case ServiceRequestActionTypes.LoadWarrantyItems.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.ApiResponse<Landmark.WarrantyItem[]>>;
            return update(state, {
                warrantyItems: { $set: loadAction.payload.result }
            });
        }

        case BillingInfoActionTypes.MakePendingPayment.SUCCESS: {
            const response = (action as PayloadAction<Landmark.CreatePendingServiceRequestPaymentResponse>).payload;
            return update(state, {
                pendingPaymentId: { $set: response.pendingPaymentId }
            });
        }

        case ServiceRequestActionTypes.REMOVE_ANSWERS: {
            const questionIds = (action as PayloadAction<string[]>).payload;
            const indexes = questionIds
                .map(id => state.questionsAndAnswers.findIndex(answer => answer.questionId === id))
                .filter(index => index !== -1);
            // Sort indexes in descending order so indexes aren't altered
            // as they are removed
            indexes.sort((a, b) => b - a);

            // Remove all matching answers
            return update(state, {
                questionsAndAnswers: { $splice: indexes.map(index => [index, 1]) }
            });
        }

        case ServiceRequestActionTypes.SELECT_WARRANTY_ITEM: {
            const warrantyItemId = (action as PayloadAction<string>).payload;
            return update(state, {
                warrantyItemId: { $set: warrantyItemId },
            });
        }

        case ServiceRequestActionTypes.SET_CONTACT_PREFERENCES: {
            const contactPreferences = (action as PayloadAction<string>).payload;
            return update(state, {
                contactPreferences: { $set: contactPreferences },
            });
        }

        case ServiceRequestActionTypes.SET_PAYMENT_INFO: {
            const paymentInfo = (action as PayloadAction<string>).payload;
            return update(state, {
                paymentInfo: { $set: paymentInfo },
            });
        }

        case ServiceRequestActionTypes.Send.SUCCESS: {
            // Return to the original state, with the selected contract id.
            return update(defaultState, {
                contractId: { $set: state.contractId }
            });
        }

        default: break;
    }
    return state;
}
