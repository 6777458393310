import * as React from "react";

interface Props { }

export class WhyChooseLandmark extends React.Component<Props, any> {
    render() {
        return (
            <section id="whylandmark" className="section-features">
                <div className="container">
                    <h1 className="homeowner">Why Choose Landmark Home Warranty?</h1>
                    <p>From our comprehensive home warranty coverage to our remarkable customer service, you know when you choose Landmark as your home warranty company you're getting the best. Take a look at how we rank as one of the top rated home warranty providers.</p>
                    <div className="row">
                        <div className="column third item">
                            <h5 data-icon=">">Best Coverage</h5>
                            <p>A Landmark Home Warranty provides some of the best coverage on over 9,000 mechanical parts of your home's systems and appliances. Last year we fully replaced over
                                <a href="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" target="_blank"> 5,000 appliances and system components.</a></p>
                        </div>
                        <div className="column third item">
                            <h5 data-icon="E">Homeowner Dashboard</h5>
                            <p>We make it easy to help protect your home. Check your coverage, open a claim, or renew your home warranty, all in one place: your homeowner dashboard.</p>
                        </div>
                        <div className="column third item">
                            <h5 data-icon="F">Highly Rated Service</h5>

                            <p>Landmark has been ranked the <a href="/resources/landmark-home-warranty-news/landmark-wins-best-home-warranty-in-region-for-3rd-year/11/208" target="_blank">Best Home Warranty in the Region</a> in 2014, 2015, 2016, 2019, and 2020
                                and aims to assign homeowners a contractor within 24 hours of opening a claim. Landmark Home Warranty provides you with award-winning service.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column third item">
                            <h5 data-icon="=">Customer Satisfaction</h5>
                            <p>With accreditation from the Better Business Bureau and Consumer Affairs along with hundreds of positive reviews, you know other homeowners trust Landmark for home warranty protection.</p>
                        </div>
                        <div className="column third item">
                            <h5 data-icon="H">Budget Protection</h5>
                            <p>Help protect your home and your budget.A Landmark home warranty can help cover the cost of unexpected and expensive repairs.We save our customers
                                <a href="/resources/home-warranty-education/is-a-home-warranty-worth-it/10/178" target="_blank"> on average $200</a> per approved repair or replacement.</p>
                        </div>
                        <div className="column third item">
                            <h5 data-icon="I">Real Support</h5>
                            <p>From opening a claim to coordinating the repair or replacement of your failed system components and appliances, your customer service account manager is available to you every step of the way.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
