import * as Landmark from "models/landmark-api";
import { actions as toastrActions } from "react-redux-toastr";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { race } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { createForumActions, ForumActionTypes } from "../actions/forum.actions";
import { Areas } from "../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const forumActions = createForumActions();

/**
 * Loads a forum entry.
 */
const handleLoadForum: Epic<
    PayloadAction<number | Error | Landmark.ForumResponse>
> = action$ => action$.pipe(
    ofType(ForumActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<number>) => LandmarkApiService
            .get(`forum/${action.payload}`)
            .fetch()
            .then(response => response.json),
        forumActions.load.success,
        forumActions.load.failure,
    ),
);

/**
 * Loads forum summaries.
 */
const handleLoadForumSummary: Epic<
    PayloadAction<Landmark.ForumRequest | Error | Landmark.ForumResponse>
> = action$ => action$.pipe(
    ofType(ForumActionTypes.LoadSummaries.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.ForumRequest>) => {
            const { count = 3, forumId = 0} = action.payload || {};

            return LandmarkApiService
                .get(`forum/summaries/${count}/${forumId}`)
                .fetch()
                .then(response => response.json);
        },
        forumActions.loadSummaries.success,
        forumActions.loadSummaries.failure,
    ),
);

/**
 * Handle subscribing to forum changes.
 */
const handleSubscribe: Epic<
    PayloadAction<string | Error | void>
> = action$ => action$.pipe(
    ofType(ForumActionTypes.Subscribe.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .post(`/forum/subscribe/${action.payload}`)
            .fetch()
            .then(response => response.json),
        forumActions.subscribe.success,
        forumActions.subscribe.failure,
    ),
);

/**
 * Notify the user on subscription success/failure
 */
const notifyOnSubscriptionSuccess: Epic<
    Action | PayloadAction<string | Landmark.ErrorResponse>
> = action$ => action$.pipe(
    ofType(ForumActionTypes.Subscribe.BEGIN),
    switchMap((action: PayloadAction<string | Landmark.ErrorResponse>) => {
        const email = action.payload;
        return race(
            action$.pipe(
                ofType(ForumActionTypes.Subscribe.SUCCESS),
                take(1),
                map(() => toastrActions.add({
                    type: "success",
                    title: "Subscribed!",
                    message: `${email} is now signed up for Landmark's newsletter!`,
                })),
            ),
            action$.pipe(
                ofType(ForumActionTypes.Subscribe.FAILURE),
                take(1),
                map(({payload}: any) => toastrActions.add({
                    type: "error",
                    title: "Subscription Failed!",
                    message: `An error occurred while subscribing to the newsletter, please try again.`
                })),
            )
        );
    }),
);

const waitForLoad = createWaitEpic(ForumActionTypes.Load, Areas.Forum);
const waitForLoadSummaries = createWaitEpic(ForumActionTypes.LoadSummaries, Areas.Forum);
const waitForSubscribe = createWaitEpic(ForumActionTypes.Subscribe, Areas.Forum);

const epic = combineEpics(
    handleLoadForum,
    handleLoadForumSummary,
    handleSubscribe,
    notifyOnSubscriptionSuccess,
    waitForLoad,
    waitForLoadSummaries,
    waitForSubscribe,
);
export default epic;
