import * as Landmark from "models/landmark-api";
import * as React from "react";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { AccountOrdersActionTypes, createAccountOrdersActions } from "../../actions/account/orders.actions";
import { PayloadAction } from "../../actions/defs";
import { CallUs } from "../../components/CallUs";
import { Areas } from "../../constants/Areas";
import { createToastrEpic } from "../../epics/toastr.epic";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const orderActions = createAccountOrdersActions();

const handleCancelOrder: Epic<
    PayloadAction<string | Error | Landmark.OrderResult>
> = action$ => action$.pipe(
    ofType(AccountOrdersActionTypes.Cancel.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .delete(`account/contract/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        orderActions.cancel.success,
        orderActions.cancel.failure,
    ),
);

const handleCompleteOrder: Epic<
    PayloadAction<Landmark.CompleteOrderRequest | Landmark.CompleteOrderResponse | Error>
> = action$ => action$.pipe(
    ofType(AccountOrdersActionTypes.Complete.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.CompleteOrderRequest>) => LandmarkApiService
            .post("/order/complete")
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.complete.success,
        orderActions.complete.failure,
    ),
);

const handleLoadOrders: Epic<
    PayloadAction<void | Error | Landmark.ContractResponse>
> = action$ => action$.pipe(
    ofType(AccountOrdersActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<void>) => LandmarkApiService
            .get(`/account/contract/agent`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        orderActions.load.success,
        orderActions.load.failure,
    ),
);

const notifyUserOfCompleteOrderFailure = createToastrEpic(
        [AccountOrdersActionTypes.Complete.FAILURE],
        (action: Action) => ({
            type: "error",
            title: "Error",
            message: "",
            options: {
                showCloseButton: true,
                timeOut: 10000,
                component:
                    React.createElement(
                        "p",
                        null,
                        "An error occurred while attempting to complete your order, please call us at ",
                        React.createElement(CallUs)
                    )
            }
        })
    );

const notifyUserOfCompleteOrderSuccess = createToastrEpic(
        [AccountOrdersActionTypes.Complete.SUCCESS],
        (action: Action) => ({
            type: "success",
            title: "Order Completed",
            message: "Your order has been successfully completed.",
            options: { showCloseButton: true, timeOut: 10000 }
        })
    );

const waitDuringCancel = createWaitEpic(AccountOrdersActionTypes.Cancel, Areas.Agent.Orders.Page);
const waitDuringLoadOrders = createWaitEpic(AccountOrdersActionTypes.Load, Areas.Agent.Orders.Page);

const epic = combineEpics(
    handleCancelOrder,
    handleCompleteOrder,
    handleLoadOrders,
    notifyUserOfCompleteOrderFailure,
    notifyUserOfCompleteOrderSuccess,
    waitDuringCancel,
    waitDuringLoadOrders,
);
export default epic;
