import { routerMiddleware } from 'connected-react-router';
import { History } from "history";
import * as Landmark from "models/landmark-api";
import { Action, applyMiddleware, compose, createStore, Reducer, Store as ReduxStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import createSagaMiddleware from "redux-saga";
import { AuthActionTypes, createAuthActions } from "../actions/auth.actions";
import { PayloadAction } from "../actions/defs";
import { createStoreActions } from "../actions/store.actions";
import rootEpic from "../epics";
import rootSaga from "../sagas";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { getFingerprintAsync } from "../utils/fingerprint";
import { actionPromise, promiseWrapperMiddleware } from "../utils/redux-promise";
import { ApplicationState, observe, createRootReducer } from "./app";

// Declare stuff that should be available with HMR (but I can"t find Typescript typings for)
declare let module: any;

export interface Store<T> extends ReduxStore<T> {
    injectAsyncReducer<U>(name: string, asyncReducer: Reducer<U>): void;
}

const authActions = createAuthActions();
const storeActions = createStoreActions();

let cachedStore: Store<ApplicationState>;
export function configureStore(history: History, initialState?: ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === "undefined" ? null : window as Window & { devToolsExtension: Function };

    const devToolsExtension = windowIfDefined && windowIfDefined.devToolsExtension; // If devTools is installed, connect to it
    let devToolsMiddleware = f => f;
    if (devToolsExtension) {
        devToolsMiddleware = devToolsExtension();
    }

    // Create middlewares that we care about
    const epicMiddleware = createEpicMiddleware();
    const sagaMiddleware = createSagaMiddleware();

    const createStoreWithMiddleware = compose(
        applyMiddleware(
            epicMiddleware,
            sagaMiddleware,
            routerMiddleware(history),
            promiseWrapperMiddleware,
        ),
        devToolsMiddleware
    )(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const store = createStoreWithMiddleware<ApplicationState, Action>(
        createRootReducer(history),
        initialState
    ) as Store<ApplicationState>;

        // Allow injecting async reducers at runtime
    let asyncReducers: {
        [key: string]: Reducer<any>
    } = {};
    store.injectAsyncReducer = <U>(name: string, reducer: Reducer<U>) => {
        asyncReducers[name] = reducer;
        store.replaceReducer(
            createRootReducer(history, asyncReducers)
        );
    };

    // NOTE: Use of setTimeout here is intentional and necessary - otherwise epics
    // and other areas won't see this initialization action
    setTimeout(() => store.dispatch(storeActions.init.begin()), 0);

    windowIfDefined && windowIfDefined.addEventListener("error", function (e: any) {
        // Dispatch the unexpected error to the store
        store.dispatch(storeActions.unexpectedError(e));
    });

    // Configure authentication within the Landmark API
    // FIXME: this logic doesn't belong here.
    let refreshPromise;
    LandmarkApiService.configure({
        clear: () => {
            return Promise.resolve();
        },
        get: () => {
            const state = store.getState();
            return state.auth.response;
        },
        isExpired: () => {
            const state = store.getState();
            const response = state.auth.response;

            if (response &&
                response.isAuthenticated &&
                !!response.tokenExpires &&
                (Date.parse(response.tokenExpires) < Date.now())) {
                return true;
            }
            return false;
        },
        refresh: async () => {
            if (!refreshPromise) {
                // Ensure only one refresh can happen at a time
                refreshPromise = new Promise(async (resolve, reject) => {
                    try {
                        const state = store.getState();
                        const username = state.auth.response.user.email;
                        const refreshToken = state.auth.response.refresh_token;
                        const fingerprint = await getFingerprintAsync();

                        // Refresh and wrap it in a promise to be awaited
                        const result = await actionPromise<PayloadAction<Landmark.AuthenticationResponse>>(
                            () => store.dispatch(authActions.refresh.begin({ username, refreshToken, fingerprint })),
                            AuthActionTypes.Refresh.SUCCESS,
                            AuthActionTypes.Refresh.FAILURE
                        );

                        // We've refreshed, no need to hold on to this anymore
                        refreshPromise = null;
                        resolve(result.payload);
                    }
                    catch (err) { reject(err); }
                });
            }
            try {
                return await refreshPromise;
            }
            catch (e) {
                window.location.assign("/login");
                return null;
            }
        }
    });

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept("./app", () => {
            const nextRootReducer: any = require("./app");
            store.replaceReducer(nextRootReducer.reducers);
        });
    }

    // Run the epics
    epicMiddleware.run(rootEpic);

    // Run the sagas
    sagaMiddleware.run(rootSaga);

    // Setup observation on the store
    observe(store);

    return cachedStore = store;
}


/**
 * Get the store if it is created; Create the store if it has yet to be created.
 *
 * @export
 * @param {History} [history]
 * @param {ApplicationState} [initialState]
 * @returns
 */
export function getStore() {
    if (!cachedStore) {
        throw new Error("Store has not yet been configured.");
    }

    return cachedStore;
}
