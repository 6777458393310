import * as Landmark from "models/landmark-api";
import { toastr } from "react-redux-toastr";
import { all, call, fork, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import { createConfirmationActions, ConfirmationActionTypes } from "../../actions/confirmation.actions";
import { createWaitActions } from "../../actions/wait.actions";
import { createDispatchActions, DispatchActionTypes } from "../../actions/account/dispatch.actions";
import { InvoiceActionTypes } from "../../actions/account/invoice.actions";
import { ContractorPreAuthActionTypes } from "../../actions/contractor/preAuth.actions";
import { getSelectedDispatch, getStatusFilter, getTotalBillable } from "../../selectors/dispatch.selectors";
import { LandmarkApiService } from "../../services/landmarkApi.service";


const waitActions = createWaitActions();
const confirmationActions = createConfirmationActions();
const dispatchActions = createDispatchActions();

// For full API see https://github.com/redux-saga/redux-saga/tree/master/docs/api#putresolveaction

function* handleSetView(action) {
    if (action.payload === null) {
        // Deselect the dispatch if we go back to a top-level view
        yield put(dispatchActions.select(null));
    }

    // select: gets state from redux, using an optional selector
    // Get the selected dispatch so we can remove it from the list, if necessary
    const dispatchItem: Landmark.ContractorDispatchSearchResponseItem = yield select(getSelectedDispatch);

    yield* removeDispatchFromList(dispatchItem);
}

function* handleDispatchSearch(action) {
    // Show a wait spinner
    let waitBegin = null;
    if (action && action.payload && action.payload.page === 0) {
        waitBegin = waitActions.begin();
        yield put(waitBegin);
    }

    try {
        let request = Object.assign({}, action.payload);

        // Call the search API
        const response = yield call(() => LandmarkApiService
            .post(`/contractor/dispatch/search`)
            .payload(request)
            .withAuthentication()
            .fetch()
        );

        // Handle success
        yield put({
            type: DispatchActionTypes.Search.SUCCESS,
            payload: response.json
        });
    }
    catch (error) {
        // Notify of any errors
        yield put({
            type: DispatchActionTypes.Search.FAILURE,
            error
        });
    }
    finally {
        if (waitBegin) {
            // Stop waiting
            yield put(waitActions.end(waitBegin.payload.id));
        }
    }
}

function* handleDispatchUpdate(action) {
    let waitBegin;
    try {
        const currentDT: Landmark.ContractorDispatchSearchResponseItem = yield select(getSelectedDispatch);
        if (currentDT &&
            currentDT.dispatchParts &&
            currentDT.dispatchParts.some(({ partDetails }) =>
                (
                    (
                        (partDetails.pricePerUnit > 0 || partDetails.taxPerUnit > 0) &&
                        partDetails.units > 0
                    ) ||
                    (
                        partDetails.costPerHour > 0 &&
                        partDetails.hoursWorked > 0
                    )
                )
            )
        ) {
            const total = yield select(getTotalBillable);

            const preAuthorizeAmount = Math.max(currentDT.preAuthorizeAmount, currentDT.preAuthorizeAmountOverride);
            if (total > preAuthorizeAmount) {
                const discount = Math.max(total - preAuthorizeAmount + (currentDT.discountAmount || 0), 0);
                yield put(confirmationActions.show(
                    `The cost of this dispatch ticket exceeds your pre-authorized amount of $${preAuthorizeAmount}. You must be under your pre-authorized amount in order to add parts.

Would you like to change the discount to $${discount.toFixed(2)} and continue?`,
                    "Change Discount"
                ));

                const { cancel } = yield race({
                    confirm: take(ConfirmationActionTypes.CONFIRM),
                    cancel: take(ConfirmationActionTypes.CANCEL),
                });


                if (cancel) {
                    throw "Submission cancelled";
                } else {
                    yield put(dispatchActions.setDiscount(discount));
                    action.payload.updateValue.discountAmount = discount;
                }
            }
        }

        waitBegin = waitActions.begin();
        yield put(waitBegin);

        const response = yield call(() => LandmarkApiService
            .post(`/contractor/dispatch/${action.payload.dispatchId}`)
            .payload(action.payload.updateValue)
            .withAuthentication()
            .fetch()
        );

        // Use the dispatch from the response
        const dispatchItem: Landmark.ContractorDispatchSearchResponseItem = response.json;

        if (response) {
            // Publish the UPDATE.SUCCESS action that tells we were successful
            yield put({
                type: DispatchActionTypes.Save.SUCCESS,
                payload: dispatchItem
            });
            yield call(() => toastr.success("Success", "Successfully submitted your changes."));
        }
    }
    catch (error) {
        // Notify of failure
        yield put({
            type: DispatchActionTypes.Save.FAILURE,
            error
        });
        if (typeof error === "string") {
            yield call(() => toastr.error("Error", error));
        }
        else if (error.message) {
            yield call(() => toastr.error("Error", error.message));
        }
        else {
            yield call(() => toastr.error("Error", "Error submitting changes"));
        }
    }
    finally {
        if (waitBegin) {
            yield put(waitActions.end(waitBegin.payload.id));
        }
    }
}

function* handleInvoiceSearch(action) {
    // Show a wait spinner
    let waitBegin = null;
    if (action && action.payload && action.payload.page === 0) {
        waitBegin = waitActions.begin();
        yield put(waitBegin);
    }

    try {
        let request = Object.assign({}, action.payload);

        // Call the search API
        const response = yield call(() => LandmarkApiService
            .post(`/contractor/dispatch/search?isInvoice=true`)
            .payload(request)
            .withAuthentication()
            .fetch()
        );

        // Handle success
        yield put({
            type: InvoiceActionTypes.Search.SUCCESS,
            payload: response.json
        });
    }
    catch (error) {
        // Notify of any errors
        yield put({
            type: InvoiceActionTypes.Search.FAILURE,
            error
        });
    }
    finally {
        if (waitBegin) {
            // Stop waiting
            yield put(waitActions.end(waitBegin.payload.id));
        }
    }
}

function* handlePreAuthLoad(action) {
    const waitBegin = waitActions.begin();
    yield put(waitBegin);

    yield race({
        success: take(ContractorPreAuthActionTypes.Load.SUCCESS),
        failure: take(ContractorPreAuthActionTypes.Load.FAILURE)
    });

    yield put(waitActions.end(waitBegin.payload.id));
}

function* handleLoadDetailsBegin(action) {
    const waitBegin = waitActions.begin();
    yield put(waitBegin);

    yield race({
        success: take(DispatchActionTypes.LoadDetails.SUCCESS),
        failure: take(DispatchActionTypes.LoadDetails.FAILURE)
    });

    yield put(waitActions.end(waitBegin.payload.id));
}

function* removeDispatchFromList(dispatchItem: Landmark.ContractorDispatchSearchResponseItem) {
    const statusFilter = yield select(getStatusFilter);
    if (// If it was found in the store
        !!dispatchItem &&
        // If we are not looking at all of the DTs
        statusFilter &&
        // The status isn't what that filter is set for
        statusFilter !== dispatchItem.contractorStatus
    ) {
        // Clear the DT from the current list.
        yield put(dispatchActions.remove(dispatchItem.dispatchId));
    }

    // Clear the selectedDispatchId
    yield put(dispatchActions.select(null));
}

function* watchSetView() {
    yield takeEvery(DispatchActionTypes.SET_VIEW, handleSetView);
}

function* watchDispatchSearch() {
    // Only take the latest search
    yield takeLatest(DispatchActionTypes.Search.BEGIN, handleDispatchSearch);
}

function* watchDispatchUpdate() {
    yield takeEvery(DispatchActionTypes.Save.BEGIN, handleDispatchUpdate);
}

function* watchDispatchDetailsLoad() {
    yield takeEvery(DispatchActionTypes.LoadDetails.BEGIN, handleLoadDetailsBegin);
}

function* watchInvoiceSearch() {
    // Only take the latest search
    yield takeLatest(InvoiceActionTypes.Search.BEGIN, handleInvoiceSearch);
}

function* watchPreAuthLoad() {
    yield takeEvery(ContractorPreAuthActionTypes.Load.BEGIN, handlePreAuthLoad);
}

export default function* root() {
    yield all([
        fork(watchSetView),
        fork(watchDispatchSearch),
        fork(watchDispatchUpdate),
        fork(watchDispatchDetailsLoad),
        fork(watchInvoiceSearch),
        fork(watchPreAuthLoad),
    ]);
}
