import { actions as toastrActions, AddToastPayload } from "react-redux-toastr";
import update from "immutability-helper";

// Create a sample action so we know we get the type right
const addAction = toastrActions.add({
    type: "error",
    title: "title",
    message: "message"
});

export interface ToastrHistoryState {
    toastrs: AddToastPayload[];
}

const defaultState: ToastrHistoryState = {
    toastrs: [],
};

export const ToastrHistoryReducer = (state = defaultState, action) => {
    switch (action.type) {
        case addAction.type: {
            return update(state, {
                toastrs: {
                    $apply: toastrs => {
                        toastrs.push(action.payload);
                        return toastrs.slice(-100);
                    }
                }
            });
        }
    }

    // If nothing changed, return the original state
    return state;
};
