import { combineEpics, Epic, ofType } from "redux-observable";
import { empty } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";

const LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

const hideChatBoxOnOrderPageEpic: Epic<PayloadAction<{ location: Location }>> = action$ => action$.pipe(
    ofType(LOCATION_CHANGE),
    switchMap(action => {
        const element = document.getElementById("chatBox");
        if (element) {
            if (/\/order\//.test(action.payload.location.pathname)) {
                // If the route changed, and we moved to a route that contains "/order/" in it,
                // wait again for another location change.
                element.style.display = "none";
            } else {
                element.style.display = null;
            }
        }

        // Prevent dispatching any actions
        return empty();
    }),
);

const epic = combineEpics(
    hideChatBoxOnOrderPageEpic,
);
export default epic;
