import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { DispatchActionTypes } from "../../actions/account/dispatch.actions";

export interface DispatchState {
    response: Landmark.ContractorDispatchSearchResponse;
    selectedDispatchId: string;
    selectedView: string;
}

export interface LoadDispatchDetailsPayload {
    workOrderId: string;
}

const defaultState: DispatchState = {
    response: null,
    selectedDispatchId: null,
    selectedView: null,
};

export function DispatchReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            string |
            number |
            Landmark.ContractorDispatchSearchResponseItem |
            Landmark.AddDispatchNoteResponse
        >,
) {
    switch (action.type) {
        case DispatchActionTypes.CreateNote.SUCCESS: {
            // Create a new server-compatible note with the note that we created
            const noteResponse = (action as PayloadAction<Landmark.AddDispatchNoteResponse>).payload;

            // Lookup the correct dispatchItem by dispatchId
            let index = state.response.items.findIndex(item => item.dispatchId === noteResponse.dispatchId);

            if (index >= 0) {
                return update(state, {
                    // Add the note to the beginning of the list of notes
                    response: {
                        items: {
                            [index]: {
                                notes: state.response.items[index].notes ?
                                    { $unshift: [noteResponse.note] } :
                                    { $set: [noteResponse.note] }
                            }
                        }
                    }
                });
            }
            break;
        }
        case DispatchActionTypes.Search.SUCCESS: {
            const dispatchResults = (action as PayloadAction<Landmark.ContractorDispatchSearchResponse>).payload;

            if (dispatchResults.page > 0) {
                dispatchResults.items = [...state.response.items, ...dispatchResults.items];
            }

            return update(state, {
                response: { $set: dispatchResults }
            });
        }

        case DispatchActionTypes.LoadDetails.SUCCESS: {
            const dispatch = (action as PayloadAction<Landmark.ContractorDispatchSearchResponseItem>).payload;

            if (state.selectedDispatchId) {
                let index = state.response.items.findIndex(item => item.dispatchId === state.selectedDispatchId);
                if (index >= 0) {
                    return update(state, {
                        response: {
                            items: {
                                [index]: { $set: dispatch }
                            }
                        }
                    });
                }
            }
            break;
        }

        case DispatchActionTypes.Save.SUCCESS: {
            const saveResponse = (action as PayloadAction<Landmark.ContractorDispatchSearchResponseItem>).payload;
            // Lookup the correct dispatchItem by dispatchId
            let index = state.response.items.findIndex(item => item.dispatchId === saveResponse.dispatchId);

            if (index >= 0) {
                return update(state, {
                    // Add the note to the beginning of the list of notes
                    response: {
                        items: {
                            [index]: {
                                $set: saveResponse
                            }
                        }
                    }
                });
            }
            break;
        }

        case DispatchActionTypes.SELECT: {
            return update(state, { selectedDispatchId: { $set: (action as PayloadAction<string>).payload } });
        }

        case DispatchActionTypes.SET_DISCOUNT: {
            const discountAmount = (action as PayloadAction<number>).payload;

            if (state.selectedDispatchId) {
                let index = state.response.items.findIndex(item => item.dispatchId === state.selectedDispatchId);
                if (index >= 0) {
                    return update(state, {
                        response: {
                            items: {
                                [index]: {
                                    discountAmount: { $set: discountAmount }
                                }
                            }
                        }
                    });
                }
            }
            break;
        }

        case DispatchActionTypes.SET_SERVICE_FEE_COLLECTED: {
            const serviceFeeAmount = (action as PayloadAction<number>).payload;

            if (state.selectedDispatchId) {
                let index = state.response.items.findIndex(item => item.dispatchId === state.selectedDispatchId);
                if (index >= 0) {
                    return update(state, {
                        response: {
                            items: {
                                [index]: {
                                    wasServiceCallFeeCollected: { $set: serviceFeeAmount > 0 },
                                    serviceCallFeeCollectedAmount: { $set: serviceFeeAmount }
                                }
                            }
                        }
                    });
                }
            }
        }

        case DispatchActionTypes.ADD_PART: {
            const part = (action as PayloadAction<Landmark.DispatchPartDetails>).payload;

            if (state.selectedDispatchId) {
                let index = state.response.items.findIndex(item => item.dispatchId === state.selectedDispatchId);
                if (index >= 0) {
                    const partToAdd = { partDetails: part } as Landmark.DispatchPart;

                    return update(state, {
                        response: {
                            items: {
                                [index]: {
                                    dispatchParts: state.response.items[index].dispatchParts ?
                                        { $push: [partToAdd] } :
                                        { $set: [partToAdd] }
                                }
                            }
                        }
                    });
                }
            }
        }

        case DispatchActionTypes.REMOVE_PART: {
            const part = (action as PayloadAction<Landmark.DispatchPartDetails>).payload;
            if (state.selectedDispatchId) {
                let index = state.response.items.findIndex(item => item.dispatchId === state.selectedDispatchId);

                if (index >= 0) {
                    const indexToRemove = (state.response.items[index].dispatchParts || []).findIndex(value => value.partDetails === part);

                    if (indexToRemove >= 0) {
                        return update(state, {
                            response: {
                                items: {
                                    [index]: {
                                        dispatchParts: { $splice: [[indexToRemove, 1]]}
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }

        case DispatchActionTypes.SET_VIEW: {
            return update(state, {
                selectedView: { $set: (action as PayloadAction<string>).payload }
            });
        }
    }

    return state;
}
