import { LocationResponse } from "../actions/location.actions";
import { LandmarkApiService } from "./landmarkApi.service";

/**
 * A location service, used to interaction with HTML5 navigator and Google Geocoder API.
 */
class LocationServiceClass {
    public getLocation(): Promise<LocationResponse> {
        return new Promise((resolve, reject) => {
            // Timeout for handling the browser's rouge handling of geolocation.
            const locationTimeout = setTimeout(() => reject("Timed Out!"), 10000);
            let result = {} as LocationResponse;

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    loc => {
                        clearTimeout(locationTimeout);

                        result.position = {
                            coords: {
                                accuracy: loc.coords.accuracy,
                                altitude: loc.coords.altitude,
                                altitudeAccuracy: loc.coords.altitudeAccuracy,
                                heading: loc.coords.heading,
                                latitude: loc.coords.latitude,
                                longitude: loc.coords.longitude,
                                speed: loc.coords.speed,
                            },
                            timestamp: loc.timestamp,
                        } as Position;
                        const params = `lat=${loc.coords.latitude}&lng=${loc.coords.longitude}`;
                        LandmarkApiService.get(`/location?${params}`)
                            .fetch()
                            .then(r =>
                                {
                                    result.google = r.json.results;

                                    // Find the state code
                                    const stateArea = result.google.find(r => r.types[0] === "administrative_area_level_1");
                                    if (stateArea) {
                                        result.stateCode = stateArea.address_components[0].short_name;
                                    }

                                    resolve(result);
                                }
                            )
                            .catch(reject);
                    },
                    (reason) => {
                        clearTimeout(locationTimeout);
                        reject(reason);
                    }
                );
            }
            else {
                reject("This browser does not support geolocation.");
            }
        });

    }
}

export const LocationService = new LocationServiceClass();
