import { combineReducers } from "redux";

import { ContractsReducer, ContractsState } from "./contracts.reducer";
import { DispatchReducer, DispatchState } from "./dispatch.reducer";
import { InvoiceReducer } from "./invoice.reducer";
import { InvoiceState } from "./invoice.reducer";
import { NoticesReducer, NoticesState } from "./notices.reducer";
import { OrdersReducer, OrdersState } from "./orders.reducer";
import { InvoicesReducer, InvoicesState } from "./invoices.reducer";
import { PaymentReducer, PaymentState } from "./payment.reducer";
import { ServiceRequestHistoryReducer, ServiceRequestHistoryState } from "./serviceRequestHistory.reducer";
import { UsersReducer, UsersState } from "./users.reducer";
import { AuthActionTypes } from "../../actions/auth.actions";
import { CategoryActionCreators, createCategoryActions } from "../../actions/category.actions";
import { ContractsActionCreators, createContractsActions } from "../../actions/account/contracts.actions";
import { createNoticesActions, NoticesActionCreators } from "../../actions/account/notices.actions";
import { AccountOrdersActionCreators, createAccountOrdersActions } from "../../actions/account/orders.actions";
import { AccountInvoicesActionCreators, createAccountInvoicesActions } from "../../actions/account/invoices.actions";
import { createPaymentActions, PaymentActionCreators } from "../../actions/account/payment.actions";
import {
    createServiceRequestHistoryActions,
    ServiceRequestHistoryActionCreators,
} from "../../actions/account/serviceRequestHistory.actions";
import { CategoryState, createCategoryReducer } from "../category.reducer";
import { Areas } from "../../constants/Areas";
import { ActionCreatorMap } from "../../store/componentBindings";

export interface AccountState {
    categories: CategoryState;
    contracts: ContractsState;
    dispatch: DispatchState;
    invoice: InvoiceState;
    notices: NoticesState;
    orders: OrdersState;
    invoices: InvoicesState;
    serviceRequestHistory: ServiceRequestHistoryState;
    users: UsersState;
    paymentDetails: PaymentState;
}

export interface AccountActions extends ActionCreatorMap {
    categories: CategoryActionCreators;
    contracts: ContractsActionCreators;
    notices: NoticesActionCreators;
    orders: AccountOrdersActionCreators;
    invoices: AccountInvoicesActionCreators;
    paymentDetails: PaymentActionCreators;
    serviceRequestHistory: ServiceRequestHistoryActionCreators;
}

export function createAccountActions(): AccountActions {
    return {
        categories: createCategoryActions(Areas.Account.Page),
        contracts: createContractsActions(),
        notices: createNoticesActions(),
        orders: createAccountOrdersActions(),
        invoices: createAccountInvoicesActions(),
        paymentDetails: createPaymentActions(),
        serviceRequestHistory: createServiceRequestHistoryActions(),
    };
}

// Create a combined reducer to handle children of this area of state
const reducer = combineReducers({
    categories: createCategoryReducer(Areas.Account.Page),
    contracts: ContractsReducer,
    dispatch: DispatchReducer,
    invoice: InvoiceReducer,
    notices: NoticesReducer,
    orders: OrdersReducer,
    invoices: InvoicesReducer,
    paymentDetails: PaymentReducer,
    serviceRequestHistory: ServiceRequestHistoryReducer,
    users: UsersReducer,
});

export function AccountReducer(state: AccountState, action) {
    // Force back to the default state
    switch (action.type)
    {
        case AuthActionTypes.LOGOUT:
        case AuthActionTypes.Impersonate.Start.SUCCESS:
        case AuthActionTypes.Impersonate.End.SUCCESS:
            return reducer(undefined, action);
    }

    return reducer(state, action);
}

