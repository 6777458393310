import fetch from "cross-fetch";
import cache from "services/cache.service";
import { ApiResponse, Error } from "models/landmark-api";
import environment from "environment";

// Only enable JavaScript caching when we're not in the browser.
// Otherwise, we're relying on the browser itself to do client-side caching.
const shouldCache = !(window && window.fetch);
const cacheControlNoCacheRegex = /no-((cache)|(store))/i;
const cacheControlMaxAgeRegex = /max-age=(\d+)/i;
const cacheControlMustRevalidateRegex = /must-revalidate/i;
const cacheControlPrivateRegex = /private/i;

function getResponseFromCache(url: string) {
    if (!shouldCache) {
        return null;
    }
    const response = cache.get(url);
    if (response) {
        console.log(`${url} cached response retrieved.`);
        return response;
    }
    return null;
}

/**
 * Caches a fetch response, if possible.
 */
function cacheJsonResponse<T>(jsonResponse: JsonResponse<T>, url: string) {
    try
    {
        if (shouldCache && jsonResponse && jsonResponse.response && jsonResponse.response.status === 200) {
            const cacheControl = jsonResponse.response.headers.get("Cache-Control");
            console.log(`Response: ${url} - ${jsonResponse.response.status}, Cache-Control=${cacheControl}`);
            // Make sure this resource is cacheable
            if (cacheControl &&
                !cacheControlNoCacheRegex.test(cacheControl) &&
                !cacheControlMustRevalidateRegex.test(cacheControl) &&
                !cacheControlPrivateRegex.test(cacheControl)) {

                const match = cacheControlMaxAgeRegex.exec(cacheControl);
                if (match !== null) {
                    const seconds = parseFloat(match[1]);
                    console.log(`${url} response received, caching for ${seconds} seconds...`);
                    cache.set(url, jsonResponse, seconds * 1000);
                }
                else {
                    console.log(`${url} response received, caching...`);
                    cache.set(url, jsonResponse);
                }
            }
        }
        else {
            console.log(`Response: ${url} - ${jsonResponse.response && jsonResponse.response.status || "unknown"}`);
        }
    }
    catch (err) {
        console.error(err.stack);
    }

    return jsonResponse;
}

export interface JsonResponse<T extends ApiResponse = any> {
    response: Response;
    json?: T;
}

export interface ArrayBufferResponse {
    response: Response;
    buffer?: ArrayBuffer;
}

function jsonResponse<T extends ApiResponse = any>(response: Response) {
    return new Promise<JsonResponse<T>>((resolve, reject) => response
        .json()
        .then((json: ApiResponse) => {
            if (json.hasErrors === true) {
                reject({ json, response } as JsonResponse<T>);
            }
            else if (response.status >= 400) {
                json = Object.assign({}, json || {}, {
                    hasErrors: true,
                    errors: [
                        { message: `Server Error. Response Code ${response.status}` },
                    ] as Error[]
                });
                reject({ json, response } as JsonResponse<T>);
            }
            else {
                resolve({ json, response } as JsonResponse<T>);
            }
        })
        .catch(error => {
            // Ensure empty responses are still resolved properly
            if (response.ok && error && /unexpected end/i.test(error.message)) {
                resolve({ response } as JsonResponse<T>);
            }
            reject({
                json: {
                    hasErrors: true,
                    errors: [ { message: error } ]
                },
                response
            } as JsonResponse<T>);
        })
    );
}

function arrayBufferResponse(response: Response) {
    return new Promise<ArrayBufferResponse>((resolve, reject) => response
        .arrayBuffer()
        .then(buffer => {
            resolve({ buffer, response } as ArrayBufferResponse);
        })
        .catch(error => {
            reject({ response } as ArrayBufferResponse);
        })
    );
}

function fetchJson<T extends ApiResponse = any>(url: string, request: RequestInit): Promise<JsonResponse<T>> {
    // Try to get the response from cache
    const response = getResponseFromCache(url);
    if (response) {
        // If a response was available in cache, use it.
        return Promise.resolve(response);
    }
    else {
        if (environment.isDebugEnabled) {
            console.log(`Fetch '${url}': ${JSON.stringify(request)}`);
        }
        // Otherwise, fetch the result from the server.
        return fetch(url, request)
            .catch(error => Promise.reject({
                json: {
                    hasErrors: true,
                    errors: [
                        { message: error }
                    ]
                }
            } as JsonResponse<T>))
            .then((response: Response) => response.status !== 204 ? jsonResponse<T>(response) : Promise.resolve<JsonResponse<T>>(null))
            .then((jsonResponse: JsonResponse<T>) => cacheJsonResponse(jsonResponse, url));
    }
}

function fetchArrayBuffer(url: string, request: RequestInit): Promise<ArrayBufferResponse> {
    return fetch(url, request)
        .catch(error => Promise.reject(error))
        .then((response: Response) => arrayBufferResponse(response));
}

/**
 * A context object, used to collect API call parameters and
 * execute the api via fetch().
 */
export class ApiContext {
    public body: <T>(body: T) => ApiContext;
    public request: RequestInit;
    public _url: string;

    constructor(init: (ApiContext) => void) {
        this.request = {};
        this.request.headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };

        // Alias body() to payload()
        this.body = this.payload;

        init(this);
    }

    url(url) {
        this._url = url;
        return this;
    }

    method(method) {
        if (method) {
            this.request.method = method.toUpperCase();
        }
        else {
            delete this.request.method;
        }
        return this;
    }

    headers(headers) {
        if (headers) {
            this.request.headers = Object.assign({}, this.request.headers, headers);
        }
        return this;
    }

    deleteHeader(name: string) {
        if (this.request.headers.hasOwnProperty(name)) {
            delete this.request.headers[name];
        }
        return this;
    }

    payload(payload) {
        if (this.request.method !== "GET") {
            if (payload) {
                if (Object.prototype.toString.call(payload) ===  "[object FormData]") {
                    this.request.body = payload;
                }
                else {
                    this.request.body = JSON.stringify(payload);
                }
            }
            else {
                delete this.request.body;
            }
        }
        else {
            // Map to an array of key-value strings
            const params = Object.keys(payload)
                .filter(k => payload[k] !== null && payload[k] !== undefined)
                .map(k => `${k}=${payload[k]}`);
            const hasParams = this._url.indexOf("?") >= 0;
            this._url = this._url + (hasParams ? "&" : "?") + params.join("&");
        }
        return this;
    }

    fetchArrayBuffer() {
        return fetchArrayBuffer(this._url, this.request);
    }

    fetch<T extends ApiResponse = any>() {
        return fetchJson<T>(this._url, this.request);
    }
}

const trimSlashes = /^\/+|\/+$/g;

// See http://stackoverflow.com/a/2676231/162907
export function joinUrl(url, concat) {
    var url1 = url ? url.replace(trimSlashes, "").split("/") : [];
    var url2 = concat ? concat.replace(trimSlashes, "").split("/") : [];
    var url3 = [];
    for (var i = 0, l = url1.length; i < l; i++) {
        if (url1[i] === "..") {
            url3.pop();
        } else if (url1[i] === ".") {
            continue;
        } else {
            url3.push(url1[i]);
        }
    }
    for (var i = 0, l = url2.length; i < l; i++) {
        if (url2[i] === "..") {
            url3.pop();
        } else if (url2[i] === ".") {
            continue;
        } else {
            url3.push(url2[i]);
        }
    }
    return url3.join("/");
}

/**
 * A simple REST API class, used to prepare and execute API calls.
 */
export class Api<TContext extends ApiContext> {
    constructor() {
        this.get = this.get.bind(this);
        this.patch = this.patch.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    buildContext(url, method): TContext {
        return new ApiContext((context: ApiContext) => {
            context
                .url(url)
                .method(method);
            return Promise.resolve();
        }) as TContext;
    }

    get(url) {
        return this.buildContext(url, "GET");
    }

    patch(url) {
        return this.buildContext(url, "PATCH");
    }

    post(url) {
        return this.buildContext(url, "POST");
    }

    put(url) {
        return this.buildContext(url, "PUT");
    }

    delete(url) {
        return this.buildContext(url, "DELETE");
    }
}

// Create a singleton instance of our service
export const ApiService = new Api();
