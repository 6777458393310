import * as Landmark from "models/landmark-api";

import { appId, AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const ServiceRequestActionTypes = {
    ANSWER_QUESTION: `${appId}/serviceRequest/question/ANSWER`,
	Authorize: AsyncAction(`serviceRequest/authorize`),
    BEGIN: `${appId}/serviceRequest/BEGIN`,
    Cancel: AsyncAction(`order/cancelServiceRequest`),
    COMPLETE_FLOW: `${appId}/serviceRequest/warrantyItems/flow/COMPLETE`,
    LoadWarrantyItems: AsyncAction(`serviceRequest/warrantyItems/load`),
    LoadWarrantyItemQuestionFlow: AsyncAction(`serviceRequest/warrantyItems/flow/load`),
    REMOVE_ANSWERS: `${appId}/serviceRequest/question/REMOVE`,
    SELECT_WARRANTY_ITEM: `${appId}/serviceRequest/warrantyItem/SELECT`,
    Send: AsyncAction(`serviceRequest/send`),
    StatusUpdate: AsyncAction(`order/statusUpdate`),
    SendMessage: AsyncAction(`serviceRequest/message/send`),
    SET_CONTACT_PREFERENCES: `${appId}/serviceRequest/contactPreferences/SET`,
    SET_PAYMENT_INFO: `${appId}/serviceRequest/paymentInfo/SET`,
};

export function createServiceRequestActions(): ServiceRequestActionCreators {
	return {
        answerQuestion: (answer: Landmark.ClaimQuestionAndAnswer) => {
            return {
                type: ServiceRequestActionTypes.ANSWER_QUESTION,
                payload: answer,
            };
        },
		authorize: createAsyncActions(ServiceRequestActionTypes.Authorize),
        begin: (contractId: string) => {
            return {
                type: ServiceRequestActionTypes.BEGIN,
                payload: contractId,
            };
        },

        cancel: createAsyncActions(ServiceRequestActionTypes.Cancel),

        statusUpdate: createAsyncActions(ServiceRequestActionTypes.StatusUpdate),

        completeFlow: (isComplete: boolean) => {
            return {
                type: ServiceRequestActionTypes.COMPLETE_FLOW,
                payload: isComplete,
            };
        },
        loadWarrantyItems: createAsyncActions(ServiceRequestActionTypes.LoadWarrantyItems),
        loadWarrantyItemQuestionFlow: createAsyncActions(ServiceRequestActionTypes.LoadWarrantyItemQuestionFlow),
        removeAnswers: (questionIds: string[]) => {
            return {
                type: ServiceRequestActionTypes.REMOVE_ANSWERS,
                payload: questionIds,
            };
        },
        selectWarrantyItem: (warrantyItemId: string) => ({
            type: ServiceRequestActionTypes.SELECT_WARRANTY_ITEM,
            payload: warrantyItemId
        }),
        send: createAsyncActions(ServiceRequestActionTypes.Send),
        sendMessage: createAsyncActions(ServiceRequestActionTypes.SendMessage),
        // Sets the contract preferences in the SR sr state.
        setContactPreferences: (contactPreferences: Landmark.ContactPreferences) => {
            return {
                type: ServiceRequestActionTypes.SET_CONTACT_PREFERENCES,
                payload: contactPreferences,
            };
        },
        setPaymentInfo: (paymentInfo: Landmark.PaymentInfo) => {
            return {
                type: ServiceRequestActionTypes.SET_PAYMENT_INFO,
                payload: paymentInfo,
            };
        }
    };
}

export interface ServiceRequestActionCreators extends ActionCreatorMap {
    /**
     * Answer a question on the question flow.
     */
    answerQuestion: (answer: Landmark.ClaimQuestionAndAnswer) => PayloadAction<Landmark.ClaimQuestionAndAnswer>;

    /**
     * Authorizing an SR, given a contract id.
     */
    authorize: AsyncActionCreators<string, void>;

    /**
     * Begin creating the service request.
     */
    begin: (contractId: string) => PayloadAction<string>;

    /**
     * Submits work order cancellation.
     */
    cancel: AsyncActionCreators<Landmark.WorkOrderCancelModel, Landmark.ApiResponse<boolean>>;

    /**
     * Submits scheduled status update.
     */
    statusUpdate: AsyncActionCreators<Landmark.WorkOrderStatusSummary, Landmark.ApiResponse<boolean>>;

    /**
     * Marks the question flow complete/incomplete.
     */
    completeFlow: (isComplete: boolean) => PayloadAction<boolean>;

    /**
     * Loads warranty items from the server for the contract.
     */
    loadWarrantyItems: AsyncActionCreators<string, Landmark.ApiResponse<Landmark.WarrantyItem[]>>;

    /**
     * Loads the question flow for a warranty item.
     */
    loadWarrantyItemQuestionFlow: AsyncActionCreators<string, Landmark.ApiResponse<Landmark.FlowchartNode>>;

    /**
     * Remove an answer from the question flow.
     */
    removeAnswers: (questionIds: string[]) => PayloadAction<string[]>;

    /**
     * Selects a warranty item for SR creation.
     */
    selectWarrantyItem: (warrantyItemId: string) => PayloadAction<string>;

    /**
     * Creates a new service request.
     */
    send: AsyncActionCreators<Landmark.ServiceRequestRequest, Landmark.ApiResponse<Landmark.ServiceRequest>>;

    /**
     * Sends a message related to the service request.
     */
    sendMessage: AsyncActionCreators<Landmark.ServiceRequestNoteRequest, Landmark.ApiResponse<Landmark.Note>>;

    /**
     * Sets the contact preferences info for the service request.
     */
    setContactPreferences: (contactPreferences: Landmark.ContactPreferences) => PayloadAction<Landmark.ContactPreferences>;

    /**
     * Sets the payment info for the service request.
     */
    setPaymentInfo: (paymentInfo: Landmark.PaymentInfo) => PayloadAction<Landmark.PaymentInfo>;
}
