import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "../../actions/defs";
import { BrochureActionTypes, createBrochureActions } from "../../actions/lead/brochure.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const brochureActions = createBrochureActions();

const handleRequestBrochure: Epic<
    PayloadAction<Landmark.BrochureRequest | Landmark.BrochureResponse | Error>
> = action$ => action$.pipe(
    ofType(BrochureActionTypes.Request.BEGIN),
    switchMapWithPromiseToActions(
        async (action: PayloadAction<Landmark.BrochureRequest>) => LandmarkApiService
            .post(`/lead/brochure?culture=${action.payload.language}`)
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        brochureActions.request.success,
        brochureActions.request.failure,
    ),
);

const waitForRequestBrochure = createWaitEpic(BrochureActionTypes.Request);

const epic = combineEpics(
    handleRequestBrochure,
    waitForRequestBrochure,
);
export default epic;
