import * as Landmark from "models/landmark-api";

import {
    appId,
    AsyncAction,
    AsyncActionCreators,
    createAsyncActions,
    PayloadAction
} from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

/**
 * Collection of defined actions used to interact with the Payment API, as well as updating the store.
 */
export const PaymentActionTypes = {
    GetPaymentInfos: AsyncAction("account/payment/getInfos"),
    UpdatePaymentInfo: AsyncAction("account/payment/updateInfo"),
    DeleteCreditCard: AsyncAction("account/payment/deleteCreditCard"),
    SetPolicyDefaultPaymentMethod: AsyncAction("account/payment/setPolicyDefaultPaymentMethod"),
    ProcessPayment: AsyncAction("account/payment/processPayment"),
    GetFailedPayments: AsyncAction("account/payment/getFailedPayments"),
    SyncPaymentProfiles: AsyncAction("account/payment/syncPaymentProfiles"),
    SELECT_PAYMENT_INFO_ID: `${appId}/account/payment/SELECT_PAYMENT_INFO_ID`,
    SELECT_POLICY_ID: `${appId}/account/warranty/SELECT_POLICY_ID`,
    SELECT_POLICY_NUMBER: `${appId}/account/warranty/SELECT_POLICY_NUMBER`
};

/**
 * Defines the actions available to use to interact with the Payment API, as well as updating the store.
 *
 * @export
 * @interface PaymentActionCreators
 * @extends {ActionCreatorMap}
 */
export interface PaymentActionCreators extends ActionCreatorMap {
    getPaymentInfos: AsyncActionCreators<void, Landmark.PaymentInfo[]>;
    updatePaymentInfo: AsyncActionCreators<Landmark.PaymentInfo, Landmark.CreditCard>;
    deleteCreditCard: AsyncActionCreators<string, boolean>;
    setPolicyDefaultPaymentMethod: AsyncActionCreators<Landmark.SetPolicyDefaultPaymentMethodRequest, any>;
    processPayment: AsyncActionCreators<Landmark.InvoiceItem, Landmark.PaymentResponse>;
    getFailedPayments: AsyncActionCreators<void, Landmark.FailedPaymentListResponse>;
    syncPaymentProfiles: AsyncActionCreators<void, any>;

    /**
     *  Sets the index of the selected payment info item.
     */
    setSelectedPaymentInfoId: (selectedPaymentInfoId: string) => PayloadAction<string>;
    /**
     *  Sets the policy id that will be assigned a default payment method
     */
    setPolicyId: (selectedPolicyId: string) => PayloadAction<string>;
    /**
     *  Sets the policy number that will be assigned a default payment method
     */
    setPolicyNumber: (selectedPolicyNumber: number) => PayloadAction<number>;
}

/**
 * Creates payment actions to be used by the application to interact with the Payment API, as well as updating the store.
 *
 * @export
 * @returns { PaymentActionCreators } - Actions that can be used to interact with the Payment API and the store.
 */
export function createPaymentActions() {
    return {
        getFailedPayments: createAsyncActions(PaymentActionTypes.GetFailedPayments),
        getPaymentInfos: createAsyncActions(PaymentActionTypes.GetPaymentInfos),
        processPayment: createAsyncActions(PaymentActionTypes.ProcessPayment),
        updatePaymentInfo: createAsyncActions(PaymentActionTypes.UpdatePaymentInfo),
        deleteCreditCard: createAsyncActions(PaymentActionTypes.DeleteCreditCard),
        setPolicyDefaultPaymentMethod: createAsyncActions(PaymentActionTypes.SetPolicyDefaultPaymentMethod),
        syncPaymentProfiles: createAsyncActions(PaymentActionTypes.SyncPaymentProfiles),

        setSelectedPaymentInfoId: (selectedPaymentInfoId: string) => ({
            type: PaymentActionTypes.SELECT_PAYMENT_INFO_ID,
            payload: selectedPaymentInfoId,
        }),
        setPolicyId: (selectedPolicyId: string) => ({
            type: PaymentActionTypes.SELECT_POLICY_ID,
            payload: selectedPolicyId,
        }),
        setPolicyNumber: (selectedPolicyNumber: number) => ({
            type: PaymentActionTypes.SELECT_POLICY_NUMBER,
            payload: selectedPolicyNumber,
        }),
    } as PaymentActionCreators;
}
