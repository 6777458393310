export const AccountCategory = {
    Admin: "admin",
    CRRDashboard: "crrDash",
    Dashboard: "dashboard",
    Default: "default",
    Dispatches: "dispatchTickets",
    Forum: "internalForum",
    Invoices: "invoices",
    AccountInvoices: "accountInvoices",
    Logout: "logout",
    NewOrder: "newOrder",
    NewServiceRequest: "submitServiceRequest",
    Notices: "notices",
    Orders: "orders",
    Profile: "profile",
    ServiceRequestHistory: "serviceRequestHistory",
    Warranty: "warranty",
};
Object.freeze(AccountCategory);
