import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { ContactUsActionTypes } from "../actions/contactUs.actions";
import { PayloadAction } from "../actions/defs";
import makeMergeable from "../utils/merge";

export interface ContactUsState {
    name: string;
    email: string;
    message: string;
    phone: string;
}

const defaultState: ContactUsState = {
    name: null,
    email: null,
    message: null,
    phone: null,
};

export function ContactUsReducer(
    state = defaultState,
    action: PayloadAction<Landmark.EmailSent | any> | Action
) {
    switch (action.type) {
        case ContactUsActionTypes.CLEAR: {
            return defaultState;
        }
        case ContactUsActionTypes.Send.SUCCESS: {
            const sendAction = action as PayloadAction<Landmark.EmailSent>;
            if (sendAction.payload && sendAction.payload.hasSent) {
                return defaultState;
            }
            return state;
        }
        case ContactUsActionTypes.SET_VALUES: {
            const sendAction = action as PayloadAction<any>;
            return update(state, { $merge: makeMergeable(state, sendAction.payload) });
        }
        default: break;
    }

    return state;
}
