import { Areas } from "constants/Areas";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { empty, of } from "rxjs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { delay, filter, map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { getFeatureItems } from "selectors/feature.selectors";
import { ApplicationState } from "store/app";
import { PayloadAction } from "../actions/defs";
import { createFeatureActions, FeatureActionTypes } from "../actions/feature.actions";
import { StoreActionTypes } from "../actions/store.actions";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createToastrEpic } from "./toastr.epic";
import { createWaitEpic } from "./wait.epic";

const featureActions = createFeatureActions();

const handleLoadFeatures: Epic<
    PayloadAction<Landmark.FeatureResponse | Error>
    > = action$ => action$.pipe(
        ofType(FeatureActionTypes.LoadFeatures.BEGIN),
        switchMapWithPromiseToActions(
            action => {
                return LandmarkApiService
                    .get("/feature")
                    .fetch()
                    .then(response => response.json);
            },
            featureActions.loadFeatures.success,
            featureActions.loadFeatures.failure,
        )
    );

const handleSetFeatureFlag: Epic<
    PayloadAction<Landmark.Feature | void | Error>
    > = action$ => action$.pipe(
        ofType(FeatureActionTypes.setFeature.BEGIN),
        switchMapWithPromiseToActions(
            (action: PayloadAction<Landmark.Feature>) => {
                return LandmarkApiService
                    .put(`/feature/flag/${action.payload.key}/${action.payload.isEnabled}`)
                    .withAuthentication()
                    .fetch()
                    .then(response => response.json);
            },
            featureActions.setFeature.success,
            featureActions.setFeature.failure,
        )
    );

// Getting the featured items on initiation
const loadFeaturesOnInit: Epic<
    PayloadAction<Landmark.AuthenticationResponse | void>
> = (action$, state$) => action$.pipe(
    ofType(StoreActionTypes.Init.BEGIN),
    withLatestFrom(state$),
    switchMap(([, state]: [any, ApplicationState]) => {
        // Determine if features have already been loaded
        const features = getFeatureItems(state);
        if (features.length === 0) {
            // Features haven't been loaded yet, load them!
            return of(featureActions.loadFeatures.begin());
        }
        return empty();
    }),
);

// If we have a failure loading the feature items
const notifyUserOfLoadFeatureFailure = createToastrEpic(
    [featureActions.loadFeatures.FAILURE],
    (action: Action) => ({
        type: "error",
        title: "Error",
        message: "Failed to load feature flagging items.",
    })
);

// Reload the feature items once one has been changed.
const reloadFeatures: Epic<Action> = action$ => action$.pipe(
    ofType(FeatureActionTypes.setFeature.SUCCESS),
    mapTo(featureActions.loadFeatures.begin())
);

// We have a race condition with entity framework. Making sure that the features have been updated
// in order to have them show correctly on the UI.
const reloadWhileFeaturesAreBeingUpdated: Epic<Action> = action$ => action$.pipe(
    ofType(FeatureActionTypes.LoadFeatures.SUCCESS),
    map((action: PayloadAction<Landmark.FeatureResponse>) => action.payload),
    filter(response => response.isUpdating),
    delay(1000),
    mapTo(featureActions.loadFeatures.begin())
);

const waitForLoad = createWaitEpic(FeatureActionTypes.LoadFeatures, Areas.Feature);

const epic = combineEpics(
    handleLoadFeatures,
    handleSetFeatureFlag,
    loadFeaturesOnInit,
    notifyUserOfLoadFeatureFailure,
    reloadFeatures,
    reloadWhileFeaturesAreBeingUpdated,
    waitForLoad,
);
export default epic;
