import * as React from "react";

import { Meta } from "../components/Meta";

/**
 * Collection of images that are associated with each category.
 */
const images = {
    buyingSellingHome: require("../content/images/importantResources/buying-or-selling-a-home-articles.jpg"),
    hvac: require("../content/images/importantResources/hvac-heating-cooling-systems-articles.jpg"),
    plumbing: require("../content/images/importantResources/plumbing-articles-blog.jpg"),
    appliances: require("../content/images/importantResources/appliance-warranty-resources.jpg"),
    homeRepairsMaintainance: require("../content/images/importantResources/home-maintanance-repairs.jpg"),
    electrical: require("../content/images/importantResources/electrical-system-articles.jpg"),
    realEstateTips: require("../content/images/importantResources/real-estate-tips.jpg"),
    pestControl: require("../content/images/importantResources/pest-control-articles-blog.jpg"),
    homeWarrantyEducation: require("../content/images/importantResources/home-warranty-education.jpg"),
    landmarkHomeWarrantyNews: require("../content/images/importantResources/landmark-home-warranty-news-blog.jpg"),
    propertyManagement: require("../content/images/importantResources/property-management-articles.jpg"),
    contractors: require("../content/images/importantResources/contractor-resources.jpg"),
    lifestyleDecor: require("../content/images/importantResources/lifestyle-decor.jpg")
};

export const forumCategoryNames = {
    buyingSellingHouse: "buying-selling-house",
    hvac: "hvac",
    plumbing: "plumbing",
    applianceWarranty: "appliance-warranty",
    homeRepairsMaintainance: "home-repairs-maintenance",
    electrical: "electrical",
    realEstateTips: "real-estate-tips",
    pestControl: "pest-control",
    homeWarrantyEducation: "home-warranty-education",
    landmarkHomeWarrantyNews: "landmark-home-warranty-news",
    propertyManagement: "property-management",
    contractors: "contractors",
    lifestyleDecor: "lifestyle-decor"
};

export interface ForumCategory {
    id: number;
    displayName: string;
    urlSafeName: string;
    categoryText: string;
    backgroundImg: string;
    meta: React.ReactElement<Meta>;
}

export const ForumCategories: ForumCategory[] = [
    {
        id: 7,
        displayName: "Buying or Selling a House",
        urlSafeName: forumCategoryNames.buyingSellingHouse,
        categoryText: "Looking to buy or sell a house? Are you a real estate professional seeking helpful resources to educate your clients? These articles will help walk you through the process of buying or selling a home.",
        backgroundImg: images.buyingSellingHome,
        meta: <Meta
            title="Buying or Selling a House | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.buyingSellingHouse}`}
            description="These articles and infographics will walk you through every step of buying or selling a home. Real estate professionals can use these to educate their clients."
            imageUrl={images.buyingSellingHome}
        />
    },
    {
        id: 4,
        displayName: "HVAC Articles",
        urlSafeName: forumCategoryNames.hvac,
        categoryText: "How does your air conditioner work? What can you do to make sure your HVAC system keeps running for its entire lifespan? These articles will help answer your questions about the heating and cooling systems in your home",
        backgroundImg: images.hvac,
        meta: <Meta
            title="HVAC Articles | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.hvac}`}
            description="Learn how your air conditioning, furnace, and HVAC system works, as well as maintenance tips to keep it running longer."
            imageUrl={images.hvac}
        />
    },
    {
        id: 2,
        displayName: "Plumbing Articles",
        urlSafeName: forumCategoryNames.plumbing,
        categoryText: "Have a clogged sink or water heater that isn’t working? Check out our plumbing maintenance articles and infographics.",
        backgroundImg: images.plumbing,
        meta: <Meta
            title="Plumbing Articles | Landmark Home Warranty "
            canonical={`/resources/${forumCategoryNames.plumbing}`}
            description="Need maintenance tips on plumbing or want to know how to do a quick DIY repair on your plugged kitchen sink? Find plumbing resources here."
            imageUrl={images.plumbing}
        />
    },
    {
        id: 5,
        displayName: "Appliance Warranty Resources",
        urlSafeName: forumCategoryNames.applianceWarranty,
        categoryText: "A Landmark Home Warranty offers warranties that cover your home’s appliances. Learn more about what we cover with our appliance warranty plans and how to maintain your appliances so they run longer.",
        backgroundImg: images.appliances,
        meta: <Meta
            title="Appliance Warranty | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.applianceWarranty}`}
            description="Landmark Home Warranty offers an appliance warranty. Learn more about what we cover on your appliances and how to maintain and repair them."
            imageUrl={images.appliances}
        />
    },
    {
        id: 6,
        displayName: "Home Repairs and Maintenance",
        urlSafeName: forumCategoryNames.homeRepairsMaintainance,
        categoryText: "Follow our step-by-step instructions to complete easy DIY repairs and maintenance on your home's systems and appliances.",
        backgroundImg: images.homeRepairsMaintainance,
        meta: <Meta
            title="Home Repairs, Maintenance | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.homeRepairsMaintainance}`}
            description="Use our step-by-step instructions to complete DIY home repairs and maintenance that will help your home's systems and appliances run longer."
            imageUrl={images.homeRepairsMaintainance}
        />
    },
    {
        id: 3,
        displayName: "Electrical System Articles",
        urlSafeName: forumCategoryNames.electrical,
        categoryText: "Learn more about how your electrical system works in your home, as well as how to do simple repairs and maintenance to keep it running longer.",
        backgroundImg: images.electrical,
        meta: <Meta
            title="Electrical System Articles | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.electrical}`}
            description="Learn how your electrical system works and how to maintain it with these helpful resources."
            imageUrl={images.electrical}
        />
    },
    {
        id: 8,
        displayName: "Real Estate Tips",
        urlSafeName: forumCategoryNames.realEstateTips,
        categoryText: "Get tips from real estate experts to help you sell more and better assist your clients.",
        backgroundImg: images.realEstateTips,
        meta: <Meta
            title="Real Estate Tips | Landmark Home Warranty "
            canonical={`/resources/${forumCategoryNames.realEstateTips}`}
            description="Real estate professionals, use these articles to get helpful hints for the industry, help sell more, and provide education to your clients. "
            imageUrl={images.realEstateTips}
        />
    },
    {
        id: 9,
        displayName: "Pest Control Articles",
        urlSafeName: forumCategoryNames.pestControl,
        categoryText: "Learn more about common household pests that could be living in your house! Then help control them with pest control services provided through Landmark Home Warranty.",
        backgroundImg: images.pestControl,
        meta: <Meta
            title="Pest Control Articles | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.pestControl}`}
            description="Learn more about common household pests and how a Landmark Home Warranty can help you get rid of them."
            imageUrl={images.pestControl}
        />
    },
    {
        id: 10,
        displayName: "Home Warranty Education",
        urlSafeName: forumCategoryNames.homeWarrantyEducation,
        categoryText: "Everything you need to know about what a home warranty is, how it works and what it covers. Real estate professionals: use these articles to help your clients!",
        backgroundImg: images.homeWarrantyEducation,
        meta: <Meta
            title="Home Warranty Education| Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.homeWarrantyEducation}`}
            description="Have questions about home warranties? Learn what a home warranty is, how to use it, what it covers and more with these articles."
            imageUrl={images.homeWarrantyEducation}
        />
    },
    {
        id: 11,
        displayName: "Landmark Home Warranty News",
        urlSafeName: forumCategoryNames.landmarkHomeWarrantyNews,
        categoryText: "From awards we've won to new services we're offering, these articles and press releases will help you learn more about what's going on in the world of Landmark Home Warranty.",
        backgroundImg: images.landmarkHomeWarrantyNews,
        meta: <Meta
            title="News | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.landmarkHomeWarrantyNews}`}
            description="From awards we've won to new services we're offering, these articles will keep you up to date in the world of Landmark Home Warranty."
            imageUrl={images.landmarkHomeWarrantyNews}
        />
    },
    {
        id: 13,
        displayName: "Property Management Articles",
        urlSafeName: forumCategoryNames.propertyManagement,
        categoryText: "Property managers have a lot to handle. A home warranty can help them balance repairs and maintenance of the systems and appliances of the homes they manage. These articles give property managers tips on how to best utilize home warranties to their advantage.",
        backgroundImg: images.propertyManagement,
        meta: <Meta
            title="Property Management | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.propertyManagement}`}
            description="Property managers can use home warranties to help them balance repairs and maintenance on the homes they manage. Learn property management tips here."
            imageUrl={images.propertyManagement}
        />
    },
    {
        id: 14,
        displayName: "Tips for Contractors",
        urlSafeName: forumCategoryNames.contractors,
        categoryText: "These articles are resources for Landmark Home Warranty contractors or those who want to apply to work with Landmark.",
        backgroundImg: images.contractors,
        meta: <Meta
            title="Tips for Contractors | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.contractors}`}
            description="Resources for Landmark Contractors. Contractors who want more profits for less advertising should consider working with a home warranty company."
            imageUrl={images.contractors}
        />
    },
    {
        id: 50,
        displayName: "Lifestyle and Décor Articles",
        urlSafeName: forumCategoryNames.lifestyleDecor,
        categoryText: "Whether you're interested in DIY decor or the latest home styles, make your home your own with our lifestyle and decor articles.",
        backgroundImg: images.lifestyleDecor,
        meta: <Meta
            title="Lifestyle and Décor | Landmark Home Warranty"
            canonical={`/resources/${forumCategoryNames.lifestyleDecor}`}
            description="Learn how to make your house your home with these DIY décor articles and helpful home living hints."
            imageUrl={images.lifestyleDecor}
        />
    },
];
