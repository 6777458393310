import * as React from "react";
import { createRequestQuoteFormik, RequestQuoteFormBase } from "../forms/request-quote-form";

interface Props {
    largeTitle?: any;
    subTitle?: any;
    planTypeId?: string;
}

/**
 * Request Quote form used by the Basic Registration section of the website.
 *
 * @class Form
 * @extends {RequestQuoteFormBase}
 */
class Form extends RequestQuoteFormBase {
    render() {
   /*     const { handleSubmit } = this.props;*/

        return (
            //<form onSubmit={handleSubmit}>
            //    <div className="container">
            //        <fieldset style={{ maxWidth: "1020px", marginLeft: "auto", marginRight: "auto" }}>
            //            <div className="row">
            //                <div className="column half">
            //                    <label className="field-label light">Name</label>
            //                    <div className="field-wrap">
            //                        {this.components.contactName}
            //                    </div>
            //                </div>
            //                <div className="column half">
            //                    <label className="field-label light">Email</label>
            //                    <div className="field-wrap">
            //                        {this.components.email}
            //                    </div>
            //                </div>
            //            </div>
            //            <div className="row">
            //                <div className="column half">
            //                    <label className="field-label light">Address</label>
            //                    <div className="field-wrap">
            //                        {this.components.address}
            //                    </div>
            //                </div>
            //                <div className="column half">
            //                    <label className="field-label light">Zip</label>
            //                    <div className="field-wrap">
            //                        {this.components.zip}
            //                    </div>
            //                </div>
            //            </div>
            //            <div className="row">

            //                <div className="column half">
            //                    <label className="field-label light">Phone</label>
            //                    <div className="field-wrap">
            //                        {this.components.phone}
            //                    </div>
            //                </div>
            //                <div className="column half">
            //                    <label className="field-label light">Is this a real estate transaction test?</label>
            //                    <div className="field-wrap">
            //                        {this.components.orderType}
            //                    </div>
            //                </div>
            //            </div>
            //        </fieldset>
            //        <div className="row center">
            //            {this.components.submitButton()}
            //        </div>
            //    </div>
            //    {this.components.validationModal}
            //</form>
            <div></div>
        );
    }
}

const RequestQuoteForm = createRequestQuoteFormik(Form);

export class BasicRegistration extends React.Component<Props, any> {
    renderTitle() {
        return <h1>{this.props.largeTitle}</h1>;
    }

    renderSubTitle() {
        return <p>
            {this.props.subTitle}
        </p>;
    }

    render() {
        return (
            <section className="section-cta" id="brochure">
                <div className="container">
                    <div className="row">
                        {this.renderTitle()}
                        {this.renderSubTitle()}
                    </div>
                    <section className="section-cta" id="quote">
                        <RequestQuoteForm />
                    </section>
                </div>
            </section>
        );
    }
}
