import * as Landmark from "models/landmark-api";
import * as React from "react";
import { toastr } from "react-redux-toastr";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import contractorSaga from "./contractor";
import { createAuthActions } from "../../actions/auth.actions";
import { SelectCategoryAction, buildCategoryActionTypes } from "../../actions/category.actions";
import { createOrderActions } from "../../actions/order.actions";
import { createServiceRequestActions } from "../../actions/serviceRequest.actions";
import { createWaitActions } from "../../actions/wait.actions";
import { UsersActionTypes } from "../../actions/account/users.actions";
import { CallUs } from "../../components/CallUs";
import { Areas } from "../../constants/Areas";
import { AccountCategory } from "../../constants/AccountCategory";
import { PlanTypeName } from "../../constants/TransactionType";
import { getContract } from "../../selectors/account/contracts.selectors";
import { getAuthenticatedUser } from "../../selectors/auth.selectors";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createRouterActions } from "../../actions/router.actions";

const authActions = createAuthActions();
const orderActions = createOrderActions();
const routerActions = createRouterActions();
const serviceRequestActions = createServiceRequestActions();
const waitActions = createWaitActions();

function* handleNewOrder() {
    // Default to a consumer order
    let planTypeName = PlanTypeName.Homeowner;

    const user: Landmark.User = yield select(getAuthenticatedUser);
    const userType = user.userType;

    // If the user is an RE agent
    if (userType === "realEstateAgent" || userType === "titleCompanyAgent" || userType === "agentAssistant") {
        // Set the type to an RE order
        planTypeName = PlanTypeName.RealEstate;
    }

    // Request the new order
    yield put(orderActions.newOrder(planTypeName));
    // Redirect to the correct order page based on the order type defined
    yield put(routerActions.push(`/order/${planTypeName}`));
}

function* handleAccountCategorySelectionChange(action: SelectCategoryAction) {
    switch (action.payload.category) {
        case AccountCategory.Logout:
            yield put(authActions.logout());
            break;

        case AccountCategory.NewOrder:
            yield* handleNewOrder();
            break;

        case AccountCategory.Forum:
            // Redirect to the forum site
            window.location.href = "https://forum.landmarkhw.com/dashboard.aspx";
            break;

        case AccountCategory.NewServiceRequest:
            // FIXME: is this still used?
            const contract = yield select(getContract);
            yield put(serviceRequestActions.begin(contract.contractId));
            break;

        default:
            if (action.payload.category) {
                // Save the selected tab as an anchor
                yield put(routerActions.push(`/account#${action.payload.category}`));
            }
            break;
    }
}

function* handleMergeAccountsBegin(action) {
    let waitBegin = waitActions.begin();
    yield put(waitBegin);

    try {
        // Call the API to merge accounts
        const result = yield call(() => LandmarkApiService
            .put(`/account/user/merge`)
            .payload(action.request)
            .withAuthentication()
            .fetch()
        );

        if (result.response.status === 200) {
            if (action.userInteractive) {
                toastr.success(
                    "Success",
                    "Success",
                    {
                        component: (<p>Your user accounts have been successfully consolidated.  Please login again.</p>),
                        transitionIn: "fadeIn",
                        transitionOut: "fadeOut"
                    }
                );
            }
            else {
                toastr.success(
                    "Problems Fixed",
                    "Problems Fixed",
                    {
                        component: (<p>We found multiple accounts associated with your email address and have consolidated them.  Please login again.</p>),
                        transitionIn: "fadeIn",
                        transitionOut: "fadeOut"
                    }
                );
            }

            yield put({ type: UsersActionTypes.MergeAccounts.SUCCESS });
            yield put(routerActions.push("/"));
            yield put(authActions.logout());
        }
        else {
            toastr.error(
                "Error",
                "",
                {
                    component: (<p>An error occurred while consolidating user accounts. Please call us at <CallUs />, option 2.</p>),
                    transitionIn: "fadeIn",
                    transitionOut: "fadeOut"
                }
            );

            yield put({ type: UsersActionTypes.MergeAccounts.FAILURE });
        }
    }
    finally {
        // Stop waiting
        yield put(waitActions.end(waitBegin.payload.id));
    }
}

const AccountCategoryTypes = buildCategoryActionTypes(Areas.Account.Page);

function* watchCategorySelectionChange() {
    yield takeEvery(AccountCategoryTypes.SELECT, handleAccountCategorySelectionChange);
}

function* watchMergeAccounts() {
    yield takeEvery(UsersActionTypes.MergeAccounts.BEGIN, handleMergeAccountsBegin);
}

function* root() {
    yield all([
        fork(contractorSaga),
        fork(watchCategorySelectionChange),
        fork(watchMergeAccounts),
    ]);
}

export default root;
