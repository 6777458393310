import { combineReducers } from "redux";

import { ContractorDashboardReducer, ContractorDashboardState } from "./dashboard.reducer";
import { ContractorDispatchStatisticsReducer, ContractorDispatchStatisticsState } from "./dispatchStatistics.reducer";
import { ContractorProfileReducer, ContractorProfileState } from "./profile.reducer";
import { ContractorSearchReducer, ContractorSearchState } from "./search.reducer";
import { ClosingItemState, ClosingItemReducer } from "./closingItem.reducer";

export interface ContractorState {
    closingItems: ClosingItemState;
    dispatchStatistics: ContractorDispatchStatisticsState;
    dashboard: ContractorDashboardState;
    profile: ContractorProfileState;
    search: ContractorSearchState;
}

export const ContractorReducer = combineReducers({
    closingItems: ClosingItemReducer,
    dispatchStatistics: ContractorDispatchStatisticsReducer,
    dashboard: ContractorDashboardReducer,
    profile: ContractorProfileReducer,
    search: ContractorSearchReducer,
});
