import { Action } from "redux";
import { Epic, ofType } from "redux-observable";
import { of, Subject } from "rxjs";
import { catchError, filter, map, mergeAll, startWith, switchMap, take, timeout } from "rxjs/operators";
import { AsyncActionType } from "../actions/defs";
import { createWaitActions, WaitAction, WaitActionTypes } from "../actions/wait.actions";

const waitActions = createWaitActions();

/**
 * Creates an epic that sets a wait flag in the store during the action.
 * @param asyncActionType The asynchronous action type to wait for.
 * @param id The id of the wait area (optional).  If specified, the wait is localized to the area.
 * @param timeout The number of milliseconds before the wait flag timeout triggers.
 */
export const createWaitEpic = (asyncActionType: AsyncActionType, id: string = undefined, timeout: number = undefined): Epic<any> => action$ => action$.pipe(
    ofType(asyncActionType.BEGIN),
    map((action: Action) => {
        const waitAction = waitActions.begin(id || timeout ? { id, isLocal: !!id, timeout } : undefined);
        return action$.pipe(
            ofType(asyncActionType.SUCCESS, asyncActionType.FAILURE),
            take(1),
            map(() => waitActions.end(waitAction.payload.id)),
            catchError(() => of(waitActions.end(waitAction.payload.id))),
            startWith(waitAction),
        );
    }),
    mergeAll(),
);

const waitWithTimeoutEpic: Epic<WaitAction> = action$ => action$.pipe(
    ofType(WaitActionTypes.BEGIN),
    switchMap(action => {
        const subject = new Subject<WaitAction>();
        action$
            .pipe(
                ofType(WaitActionTypes.END),
                // Ensure this is the 'end' action that corresponds to the 'begin' action
                filter((endAction: WaitAction) => endAction.payload.id === action.payload.id),
                // Guarantee we timeout the wait timer after the timeout
                timeout(action.payload.timeout || 30000),
                take(1),
            )
            .subscribe(
                () => {
                    subject.complete();
                },
                () => {
                    subject.next(waitActions.end(action.payload.id));
                    subject.complete();
                }
            );

        return subject.asObservable();
    }),
);

export default waitWithTimeoutEpic;
