import * as Landmark from "models/landmark-api";
import * as React from "react";
import { RouteComponentProps } from "react-router";

import { createForumActions, ForumActionCreators } from "../../actions/forum.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { Wait } from "../../components/Wait";
import { Areas } from "../../constants/Areas";
import { ForumCategories, ForumCategory } from "../../constants/ForumCategory";
import { getForumEntries, getForumEntry, getSelectedForumId } from "../../selectors/forum.selectors";
import { createGetIsWaitingForArea } from "../../selectors/wait.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { convertTextToUrlSafe } from "../../utils/text";
import { scrollTo } from "../../utils/scroll";

interface Props extends Partial<RouteComponentProps<any>> {
    actions?: {
        forum: ForumActionCreators;
        router: RouterActionCreators;
    };
    count: number;
    category?: string;
    store?: {
        entries: Landmark.ForumEntry[];
        entry: Landmark.ForumEntry;
        isWaiting: boolean;
        selectedForumId: number;
    };
}

@connect(
    state => ({
        store: {
            entries: getForumEntries(state),
            entry: getForumEntry(state),
            isWaiting: createGetIsWaitingForArea(Areas.Forum)(state),
            selectedForumId: getSelectedForumId(state),
        }
    }),
    bindActionCreatorsToProps({
        forum: createForumActions(),
        router: createRouterActions()
    }),
)
export class Resources extends React.Component<Props, any> {
    /**
     * Constructs our resources, including making the request to retrieve the data on this page.
     */
    constructor(props: Props) {
        super(props);

        if (props.store.entries.length === 0) {
            // Only load entries when mounting if none are currently loaded

            if (props.store.entry) {
                props.actions.forum.loadSummaries.begin({ forumId: props.store.entry.categoryForumId, count: 999999 });
            }
            else {
                if (props.category) {
                    // Auto-load the forum category based on the hash in the URL
                    let category = ForumCategories.find(c => c.urlSafeName === props.category);
                    if (category) {
                        props.actions.forum.loadSummaries.begin({ forumId: category.id, count: 999999 });
                    }
                }
                else {
                    // Load the default list of summaries
                    props.actions.forum.loadSummaries.begin();
                }
            }
        }
        else {
            if (props.category) {
                // Auto-load the forum category based on the hash in the URL
                let category = ForumCategories.find(c => c.urlSafeName === props.category);
                if (category) {
                    props.actions.forum.loadSummaries.begin({ forumId: category.id, count: 999999 });
                }
            }
            else {
                // Load the default list of summaries
                props.actions.forum.loadSummaries.begin();
            }
        }
    }

    componentDidMount() {
        if (this.props.category) {
            let category = ForumCategories.find(c => c.urlSafeName === this.props.category);
            if (category) {
                scrollTo("resources");
            }
        }
    }

    componentWillUnmount() {
        this.props.actions.forum.clear();
    }

    handleLoadMoreResources = () => {
        if (this.props.store.selectedForumId) {
            this.props.actions.forum.loadSummaries.begin({ forumId: this.props.store.selectedForumId, count: 999999 });
        }
        else {
            this.props.actions.forum.loadSummaries.begin({ count: (this.props.store.entries.length + 3) });
        }
    }

    renderHeader(forumCategory: ForumCategory) {
        return <h1>{forumCategory.displayName}</h1>;
    }

    renderForumCategoryHeader(forumCategory: ForumCategory) {
        return <p className="intro-text">{forumCategory.categoryText}</p>;
    }

    findCategory(forumEntry) {
        let forumCategory = ForumCategories.find(c => c.id === forumEntry.categoryForumId);

        if (!forumCategory) {
            return "/resources";
        }

        const url = `/resources/${forumCategory.urlSafeName}/${convertTextToUrlSafe(forumEntry.title)}/${forumCategory.id}/${forumEntry.id}`;
        return url;
    }

    handleBlogPostClick(e: any, url: string) {
        e.preventDefault();

        if (this.props.location && this.props.location.pathname === url)
        {
            window.scrollTo(0, 0);
            return;
        }

        this.props.actions.forum.clear();
        this.props.actions.router.push(url);
    }

    render() {
        if (!this.props.store) {
            return null;
        }

        let forumCategory = ForumCategories.find(c => c.id === this.props.store.selectedForumId);

        if (!forumCategory) {
            forumCategory = {
                id: 0,
                displayName: "Recent Home Warranty Resources",
                urlSafeName: "recent-home-warranty-resources",
                categoryText: "Check out our latest articles here and learn more about home warranties, home maintenance, and more.",
                backgroundImg: "../../images/bg-post.jpg",
            } as ForumCategory;
        }

        return (
            // Added the delay for bug 23211. The delay apears to fix the bug. If we run into the same issue, the wait.tsx
            // component is what is causing the issue. Best to remove the wait component. In testing, I didnt notice
            // the wait component in use since the data had been retrieved really fast.
            <Wait
                delay={2000}
                isWaiting={this.props.store.isWaiting}
                style={{ container: { borderRadius: 0, } }}
            >
                <section id="resources" title="Learn about home warranties with Landmark's home warranty resource section." className="section-resources-intro">
                    <div className="container">
                        <div className="row">
                            <i title="Icon for the home warranty resources section of the website." className="section-icon icon-resources"></i>
                            {this.renderHeader(forumCategory)}
                            {this.renderForumCategoryHeader(forumCategory)}
                        </div>
                        <div className="row wrapper" style={{ minHeight: "450px" }}>
                            {this.props.store.entries.map((entry, index) => {
                                const url = this.findCategory(entry);

                                return (
                                    <div key={index} className="column third">
                                        <div className="hiddenIfTablet">
                                            <div style={{ minHeight: "300px" }}>
                                                <a
                                                    href={url}
                                                    onClick={e => this.handleBlogPostClick(e, url)}>
                                                    <h3 className="ellipse">{entry.title}</h3></a>
                                                <p className="small ellipse" style={{ maxHeight: "unset" }}>{entry.content}</p>
                                            </div>
                                            <a className="read-more"
                                                href={url}
                                                onClick={e => this.handleBlogPostClick(e, url)}>Read More »</a>
                                            <hr />
                                        </div>
                                        <div className="visibleIfTablet">
                                            <div>
                                                <a
                                                    href={url}
                                                    onClick={e => this.handleBlogPostClick(e, url)}>
                                                    <h3 className="ellipse">{entry.title}</h3></a>
                                                <p className="small ellipse" style={{ maxHeight: "unset" }}>{entry.content}</p>
                                            </div>
                                            <a className="read-more"
                                                href={url}
                                                onClick={e => this.handleBlogPostClick(e, url)}>Read More »</a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {
                            !this.props.category &&
                            <div className="row">
                                <a className="btn-gray" onClick={this.handleLoadMoreResources}>Load More<i data-icon="h"></i></a>
                            </div>
                        }
                    </div>
                </section>
            </Wait>
        );
    }
}
