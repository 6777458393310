import * as Landmark from "models/landmark-api";
import { all, call, fork, put, race, select, take, takeEvery } from "redux-saga/effects";

import { OrderActionTypes } from "../actions/order.actions";
import { getOrderRequest } from "../selectors/order.selectors";
import { LandmarkApiService } from "../services/landmarkApi.service";


function* handleLeads(action) {
    while (true) {
        // Look for one of the following conditions to happen:
        // 1. The order is successfull
        // 2. The order fails
        // 3. The browser location changes (i.e. they navigate to another page)
        // 4. (not implemented) Periodically (maybe every 30 seconds) record what data we"ve collected so far
        const { order_success, location_changed } = yield race({
            order_success: take(OrderActionTypes.Submit.SUCCESS),
            order_failure: take(OrderActionTypes.Submit.FAILURE),
            location_changed: take("@@router/LOCATION_CHANGE"),
        });

        if (location_changed &&
            /\/order\//.test(location_changed.payload.location.pathname)) {
            // If the route changed, and we moved to a route that contains "/order/" in it,
            // wait again for another location change.
            continue;
        }

        // Only save the lead if the order wasn't successful
        if (!order_success) {
            // Let's save the lead
            const request: Landmark.OrderRequest = yield select(getOrderRequest);

            if (!request.owner) {
                continue;
            }

            const owner = request.owner.user;

            const email = owner.email;
            if (email != null) {
                // Build a request to register the lead
                const leadRegistrationRequest: Landmark.LeadRegistrationRequest = {
                    email: email,
                    firstName: owner.name.firstName,
                    lastName: owner.name.lastName,
                    address: request.property.address.fullStreet,
                    postalCode: request.property.address.postalCode,
                    homePhone: owner.phones && owner.phones.length > 0 ?
                        owner.phones[0].number :
                        null,
                    // FIXME: find brochure type id
                    //leadBrochureTypeId: request,
                    type: Landmark.LeadRegistrationTypes.IncompleteOrder,
                };

                // Clear the order if the user has routed away from the order.
                if (location_changed) {
                    yield put({ type: OrderActionTypes.CLEAR });
                }
                // Call the API to create/update the lead
                yield call(() => LandmarkApiService
                    .post("/lead")
                    .payload(leadRegistrationRequest)
                    .fetch()
                );
            }
        }

        break;
    }
}

function* watchLeads() {
    yield takeEvery(
        [
            OrderActionTypes.NEW_ORDER,
            OrderActionTypes.QUICK_ORDER,
        ],
        handleLeads
    );
}

export default function* root() {
    yield all([
        fork(watchLeads),
    ]);
}
