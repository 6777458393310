import update from "immutability-helper";
import { Action } from "redux";
import { StoreActionTypes } from "actions/store.actions";

export interface StoreState {
    isInitialized?: boolean;
}

const defaultState: StoreState = {
    isInitialized: undefined,
};
Object.freeze(defaultState);

export const StoreReducer = (
    state = defaultState,
    action: Action
) => {
    switch (action.type) {
        case StoreActionTypes.Init.BEGIN: {
            return update(state, { isInitialized: { $set: undefined } });
        }
        case StoreActionTypes.Init.SUCCESS: {
            return update(state, { isInitialized: { $set: true } });
        }
        case StoreActionTypes.Init.FAILURE: {
            return update(state, { isInitialized: { $set: false } });
        }
        default: return state;
    }
};
