import { fork, all } from "redux-saga/effects";

import accountSaga from "./account/index";
import analyticsSaga from "./analytics";
import convertContractSaga from "./convertContract";
import orderSaga from "./order";
import partnerSaga from "./partner";
import paymentSaga from "./payment";

//For full API see https://github.com/redux-saga/redux-saga/tree/master/docs/api

function* root() {
    yield all([
        fork(accountSaga),
        fork(analyticsSaga),
        fork(convertContractSaga),
        fork(orderSaga),
        fork(paymentSaga),
        fork(partnerSaga),
    ]);
}

export default root;
