import { ApplicationState } from "../store/app";

/**
 * Gets reviews from customers, contractors, or real estate agents.
 */
export const getReviews = (state: ApplicationState, props: any) => {
    const review = state.review;
    if (props && props.type) {
        return review[props.type] || [];
    }
    return review.customer || [];
};
