import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { createServiceRequestHistoryActions, ServiceRequestHistoryActionTypes } from "../../actions/account/serviceRequestHistory.actions";
import { PayloadAction } from "../../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const serviceRequestHistoryActions = createServiceRequestHistoryActions();

const handleLoadServiceRequestHistory: Epic<
    PayloadAction<string[] | Error | Landmark.ServiceRequest[]>
> = action$ => action$.pipe(
    ofType(ServiceRequestHistoryActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string[]>) => LandmarkApiService
            .get(`/account/servicerequest?contractIds=${action.payload.join(",")}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        serviceRequestHistoryActions.load.success,
        serviceRequestHistoryActions.load.failure,
    ),
);

const handleLoadServiceRequestNotes: Epic<
    PayloadAction<string | Error | Landmark.NotesResponse>
> = action$ => action$.pipe(
    ofType(ServiceRequestHistoryActionTypes.LoadNotes.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/account/servicerequest/note/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        serviceRequestHistoryActions.loadNotes.success,
        serviceRequestHistoryActions.loadNotes.failure,
    ),
);

const waitDuringLoadServiceRequestHistory = createWaitEpic(ServiceRequestHistoryActionTypes.Load);
const waitDuringLoadServiceRequestNotes = createWaitEpic(ServiceRequestHistoryActionTypes.LoadNotes);

const epic = combineEpics(
    handleLoadServiceRequestHistory,
    handleLoadServiceRequestNotes,
    waitDuringLoadServiceRequestHistory,
    waitDuringLoadServiceRequestNotes,
);
export default epic;
