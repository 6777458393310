import * as React from "react";

import { contractorApplicationEmailInfo } from "../ContractorsPage/BecomeAContractor";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";

interface Props {
    actions?: {
        router: RouterActionCreators
    };
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class Contractors extends React.Component<Props, any> {
    render() {
        const { body, emailAddress, subject } = contractorApplicationEmailInfo;

        return (
            <section className="section-side contractor">
                <div className="container">
                    <div className="row">
                        <div className="column two-thirds content">
                            <i title="Icon for the contractors portion of Landmark Home Warranty's website." className="section-icon icon-contractor"></i>
                            <h1>Contractors</h1>
                            <p>Landmark Home Warranty helps contractors grow their business and increase brand exposure. Excellent service contractors who work with Landmark can receive steady
                                business year-round. Work with a trusted home warranty company today!</p>
                            <div className="btn-group">
                                <a className="btn-teal"
                                    href="/contractors"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.actions.router.push("/contractors");
                                        window.scrollTo(0, 0);
                                    }}>Learn More<i data-icon="j"></i></a>
                                <a className="btn-white btn-contractor" href={`mailto:${emailAddress}?subject=${subject}&body=${encodeURIComponent(body)}`}>Begin Application</a>
                            </div>
                            <div className="row">
                                <div className="column half">
                                    <h4>Benefits</h4>
                                    <ul className="feature-list">
                                        <li>Contractor Portal</li>
                                        <li>Use of Dispatch.me</li>
                                        <li>Healthy Profits</li>
                                        <li>Potential for Year-Round Work</li>
                                    </ul>
                                </div>
                                <div className="column half">
                                    <h4>How it Works</h4>
                                    <ul className="feature-list">
                                        <li>1. Apply with Landmark Home Warranty</li>
                                        <li>2. Become a Home Warranty Contractor</li>
                                        <li>3. Work with Home Warranty Customers</li>
                                        <li>4. Get Chances to Grow Your Business</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div title="Contractor working for a home warranty company repairs plumbing." className="column third photo"></div>
                    </div>
                </div>
            </section>
        );
    }
}
