import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "../actions/defs";
import { createOffersActions, GetContractOffersRequest, OffersActionTypes } from "../actions/offers.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";
import { Areas } from "constants/Areas";

const offersActions = createOffersActions();

/**
 * Loads offers.
 */
const handleLoadOffers: Epic<
    PayloadAction<GetContractOffersRequest | Landmark.GetContractOffersResponse | Error>
> = action$ => action$.pipe(
    ofType(OffersActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        async (action: PayloadAction<GetContractOffersRequest>) => {
            const {
                contractId,
                dwellingTypeId,
                isConvertingFromListingCoverage,
                isListingCoverage,
                isUpgrading,
                planTypeId,
                stateCode,
            } = action.payload;
            if (contractId && !isConvertingFromListingCoverage) {
                // Get offers based on the contract
                return await LandmarkApiService
                    .get(`/offers/${contractId}/${isUpgrading}`)
                    .withAuthentication()
                    .fetch()
                    .then(response => response.json);
            }
            else if (stateCode && planTypeId && dwellingTypeId) {
                // The request is different from our previous request,
                // call the API to retrieve contract offers.
                return await LandmarkApiService
                    .get(`/offers/${stateCode}/${planTypeId}/${dwellingTypeId}/${isListingCoverage}`)
                    .fetch()
                    .then(response => response.json);
            }

            // Not enough info to call the API
            return {
                offers: [],
                over5000SqFtFee: 0,
            } as Landmark.GetContractOffersResponse;
        },
        payload => offersActions.load.success(payload),
        err => offersActions.load.failure(err),
    ),
);

const waitForLoadOffers = createWaitEpic(OffersActionTypes.Load, Areas.Offer.OfferPackages);

const epic = combineEpics(
    handleLoadOffers,
    waitForLoadOffers,
);
export default epic;
