import * as Animation from "./Animation";
import * as Colors from "./Colors";
import * as Fonts from "./Fonts";
import * as Modal from "./Modal";
import * as Select from "./Select";
import * as Swipe from "./Swipe";
import * as Wait from "./Wait";

export {
    Animation,
    Colors,
    Fonts,
    Modal,
    Select,
    Swipe,
    Wait,
};
