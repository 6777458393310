import * as React from "react";

import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { Footer } from "../components/Footer";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";

interface Props {
    actions?: {
        router: RouterActionCreators;
    };
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions(),
    })
)
export class ErrorPage extends React.Component<Props, any> {
    onBackClick = () => {
        this.props.actions.router.push("/");
    }
    render() {
        return (
            <main className="misc error">
                <section className="section-intro error">
                    <div className="container">
                        <div className="row intro-content">
                            <h1>Oh no… This isn’t right</h1>
                            <p>We can’t seem to find the page you’re looking for.</p>
                            <a onClick={() => this.onBackClick()} className="btn-orange btn-back"><i data-icon="i"></i> Go Back</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        );
    }
}
