import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { ActionCreatorMap } from "../../store/componentBindings";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";

export const ContractorDashboardActionTypes = {
    LoadScores: AsyncAction(`contractor/dashboard/dispatchStatistics/scores/load`),
    LoadComments: AsyncAction(`contractor/dashboard/comments/load`),
    CLEAR_COMMENTS: `${appId}/contractor/dashboard/CLEAR_COMMENTS`,
};

export interface LoadContractorsOwnStatisticsPayload {
    webUserId: string;
    year: number;
}

export interface LoadCommentsPayload {
    month: number;
    trade: string;
    userId: string;
    year: number;
}

export function createContractorDashboardActions(): ContractorDashboardActionCreators {
    return {
        clearComments: () => {
            return {
                type: ContractorDashboardActionTypes.CLEAR_COMMENTS
            };
        },
        loadScores: createAsyncActions(ContractorDashboardActionTypes.LoadScores),
        loadComments: createAsyncActions(ContractorDashboardActionTypes.LoadComments),
    };
}

export interface ContractorDashboardActionCreators extends ActionCreatorMap {
    /**
     * Clears the comments that have been loaded for the selected month.
     */
    clearComments: () => Action;

    /**
     * Loads score statistics for the contractor.
     */
    loadScores: AsyncActionCreators<LoadContractorsOwnStatisticsPayload, Landmark.DispatchStatisticsResponse>;

    /**
     * Loads comments for selected user, month, year, and trade
     */
    loadComments: AsyncActionCreators<LoadCommentsPayload, Landmark.VOCCommentsResponse[]>;
}
