import * as React from "react";

import { createDialogActions, DialogActionCreators } from "../actions/dialog.actions";
import { createForumActions, ForumActionCreators } from "../actions/forum.actions";
import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { Login } from "../components/Login";
import { MobileHeaderButton } from "../components/MobileHeaderButton";
import { ScrollPosition, withScrollTracking } from "../components/ScrollingTracker";
import { Areas } from "../constants/Areas";
import { getIsAuthenticated } from "../selectors/auth.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { handleClick } from "../utils/link";

const styles = {
    logo: {
        cursor: "pointer"
    },
    link: {
        textDecoration: "none",
        fontWeight: 600,
    } as React.CSSProperties,
    mobileLink: {
        textDecoration: "none",
        position: "relative",
        left: "30%",
        maxHeight: "70px",
    },
    phoneNumber: {
        color: "#F2693E",
        fontWeight: 600,
        pointerEvent: "none",
        cursor: "default",
    },
};

interface Props {
    children?: any;
    store?: {
        isAuthenticated?: boolean;
    };
    actions?: {
        forum: ForumActionCreators;
        loginDialog: DialogActionCreators;
        router: RouterActionCreators;
        srDialog: DialogActionCreators;
    };
    scroll?: ScrollPosition;
}

@withScrollTracking(80)
@connect(
    state => ({
        store: {
            isAuthenticated: getIsAuthenticated(state),
        }
    }),
    bindActionCreatorsToProps({
        forum: createForumActions(),
        loginDialog: createDialogActions(Areas.Account.Login),
        router: createRouterActions(),
        srDialog: createDialogActions(Areas.Account.ServiceRequest.Create),
    })
)
export class Header extends React.Component<Props, any> {
    handleLoginClick = e => {
        e.preventDefault();
        if (this.props.store.isAuthenticated) {
            this.props.actions.router.push("/account");
        }
        else {
            this.props.actions.loginDialog.open();
        }
    }

    handleRequestRepair = (e) => {
        e.preventDefault();
        if (this.props.store.isAuthenticated) {
            this.props.actions.srDialog.open();
            this.props.actions.router.push("/account");
        }
        else {
            this.props.actions.srDialog.open();
            this.props.actions.loginDialog.open();
        }
    }

    //handleResourcesClick = e => {
    //    e.preventDefault();
    //    this.props.actions.forum.clear();
    //    this.props.actions.forum.loadSummaries.begin();
    //    this.props.actions.router.push("/resources");
    //}

    render() {
        return (
            <header id="header" className={this.props.scroll.currentStep > 0 ? "header-is-scrolled" : ""}>
                <div className="container">
                    <div className="row" style={{ maxHeight: "70px" }}>
                        <figure className="logo" style={styles.logo} onClick={e => this.props.actions.router.push("/")}></figure>
                        <div className="header-image-offset">
                            <div className="hiddenIfTablet">
                                <nav style={{ maxHeight: "70px", marginTop: "-3px" }}>
                                    <a data-icon="a" style={styles.link} href="/homeowner-warranty" onClick={e => handleClick(e, this)}>Homeowner</a>
                                    <a data-icon="b" style={styles.link} href="/home-buyers-warranty" onClick={e => handleClick(e, this)}>Real Estate</a>
                                    <a data-icon="c" style={styles.link} href="/contractors" onClick={e => handleClick(e, this)}>Contractors</a>
                                    {/*<a data-icon="d" style={styles.link} href="/resources" onClick={e => this.handleResourcesClick(e)}>Resources</a>*/}
                                    <a style={styles.phoneNumber}>866-306-2999</a>
                                    <a type="button"
                                        className="btn-orange btn-stroke-green"
                                        href="/claim"
                                        style={styles.link}
                                        onClick={e => this.handleRequestRepair(e)}>Request Repair</a>
                                    <Login />
                                </nav>
                            </div>
                            <div className="visibleIfTablet">
                                <nav style={{ maxHeight: "70px" }}>
                                    <MobileHeaderButton
                                        iconColor={"#F2693E"} // orange
                                        onClick={() => this.props.actions.router.push("/homeowner-warranty")}
                                        fontIcon="a"
                                        label="Homeowner" />
                                    <MobileHeaderButton
                                        iconColor={"#1F506F"} // blue
                                        onClick={() => this.props.actions.router.push("/home-buyers-warranty")}
                                        fontIcon="b"
                                        label="Real Estate" />
                                    <MobileHeaderButton
                                        iconColor={"#95CED7"} // teal
                                        onClick={() => this.props.actions.router.push("/contractors")}
                                        fontIcon="c"
                                        label="Contractor" />
                                    {/*<MobileHeaderButton*/}
                                    {/*    iconColor={"#43CB83"} // green*/}
                                    {/*    onClick={() => this.props.actions.router.push("/resources")}*/}
                                    {/*    fontIcon="d"*/}
                                    {/*    label="Tools" />*/}
                                    <MobileHeaderButton
                                        iconColor={"#808285"} // gray
                                        onClick={e => this.handleLoginClick(e)}
                                        fontIcon="0"
                                        label={this.props.store.isAuthenticated ? "Account" : "Login"} />
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.children}
            </header>
        );
    }
}
