import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { ActionCreatorMap } from "../store/componentBindings";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions } from "./defs";

export const SalesRepActionTypes = {
    CLEAR: `${appId}/salesRep/CLEAR`,
    Load: AsyncAction("salesRep/load"),
};
Object.freeze(SalesRepActionTypes);

export interface SalesRepActionCreators extends ActionCreatorMap {
    /**
     * Clears the list of reps.
     */
    clear: () => Action;
    /**
     * Load sales rep data from the server.
     */
    load: AsyncActionCreators<string, Landmark.SalesRep[]>;
}

export function createSalesRepActions() {
    return {
        clear: () => ({ type: SalesRepActionTypes.CLEAR }),
        load: createAsyncActions(SalesRepActionTypes.Load),
    } as SalesRepActionCreators;
}
