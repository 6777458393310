import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { AccountNoticesActionTypes, createNoticesActions } from "../../actions/account/notices.actions";
import { PayloadAction } from "../../actions/defs";
import { Areas } from "../../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";

const noticesActions = createNoticesActions();

const handleLoadAccountNotices: Epic<
    PayloadAction<string | Error | Landmark.NoticesResponse>
> = action$ => action$.pipe(
    ofType(AccountNoticesActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/account/notice/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        noticesActions.load.success,
        noticesActions.load.failure,
    ),
);

const waitDuringLoadNotices = createWaitEpic(AccountNoticesActionTypes.Load, Areas.Account.Notices);

const epic = combineEpics(
    handleLoadAccountNotices,
    waitDuringLoadNotices,
);
export default epic;
