import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { AuthActionTypes } from "../actions/auth.actions";
import { CrrActionTypes } from "../actions/crr.actions";
import { PayloadAction } from "../actions/defs";
import { Areas } from "../constants/Areas";
import { CategoryState, defaultCategoryState, createCategoryReducer } from "./category.reducer";

export interface CrrState {
    categories: CategoryState;
    contractorCategories: CategoryState;
    contractorDispatchStatistics: Landmark.DispatchStatisticsResponse;
    dispatchStatistics: Landmark.DispatchStatisticsResponse;
    graph: CategoryState;
    selectedYear: string;
}

const defaultState: CrrState = {
    categories: defaultCategoryState,
    contractorCategories: defaultCategoryState,
    contractorDispatchStatistics: {},
    dispatchStatistics: {},
    graph: defaultCategoryState,
    selectedYear: null,
};

const categoryReducer = createCategoryReducer(Areas.CRR.Scores);
const contractorCategoryReducer = createCategoryReducer(Areas.CRR.ContractorScores);
const graphReducer = createCategoryReducer(Areas.Contractor.Graphs);

export const CrrReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<CrrState>
) => {
    const crrAction = action as PayloadAction<CrrState>;

    // Update state with our other reducers
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action )},
        contractorCategories: { $set: contractorCategoryReducer(state.contractorCategories, action )},
        graph: { $set: graphReducer(state.graph, action )},
    });

    switch (action.type) {
        case CrrActionTypes.loadDispatchStatistics.SUCCESS: {
            return update(state, {
                dispatchStatistics: { $set: crrAction.payload }
            });
        }
        case CrrActionTypes.loadContractorDispatchStatistics.SUCCESS: {
            return update(state, {
                contractorDispatchStatistics: { $set: crrAction.payload }
            });
        }
        case CrrActionTypes.RESET_CONTRACTOR_STATISTICS: {
            return update(state, {
                contractorDispatchStatistics:{
                    $set: defaultState.contractorDispatchStatistics
                }
            });
        }
        case CrrActionTypes.SET_SELECTED_YEAR: {
            return update(state, { selectedYear: { $set: crrAction.payload }});
        }
        case AuthActionTypes.LOGOUT: {
            return defaultState;
        }
    }
    return state;
};
