import * as Landmark from "models/landmark-api";
import * as React from "react";

import { TextBox } from "./TextBox";
import { ContactUsActionCreators, createContactUsActions } from "../actions/contactUs.actions";
import { getContactUs } from "../selectors/contactUs.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";

interface Props {
    store?: {
        contactUs: Landmark.ContactUs;
    };
    actions?: {
        contactUs: ContactUsActionCreators;
    };
}

class Controls {
    name: TextBox;
    email: TextBox;
    phone: TextBox;
    message: TextBox;
}

@connect(
    state => ({
        store: {
            contactUs: getContactUs(state),
        }
    }),
    bindActionCreatorsToProps({
        contactUs: createContactUsActions(),
    })
)
export class EmailUs extends React.Component<Props, any> {
    private controls = new Controls();

    handleClick = async () => {
        const request = this.props.store.contactUs;

        // FIXME: add validation
        this.props.actions.contactUs.send.begin(request);
    }

    render() {
        return (
            <section className="section-contact">
                <div className="container">
                    <form onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    } }>
                        <fieldset>
                            <div className="row intro">
                                <h1 className="professional">Contact Landmark Home Warranty</h1>
                                <p>Live Customer Care representatives 24/7. Contact Landmark by phone or email.</p>
                            </div>
                        </fieldset>
                        <fieldset style={{ maxWidth: "1100px", marginLeft: "auto", marginRight: "auto" }}>
                            <div className="row">
                                <div className="fieldset-title">Send Us An Email</div>
                            </div>
                            <div className="row">
                                <div className="column third">
                                    <label className="field-label">Name</label>
                                    <div className="field-wrap">
                                        <TextBox
                                            ref={control => this.controls.name = control}
                                            placeholder="Name..."
                                            onBlur={(e: any) => this.props.actions.contactUs.setValues({ name: e.target.value }) }
                                            value={this.props.store.contactUs.name} />
                                    </div>
                                </div>
                                <div className="column third">
                                    <label className="field-label">Phone</label>
                                    <div className="field-wrap">
                                        <TextBox
                                            ref={control => this.controls.phone = control}
                                            type="tel"
                                            placeholder="Phone..."
                                            onChange={(e: any) => this.props.actions.contactUs.setValues({ phone: e.target.value }) }
                                            value={this.props.store.contactUs.phone} />
                                    </div>
                                </div>
                                <div className="column third">
                                    <label className="field-label">Email</label>
                                    <div className="field-wrap">
                                        <TextBox
                                            ref={control => this.controls.email = control}
                                            placeholder="Email..."
                                            onChange={(e: any) => this.props.actions.contactUs.setValues({ email: e.target.value }) }
                                            value={this.props.store.contactUs.email} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column full">
                                    <div className="field-wrap">
                                        <TextBox
                                            ref={control => this.controls.message = control}
                                            placeholder="Please provide all comments here..."
                                            onChange={(e: any) => this.props.actions.contactUs.setValues({ message: e.target.value }) }
                                            type="textarea"
                                            value={this.props.store.contactUs.message} />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="submit-flow" style={{ maxWidth: "1100px", marginLeft: "auto", marginRight: "auto" }}>
                            <div className="row">
                                {/* disabled={this.props.store.contactUs.isLoading} */}
                                <button
                                    onClick={this.handleClick}
                                    className="btn btn-next">
                                    Submit
                                    <i data-icon="j"></i>
                                </button>
                            </div>
                        </fieldset>
                    </form>
                    {/* <ValidationModal
                        onRequestClose={() => this.props.actions.contactUs.setValues({ allValidationResults: [] })}
                        results={this.props.store.contactUs.allValidationResults} /> */}
                </div>
            </section>
        );
    }
}
