import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { CategoryState, defaultCategoryState, createCategoryReducer } from "../category.reducer";
import { PayloadAction } from "../../actions/defs";
import { AccountInvoicesActionTypes } from "../../actions/account/invoices.actions";
import { Areas } from "../../constants/Areas";

export interface InvoicesState {
    categories: CategoryState;
    list: Landmark.Invoice[];
    selectedInvoice: Landmark.Invoice;
    paymentInfo: Landmark.PaymentInfo;
}

export const defaultState: InvoicesState = {
    categories: defaultCategoryState,
    list: [],
    selectedInvoice: null,
    paymentInfo: null,
};

const categoryReducer = createCategoryReducer(Areas.Agent.Invoices.Page);

export function InvoicesReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.Invoice[] |
            Landmark.InvoiceResult |
            Landmark.Invoice |
            string
        >
) {
    // Update the categories state
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action) }
    });

    switch (action.type) {
        case AccountInvoicesActionTypes.Load.SUCCESS: {
            const invoices = (action as PayloadAction<Landmark.Invoice[]>).payload;
            return update(state, {
                list: { $set: invoices }
            });
        }

        case AccountInvoicesActionTypes.Cancel.SUCCESS: {
            const payload = (action as PayloadAction<Landmark.InvoiceResult>).payload;
            const index = state.list.findIndex(invoice => invoice.invoiceId === payload.contractId);
            if (index >= 0) {
                return update(state, {
                    list: {
                        [index]: { $set: payload }
                    }
                });
            }
            break;
        }

        case AccountInvoicesActionTypes.Complete.SUCCESS: {
            const response = (action as PayloadAction<Landmark.CompleteInvoiceResponse>).payload;
            const index = state.list.findIndex(i => i.invoiceId === response.invoice.invoiceId);
            if (index >= 0) {
                return update(state, {
                    list: {
                        [index]: { $set: response.invoice }
                    }
                });
            }
        }

        case AccountInvoicesActionTypes.SelectInvoice: {
            const payload = (action as PayloadAction<Landmark.Invoice>).payload;
            return update(state, {
                selectedInvoice: { $set: payload }
            });
        }

        case AccountInvoicesActionTypes.SET_PAYMENT_INFO: {
            const paymentInfo = (action as PayloadAction<string>).payload;
            return update(state, {
                paymentInfo: { $set: paymentInfo },
            });
        }

        case AccountInvoicesActionTypes.Payment.SUCCESS: {
            return update(state, {
                wasPaymentSuccessful: { $set: (action as PayloadAction<Landmark.PaymentResponse>).payload.wasPaymentSuccessful }
            });
        }
    }

    // If nothing changed, return the original state
    return state;
}
