import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { ActionCreatorMap } from "../store/componentBindings";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "./defs";

export const AuthActionTypes = {
    Impersonate: {
        Start: AsyncAction(`auth/impersonate/start`),
        End: AsyncAction(`auth/impersonate/end`),
    },
    Authenticate: AsyncAction(`auth/authenticate`),
    LOGOUT: `${appId}/auth/LOGOUT`,
    Refresh: AsyncAction(`auth/refresh`),
};
Object.freeze(AuthActionTypes);

export type RefreshAction = {
    username: string;
    refreshToken: string;
    fingerprint: string;
};

export interface AuthActionCreators extends ActionCreatorMap {
    /**
     * Authenticates with the system with a username/password or username/token combination.
     * @param {AuthenticateRequestPayload} request The authentication request.
     * @returns An authentication response.
     */
    authenticate: AsyncActionCreators<Landmark.AuthenticationRequest, Landmark.AuthenticationResponse>;

    /**
     * Ends impersonating another user.
     */
    endImpersonation: AsyncActionCreators<void, Landmark.AuthenticationResponse>;

    /**
     * Logs out of the system.
     */
    logout: () => Action;

    /**
     * Refreshes an authentication token.
     */
    refresh: AsyncActionCreators<RefreshAction, Landmark.AuthenticationResponse>;

    /**
     * Starts impersonating another user.
     */
    startImpersonation: AsyncActionCreators<string, Landmark.AuthenticationResponse>;

    /**
     * Authenticates with a token.
     */
    withToken: (
        username: string,
        token: string,
        fingerprint: string
    ) => PayloadAction<Landmark.AuthenticationRequest>;

    /**
     * Authenticates with a password.
     */
    withPassword: (
        username: string,
        password: string,
        fingerprint: string,
        rememberMe: boolean
    ) => PayloadAction<Landmark.AuthenticationRequest>;

    withUser: (
        webuserid: string,
        wmuserid: string,
        fingerprint: string
    ) => PayloadAction<Landmark.AuthenticationRequest>;
}

export function createAuthActions() {
    return {
        authenticate: createAsyncActions<Landmark.AuthenticationRequest, Landmark.AuthenticationResponse>(AuthActionTypes.Authenticate),
        endImpersonation: createAsyncActions<void, Landmark.AuthenticationResponse>(AuthActionTypes.Impersonate.End),
        logout: () => {
            // TODO: Do we need to communicate our logout to the API server?
            return {
                type: AuthActionTypes.LOGOUT
            };
        },
        refresh: createAsyncActions<RefreshAction, Landmark.AuthenticationResponse>(AuthActionTypes.Refresh),
        startImpersonation: createAsyncActions<string, Landmark.AuthenticationResponse>(AuthActionTypes.Impersonate.Start),
        withToken: (username: string, token: string, fingerprint: string) => {
            return {
                type: AuthActionTypes.Authenticate.BEGIN,
                payload: {
                    username,
                    password: token,
                    fingerprint,
                    grant_type: "code",
                }
            };
        },
        withPassword: (username: string, password: string, fingerprint: string) => {
            return {
                type: AuthActionTypes.Authenticate.BEGIN,
                payload: {
                    username,
                    password,
                    fingerprint,
                    grant_type: "password"
                }
            };
        },
        withUser: (webuserid: string, wmuserid: string, fingerprint: string) => {
            return {
                type: AuthActionTypes.Authenticate.BEGIN,
                payload: {
                    webuserid,
                    wmuserid,
                    fingerprint,
                    grant_type: "user"
                }
            };
        }

    } as AuthActionCreators;
}
