import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "../actions/defs";
import { createReviewActions, ReviewActionTypes } from "../actions/review.actions";
import { Areas } from "../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const reviewActions = createReviewActions();

const handleLoadReviewsForCustomers: Epic<
    PayloadAction<void | Error | Landmark.Review[]>
> = action$ => action$.pipe(
    ofType(ReviewActionTypes.LoadCustomers.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/review/customer/10")
            .fetch()
            .then(response => response.json),
        reviewActions.loadCustomerReviews.success,
        reviewActions.loadCustomerReviews.failure,
    ),
);

const handleLoadReviewsForContractors: Epic<
    PayloadAction<void | Error | Landmark.Review[]>
> = action$ => action$.pipe(
    ofType(ReviewActionTypes.LoadContractors.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/review/contractor/10")
            .fetch()
            .then(response => response.json),
        reviewActions.loadContractorReviews.success,
        reviewActions.loadContractorReviews.failure,
    ),
);

const handleLoadReviewsForHomeowners: Epic<
    PayloadAction<void | Error | Landmark.Review[]>
> = action$ => action$.pipe(
    ofType(ReviewActionTypes.LoadHomeowners.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/review/homeowner/10")
            .fetch()
            .then(response => response.json),
        reviewActions.loadHomeownerReviews.success,
        reviewActions.loadHomeownerReviews.failure,
    ),
);

const handleLoadReviewsForRealEstate: Epic<
    PayloadAction<void | Error | Landmark.Review[]>
> = action$ => action$.pipe(
    ofType(ReviewActionTypes.LoadRealEstate.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/review/realEstate/10")
            .fetch()
            .then(response => response.json),
        reviewActions.loadRealEstateReviews.success,
        reviewActions.loadRealEstateReviews.failure,
    ),
);

const waitForCustomerReviews = createWaitEpic(ReviewActionTypes.LoadCustomers, Areas.Reviews);
const waitForContractorReviews = createWaitEpic(ReviewActionTypes.LoadContractors, Areas.Reviews);
const waitForHomeownerReviews = createWaitEpic(ReviewActionTypes.LoadContractors, Areas.Reviews);
const waitForRealEstateReviews = createWaitEpic(ReviewActionTypes.LoadRealEstate, Areas.Reviews);

const epic = combineEpics(
    handleLoadReviewsForCustomers,
    handleLoadReviewsForContractors,
    handleLoadReviewsForHomeowners,
    handleLoadReviewsForRealEstate,
    waitForCustomerReviews,
    waitForContractorReviews,
    waitForHomeownerReviews,
    waitForRealEstateReviews,
);
export default epic;
