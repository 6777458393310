import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "../actions/defs";
import { createLimitsAndExclusionsActions, LimitsAndExclusionsActionTypes } from "../actions/limitsAndExclusions.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const limitsAndExclusionsActions = createLimitsAndExclusionsActions();

/**
 * Gets limits and exclusions for a sales item coverage id
 */
const handleGetLimitsAndExclusions: Epic<
    PayloadAction<string | Landmark.LimitsAndExclusions | Error>
> = action$ => action$.pipe(
    ofType(LimitsAndExclusionsActionTypes.Get.BEGIN),
    switchMapWithPromiseToActions(
        async (action: PayloadAction<string>) => {
            const salesItemCoverageId = action.payload;
            if (salesItemCoverageId) {
                // Get limits and exclusions based on the sales item coverage id
                return await LandmarkApiService
                    .get(`coverageItem/limitsAndExclusions/${salesItemCoverageId}`)
                    .fetch()
                    .then(response => response.json);
            }

            // Not enough info to call the API
            return {
                name: "",
                factoids: [],
                coveredCoverageList: [],
                notCovered: [],
                limits: [],
                legal: [],
            } as Landmark.LimitsAndExclusions;
        },
        payload => limitsAndExclusionsActions.get.success(payload),
        err => limitsAndExclusionsActions.get.failure(err),
    ),
);

const waitGetLimitsAndExclusions = createWaitEpic(LimitsAndExclusionsActionTypes.Get);

const epic = combineEpics(
    handleGetLimitsAndExclusions,
    waitGetLimitsAndExclusions,
);
export default epic;
