import { createSelector } from "./common.selectors";
import { ApplicationState } from "../store/app";

export const getLimitsAndExclusionsState = (state: ApplicationState) => state.limitsAndExclusions;

/**
 * Gets current contract when they are upgrading
 */
export const getLimitsAndExclusions = createSelector(
    getLimitsAndExclusionsState,
    limitsAndExclusions => {
        if (limitsAndExclusions.response) {
            return limitsAndExclusions.response;
        }
        return null;
    }
);
