import { ActionCreatorsMapObject } from "redux";

import { appId, PayloadAction } from "./defs";

export interface CategoryActionTypes {
    COMPLETE: string;
    SELECT: string;
}

export function buildCategoryActionTypes(area: string) {
    return {
        COMPLETE: `${appId}/category/${area}/COMPLETE`,
        SELECT: `${appId}/category/${area}/SELECT`,
    } as CategoryActionTypes;
}

export interface SelectCategoryAction extends PayloadAction<{ category: string; }> {}
export interface CompleteCategoryAction extends PayloadAction<{ category: string; isCompleted: boolean; }> {}

/**
 * Returns action creators for category selection.
 * @param {string} area The name of the area the category applies to.
 * @returns An object containing action creators for the category selection.
 */
export function createCategoryActions(area: string) {
    const types = buildCategoryActionTypes(area);

    return {
        complete: (category: string, isCompleted: boolean = true) => {
            return {
                type: types.COMPLETE,
                payload: {
                    area,
                    category,
                    isCompleted,
                },
            };
        },
        select: (category: string) => {
            return {
                type: types.SELECT,
                payload: {
                    area,
                    category,
                },
            };
        },
    } as CategoryActionCreators;
}

export interface CategoryActionCreators extends ActionCreatorsMapObject {
    /**
     * Completes a category with the given name.
     */
    complete: (category: string, isCompleted: boolean) => CompleteCategoryAction;

    /**
     * Select a category with the given name.
     */
    select: (category: string) => SelectCategoryAction;
}
