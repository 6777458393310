import { ParsedUrlQuery } from "querystring";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as url from "url";

import { AuthActionCreators, createAuthActions } from "../../actions/auth.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { Wait } from "../../components/Wait";
import { connect, bindActionCreatorsToProps } from "../../store/componentBindings";
import { getFingerprintAsync } from "../../utils/fingerprint";

interface Props extends Partial<RouteComponentProps<any>> {
    actions: {
        auth: AuthActionCreators;
        router: RouterActionCreators;
    };
}

interface QueryParams extends ParsedUrlQuery {
    u: string;
    t: string;
}

interface ParsedLocation {
    query?: QueryParams;
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        auth: createAuthActions(),
        router: createRouterActions()
    })
)
export class LoginPage extends React.Component<Props, any> {
    async componentDidMount() {
        try {
            const { pathname = "", search = "" } = this.props.location || {};
            const { query } = url.parse(pathname + search, !!search) as ParsedLocation;

            if (query && query.u && query.t) {
                const fingerprint = await getFingerprintAsync();
                this.props.actions.auth.withToken(query.u, query.t, fingerprint);
            }
            else {
                // Not enough info provided in query string
                this.props.actions.router.push("/account");
            }
        }
        catch (err) {
            // No credentials were provided, redirect to the account page
            this.props.actions.router.push("/account");
        }
    }

    render() {
        return (
            <Wait style={{
                container: {
                    borderRadius: 0,
                },
                outer: {
                    width: "100%",
                    height: "100%",
                }
            }} isWaiting={true} />
        );
    }
}
