const isTrue = /true/i;

/**
 * NOTE: UTF8-encoded strings don't always proprerly deserialize with atob(), even
 * though the documentation for atob() says it should.
 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
 */
export function b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

/**
 * Environment settings.
 */
let environment = {
    appEnvironment: process.env.REACT_APP_ENVIRONMENT,
    canUseABtesting: isTrue.test(process.env.REACT_APP_CAN_USE_AB_TESTING),
    canUseDom: navigator.userAgent !== "server",
    googleAnalyticsKey: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
    googleSiteVerificationKey: process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_KEY,
    isDebugEnabled: isTrue.test(process.env.REACT_APP_DEBUG_ENABLED),
    isDevelopment: process.env.REACT_APP_ENVIRONMENT !== "prod",
    isGoogleAnalyticsDebugEnabled: isTrue.test(process.env.REACT_APP_GOOGLE_DEBUG_ENABLED),
    isLocal: process.env.REACT_APP_ENVIRONMENT === "local",
    isProduction: process.env.REACT_APP_ENVIRONMENT === "prod",
    port: process.env.PORT,
    publicUrl: process.env.PUBLIC_URL,
    webApiUrl: process.env.REACT_APP_WEB_API_URL,
};

export function update(env) {
    // NOTE: we can't use Object.assign() here, as it won't replace the original instance
    // of `environment` that everyone is bound to.
    const keys = Object.keys(env);
    for (let key of keys) {
        if (environment.hasOwnProperty(key)) {
            environment[key] = env[key];
        }
    }
}

if (window && !!window["__environment"]) {
    // Load environment settings from SSR if provided
    console.debug("Found environment settings in global.");
    const envBase64 = window["__environment"];
    const env = JSON.parse(b64DecodeUnicode(envBase64));
    update(env);
}

export default environment;
