import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { createAccountInvoicesActions, AccountInvoicesActionTypes } from "../../actions/account/invoices.actions";
import { PayloadAction } from "../../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createWaitEpic } from "../wait.epic";
import { getContracts } from "../../selectors/account/contracts.selectors";
import { Action } from "redux";
import { map, withLatestFrom } from "rxjs/operators";
import { ApplicationState } from "store/app";

const invoicesActions = createAccountInvoicesActions();

const handleLoadInvoices: Epic<
    PayloadAction<string[] | Error | Landmark.Invoice[]>
> = action$ => action$.pipe(
    ofType(AccountInvoicesActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string[]>) => LandmarkApiService
            .get(`/account/invoices?contractIds=${action.payload.join(",")}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        invoicesActions.load.success,
        invoicesActions.load.failure,
    ),
    );

const handleProcessPayment: Epic<
    PayloadAction<Landmark.PaymentRequest | Landmark.PaymentResponse | Error>
    > = action$ => action$.pipe(
        ofType(AccountInvoicesActionTypes.Payment.BEGIN),
        switchMapWithPromiseToActions(
            (action: PayloadAction<Landmark.PaymentRequest>) => LandmarkApiService
                .post("/account/invoices")
                .withAuthentication()
                .payload(action.payload)
                .fetch()
                .then(response => response.json),
            invoicesActions.payment.success,
            invoicesActions.payment.failure
        ),
    );

const waitDuringLoadInvoices = createWaitEpic(AccountInvoicesActionTypes.Load);
const waitForProcessPayment = createWaitEpic(AccountInvoicesActionTypes.Payment);

const handleProcessPaymentSuccessful: Epic<Action> = (action$, state$) => action$
.pipe(
    ofType(AccountInvoicesActionTypes.Payment.SUCCESS),
    withLatestFrom(state$),
    map(([, state]: [PayloadAction<Landmark.PaymentResponse>, ApplicationState]) => {
        const contracts = getContracts(state);

        return invoicesActions.load.begin(contracts.map(c => c.contractId));

    }),
);

const epic = combineEpics(
    handleLoadInvoices,
    waitDuringLoadInvoices,
    handleProcessPayment,
    waitForProcessPayment,
    handleProcessPaymentSuccessful,
);
export default epic;
