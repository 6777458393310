import * as Landmark from "models/landmark-api";
import { withFormik, InjectedFormikProps } from "formik";
import * as React from "react";
import { isEmpty } from "validator";

import { ChoosePropertyType } from "./ChoosePropertyType";
import { SquareFootage } from "./SquareFootage";
import { TextBox } from "./TextBox";
import { ValidationModal } from "./ValidationModal";
import { PayloadAction } from "../actions/defs";
import { createOffersActions, OffersActionCreators, OffersActionTypes } from "../actions/offers.actions";
import { createOrderActions, OrderActionCreators } from "../actions/order.actions";
import { createRouterActions, RouterActionCreators } from "../actions/router.actions";
import { PlanTypeName, PlanType } from "../constants/TransactionType";
import { getOrderProperty, getOrderRequest } from "../selectors/order.selectors";
import { getOfferCoverages } from "../selectors/offers.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { actionPromise } from "../utils/redux-promise";

interface Props {
    store?: {
        property: Landmark.Property;
        offerCoverage: Landmark.ContractCoverage[];
        request: Landmark.OrderRequest;
    };
    actions?: {
        offers: OffersActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
    };
}

interface FormProps {
    store: {
        property: Landmark.Property;
        offerCoverage: Landmark.ContractCoverage[];
        request: Landmark.OrderRequest;
    };
    actions: {
        offers: OffersActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
    };
}

interface FormState {
    hasAttemptedSubmit: boolean;
}

interface FormValues {
    budgetAmount: string;
    dwellingTypeId: string;
    squareFootage: number;
}

class Form extends React.Component<InjectedFormikProps<FormProps, FormValues>, FormState> {
    constructor(props) {
        super(props);

        this.state = {
            hasAttemptedSubmit: false,
        };
    }

    render() {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldValue,
        } = this.props;

        return (
            <form onSubmit={handleSubmit} action={`/order/${PlanTypeName.RealEstate}`}>
                <div className="row">
                    <div className="column fourth">
                        <label className="field-label">Budget</label>
                        <TextBox
                            error={touched.budgetAmount && errors.budgetAmount}
                            filter={/[^\d,\.]/g}
                            name="budgetAmount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Amount to spend..."
                            type="text"
                            template="#####"
                            value={values.budgetAmount} />
                    </div>
                    <div style={{ color: "#58585B" }}>
                        <ChoosePropertyType
                            className="column fourth"
                            name="dwellingTypeId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            style={{ maxHeight: "51px" }}
                            title="Select a Property Type"
                            value={values.dwellingTypeId}
                        />
                    </div>
                    <div className="column fourth">
                        <label className="field-label">House Size</label>
                        <div className="field-wrap">
                            <div className="select dark">
                                <SquareFootage
                                    name="squareFootage"
                                    onChange={(e: any) => {
                                        const squareFootage = parseInt(e.target.value);
                                        setFieldValue("squareFootage", squareFootage);
                                    }}
                                    style={{ maxHeight: "51px" }}
                                    value={values.squareFootage} />
                            </div>
                        </div>
                    </div>
                    <div className="column fourth">
                        <label className="field-label">&nbsp; </label>
                        <div className="field-wrap">
                        <button
                            className="btn-orange"
                            disabled={isSubmitting}
                            onClick={e => this.setState({ hasAttemptedSubmit: true })}
                            type="submit">Submit<i data-icon="j"></i></button>
                        </div>
                    </div>
                </div>
                <ValidationModal
                    errors={errors}
                    isOpen={!isValid && this.state.hasAttemptedSubmit}
                    onRequestClose={() => this.setState({ hasAttemptedSubmit: false })} />
            </form>
        );
    }
}

const QuickOrderForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: props => {
        return {
            squareFootage: props.store.property.squareFootage,
            dwellingTypeId: props.store.property.dwellingTypeId,
        } as FormValues;
     },
    // Add a custom validation function
    validate: (values: FormValues, FormValues) => {
        let errors: any = {};
        if (!values.budgetAmount || isEmpty(values.budgetAmount)) {
            errors.budgetAmount = "Budget Amount is required.";
        }

        return errors;
    },
    // // Submission handler
    handleSubmit: async (
        values: FormValues,
        {
            props,
            setSubmitting,
        }
    ) => {
        const budgetAmount = parseInt(values.budgetAmount);
        const squareFootage = values.squareFootage;
        const dwellingTypeId =  values.dwellingTypeId;

        // Move to the order page and scroll to the top
        props.actions.order.newOrder(PlanTypeName.RealEstate);
        props.actions.order.quickOrder(budgetAmount, dwellingTypeId, squareFootage);

        // we need to get the offers with the dwelling type that they selected.
        const {payload} = await actionPromise<PayloadAction<Landmark.GetContractOffersResponse>>(
            () => props.actions.offers.load.begin({
                dwellingTypeId: dwellingTypeId,
                isListingCoverage: props.store.request.isListingCoverage,
                planTypeId: PlanType.RealEstate,
                stateCode: props.store.property.address.state,
            }),
            OffersActionTypes.Load.SUCCESS,
            OffersActionTypes.Load.FAILURE
        );

        let offersList: {[key:string]: string[]} = {};

        payload.offers.map(item => (
            offersList[item.planId] = [...item.includedAddonIds]
        ));

        // Set the budget, offers list and sq ft to get the correct plan for the amount choosen.
        props.actions.order.setQuickOrderPlanId.begin({
                budgetAmount: budgetAmount,
                planIds: offersList,
                squareFootage: squareFootage,
            }),

        props.actions.router.push(`/order/${PlanTypeName.RealEstate}`);

        window.scrollTo(0, 0);

        setSubmitting(false);
    }
})(Form);

@connect(
    state => ({
        store: {
           property: getOrderProperty(state),
           offerCoverages: getOfferCoverages(state),
           request: getOrderRequest(state),
        }
    }),
    bindActionCreatorsToProps({
        offers: createOffersActions(),
        order: createOrderActions(),
        router: createRouterActions()
    })
)
export class QuickOrder extends React.Component<Props, any> {
    render() {
        return (
            <div className="pricing-quick-order">
                <h3 style={{ textAlign: "left" }}>
                    <a> Quick Home Warranty Order Form </a>
                    </h3>{/*<div className="credit-tag">$25<span>Credit</span></div>*/}
                <QuickOrderForm
                    actions={this.props.actions}
                    store={this.props.store}
                />

            </div>
        );
    }
}
