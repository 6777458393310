const storageKeys = {
    auth: "redux:store:auth",
    category: "redux:store:category",
    order: "redux:store:order",
    reference: "redux:store:reference",
};

/**
 * A list of storage keys used to identify a chunk of data within storage.
 */
export const StorageKeys = Object.freeze(storageKeys);
