let watchedActionTypes = {};

/**
 * Redux middleware that handles action promises.
 */
export function promiseWrapperMiddleware(store) {
    return next => action => {
        if (typeof watchedActionTypes[action.type] !== "undefined") {
            for (let i = 0; i < watchedActionTypes[action.type].length; i++) {
                watchedActionTypes[action.type][i](action);
            }
            delete watchedActionTypes[action.type];
        }
        return next(action);
    };
}

/**
 * Creates a promise that wraps actions that flow through the store.
 * @param action The initial action to perform.  Usually, this will dispatch an action to the store.
 * @param resolveType The action type that represents a success condition.
 * @param rejectType The action type that represents a failure condition.
 */
export function actionPromise<TReturnType>(action: () => any, resolveType: string, rejectType: string) {
    return new Promise<TReturnType>((resolve, reject) => {
        // First, subscribe to the resolve/reject action types
        if (typeof resolveType !== "string") {
            throw "Must provide a valid action type for the promise resolution.";
        }
        if (typeof rejectType !== "string") {
            throw "Must provide a valid action type for the promise rejection.";
        }

        if (!watchedActionTypes.hasOwnProperty(resolveType)) {
            watchedActionTypes[resolveType] = [resolve];
        }
        else {
            watchedActionTypes[resolveType].push(resolve);
        }

        if (!watchedActionTypes.hasOwnProperty(rejectType)) {
            watchedActionTypes[rejectType] = [reject];
        }
        else {
            watchedActionTypes[rejectType].push(reject);
        }

        // Then, execute the action
        action();
    });
}
