import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { empty, of } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { LocationActionTypes, LocationResponse } from "../actions/location.actions";
import { OffersActionTypes } from "../actions/offers.actions";
import { createOrderActions, OrderActionTypes, PartnerSearchRequest } from "../actions/order.actions";
import { Areas } from "../constants/Areas";
import { switchMapWithPromiseToActions, waitForAsyncAction } from "rxjs/custom-operators";
import { getCurrentCouponCode, getOrderProperty, getOrderRequest, getBaseOrderRequest } from "../selectors/order.selectors";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { ApplicationState } from "../store/app";
import { createWaitEpic } from "./wait.epic";

const orderActions = createOrderActions();

const handleGetPriceBreakdown: Epic<PayloadAction<Landmark.OrderRequest | Landmark.ContractItemResponse | Error>> = action$ => action$.pipe(
    ofType(OrderActionTypes.GetPriceBreakdown.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.OrderRequest>) => LandmarkApiService
            .post("/order/quote")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.getPriceBreakdown.success,
        orderActions.getPriceBreakdown.failure,
    ),
);

const handleRegisterLead: Epic<PayloadAction<Landmark.LeadRegistrationRequest | Error | void>> = action$ => action$.pipe(
    ofType(OrderActionTypes.RegisterLead.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.LeadRegistrationRequest>) => LandmarkApiService
            .post("/lead")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.registerLead.success,
        orderActions.registerLead.failure,
    ),
);

const handleStartOrderOnRegisterLeadSuccess: Epic<
    Action | PayloadAction<Landmark.LeadRegistrationRequest | Error | void>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.RegisterLead.BEGIN),
    waitForAsyncAction(
        action$,
        OrderActionTypes.RegisterLead,
        (beginAction: PayloadAction<Landmark.LeadRegistrationRequest>, successAction) => orderActions.setValues({
            property: {
                address: {
                    postalCode: beginAction.payload.postalCode,
                    address: beginAction.payload.address,
                },
            },
            owner: {
                user: {
                    name: {
                        firstName: beginAction.payload.firstName,
                        lastName: beginAction.payload.lastName,
                    },
                    email: beginAction.payload.email,
                    phones: [{
                        number: beginAction.payload.homePhone,
                        isPrimary: true,
                        type: "home",
                    }],
                }
            },
        }),
        // NOTE: this translates to "on failure, don't do anything"
        (beginAction, failureAction) => null
    ),
);

/**
 * Finds real estate and title agents/companies.
 */
const handleSearchPartner: Epic<
    PayloadAction<PartnerSearchRequest | Landmark.PartnerSearchResponse[] | Error | void>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.SearchPartner.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<PartnerSearchRequest>) => LandmarkApiService
            //.get(`/partners/searchpartner?for=${action.payload.searchTerm}&partnerType=${action.payload.partnerType}`)
            .get(`/partners/search?for=${action.payload.searchTerm}&type=${action.payload.partnerType}`)
            .fetch()
            .then(response => response.json),
        orderActions.searchPartner.success,
        orderActions.searchPartner.failure,
    ),
    );

const handleSearchExistingPartner: Epic<
    PayloadAction<Landmark.ExistingPartnerRequest | Landmark.PartnerSearchResponse[] | Error | void>
    > = action$ => action$.pipe(
        ofType(OrderActionTypes.SearchExistingPartner.BEGIN),
        switchMapWithPromiseToActions(
            (action: PayloadAction<Landmark.ExistingPartnerRequest>) => LandmarkApiService
                .get(`/partners/findExistingPartner`)
                .body(action.payload)
                .fetch()
                .then(response => response.json),
            orderActions.searchExistingPartner.success,
            orderActions.searchExistingPartner.failure,
        ),
    );

const handleSearchExistingParent: Epic<
    PayloadAction<Landmark.ExistingPartnerRequest | Landmark.PartnerSearchResponse[] | Error | void>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.SearchExistingParent.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.ExistingPartnerRequest>) => LandmarkApiService
            .get(`/partners/findExistingParent`)
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.searchExistingParent.success,
        orderActions.searchExistingParent.failure,
    ),
);

/**
 * Submits the order to the server.
 */
const handleSubmit: Epic<
    PayloadAction<Landmark.OrderRequest | Landmark.OrderResponse | Error>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.Submit.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.OrderRequest>) => LandmarkApiService
            .post("/order")
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.submit.success,
        err => orderActions.submit.failure(err),
    ),
);
/**
 * Sets the plan id depending on the budget amount from quick order.
 */
const handleQuickOrder: Epic<
    PayloadAction<Landmark.QuickOrderRequest | Error>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.SetQuickOrderPlanId.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.QuickOrderRequest>) => LandmarkApiService
            .post("/order/quickOrder")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.setQuickOrderPlanId.success,
        orderActions.setQuickOrderPlanId.failure,
    ),
);

/**
 * Sets the property's state code from the browser's location information.
 */
const setOrderPropertyStateWhenBrowserLocationIsAvailable: Epic<
    Action | PayloadAction<LocationResponse>
> = (action$, state$) => action$.pipe(
    ofType(LocationActionTypes.Get.SUCCESS),
    withLatestFrom(state$),
    switchMap(([action, state]: [PayloadAction<LocationResponse>, ApplicationState]) => {
        const orderProperty = getOrderProperty(state);
        if (!orderProperty.address.state && action.payload.stateCode) {
            // The state hasn't been selected yet, let's auto-select select it from location.
            return of(orderActions.setValues({
                property: {
                    address: {
                        state: action.payload.stateCode
                    }
                }
            }));
        }
        return empty();
    }),
);

/**
 * Handles validating an applied coupon if the selected package changes.
 *
 * Before, the coupon was being cleared out when the package changed, but due to some business requirement changes,
 * it has become necessary to make sure an applied promo code isn't lost when selecting a package.
 *
 * If the promo code is not valid for the selected package, it will be cleared out. This will enforce promo codes can't be used
 * where they are not valid.
 */
const handleSetCouponAfterSelectOffer: Epic<Action> = (action$, state$) => action$.pipe(
    ofType(OrderActionTypes.SELECT_OFFER),
    withLatestFrom(state$),
    switchMap(([, state]) => {
        const { addons, planId, property } = getOrderRequest(state);
        const couponCode = getCurrentCouponCode(state);

        if (couponCode) {
            return of(orderActions.validateCouponCode.begin({
                coupon: {
                    couponCode
                },
                addons,
                planId,
                property,
            }));
        }

        // No action
        return empty();
    }),
);

/**
 * Updates the price breakdown when we see any actions that could affect price.
 */
const updatePriceBreakdown: Epic<PayloadAction<any>> = (action$, state$) => action$.pipe(
    ofType(
        OffersActionTypes.Load.SUCCESS,
        OrderActionTypes.ADD_ADDON,
        OrderActionTypes.ADD_BUYERS_CREDIT,
        OrderActionTypes.ADD_PLAN,
        OrderActionTypes.ADD_SERVICE_CALL_FEE,
        OrderActionTypes.REMOVE_AC,
        OrderActionTypes.REMOVE_PLAN,
        OrderActionTypes.REMOVE_QUOTE_ADDONS,
        OrderActionTypes.REMOVE_QUOTE_ITEM,
        OrderActionTypes.SELECT_OFFER,
        OrderActionTypes.SELECT_SERVICE_CALL_PRICE,
        OrderActionTypes.SetQuickOrderPlanId.SUCCESS,
        OrderActionTypes.ValidateCouponCode.SUCCESS,
    ),
    withLatestFrom(state$),
    map(([, state]: [Action, ApplicationState]) => {
        //Base order request defaults coverage length to 1 to get accurate yearly cost on field change or page refresh
        const orderRequest = getBaseOrderRequest(state);
        // Get the price breakdown
        return orderActions.getPriceBreakdown.begin(orderRequest);
    }),
);

const handleGetProfileToken: Epic<
    PayloadAction<Landmark.GetProfileTokenRequest | Error | string>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.GetProfileToken.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.GetProfileTokenRequest>) => LandmarkApiService
            .post(`/order/getCustomerProfileToken`)
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.getProfileToken.success,
        orderActions.getProfileToken.failure,
    ),
);

const handleGetCustomerPaymentProfile: Epic<
    PayloadAction<Landmark.GetProfileTokenRequest | Error | string>
> = action$ => action$.pipe(
    ofType(OrderActionTypes.GetCustomerPaymentProfile.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.GetProfileTokenRequest>) => LandmarkApiService
            .post(`/order/getCustomerPaymentProfile`)
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        orderActions.getCustomerPaymentProfile.success,
        orderActions.getCustomerPaymentProfile.failure,
    ),
);



const waitForGetPriceBreakdown = createWaitEpic(OrderActionTypes.GetPriceBreakdown, Areas.Order.PriceBreakdown);
const waitForLeadRegistration = createWaitEpic(OrderActionTypes.RegisterLead, Areas.Order.Lead);
const waitForSearchPartner = createWaitEpic(OrderActionTypes.SearchPartner, Areas.Order.PartnerSearch);
const waitForSubmit = createWaitEpic(OrderActionTypes.Submit);
const waitForQuickOrder = createWaitEpic(OrderActionTypes.SetQuickOrderPlanId);
const waitForGetProfileToken = createWaitEpic(OrderActionTypes.GetProfileToken);
const waitForGetCustomerPaymentProfile = createWaitEpic(OrderActionTypes.GetCustomerPaymentProfile);

const epic = combineEpics(
    handleGetPriceBreakdown,
    handleRegisterLead,
    handleSearchPartner,
    handleSearchExistingPartner,
    handleSearchExistingParent,
    handleSetCouponAfterSelectOffer,
    handleStartOrderOnRegisterLeadSuccess,
    handleSubmit,
    handleQuickOrder,
    setOrderPropertyStateWhenBrowserLocationIsAvailable,
    updatePriceBreakdown,
    waitForGetPriceBreakdown,
    waitForLeadRegistration,
    waitForSearchPartner,
    waitForSubmit,
    waitForQuickOrder,
    handleGetProfileToken,
    waitForGetProfileToken,
    handleGetCustomerPaymentProfile,
    waitForGetCustomerPaymentProfile
);
export default epic;
