import * as Landmark from "models/landmark-api";
import * as React from "react";
import Loadable from 'react-loadable';
import { RouteComponentProps } from "react-router";

import { createDialogActions, DialogActionCreators } from "../actions/dialog.actions";
import WaitComponent from "../components/WaitIndicator";
import { Meta } from "../components/Meta";
import { Header } from "../containers/Header";
import { HomePage } from "../containers/HomePage";
import { Areas } from "../constants/Areas";
import { getStore } from "../store/configure";
import { getAuthenticatedUser } from "../selectors/auth.selectors";
import { connect, bindActionCreatorsToProps } from "../store/componentBindings";

/**
 * All the async components that will allow code splitting.
 */
export const {
    AccountHeader,
    AccountPage,
    Contractors,
    OrderHeader,
    OrderPage,
    OrderSubmittedPage
} = getLoadableComponents();


/**
 * Props used by the RenderAccount wrapper component.
 *
 * @interface RenderAccountProps
 * @extends {RouteComponentProps<any>}
 */
interface RenderAccountProps extends RouteComponentProps<any> {
    store?: { user: Landmark.User; };
    actions?: { loginDialog: DialogActionCreators };

    /**
     * Flag for whether to render the header or the page. The wrapper is used for both components.
     *
     * @type {boolean}
     * @memberof RenderAccountProps
     */
    isHeader?: boolean;
}

/**
 * Special wrapper component that will route to either the account page or the homepage and launch the login dialog, depending on the state of the store.
 *
 * @class RenderAccount
 * @extends {React.Component<RenderAccountProps>}
 */
@connect(
    state => ({
        store: {
            user: getAuthenticatedUser(state)
        }
    }),
    bindActionCreatorsToProps({
        loginDialog: createDialogActions(Areas.Account.Login)
    })
)
export class RenderAccount extends React.Component<RenderAccountProps> {
    /**
     * Gets the actual props that are passed to the child.
     *
     * @readonly
     * @memberof RenderAccount
     */
    get componentProps() {
        const { isHeader, actions, store, ...props } = this.props;
        return props;
    }

    /**
     * Gets whether the url path is /account or /claim
     *
     * @readonly
     * @memberof RenderAccount
     */
    get isClaim() {
        const { pathname } = this.props.location;

        return pathname.includes("/claim");
    }

    /**
     * Gets the metadata content for the title of this page.
     *
     * @readonly
     * @memberof RenderAccount
     */
    get title() {
        return this.isClaim ?
            "Submit a Home Warranty Claim" :
            "";
    }

    /**
     * Gets the metadata content for the description of this page.
     *
     * @readonly
     * @memberof RenderAccount
     */
    get description() {
        return this.isClaim ?
            "Log into your Landmark Home Warranty account to request service on a covered system or appliance in your home." :
            "Log into your Landmark Home Warranty account to request service, view past claims, and renew your home warranty plan.";
    }

    /**
     * Launches the login dialog if the user is not logged in.
     *
     * @memberof RenderAccount
     */
    componentDidMount() {
        // Wait until we know that all the initialization has happened before showing dialog.
        window.onload = () => {
            if (!this.props.store.user || !this.props.store.user.userId) {
                this.props.actions.loginDialog.open();
            }
        };
    }

    /**
     * Gets the correct header based on the current state.
     *
     * @returns Account header if the user is logged in, or header if not.
     * @memberof RenderAccount
     */
    getHeader() {
        const { user } = this.props.store;
        return user && user.userId ? <AccountHeader {...this.componentProps} /> : <Header {...this.componentProps} />;
    }

    /**
     * Gets the correct page based on the current state.
     *
     * @returns
     * @memberof RenderAccount
     */
    getPage() {
        const { user } = this.props.store;
        const { pathname } = this.props.location;

        return <React.Fragment>
            <Meta canonical={pathname} title={this.title} description={this.description}/>
            { user && user.userId ? <AccountPage {...this.componentProps} /> : <HomePage {...this.componentProps} /> }
        </React.Fragment>;
    }

    /**
     * Renders either the header component or the page component for an account route.
     *
     * @memberof RenderAccount
     */
    render() {
        const { isHeader } = this.props;
        return isHeader ? this.getHeader() : this.getPage();
    }
}

/**
 * Gets a collection of components that will be lazy loaded to help with code splitting.
 *
 * @returns Collection of lazy loaded components
 */
function getLoadableComponents() {
    return {
        AccountHeader: Loadable({
            loader: async () => (
                await import("../containers/AccountPage/AccountHeader")
            ).AccountHeader,
            loading: WaitComponent
        } as Loadable.Options<any, any>),
        AccountPage: Loadable({
            loader: async () => {
                const { reducer: formReducer } = await import("redux-form");
                getStore().injectAsyncReducer("form", formReducer);

                return (await import("../containers/AccountPage/AccountPage")).AccountPage;
            },
            loading: WaitComponent
        } as Loadable.Options<any, any>),
        Contractors: Loadable({
            loader: async () => (
                await import("../containers/ContractorsPage")
            ).ContractorsPage,
            loading: WaitComponent
        } as Loadable.Options<any, any>),
        OrderHeader: Loadable({
            loader: async () => (
                await import("../containers/OrderPage/OrderHeader")
            ).OrderHeader,
            loading: WaitComponent
        } as Loadable.Options<any, any>),
        OrderPage: Loadable({
            loader: async () => (
                await import("../containers/OrderPage/OrderPage")
            ).OrderPage,
            loading: WaitComponent
        } as Loadable.Options<any, any>),
        OrderSubmittedPage: Loadable({
            loader: async () => (
                await import("../containers/OrderPage/OrderSubmittedPage")
            ).OrderSubmittedPage,
            loading: WaitComponent
        } as Loadable.Options<any, any>),
    };
}
