import { actions as toastrActions, AddToastPayload } from "react-redux-toastr";
import { Epic, ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";

/**
 * Creates an epic that displayed a toastr message when the given action occurs.
 * @param actionType The action that triggers the toastr message.
 * @param type The toastr message type.
 * @param title The title of the toastr message.
 * @param message The contents of the toastr message.
 * @param options toastr options.
 */
export const createToastrEpic = <TPayloadType, TPayloadAction extends PayloadAction<TPayloadType>>(
    actionType: any[],
    fn: (action: TPayloadAction) => AddToastPayload
): Epic<any> => action$ => action$.pipe(
    ofType(...actionType),
    map(action => toastrActions.add(fn(action))),
);
