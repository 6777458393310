import { RouteComponentProps } from "react-router";

/**
 * Scroll to the element indicated in the URL hash.
 * i.e. With a location of, https://landmarkhw.com/homeowner-warranty#whylandmark
 * this will attempt to scroll to the element with id "whylandmark".
 * @returns True if scroll was successful, false otherwise.
 */
export function scrollToLocationHash(props: Partial<RouteComponentProps<any>>) {
    if (props.location.hash && props.location.hash.length) {
        const id = props.location.hash.substr(1);
        return scrollTo(id);
    }
    return null;
}

/**
 * Scroll to the element with the given id.
 * @param {string} id The id of the element to scroll to.
 * @returns True if scroll was successful, false otherwise.
 */
export function scrollTo(id: string) {
    const elm = document.getElementById(id);
    if (elm && elm.scrollIntoView) {
        elm.scrollIntoView(true);
        return true;
    }
    return false;
}
