import * as React from "react";

import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";

interface Props {
    actions?: {
        router: RouterActionCreators;
    };
}
const styles = {
    size: {
        fontSize: "21px",
    },
    Arrow: {
        fontSize: "16px",
        paddingLeft: "10px",
    }
};

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class Benefits extends React.Component<Props, any> {
    render() {
        return (
            <section id="benefits" className="section-benefits">
                <div className="container">
                    <div className="row">
                        <div className="column half">
                            <h1>Landmark Helps Realtors Sell!</h1>
                            <p>At Landmark, we work closely with real estate professionals. That's why we provide hundreds of free articles that you can use to educate your clients.
                                Find articles on the real estate process, simple home maintenance tips, and how to best utilize a home warranty plan with our resources.</p>
                            <a className="btn"
                                href="/resources"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.actions.router.push("/resources");
                                    window.scrollTo(0, 0);
                                }}>Get Started <i data-icon="j" /></a>
                        </div>
                        <div className="column half">
                            <div className="fluid-video">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/V-_K9Zv_ZDQ?rel=0&showinfo=0&modestbranding=1&iv_load_polcy=3" frameBorder="0" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="column third">
                            <a
                                href="/resources/buying-selling-house"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.actions.router.push("/resources/buying-selling-house");
                                }}
                            ><h4 style={styles.size}>Buying and Selling a Home<i style={styles.Arrow} data-icon="j"></i></h4></a>
                            <p className="small">Start with the basics. Use these articles to help educate your clients about the entire process of buying and selling their home.  </p>
                        </div>
                        <div className="column third">
                            <a
                                href="/resources/home-warranty-education"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.actions.router.push("/resources/home-warranty-education");
                                }}
                            ><h4 style={styles.size}>Home Warranty Education<i style={styles.Arrow} data-icon="j"></i></h4></a>
                            <p className="small">Do your clients have questions about home warranties? We have articles that will help explain what a home warranty is, what’s covered and how
                                they can best use it to help protect their systems and appliances.</p>
                        </div>
                        <div className="column third">
                            <a
                                href="/resources/home-repairs-maintenance"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.actions.router.push("/resources/home-repairs-maintenance");
                                }}><h4 style={styles.size}>Home Maintenance <i style={styles.Arrow} data-icon="j"></i></h4></a>
                            <p className="small">
                                Landmark Home Warranty provides useful articles related to home maintenance, do-it-yourself repairs, and how the different systems and appliances function in
                                your clients’ homes. Sharing these articles can help build value and relationships with your clients.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
