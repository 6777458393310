import * as React from "react";
import Modal from "react-modal";

import * as Styles from "../styles";

interface Props {
    errors: any;
    isOpen: boolean;
    onRequestClose: () => void;
}

interface State {
    /**
     * Flag representing whether this currently being rendered on client vs server.
     *
     * @type {boolean}
     * @memberof State
     */
    isClientSide?: boolean;
}

const styles = {
    error: {
        color: "#E21E1E",
        textAlign: "center",
    } as React.CSSProperties
};

/**
 * A modal for displaying collected validation results.
 */
export class ValidationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isClientSide: false
        };
    }

    /**
     * This function is only ran on client side, so we can no for certain that client side rendered components are ready to render after this function is called.
     *
     * @memberof Footer
     */
    componentDidMount() {
        document.addEventListener("keydown", this.handleEnterKeyPressed);

        this.setState({ isClientSide: true });
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleEnterKeyPressed);
    }

    handleEnterKeyPressed = (e) => {
        if (this.props.isOpen && e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            window.requestAnimationFrame(this.handleRequestClose);
        }
    }

    /**
     * Moves focus to the first field that failed validation.
     */
    focusFirstErrorField = () => {
        var errorFields = Array.from(document.querySelectorAll("input[class*=error], select[class*=error]")) as HTMLElement[];
        if (errorFields.length > 0) {
            errorFields[0].focus();
        }
    }

    handleRequestClose = () => {
        this.props.onRequestClose();
        window.requestAnimationFrame(() => this.focusFirstErrorField());
    }

    render() {
        const results = Object
            .keys(this.props.errors)
            .map(key => this.props.errors[key]);

        // Only want to return the modal if we have errors.
        // Noticed that the Oops will pop up when length is 0.
        if (results.length > 0){
            return (
                this.state.isClientSide ?
                    <Modal
                        contentLabel=""
                        isOpen={this.props.isOpen}
                        onRequestClose={this.handleRequestClose}
                        style={Styles.Modal.AutoSized}>
                        <div style={Styles.Modal.AutoSized.close} onClick={this.props.onRequestClose}>l</div>
                        <div>
                            <h4 style={{ fontSize: "34px", marginTop: 0, paddingTop: 0, marginRight: 30 }}>Oops...we need a bit more information!</h4>
                            {results.map((message, i) => (
                                <div style={styles.error} key={i}>{message}</div>
                            ))}
                        </div>
                    </Modal> :
                    <div />
            );
        }
        return null;
    }
}
