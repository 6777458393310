import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { appId, AsyncAction, AsyncActionCreators, createAsyncActions } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const ForumActionTypes = {
    LoadSummaries: AsyncAction(`forum/summaries/load`),
    Load: AsyncAction(`forum/load`),
    CLEAR: `${appId}/forum/CLEAR`,
    Subscribe: AsyncAction(`forum/subscribe`),
};
Object.freeze(ForumActionTypes);

export function createForumActions(): ForumActionCreators {
    return {
        clear: () => ({ type: ForumActionTypes.CLEAR }),
        loadSummaries: createAsyncActions(ForumActionTypes.LoadSummaries),
        load: createAsyncActions(ForumActionTypes.Load),
        subscribe: createAsyncActions(ForumActionTypes.Subscribe),
    };
}

export interface ForumActionCreators extends ActionCreatorMap {
    /**
     * Clears the comments that have been loaded for the selected month.
     */
    clear: () => Action;

    /**
     * Load summaries for the forum.
     */
    loadSummaries: AsyncActionCreators<Landmark.ForumRequest, Landmark.ForumResponse>;

    /**
     * Loads a forum entry.
     */
    load: AsyncActionCreators<number, Landmark.ForumResponse>;

    /**
     * Subscribes to a particular forum.
     */
    subscribe: AsyncActionCreators<string, string>;
}
