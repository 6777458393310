import update from "immutability-helper";

import { ConfirmationActionTypes } from "../actions/confirmation.actions";

export interface ConfirmationState {
    isOpen: boolean;
    content: string;
    title: string;
}

const defaultState: ConfirmationState = {
    isOpen: false,
    content: null,
    title: null,
};

export function ConfirmationReducer(
    state = defaultState,
    action
) {
    switch (action.type) {
        case ConfirmationActionTypes.SHOW:
            return update(state, {
                $set: {
                    isOpen: true,
                    title: action.payload.title,
                    content: action.payload.content,
                }
            });
        case ConfirmationActionTypes.CONFIRM:
        case ConfirmationActionTypes.CANCEL:
            return defaultState;
    }

    return state;
}
