import { createRequestQuoteFormik, RequestQuoteFormBase } from 'components/forms/request-quote-form';
import * as React from 'react';

class Form extends RequestQuoteFormBase {
    /**
     * Collection of styles used by this component.
     *
     * @readonly
     * @memberof SocialShare
     */
    get styles() {
        return {
            /** Container Styles **/
            containers: {
                columnFlex: {
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                } as React.CSSProperties,
                formInput: {
                    flex: "1 1 auto",
                    fontWeight: "bold",
                    width: "100%",
                    textTransform: "uppercase",
                    textAlign: "left" // IE and Edge don't support start :(
                } as React.CSSProperties,

                /** Style of the form is a Flexbox column but with some additional properties **/
                get form() {
                    return {
                        ...this.columnFlex,
                        alignItems: "start",
                        flex: "1 1 auto",
                        width: "100%",
                    } as React.CSSProperties;
                }
            },
        };
    }

    /**
     * Renders the request quote form for the social panel.
     *
     * @memberof Form
     */
    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit} className="social-share-form">
                <div style={this.styles.containers.columnFlex}>
                    <div className="title">Get A Free Quote!</div>
                    <div style={this.styles.containers.form}>
                        <div style={this.styles.containers.formInput}>
                            <label>Name</label>
                            {this.components.contactName}
                        </div>
                        <div style={this.styles.containers.formInput}>
                            <label>Email</label>
                            {this.components.email}
                        </div>
                        <div style={this.styles.containers.formInput}>
                            <label>Address</label>
                            {this.components.address}
                        </div>
                        <div style={this.styles.containers.formInput}>
                            <label>Phone Number</label>
                            {this.components.phone}
                        </div>
                        <div style={this.styles.containers.formInput}>
                            <label>Zip Code</label>
                            {this.components.zip}
                        </div>
                        <div style={this.styles.containers.formInput}>
                            <label className="subtitle">Did you purchase the home in the last 30 days?</label>
                            {this.components.orderType}
                        </div>
                    </div>
                    {
                        // Update the button content to be Request Quote>>
                        this.components.submitButton(
                            <React.Fragment>Request Quote<i data-icon="jj"></i></React.Fragment>
                        )
                    }
                </div>
                {this.components.validationModal}
            </form>
        );
    }
}

/**
 * Wraps the form with the formik functionality for requesting a quote.
 */
export const SocialShareForm = createRequestQuoteFormik(Form);
