import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { LoadContractorStatisticsPayload } from "./contractor/dispatchStatistics.actions";
import { ActionCreatorMap } from "../store/componentBindings";
import { AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "./defs";

export const CrrActionTypes = {
    loadDispatchStatistics: AsyncAction(`crr/dispatchStatistics/load`),
    loadContractorDispatchStatistics: AsyncAction(`crr/dispatchStatistics/contractor/load`),
    RESET_CONTRACTOR_STATISTICS: `crr/RESET_CONTRACTOR_STATISTICS`,
    SET_SELECTED_YEAR: `crr/SET_SELECTED_YEAR`,
};
Object.freeze(CrrActionTypes);

export interface LoadContractorRelationsStatisticsPayload {
    username: string;
    year: number;
}

export interface CrrActionCreators extends ActionCreatorMap {
    /**
     * Loads CRR dispatch statistics.
     */
    loadDispatchStatistics: AsyncActionCreators<LoadContractorRelationsStatisticsPayload, Landmark.DispatchStatisticsResponse>;

    /**
     * Loads Dispatch statistics for a specific contractor for the CRR view.
     */
    loadContractorDispatchStatistics: AsyncActionCreators<LoadContractorStatisticsPayload, Landmark.DispatchStatisticsResponse>;


    resetContractorStatistics: () => Action;

    /**
     * sets the selected year for the CRR view.
     */
    setSelectedYear: (selectedYear: number) => PayloadAction<number>;
}

export function createCrrActions() {
    return {
        loadDispatchStatistics: createAsyncActions(CrrActionTypes.loadDispatchStatistics),
        loadContractorDispatchStatistics: createAsyncActions(CrrActionTypes.loadContractorDispatchStatistics),
        resetContractorStatistics: () => ({ type: CrrActionTypes.RESET_CONTRACTOR_STATISTICS }),
        setSelectedYear: (selectedYear: number) => ({ type: CrrActionTypes.SET_SELECTED_YEAR, payload: selectedYear})
    } as CrrActionCreators;
}
