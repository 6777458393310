import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { InvoiceActionTypes } from "../../actions/account/invoice.actions";

export interface InvoiceState {
    invoice: Landmark.ContractorDispatchSearchResponseItem;
    response: Landmark.ContractorDispatchSearchResponse;
}

const defaultState: InvoiceState = {
    invoice: null,
    response: null,
};

export function InvoiceReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.ContractorDispatchSearchResponse
        >,
) {
    switch(action.type) {
        case InvoiceActionTypes.Search.SUCCESS: {
            const dispatchResults = (action as PayloadAction<Landmark.ContractorDispatchSearchResponse>).payload;

            if (dispatchResults.page > 0) {
                dispatchResults.items = [...state.response.items, ...dispatchResults.items];
            }

            return update(state, {
                response: { $set: dispatchResults }
            });
        }
    }

    return state;
}
