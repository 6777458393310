import { ApplicationState } from "../store/app";
import { createSelector } from "./common.selectors";

/**
 * Get all property types.
 */
export const getPropertyTypes = createSelector(
    (state: ApplicationState) => state.reference,
    reference => reference.propertyTypes
);

/**
 * Get all possible trades.
 */
export const getTrades = createSelector(
    (state: ApplicationState) => state.reference,
    reference => reference.trades
);

/**
 * Gets all states in the country.
 */
export const getStates = (state: ApplicationState, { showAllStates = true  } = {} ) => createSelector(
    (state: ApplicationState) => {
        if (state.reference && state.reference.states) {
            if (showAllStates) {
                // Return all states
                return state.reference.states;
            }

            // Only return states that are within the market area
            return state.reference.states.filter(s => s.isMarketState);
        }
        return [];
    },
    states => states
)(state);
