import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../../actions/defs";
import { AccountNoticesActionTypes } from "../../actions/account/notices.actions";

export interface NoticesState {
    list: Landmark.AccountNotice[];
}

const defaultState: NoticesState = {
    list: []
};

export const NoticesReducer = (
    state = defaultState,
    action:
        any |
        Action |
        PayloadAction<string | number | Landmark.NoticesResponse | void>
) => {
    switch (action.type) {
        case AccountNoticesActionTypes.Load.SUCCESS: {
            const noticesResponse = (action as PayloadAction<Landmark.NoticesResponse>).payload;
            return update(state, {
                list: { $set: noticesResponse.result.notices }
            });
        }

        //TODO: These need to be persisted in the database somewhere.
        case AccountNoticesActionTypes.DISMISS: {
            // Update a single item, set their 'isDismissed' property to true.
            const id = action.id;
            const index = state.list.findIndex(item => item.id === id);
            if (index >= 0) {
                return update(state, {
                    list: {
                        [index]: {
                            isDismissed: { $set: true }
                        }
                    }
                });
            }
        }
        case AccountNoticesActionTypes.DISMISS_ALL: {
            // Update each item, set their 'isDismissed' property to true.
            return update(state, {
                list: { $apply:
                    (list: Landmark.AccountNotice[]) => list.map(
                        item => update(item, {
                                    isDismissed: { $set: true }
                                }
                            )
                        )
                    }
                }
            );
        }
    }

    // If nothing changed, return the original state
    return state;
};
