import * as Landmark from "models/landmark-api";

import { AsyncAction, AsyncActionCreators, createAsyncActions, appId, PayloadAction } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const AccountOrdersActionTypes = {
    Cancel: AsyncAction(`account/order`),
    Complete: AsyncAction(`account/order/complete`),
    Load: AsyncAction(`account/order/load`),
    SelectOrder: `${appId}/account/order/SELECT`,
};

export function createAccountOrdersActions(): AccountOrdersActionCreators {
    return {
        cancel: createAsyncActions(AccountOrdersActionTypes.Cancel),
        complete: createAsyncActions(AccountOrdersActionTypes.Complete),
        load: createAsyncActions(AccountOrdersActionTypes.Load),
        selectOrder: (orderId: string) => {
            return {
                type: AccountOrdersActionTypes.SelectOrder,
                payload: orderId,
            };
        },
    };
}

export interface AccountOrdersActionCreators extends ActionCreatorMap {
    /**
     * Cancels an order, given an order number.
     */
    cancel: AsyncActionCreators<string, Landmark.OrderResult>;

    /**
     * Completing and un-paid order
     */
    complete:  AsyncActionCreators<Landmark.CompleteOrderRequest, Landmark.CompleteOrderResponse>;

    /**
     * Loads partner's order information.
     */
    load: AsyncActionCreators<void, Landmark.ContractResponse>;

    /**
     *  Sets the selected contract for viewing coverage.
     */
    selectOrder: (orderId: string) => PayloadAction<string>;
}
