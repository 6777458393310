import * as React from "react";

import { OfferOptions } from "./OfferOptions";
import { OfferPackages } from "./OfferPackages";

interface Props {
    isMonthlyPrice: boolean;
    planTypeId: string;
    onOrder?: (contractOfferId: string) => void;
}

export class OfferDetails extends React.Component<Props, any> {
    renderOrderOptions = () => {
        return (
            <OfferOptions
                isMonthlyPrice={this.props.isMonthlyPrice} />
        );
    }

    render() {
        return (
            <section id="planspricing" className="section-plans">
                <OfferPackages
                    isMonthlyPrice={this.props.isMonthlyPrice}
                    planTypeId={this.props.planTypeId}
                    onOrder={this.props.onOrder}
                />
                {this.renderOrderOptions()}
            </section>
        );
    }
}
