import * as React from "react";
import Scroll from "react-scroll";

import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";

interface Props {
    actions?: {
        router: RouterActionCreators;
    };
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class HomeownerSubNavBar extends React.Component<Props, any> {

    handlePlansPricingClick = (e) => {
        let scroller = Scroll.scroller;
        e.preventDefault();
        this.props.actions.router.push(`/home-warranty-plans`);
        scroller.scrollTo("home-warranty-plans", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    handleWhyClick = (e) => {
        let scroller = Scroll.scroller;
        e.preventDefault();
        this.props.actions.router.push(`/homeowner-warranty/why-landmark`);
        scroller.scrollTo("whylandmark", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    handleReviewsClick = (e) => {
        let scroller = Scroll.scroller;
        e.preventDefault();
        this.props.actions.router.push(`/homeowner-warranty#reviews`);
        scroller.scrollTo("reviews", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    render() {
        return (
            <section className="section-subnav">
                <div className="container">
                    <ul className="homeowner">
                        <li ><a className="btn btn-subnav" href="/home-warranty-plans" onClick={e => this.handlePlansPricingClick(e)} >Compare Home Warranty Plans<i data-icon="j"></i></a></li>
                        <li><a className="btn btn-subnav" href="/homeowner-warranty/why-landmark" onClick={e => this.handleWhyClick(e)}>Why Choose Landmark?<i data-icon="j"></i></a></li>
                        <li><a className="btn btn-subnav" href="/homeowner-warranty/reviews" onClick={e => this.handleReviewsClick(e)}>Homeowner Reviews<i data-icon="j"></i></a></li>
                    </ul>
                </div>
            </section>
        );
    }
}
