export const Base = {
        black: "#202227",
        white: "#FFFFFF",
};

export const App = {
        blue: "#1F506F",
        blueDark: "#113E5B",
        teal: "#95CED7",
        tealDark: "#7AB9C3",
        orange: "#F2693E",
        orangeLight: "#F5A97D",
        orangeDark: "#D45028",
        gray: "#808285",
        grayDark: "#696D72",
};

export const Grays = {
        grayZero: "#FBFBFB",
        grayOne: "#DDDEDE",
        grayTwo: "#58585B",
};

export const Misc = {
        facebook: "#39579A",
        twitter: "#69ACE0",
        instagram: "#E9E2D6",
        google: "#DF4B38",
};

export const Validation = {
        green: "#43CB83",
        red: "#E21E1E",
        yellow: "#EBDE41",
};

export const Plans = {
        standard: "$orange",
        premier: "#C89C1F",
        value: "$green",
        deluxe: "#4E486E",
};
