import * as Landmark from "models/landmark-api";

import { AsyncActionCreators, createAsyncActions, AsyncAction, PayloadAction, appId } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const ExecutiveActionTypes = {
    Load: AsyncAction(`executives/load`),
    SELECT: `${appId}/executives/SELECT`,
};
Object.freeze(ExecutiveActionTypes);

export interface ExecutiveActionCreators extends ActionCreatorMap {
    /**
     * Loads a list of executives.
     */
    load: AsyncActionCreators<void, Landmark.Executive[]>;

    /**
     * Selects an executive.
     */
    select: (id: any) => PayloadAction<any>;
}

export function createExecutiveActions() {
    return {
        load: createAsyncActions(ExecutiveActionTypes.Load),
        select: (id: any) => ({
            type: ExecutiveActionTypes.SELECT,
            payload: id
        }),
    } as ExecutiveActionCreators;
}
