import * as Landmark from "models/landmark-api";
import update from "immutability-helper";
import { Action } from "redux";

import { PayloadAction } from "actions/defs";
import { AwardsActionTypes } from "actions/awards.actions";

export interface AwardsState {
    awards: Landmark.Award[];
}

const defaultState: AwardsState = {
    awards: [],
};

export function AwardReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<Landmark.Award[]>
) {
    switch (action.type) {
        case AwardsActionTypes.LoadAwards.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.Award[]>;
            return update(state, {
                awards: { $set: loadAction.payload }
            });
        }
        default: break;
    }

    // If nothing changed, return the original state
    return state;
}
