import * as Landmark from "models/landmark-api";

import { appId, AsyncActionCreators, AsyncAction, createAsyncActions, PayloadAction } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const ContractActionTypes = {
    Load: AsyncAction(`account/contracts/load`),
    LoadCoverages: AsyncAction(`account/contracts/coverages/load`),
    SELECT: `${appId}/account/contracts/SELECT`,
};

export interface ContractsActionCreators extends ActionCreatorMap {

    /**
     * Load contracts from the server for a given user ID.
     */
    load: AsyncActionCreators<string, Landmark.ContractResponse>;

    /**
     * Load coverages for a contract given a contract ID.
     */
    loadCoverages: AsyncActionCreators<string, Landmark.ContractCoverage[]>;

    /**
     *  Sets the selected contract for viewing coverage.
     */
    select: (contractId: string) => PayloadAction<string>;
}

export function createContractsActions() {
    return {
        load: createAsyncActions(ContractActionTypes.Load),
        loadCoverages: createAsyncActions(ContractActionTypes.LoadCoverages),
        select: (contractId: string) => ({
            type: ContractActionTypes.SELECT,
            payload: contractId,
        }),
    } as ContractsActionCreators;
}
