import * as React from "react";

const styles = {
    container: {
        textAlign: "center",
        maxHeight: "70px",
        display: "inline-block",
        position: "relative",
    } as React.CSSProperties,
    iconDiv: {
        width: "100%",
        maxHeight: "50px",
        textAlign: "center",
    } as React.CSSProperties,
    label: {
        maxHeight: "20px",
    } as React.CSSProperties,
    moreItem: {
        borderTop: "1px solid #DDDEDE",
        maxHeight: "40px",
    },
    a: (style) => Object.assign(
            {},
            {
                maxHeight: "50px",
                textAlign: "center",
                float: "none",
                margin: "auto",
            },
            style
        )
};

export interface Props {
    className?: string;
    fontIcon: string;
    iconColor?: string;
    id?: string;
    label?: string;
    labelColor?: string;
    labelShort?: string;
    onClick?: React.EventHandler<React.MouseEvent<EventTarget>>;
    orientation?: "vertical" | "horizontal";
    style?: React.CSSProperties;
}

export class MobileHeaderButton extends React.Component<Props, any>{
    render() {
        if (this.props.orientation === "horizontal") {
            return (
                <div className={`${this.props.className ? this.props.className : ""} row`} style={styles.moreItem} onClick={this.props.onClick}>
                    <span style={{ display: "inline-block", float: "right", textAlign: "center", width: "20px" }} data-icon={this.props.fontIcon} />
                    <span style={{ float: "right", margin: "0px 20px" }} className="scaling-font-size">{this.props.label}</span>
                </div>
            );
        }
        else {
            return (
                <div
                    className={`${this.props.className ? this.props.className : ""} mobile-header-width`}
                    style={styles.container}
                    onClick={this.props.onClick}>
                    <div style={styles.iconDiv}>
                        <div style={styles.a({ color: this.props.iconColor })} data-icon={this.props.fontIcon} />
                    </div>
                    <p style={styles.label} className="scaling-font-size">{this.props.labelShort || this.props.label}</p>
                </div>
            );
        }
    }
}
