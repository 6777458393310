import * as Landmark from "models/landmark-api";

import { createSelector } from "./common.selectors";
import { ApplicationState } from "../store/app";

const getDispatchResponse = (state: ApplicationState) => state.account.dispatch.response;
const getDispatchState = (state: ApplicationState) => state.account.dispatch;

/**
 * Get a list of loaded dispatch items.
 */
export const getDispatchItems = createSelector(
    getDispatchResponse,
    response => (response && response.items) || [],
);

/**
 * Gets the total number of dispatch items.
 */
export const getTotalDispatchItems = createSelector(
    getDispatchResponse,
    response => (response && response.totalItems) || 0,
);

/**
 * Creates a selector that gets the selected dispatch.
 */
export const getSelectedDispatch = createSelector(
    getDispatchState,
    getDispatchItems,
    (dispatch, items) => {
        return items.find(item => item.dispatchId === dispatch.selectedDispatchId);
    }
);

/**
 * Get selected dispatch parts.
 */
export const getSelectedDispatchParts = createSelector(
    getSelectedDispatch,
    dispatchItem => dispatchItem && dispatchItem.dispatchParts,
);

/**
 * Creates a selector that gets the current dispatch status filter.
 */
export const getStatusFilter = createSelector(
    (state: ApplicationState) => state.account.dispatch,
    dispatch => dispatch.response && dispatch.response.contractorStatus,
);

/**
 * Gets the total amount of the contractor parts.
 */
export const getContractorPartsTotal = createSelector(
    getSelectedDispatchParts,
    (parts: Landmark.DispatchPart[]): number => {
        if (!parts) {
            return 0;
        }
        return parts
            .filter(({ partDetails }) => partDetails.isProvidedByContractor)
            .reduce(
                (sum, { partDetails }) =>
                    sum + ((partDetails.pricePerUnit * partDetails.units) ||
                    0),
                0
            );
    }
);

/**
 * Gets the total landmark amount for parts for a dispatch ticket.
 */
export const getLandmarkPartsTotal = createSelector(
    getSelectedDispatch,
    (dispatchItem: Landmark.ContractorDispatchSearchResponseItem): number => {
        return dispatchItem.purchaseOrderCost || 0;
    }
);

/**
 * Gets the contractor tax total.
 */
export const getContractorTaxTotal = createSelector(
    getSelectedDispatchParts,
    (parts: Landmark.DispatchPart[]): number => {
        if (!parts) {
            return 0;
        }
        return parts
            .filter(({ partDetails }) => partDetails.isProvidedByContractor)
            .reduce(
                (sum, { partDetails }) =>
                    sum + ((partDetails.taxPerUnit * partDetails.units) ||
                    0),
                0
            );
    }
);

/**
 * Gets the labor total.
 */
export const getLaborTotal = createSelector(
    getSelectedDispatchParts,
    (parts: Landmark.DispatchPart[]): number => {
        if (!parts) {
            return 0;
        }
        return parts
            .filter(({ partDetails }) => partDetails && partDetails.closingItem && partDetails.closingItem.name.match(/^Labor \(.*\)$/))
            .reduce(
                (sum, { partDetails }) =>
                    sum + ((partDetails.costPerHour * partDetails.hoursWorked) ||
                    0),
                0
            );
    }
);

/**
 * Gets the total billable amount of a dispatch ticket.
 */
export const getTotalBillable = createSelector(
    getSelectedDispatch,
    (dispatchItem: Landmark.ContractorDispatchSearchResponseItem): number => {
        if (dispatchItem.dispatchParts) {
            const serviceCallFeeAmountCollected = dispatchItem.serviceCallFeeCollectedAmount || 0;
            const discount = dispatchItem.discountAmount || 0;
            const laborAndParts = dispatchItem
                .dispatchParts
                .reduce(
                    (sum, { partDetails }) => {
                        let partCost = 0;

                        if (partDetails.isProvidedByContractor) {
                            // Contractors only bill us for parts provided by them
                            partCost = ((partDetails.pricePerUnit + partDetails.taxPerUnit) * partDetails.units) || 0;
                        }
                        return sum + partCost;
                    },
                    0
                );
            return Math.max(laborAndParts - discount - serviceCallFeeAmountCollected, 0);
        }
        return 0;
    }
);

/**
 * Gets the total cost of a dispatch ticket.
 */
export const getTotalCost = createSelector(
    getSelectedDispatch,
    getLandmarkPartsTotal,
    (dispatchItem: Landmark.ContractorDispatchSearchResponseItem, landmarkPartsTotal: number): number => {
        if (dispatchItem.dispatchParts) {
            const discount = dispatchItem.discountAmount || 0;
            const laborAndParts = dispatchItem
                .dispatchParts
                .reduce(
                    (sum, { partDetails }) => {
                        const partCost = ((partDetails.pricePerUnit + partDetails.taxPerUnit) * partDetails.units) || 0;
                        return sum + partCost;
                    },
                    0
                );
            const tax = dispatchItem.tax || 0;
            const shipping = dispatchItem.shipping || 0;
            return Math.max(laborAndParts + tax + shipping + landmarkPartsTotal - discount, 0);
        }
        return 0;
    }
);
