import * as Landmark from "models/landmark-api";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import Scroll from "react-scroll";

import { ProtectYourHome } from "./ProtectYourHome";
import { HomeownerSubNavBar } from "../HomeownerPage/HomeownerSubNavBar";
import { WhyChooseLandmark } from "../HomeownerPage/WhyChooseLandmark";
import { createOrderActions, OrderActionCreators } from "../../actions/order.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { Footer } from "../../components/Footer";
import { Meta } from "../../components/Meta";
import { OfferDetails } from "../../components/offers/OfferDetails";
import { BasicRegistration } from "../../components/sections/BasicRegistration";
import { Reviews } from "../../components/sections/Reviews";
import { PlanType, PlanTypeName } from "../../constants/TransactionType";
import { getOffers } from "../../selectors/offers.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { scrollToLocationHash } from "../../utils/scroll";
import { LimitsAndExclusions } from "../../components/LimitsAndExclusions";
import { createDialogActions, DialogActionCreators } from "../../actions/dialog.actions";
import { createLimitsAndExclusionsActions, LimitsAndExclusionsActionCreators } from "../../actions/limitsAndExclusions.actions";
import { Areas } from "../../constants/Areas";
import { createGetIsDialogOpen } from "../../selectors/dialog.selectors";

interface Props extends RouteComponentProps<any> {
    type?: "customer";
    store: {
        isLimitsAndExclusionsOpen: boolean;
        offers: Landmark.ContractOffer[];
    };

    actions: {
        limitsAndExclusionsActions: DialogActionCreators;
        limitsAndExclusions: LimitsAndExclusionsActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
    };
}

@connect(
    // Items added to `this.props.store`
    state => ({
        store: {
            isLimitsAndExclusionsOpen: createGetIsDialogOpen(Areas.LimitsAndExclusions.Details)(state),
            offers: getOffers(state),
        }
    }),
    bindActionCreatorsToProps({
        limitsAndExclusionsActions: createDialogActions(Areas.LimitsAndExclusions.Details),
        limitsAndExclusions: createLimitsAndExclusionsActions(),
        order: createOrderActions(),
        router: createRouterActions()
    })
)
export class HomeownerPage extends React.Component<Props, any> {
    componentDidMount() {
        let scroller = Scroll.animateScroll;
        if (!this.props.location.hash.length) {
            scroller.scrollToTop();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.location.hash.length &&
            prevProps.location.hash !== this.props.location.hash
        ) {
            scrollToLocationHash(this.props);
        }
    }

    /**
     * Gets the meta data for the page based on the current has value of the current url.
     *
     * @memberof HomeownerPage
     */
    getMetaData = () => {
        const { hash } = this.props.location || {} as Location;

        switch(hash.toLowerCase().trim()) {
            case "#home-warranty-plans":
                return (
                    <Meta
                        title="Home Warranty Plans and Coverage | Landmark Home Warranty"
                        description="Compare coverage on Landmark Home Warranty's plans and see our affordable monthly costs for homes in Arizona, Idaho, Nevada, Oregon, Texas, and Utah."
                        canonical="/home-warranty-plans"
                    />
                );
            case "#whylandmark":
                return (
                    <Meta
                        title="Choose a Top Home Warranty Company | Landmark Home Warranty"
                        description="We provide outstanding customer service and valuable coverage for our customers. Learn more about why we are one of the best home warranty companies."
                        canonical="/homeowner-warranty/why-landmark"
                    />
                );
            case "#reviews":
                return (
                    <Meta
                        title="Homeowner Warranty Reviews | Landmark Home Warranty"
                        description="Not sure if a home warranty from Landmark is right for you? Read what other homeowners have said about our service."
                        canonical="/homeowner-warranty/reviews"
                    />
                );
            default:
                return (
                    <Meta
                        title="Homeowner Warranty Protection | Landmark Home Warranty"
                        description="A homeowner's warranty helps protect your major systems and appliances that aren't covered by homeowner's insurance. Learn if a home warranty is right for you."
                        canonical="/homeowner-warranty"
                    />
                );
        }
    }

    startOrder = (contractOfferId: string) => {
        // Find the offer to be selected
        const offer = this.props.store.offers.find(offer => offer.contractOfferId === contractOfferId);
        // Select the offer
        this.props.actions.order.selectOffer(offer);

        // Move to the order page and scroll to the top
        this.props.actions.router.push(`/order/${PlanTypeName.Homeowner}`);
        window.scrollTo(0, 0);
    }

    /**
     * A react standard method to render this component.
     */
    render() {
        return (
            <main className="homeowner">
                {this.getMetaData()}
                <ProtectYourHome />
                <HomeownerSubNavBar />
                <OfferDetails
                    isMonthlyPrice={true}
                    planTypeId={PlanType.Homeowner}
                    onOrder={(contractOfferId) => this.startOrder(contractOfferId)} />
                <WhyChooseLandmark />

                <Reviews
                    type={"homeowner"} />
                <BasicRegistration
                    largeTitle="Get a Free Home Warranty Quote!"
                    subTitle="Input your information and get a free, personalized home warranty quote. You can customize the coverage and pricing to fit your needs. Experience what over 70,000 homeowners already know: A Landmark Home Warranty helps provide protection for your home and budget!" />
                <Footer />
                <LimitsAndExclusions
                    dialogActions={this.props.actions.limitsAndExclusionsActions}
                    isDialogOpen={this.props.store.isLimitsAndExclusionsOpen}
                />
            </main>
        );
    }
}
