import * as React from "react";

import { createOrderActions, OrderActionCreators } from "../../actions/order.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { PlanTypeName } from "../../constants/TransactionType";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import * as Colors from "../../styles/Colors";
import { getCanOrder } from "selectors/order.selectors";

interface Props {
    store?: {
        canOrder: boolean;
    };
    actions?: {
        order: OrderActionCreators;
        router: RouterActionCreators;
    };

}

@connect(
    state => ({
        store: {
            canOrder: getCanOrder(state),
        }
    }),
    bindActionCreatorsToProps({
        order: createOrderActions(),
        router: createRouterActions(),
    })
)
export class RealEstate extends React.Component<Props, any> {

    handleCanOrder = (e, realEstateRoute) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            this.props.actions.order.newOrder(PlanTypeName.RealEstate);
            this.props.actions.router.push(realEstateRoute);
            window.scrollTo(0, 0);
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    render() {
        const realEstateRoute = `/order/${PlanTypeName.RealEstate}`;

        return (
            <section id="professional" className="section-side professional" style={{ background: "white" }}>
                <div className="container">
                    <div className="row">
                        <div title="Real estate agent purchasing a home warranty for her new home buyers. " className="column third photo hidden-small"></div>
                        <div className="column two-thirds content">
                            <i title="Icon for the real estate professionals page." className="section-icon icon-professional"></i>
                            <h1>Real Estate Professionals</h1>
                            <p>Real estate professionals trust their clients with a home warranty from Landmark because of our remarkable service, excellent coverage, and easy ordering process.</p>
                            <div className="btn-group">
                                <a onClick={e => {
                                    e.preventDefault();
                                    this.props.actions.router.push("/home-buyers-warranty");
                                    window.scrollTo(0, 0);
                                }} className="btn" href="/home-buyers-warranty">
                                    Learn More<i data-icon="j"></i>
                                </a>
                                    <a
                                    href={realEstateRoute}
                                    onClick={e => this.handleCanOrder(e, realEstateRoute)} className="btn-gray btn-stroke-light btn-professional">Place an Order</a> 

                                                              
                            </div>
                            <div className="row hidden-small">
                                <div className="column half">
                                    <h4>Home Warranty Coverage for Your Clients Includes:</h4>
                                    <ul className="feature-list">
                                        <li style={{ color: Colors.App.blue }}>Welcome Call</li>
                                        <li style={{ color: Colors.App.blue }}>Rekey Service for New Homeowners</li>
                                        <li style={{ color: Colors.App.blue }}>HVAC Tune-ups</li>
                                        <li style={{ color: Colors.App.blue }}>Repairs &amp; Replacements on Major System &amp; Appliances</li>
                                    </ul>
                                </div>
                                <div className="column half">
                                    <h4>Landmark Home Warranty Advantages:</h4>
                                    <ul className="feature-list">
                                        <li style={{ color: Colors.App.blue }}>Quick and Easy Ordering</li>
                                        <li style={{ color: Colors.App.blue }}><a href="/resources/real-estate-tips/listing-coverage" target="_blank">Comprehensive Seller's Coverage</a></li>
                                        <li style={{ color: Colors.App.blue }}>Free Educational Materials</li>
                                        <li style={{ color: Colors.App.blue }}>Local Representatives and In-House Support Teams</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
