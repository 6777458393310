import update from "immutability-helper";
import { Action } from "redux";

import { WaitActionTypes, WaitAction } from "../actions/wait.actions";

export interface WaitState {
    localWaits: { [key: string]: Date };
    globalWaits: { [key:string]: Date };
}

const defaultState = {
    localWaits: {},
    globalWaits: {},
} as WaitState;

const reducer = (
    state = defaultState,
    action:
        Action |
        WaitAction
) => {
    switch (action.type) {
        case WaitActionTypes.BEGIN: {
            const waitAction = action as WaitAction;
            return update(state, {
                // Determine which part of the store to update (global/local)
                [waitAction.payload.isLocal ? "localWaits" : "globalWaits"]: { [waitAction.payload.id]: { $set: new Date() } }
            });
        }

        case WaitActionTypes.END: {
            const waitAction = action as WaitAction;
            state = update(state, {
                localWaits: { $unset: [waitAction.payload.id] },
                globalWaits: { $unset: [waitAction.payload.id] }
            });
            return state;
        }

        default: break;
    }

    return state;
};

export const WaitReducer = reducer;
