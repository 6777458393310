import { Store } from "redux";
import { ApplicationState } from "../store/app";
import { ActionCreatorMap } from "../store/componentBindings";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction } from "./defs";

// See https://github.com/reactjs/redux/issues/303#issuecomment-125184409
export function observeStore<T>(
    store: Store<ApplicationState>,
    select: (store: ApplicationState) => T,
    onChange: (prev: T, next: T) => void) {
    let currentState;
    const localStore = store;

    function handleChange() {
        let nextState;
        try {
            nextState = select(localStore.getState());
        }
        catch (err) {
            // If selection failed, fail silently
        }

        if (nextState && nextState !== currentState) {
            onChange(currentState, nextState);
            currentState = nextState;
        }
    }

    return localStore.subscribe(handleChange);
}

// See http://stackoverflow.com/a/37616104/162907
function filterObject(obj: any, predicate: (obj: any, key: string) => boolean) {
    if (Array.isArray(obj)) {
        return obj.map(child => filterObject(child, predicate));
    }
    else if (obj !== null && obj !== undefined && (typeof obj === "object")) {
        return Object.keys(obj)
            .filter(key => predicate(obj[key], key))
            .reduce((res, key) => Object.assign(res, { [key]: filterObject(obj[key], predicate) }), {});
    }
    return obj;
}

/**
 * Creates an observer for an area of the store and persists changes in local or session storage.
 * @param select A selector used to select an area of the state be observed.
 * @param storage The storage to use, local or session.
 * @param storageKey The storage key to use.
 */
export function createStoreObserver<T>(
    select: (store: ApplicationState) => T,
    storageKey: string,
    storage: Storage = window.sessionStorage,
    filter: (obj: any, key: string) => boolean = (obj, key) => true
) {
    return (store: Store<ApplicationState>) => observeStore(
        store,
        select,
        (prev, next) => {
            // Skip if this is the first time working with this state
            if (!!prev) {
                next = filterObject(next, filter);
                storage.setItem(storageKey, JSON.stringify(next));
            }
        }
    );
}

type storeObserver = (store: Store<ApplicationState>) => void;

/**
 * Combines multiple observers into a single observer.
 */
export function combineObservers(...args: storeObserver[]) {
    return (store: Store<ApplicationState>) => {
        for (const obs of args) {
            obs(store);
        }
    };
}

export const StoreActionTypes = {
    HYDRATE: `${appId}/HYDRATE`,
    Init: AsyncAction("init"),
    UNEXPECTED_ERROR: `${appId}/UNEXPECTED_ERROR`,
};

export function createStoreActions(): StoreActionCreators {
    return {
        hydrate: (key: string, state: any) => ({
            type: StoreActionTypes.HYDRATE,
            payload: {
                key,
                state
            }
        }),

        init: createAsyncActions<void, void>(StoreActionTypes.Init),

        unexpectedError: (error: any) => ({
            type: StoreActionTypes.UNEXPECTED_ERROR,
            payload: { error },
        }),
    };
}

export interface HydrateAction extends PayloadAction<{
    key: string;
    state: any;
}> {}

export interface ErrorAction extends PayloadAction<{ error: any; }> {}

export interface StoreActionCreators extends ActionCreatorMap {
    /**
     * Hydrates a piece of state from a persistence store.
     */
    hydrate: (key: string, state: any) => HydrateAction;

    /**
     * Marks the initialization of the store during app startup.
     */
    init: AsyncActionCreators<void, void>;

    /**
     * Notifies of an unexpected error in the system.
     */
    unexpectedError: (error: any) => ErrorAction;
}
