import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "../actions/defs";
import { createSalesRepActions, SalesRepActionTypes } from "../actions/salesRep.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const salesRepActions = createSalesRepActions();

const handleLoadSalesReps: Epic<
    PayloadAction<string | Error | Landmark.SalesRep[]>
> = action$ => action$.pipe(
    ofType(SalesRepActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/salesRep/${action.payload}`)
            .fetch()
            .then(response => response.json),
        salesRepActions.load.success,
        salesRepActions.load.failure,
    ),
);

const waitForLoadSalesReps = createWaitEpic(SalesRepActionTypes.Load);

const epic = combineEpics(
    handleLoadSalesReps,
    waitForLoadSalesReps,
);
export default epic;
