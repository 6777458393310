import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { PayloadAction } from "../actions/defs";
import { createExecutiveActions, ExecutiveActionTypes } from "../actions/executive.actions";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const executiveActions = createExecutiveActions();

/**
 * Loads executives.
 */
const handleLoadExecutives: Epic<
    PayloadAction<void | Landmark.Executive[] | Error>
> = action$ => action$.pipe(
    ofType(ExecutiveActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get(`/company/executive`)
            .fetch()
            .then(response => response.json),
        executiveActions.load.success,
        executiveActions.load.failure,
    ),
);

const waitForLoadExecutives = createWaitEpic(ExecutiveActionTypes.Load);

const epic = combineEpics(
    handleLoadExecutives,
    waitForLoadExecutives,
);
export default epic;
