export function convertTextToUrlSafe(text) {
    if (!text) {
        return "";
    }

    return text
        .toLowerCase()
        .replace(/[^\w\s]+/g, "")
        .replace(/\s+/g, "-");
}
