import * as Landmark from "models/landmark-api";

import { ActionCreatorMap } from "../../store/componentBindings";
import { AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";

export const ContractorStatisticsActionTypes = {
    Load: AsyncAction(`contractor/dispatchStatistics/load`),
};

export interface LoadContractorStatisticsPayload {
    contractorId: string;
    year: number;
}

export interface ContractorDispatchStatisticsActionCreators extends ActionCreatorMap {
    /**
     * Loads statistics for the contractor.
     */
    load: AsyncActionCreators<LoadContractorStatisticsPayload, Landmark.DispatchStatisticsResponse>;
}

export function createContractorDispatchStatisticsActions() {
    return {
        load: createAsyncActions(ContractorStatisticsActionTypes.Load),
    } as ContractorDispatchStatisticsActionCreators;
}
