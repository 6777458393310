/**
 * A service that provides simple in-memory caching.
 */
export class Cache {
    private map: {[key: string]: any} = {};

    /**
     * Gets an entry from the cache by key.
     * @param key The key to retrieve.
     * @returns The value from the cache, or null if the entry could not be found.
     */
    get(key: string) {
        if (this.map.hasOwnProperty(key)) {
            return this.map[key];
        }
        return null;
    }

    /**
     * Sets an entry in the cache.
     * @param name The key to set.
     * @param value The value to set.
     * @param ttl The time to live, in milliseconds (optional).
     */
    set(name: string, value: any, ttl?: number) {
        this.map[name] = value;
        if (ttl) {
            setTimeout(() => this.remove(name), ttl);
        }
    }

    /**
     * Removes an entry in the cache by key.
     * @param key The key to remove.
     */
    remove(key: string) {
        if (this.map.hasOwnProperty(key)) {
            delete this.map[key];
        }
    }
}

const cache = new Cache();
export default cache;
