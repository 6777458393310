import * as React from "react";

const styles = {
    span: {
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    link: {
        color: "inherit",
        textDecoration: "underline",
        textDecorationSkipInk: "none"
    } as React.CSSProperties
};

/**
 * Simple component that renders our phone number with a clickable link.
 *
 * TODO - It may be good to also do an email version of this component where the email has a mailto link.
 * TODO - May also want to add optional props that would allow you to change the phone number or email.
 *
 * @export
 * @class CallUs
 * @extends {React.Component}
 */
export class CallUs extends React.Component {
    render() {
        return (
            <span style={styles.span}><a style={styles.link} href="tel:8663062999">(866) 306-2999</a></span>
        );
    }
}
