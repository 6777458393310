import * as React from "react";
import { toastr } from "react-redux-toastr";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { OrderActionTypes } from "../actions/order.actions";
import { createWaitActions } from "../actions/wait.actions";
import { CallUs } from "../components/CallUs";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createRouterActions } from "../actions/router.actions";

const routerActions = createRouterActions();
const waitActions = createWaitActions();

function* handleConvertContract(action) {
    let waitBegin = waitActions.begin();
    yield put(waitBegin);

    try {
        const response = yield call(() => LandmarkApiService
            .get(`order/convert/${action.payload}`)
            .withAuthentication()
            .fetch()
        );

        // Notify of success
        yield put({
            type: OrderActionTypes.ConvertContract.SUCCESS,
            payload: {
                data: response.json,
                isConvertingFromListingCoverage: true,
                partnerType: action.payload,
            }
        });

        yield put(routerActions.push(`/order/realEstate`));
    }
    catch (error) {

        // Notify of failure
        yield put({
            type: OrderActionTypes.ConvertContract.FAILURE,
            error
        });

        toastr.error(
            "Convert Failed",
            "",
            {
                transitionIn: "fadeIn",
                transitionOut: "fadeOut",
                component:
                <p>
                    Please call us for assistance at <CallUs />.
                </p>
            }
        );
    }
    finally {
        // Stop waiting
        yield put(waitActions.end(waitBegin.payload.id));
    }
}

function* watchConvertContract() {
    yield takeEvery(OrderActionTypes.ConvertContract.BEGIN, handleConvertContract);
}

export default function* root() {
    yield all([
        fork(watchConvertContract),
    ]);
}
