import { AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const ContractorPreAuthActionTypes = {
    Load: AsyncAction(`contractor/preAuth/load`),
};

export function createContractorPreAuthActions(): ContractorPreAuthActionCreators {
    return {
        load: createAsyncActions(ContractorPreAuthActionTypes.Load),
    };
}

export interface ContractorPreAuthActionCreators extends ActionCreatorMap {
    load: AsyncActionCreators<void, void>;
}
