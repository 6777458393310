import * as React from "react";

import { createDialogActions, DialogActionCreators } from "actions/dialog.actions";
import { createOrderActions, OrderActionCreators } from "actions/order.actions";
import { createRouterActions, RouterActionCreators } from "actions/router.actions";
import { Areas } from "constants/Areas";
import { PlanTypeName } from "constants/TransactionType";
import { createGetIsDialogOpen } from "selectors/dialog.selectors";
import { bindActionCreatorsToProps, connect } from "store/componentBindings";
import * as Styles from "styles";
import { Modal } from "./Modal";
import { getCanOrder } from "selectors/order.selectors";


interface Props {
    store?: {
        isDialogOpen: boolean;
        canOrder: boolean;
    };
    actions?: {
        dialog: DialogActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
    };
    router?: any;
    color?: "orange" | "white";
    marketingSourceId?: string;
}

@connect(
    state => ({
        store: {
            isDialogOpen: createGetIsDialogOpen(Areas.Order.OrderingParty)(state),
            canOrder: getCanOrder(state),
        }
    }),
    bindActionCreatorsToProps({
        dialog: createDialogActions(Areas.Order.OrderingParty),
        order: createOrderActions(),
        router: createRouterActions()
    })
)
export class OrderingPartyModal extends React.Component<Props, any> {

    componentDidMount() {
        if (!this.props.store.canOrder && (window.location.pathname === "/order/consumer" || window.location.pathname === "/order/realEstate")
            || window.location.pathname === "/order" || window.location.pathname === "/order/") {
            window.location.replace("https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/");
        }
    }

    handleCanOrderConsumer = (e, consumerRoute) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            this.props.actions.dialog.close();
            this.props.actions.order.newOrder(PlanTypeName.Homeowner);
            this.props.actions.order.setMarketingSourceId(this.props.marketingSourceId);
            this.props.actions.router.push(consumerRoute);
            window.scrollTo(0, 0);
        }
        else {
             window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    handleCanOrderRealEstate = (e, realEstateRoute) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            this.props.actions.dialog.close();
            this.props.actions.order.newOrder(PlanTypeName.RealEstate);
            this.props.actions.router.push(realEstateRoute);
            window.scrollTo(0, 0);
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    render() {
        const modalStyle = Object.assign({}, Styles.Modal.AutoSized, {
            content: {
                height: 275,
                borderRadius: 20,
            },
            container: {
                height: 275,
                borderRadius: 20,
            },
        });

        const consumerRoute = `/order/${PlanTypeName.Homeowner}`;
        const realEstateRoute = `/order/${PlanTypeName.RealEstate}`;

        return (
            <div>
                <Modal
                    isOpen={this.props.store.isDialogOpen}
                    onRequestClose={this.props.actions.dialog.close}
                    style={modalStyle}>
                    <div style={{ maxWidth: 600, padding: "60px 30px" }} className="modal modal-admin">
                        <h2>Which best describes you?</h2>
                        <div>
                            <a
                                href={consumerRoute}
                                className="btn-orange"
                                style={{ margin: "0.5em 0", width: 375 }}
                                onClick={e => this.handleCanOrderConsumer(e, consumerRoute)}> Homeowner <i data-icon="j"></i></a>
                        </div>
                        <div>
                            <a
                                href={realEstateRoute}
                                className="btn-orange"
                                style={{ margin: "0.5em 0", width: 375 }}
                                onClick={e => this.handleCanOrderRealEstate(e, realEstateRoute)}> Real Estate Professional <i data-icon="j"></i></a>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
