import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import {
    AuthActionTypes,
} from "../actions/auth.actions";
import { PayloadAction } from "../actions/defs";
import { createStoreObserver, HydrateAction, StoreActionTypes } from "../actions/store.actions";
import { ProfileActionTypes } from "../actions/account/profile.actions";
import { StorageKeys } from "../constants/Storage";
import { ServiceRequestActionTypes } from "../actions/serviceRequest.actions";

export interface AuthState {
    lastUsername: string;
    response: Landmark.AuthenticationResponse;
}

const defaultState = {
    lastUsername: null,
    response: undefined
};
Object.freeze(defaultState);

const reducer = (
    state = defaultState,
    action:
        Action |
        HydrateAction |
        PayloadAction<
            Landmark.AuthenticationResponse |
            Landmark.EditProfileResponse
        >
) => {
    switch (action.type) {
        case StoreActionTypes.HYDRATE: {
            const hydrateAction = action as HydrateAction;
            if (hydrateAction.payload.key === StorageKeys.auth) {
                // Return hydrated state
                return hydrateAction.payload.state;
            }
            break;
        }

        case AuthActionTypes.Impersonate.Start.SUCCESS:
        case AuthActionTypes.Impersonate.End.SUCCESS:
        case AuthActionTypes.Authenticate.SUCCESS:
        case AuthActionTypes.Refresh.SUCCESS: {
            const authAction = action as PayloadAction<Landmark.AuthenticationResponse>;
            const lastUsername = (authAction.payload.user && authAction.payload.user.email) || null;
            return update(state, {
                lastUsername: { $set: lastUsername },
                response: { $set: authAction.payload },
            });
        }

        case ProfileActionTypes.Save.SUCCESS:
            const profileAction = action as PayloadAction<Landmark.EditProfileResponse>;
            return update(state, {
                response: {
                    user: { $set: profileAction.payload.user }
                }
            });

        // If they opted in to sms text on an SR, we need to set the isOptedInToSms to true so that
        // we do not display the box on the profile page.
        case ServiceRequestActionTypes.SET_CONTACT_PREFERENCES: {
                const contactPreferences = (action as PayloadAction<Landmark.ContactPreferences>).payload;
                if (contactPreferences.isOptedInToSms) {
                    return update(state, {
                        response: {
                            user: {
                                isOptedInToSms: { $set: true }
                            }
                        }
                    });
                }
                return state;
            }

        // Set the isOptedInToSms to true on the profile page if they opted into text there.
        case ProfileActionTypes.SetSmsOptIn.SUCCESS:
            return update(state, {
                response: {
                    user: {
                        isOptedInToSms: { $set: true }
                    }
                }
            });

        case AuthActionTypes.Authenticate.FAILURE:
        case AuthActionTypes.Refresh.FAILURE:
        case AuthActionTypes.LOGOUT: {
            const { lastUsername } = state;

            // Reset to the default state
            return update(defaultState, {
                lastUsername: { $set: lastUsername },
            });
        }

        default: break;
    }

    // If nothing changed, return the original state
    return state;
};

export const observer = createStoreObserver(
    state => state.auth,
    StorageKeys.auth,
    window.localStorage,
);

export const AuthReducer = reducer;
