import * as React from "react";
import * as ReactDOM from "react-dom";

interface Props {
    autoFocus?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    error?: string;
    filter?: RegExp;
    id?: string;
    maxLength?: number;
    minLength?: number;
    name?: string;
    onBlur?: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onChange?: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onEnterPressed?: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>;
    placeholder?: string;
    readOnly?: boolean;
    style?: React.CSSProperties;
    template?: string;
    value?: string;
    min?: number | string;
    max?: number | string;
    step?: number | string;
    type?:
    "text" |
    "password" |
    "card" |
    "color" |
    "cvc" |
    "date" |
    "datetime" |
    "datetime-local" |
    "email" |
    "month" |
    "number" |
    "range" |
    "search" |
    "tel" |
    "time" |
    "url" |
    "week" |
    "zip" |
    "textarea";
    optOutErrorMessage?: boolean;
}

class Controls {
    input: HTMLInputElement | HTMLTextAreaElement;
}

interface State {
    id?: string;
    filter?: RegExp;
    template?: string;
    value?: string;
}

export class TextBox extends React.Component<Props, State> {
    static defaultProps: Props = {
        type: "text",
        optOutErrorMessage: false,
    };

    controls = new Controls();

    constructor(props: Props) {
        super(props);

        this.state = this.prepareState({
            ...this.getStateForType(props.type),
            ...{ value: props.value || props.defaultValue }
        });
    }

    componentWillReceiveProps(newProps: Props) {
        if (newProps.value !== this.props.value ||
            newProps.filter !== this.props.filter ||
            newProps.template !== this.props.template) {
            this.setState(
                this.prepareState({
                    filter: newProps.filter || this.state.filter,
                    template: newProps.template || this.state.template,
                    value: newProps.value,
                })
            );
        }
    }

    /**
     * Moves focus to the text box.
     */
    focus = () => {
        const node = ReactDOM.findDOMNode(this.controls.input) as HTMLInputElement;
        if (node) {
            node.focus();
        }
    }

    handleKeyDown = e => {
        if (this.props.onEnterPressed && e.keyCode === 13) {
            e.preventDefault();
            this.props.onEnterPressed(e);
        }
    }

    handleBlur = e => {
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    handleChange = e => {
        let value = e.target.value;

        value = this.processTemplate(value);
        if (e.target.value !== value)
        {
            // Change the value in the control
            this.controls.input.value = value;
        }

        this.setState(
            this.prepareState({ ...this.state, value })
        );

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    private getStateForType = (type: string) => {
        switch (type) {
            case "tel": {
                return {
                    filter: /(^1)|[^0-9]/g,
                    template: "###-###-####"
                } as State;
            }
            case "zip":
                {
                    return {
                        filter: /[^0-9]/g,
                        template: "#####"
                    };
                }
            case "card":
                {
                    return {
                        filter: /[^0-9\*]/g,
                        template: "#### #### #### ####"
                    };
                }
            case "cvc":
                {
                    return {
                        filter: /[^0-9]/g,
                        template: "####"
                    };
                }
        }
        return {} as State;
    }

    prepareState = (state: State) => {
        // Regex match against the value
        if (state.filter && state.value) {
            state.value = state.value.replace(state.filter, "");
        }

        return state;
    }

    processTemplate = (value: string) => {
        // Template the value
        if (value && this.state.template) {
            let i = 0;
            // Process the template, replacing # with the value
            value = this.state.template.split("").reduce(
                (v, current) => {
                    if (i >= value.length) {
                        // Stop rendering the template
                        return v;
                    }
                    else if (current === "#") {
                        // Explicit replacement of template from input
                        return v + value[i++];
                    }
                    if (current === value[i]) {
                        // Template and input are the same character, advance input
                        i++;
                    }
                    return v + current;
                },
                ""
            );
        }

        if (!value) {
            value = "";
        }

        return value;
    }

    get value() {
        if (this.controls.input) {
            return this.controls.input.value;
        }
        return this.state.value;
    }

    set value(value: string) {
        if (this.controls.input) {
            this.controls.input.value = value;
        }
    }

    render() {
        let { error } = this.props;

        let hasValidationErrors = false;
        if (!!error && error.length > 0) {
            hasValidationErrors = true;
        }

        let errorContent = null;
        if (hasValidationErrors && !this.props.optOutErrorMessage) {
            // TODO: "Required" text is not dynamic at the moment.
            errorContent = (
                <div className="field-validation-error">REQUIRED</div>
            );
        }

        let className = "";
        if (hasValidationErrors) {
            className = "error";
        }

        let title: string = null;
        if (hasValidationErrors) {
            title = error;
        }

        // Build some common props between components
        let props = {
            autoFocus: this.props.autoFocus,
            className,
            id: this.props.id,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            onKeyDown: this.handleKeyDown,
            placeholder: this.props.placeholder,
            title,
            value: this.processTemplate(this.state.value),
        };
        if (!props.id) {
            delete props.id;
        }

        if (this.props.type === "textarea") {
            return (
                <textarea
                    {...props}
                    ref={input => this.controls.input = input}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    style={this.props.optOutErrorMessage && hasValidationErrors ? { paddingRight: 20 } : {}}
                    readOnly={this.props.readOnly} />
            );
        }

        const classes = ["textbox"];
        if (this.props.error) {
            classes.push("error");
        }
        if (this.props.disabled) {
            classes.push("readable");
        }

        return (
            <div className={`field-wrap ${className}`}>
                <input
                    {...props}
                    className={classes.join(" ")}
                    min={this.props.min}
                    max={this.props.max}
                    minLength={this.props.minLength}
                    maxLength={this.props.maxLength}
                    name={this.props.name}
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                    step={this.props.step}
                    ref={input => this.controls.input = input}
                    style={
                        this.props.optOutErrorMessage ? { paddingRight: 20 } :
                        this.props.style ?  this.props.style :
                        {}
                    }
                    type={this.props.type} />
                {errorContent}
            </div>
        );
    }
}
