
import * as React from "react";
import { connect } from "react-redux";

import { Modal } from "./Modal";
import { ConfirmationActionCreators, createConfirmationActions } from "../actions/confirmation.actions";
import { bindActionCreatorsToProps } from "../store/componentBindings";
import * as Styles from "../styles";

interface Props {
    isOpen?: boolean;
    content?: string; //TODO: Maybe add support for markdown with react-markdown
    title?: string;
    actions?: {
        confirmation: ConfirmationActionCreators;
    };
}

@connect(
    state => ({
        isOpen: state.confirmation.isOpen,
        content: state.confirmation.content,
        title: state.confirmation.title,
    }),
    bindActionCreatorsToProps({
        confirmation: createConfirmationActions(),
    })
)
export default class Confirmation extends React.Component<Props, any> {

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.actions.confirmation.cancel}
                style={Styles.Modal.AutoSized}>
                <div style={Styles.Modal.AutoSized.close} onClick={this.props.actions.confirmation.cancel}>l</div>
                <div>
                    <h4 style={{ fontSize: "34px", marginTop: 0, paddingTop: 0, marginRight: 30 }}>{this.props.title}</h4>
                    <div style={{ textAlign: "center" }}>
                        {
                            this.props.content && this.props.content
                                .split("\n")
                                .map(value => {
                                    return <div>
                                        {value ? value : <br/>}
                                    </div>;
                                })
                        }
                    </div>
                </div>
                <div className="row" style={{ margin: "20px 0 0 0" }}>
                    <div className="column half">
                        <input
                            type="button"
                            onClick={() => {
                                this.props.actions.confirmation.confirm();
                            }}
                            style={{
                                height: 38,
                                lineHeight: "45px",
                                margin: 0,
                            }}
                            value="Yes" />
                    </div>
                    <div className="column half">
                        <input
                            type="button"
                            onClick={() => {
                                this.props.actions.confirmation.cancel();
                            }}
                            style={{
                                height: 38,
                                lineHeight: "45px",
                                margin: 0,
                            }}
                            value="No" />
                    </div>
                </div>
            </Modal>
        );
    }
}
