import * as React from "react";
import { ContactUsActionCreators,ContactUsActionTypes,createContactUsActions } from "actions/contactUs.actions";
import { bindActionCreatorsToProps, connect } from "store/componentBindings";
import * as Landmark from "models/landmark-api";
import { TextBox } from "components/TextBox";
import { PayloadAction } from "actions/defs";
import { actionPromise } from "utils/redux-promise";
import { isEmpty } from "validator";
interface Props {
    actions?: {
        contactUs: ContactUsActionCreators;
    };
}


interface State {
    FirstName: string;
    LastName: string;
    Email: string;
    Company: string;
    Phone: string;
    YesToStreemEmail: boolean;
    YesToPolicy: boolean;
    submitting: boolean;
    success: boolean;
    error: any;
    errors: any;
    submitted:boolean;
}



@connect(
    state => ({
    }),
    bindActionCreatorsToProps({
        contactUs: createContactUsActions(),
    })
)
export class StreemSignup extends React.Component<Props, State> {

    constructor(props) {
        super(props);

        this.state = {
            FirstName: "",
            LastName: "",
            Email: "",
            Phone: "",
            Company: "",
            YesToStreemEmail: false,
            YesToPolicy: false,
            submitting: false,
            success: false,
            error: false,
            submitted: false,
            errors: {}

        };
    }
    handleYesToEmail = (event) => {
        console.log("clicked");
        this.setState({ YesToStreemEmail: event.target.checked });
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        console.log("clicked");
        let errors: any = {};
        let hasErrors = false;
        if (!this.state.FirstName || isEmpty(this.state.FirstName)) {
            errors.FirstName = "FirstName is required";
            hasErrors= true;
        }
        if (!this.state.LastName || isEmpty(this.state.LastName)) {
            errors.LastName = "LastName is required";
            hasErrors= true;
        }
        if (!this.state.Email || isEmpty(this.state.Email)) {
            errors.Email = "Email is required";
            hasErrors= true;
        }
        if (!this.state.Company || isEmpty(this.state.Company)) {
            errors.Company = "Company is required";
            hasErrors= true;
        }
        if (!this.state.Phone || isEmpty(this.state.Phone)) {
            errors.Phone = "PhoneNumber is required";
            hasErrors= true;
        }
        if(hasErrors)
        {
            this.setState({errors: errors});
            return;
        }

        try {
            const request: Landmark.StreemSignupRequest = {
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                Email: this.state.Email,
                Company: this.state.Company,
                Phone: this.state.Phone,
            };

            const response = await actionPromise<PayloadAction<Landmark.EmailSent>>(
                () => this.props.actions.contactUs.streemSignup.begin(request),
                ContactUsActionTypes.StreemSignup.SUCCESS,
                ContactUsActionTypes.StreemSignup.FAILURE
            );

            if (response.payload) {
                this.setState({ success: response.payload.hasSent, submitted: true });
            }
            else{
                this.setState({ error: true, submitted: false });
            }
        }
        catch (e) {
            this.setState({ error: true, submitted: true });
        }
        finally {
            this.setState({ submitting:false });
        }
    }

    handleYesToPolicy = (event) => {
        console.log("yes");
        this.setState({ YesToPolicy: event.target.checked });
    }
    render() {


        return (
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "50px", backgroundColor: "white", fontWeight: "bold" }}>
                <div style={{ margin: "10px" }}>
                    <img src="https://www.streem.com/hs-fs/hubfs/images/blog-headers/ContractorSignUpHeader_FTDR640.jpg?width=640&amp;name=ContractorSignUpHeader_FTDR640.jpg" alt="ContractorSignUpHeader_FTDR640"></img>
                </div>

                <fieldset style={{ display: "flex", flexDirection: "column", margin: "10px", justifyContent: "space-around" }} disabled ={this.state.success}>
                    <form autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
                        <div>
                            <h1>Get Streem for Your Team</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                        <div>
                            <TextBox name="FirstName"
                                placeholder="First Name *"
                                type="text"
                                value={this.state.FirstName}
                                onChange={(e: any) => { this.state.errors.FirstName = null; this.setState({ FirstName: e.target.value });}}
                                error = {this.state.errors.FirstName}>
                                </TextBox>

                        </div>
                        <div>
                            <TextBox name="LastName"
                                placeholder="Last Name *"
                                type="text"
                                value={this.state.LastName}
                                onChange={(e: any) => { this.state.errors.LastName = null; this.setState({ LastName: e.target.value });}}
                                error = {this.state.errors.LastName}
                            ></TextBox>

                        </div>
                        </div>
                        <div>
                            <TextBox name="Email"
                                placeholder="Email *"
                                type="email"
                                value={this.state.Email}
                                onChange={(e: any) => { this.state.errors.Email = null; this.setState({ Email: e.target.value });}}
                                error = {this.state.errors.Email}
                            ></TextBox>

                        </div>

                        <div>
                            <TextBox name="Company"
                                placeholder="Company Name *"
                                type="text"
                                value={this.state.Company}
                                onChange={(e: any) => { this.state.errors.Company = null; this.setState({ Company: e.target.value });}}
                                error = {this.state.errors.Company}
                                >
                            </TextBox>

                        </div>
                        <div>
                            <TextBox name="Phone"
                                placeholder="Phone *"
                                type="tel"
                                value={this.state.Phone}
                                onChange={(e: any) => { this.state.errors.Phone = null; this.setState({ Phone: e.target.value });}}
                                minLength= {12}
                                maxLength = {12}
                                error = {this.state.errors.Phone}>
                            </TextBox>

                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="email-pref"
                                onChange={(event:any) =>{ console.log(event.target.checked) ;this.setState({ YesToStreemEmail: event.target.checked });}}
                                checked={this.state.YesToStreemEmail} />
                            <label htmlFor="email-pref">Yes, Streem may email me.*</label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="policy-pref"
                                onChange={(event:any) => this.setState({ YesToPolicy: event.target.checked })}
                                checked={this.state.YesToPolicy} />
                            <label htmlFor="policy-pref">Yes, I agree to Streem’s data and privacy policy.*</label>
                        </div>

                        <div style={{ maxWidth: "fit-content" }}>
                            <p>
                                We store the data you share with us to improve your experience & deliver the content you requested. Learn more in our <a href="https://sites.google.com/streem.pro/terms-and-privacy/home" >privacy policy</a>
                            </p>
                        </div>
                        <div className="field-wrap">
                            <button disabled={!this.state.YesToStreemEmail || !this.state.YesToPolicy} type="submit">Get Streem</button>
                        </div>
                        <div className="field-wrap" style={{ maxWidth: "fit-content" }}>
                        {
                          (this.state.success) ? <span style={{ color: "#46c744a1"}}>Thank you for signing up with streem.  We will be reaching out within a couple business days to complete signup.</span>:null
                        }
                        </div>
                        <div className="field-wrap" style={{ maxWidth: "fit-content" }}>
                        {
                          (this.state.error) ? <span style={{ color: "#f61766"}}>Something went wrong please try again later</span>:null
                        }
                        </div>
                    </form>
                </fieldset>
            </div>

        );
    }
}
