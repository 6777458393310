import { ActionCreatorsMapObject } from "redux";
import shortid from "shortid";

import { appId, PayloadAction } from "./defs";

export const WaitActionTypes = {
    BEGIN: `${appId}/wait/BEGIN`,
    END: `${appId}/wait/END`,
};
Object.freeze(WaitActionTypes);

export interface WaitRequest {
    /**
     * The ID of the area that is waiting.
     */
    id: string;

    /**
     * True if the wait area is localized to a specific part of the UI, false if globally waiting.
     */
    isLocal?: boolean;

    /**
     * The timeout of the wait area in milliseconds.
     */
    timeout?: number;
}

export interface WaitAction extends PayloadAction<WaitRequest> { }

export interface WaitActionCreators extends ActionCreatorsMapObject {
    /**
     * Begins waiting for an asyncronous event to complete.
     */
    begin: (request?: WaitRequest) => WaitAction;

    /**
     * Ends waiting for an asynchronous event.
     */
    end: (id?: string) => WaitAction;
}

export function createWaitActions() {
    return {
        begin: (request?: WaitRequest) => {
            if (!request) {
                request = {
                    id: shortid.generate()
                };
            }

            var action: WaitAction = {
                type: WaitActionTypes.BEGIN,
                payload: request
            };
            return action;
        },

        end: (id: string) => ({
            type: WaitActionTypes.END,
            payload: { id }
        }) as WaitAction,
    } as WaitActionCreators;
}
