import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { CategoryState, createCategoryReducer, defaultCategoryState } from "../category.reducer";
import { PayloadAction } from "../../actions/defs";
import { ServiceRequestActionTypes } from "../../actions/serviceRequest.actions";
import { ServiceRequestHistoryActionTypes } from "../../actions/account/serviceRequestHistory.actions";
import { Areas } from "../../constants/Areas";


export interface ServiceRequestHistoryState {
    categories: CategoryState;
    items?: Landmark.ServiceRequest[];
    notes: Landmark.Note[];
    selectedId: string;
}

const defaultState: ServiceRequestHistoryState = {
    categories: defaultCategoryState,
    items: [],
    notes: [],
    selectedId: null,
};
Object.freeze(defaultState);

const categoryReducer = createCategoryReducer(Areas.Account.ServiceRequest.History);

export const ServiceRequestHistoryReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.ServiceRequest[] |
            Landmark.NotesResponse |
            Landmark.ServiceRequestNoteRequest |
            Landmark.ServiceRequest
        >
) => {
    // Update selected category state
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action) }
    });

    switch (action.type) {
        case ServiceRequestHistoryActionTypes.Load.SUCCESS: {
            const serviceRequests = (action as PayloadAction<Landmark.ServiceRequest[]>).payload;
            return update(state, {
                items: { $set: serviceRequests }
            });
        }
        case ServiceRequestHistoryActionTypes.LoadNotes.SUCCESS: {
            const notes = (action as PayloadAction<Landmark.NotesResponse>).payload.notes;
            return update(state, {
                notes: { $set: notes }
            });
        }
        case ServiceRequestHistoryActionTypes.SELECT: {
            return update(state, {
                selectedId: { $set: (action as PayloadAction<string>).payload }
            });
        }
        case ServiceRequestActionTypes.Send.SUCCESS: {
            // Add the SR to the end of the service request list
            return update(state, {
                items: { $push: [ (action as PayloadAction<Landmark.ServiceRequest>).payload ]}
            });
        }
        case ServiceRequestActionTypes.SendMessage.SUCCESS: {
            // Add the note to the end of the notes list
            const note = (action as PayloadAction<Landmark.ApiResponse<Landmark.Note>>).payload.result;
            return update(state, {
                notes: { $unshift: [ note ]}
            });
        }
        default: break;
    }
    return state;
};
