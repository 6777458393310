import { AsyncAction, createAsyncActions, AsyncActionCreators } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const LocationActionTypes = {
    Get: AsyncAction("location/get"),
};
Object.freeze(LocationActionTypes);

export interface LocationResponse {
    google: google.maps.GeocoderResult[];
    position: Position;
    stateCode: string;
}

export interface LocationActionCreators extends ActionCreatorMap {
    /**
     * Gets the location of the user.
     */
    get: AsyncActionCreators<void, LocationResponse>;
}

export function createLocationActions(): LocationActionCreators {
    return {
        get: createAsyncActions(LocationActionTypes.Get),
    };
}
