import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { CategoryState, defaultCategoryState, createCategoryReducer } from "../category.reducer";
import { PayloadAction } from "../../actions/defs";
import { AccountOrdersActionTypes } from "../../actions/account/orders.actions";
import { Areas } from "../../constants/Areas";

export interface OrdersState {
    categories: CategoryState;
    list: Landmark.Contract[];
    selectedOrder: string;
}

export const defaultState: OrdersState = {
    categories: defaultCategoryState,
    list: [],
    selectedOrder: null,
};

const categoryReducer = createCategoryReducer(Areas.Agent.Orders.Page);

export function OrdersReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            Landmark.ContractResponse |
            Landmark.OrderResult |
            string
        >
) {
    // Update the categories state
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action) }
    });

    switch (action.type) {
        case AccountOrdersActionTypes.Load.SUCCESS: {
            return update(state, {
                list: { $set: ((action as PayloadAction<Landmark.ContractResponse>).payload).contracts }
            });
        }

        case AccountOrdersActionTypes.Cancel.SUCCESS: {
            const payload = (action as PayloadAction<Landmark.OrderResult>).payload;
            const index = state.list.findIndex(order => order.contractId === payload.contractId);
            if (index >= 0) {
                return update(state, {
                    list: {
                        [index]: { $set: payload }
                    }
                });
            }
            break;
        }

        case AccountOrdersActionTypes.Complete.SUCCESS: {
            const response = (action as PayloadAction<Landmark.CompleteOrderResponse>).payload;
            const index = state.list.findIndex(c => c.contractId === response.contract.contractId);
            if (index >= 0) {
                return update(state, {
                    list: {
                        [index]: { $set: response.contract }
                    }
                });
            }
        }

        case AccountOrdersActionTypes.SelectOrder: {
            const payload = (action as PayloadAction<string>).payload;
            return update(state, {
                selectedOrder: { $set: payload }
            });
        }
    }

    // If nothing changed, return the original state
    return state;
}
