import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { empty, of } from "rxjs";
import { delay, filter, map, mapTo, switchMap, withLatestFrom } from "rxjs/operators";
import { AuthActionTypes } from "../../actions/auth.actions";
import { ContractorProfileActionTypes, createContractorProfileActions } from "../../actions/contractor/profile.actions";
import { PayloadAction } from "../../actions/defs";
import { createDialogActions, DialogAction } from "../../actions/dialog.actions";
import { HydrateAction, StoreActionTypes } from "../../actions/store.actions";
import { Areas } from "../../constants/Areas";
import { StorageKeys } from "../../constants/Storage";
import { AuthState } from "../../reducers/auth.reducer";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { getAuthenticatedUser } from "../../selectors/auth.selectors";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createToastrEpic } from "../toastr.epic";
import { createWaitEpic } from "../wait.epic";

const contractorProfileActions = createContractorProfileActions();
const dialogActions = createDialogActions(Areas.Account.Profile.Contractor);

const closeDialogOnAddFileSuccess: Epic<DialogAction | PayloadAction<void | Error>> = action$ => action$.pipe(
    ofType(ContractorProfileActionTypes.AddFile.SUCCESS),
    mapTo(dialogActions.close()),
);

const handleAddFile: Epic<PayloadAction<FormData | void | Error>> = action$ => action$.pipe(
    ofType(ContractorProfileActionTypes.AddFile.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<FormData>) => LandmarkApiService
            .post(`/contractor/addFile`)
            .withAuthentication()
            .deleteHeader("Content-Type")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        contractorProfileActions.addFile.success,
        contractorProfileActions.addFile.failure,
    ),
);

const handleAgreeToServiceAgreement: Epic<Action> = action$ => action$.pipe(
    ofType(ContractorProfileActionTypes.AgreeToServiceAgreement.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .post("/contractor/agreement")
            .withAuthentication()
            .fetch(),
        contractorProfileActions.agreeToServiceAgreement.success,
        contractorProfileActions.agreeToServiceAgreement.failure,
    ),
);

const handleCheckContractorServiceAgreement: Epic<Action | PayloadAction<Landmark.ContractorServiceAgreementResponse>> = action$ => action$.pipe(
    ofType(ContractorProfileActionTypes.CheckContractorServiceAgreement.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .get("/contractor/agreement")
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        contractorProfileActions.checkContractorServiceAgreement.success,
        contractorProfileActions.checkContractorServiceAgreement.failure
    ),
);

const handleCheckContractorServiceAgreementOnAlreadyAuthenticatedPageLoad: Epic<
    HydrateAction |
    PayloadAction<
        void |
        Error
    >
> = action$ => action$.pipe(
    ofType(StoreActionTypes.HYDRATE),
    // Auth state is being hydrated
    filter((action: HydrateAction) => action.payload.key === StorageKeys.auth),
    switchMap((action: HydrateAction) => {
        const auth = action.payload.state as AuthState;
        // If the hydrated user is a contractor, then begin the check
        if (auth &&
            auth.response &&
            auth.response.user &&
            auth.response.user.userType === "contractor") {
            return of(contractorProfileActions.checkContractorServiceAgreement.begin())
                // Add a slight delay so auth information can be stored for the service agreement check
                .pipe(delay(1));
        }
        return empty();
    }),
);

const handleCheckContractorServiceAgreementOnLogin: Epic<PayloadAction<Landmark.AuthenticationResponse | void | Error>> = action$ => action$.pipe(
    ofType(AuthActionTypes.Authenticate.SUCCESS),
    switchMap((action: PayloadAction<Landmark.AuthenticationResponse>) => {
        if (action.payload &&
            action.payload.user &&
            action.payload.user.userType === "contractor") {
            return of(contractorProfileActions.checkContractorServiceAgreement.begin());
        }
        return empty();
    }),
);

const handleLoad: Epic<PayloadAction<string | Landmark.Contractor | Error>> = action$ => action$.pipe(
    ofType(ContractorProfileActionTypes.Load.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/contractor/user/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        contractorProfileActions.load.success,
        contractorProfileActions.load.failure,
    ),
);

/**
 * Reloads the contractor information when a file has been added.
 */
const handleReloadOnAddFileSuccess: Epic<
    PayloadAction<string | Landmark.Contractor | Error>
> = (action$, state$) => action$.pipe(
    ofType(ContractorProfileActionTypes.AddFile.SUCCESS),
    withLatestFrom(state$),
    map(([action, state]) => {
        const user = getAuthenticatedUser(state);
        return contractorProfileActions.load.begin(user.userId);
    }),
);

const notifyUserOfFileUploadBegin = createToastrEpic(
    [ContractorProfileActionTypes.AddFile.BEGIN],
    (action: PayloadAction<Landmark.ForgotPasswordResponse>) => ({
        type: "info",
        title: "Sending File...",
        message: "Depending on the size of the file, this could take some time.",
        options: { showCloseButton: true, timeOut: 10000 }
    })
);

const notifyUserOfFileUploadSuccess = createToastrEpic(
    [ContractorProfileActionTypes.AddFile.SUCCESS],
    (action: PayloadAction<Landmark.ForgotPasswordResponse>) => ({
        type: "success",
        title: "File Sent",
        message: "The file has been successfully sent.",
        options: { showCloseButton: true, timeOut: 10000 }
    })
);

const notifyUserOfFileUploadFailure = createToastrEpic(
    [ContractorProfileActionTypes.AddFile.FAILURE],
    (action: PayloadAction<Landmark.ForgotPasswordResponse>) => ({
        type: "error",
        title: "Error",
        message: "An error occurred while sending the file, please try again.",
        options: { showCloseButton: true, timeOut: 10000 }
    })
);

const waitAddFile = createWaitEpic(ContractorProfileActionTypes.AddFile);
const waitForAgreeToServiceAgreement = createWaitEpic(ContractorProfileActionTypes.AgreeToServiceAgreement);
const waitLoad = createWaitEpic(ContractorProfileActionTypes.Load);

const epic = combineEpics(
    closeDialogOnAddFileSuccess,
    handleAddFile,
    handleAgreeToServiceAgreement,
    handleCheckContractorServiceAgreement,
    handleCheckContractorServiceAgreementOnAlreadyAuthenticatedPageLoad,
    handleCheckContractorServiceAgreementOnLogin,
    handleLoad,
    handleReloadOnAddFileSuccess,
    notifyUserOfFileUploadBegin,
    notifyUserOfFileUploadFailure,
    notifyUserOfFileUploadSuccess,
    waitAddFile,
    waitForAgreeToServiceAgreement,
    waitLoad,
);
export default epic;
