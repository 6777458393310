import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { PayloadAction } from "../actions/defs";
import { ReferenceActionTypes } from "../actions/reference.actions";
import { createStoreObserver, HydrateAction, StoreActionTypes } from "../actions/store.actions";
import { StorageKeys } from "../constants/Storage";

export interface ReferenceState {
    propertyTypes: Landmark.PropertyType[];
    states: Landmark.State[];
    trades: Landmark.Trade[];
    zipCodes: Landmark.ZipCode[];
}

const defaultState: ReferenceState = {
    propertyTypes: [],
    states: [],
    trades: [],
    zipCodes: [],
};
Object.freeze(defaultState);

export const ReferenceReducer = (
    state = defaultState,
    action:
        Action |
        HydrateAction |
        PayloadAction<Landmark.State[]> |
        PayloadAction<Landmark.ZipCode[]> |
        PayloadAction<Landmark.PropertyType[]> |
        PayloadAction<Landmark.Trade[]>
) => {
    switch (action.type) {
        case StoreActionTypes.HYDRATE: {
            const hydrateAction = action as HydrateAction;
            if (hydrateAction.payload.key === StorageKeys.reference) {
                // Return hydrated state
                return hydrateAction.payload.state;
            }
            break;
        }
        case ReferenceActionTypes.LoadPropertyTypes.SUCCESS: {
            const { payload: propertyTypes = [] } = action as PayloadAction<Landmark.PropertyType[]>;
            return update(state, { propertyTypes: { $set: Array.isArray(propertyTypes) ? propertyTypes : [] } });
        }
        case ReferenceActionTypes.LoadStates.SUCCESS: {
            const { payload: states = [] } = action as PayloadAction<Landmark.State[]>;
            return update(state, { states: { $set: Array.isArray(states) ? states : [] } });
        }
        case ReferenceActionTypes.LoadTrades.SUCCESS: {
            let { payload: trades = [] } = action as PayloadAction<Landmark.Trade[]>;
            trades = Array.isArray(trades) ? trades.filter(trade => trade.webView) : [];
            const tradesWithFilteredProductFamilies = trades.map(trade => Object.assign({}, trade, {
                productFamilies: trade.productFamilies.filter(pf => pf.name.toLowerCase() !== "no fault")
            }));

            return update(state, { trades: { $set: tradesWithFilteredProductFamilies } });
        }
        case ReferenceActionTypes.LoadZipCodes.SUCCESS: {
            const { payload: zipCodes = [] } = action as PayloadAction<Landmark.ZipCode[]>;
            return update(state, { zipCodes: { $set: Array.isArray(zipCodes) ? zipCodes : [] } });
        }
    }

    // If nothing changed, return the original state
    return state;
};

export const observer = createStoreObserver(
    state => state.reference,
    StorageKeys.reference,
    window.localStorage,
);
