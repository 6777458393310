export const TransactionType = Object.freeze({
    PropertyManager: 1,
    RealEstate: 2,
    Consumer: 3,
    PropertyManagerAEP: 4,
    PropertyManagerREM: 5,
    REProPrimaryResidence: 6,
});

export const TransactionTypeName = Object.freeze({
    PropertyManager: "propertyManager",
    RealEstate: "realEstate",
    Consumer: "consumer",
    PropertyManagerAEP: "propertyManagerAep",
    PropertyManagerREM: "propertyManagerRem",
    REProPrimaryResidence: "reProPrimaryResidence",
});

export const convertNameToTransactionType = (name: string) => {
    name = name && name.toLowerCase();
    const key = Object.keys(TransactionTypeName).find(k => TransactionTypeName[k].toLowerCase() === name);
    return (key && TransactionType[key]) || null;
};

export const convertTransactionTypeIdToName = (planTypeId: string) => {
    const key = Object.keys(TransactionType).find(k => TransactionType[k] === planTypeId);
    return (key && TransactionTypeName[key]) || null;
};

export const getKeyFromTransactionTypeId = (transactionTypeId: number) => {
    // Ensure that we have a number
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus
    transactionTypeId = +transactionTypeId;
    const key = Object.keys(TransactionType).find(k => TransactionType[k] === transactionTypeId);
    return key;
};
// new stuff for new database

export const PlanType = Object.freeze({
    Homeowner: "3C6EDE45-B3BE-4236-93CF-09755DB0538E",
    RealEstate: "4736A67B-08BD-49E0-90EC-78CE03BE0A60",
});

export const PlanTypeName = {
    Homeowner: "consumer",
    RealEstate: "realEstate",
};
Object.freeze(PlanTypeName);

export const PlanTypeAbbreviation = Object.freeze({
    Homeowner: "CO",
    RealEstate: "RE",
    PropertyManager: "PM",
});

export const convertNameToPlanType = (name: string) => {
    name = name && name.toLowerCase();
    const key = Object.keys(PlanTypeName).find(k => PlanTypeName[k].toLowerCase() === name);
    return (key && PlanType[key]) || null;
};

export const convertPlanTypeIdToName = (planTypeId: string) => {
    const key = Object.keys(PlanType).find(k => PlanType[k] === planTypeId);
    return (key && PlanTypeName[key]) || null;
};

export const getKeyFromPlanTypeId = (planTypeId: string) => {
    const key = Object.keys(PlanType).find(k => PlanType[k] === planTypeId);
    return key;
};

export const AccountNoticeTypeName = {
    AutoRenewal: "Auto Renewal",
    Renewal: "Renewal",
    UpdateBilling: "Update Billing",
    Upgrade: "Upgrade",
};
Object.freeze(PlanTypeName);
