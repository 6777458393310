import * as Landmark from "models/landmark-api";

import { ActionCreatorMap } from "../../store/componentBindings";
import { AsyncAction, AsyncActionCreators, createAsyncActions, PayloadAction, appId } from "../defs";

export const ContractorSearchActionTypes = {
    RESET: `${appId}/contractor/search/RESET`,
    Search: AsyncAction(`contractor/search`),
    SELECT: `${appId}/contractor/search/SELECT`,
};

export interface ContractorSearchActionCreators extends ActionCreatorMap {
    /**
     * Resets the 'search' area.
     */
    reset: () => void;

    /**
     * Performs a search for Landmark partners.
     * @param {string} searchTerm The text to search for.
     */
    search: AsyncActionCreators<string, Landmark.ContractorSearchResponse[]>;

    /**
     * Selects a contractor from the search response.
     */
    select: (contractor: Landmark.ContractorSearchResponse) => PayloadAction<Landmark.ContractorSearchResponse>;
}

export function createContractorSearchActions(): ContractorSearchActionCreators {
    return {
        reset: () => ({ type: ContractorSearchActionTypes.RESET }),
        search: createAsyncActions(ContractorSearchActionTypes.Search),
        select: (contractor: Landmark.ContractorSearchResponse) => ({
            type: ContractorSearchActionTypes.SELECT,
            payload: contractor
        }),
    };
}
