import * as Landmark from "models/landmark-api";

import { AsyncActionCreators, createAsyncActions, AsyncAction } from "./defs";
import { ActionCreatorMap } from "../store/componentBindings";

export const ReviewActionTypes = {
    LoadCustomers: AsyncAction(`review/customers/load`),
    LoadContractors: AsyncAction(`review/contractors/load`),
    LoadHomeowners: AsyncAction(`review/homeowners/load`),
    LoadRealEstate: AsyncAction(`review/realEstate/load`),
};
Object.freeze(ReviewActionTypes);

export function createReviewActions() {
    return {
        loadCustomerReviews: createAsyncActions(ReviewActionTypes.LoadCustomers),
        loadContractorReviews: createAsyncActions(ReviewActionTypes.LoadContractors),
        loadHomeownerReviews: createAsyncActions(ReviewActionTypes.LoadHomeowners),
        loadRealEstateReviews: createAsyncActions(ReviewActionTypes.LoadRealEstate),
    } as ReviewActionCreators;
}

export interface ReviewActionCreators extends ActionCreatorMap {
    /**
     * Load customer review data from the server.
     */
    loadCustomerReviews: AsyncActionCreators<void, Landmark.Review[]>;
    /**
     * Load contractor review data from the server.
     */
    loadContractorReviews: AsyncActionCreators<void, Landmark.Review[]>;
    /**
     * Load homeowner review data from the server.
     */
    loadHomeownerReviews: AsyncActionCreators<void, Landmark.Review[]>;
    /**
     * Load real estate review data from the server.
     */
    loadRealEstateReviews: AsyncActionCreators<void, Landmark.Review[]>;
}
