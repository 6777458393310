export interface AccountNotice { 
    id?: string;
    isDismissed?: boolean;
    message?: string;
    noticeDate?: string;
    type?: AccountNoticeType;
    value?: string;
}


export enum AccountNoticeType {
    Action = "action",
    Reference = "reference",
    Video = "video"
}

export namespace AccountNoticeType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "action" | "reference" | "video";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "action" = 0,
        "reference" = 1,
        "video" = 2
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(accountNoticeType: AccountNoticeType.Type) {
        return AccountNoticeType.Values[accountNoticeType];
    }
}

export interface AddDispatchNoteRequest { 
    dispatchId?: string;
    note?: DispatchNote;
    userId?: string;
}


export interface AddDispatchNoteResponse { 
    dispatchId?: string;
    note?: DispatchNote;
}


export interface Address { 
    city?: string;
    country?: string;
    fullStreet?: string;
    postalCode?: string;
    /**
     * The additional 4 digits to the zip code
     */
    postalCodeSuffix?: string;
    state?: string;
    street1?: string;
    street2?: string;
}


export interface AgencyLocation { 
    /**
     * The Entity.Id of the Agency
     */
    agencyId?: string;
    /**
     * The Address.Id of the Agency. Should be referenced by the Entity.PhysicalAddressId of the Entity.
     */
    agencyLocationId?: string;
    name?: string;
    address?: Address;
    salesTerritoryId?: number;
}


export interface Agent { 
    address?: any;
    agentId?: string;
    branch?: any;
    branchId?: string;
    email?: string;
    name?: string;
}


export interface ApiResponse { 
    errors?: Error[];
    hasErrors?: boolean;
}

export interface ApiResponse<T = any> { 
    result?: T;
}


export enum AuthenticationGrantType {
    Password = "password",
    Code = "code",
    RefreshToken = "refresh_token",
    User = "user"
}

export namespace AuthenticationGrantType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "password" | "code" | "refresh_token" | "user";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "password" = 0,
        "code" = 1,
        "refresh_token" = 2,
	"user" = 3
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(authenticationGrantType: AuthenticationGrantType.Type) {
        return AuthenticationGrantType.Values[authenticationGrantType];
    }
}

export interface AuthenticationRequest { 
    fingerprint?: string;
    grant_type?: AuthenticationGrantType;
    password?: string;
    rememberMe?: boolean;
    response_type?: AuthenticationResponseType;
    username?: string;
    webuserid?: string;
}


/**
 * Represents an authentication response in the system.
 */
export interface AuthenticationResponse { 
    /**
     * Gets the access token used to access resources.
     */
    access_token?: string;
    /**
     * This is to indicate to the UI that the user has authenticated with a forgot password token.
     */
    forgotPassword?: boolean;
    /**
     * Returns true if the user is an anonymous user, false otherwise.
     */
    isAnonymous?: boolean;
    /**
     * Returns true if the user is authenticated, false otherwise.
     */
    isAuthenticated?: boolean;
    /**
     * Returns true if the current user is being impersonated by another user, false otherwise.
     */
    isImpersonated?: boolean;
    /**
     * Gets/sets a list of possible duplicate users.
     */
    possibleDuplicateUsers?: User[];
    /**
     * Returns the redirect URL to the old contractor portal if the user logging in is a contractor.
     */
    redirect_uri?: string;
    /**
     * Gets the refresh token used to be issued a new access token.
     */
    refresh_token?: string;
    /**
     * Gets/sets roles for the authenticated user.
     */
    roles?: string[];
    /**
     * Gets the date/time the access token expires.
     */
    tokenExpires?: string;
    /**
     * The type of token issued.
     */
    token_type?: AuthenticationTokenType;
    /**
     * Information about the authenticated user.
     */
    user?: User;
}


export enum AuthenticationResponseType {
    Token = "token"
}

export namespace AuthenticationResponseType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "token";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "token" = 0
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(authenticationResponseType: AuthenticationResponseType.Type) {
        return AuthenticationResponseType.Values[authenticationResponseType];
    }
}

export interface AuthenticationResult { 
    /**
     * Gets/sets the actual user ID of the authenticated user.  This is null except when the user is being impersonated.  In that case,  this is the user ID of the user that was originally authenticated.
     */
    actualUserId?: string;
    /**
     * Returns true if the user was authenticated, false otherwise.
     */
    isAuthenticated?: boolean;
    /**
     * Gets/sets the roles of the authenticated user.
     */
    roles?: UserRole[];
    /**
     * Gets/sets the user ID of the authenticated user.  Null if the user was not authenticated.
     */
    userId?: string;
    /**
     * The username used to authenticate.
     */
    username?: string;
}

/**
 * Collection of details about the resulting authentication from a LDAP request.
 */
export interface DirectoryAccessProtocolAuthenticationResult extends AuthenticationResult { 
    /**
     * Turns the UserID property to read only.
     */
    userId?: string;
    /**
     * True if the user is authenticated.
     */
    isAuthenticated?: boolean;
    /**
     * Value retrieved from the common name column in LDAP.
     */
    commonName?: string;
    /**
     * Value retrieved from the mail column in LDAP.
     */
    email?: string;
}


export enum AuthenticationTokenType {
    Bearer = "bearer"
}

export namespace AuthenticationTokenType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "bearer";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "bearer" = 0
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(authenticationTokenType: AuthenticationTokenType.Type) {
        return AuthenticationTokenType.Values[authenticationTokenType];
    }
}

/**
 * Represents an authorization response in the system.
 */
export interface AuthorizationResponse extends ApiResponse { 
    isAuthorized?: boolean;
}


export interface Award { 
    altText?: string;
    awardId?: number;
    contents?: string;
    headline?: string;
    imageName?: string;
    url?: string;
    titleTag?: string;
}


export enum BaseSyncType {
    Agencies = "agencies",
    Agents = "agents",
    ContactClaimsCost = "contactClaimsCost",
    ContactClaimsNumber = "contactClaimsNumber",
    ContactOrders = "contactOrders",
    ContactRevenue = "contactRevenue",
    Contractors = "contractors",
    Policies = "policies",
    SalesTerritories = "salesTerritories",
    NonAgentUsers = "nonAgentUsers"
}

export namespace BaseSyncType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "agencies" | "agents" | "contactClaimsCost" | "contactClaimsNumber" | "contactOrders" | "contactRevenue" | "contractors" | "policies" | "salesTerritories" | "nonAgentUsers";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "agencies" = 1,
        "agents" = 2,
        "contactClaimsCost" = 3,
        "contactClaimsNumber" = 4,
        "contactOrders" = 5,
        "contactRevenue" = 6,
        "contractors" = 7,
        "policies" = 8,
        "salesTerritories" = 9,
        "nonAgentUsers" = 10
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(baseSyncType: BaseSyncType.Type) {
        return BaseSyncType.Values[baseSyncType];
    }
}

export interface BillingInfo { 
    address?: Address;
    bankAccountNumber?: number;
    bankName?: string;
    bankRoutingNumber?: number;
    billingAddress?: Address;
    buyersCreditAmount?: number;
    cards?: BasicCreditCardInfo[];
    cardSelectedForScf?: BasicCreditCardInfo;
    contractBalanceCurrentYear?: number;
    contractBalanceRenewalYear?: number;
    contractDatePaymentIsFor?: string;
    contractId?: string;
    contractMonthlyBalanceCurrentYear?: number;
    contractMonthlyBalanceRenewalYear?: number;
    contractMonthlyCostCurrentYear?: number;
    contractMonthlyCostRenewalYear?: number;
    contractTotalCostCurrentYear?: number;
    contractTotalCostRenewalYear?: number;
    creditCardNumber?: string;
    currentScfBillingAddress?: Address;
    expMonth?: string;
    expYear?: string;
    isCurrent?: boolean;
    isDefaultCard?: boolean;
    isPayingForMissingPayment?: boolean;
    isRenewalPayment?: boolean;
    lastFourCc?: string;
    mainPaymentProfile?: PaymentProfile;
    nameOnAccount?: string;
    nameOnCard?: string;
    newScfCardExpiration?: string;
    ownerName?: Name;
    paymentAmount?: number;
    paymentType?: PaymentType;
    phones?: Phone[];
    usePropertyAddressForBillingAddress?: boolean;
    userId?: string;
}
export interface BasicCreditCardInfo { 
    expiration?: string;
    isDefault?: boolean;
    lastFourCc?: string;
}


export interface BrochureRequest extends LeadRegistrationRequest { 
    language?: string;
    type?: LeadRegistrationTypes;
}


export interface BrochureResponse extends ApiResponse { 
    /**
     * The URL where the brochure can be accessed.
     */
    url?: string;
}


export enum CardType {
    Amex = "amex",
    DinersClub = "dinersClub",
    Discover = "discover",
    JCB = "jcb",
    MasterCard = "masterCard",
    Visa = "visa"
}

export namespace CardType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "amex" | "dinersClub" | "discover" | "jcb" | "masterCard" | "visa";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "amex" = 0,
        "dinersClub" = 1,
        "discover" = 2,
        "jcb" = 3,
        "masterCard" = 4,
        "visa" = 5
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(cardType: CardType.Type) {
        return CardType.Values[cardType];
    }
}

export interface ChangePasswordRequest { 
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
}


export interface ClaimQuestionAndAnswer { 
    /**
     * The answer to a claim question, human-readable.
     */
    answer?: string;
    /**
     * The ID of the answer to a claim question, or null if no ID is provided/possible.
     */
    answerId?: string;
    /**
     * The question, in human-readable language.
     */
    question?: string;
    /**
     * The ID of the question.
     */
    questionId?: string;
}


/**
 * Model representing a closing item.
 */
export interface ClosingItem { 
    /**
     * Flag for whether the closing item is currently active in the system or not.
     */
    active?: boolean;
    /**
     * Category that this closing item is associated with.
     */
    closingItemCategoryId?: string;
    /**
     * ID of the closing item.
     */
    id?: string;
    /**
     * Name of this closing item.
     */
    name?: string;
}


/**
 * Model representing a category for closing items.
 */
export interface ClosingItemCategory { 
    /**
     * ID of the closing item category.
     */
    id?: string;
    /**
     * Name of the closing item category.
     */
    name?: string;
}


/**
 * Response model containing the details of closing item categories.
 */
export interface ClosingItemCategoryResponse extends ApiResponse { 
    /**
     * Collection of closing item categories.
     */
    closingItemCategories?: ClosingItemCategory[];
}


/**
 * Response model containing the details of closing items.
 */
export interface ClosingItemResponse extends ApiResponse { 
    /**
     * Collection of closing items.
     */
    closingItems?: ClosingItem[];
}


export interface CompleteInvoiceRequest {
    /**
     * Gets/sets the contrat id of the purchase.
     */
    invoiceId?: string;
    /**
     * Gets/sets the payment information.
     */
    payment?: InvoicePayment;
}
/**
 * A response to an CompleteInvoiceResponse 
 */
export interface CompleteInvoiceResponse extends ApiResponse {
    /**
     * The resulting contract ID.
     */
    invoice?: Invoice;
    wasPaymentSuccessful?: boolean;
}
export interface CompleteOrderRequest { 
    /**
     * Gets/sets the contrat id of the purchase.
     */
    contractId?: string;
    /**
     * Gets/sets the payment information.
     */
    payment?: OrderPayment;
}


/**
 * A response to an Unpaid CompleteOrderRequest.
 */
export interface CompleteOrderResponse extends ApiResponse { 
    /**
     * The resulting contract ID.
     */
    contract?: Contract;
    wasPaymentSuccessful?: boolean;
}


export interface Contact { 
    contactId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    contractHolder?: boolean;
    canOpenClaim?: boolean;
    phone?: Phone;
}


export interface ContactPreferences { 
    sendRecordedContractInfo?: boolean;
    isOptedInToSms?: boolean;
    smsPhoneNumber?: string;
    wantsWelcomeCall?: boolean;
    welcomeCallDate?: string;
}


export interface ContactUs { 
    email?: string;
    message?: string;
    name?: string;
    phone?: string;
}


export interface Contract { 
    /**
     * Additional owners on the contract.
     */
    additionalOwners?: User[];
    /**
     * The address of the contract.
     */
    address?: Address;
    /**
     * An alternate contact address for the contract.
     */
    alternateContactAddress?: Address;
    /**
     * An alternate contact name for the contract.
     */
    alternateContactName?: Name;
    /**
     * The square footage of the property.
     */
    areaSqFt?: number;
    /**
     * The buyer agent for the contract.
     */
    buyerAgent?: any;
    /**
     * True if the contract can be renewed.
     */
    canRenew?: boolean;
    /**
     * True if the contract is upgradable.
     */
    canUpgrade?: boolean;
    /**
     * The ID of the contract.
     */
    contractId?: string;
    /**
     * The date/time the contract was put on hold.
     */
    contractOnHoldDate?: string;
    /**
     * The status of the contract.
     */
    contractStatus?: ContractStatus;
    /**
     * True if the contract can be set up for auto renew.
     */
    canSetupAutoRenew?: boolean;
    /**
     * A Guid if there is a default credit card id..
     */
    defaultCreditCardId?: string;
    /**
     * The default service call fee for the contract.
     */
    defaultServiceCallFee?: number;
    /**
     * The effective date of the contract.
     */
    effectiveDate?: string;
    /**
     * The escrow agent for the contract.
     */
    escrowAgent?: any;
    /**
     * The estimated date that th property will be closed on.
     */
    estimatedClosingDate?: string;
    /**
     * The expiration date of the contract (i.e. the last date of coverage)
     */
    expireDate?: string;
    /**
     * Is the home owner set to auto renew
     */
    hasAutoRenewed?: boolean;
    /**
     * The name of the homeowner.
     */
    homeownerName?: Name;
    /**
     * True if the contract is able to be cancelled.
     */
    isCancellable?: boolean;
    /**
     * True if the contract is on hold, false otherwise.
     */
    isContractOnHold?: boolean;
    /**
     * True if this contract is a listing coverage.
     */
    isListingCoverage?: boolean;
    /**
     * The date/time the contract was last modified.
     */
    lastModifiedDate?: string;
    /**
     * The monthly cost for this contract.
     */
    monthlyCost?: number;
    /**
     * The date the contract was ordered.
     */
    orderDate?: string;
    /**
     * The original policy id
     */
    originalPolicyId?: string;
    /**
     * The original policy payment method
     */
    paymentMethod?: string;
    /**
     * The name of the plan for the contract.
     */
    planName?: string;
    /**
     * The ID of the plan type for the contract.
     */
    planTypeId?: string;
    /**
     * The name of the plan type for the contract.
     */
    planTypeName?: string;
    /**
     * The policy number of the contract.
     */
    policyNumber?: number;
    /**
     * The property type for the property covered by the contract.
     */
    propertyType?: PropertyType;
    /**
     * The name of the person who purchased the contract.
     */
    purchasedByName?: Name;
    /**
     * The renewal date for the contract.
     */
    renewalDate?: string;
    /**
     * The date the contract was renewed.
     */
    renewedOnDate?: string;
    /**
     * The total cost for the contract.
     */
    totalCost?: number;
    /**
     * The user that owns the contract.
     */
    userId?: string;
    /**
     * The year the property was built.
     */
    yearBuilt?: number;
    /**
    * The total premiums paid on contract.
    */
    totalPremiumsPaid?: number;
}


export interface ContractAddon { 
    /**
     * The quantity of coverage to apply.
     */
    quantity?: number;
    /**
     * The id of the coverage to apply.
     */
    salesItemId?: string;
}


export interface ContractCoupon { 
    amount?: number;
    contractCouponId?: string;
    couponCode?: string;
}


export interface ContractCoverage { 
    /**
     * True if multiple of this item are allowed on the contract.
     */
    allowMultiple?: boolean;
    /**
     * The category that this contract item belongs to.
     */
    category?: string;
    /**
     * The price for a single unit of this addon.
     */
    cost?: number;
    /**
     * The description of this item.
     */
    description?: string;
    /**
     * The ID of the coverage.
     */
    id?: string;
    /**
     * True if this coverage is included by default on the contract.
     */
    isIncludedByDefault?: boolean;
    /**
     * True if this coverage is optional on the contract.
     */
    isOptional?: boolean;
    /**
     * The monthly (recurring) cost for a single unit of the item.
     */
    recurringCost?: number;
    /**
     * The ID of the sales item coverage.
     */
    salesItemCoverageId?: string;
}


export interface ContractFile { 
    contractFileId?: string;
    policyId?: string;
    description?: string;
    fileName?: string;
    uploadedBy?: string;
    uploadedDate?: string;
    sentTo?: string;
    agentUserId?: number;
    claimId?: string;
}


export interface ContractFiles { 
    contractFileId?: number;
    policyId?: number;
    description?: string;
    originalFileName?: string;
    systemFileName?: string;
    uploadedById?: number;
    uploadedDate?: string;
    size?: number;
    downloadedById?: number;
    downloadedDate?: string;
    sentTo?: string;
    agentUserId?: number;
    claimId?: number;
}


export interface ContractItemExtensions { 
}

export interface ContractItem { 
    /**
     * True if this item can be paid for with a montly payment, false if the item must be paid for up-front.
     */
    canBePaidMonthly?: boolean;
    /**
     * The contract ID the item is associated with.
     */
    contractId?: string;
    /**
     * The cost for a single unit of the item.
     */
    cost?: number;
    /**
     * The id of the coverage that this contract item represents.
     */
    coverageId?: string;
    /**
     * Gets/sets the date/time this record was created.
     */
    createDate?: string;
    /**
     * Gets/sets the user ID of the person who created this record.
     */
    createdBy?: string;
    /**
     * A description of the item.
     */
    description?: string;
    /**
     * A unique identifier for an item of the given type.
     */
    id?: string;
    /**
     * The monthly (recurring) cost for a single unit of the item.
     */
    monthlyCost?: number;
    /**
     * The quantity of the item on the contract.
     */
    quantity?: number;
    /**
     * The item type.
     */
    type?: ContractItemType;
}


export interface ContractItemRequest { 
    /**
     * Addons included in the quote.
     */
    addons?: ContractAddon[];
    /**
     * The amount of buyer's credit to include in the quote.
     */
    buyersCredit?: number;
    /**
     * The contract ID for which to retrieve contract items.
     */
    contractId?: string;
    /**
     * The coupon code included in the quote.
     */
    couponCode?: string;
    /**
     * True to include payment items for the contract, false otherwise.
     */
    includeContractItems?: ContractItemType[];
    /**
     * True if we are looking for converting listing coverage to buyers coverage.
     */
    isConvertingFromListingCoverage?: boolean;
    /**
     * True for listing coverage, false otherwise.
     */
    isListingCoverage?: boolean;
    /**
     * True if we are looking for current coverage to quote a renewal.
     */
    isRenewing?: boolean;
    /**
     * True if we are looking for coverage to a upgrade quote.
     */
    isUpgrading?: boolean;
    /**
     * The type of payment made.
     */
    paymentType?: PaymentType;
    /**
     * The plan to be quoted.
     */
    planId?: string;
    /**
     * The number of prepaid service call fees to quote.
     */
    prePaidServiceCallFees?: number;
    /**
     * The property being quoted.
     */
    property?: Property;
    /**
     * The selected service call price for the quote.
     */
    serviceCallPrice?: ServiceCallPrice;
}


export interface ContractItemResponse extends ApiResponse { 
    items?: ContractItem[];
    /**
     * Gets the monthly total sum of all the included items.
     */
    monthlyTotal?: number;
    timestamp?: string;
    /**
     * Gets the total sum of the associated items or 0 if there is none.
     */
    total?: number;
}


export enum ContractItemType {
    Unknown = "unknown",
    Plan = "plan",
    Addon = "addon",
    Coupon = "coupon",
    Credit = "credit",
    PrepaidService = "prepaidService",
    Discount = "discount",
    Surcharge = "surcharge",
    Payment = "payment",
    ServiceFee = "serviceFee"
}

export namespace ContractItemType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "plan" | "addon" | "coupon" | "credit" | "prepaidService" | "discount" | "surcharge" | "payment" | "serviceFee";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "unknown" = 0,
        "plan" = 1,
        "addon" = 2,
        "coupon" = 3,
        "credit" = 4,
        "prepaidService" = 5,
        "discount" = 6,
        "surcharge" = 7,
        "payment" = 8,
        "serviceFee" = 9
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(contractItemType: ContractItemType.Type) {
        return ContractItemType.Values[contractItemType];
    }
}

export interface ContractOffer { 
    /**
     * Gets/sets whether or not this plan can be offered as a listing coverage.
     */
    canBeListingCoverage?: boolean;
    /**
     * Gets/sets the contract offer id.
     */
    contractOfferId?: string;
    /**
     * Gets/sets the total cost of this offer.
     */
    cost?: number;
    /**
     * Items on the offer.
     */
    coverages?: ContractCoverage[];
    /**
     * For a renewal offer, the list of optional SalesItem Guids that the current contract has
     */
    includedAddonIds?: string[];
    /**
     * Gets/sets whether this offer represents "keeping my current coverage".
     */
    isCurrentCoverage?: boolean;
    /**
     * Gets/sets the monthly cost for this offer.
     */
    monthlyCost?: number;
    /**
     * Gets/sets the name of this offer.
     */
    name?: string;
    /**
     * Gets/sets the plan id for this offer.
     */
    planId?: string;
    /**
     * Gets/sets the service call prices for the offer.
     */
    serviceCallPrices?: ServiceCallPrice[];
}


export interface Contractor { 
    companyName?: string;
    contractorId?: string;
    userId?: string;
    files?: ContractorFiles[];
}


export interface ContractorApplicationInfo { 
    contractor?: any;
    contractorAddress?: string;
    contractorEntity?: any;
    contractorPhoneNumbers?: any[];
}


export interface ContractorApplicationRequest { 
    agreeFastCashTerms?: boolean;
    agreeTerms?: boolean;
    authorizedSignee?: string;
    businessFax?: Phone;
    businessPhone?: Phone;
    companyName?: string;
    email?: string;
    emergencyNumber?: Phone;
    firstName?: string;
    inUniform?: boolean;
    isFastCash?: boolean;
    isUnion?: boolean;
    lastName?: string;
    mailingAddress?: Address;
    markedVehicles?: boolean;
    officeStaff?: boolean;
    serviceAreas?: MarketAreaSearchResponse[];
    trades?: TradeSelection[];
    unionLocalNumber?: number;
    unionLocalDesignation?: string;
}


export interface ContractorApplicationResponse extends ApiResponse { 
    /**
     * The new contractor id after they apply
     */
    contractorId?: string;
}


export interface ContractorDispatchSearchRequest extends IContractorDispatchRequest { 
    contractorId?: string;
    contractorStatus?: string;
    dispatchNumber?: string;
    invoiceSearchFromDate?: string;
    invoiceSearchToDate?: string;
    isAscending?: boolean;
    page?: number;
    resultsPerPage?: number;
    sortThisBy?: ContractorDispatchSearchSortBy;
}


export interface ContractorDispatchSearchResponse extends ApiResponse { 
    contractorStatus?: string;
    invoiceSearchFromDate?: string;
    invoiceSearchToDate?: string;
    invoiceTotalAmount?: number;
    isAscending?: boolean;
    items?: ContractorDispatchSearchResponseItem[];
    page?: number;
    resultsPerPage?: number;
    searchCriteria?: string;
    sortThisBy?: ContractorDispatchSearchSortBy;
    totalItems?: number;
}


export interface ContractorDispatchSearchResponseItem extends ContractorDispatchUpdateRequest { 
    address?: Address;
    authorizationReceivedOn?: string;
    customerName?: string;
    dispatchId?: string;
    dispatchNumber?: string;
    dispatchStatus?: string;
    dispatchStatusId?: number;
    email?: string;
    files?: ContractFiles[];
    invoiceAmount?: number;
    invoiceDate?: string;
    notes?: DispatchNote[];
    /**
     * Cost of the items purchased by Landmark.
     */
    openDate?: string;
    purchaseOrderCost?: number;
    phones?: Phone[];
    preAuthorizeAmount?: number;
    /**
     * 
     */
    preAuthorizeAmountOverride?: number;
    scheduledEndTime?: string;
    scheduledStartTime?: string;
    shipping?: number;
    tax?: number;
    tradeId?: string;
    wasAuthorizationSubmitted?: boolean;
}


export enum ContractorDispatchSearchSortBy {
    Default = "default",
    OpenDate = "openDate"
}

export namespace ContractorDispatchSearchSortBy {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "default" | "openDate";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "default" = 0,
        "openDate" = 1
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(contractorDispatchSearchSortBy: ContractorDispatchSearchSortBy.Type) {
        return ContractorDispatchSearchSortBy.Values[contractorDispatchSearchSortBy];
    }
}

export interface ContractorDispatchUpdateRequest extends IContractorDispatchRequest { 
    contractorId?: string;
    contractorStatus?: string;
    discountAmount?: number;
    isAuthorizationSubmission?: boolean;
    serviceCallFeeCollectedAmount?: number;
    scheduledDate?: string;
    scheduledWindowEnd?: string;
    scheduledWindowStart?: string;
    wasServiceCallFeeCollected?: boolean;
    dispatchParts?: DispatchPart[];
}


export interface ContractorFiles { 
    contractorFileId?: string;
    contractorFileName?: string;
    contractorFileDescription?: string;
    uploadeddate?: string;
    contractorId?: string;
}


export interface ContractorScore { 
    stateScores?: StateScore[];
}


export interface ContractorSearchResponse { 
    address?: Address;
    contractorId?: string;
    contractorUserId?: string;
    inactive?: boolean;
    email?: string;
    companyName?: string;
    selected?: boolean;
}


export interface ContractorServiceAgreementResponse extends ApiResponse { 
    /**
     * The date/time the service agreement was agreed to, or null.
     */
    serviceAgreementDate?: string;
}


export interface ContractResponse extends ApiResponse { 
    /**
     * A list of contracts for the user.
     */
    contracts?: Contract[];
}


export interface ContractSelections { 
    /**
     * Gets/sets the list of optional SalesItem Guids on the offer.
     */
    addonIds?: string[];
    /**
     * Gets/sets the contract id.
     */
    contractId?: string;
    /**
     * Gets/sets the plan id.
     */
    planId?: string;
    /**
     * service call price for the offer.
     */
    serviceCallPrice?: ServiceCallPrice;
    /**
     * price breakdown for the contract offer
     */
    priceBreakdown?: ContractItemResponse;
}


export interface ContractStatus { 
    contractStatusId?: string;
    description?: string;
}


export enum ContractStatusType {
    Unknown = "unknown",
    NewOrderExternal = "newOrderExternal",
    NewOrderOSR = "newOrderOSR",
    NewOrderInternal = "newOrderInternal",
    Incomplete = "incomplete",
    WaitingForPayment = "waitingForPayment",
    Completed = "completed",
    Cancelled = "cancelled",
    Renewable = "renewable",
    Renewed = "renewed",
    Expired = "expired",
    FutureRenewal = "futureRenewal"
}

export namespace ContractStatusType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "newOrderExternal" | "newOrderOSR" | "newOrderInternal" | "incomplete" | "waitingForPayment" | "completed" | "cancelled" | "renewable" | "renewed" | "expired" | "futureRenewal";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "unknown" = 0,
        "newOrderExternal" = 1,
        "newOrderOSR" = 2,
        "newOrderInternal" = 3,
        "incomplete" = 4,
        "waitingForPayment" = 5,
        "completed" = 6,
        "cancelled" = 7,
        "renewable" = 8,
        "renewed" = 9,
        "expired" = 10,
        "futureRenewal" = 97
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(contractStatusType: ContractStatusType.Type) {
        return ContractStatusType.Values[contractStatusType];
    }
}

export enum CoverageItemType {
    
    /**
     * An itemized item representing a coverage included or not included depending on the coverage list it is in.
     */
    CoverageItem = "coverageItem",
    
    /**
     * An block of text used to describe or explain rules and limits of a coverage or lack there of, depending on the list it is in.
     */
    TextBlock = "textBlock"
}

export namespace CoverageItemType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "coverageItem" | "textBlock";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        
        /**
         * An itemized item representing a coverage included or not included depending on the coverage list it is in.
         */
        "coverageItem" = 0,
        
        /**
         * An block of text used to describe or explain rules and limits of a coverage or lack there of, depending on the list it is in.
         */
        "textBlock" = 1
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(coverageItemType: CoverageItemType.Type) {
        return CoverageItemType.Values[coverageItemType];
    }
}

export interface CoverageResponse extends ApiResponse { 
    /**
     * A list of coverages.
     */
    coverages?: ContractCoverage[];
}


export enum CoverageType {
    Buyer = "buyer",
    Homeowner = "homeowner",
    Renewal = "renewal",
    SellerAndBuyer = "seller_and_Buyer"
}

export namespace CoverageType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "buyer" | "homeowner" | "renewal" | "seller_and_Buyer";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "buyer" = 0,
        "homeowner" = 1,
        "renewal" = 2,
        "seller_and_Buyer" = 3
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(coverageType: CoverageType.Type) {
        return CoverageType.Values[coverageType];
    }
}

export interface CreateContractResponse extends ApiResponse { 
    contractId?: string;
    contractNumber?: string;
}


export interface CreatePartnerRequest { 
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    role?: PartyType;
    agencyName?: string;
    agencyLocation?: AgencyLocation;
    licenseNumber?:  string;
}


export interface CreatePendingContractPaymentRequest { 
    billingInfo?: BillingInfo;
    creditCardTransactionResponse?: CreditCardTransactionResponse;
    note?: string;
    policyStartDatePaymentIsFor?: string;
}


export interface CreatePendingContractPaymentResponse { 
    paymentId?: number;
}


export interface CreatePendingServiceRequestPaymentRequest { 
    billingInfo?: BillingInfo;
    contractId?: string;
    isPayingWithBuyersCredit?: boolean;
    isServiceCallFee?: boolean;
    paymentAmount?: number;
    serviceRequestId?: number;
    userId?: string;
    usingNewCard?: boolean;
}


export interface CreatePendingServiceRequestPaymentResponse extends ApiResponse { 
    pendingPaymentId?: number;
}


export interface CreateSRRequest { 
    billingInfo?: BillingInfo;
    brand?: string;
    contractId?: number;
    firstNoticed?: string;
    hasManufacturersWarranty?: boolean;
    isTermites?: boolean;
    message?: string;
    numUnits?: number;
    portalTradeId?: number;
    serviceCallFee?: number;
    tradeId?: number;
    waterHeaterSize?: string;
}


export interface Credentials { 
    username?: string;
    password?: string;
}


export interface CreditCard { 
    cardType?: CardType;
    creditCardId?: string;
    expirationDate?: string;
    lastFour?: string;
    number?: string;
    /**
     * CSC, CVV, CVC, etc. Security code.  https://en.wikipedia.org/wiki/Card_security_code
     */
    securityCode?: string;
    /**
     * List of Policy Numbers (not IDs) that have this Credit Card as the default payment method.
     */
    policyNumber?: number[];
}


export interface CreditCardTransactionResponse { 
    customerProfileId?: number;
    customerScfProfileId?: number;
    customerPaymentProfileId?: number;
    customerScfPaymentProfileId?: number;
    transactionId?: string;
    scfTransactionId?: string;
    authorizationCode?: string;
    scfAuthorizationCode?: string;
    messages?: string;
    successful?: boolean;
}


export interface Customer { 
    id?: string;
    user?: User;
}


export enum DiscountDispositionType {
    DiscountDisposition = "discountDisposition",
    SRForgivenessDispostion = "srForgivenessDispostion",
    MilitaryDiscount = "militaryDiscount",
    CustomerServiceError = "customerServiceError",
    OtherDiscountissue = "otherDiscountissue",
    OtherSRForgivnessError = "otherSRForgivnessError",
    RealtorDiscount = "realtorDiscount",
    RealtorSRForgiveness = "realtorSRForgiveness",
    EmployeeDiscount = "employeeDiscount",
    RenewalDiscount = "renewalDiscount",
    Coupon = "coupon",
    TransferDiscount = "transferDiscount",
    PaymenttoContractor = "paymenttoContractor",
    PaymenttoVendor = "paymenttoVendor",
    PaymenttoHomeOwner = "paymenttoHomeOwner",
    AutoRenewDiscount = "autoRenewDiscount"
}

export namespace DiscountDispositionType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "discountDisposition" | "srForgivenessDispostion" | "militaryDiscount" | "customerServiceError" | "otherDiscountissue" | "otherSRForgivnessError" | "realtorDiscount" | "realtorSRForgiveness" | "employeeDiscount" | "renewalDiscount" | "coupon" | "transferDiscount" | "paymenttoContractor" | "paymenttoVendor" | "paymenttoHomeOwner" | "autoRenewDiscount";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "discountDisposition" = 1,
        "srForgivenessDispostion" = 2,
        "militaryDiscount" = 4,
        "customerServiceError" = 5,
        "otherDiscountissue" = 6,
        "otherSRForgivnessError" = 7,
        "realtorDiscount" = 8,
        "realtorSRForgiveness" = 9,
        "employeeDiscount" = 10,
        "renewalDiscount" = 11,
        "coupon" = 12,
        "transferDiscount" = 13,
        "paymenttoContractor" = 14,
        "paymenttoVendor" = 15,
        "paymenttoHomeOwner" = 16,
        "autoRenewDiscount" = 17
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(discountDispositionType: DiscountDispositionType.Type) {
        return DiscountDispositionType.Values[discountDispositionType];
    }
}

export interface DispatchCompleteModel { 
    claimID?: string;
    contractor?: string;
    description?: string;
    fullAddress?: string;
    name?: Name;
    policyId?: string;
    policyNumber?: number;
    workOrderNumber?: number;
}


export interface DispatchNote { 
    createdDate?: string;
    isNew?: boolean;
    text?: string;
}


/**
 * A special note representing the data for a dispatch part.
 */
export interface DispatchPart { 
    /**
     * The ID of the note.
     */
    id?: string;
    /**
     * The details of the part the note represents.
     */
    partDetails?: DispatchPartDetails;
}


/**
 * Details about a part being added to a dispatch.
 */
export interface DispatchPartDetails { 
    /**
     * The closing item associated with this part.
     */
    closingItem?: ClosingItem;
    /**
     * Closing item category associated with this part.
     */
    closingItemCategory?: ClosingItemCategory;
    /**
     * Labor costs for installing the part.
     */
    costPerHour?: number;
    /**
     * Number of hours the installation of the part took.
     */
    hoursWorked?: number;
    /**
     * Flag for whether the part is being provided by the contractor.
     */
    isProvidedByContractor?: boolean;
    /**
     * The model number of the part being added.
     */
    modelNumber?: string;
    /**
     * The Part number of the part being added.
     */
    partNumber?: string;
    /**
     * The cost per unit.
     */
    pricePerUnit?: number;
    /**
     * The serial number of the part being added.
     */
    serialNumber?: string;
    /**
     * The tax cost per unit.
     */
    taxPerUnit?: number;
    /**
     * The number of units ordered.
     */
    units?: number;
    /**
     * Flag for whether the part was added by the contractor.
     */
    wasAddedByContractor?: boolean;
}


/**
 * Represents an authorization response in the system.
 */
export interface DispatchStatisticsResponse { 
    contractorId?: string;
    contractorRides?: ContractorScore;
    cost?: ContractorScore;
    hoursToAccept?: ContractorScore;
    hoursToSetAppointment?: ContractorScore;
    openDispatches?: ContractorScore;
    osrCalls?: ContractorScore;
    referralsWaiting?: ContractorScore;
    scheduledVisits?: ContractorScore;
    sevenDayDispatches?: ContractorScore;
    speedOfService?: ContractorScore;
    voiceOfCustomer?: ContractorScore;
    workCompleteDispatches?: ContractorScore;
}


export enum DispatchStatuses {
    AttemptedToSchedule = "Attempted To Schedule",
    Authorized = "Authorized",
    Canceled = "Canceled",
    CashInLieu = "Cash in lieu",
    FactoryBackOrder = "Factory back-ordered parts",
    NotSet = "Not Set",
    Paid = "Paid",
    Pending = "Pending",
    PendingAutho = "Pending Autho",
    Scheduled = "Scheduled",
    SubmittedForBilling = "Submitted for Billing",
    WaitingForCustomerDecision = "Waiting for Customer Decision",
    WaitingForInspection = "Waiting for inspection",
    WaitingOnParts = "Waiting On Parts",
    WorkComplete = "Work Complete"
}

export namespace DispatchStatuses {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "Attempted To Schedule" | "Authorized" | "Canceled" | "Cash in lieu" | "Factory back-ordered parts" | "Not Set" | "Paid" | "Pending" | "Pending Autho" | "Scheduled" | "Submitted for Billing" | "Waiting for Customer Decision" | "Waiting for inspection" | "Waiting On Parts" | "Work Complete";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "Attempted To Schedule" = 0,
        "Authorized" = 1,
        "Canceled" = 2,
        "Cash in lieu" = 3,
        "Factory back-ordered parts" = 4,
        "Not Set" = 5,
        "Paid" = 6,
        "Pending" = 7,
        "Pending Autho" = 8,
        "Scheduled" = 9,
        "Submitted for Billing" = 10,
        "Waiting for Customer Decision" = 11,
        "Waiting for inspection" = 12,
        "Waiting On Parts" = 13,
        "Work Complete" = 14
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(dispatchStatuses: DispatchStatuses.Type) {
        return DispatchStatuses.Values[dispatchStatuses];
    }
}

export interface DispatchSurveyInfo { 
    commentsForContractor?: string;
    commentsForLandmark?: string;
    contractorCompanyName?: string;
    contractorEmail?: string;
    contractorRating?: number;
    landmarkRating?: number;
    workOrderNumber?: number;
}


export interface DispatchTicketFilesResponse extends ApiResponse { 
    files?: ContractFile[];
}


export interface DispatchTicketSearchRequest { 
    contractorId?: number;
    page?: number;
    resultsPerPage?: number;
}


export interface DispatchTicketSearchResponse extends ApiResponse { 
    items?: DispatchTicketSearchResponseItem[];
    page?: number;
    resultsPerPage?: number;
}

export interface DispatchTicketSearchResponseItem { 
    address?: Address;
    closeDate?: string;
    contractorStatus?: string;
    customerName?: string;
    dispatchTicketNumber?: string;
    dispatchTicketStatus?: string;
    notes?: string;
    openDate?: string;
}


/**
 * Model representing Closed dispatch tickets used for averaging ratings for contractors.
 */
export interface DispatchTicketStatisticData { 
    acceptDate?: string;
    appointmentScheduledDate?: string;
    closeDate?: string;
    contractorRating?: number;
    contractState?: string;
    responseDate?: string;
    workOrderId?: string;
    workOrderCreatedDate?: string;
    workOrderModifiedDate?: string;
    workOrderStatusId?: string;
    workOrderTradeId?: string;
    workOrderWorkCompleteDate?: string;
    totalWorkOrderCost?: number;
    tradeDescription?: string;
}


export enum DtStatus {
    Open = "open",
    SentToContractor = "sentToContractor",
    PendingLhwApproval = "pendingLhwApproval",
    Approved = "approved",
    Denied = "denied",
    Cancelled = "cancelled",
    CompletePendingPayment = "completePendingPayment",
    Batched = "batched",
    Invoiced = "invoiced",
    Paid = "paid",
    CompleteNoBill = "completeNoBill",
    PaidCIL = "paidCIL",
    WorkComplete = "workComplete"
}

export namespace DtStatus {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "open" | "sentToContractor" | "pendingLhwApproval" | "approved" | "denied" | "cancelled" | "completePendingPayment" | "batched" | "invoiced" | "paid" | "completeNoBill" | "paidCIL" | "workComplete";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "open" = 1,
        "sentToContractor" = 2,
        "pendingLhwApproval" = 3,
        "approved" = 4,
        "denied" = 5,
        "cancelled" = 6,
        "completePendingPayment" = 7,
        "batched" = 8,
        "invoiced" = 9,
        "paid" = 10,
        "completeNoBill" = 11,
        "paidCIL" = 12,
        "workComplete" = 13
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(dtStatus: DtStatus.Type) {
        return DtStatus.Values[dtStatus];
    }
}

export enum DtStatusType {
    Open = "open",
    Closed = "closed",
    WorkComplete = "workComplete",
    All = "all"
}

export namespace DtStatusType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "open" | "closed" | "workComplete" | "all";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "open" = 0,
        "closed" = 1,
        "workComplete" = 2,
        "all" = 3
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(dtStatusType: DtStatusType.Type) {
        return DtStatusType.Values[dtStatusType];
    }
}

export interface EditProfileRequest { 
    user?: User;
    contractor?: Contractor;
}


export interface EditProfileResponse { 
    user?: User;
    contractor?: Contractor;
}


export interface EmailSent { 
    hasSent?: boolean;
}


export enum RootEntityType {
    Agent = "agent",
    Branch = "branch",
    ClosingOfficer = "closing_Officer",
    ClosingOfficerCoordinator = "closing_Officer_Coordinator",
    Contractor = "contractor",
    Distributor = "distributor",
    DistributorOffice = "distributorOffice",
    InspectionCompany = "inspection_Company",
    InspectionEmployee = "inspection_Employee",
    LoanOfficer = "loan_Officer",
    MortgageCompany = "mortgage_Company",
    RealEstateCompany = "real_Estate_Company",
    RealEstateOfficeCoordinator = "real_Estate_Office_Coordinator",
    SalesRep = "sales_Rep",
    TitleCompany = "title_Company"
}

export namespace RootEntityType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "agent" | "branch" | "closing_Officer" | "closing_Officer_Coordinator" | "contractor" | "distributor" | "distributorOffice" | "inspection_Company" | "inspection_Employee" | "loan_Officer" | "mortgage_Company" | "real_Estate_Company" | "real_Estate_Office_Coordinator" | "sales_Rep" | "title_Company";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "agent" = 0,
        "branch" = 1,
        "closing_Officer" = 2,
        "closing_Officer_Coordinator" = 3,
        "contractor" = 4,
        "distributor" = 5,
        "distributorOffice" = 6,
        "inspection_Company" = 7,
        "inspection_Employee" = 8,
        "loan_Officer" = 9,
        "mortgage_Company" = 10,
        "real_Estate_Company" = 11,
        "real_Estate_Office_Coordinator" = 12,
        "sales_Rep" = 13,
        "title_Company" = 14
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(rootEntityType: RootEntityType.Type) {
        return RootEntityType.Values[rootEntityType];
    }
}
export enum PersonEntityType {
    Agent = "agent",
    ClosingOfficer = "closing_Officer",
    ClosingOfficerCoordinator = "closing_Officer_Coordinator",
    InspectionEmployee = "inspection_Employee",
    LoanOfficer = "loan_Officer",
    RealEstateOfficeCoordinator = "real_Estate_Office_Coordinator"
}

export namespace PersonEntityType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "agent" | "closing_Officer" | "closing_Officer_Coordinator" | "inspection_Employee" | "loan_Officer" | "real_Estate_Office_Coordinator";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "agent" = 0,
        "closing_Officer" = 1,
        "closing_Officer_Coordinator" = 2,
        "inspection_Employee" = 3,
        "loan_Officer" = 4,
        "real_Estate_Office_Coordinator" = 5
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(personEntityType: PersonEntityType.Type) {
        return PersonEntityType.Values[personEntityType];
    }
}
export enum InitiatingEntityType {
    Homeowner = "homeowner",
    BuyerAgent = "buyer_Agent",
    NotSet = "not_Set",
    PropertyManager = "property_Manager",
    TitleCompany = "title_Company",
    LoanOfficer = "loan_Officer",
    SellerAgent = "seller_Agent",
    InspectionCompany = "inspection_Company",
    AgentAssistant = "agentAssistant"
}

export namespace InitiatingEntityType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "homeowner" | "buyer_Agent" | "not_Set" | "property_Manager" | "title_Company" | "loan_Officer" | "seller_Agent" | "inspection_Company" | "agentAssistant";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "homeowner" = 0,
        "buyer_Agent" = 1,
        "not_Set" = 2,
        "property_Manager" = 3,
        "title_Company" = 4,
        "loan_Officer" = 5,
        "seller_Agent" = 6,
        "inspection_Company" = 7,
        "agentAssistant" = 8
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(initiatingEntityType: InitiatingEntityType.Type) {
        return InitiatingEntityType.Values[initiatingEntityType];
    }
}

export interface Error { 
    code?: number;
    key?: string;
    message?: string;
}


export interface ErrorCodes { 
}
export interface Categories { 
}
export interface Reasons { 
}


export interface ErrorResponse extends ApiResponse { 
}


export interface Executive { 
    department?: string;
    description?: string;
    executiveId?: string;
    fullName?: string;
    imageUrl?: string;
    links?: Link[];
    title?: string;
}
export interface Link { 
    type?: LinkType;
    url?: string;
}


export interface ExistingPartnerRequest {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    role?: PartyType;
    agencyName?: string;
    postalCode?: string;
}
export interface FailedPaymentListResponse extends ApiResponse { 
    /**
     * Collection of payments with a balance remaining.
     */
    invoicesWithBalance?: InvoiceInfo[];
    /**
     * Flag for whether the card is on hold.
     */
    cardIsOnHold?: boolean;
    /**
     * Flag for whether there is any failed payments on the card(Like a decline).
     */
    cardHasFailedAttempts?: boolean;
    /**
     * Whether there is a card on file or not.
     */
    cardOnFile?: boolean;
}


/**
 * Request to retrieve failed payment request
 */
export interface FailedPaymentRequest { 
    /**
     * The customer ID associated with this request.
     */
    customerId?: string;
}


/**
 * An application feature.
 */
export interface Feature { 
    /**
     * The description of the feature.
     */
    description?: string;
    /**
     * True if the feature is currently enabled, false otherwise.
     */
    isEnabled?: boolean;
    /**
     * A unique key for the feature.
     */
    key?: string;
}


export namespace FeatureKey.Contract {
    /**
     * Upgrading a contract.
     */
    export const upgrade: string = "Contract:Upgrade";
}

export namespace FeatureKey.LimitsAndExclusions {
    /**
     * Limits and exclusions of a plan line item.
     */
    export const details: string = "LimitsAndExclusions:Details";
}

export namespace FeatureKey.Location {
    /**
     * Geocoding of an address.
     */
    export const geocoding: string = "Location:Geocoding";
}

export namespace FeatureKey.Payment {
    /**
     * Management of customer credit card information.
     */
    export const manageCreditCard: string = "Payment:ManageCreditCard";
}

export interface FeatureResponse extends ApiResponse { 
    /**
     * A list of features in the system.
     */
    features?: Feature[];
    /**
     * True if the list of features is currently being updated, false otherwise.
     */
    isUpdating?: boolean;
}


export interface FileContents { 
    mimeType?: string;
    fileName?: string;
    contents?: number[];
}


export interface Flowchart { 
    initialId?: string;
    nodes?: { [key: string]: FlowchartNode; };
    terminalId?: string;
}


/**
 * A flowchart-based node.
 */
export interface FlowchartNode { 
    /**
     * A list of child IDs to this node.
     */
    childrenIds?: string[];
    /**
     * The ID of this node.
     */
    id?: string;
    /**
     * The order of this node, in relation to other nodes.
     */
    order?: number;
    /**
     * The type of node.
     */
    type?: FlowchartNodeType;
    /**
     * The value of this node.
     */
    value?: string;
}


/**
 * An answer type for flowchart-based questions.
 */

export enum FlowchartNodeType {
    
    /**
     * Answer type could not be determined.
     */
    Unknown = "unknown",
    
    /**
     * A possible answer to a question.
     */
    Answer = "answer",
    
    /**
     * The question's answer must be a date.
     */
    Date = "date",
    
    /**
     * The question answer is a star-rating, with 1-5 stars as the value.
     */
    FiveStar = "fiveStar",
    
    /**
     * The initial node of the flowchart.
     */
    Initial = "initial",
    
    /**
     * The question is a message to display to the user.
     */
    Message = "message",
    
    /**
     * The question is a modal message that must be acknowledged before moving on.
     */
    ModalMessage = "modalMessage",
    
    /**
     * The user can enter multiple lines of freeform text.
     */
    MultiLine = "multiLine",
    
    /**
     * The user can select one or more options from the possible answers.
     */
    MultiSelect = "multiSelect",
    
    /**
     * The question's answer must be a number.
     */
    Number = "number",
    
    /**
     * The user can select exactly one option from the possible answers.
     */
    Select = "select",
    
    /**
     * The terminal node of the flowchart.
     */
    Terminal = "terminal",
    
    /**
     * The user can enter a single line of freeform text.
     */
    Text = "text"
}

export namespace FlowchartNodeType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "answer" | "date" | "fiveStar" | "initial" | "message" | "modalMessage" | "multiLine" | "multiSelect" | "number" | "select" | "terminal" | "text";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        
        /**
         * Answer type could not be determined.
         */
        "unknown" = 0,
        
        /**
         * A possible answer to a question.
         */
        "answer" = 1,
        
        /**
         * The question's answer must be a date.
         */
        "date" = 2,
        
        /**
         * The question answer is a star-rating, with 1-5 stars as the value.
         */
        "fiveStar" = 3,
        
        /**
         * The initial node of the flowchart.
         */
        "initial" = 4,
        
        /**
         * The question is a message to display to the user.
         */
        "message" = 5,
        
        /**
         * The question is a modal message that must be acknowledged before moving on.
         */
        "modalMessage" = 6,
        
        /**
         * The user can enter multiple lines of freeform text.
         */
        "multiLine" = 7,
        
        /**
         * The user can select one or more options from the possible answers.
         */
        "multiSelect" = 8,
        
        /**
         * The question's answer must be a number.
         */
        "number" = 9,
        
        /**
         * The user can select exactly one option from the possible answers.
         */
        "select" = 10,
        
        /**
         * The terminal node of the flowchart.
         */
        "terminal" = 11,
        
        /**
         * The user can enter a single line of freeform text.
         */
        "text" = 12
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(flowchartNodeType: FlowchartNodeType.Type) {
        return FlowchartNodeType.Values[flowchartNodeType];
    }
}

export interface ForgotPasswordResponse extends ApiResponse { 
    email?: string;
}


export interface ForumEntry { 
    id?: number;
    author?: string;
    avatar?: string;
    categoryForumId?: number;
    /**
     * Content of the forum entry. When setting content, all instances of http://docs.landmarkhw.com will be replaced with https://docs.landmarkhw.com.  In the future, when we can figure out how to migrate all the http links to https, This should replace all http:// with https://.  This will, for now, help us stay security compliant for the things we do control.
     */
    content?: string;
    description?: string;
    forum?: string;
    publishDate?: string;
    title?: string;
    imageURL?: string;
}


export interface ForumRequest { 
    count?: number;
    forumId?: number;
    isSummary?: boolean;
    messageId?: number;
}


export interface ForumResponse extends ApiResponse { 
    count?: number;
    entries?: ForumEntry[];
    forumId?: number;
    isSummary?: boolean;
}


export interface GeocodingResponse extends ApiResponse { 
    /**
     * Landmark Address with Geocoded address
     */
    address?: Address;
    /**
     * Source of where the geocoding request was made.
     */
    geocodingSource?: GeocodingSources;
    /**
     * Flag to notify that there is a not found error code.
     */
    hasNotFoundError?: boolean;
    /**
     * Collection of info messages recieved in the process of Geocoding.
     */
    infoMessages?: { [key: string]: string; };
    /**
     * Additional data needed from geo coding/melissa data for WM address table
     */
    location?: Location;
    /**
     * Error to signify that the attempt to find addresses produced no results.
     */
    notFoundError?: Error;
}


export enum GeocodingSources {
    Google = "google",
    MelissaData = "melissaData"
}

export namespace GeocodingSources {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "google" | "melissaData";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "google" = 0,
        "melissaData" = 1
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(geocodingSources: GeocodingSources.Type) {
        return GeocodingSources.Values[geocodingSources];
    }
}

export interface GetContractOffersRequest { 
    contractId?: string;
    dwellingTypeId?: string;
    isConvertingFromListingCoverage?: boolean;
    isUpgrading?: boolean;
    planTypeId?: string;
    stateCode?: string;
}


/**
 * A response object for retrieving contract offers for a given type of contract.
 */
export interface GetContractOffersResponse { 
    /**
     * The list of offers.
     */
    offers?: ContractOffer[];
    /**
     * currently selected options
     */
    current?: ContractSelections;
}


export interface GetPaymentProfileRequest { 
    contractId?: string;
    userId?: string;
    last4CreditCard?: string;
    expiration?: string;
}


export interface GetProfileTokenRequest {
    customerId?: string;
    commUrl?: string;
    urlType?: string;
    authorizeNetCustomerProfileId?: string;
    email?: string;
    description?: string;
}
export namespace Geocoding {
    export namespace Google {
        /**
         * Geocoding response from Google.
         */
        export class GoogleGeocodingResponse {
            /**
             * Gets the Geocoding source as Google.
             */
            public geocodingSource: GeocodingSources;
        }
    }
}
export interface HomeownerSurveyInfo { 
    name?: string;
    address?: string;
    email?: string;
    homePhone?: string;
    workPhone?: string;
    mobilePhone?: string;
    otherPhone?: string;
}


export interface HomeServicesInterest { 
    isInterested?: boolean;
}


export interface IContractorDispatchRequest { 
    contractorId?: string;
}

export enum InspectionType {
    Unknown = "unknown",
    CustomerDeclinedInspection = "customer_Declined_Inspection",
    CustomerIsSendingInInspection = "customer_is_Sending_in_Inspection",
    Waived = "waived",
    Mechanical = "mechanical",
    RequestInspectionFromAgent = "request_Inspection_from_Agent",
    Professional = "professional"
}

export namespace InspectionType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "customer_Declined_Inspection" | "customer_is_Sending_in_Inspection" | "waived" | "mechanical" | "request_Inspection_from_Agent" | "professional";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "unknown" = 0,
        "customer_Declined_Inspection" = 1,
        "customer_is_Sending_in_Inspection" = 2,
        "waived" = 3,
        "mechanical" = 4,
        "request_Inspection_from_Agent" = 5,
        "professional" = 6
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(inspectionType: InspectionType.Type) {
        return InspectionType.Values[inspectionType];
    }
}

export interface Invoice {
    id: string;
    invoiceId?: string;
    policyNumber?: number;
    invoiceNumber?: number;
    description?: string;
    dueDate?: string;
    amount?: number;
    amountPaid?: number;
    amountDue?: number;
    invoiceType?: string;
    claimNumber?: number;
    workOrderNumber?: number;
    customerId?: string;
}
/**
 * Response representing a payment that failed.
 */
export interface InvoiceInfo { 
    /**
     * The amount due in the invoice.
     */
    amount?: number;
    /**
     * The ID of the contract associated with this invoice.
     */
    contractId?: string;
    /**
     * The ID of the invoice.
     */
    invoiceId?: string;
}


export interface InvoiceItem { 
    amount?: number;
    description?: string;
    quantity?: number;
    revenueAccountType?: LedgerAccountType;
    sortOrder?: number;
}

export interface InvoiceItemRequest { 
    invoiceId?: string;
    invoiceItems?: InvoiceItem[];
}


export interface InvoiceItemResponse { 
    invoiceItemIds?: string[];
}


export interface InvoicePayment {
    billingAddress?: Address;
    billingAddressSameAsProperty?: boolean;
    billingFirstName?: string;
    billingLastName?: string;
    comments?: string;
    confirmationEmailAddresses?: string[];
    creditCardNumber?: string;
    expiration?: string;
    isPayingNow?: boolean;
    mainPaymentProfile?: PaymentProfile;
    payingParty?: PartyType;
    paymentAmount?: number;
    securityCode?: number;
    /**
     * The type of payment being made.
     */
    type?: PaymentType;
}
export enum InvoicePaymentMethod {
    Cash = "cash",
    Check = "check",
    ContractorInvoice = "contractorInvoice",
    CreditCard = "creditCard",
    ECheck = "eCheck",
    PolicyCredit = "policyCredit",
    Transfer = "transfer"
}

export namespace InvoicePaymentMethod {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "cash" | "check" | "contractorInvoice" | "creditCard" | "eCheck" | "policyCredit" | "transfer";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "cash" = 0,
        "check" = 1,
        "contractorInvoice" = 2,
        "creditCard" = 3,
        "eCheck" = 4,
        "policyCredit" = 5,
        "transfer" = 6
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(invoicePaymentMethod: InvoicePaymentMethod.Type) {
        return InvoicePaymentMethod.Values[invoicePaymentMethod];
    }
}

export interface InvoicePaymentRequest { 
    amount?: number;
    authorizeNetAuthorizationCode?: string;
    authorizeNetTransactionId?: string;
    invoiceId?: string;
    paymentInfo?: PaymentInfo;
    paymentMethod?: InvoicePaymentMethod;
    paymentReceivedDate?: string;
}


export interface InvoicePaymentResponse { 
    invoicePaymentId?: string;
    invoicePaymentItemId?: string;
}


export interface InvoiceRequest { 
    amount?: number;
    contractId?: string;
    customerId?: string;
    claimId?: string;
    invoiceItems?: InvoiceItem[];
    invoiceSubType?: InvoiceSubType;
    invoiceType?: InvoiceType;
    isUpgrading?: boolean;
}


export interface InvoiceResponse { 
    invoiceId?: string;
    invoiceItemIds?: string[];
}


export interface InvoiceResult {
    assistantId?: string;
    buyerAgent?: Name;
    buyerAgentUserId?: string;
    contractId?: string;
    defaultServiceCallFee?: number;
    escrowAgent?: Name;
    expirationDate?: string;
    homeownerName?: Name;
    isCancellable?: boolean;
    isListingCoverage?: boolean;
    orderDate?: string;
    ownerUserId?: string;
    planName?: string;
    policyNumber?: number;
    primaryStatus?: ContractStatus;
    propertyAddress?: Address;
    purchasedById?: string;
    purchasedByName?: Name;
    sellerAgentUserId?: string;
    startDate?: string;
    titleCompanyAgentUserId?: string;
    titleCompanyId?: string;
}
export enum InvoiceSubType {
    Collection = "collection",
    Escrow = "escrow",
    Full = "full",
    OneMonth = "oneMonth",
    PolicyChange = "policyChange",
    ThreePay = "threePay",
    TwoMonth = "twoMonth"
}

export namespace InvoiceSubType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "collection" | "escrow" | "full" | "oneMonth" | "policyChange" | "threePay" | "twoMonth";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "collection" = 0,
        "escrow" = 1,
        "full" = 2,
        "oneMonth" = 3,
        "policyChange" = 4,
        "threePay" = 5,
        "twoMonth" = 6
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(invoiceSubType: InvoiceSubType.Type) {
        return InvoiceSubType.Values[invoiceSubType];
    }
}

export enum InvoiceType {
    Cancellation = "cancellation",
    Inspection = "inspection",
    PortableRental = "portableRental",
    Premium = "premium",
    ServiceFee = "serviceFee",
    Upgrade = "upgrade"
}

export namespace InvoiceType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "cancellation" | "inspection" | "portableRental" | "premium" | "serviceFee" | "upgrade";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "cancellation" = 0,
        "inspection" = 1,
        "portableRental" = 2,
        "premium" = 3,
        "serviceFee" = 4,
        "upgrade" = 5
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(invoiceType: InvoiceType.Type) {
        return InvoiceType.Values[invoiceType];
    }
}

export interface LeadRegistrationRequest { 
    couponCode?: string;
    email?: string;
    address?: string;
    firstName?: string;
    homePhone?: string;
    lastName?: string;
    contactName?: string;
    postalCode?: string;
    leadBrochureTypeId?: string;
    type?: LeadRegistrationTypes;
    squareFootage?: number;
    dwellingTypeId?: string;
    planId?: string;
    coverageLength?: number;
    coverageType?: string;
    marketingSourceId?: string;
}


export enum LeadRegistrationTypes {
    Brochure = "brochure",
    IncompleteOrder = "incompleteOrder"
}

export namespace LeadRegistrationTypes {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "brochure" | "incompleteOrder";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "brochure" = 0,
        "incompleteOrder" = 1
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(leadRegistrationTypes: LeadRegistrationTypes.Type) {
        return LeadRegistrationTypes.Values[leadRegistrationTypes];
    }
}

export enum LedgerAccountType {
    PolicyCredits = "policyCredits",
    ServiceFeeRevenue = "serviceFeeRevenue",
    Upgrades = "upgrades",
    WarrantyPremiumDiscounts = "warrantyPremiumDiscounts",
    WarrantyPremiums = "warrantyPremiums"
}

export namespace LedgerAccountType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "policyCredits" | "serviceFeeRevenue" | "upgrades" | "warrantyPremiumDiscounts" | "warrantyPremiums";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "policyCredits" = 0,
        "serviceFeeRevenue" = 1,
        "upgrades" = 2,
        "warrantyPremiumDiscounts" = 3,
        "warrantyPremiums" = 4
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(ledgerAccountType: LedgerAccountType.Type) {
        return LedgerAccountType.Values[ledgerAccountType];
    }
}

/**
 * An item that contains some text that will be used to describe an item in a list.
 */
export interface ValueItem { 
    /**
     * Text content that represents the value of this item in a list.
     */
    value?: string;
}

/**
 * Details displayed in the factoids section.
 */
export interface FactoidItem extends ValueItem { 
    /**
     * The title of the factoid.
     */
    title?: string;
}

/**
 * An item displayed in a coverage section.
 */
export interface CoverageItem extends ValueItem { 
    /**
     * The type used to decide how to display the provided item.    A text block will be displayed as a paragraph.  A coverage item will be displayed in a list format with a Checkmark or a X depending on which section it is in.
     */
    type?: CoverageItemType;
}

/**
 * Collection of facts associated with this coverage item.
 */
export interface Factoids { 
    /**
     * Items that will be shown in the factoid section.
     */
    items?: FactoidItem[];
    /**
     * Image URL that will be used in the factoid section.
     */
    imageUrl?: string;
}

/**
 * List of items shown in a coverage list.
 */
export interface CoverageList { 
    /**
     * Collection of coverage items in this coverage list.
     */
    items?: CoverageItem[];
}

/**
 * Coverage list for the upgrade section.
 */
export interface UpgradeCoverageList extends CoverageList { 
    /**
     * Title of the upgrade.
     */
    title?: string;
}

/**
 * List of covered coverage items.
 */
export interface CoveredCoverageList extends CoverageList { 
    /**
     * Collection of upgrades associated with this coverage item.
     */
    upgrade?: UpgradeCoverageList[];
}

/**
 * Details on the limits and exclusions of this coverage item.
 */
export interface LimitsAndExclusions { 
    name?: string;
    /**
     * Items displayed in the Factoids section.
     */
    factoids?: Factoids;
    /**
     * Items that fall under the coverage of this coverage item.
     */
    covered?: CoveredCoverageList;
    /**
     * Items that do not fall under the coverage of this coverage item.
     */
    notCovered?: CoverageList;
    /**
     * Individual items in the limits section.
     */
    limits?: ValueItem[];
    /**
     * Content that will be filled into the legal terms of use section.
     */
    legal?: ValueItem[];
}


export enum LinkType {
    None = "none",
    Twitter = "twitter",
    Facebook = "facebook",
    GooglePlus = "googlePlus",
    LinkedIn = "linkedIn",
    YouTube = "youTube",
    Flickr = "flickr"
}

export namespace LinkType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "none" | "twitter" | "facebook" | "googlePlus" | "linkedIn" | "youTube" | "flickr";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "none" = 0,
        "twitter" = 1,
        "facebook" = 2,
        "googlePlus" = 3,
        "linkedIn" = 4,
        "youTube" = 5,
        "flickr" = 6
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(linkType: LinkType.Type) {
        return LinkType.Values[linkType];
    }
}

export interface Location { 
    /**
     * Latitude of the address
     */
    latitude?: string;
    /**
     * Longitude of the address.
     */
    longitude?: string;
}


export interface MakeCreditCardPaymentRequest { 
    billingInfo?: BillingInfo;
}


export interface MakeCreditCardPaymentResponse { 
    anetAuthorizationCode?: string;
    anetTransactionId?: string;
    message?: string;
    paymentAmount?: number;
    policyPaymentId?: string;
}


export interface MarketAreaSearchResponse { 
    zipCode?: string;
    city?: string;
    state?: string;
    areaName?: string;
    areaId?: string;
}


export namespace Geocoding {
    export namespace MelissaData {
        /**
         * Response provided by MelissaData. It adds some new details on top of the data already provided by a GeocodingResponse.
         */
        export class MelissaDataResponse {
            /**
             * Errors that are associated with a problem with the city input.
             */
            public cityErrors: Error[];
        
            /**
             * Marks this Geocoding Source to be Melissa Data
             */
            public geocodingSource: GeocodingSources;
        
            /**
             * Errors that are associated with a problem with the address in general.
             */
            public generalAddressErrors: Error[];
        
            /**
             * Flag to notify that there is errors with the city input.
             */
            public hasCityErrors: boolean;
        
            /**
             * Flag to notify that there is errors with the address in general.
             */
            public hasGeneralAddressErrors: boolean;
        
            /**
             * Flag to notify that there is errors with the postal code.
             */
            public hasPostalCodeErrors: boolean;
        
            /**
             * Flag to notify that there is errors with street1.
             */
            public hasStreet1Errors: boolean;
        
            /**
             * Flag to notify that there is errors with street2.
             */
            public hasStreet2Errors: boolean;
        
            /**
             * Errors that are associated with the postal code input.
             */
            public postalCodeErrors: Error[];
        
            /**
             * Errors that are associated with the street1 input.
             */
            public street1Errors: Error[];
        
            /**
             * Errors that are associated with the street2 input.
             */
            public street2Errors: Error[];
        }
    }
}
export interface MergeUserRequest { 
    /**
     * The user id of the user who will receive assets (contracts) from the merged users.
     */
    targetUserId?: string;
    /**
     * The user id to use for user information (address, name, phone numbers).
     */
    userIdForUserInfo?: string;
    /**
     * The user id of the users who will be merged with the target user.
     */
    userIdsToBeMergedAndDisabled?: string[];
}


export interface MonthlyScore { 
    totalCount?: number;
    average?: number;
}


export interface Name { 
    firstName?: string;
    fullName?: string;
    lastName?: string;
    middleInitials?: string;
    salutation?: string;
    suffix?: string;
}


export interface Note { 
    id?: string;
    createdDate?: string;
    recipient?: string;
    subject?: string;
    message?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
}


export interface NotesResponse extends ApiResponse { 
    notes?: Note[];
    serviceRequestId?: string;
}


export interface NoticesResponse extends ApiResponse { 
    notices?: AccountNotice[];
}


export enum OldStatusType {
    ClosingInfoPending = "closingInfoPending",
    Expired = "expired",
    Active = "active",
    NewApplication = "newApplication",
    Suspended = "suspended",
    MechanicalInspectionPending = "mechanicalInspectionPending",
    PaymentPending = "paymentPending",
    ScheduleInspection = "scheduleInspection",
    ProfessionalHomeInspectionPending = "professionalHomeInspectionPending",
    InspectionOrderNotSent = "inspectionOrderNotSent",
    Cancelled = "cancelled"
}

export namespace OldStatusType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "closingInfoPending" | "expired" | "active" | "newApplication" | "suspended" | "mechanicalInspectionPending" | "paymentPending" | "scheduleInspection" | "professionalHomeInspectionPending" | "inspectionOrderNotSent" | "cancelled";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "closingInfoPending" = 0,
        "expired" = 1,
        "active" = 2,
        "newApplication" = 3,
        "suspended" = 4,
        "mechanicalInspectionPending" = 5,
        "paymentPending" = 6,
        "scheduleInspection" = 7,
        "professionalHomeInspectionPending" = 8,
        "inspectionOrderNotSent" = 9,
        "cancelled" = 10
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(oldStatusType: OldStatusType.Type) {
        return OldStatusType.Values[oldStatusType];
    }
}

/**
 * This class was created because we went from using 2 Authorize.Net accounts  (1 for contract payments, and 1 for service request payments) into a single  account. Accounting still needs to be able to tell which payments are from  contract revenue vs. service request revenue, and they like it split up by  state. We gather this info and pass it through to Authorize.Net whenever we  make a payment so when Accounting runs their reports to reconcile the  General Ledger, they have more helpful information.
 */
export interface OrderIdentification { 
    invoiceNumber?: string;
    paymentType?: PaymentTypes;
    stateCode?: string;
}


export interface OrderPayment { 
    billingAddress?: Address;
    billingAddressSameAsProperty?: boolean;
    billingFirstName?: string;
    billingLastName?: string;
    comments?: string;
    confirmationEmailAddresses?: string[];
    creditCardNumber?: string;
    expiration?: string;
    isPayingNow?: boolean;
    mainPaymentProfile?: PaymentProfile;
    payingParty?: PartyType;
    paymentAmount?: number;
    securityCode?: number;
    /**
     * The type of payment being made.
     */
    type?: PaymentType;
	authNetCustomerProfileId?:string;
    authNetPayProfileId?:string;
}


export interface OrderReceivedInvoiceInfo { 
    buyerAgent?: any;
    escrowOfficer?: any;
    estimatedCloseDate?: string;
    effectiveDate?: string;
    isPayingNow?: boolean;
    isRenewal?: boolean;
    isUpgrading?: boolean;
    homeownerWaitPeriod?: number;
    originalPolicyEndDate?: string;
    ownerName?: Name;
    paymentMethod?: string;
    policyAddress?: string;
    policyId?: string;
    policyNumber?: number;
    renewalEffectiveDate?: string;
    renewalExpireDate?: string;
    sellerAgent?: any;
}


/**
 * A request to place an order for a contract.
 */
export interface OrderRequest { 
    /**
     * Gets/sets addons for the order.
     */
    addons?: ContractAddon[];
    /**
     * The assistant for the order, if any.
     */
    assistant?: PartnerSearchResponse;
    /**
     * The buyer agent for the order, if any.
     */
    buyerAgent?: PartnerSearchResponse;
    /**
     * Gets/sets the amount the ordering party would like to add on as buyer's credit.
     */
    buyersCredit?: number;

    /**
    * Returns true if current date is less than 02-01-2023.
    */
    canOrder?: boolean;


    /**
     * Gets/sets the customer's contact preferences.
     */
    contactPreferences?: ContactPreferences;
    /**
     * Gets/sets a list of contacts.
     */
    contacts?: Contact[];
    /**
     * Gets/sets a previous contract to be renewed (if any).
     */
    contractId?: string;
    /**
     * Gets/sets the coupon for the request.
     */
    coupon?: ContractCoupon;
     /**
     * Gets/sets the coverageLength for the request.
     */
    coverageLength?: number;
    /**
     * Gets/sets the amount the ordering party would like to use of the policy credit.
     */
    creditAmountToBeConsumed?: number;
    /**
     * Gets/sets the estimated close date of the purchase.
     */
    estimatedCloseDate?: string;
    /**
     * Gets/sets the customer's interest in home services.
     */
    homeServicesInterest?: HomeServicesInterest;
    /**
     * Indicates that they are auto renewing there coverage.
     */
    isAutoRenew?: boolean;
    isConvertingFromListingCoverage?: boolean;
    /**
     * Gets/sets if the user is renewing their current policy.
     */
    isKeepingCurrentCoverage?: boolean;
    /**
     * Gets/sets if this is listing coverage.
     */
    isListingCoverage?: boolean;
    /**
     * Gets/sets if the contract was a recent purchase.
     */
    isRecentPurchase?: boolean;
    /**
     * Indicates that they are y renewing there coverage.
     */
    isRenewing?: boolean;
    /**
     * Gets/sets if the user is upgrading the contract.
     */
    isUpgrading?: boolean;
    loanOfficer?: PartnerSearchResponse;
    /**
     * Gets/sets the ordering party type, if any.
     */
    orderingPartyType?: PartyType;
    /**
     * Gets/sets the contract owner.
     */
    owner?: Customer;
    /**
     * Gets boolean if different address than mailing address.
     */
    ownerAddressIsDifferentFromProperty?: boolean;
    /**
     * Gets/sets payment information.
     */
    payment?: OrderPayment;
    /**
     * Specify the chosen payment option to use for the order.
     */
    paymentOption?: PaymentOption;
    /**
     * Gets/sets plan ID for the contract.
     */
    planId?: string;
    /**
     * Gets/sets plan ID for the contract.
     */
    marketingSourceId?: string;
    /**
     * Gets/sets the number of pre-paid service call fees to order.
     */
    prepaidServiceCallFees?: number;
    /**
     * Gets/sets the property for the order.
     */
    property?: Property;
    /**
     * True to remove AC coverage from the policy, false otherwise.
     */
    removeACCoverage?: boolean;
    /**
     * Gets/sets the seller of the home.  Used for Listing Coverage.
     */
    seller?: Customer;
    /**
     * Gets/sets the seller agent for the order, if any.
     */
    sellerAgent?: PartnerSearchResponse;
    /**
     * Gets/sets the selected service call price.
     */
    serviceCallPrice?: ServiceCallPrice;
    /**
     * has the contract owner asked to use any or all available credit for a payment
     */
    shouldUseCredit?: boolean;
    /**
     * Gets/sets the title company agent for the order. if any.
     */
    titleCompany?: PartnerSearchResponse;
	/**
     * Gets/sets the title company agent for the order. if any.
     */
    isAddressVerified?: boolean;
}


/**
 * A response to an OrderRequest.
 */
export interface OrderResponse extends ApiResponse { 
    /**
     * The resulting contract ID.
     */
    contractId?: string;
    wasPaymentSuccessful?: boolean;
	isListingCoverage?: boolean;
	isWebPolicy?: boolean;
}


export interface OrderResult { 
    assistantId?: string;
    buyerAgent?: Name;
    buyerAgentUserId?: string;
    contractId?: string;
    defaultServiceCallFee?: number;
    escrowAgent?: Name;
    expirationDate?: string;
    homeownerName?: Name;
    isCancellable?: boolean;
    isListingCoverage?: boolean;
    orderDate?: string;
    ownerUserId?: string;
    planName?: string;
    policyNumber?: number;
    primaryStatus?: ContractStatus;
    propertyAddress?: Address;
    purchasedById?: string;
    purchasedByName?: Name;
    sellerAgentUserId?: string;
    startDate?: string;
    titleCompanyAgentUserId?: string;
    titleCompanyId?: string;
}


export interface Organization { 
    country?: string;
    email?: string;
    name?: string;
    phone_number?: string;
    service_types?: { [key: string]: string; };
    timezone?: string;
    url?: string;
}


export interface Part { 
    id?: number;
    name?: string;
    productId?: number;
}


export interface PartnerSearchResponse { 
    address?: any;
    agentId?: string;
    branchId?: string;
    companyId?: string;
    email?: string;
    entityType?: string;
    locationName?: string;
    name?: string;
    selected?: boolean;
    userType?: string;
}


export enum PartyType {
    Unknown = "unknown",
    HomeBuyer = "homeBuyer",
    TitleCompany = "titleCompany",
    HomeSeller = "homeSeller",
    BuyerAgent = "buyerAgent",
    SellerAgent = "sellerAgent",
    Assistant = "assistant",
    Lender = "lender",
    AgentSplit = "agentSplit"
}

export namespace PartyType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "homeBuyer" | "titleCompany" | "homeSeller" | "buyerAgent" | "sellerAgent" | "assistant" | "lender" | "agentSplit";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "unknown" = 0,
        "homeBuyer" = 2,
        "titleCompany" = 4,
        "homeSeller" = 7,
        "buyerAgent" = 9,
        "sellerAgent" = 10,
        "assistant" = 11,
        "lender" = 12,
        "agentSplit" = 13
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(partyType: PartyType.Type) {
        return PartyType.Values[partyType];
    }
}

export interface PaymentInfo { 
    address?: Address;
    creditCard?: CreditCard;
    /**
     * When dealing with multiple payment infos this defines  whether this is the primary/default payment method
     */
    isDefault?: boolean;
    name?: Name;
    /**
     * The Authorize.Net payment profile ID
     */
    paymentProfileId?: string;
}


export interface PaymentInfoUpdateResponse { 
    /**
     * The Authorize.Net customer profile ID
     */
    customerProfileId?: string;
    paymentInfo?: PaymentInfo;
}


export enum PaymentMethod {
    Escrow = "escrow",
    MonthlyWithCreditCard = "monthlyWithCreditCard",
    PaymentInFull = "paymentInFull",
    ThreeMonthPlan = "threeMonthPlan",
    ThreeMonthPlanWithCreditCard = "threeMonthPlanWithCreditCard"
}

export namespace PaymentMethod {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "escrow" | "monthlyWithCreditCard" | "paymentInFull" | "threeMonthPlan" | "threeMonthPlanWithCreditCard";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "escrow" = 0,
        "monthlyWithCreditCard" = 1,
        "paymentInFull" = 2,
        "threeMonthPlan" = 3,
        "threeMonthPlanWithCreditCard" = 4
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(paymentMethod: PaymentMethod.Type) {
        return PaymentMethod.Values[paymentMethod];
    }
}

/**
 * Collection of possible payment options that can be presented to a user.
 */

export enum PaymentOption {
    
    /**
     * Run the payment now.
     */
    PayNow = "payNow",
    
    /**
     * Create a scheduled payment.
     */
    PayLater = "payLater",
    
    /**
     * Pay using waived funds(Used by Reps)
     */
    FavorFund = "favorFund",
    
    /**
     * Pay with a coupon.
     */
    Coupon = "coupon",
    
    /**
     * Pay directly to contractor.
     */
    PayToContractor = "payToContractor",
    
    /**
     * If a property manager is involved, bill directly to the property manager.
     */
    BillNow = "billNow"
}

export namespace PaymentOption {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "payNow" | "payLater" | "favorFund" | "coupon" | "payToContractor" | "billNow";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        
        /**
         * Run the payment now.
         */
        "payNow" = 0,
        
        /**
         * Create a scheduled payment.
         */
        "payLater" = 1,
        
        /**
         * Pay using waived funds(Used by Reps)
         */
        "favorFund" = 2,
        
        /**
         * Pay with a coupon.
         */
        "coupon" = 3,
        
        /**
         * Pay directly to contractor.
         */
        "payToContractor" = 4,
        
        /**
         * If a property manager is involved, bill directly to the property manager.
         */
        "billNow" = 5
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(paymentOption: PaymentOption.Type) {
        return PaymentOption.Values[paymentOption];
    }
}

export interface PaymentProfile { 
    customerProfileId?: number;
    customerScfProfileId?: number;
    customerPaymentProfileId?: number;
    customerScfPaymentProfileId?: number;
    lastFourCc?: string;
    expiration?: string;
}


/**
 * Request used to make a payment.
 */
export interface PaymentRequest { 
    /**
     * The amount due in the invoice.
     */
    amount?: number;
    /**
     * The contract credit amount to be used for the payment.
     */
    creditAmountToBeConsumed?: number;
    /**
     * The ID of the invoice.
     */
    invoiceId?: string;
    /**
     * Whether the person making this payment is a Customer (vs. an Entity)
     */
    isPersonACustomer?: boolean;
    /**
     * Info of the payment method.
     */
    paymentInfo?: PaymentInfo;
    paymentType?: PaymentTypes;
    /**
     * The ID of the person that is paying.
     */
    personId?: string;
    /**
     * The address of the property that the payment is being made for.  The accounting team needs to separate payments by state and they  care about the state that the property is located in.
     */
    propertyAddress?: Address;
    scheduledPaymentDate?: string;
    /**
     * has the contract owner asked to use any or all available credit for a payment
     */
    shouldUseCredit?: boolean;
}


export interface PaymentResponse { 
    /**
     * The Authorize.Net customer profile ID
     */
    customerProfileId?: string;
    invoicePaymentId?: string;
    invoicePaymentItemId?: string;
    /**
     * The payment info that was created/updated based on the information sent  in the PaymentRequest
     */
    paymentInfo?: PaymentInfo;
    wasPaymentSuccessful?: boolean;
}


export enum PaymentType {
    Escrow = "escrow",
    Full = "full",
    Monthly = "monthly",
    Partial = "partial",
    ServiceCallFee = "serviceCallFee"
}

export namespace PaymentType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "escrow" | "full" | "monthly" | "partial" | "serviceCallFee";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "escrow" = 0,
        "full" = 1,
        "monthly" = 2,
        "partial" = 3,
        "serviceCallFee" = 4
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(paymentType: PaymentType.Type) {
        return PaymentType.Values[paymentType];
    }
}

export enum PaymentTypes {
    Unknown = "unknown",
    Contract = "contract",
    ServiceCall = "serviceCall"
}

export namespace PaymentTypes {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "contract" | "serviceCall";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "unknown" = 0,
        "contract" = 1,
        "serviceCall" = 2
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(paymentTypes: PaymentTypes.Type) {
        return PaymentTypes.Values[paymentTypes];
    }
}

export interface Phone { 
    isPrimary?: boolean;
    number?: string;
    type?: PhoneType;
}


export enum PhoneType {
    Mobile = "mobile",
    Home = "home",
    Work = "work",
    Other = "other",
    Fax = "fax"
}

export namespace PhoneType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "mobile" | "home" | "work" | "other" | "fax";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "mobile" = 0,
        "home" = 1,
        "work" = 2,
        "other" = 3,
        "fax" = 4
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(phoneType: PhoneType.Type) {
        return PhoneType.Values[phoneType];
    }
}

export enum Status {
    Active = "active",
    Expired = "expired",
    Cancelled = "cancelled",
    Pending = "pending",
    Suspended = "suspended",
    PendingCancel = "pending_Cancel"
}

export namespace Status {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "active" | "expired" | "cancelled" | "pending" | "suspended" | "pending_Cancel";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "active" = 0,
        "expired" = 1,
        "cancelled" = 2,
        "pending" = 3,
        "suspended" = 4,
        "pending_Cancel" = 5
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(status: Status.Type) {
        return Status.Values[status];
    }
}

export interface ProcessedPaymentInfo { 
    anetTransactionId?: string;
    maskedCardNumber?: string;
    payerName?: Name;
    paymentAmount?: number;
    policyId?: string;
    policyNumber?: number;
    policyTotalCost?: number;
    propertyAddress?: string;
}


export interface Product { 
    id?: string;
    name?: string;
    parts?: Part[];
    productFamilyId?: number;
}


export interface ProductFamily { 
    id?: string;
    name?: string;
    products?: Product[];
    tradeId?: string;
}


export interface Property { 
    /**
     * The address key from Geo Coding/Melissa Data.
     */
    addressKey?: string;
    /**
     * The address of the property being warranted.
     */
    address?: Address;
    /**
     * The type of dwelling for the contract.
     */
    dwellingTypeId?: string;
    /**
     * The Latitude from Geo Coding/Melissa Data.
     */
    location?: Location;
    /**
     * The square footage of the property interior.
     */
    squareFootage?: number;
}


export interface PropertyType { 
    description?: string;
    dwellingTypeId?: string;
    isSingleFamilyProperty?: boolean;
    numberOfUnits?: number;
}


/**
 * A request to place an quick order for a contract.
 */
export interface QuickOrderRequest { 
    /**
     * Gets/sets the amount the ordering party would like to spend.
     */
    budgetAmount?: number;
    /**
     * Gets/setsa list of Guids to find out the closest Guid to the entered price.
     */
    planIds?: { [key: string]: string[]; };
    /**
     * Gets/sets the square footage on quick order.
     */
    squareFootage?: number;
}


/**
 * A response to an QuickOrderRequest.
 */
export interface QuickOrderResponse { 
    /**
     * The resulting plan ID.
     */
    planId?: string;
    /**
     * The buyers credit if we have one.
     */
    buyersCredit?: number;
    /**
     * The true base price with offer included add ons.
     */
    basePrice?: number;
}


export enum RenewalStatus {
    DoNotRenew = "doNotRenew",
    Empty = "empty",
    Monitored = "monitored",
    OutOfServiceArea = "outOfServiceArea",
    NotMonitored = "notMonitored",
    Reviewed = "reviewed"
}

export namespace RenewalStatus {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "doNotRenew" | "empty" | "monitored" | "outOfServiceArea" | "notMonitored" | "reviewed";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "doNotRenew" = 0,
        "empty" = 1,
        "monitored" = 2,
        "outOfServiceArea" = 3,
        "notMonitored" = 4,
        "reviewed" = 5
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(renewalStatus: RenewalStatus.Type) {
        return RenewalStatus.Values[renewalStatus];
    }
}

export interface Review { 
    altText?: string;
    companyName?: string;
    contents?: string;
    name?: string;
    titleTag?: string;
    rating?: number;
    reviewId?: number;
}


export interface SalesRep { 
    cellPhone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    salesRepId?: string;
    territory?: string;
    titleTag?: string;
}


export interface Schedule { 
    days?: any[];
    startTime?: string;
    endTime?: string;
    isAfterHours?: boolean;
}


/**
 * Service action types for a contractor.
 */

export enum ServiceActionType {
    Repair = "repair",
    Replace = "replace",
    Denial = "denial",
    NotCovered = "notCovered"
}

export namespace ServiceActionType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "repair" | "replace" | "denial" | "notCovered";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "repair" = 0,
        "replace" = 1,
        "denial" = 2,
        "notCovered" = 3
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(serviceActionType: ServiceActionType.Type) {
        return ServiceActionType.Values[serviceActionType];
    }
}

/**
 * Represents a service call fee, and how it affects the contract price.
 */
export interface ServiceCallPrice { 
    /**
     * Gets/sets the actual service call fee.
     */
    fee?: number;
    /**
     * Gets/sets the price that is added/removed from the contract price.
     */
    priceOffset?: number;
    /**
     * Gets/sets the recurring (monthly) price that is added/removed from the contract price.
     */
    recurringPriceOffset?: number;
}


export interface ServiceRequest { 
    /**
     * The ID of the contract this service request belongs to.
     */
    contractId?: string;
    /**
     * The date/time the service request was opened.
     */
    createdDate?: string;
    /**
     * A description of the service request.
     */
    description?: string;
    /**
     * The policy number of the contract for this service request.
     */
    policyNumber?: number;
    /**
     * The ID of this service request.
     */
    serviceRequestId?: string;
    /**
     * The status of the service request.
     */
    status?: string;
    /**
     * The work order number for the service request.
     */
    workOrderNumber?: number;
}


export interface ServiceRequestNoteRequest { 
    serviceRequestId?: string;
    note?: Note;
}


export interface ServiceRequestRequest { 
    /**
     * ID of the policy associated with the service request.
     */
    contractId?: string;
    /**
     * Information about the payment that will be made for the service request.
     */
    paymentInfo?: PaymentInfo;
    /**
     * Specify the chosen payment option to use for the order.
     */
    paymentOption?: PaymentOption;
    /**
     * List of questions and answers that the user filled out to help describe the problem.
     */
    questionsAndAnswers?: ClaimQuestionAndAnswer[];
    /**
     * Opting in for SMS text.
     */
    smsOptIn?: boolean;
    /**
     * Phone number for SMS Text.
     */
    smsPhoneNumber?: string;
    /**
     * Warranty Item associated with the claim.
     */
    warrantyItemId?: string;
    /**
     * Number of AC units to tune up.
     */
    tuneUpUnits?: number;
}


export interface SetPolicyDefaultPaymentMethodRequest { 
    policyId?: string;
    creditCardId?: string;
}


/**
 * Request used to make a payment.
 */
export interface SetPolicyDefaultPaymentMethodRequest { 
    /**
     * The ID of the policy.
     */
    policyId?: string;
    /**
     * The ID of the credit card that is being used as the policy's new default
     */
    creditCardId?: string;
}


export interface ShortId { 
}


export interface SmsOptIn { 
    id?: string;
    smsPhoneNumber?: string;
}


/**
 * A state within the country.
 */
export interface State { 
    /**
     * The description of the state.
     */
    description?: string;
    /**
     * True if the state is one where we do business, false otherwise.
     */
    isMarketState?: boolean;
    /**
     * The 2-digit state code.
     */
    stateCode?: string;
    /**
     * The ID of the state.
     */
    stateId?: string;
}


export interface StateScore { 
    state?: string;
    tradeScores?: TradeScore[];
}


export interface StreemSignupRequest {
    FirstName: string;
    LastName: string;
    Email: string;
    Company: string;
    Phone: string;
}
export interface SurveyResultQuestionAndAnswer { 
    answerId?: string;
    answerScore?: number;
    answerScorePossible?: number;
    answerText?: string;
    questionId?: string;
    questionText?: string;
    surveySubject?: string;
}


export interface Trade { 
    categoryDescriptions?: { [key: string]: string; };
    portalTradeId?: number;
    description?: string;
    productFamilies?: ProductFamily[];
    tradeId?: string;
    webView?: boolean;
}


export interface TradeScore { 
    benchmarkScore?: number;
    monthlyScore?: { [key: string]: MonthlyScore; };
    trade?: string;
    tradeId?: string;
}


export interface TradeSelection { 
    afterHoursRate?: number;
    hourlyRate?: number;
    licenseMonth?: number;
    licenseNumber?: string;
    licenseYear?: number;
    notes?: string;
    productFamilies?: number[];
    schedules?: Schedule[];
    tradeId?: number;
}


export interface UpdateCreditCardInfoRequest { 
    billingInfo?: BillingInfo;
}


export interface UpdateCreditCardInfoResponse { 
    card?: BasicCreditCardInfo;
    creditCardTransactionResponse?: CreditCardTransactionResponse;
    message?: string;
    paymentId?: number;
}


export interface UpgradeNotice extends AccountNotice { 
    policyCreditMessage?: string;
}


/**
 * A user in the system.
 */
export interface User { 
    /**
     * The user's physical/mailing address.
     */
    address?: Address;
    /**
     * The user's email address.
     */
    email?: string;
    /**
     * The date/time that this record was updated.
     */
    lastUpdated?: string;
    /**
     * The user's name.
     */
    name?: Name;
    /**
     * The user's phone numbers.
     */
    phones?: Phone[];
    /**
     * The SMS opt in bool.
     */
    isOptedInToSms?: boolean;
    /**
     * If the user is an RE Pro, we want to include their partner information on their user.
     */
    partnerInfo?: PartnerSearchResponse;
    /**
     * The ID of the user.
     */
    userId?: string;
    /**
     * The user type.
     */
    userType?: UserType;
}


export interface UserRole { 
    /**
     * Gets/sets the name of the user role.
     */
    name?: string;
}


export enum UserType {
    Unknown = "unknown",
    Homeowner = "homeowner",
    Employee = "employee",
    Contractor = "contractor",
    RealEstateAgent = "realEstateAgent",
    TitleCompanyAgent = "titleCompanyAgent",
    AgentAssistant = "agentAssistant"
}

export namespace UserType {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "unknown" | "homeowner" | "employee" | "contractor" | "realEstateAgent" | "titleCompanyAgent" | "agentAssistant";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "unknown" = 0,
        "homeowner" = 1,
        "employee" = 2,
        "contractor" = 3,
        "realEstateAgent" = 4,
        "titleCompanyAgent" = 5,
        "agentAssistant" = 6
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(userType: UserType.Type) {
        return UserType.Values[userType];
    }
}

export interface VOCCommentsResponse { 
    comments?: string;
    contractorRating?: number;
    dispatchNumber?: string;
    address?: Address;
    closedDate?: string;
}


/**
 * An item covered by the warranty.  Used during SR creation.
 */
export interface WarrantyItem { 
    description?: string;
    id?: string;
}


export interface WorkOrderCancelModel {
    /**
     * Scheduled Start time.
     */
    WorkOrderId?: string;
    /**
     * Schedules End time.
     */
    CancellationNotes?: string;
}
export interface WorkOrderStatusSummary {
    /**
     * Scheduled Start time.
     */
    ScheduledStartWindow?: string;
    /**
     * Schedules End time.
     */
    ScheduledEndWindow?: string;
    /**
     * Status, should be scheduled.
     */
    Status?: string;
    /**
     * Selected Scheduled time.
     */
    ScheduleDateTime?: string;
    /**
     * Work order Id.
     */
    Id?: string;
    /**
     * Tenant.
     */
    Tenant?: string;
}
export enum WorkOrderSystemStatus {
    AppointmentCompleted = "appointmentCompleted",
    AppointmentStarted = "appointmentStarted",
    AttemptedToSchedule = "attemptedToSchedule",
    Authorized = "authorized",
    CalledAlternatePhone = "calledAlternatePhone",
    CalledPrimaryPhone = "calledPrimaryPhone",
    CheckedIn = "checkedIn",
    ContractorAcknowledgedWorkOrder = "contractorAcknowledgedWorkOrder",
    ContractorNote = "contractorNote",
    JobComplete = "jobComplete",
    JobCompleted = "jobCompleted",
    NotSet = "notSet",
    Pending = "pending",
    PendingAutho = "pendingAutho",
    Scheduled = "scheduled",
    TakingPhoto = "takingPhoto",
    WaitingOnParts = "waitingOnParts"
}

export namespace WorkOrderSystemStatus {
    /**
     * A simplified string union type that represents this enum.
     */
    export type Type = "appointmentCompleted" | "appointmentStarted" | "attemptedToSchedule" | "authorized" | "calledAlternatePhone" | "calledPrimaryPhone" | "checkedIn" | "contractorAcknowledgedWorkOrder" | "contractorNote" | "jobComplete" | "jobCompleted" | "notSet" | "pending" | "pendingAutho" | "scheduled" | "takingPhoto" | "waitingOnParts";

    /**
     * An enum with that maps the values of the enum to their numerical values, or numerical values back to the names.
     */
    export enum Values {
        "appointmentCompleted" = 0,
        "appointmentStarted" = 1,
        "attemptedToSchedule" = 2,
        "authorized" = 3,
        "calledAlternatePhone" = 4,
        "calledPrimaryPhone" = 5,
        "checkedIn" = 6,
        "contractorAcknowledgedWorkOrder" = 7,
        "contractorNote" = 8,
        "jobComplete" = 9,
        "jobCompleted" = 10,
        "notSet" = 11,
        "pending" = 12,
        "pendingAutho" = 13,
        "scheduled" = 14,
        "takingPhoto" = 15,
        "waitingOnParts" = 16
    }

    /**
     * Gets the enum value if given the string representation of the value.
     */
    export function GetValue(workOrderSystemStatus: WorkOrderSystemStatus.Type) {
        return WorkOrderSystemStatus.Values[workOrderSystemStatus];
    }
}

export interface ZipCode { 
    city?: string;
    postalCode?: string;
    state?: State;
    stateId?: number;
    zipCodeId?: number;
}

