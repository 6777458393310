
import * as Landmark from "models/landmark-api";
import { Action } from "redux";
import { combineEpics, Epic, ofType } from "redux-observable";
import { map } from "rxjs/operators";
import { createPasswordActions, PasswordActionTypes } from "../../actions/account/password.actions";
import { PayloadAction } from "../../actions/defs";
import { createDialogActions } from "../../actions/dialog.actions";
import { Areas } from "../../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../../services/landmarkApi.service";
import { createToastrEpic } from "../toastr.epic";
import { createWaitEpic } from "../wait.epic";

const forgotPasswordDialogActions = createDialogActions(Areas.Account.Password.Forgot);
const changePasswordDialogActions = createDialogActions(Areas.Account.Password.Change);
const passwordActions = createPasswordActions();

const handleChangePassword: Epic<
    PayloadAction<Landmark.ChangePasswordRequest | Error | void>
> = action$ => action$.pipe(
    ofType(PasswordActionTypes.Change.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.ChangePasswordRequest>) => LandmarkApiService
            .post("/authenticate/changePassword")
            .withAuthentication()
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        passwordActions.change.success,
        passwordActions.change.failure,
    ),
);

const handleForgotPassword: Epic<
    PayloadAction<string | Error | Landmark.ForgotPasswordResponse>
> = action$ => action$.pipe(
    ofType(PasswordActionTypes.Forgot.BEGIN),
    switchMapWithPromiseToActions(
        action => LandmarkApiService
            .post("/authenticate/forgotPassword")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        passwordActions.forgot.success,
        passwordActions.forgot.failure,
    ),
);

const notifyUserOfChangePasswordFailure = createToastrEpic(
    [PasswordActionTypes.Change.FAILURE],
    (action: Action) => ({
        type: "error",
        title: "Error",
        message: "An error occurred while changing your password, please try again.",
        options: { showCloseButton: true, timeOut: 10000 }
    })
);

const notifyUserOfChangePasswordSuccess = createToastrEpic(
    [PasswordActionTypes.Change.SUCCESS],
    (action: Action) => ({
        type: "success",
        title: "Password Changed",
        message: "Your password has been successfully changed.",
        options: { showCloseButton: true, timeOut: 10000 }
    })
);

const notifyUserOfForgotPasswordSuccess = createToastrEpic(
    [PasswordActionTypes.Forgot.SUCCESS],
    (action: PayloadAction<Landmark.ForgotPasswordResponse>) => ({
        type: "success",
        title: "Forgot Password",
        message: `If an account is set up, an email will be sent to the specified email address ${action.payload.email}.`,
        options: { showCloseButton: true, timeOut: 10000 }
    })
);

const closeDialogOnForgotPasswordSuccess: Epic<Action> = action$ => action$.pipe(
    ofType(PasswordActionTypes.Forgot.SUCCESS),
    // Close the forgot password dialog
    map(action => forgotPasswordDialogActions.close()),
);

const closeDialogOnChangePasswordSuccess: Epic<Action> = action$ => action$.pipe(
    ofType(PasswordActionTypes.Change.SUCCESS),
    // Close the change password dialog
    map(action => changePasswordDialogActions.close()),
);

// Show a wait spinner while calling the API
const waitDuringForgotPassword = createWaitEpic(PasswordActionTypes.Forgot, Areas.Account.Password.Forgot);
const waitDuringChangePassword = createWaitEpic(PasswordActionTypes.Change);

const epic = combineEpics(
    closeDialogOnChangePasswordSuccess,
    closeDialogOnForgotPasswordSuccess,
    handleChangePassword,
    handleForgotPassword,
    notifyUserOfChangePasswordFailure,
    notifyUserOfChangePasswordSuccess,
    notifyUserOfForgotPasswordSuccess,
    waitDuringChangePassword,
    waitDuringForgotPassword,
);
export default epic;
