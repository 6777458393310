import { AsyncActionType } from "actions/defs";
import { Action } from "redux";
import { ActionsObservable, ofType } from "redux-observable";
import { empty, merge, of } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";

export function waitForAsyncAction<
    TActionsObservableAction extends Action,
    TBeginActionType extends Action,
    TSuccessActionType extends Action,
    TFailureActionType extends Action,
    TSuccess extends Action,
    TFailure extends Action
    >(
        action$: ActionsObservable<TActionsObservableAction>,
        asyncActionType: AsyncActionType,
        successAction: (beginAction: TBeginActionType, successAction: TSuccessActionType) => TSuccess,
        failureAction: (beginAction: TBeginActionType, failureAction: TFailureActionType) => TFailure,
) {
    return switchMap(
        (beginAction: TBeginActionType) => merge(
            action$.pipe(
                ofType(asyncActionType.SUCCESS),
                take(1),
            ),
            action$.pipe(
                ofType(asyncActionType.FAILURE),
                take(1),
            )
        ).pipe(
            switchMap((successOrFailureAction: TActionsObservableAction | TBeginActionType | TSuccessActionType | TFailureActionType) => {
                switch (successOrFailureAction.type) {
                    case asyncActionType.SUCCESS:
                        return of(successAction(beginAction, successOrFailureAction as TSuccessActionType))
                            .pipe(filter(Boolean));
                    case asyncActionType.FAILURE:
                        return of(failureAction(beginAction, successOrFailureAction as TFailureActionType))
                            .pipe(filter(Boolean));
                    default:
                        return empty();
                }
            })
        )
    );
}
