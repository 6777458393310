import * as Landmark from "models/landmark-api";
import moment from "moment";
import * as React from "react";
import { combineEpics, Epic, ofType } from "redux-observable";
import { empty, of } from "rxjs";
import { filter, map, mapTo, switchMap } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { createDialogActions } from "../actions/dialog.actions";
import { createServiceRequestActions, ServiceRequestActionTypes } from "../actions/serviceRequest.actions";
import { CallUs } from "../components/CallUs";
import { Areas } from "../constants/Areas";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { JsonResponse } from "../services/api.service";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createToastrEpic } from "./toastr.epic";
import { createWaitEpic } from "./wait.epic";

const dialogActions = createDialogActions(Areas.Account.ServiceRequest.Create);
const serviceRequestActions = createServiceRequestActions();

/**
 * Closes the service request dialog on a successful submit.
 */
const closeServiceRequestDialogOnSuccess: Epic<PayloadAction<void | string | Error>> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.Send.SUCCESS),
    mapTo(dialogActions.close()),
);

/**
 * Authorizes the creation of an SR when beginning a service request.
 */
const handleAuthorizeCreateSrOnBeginServiceRequest: Epic<PayloadAction<void | string | Error>> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.BEGIN),
    switchMap((action: PayloadAction<string>) => {
        if (action.payload) {
            return of(serviceRequestActions.authorize.begin(action.payload));
        }
        return empty();
    }),
);

/**
 * Authorizes the creation of an SR against a particular contract.
 */
const handleAuthorizeContractForServiceRequest: Epic<
    PayloadAction<void | string | Error | Landmark.AuthorizationResponse>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.Authorize.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/authorize/serviceRequest/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        serviceRequestActions.authorize.success,
        serviceRequestActions.authorize.failure,
    ),
);

/**
 * Begins loading warranty items when starting a service request.
 */
const handleLoadWarrantyItemsOnBeginServiceRequest: Epic<
    PayloadAction<void | string | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.BEGIN),
    map((action: PayloadAction<string>) => serviceRequestActions.loadWarrantyItems.begin(action.payload)),
);

/**
 * Loads warranty items from the API for the given contract ID.
 */
const handleLoadWarrantyItems: Epic<
    PayloadAction<void | string | Landmark.ApiResponse<Landmark.WarrantyItem[]> | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.LoadWarrantyItems.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/account/serviceRequest/items/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        serviceRequestActions.loadWarrantyItems.success,
        serviceRequestActions.loadWarrantyItems.failure,
    ),
);

/**
 * Triggers loading question flow for the API when a warranty item is selected
 */
const handleLoadQuestionFlowForSelectedWarrantyItem: Epic<
    PayloadAction<void | string | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.SELECT_WARRANTY_ITEM),
    filter((action: PayloadAction<string>) => action.payload.length > 0),
    map((action: PayloadAction<string>) => serviceRequestActions.loadWarrantyItemQuestionFlow.begin(action.payload)),
);

/**
 * Loads warranty items from the API for the given contract ID.
 */
const handleLoadQuestionFlow: Epic<
    PayloadAction<void | string | Landmark.ApiResponse<Landmark.FlowchartNode> | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.LoadWarrantyItemQuestionFlow.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<string>) => LandmarkApiService
            .get(`/account/serviceRequest/flow/${action.payload}`)
            .withAuthentication()
            .fetch()
            .then(response => response.json),
        serviceRequestActions.loadWarrantyItemQuestionFlow.success,
        serviceRequestActions.loadWarrantyItemQuestionFlow.failure,
    ),
);


/**
 * Handles cancelling a service request.
 */
const handleCancelServiceRequest: Epic<
    PayloadAction<Landmark.WorkOrderCancelModel | Landmark.ApiResponse<boolean> | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.Cancel.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.WorkOrderCancelModel>) => LandmarkApiService
            .patch(`order/cancelServiceRequest`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        serviceRequestActions.cancel.success,
        serviceRequestActions.cancel.failure,
    ),
);

/**
 * Handles sending a service request to the server.
 */
const handleSendStatusUpdate: Epic<
    PayloadAction<Landmark.WorkOrderStatusSummary | Landmark.ApiResponse<boolean> | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.StatusUpdate.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.WorkOrderStatusSummary>) => LandmarkApiService
            .post(`order/sendStatusUpdate`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        serviceRequestActions.statusUpdate.success,
        serviceRequestActions.statusUpdate.failure,
    ),
);

const handleSendServiceRequestToServer: Epic<
    PayloadAction<void | Landmark.ServiceRequestRequest | Landmark.ApiResponse<Landmark.ServiceRequest> | Error>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.Send.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.ServiceRequestRequest>) => LandmarkApiService
            .post(`/account/serviceRequest`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        serviceRequestActions.send.success,
        serviceRequestActions.send.failure,
    ),
);

const handlePostServiceRequestNotes: Epic<
    PayloadAction<Landmark.ServiceRequestNoteRequest | Error | Landmark.ApiResponse<Landmark.Note>>
> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.SendMessage.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.ServiceRequestNoteRequest>) => LandmarkApiService
            .post(`/account/servicerequest/note`)
            .withAuthentication()
            .body(action.payload)
            .fetch()
            .then(response => response.json),
        serviceRequestActions.sendMessage.success,
        serviceRequestActions.sendMessage.failure,
    ),
);

/**
 * Notifies the user when the contract isn't authorized to open an SR.
 */
const notifyUserOfAuthorizeSRFailure = createToastrEpic(
    [ServiceRequestActionTypes.Authorize.FAILURE],
    (action: PayloadAction<JsonResponse<Landmark.AuthorizationResponse>>) => {
        let messages = action.payload.json &&
            action.payload.json.hasErrors &&
            action.payload.json.errors.map(e => e.message).join(", ") || "An error occurred while submitting your claim.";
        messages = messages +
            React.createElement(
                "p",
                null,
                "Please call us at ",
                React.createElement(CallUs)
            );
        return {
            type: "error",
            title: "There's a Problem...!",
            options: {
                component: React.createElement("div", null, messages),
                showCloseButton: true,
                timeOut: messages.length * 10000,
            },
        };
    }
);

/**
 * Notifies the user of the SR creation.
 */
const notifyUserOfCreateSR = createToastrEpic(
    [ServiceRequestActionTypes.Send.BEGIN],
    (action: PayloadAction<Landmark.CreateSRRequest>) => ({
        type: "info",
        title: "Submitting Request",
        message: "Submitting service request. Please wait...",
        options: {
            timeOut: 10000,
        }
    })
);

/**
 * Notify user when an SR is successfully created.
 */
const notifyUserOfCreateSRSuccess = createToastrEpic(
    [ServiceRequestActionTypes.Send.SUCCESS],
    (action: PayloadAction<Landmark.CreateSRRequest>) => {
        // Get business hours
        const now = moment();
        const time = now.hours() * 60 + now.minutes();
        const begin = moment(new Date(now.year(), now.month(), now.day(), 7));
        const end = moment(new Date(now.year(), now.month(), now.day(), 17));
        const beginTime = begin.hours() * 60 + begin.minutes();
        const endTime = end.hours() * 60 + end.minutes();

        const messages = [];

        // Change message based on business hours
        if (time >= beginTime && time <= endTime) {
            messages.push("We received your service request. Someone will be in contact with you to discuss your issue or schedule an appointment.");
            messages.push(
                React.createElement(
                    "span",
                    null,
                    "If this is an emergency, please call us right away at ",
                    React.createElement(CallUs)
                )
            );
        }
        else {
            messages.push("We received your service request after normal business hours, but we still have people on staff to help if this is an emergency.");
            messages.push(
                React.createElement(
                    "span",
                    null,
                    "If this is an emergency, please call us right away at ",
                    React.createElement(CallUs)
                )
            );
            messages.push("If it can wait, we will contact you for more information or to schedule an appointment first thing on our next business day.");
        }

        return {
            type: "success",
            title: "Success!",
            options: {
                component: React.createElement("div", null, messages.map(m => React.createElement("p", null, m))),
                showCloseButton: true,
                timeOut: messages.length * 10000,
            },
        };
    }
);

/**
 * Notify user when an SR is successfully created.
 */
const notifyUserOfCreateSRFailure = createToastrEpic(
    [ServiceRequestActionTypes.Send.FAILURE],
    (action: PayloadAction<Landmark.CreateSRRequest>) => ({
        type: "error",
        title: "Error",
        message: "",
        options: {
            timeOut: 10000,
            component: React.createElement(
                "p",
                null,
                "Oops, something went wrong! Please call us at ",
                React.createElement(CallUs)
            )
        }
    })
);

const waitForAuthorize = createWaitEpic(ServiceRequestActionTypes.Authorize);
const waitForCreateServiceRequest = createWaitEpic(ServiceRequestActionTypes.Send);
const waitForLoadQuestionFlow = createWaitEpic(ServiceRequestActionTypes.LoadWarrantyItemQuestionFlow);
const waitForLoadWarrantyItems = createWaitEpic(ServiceRequestActionTypes.LoadWarrantyItems);

const epic = combineEpics(
    closeServiceRequestDialogOnSuccess,
    handleAuthorizeCreateSrOnBeginServiceRequest,
    handleAuthorizeContractForServiceRequest,
    handleLoadQuestionFlow,
    handleLoadQuestionFlowForSelectedWarrantyItem,
    handleLoadWarrantyItemsOnBeginServiceRequest,
    handleLoadWarrantyItems,
    handlePostServiceRequestNotes,
    handleSendServiceRequestToServer,
    handleSendStatusUpdate,
    handleCancelServiceRequest,
    notifyUserOfAuthorizeSRFailure,
    notifyUserOfCreateSR,
    notifyUserOfCreateSRSuccess,
    notifyUserOfCreateSRFailure,
    waitForAuthorize,
    waitForCreateServiceRequest,
    waitForLoadQuestionFlow,
    waitForLoadWarrantyItems,
);
export default epic;
