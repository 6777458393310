export const Areas = {
    Awards: "awards",
    Account: {
        AddOwner: "account/addOwner",
        BillingInfo: "account/billingInfo",
        Contract: {
            Coverage: "account/contract/coverage",
            Page: "account/contract",
            Renew: "account/contract/renew",
        },
        Contractor: {
            ServiceAgreement: "account/contractor/serviceAgreement",
        },
        Dispatch: "account/dispatch",
        FeatureFlag: {
            Load: "account/getFeatureFlag",
            Set: "account/setFeatureFlag",
        },
        Login: "account/login",
        MergeUsers: "account/mergeUsers",
        Notices: "account/notices",
        Orders: {
            Page: "account/orders/page",
        },
        Page: "account/page",
        Password: {
            Change: "account/password/change",
            Forgot: "account/password/forgot",
        },
        Profile: {
            Contractor: "account/profile/contractor",
            Edit: "account/profile/edit",
            PaymentInfo: "account/profile/paymentInfos",
            EditPaymentInfo: "account/profile/paymentInfo/edit",
            SelectDefaultPaymentMethod: "account/profile/contract/defaultPaymentMethod",
        },
        ServiceRequest: {
            Authorize: "account/serviceRequest/authorize",
            Create: "account/serviceRequest/create",
            History: "account/serviceRequest/history",
            QuestionFlow: "account/serviceRequest/questionFlow",
            WarrantyItems: "account/serviceRequest/warrantyItems",
        }
    },
    Agent: {
        Orders: {
            Page: "account/orders",
            Payment: "account/orders/payment",
            Details: "account/orders/details",
        },
        Invoices: {
            Page: "account/invoices",
            Payment: "account/invoices/payment",
            Details: "account/invoices/details",
        }
    },
    Contractor: {
        Application: {
            Information: "contractor/application/information",
            Page: "contractor/application",
            Zones: "contractor/application/zones",
        },
        Dashboard: {
            Page: "contractor/dashboard",
            Scores: "contractor/dashboard/dispatchStatistics",
        },
        Graphs: "contractor/graphs",
        Scores: "contractor/dispatchStatistics",
        Search: "contractor/search",
    },
    CRR: {
        ContractorScores: "crr/contractor/dispatchStatistics",
        Graphs: "crr/graphs",
        Scores: "crr/dispatchStatistics",
    },
    Feature: "feature",
    Forum: "forum",
    LimitsAndExclusions: {
        Get: "limitsAndExclusions/get",
        Details: "limitsAndExclusions/details",
    },
    Offer: {
        OfferPackages: "offer/packages",
    },
    Order: {
        Coupon: "order/coupon",
        Lead: "order/lead",
        Geocode: "order/geocode",
        OrderingParty: "order/orderingParty",
        Page: "order",
        PartnerSearch: "order/partnerSearch",
        PriceBreakdown: "order/priceBreakdown",
        AuthnetPayment: "order/getCustomerProfileToken"
    },
    Reviews: "reviews",
};
Object.freeze(Areas);
