import update from "immutability-helper";

import { DialogActionTypes } from "../actions/dialog.actions";

export interface DialogState {
    [id: string]: boolean;
}

export const defaultDialogState = {} as DialogState;

/**
 * A reducer that automatically handles dialog values.
 * @param {any} defaultState The default state for the reducer.
 */
export function DialogReducer(state = defaultDialogState, action) {
    const id = action.payload;

    switch (action.type) {
        case DialogActionTypes.OPEN: return update(state, { [id]: { $set: true }});
        case DialogActionTypes.CLOSE: return update(state, { [id]: { $set: false }});
    }
    return state;
}

