
import * as Landmark from "models/landmark-api";
import { combineEpics, Epic, ofType } from "redux-observable";
import { ContactUsActionTypes, createContactUsActions } from "../actions/contactUs.actions";
import { PayloadAction } from "../actions/defs";
import { switchMapWithPromiseToActions } from "rxjs/custom-operators";
import { LandmarkApiService } from "../services/landmarkApi.service";
import { createWaitEpic } from "./wait.epic";

const contactUsActions = createContactUsActions();

const handleContactUsSend: Epic<
    PayloadAction<Landmark.ContactUs | Error | Landmark.EmailSent>
> = action$ => action$.pipe(
    ofType(ContactUsActionTypes.Send.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.ContactUs>) => LandmarkApiService
            .post("/contact/email")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        contactUsActions.send.success,
        contactUsActions.send.failure,
    ),
);

const handleStreemSignup: Epic<
    PayloadAction<Landmark.StreemSignupRequest | Error | Landmark.EmailSent>
> = action$ => action$.pipe(
    ofType(ContactUsActionTypes.StreemSignup.BEGIN),
    switchMapWithPromiseToActions(
        (action: PayloadAction<Landmark.StreemSignupRequest>) => LandmarkApiService
            .post("/contact/streemsignup")
            .payload(action.payload)
            .fetch()
            .then(response => response.json),
        contactUsActions.streemSignup.success,
        contactUsActions.streemSignup.failure,
    ),
);

const waitDuringContactUsSend = createWaitEpic(ContactUsActionTypes.Send);

const waitDuringStreemSignup = createWaitEpic(ContactUsActionTypes.StreemSignup);

const epic = combineEpics(
    handleContactUsSend,
    handleStreemSignup,
    waitDuringContactUsSend,
    waitDuringStreemSignup
);
export default epic;
