import * as Landmark from "models/landmark-api";
import * as React from "react";

import { PayloadAction } from "../../actions/defs";
import { createDialogActions, DialogActionCreators } from "../../actions/dialog.actions";
import { createLimitsAndExclusionsActions, LimitsAndExclusionsActionCreators, LimitsAndExclusionsActionTypes } from "../../actions/limitsAndExclusions.actions";
import { Areas } from "../../constants/Areas";
import { createGetIsDialogOpen } from "../../selectors/dialog.selectors";
import { getIsFeatureEnabled } from "../../selectors/feature.selectors";
import { getAvailableAddonsForFirstOffer, getOfferAddons } from "../../selectors/offers.selectors";
import { ApplicationState } from "../../store/app";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { actionPromise } from "../../utils/redux-promise";



interface Props {
    actions?: {
        limitsAndExclusionsActions: DialogActionCreators;
        limitsAndExclusions: LimitsAndExclusionsActionCreators;
    };
    addonsFromFirstOffer?: Landmark.ContractCoverage[];
    isMonthlyPrice?: boolean;
    store?: {
        isLimitsAndExclusionsFeatureEnabled: boolean;
        isLimitsAndExclusionsOpen: boolean;
    };
}

const styles = {
    left: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        border: "none",
        width: "83.333%",
        float: "left",
        maxHeight: "37px",
    } as React.CSSProperties,
    right: {
        border: "none",
        width: "16.667%",
        float: "right",
    } as React.CSSProperties
};

@connect(
    (state: ApplicationState) => ({
        addons: getOfferAddons(state),
        addonsFromFirstOffer: getAvailableAddonsForFirstOffer(state),
        store: {
            isLimitsAndExclusionsFeatureEnabled: getIsFeatureEnabled(Landmark.FeatureKey.LimitsAndExclusions.details)(state),
            isLimitsAndExclusionsOpen: createGetIsDialogOpen(Areas.LimitsAndExclusions.Details)(state),
        }
    }),
    bindActionCreatorsToProps({
        limitsAndExclusionsActions: createDialogActions(Areas.LimitsAndExclusions.Details),
        limitsAndExclusions: createLimitsAndExclusionsActions(),
    })
)
export class OfferOptions extends React.Component<Props, any> {
    static defaultProps = {
        isLimitsAndExclusionsFeatureEnabled: false,
        isMonthlyPrice: true
    };

    handleLimitsModalPopup = async (e, coverage: Landmark.ContractCoverage) => {
        e.preventDefault();
        await actionPromise<PayloadAction<Landmark.LimitsAndExclusions>>(
            () => this.props.actions.limitsAndExclusions.get.begin(coverage.salesItemCoverageId),
            LimitsAndExclusionsActionTypes.Get.SUCCESS,
            LimitsAndExclusionsActionTypes.Get.FAILURE
        );
        this.props.actions.limitsAndExclusionsActions.open();
    }

    renderOptions = () => {
        const isLimitsAndExclusionsFeatureEnabled = this.props.store.isLimitsAndExclusionsFeatureEnabled;
        let addons = this.props.addonsFromFirstOffer;

        if (addons) {
            addons.sort((addon1, addon2) => {
                if (addon1.description > addon2.description) {
                    return 1;
                }
                return -1;
            });
            const index = Math.ceil(addons.length / 2);
            let leftSide = addons.slice(0, index);
            let rightSide = addons.slice(index);

            return (
                <div>
                    <div style={{ width: "50%", float: "left", padding: "0 0.5em 0 1em" }}>
                        <dl>
                            {leftSide.map((addon, index) => (
                                <dd key={index}
                                    onClick={(e) => isLimitsAndExclusionsFeatureEnabled ? this.handleLimitsModalPopup(e, addon) : "" }
                                    style={ isLimitsAndExclusionsFeatureEnabled ? { cursor: "pointer" } : { cursor: "text" }}
                                >
                                    <div className="item" style={styles.left}>
                                        {addon.description}
                                    </div>
                                    <div className="cost" style={styles.right}>
                                        ${(this.props.isMonthlyPrice ? addon.recurringCost : addon.cost).toFixed(this.props.isMonthlyPrice ? 2 : 0)}
                                    </div>
                                </dd>
                            )) }
                        </dl>
                    </div>
                    <div style={{ width: "50%", float: "right", padding: "0 0.5em 0 1em" }}>
                        <dl>
                            {rightSide.map((addon, index) => (
                                <dd key={index}
                                    onClick={(e) => isLimitsAndExclusionsFeatureEnabled ? this.handleLimitsModalPopup(e, addon) : "" }
                                    style={ isLimitsAndExclusionsFeatureEnabled ? { cursor: "pointer" } : { cursor: "text" }}
                                >
                                    <div className="item" style={styles.left}>
                                        {addon.description}
                                    </div>
                                    <div className="cost" style={styles.right}>
                                        ${(this.props.isMonthlyPrice ? addon.recurringCost : addon.cost).toFixed(this.props.isMonthlyPrice ? 2 : 0)}
                                    </div>
                                </dd>
                            )) }
                        </dl>
                    </div>
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <section className="section-plans-more">
                <div className="container">
                    <div className="row">
                        <div className="pricing-title">
                            <h2 className="resources">Home Warranty Plan Add-Ons</h2>
                        </div>
                    </div>
                    <div className="row border">
                        <div className="pricing-details">
                            {this.renderOptions() }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
