import { Modal } from 'components/Modal';
import * as SocialShareIcons from 'content/images/social-share-icons';
import * as React from 'react';
import { Meta } from "components/Meta";
import * as Styles from 'styles';

import { SocialShareForm } from './social-share-form';

import "./social-share.scss";

interface Props {
    description: string;
    imageUrl: string;
    title: string;
    urlPath: string;
}

interface State {
    isDialogOpen: boolean;
}

interface SocialShareButton {
    alt: string;
    iconPath: string;
    url: string;
}

export class SocialShare extends React.Component<Props, State> {
    state = {
        isDialogOpen: false
    };

    /**
     * Gets a collection of social network sharing URLs that can be used to post a page to any of the social networks.
     *
     * @readonly
     * @memberof SocialShare
     */
    get shareUrls() {
        const href = encodeURIComponent("https://www.landmarkhw.com" + this.props.urlPath);

        // Email details.
        const subject = encodeURIComponent(`I'd like to share this article, "${this.props.title}", from Landmark Home Warranty with you!`);
        const body = encodeURIComponent(
            `Hey there! I thought you would enjoy this blog, "${this.props.title}", from Landmark Home Warranty.\n`
        );

        const summary = encodeURIComponent(this.props.description);
        const source = encodeURIComponent("Landmark Home Warranty");
        const title = encodeURIComponent(this.props.title);

        const getAltText = (value: string) => `Share this blog through ${value}`;

        return {
            email: {
                alt: getAltText("Email"),
                iconPath: SocialShareIcons.Email,
                url: `mailto:?subject=${subject}&body=${body}${href}`,
            },
            facebook: {
                alt: getAltText("Facebook"),
                iconPath: SocialShareIcons.Facebook,
                url: `https://www.facebook.com/sharer/sharer.php?u=${href}`
            },
            googlePlus: {
                alt: getAltText("Google Plus"),
                iconPath: SocialShareIcons.GooglePlus,
                url: `https://plus.google.com/share?url=${href}`
            },
            linkedIn: {
                alt: getAltText("LinkedIn"),
                iconPath: SocialShareIcons.LinkedIn,
                url: `https://www.linkedin.com/shareArticle?mini=true&url=${href}&title=${title}&summary=${summary}&source=${source}`
            },
            pinterest: {
                alt: getAltText("Pinterest"),
                iconPath: SocialShareIcons.Pinterest,
                url: "https://www.pinterest.com/pin/create/button/"
            },
            twitter: {
                alt: getAltText("Twitter"),
                iconPath: SocialShareIcons.Twitter,
                url: `https://twitter.com/intent/tweet?url=${href}&text=${title}`
            },
        } as {[key: string]: SocialShareButton};
    }

    /**
     * Collection of styles used by this component.
     *
     * @readonly
     * @memberof SocialShare
     */
    get styles() {
        return {
            /** Container Styles **/
            containers: {
                columnFlex: {
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                } as React.CSSProperties,
                /** Overall styling of the panel. There are two types, Side Panel and Modal panel. **/
                parent: {
                    base: {
                        backgroundColor: "#95D0D8",
                    } as React.CSSProperties,
                    get sidePanel() {
                        return {
                            ...this.base,
                            borderRadius: "0 30px 30px 0",
                            flex: "0 1 285px",
                            height: "0%",
                            margin: ".5% 0 0 0",
                            padding: "1%",
                            minWidth: "250px",
                            zIndex: 45,
                        } as React.CSSProperties;
                    },
                    get modal() {
                        return {
                            ...Styles.Modal.AutoSized.container,
                            ...this.base,
                            padding: "10px 45px",
                        } as React.CSSProperties;
                    }
                },
                /** Container for the dialog button only appears when screen is mobile size. **/
                socialShareDialogButton: {
                    height: "100%",
                    left: 0,
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: 45,
                    pointerEvents: "none"
                } as React.CSSProperties,
            },
            /** Input Styles **/
            inputs: {
                socialShareButton: {
                    background: "#BBE0E8",
                    borderRadius: "50%",
                    display: "block",
                    cursor: "pointer",
                    width: "50px",
                    height: "50px",
                    padding: "10%",
                } as React.CSSProperties
            },
            /** Text Styles **/
            text: {
                title: {
                    fontSize: "2.5em",
                    fontWeight: 600
                } as React.CSSProperties,
            },
        };
    }

    /**
     * Prevents the normal action for an anchor, and instead launches a new window that routes to the anchor href.
     *
     * @param {React.MouseEvent<HTMLAnchorElement>} e
     * @memberof SocialShare
     */
    launchShareDialog(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();

        const windowFeatures = [
            "menubar=no",
            "toolbar=no",
            "resizable=yes",
            "scrollbars=yes",
            "height=600",
            "width=600"
        ];

        window.open(e.currentTarget.href, "sharer", windowFeatures.join(","));
    }

    /**
     * Renders the panel content.
     *
     * @memberof SocialShare
     */
    renderPanelContent() {
        return (
            <div className="social-share" style={this.styles.containers.columnFlex}>
                <SocialShareForm />
                <hr/>
                {/**<div style={this.styles.containers.columnFlex}>
                    <div className="title">Share This Article!</div>
                    <div className="subtitle">Like the article? Click and share using the buttons below:</div>
                    <div className="social-share-button-container">
                        {this.renderSocialButtons()}
                    </div>
                </div>
                */}
            </div>
        );
    }

    /**
     * Render a social button using an anchor tag wrapping an image.
     *
     * @param {string} url - The URL to go to when the button is clicked.
     * @param {string} iconPath - Path for the icon image of the button.
     * @param {React.EventHandler<React.MouseEvent<HTMLAnchorElement>>} [onClick=null] - Event handler for the button.
     *      If none is provided, open a dialog that will route to the anchor href.
     * @param {{}} [props={}] - Collection of properties to add to the anchor. Some of these social networks require special tagging on the tag.
     * @returns
     * @memberof SocialShare
     */
    renderSocialButton(
        { alt, iconPath, url }: SocialShareButton,
        onClick: React.EventHandler<React.MouseEvent<HTMLAnchorElement>> = () => {},
        props: {} = {}
    ) {
        return (
            <a href={url} onClick={onClick} {...props} >
                <img alt={alt} style={this.styles.inputs.socialShareButton} data-pin-nopin src={iconPath} />
            </a>
        );
    }

    /**
     * Renders the social buttons in order. Most will just launch the dialog
     *
     * @returns
     * @memberof SocialShare
     */
    renderSocialButtons() {
        return (
            <React.Fragment>
                {this.renderSocialButton(this.shareUrls.facebook, this.launchShareDialog)}
                {
                    this.renderSocialButton(
                        this.shareUrls.pinterest,
                        undefined,
                        { "data-pin-do": "buttonBookmark", "data-pin-custom": true }
                    )
                }
                {this.renderSocialButton(this.shareUrls.googlePlus, this.launchShareDialog)}
                {this.renderSocialButton(this.shareUrls.linkedIn, this.launchShareDialog)}
                {this.renderSocialButton(this.shareUrls.twitter, this.launchShareDialog)}
                {this.renderSocialButton(this.shareUrls.email)}
            </React.Fragment>
        );
    }

    /**
     * Renders the social share panel or button depending on the user's current view.
     *
     * @memberof SocialShare
     */
    render() {
        return (
            <React.Fragment>
                <Meta>
                    {/* TODO - Both Google Plus and Facebook have SDK's for posting, but we don't have an AppId for Facebook and Google Plus requires */}
                    {/* TODO - that the button be rendered their way, which isn't even compatible with JSX. We can potentially iterate on this in the future. */}
                    <script async defer src="//assets.pinterest.com/js/pinit.js"></script>
                </Meta>

                {/* Desktop View */}
                <div className="hiddenIfTablet social-share-side-panel" style={this.styles.containers.parent.sidePanel}>
                    {this.renderPanelContent()}
                </div>

                {/* Mobile/Tablet View */}
                <div className="visibleIfTablet" style={this.styles.containers.socialShareDialogButton}>
                    <img
                        alt="Share this blog"
                        className="social-share-dialog-button"
                        data-pin-nopin
                        onClick={() => this.setState({ isDialogOpen: true })}
                        src={SocialShareIcons.Share}
                    >
                    </img>
                </div>
                <Modal
                    isOpen={this.state.isDialogOpen}
                    onRequestClose={() => this.setState({ isDialogOpen: false })}
                    style={{...Styles.Modal.AutoSized, container: this.styles.containers.parent.modal}}
                >
                    {this.renderPanelContent()}
                </Modal>
            </React.Fragment>
        );
    }
}
