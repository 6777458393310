import * as React from "react";
import Scroll from "react-scroll";

import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";

interface Props {
    actions?: {
        router: RouterActionCreators;
    };
}

@connect(
    state => ({}),
    bindActionCreatorsToProps({
        router: createRouterActions()
    })
)
export class REProsSubnav extends React.Component<Props, any> {
    handleReviewsClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty#residential-service-contract`);
        scroller.scrollTo("reviews", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }
    handleRepsClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty#your-local-representative`);
        scroller.scrollTo("your-local-representative", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }
    handleOrdersClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty#home-warranty-plans`);
        scroller.scrollTo("home-warranty-plans", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    handleBenefitsClick = (e: React.MouseEvent<EventTarget>) => {
        e.preventDefault();
        let scroller = Scroll.scroller;
        this.props.actions.router.push(`/home-buyers-warranty#benefits`);
        scroller.scrollTo("benefits", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    render() {
        return (
            <section className="section-subnav">
                <div className="container">
                    <ul className="professionals">
                        <li><a className="btn btn-subnav" href="/home-buyers-warranty/protection-plans" onClick={e => { this.handleOrdersClick(e); }}>Home Buyer Plans<i data-icon="j"></i></a></li>
                        <li><a className="btn btn-subnav" href="/home-buyers-warranty/residential-service-contract" onClick={e => { this.handleReviewsClick(e); }}>Why Choose Landmark<i data-icon="j"></i></a></li>
                        <li><a className="btn btn-subnav" href="/home-buyers-warranty/your-local-representative" onClick={e => { this.handleRepsClick(e); }}>Meet your Representative<i data-icon="j"></i></a></li>
                        <li><a className="btn btn-subnav" href="/home-buyers-warranty/real-estate-education-tools" onClick={e => { this.handleBenefitsClick(e); }}>Partner with Landmark<i data-icon="j"></i></a></li>
                    </ul>
                </div>
            </section>
        );
    }
}
