import * as Landmark from "models/landmark-api";
import * as React from "react";
import * as Styles from "styles";
import { Modal } from "./Modal";
import { connect, bindActionCreatorsToProps } from "../store/componentBindings";
import { getLimitsAndExclusions } from "../selectors/limitsAndExclusions.selectors";
import { DialogActionCreators } from "../actions/dialog.actions";

import "./LimitsAndExclusions.scss";

const covered = require(`../content/images/limitsAndExclusions/covered.png`);
const logo = require(`../content/images/logo-landmark-horz.png`);
const notCovered = require(`../content/images/limitsAndExclusions/not-covered.png`);
const upgrade = require(`../content/images/limitsAndExclusions/upgrade.png`);
const warningSign = require(`../content/images/limitsAndExclusions/limits.png`);

interface Props {
    dialogActions: DialogActionCreators;
    isDialogOpen: boolean;
    store?: {
        limitsAndExclusions: Landmark.LimitsAndExclusions;
    };
}
@connect(
    state => ({
        store: {
            limitsAndExclusions: getLimitsAndExclusions(state),
        }
    }),
    bindActionCreatorsToProps({

    })
)
export class LimitsAndExclusions extends React.Component<Props, any> {

    // Covered section in the modal
    renderCovered(coveredItem: Landmark.CoverageItem, i: number) {

        if(coveredItem.type === Landmark.CoverageItemType.CoverageItem) {
            return(
                <div className="covered">
                    <img src={ covered } />
                    <p>{ coveredItem.value }</p>
                </div>
            );
        }
        else {
            return(
                <div>
                    <p style={{ marginBottom: 10 }}>{ coveredItem.value }</p>
                </div>
            );
        }
    }

    // Non-covered section in the modal
    renderNotCovered(notCoveredItem: Landmark.CoverageItem, i: number) {

        if(notCoveredItem.type === Landmark.CoverageItemType.CoverageItem) {
            return(
                <div className="not-covered">
                    <img src={ notCovered } />
                    <p>{ notCoveredItem.value }</p>
                </div>
            );
        }
        else {
            return(
                <div>
                    <p style={{ marginBottom: 10 }}>{ notCoveredItem.value }</p>
                </div>
            );
        }
    }

    render() {
        const limitsAndExclusions = this.props.store.limitsAndExclusions;

        if (!limitsAndExclusions){
            return "";
        }
        return (
            <Modal
                isOpen={this.props.isDialogOpen}
                onRequestClose={this.props.dialogActions.close}
                style={Styles.Modal.AutoSized}>
                <div style={Styles.Modal.AutoSized.close} onClick={this.props.dialogActions.close}>
                </div>
                <div className="limits-exclusions">
                    <div className="limits-exclusions-row">
                        <div className="column forth">
                            <div className="visibleIfTablet">
                                <div className="limits-exclusions-row">
                                    <div className="main-title">
                                        <div className="main-title-text"><h1>{ limitsAndExclusions.name }</h1></div>
                                    </div>
                                </div>
                            </div>
                            <div className="limits-exclusions-row left-column">
                                <div className="factoid-section">
                                    <div className="blue-circle">
                                        <img src={ limitsAndExclusions.factoids.imageUrl } />
                                    </div>
                                    {
                                        limitsAndExclusions.factoids.items.length > 0 ?
                                        <div className="blue-rectangle">
                                            <div className="factoids">
                                                {
                                                    limitsAndExclusions.factoids.items.map((factoid, i) => (
                                                        <div key={i} className="factoid-item" >
                                                            <div className="factoid-title">{ factoid.title }</div>
                                                            <div className="factoid-value">{ factoid.value }</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div> :
                                        null
                                    }
                                </div>
                            </div>
                            {/* For future release */}
                            {/* <div className="limits-exclusions-row">
                                <div id="download">
                                    <p>
                                        View the full terms, conditions, coverages, limits, and exclusions by downloading the full contract here:
                                    </p>
                                    <button
                                        className="btn-orange"
                                        // disabled={isSubmitting}
                                        // onClick={() => {
                                        //     this.props.setFieldValue("language", "en-US");
                                        //     this.setState({ hasAttemptedSubmit: true });
                                        //     window.requestAnimationFrame(this.props.submitForm);
                                        // }}
                                        type="button"
                                    >Download Full Contract</button>
                                </div>
                            </div> */}
                        </div>
                        <div className="column three-fourths">
                            <div className="hiddenIfTablet">
                                <div className="limits-exclusions-row">
                                    <div className="main-title">
                                        <div className="main-title-text"><h1>{ limitsAndExclusions.name }</h1></div>
                                    </div>
                                </div>
                            </div>
                            <div className="limits-exclusions-row">
                                <div className="column half">
                                    <div>
                                        <h2 className="bottom-line">Covered:</h2>
                                    </div>
                                    {
                                        limitsAndExclusions.covered.upgrade.length > 0 ?
                                        <div className="plan-upgrade">
                                            <div>
                                                <img className="upgrade-img" src={ upgrade }/>
                                            </div>
                                            {
                                                limitsAndExclusions.covered.upgrade.map((upgradeTitle, a) =>(
                                                    <div key={a}>
                                                        <h4>{ upgradeTitle.title }</h4>
                                                    </div>
                                                ))
                                            }
                                            {
                                                limitsAndExclusions.covered.upgrade.map((upgrade, i) => (
                                                    <div key={i}>
                                                        {
                                                            upgrade.items.map((upgradeItem, t) => (
                                                                <div key={t}>
                                                                    <div className="upgrade">
                                                                        <img src={ covered } />
                                                                        <p>{ upgradeItem.value }</p>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div> :
                                        null
                                    }
                                    <div>
                                        {
                                            limitsAndExclusions.covered.items.map((coveredItem, i) => (
                                                <div key={i}>
                                                    { this.renderCovered(coveredItem, i) }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="vertical-line"></div>
                                <div className="column half">
                                    <div>
                                        <h2 className="bottom-line">Not Covered:</h2>
                                    </div>
                                    <div>
                                        {
                                            limitsAndExclusions.notCovered.items.map((notCoveredItem, i) => (
                                                <div key={i}>
                                                    { this.renderNotCovered(notCoveredItem, i)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                limitsAndExclusions.limits.length > 0 ?
                                <div className="limits-exclusions-row">
                                    <div className="limits">
                                        <div className="hiddenIfTablet">
                                            <div className="limits-line-above"></div>
                                        </div>
                                        <h2>Limits:</h2>
                                        <div className="visibleIfTablet">
                                            <div className="limits-line-below"></div>
                                        </div>
                                        <div className="column" >
                                            {
                                                limitsAndExclusions.limits.map((limit, i) => (
                                                    <div key={i} >
                                                        <img src={ warningSign }/>
                                                        <p>{ limit.value }</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className="limits-exclusions-row legal">
                        <div className="logo">
                            <div>
                                <img src={ logo } />
                            </div>
                        </div>
                        <div className="column">
                            <div className="legal-text">
                                {
                                    limitsAndExclusions.legal.map((legal, i) => (
                                        <div key={i} >
                                            <p className={((i + 1) === limitsAndExclusions.legal.length ? "last" : "")}>
                                                {
                                                    legal.value.includes("http") ?
                                                        <a target="_blank" href={ legal.value }>{ legal.value }</a>
                                                    : <span>{ legal.value }</span>
                                                }
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
