import { ActionCreatorsMapObject } from "redux";

import { appId, PayloadAction } from "./defs";

export const DialogActionTypes = {
    OPEN: `${appId}/dialog/OPEN`,
    CLOSE: `${appId}/dialog/CLOSE`,
};
Object.freeze(DialogActionTypes);

export interface DialogAction extends PayloadAction<string> {}

/**
 * Returns action creators for a dialog.
 * @param {string} id The ID of the dialog.
 * @returns An object containing action creators for the dialog.
 */
export function createDialogActions(id: string) {
    return {
        open: () => ({ type: DialogActionTypes.OPEN, payload: id }),
        close: () => ({ type: DialogActionTypes.CLOSE, payload: id }),
    } as DialogActionCreators;
}

export interface DialogActionCreators extends ActionCreatorsMapObject {
    open: () => DialogAction;
    close: () => DialogAction;
}
