import * as React from "react";
import { Helmet } from "react-helmet";

import environment from "../environment";

interface Props {
    /**
     * The canonical address of the site.
     * Providing this value will create a <link rel="canonical"> and a <meta property="og:url">
     *
     * @type {string}
     * @memberof Props
     */
    canonical?: string;

    /**
     * The description of the site.
     * Providing this value will create a <meta name="description"> and a <meta property="og:description">
     *
     * @type {string}
     * @memberof Props
     */
    description?: string;

    /**
     * The alternate text for the image used in social networks when the page is shared.
     * Providing this value will create a <meta name="twitter:image:alt"> and a <meta property="og:image:alt">
     *
     * @type {string}
     * @memberof Props
     */
    imageAlt?: string;

    /**
     * The url of the image used in social networks when the page is shared.
     *
     * @type {string}
     * @memberof Props
     */
    imageUrl?: string;

    /**
     * Properties used to generate OpenGraph meta tags.
     *
     * @type {OpenGraphProps}
     * @memberof Props
     */
    openGraph?: OpenGraphProps;

    /**
     * The title of the page.
     * Providing this value will create a <title> and a <meta property="og:title">
     *
     * @type {string}
     * @memberof Props
     */
    title?: string;

    /**
     * Properties used to generate Twitter meta tags.
     *
     * @type {TwitterProps}
     * @memberof Props
     */
    twitter?: TwitterProps;
}

/**
 * Twitter meta tag properties.
 *
 * @interface TwitterProps
 */
interface TwitterProps {
    /**
     * The type of card to generate.
     *
     * @type {string}
     * @memberof TwitterProps
     */
    card?: "summary" | "summary_large_image" | "app" | "player";

    /**
     * The Twitter @ handler for the site.
     *
     * @type {string}
     * @memberof TwitterProps
     */
    site?: string;
}

/**
 * OpenGraph meta tag properties.
 *
 * @interface TwitterProps
 */
interface OpenGraphProps {
    /**
     * Image height metadata.
     *
     * @type {number}
     * @memberof OpenGraphProps
     */
    imageHeight?: number;

    /**
     * Image type metadata.
     *
     * @type {("image/jpeg" | "image/gif" | "image/png")}
     * @memberof OpenGraphProps
     */
    imageType?: "image/jpeg" | "image/gif" | "image/png";

    /**
     * Image width metadata.
     *
     * @type {number}
     * @memberof OpenGraphProps
     */
    imageWidth?: number;

    /**
     * Site locale metadata.
     *
     * @type {string}
     * @memberof OpenGraphProps
     */
    locale?: string;

    /**
     * Name of the website.
     *
     * @type {string}
     * @memberof OpenGraphProps
     */
    siteName?: string;

    /**
     * Type of site
     *
     * @type
     * {
     *     (
     *          "article" | "book" | "books.author" | "books.book" | "books.genre" | "business.business" | "fitness.course" | "game.achievement" |
     *          "music.album" | "music.playlist" | "music.radio_station" | "music.song" | "place" | "product" | "product.group" | "product.item" |
     *          "profile" | "restaurant.menu" | "restaurant.menu_item" | "restaurant.menu_section" | "video.episode" | "video.movie" |
     *          "video.other" | "video.tv_show" | "website"
     *     )
     * }
     * @memberof OpenGraphProps
     */
    type?: "article" | "book" | "books.author" | "books.book" | "books.genre" | "business.business" | "fitness.course" | "game.achievement" |
           "music.album" | "music.playlist" | "music.radio_station" | "music.song" | "place" | "product" | "product.group" | "product.item" |
           "profile" | "restaurant.menu" | "restaurant.menu_item" | "restaurant.menu_section" | "video.episode" | "video.movie" |
           "video.other" | "video.tv_show" | "website";
}

/**
 * Component that will render the sites meta tags(and also can handle script tags and style tags).
 * These tags will be automatically inserted into the <head> tag and is generally used to help with crawlers like Google, Facebook, and Twitter.
 *
 * @export
 * @class Meta
 * @extends {React.Component<Props, {}>}
 */
export class Meta extends React.Component<Props, {}> {
    renderCanonicalMeta(canonical: string) {
        const fullUrl = environment.publicUrl + canonical;
        return [
            <link key="canonical" rel="canonical" href={fullUrl} />,
            <meta key="og:url" property="og:url" content={fullUrl} />
        ];
    }

    renderDescriptionMeta(description: string) {
        return [
            <meta key="description" name="description" content={description} />,
            <meta key="og:description" property="og:description" content={description} />
        ];
    }

    renderImageMeta(imageUrl: string, imageAlt?: string) {
        // Sometimes imageUrl fails to include the publicUrl on initial loading, but then adds it after initially loading.
        // This should prevent us from double adding the value.
        let fullUrl: string = null;
        if (/^data:/.test(imageUrl)) {
            fullUrl = imageUrl;
        }
        else {
            fullUrl = environment.publicUrl + "/";
            // Ensure the image url isn't absolute, and that it doesn't start with a "/" character.
            fullUrl += imageUrl.replace(environment.publicUrl, "").replace(/^\/(.+)/, "$1");
        }
        const result = [<meta key="og:image" property="og:image" content={fullUrl} />];

        if (imageAlt) {
            result.push(<meta key="twitter:image:alt" name="twitter:image:alt" content={imageAlt} />);
            result.push(<meta key="og:image:alt" property="og:image:alt" content={imageAlt} />);
        }

        return result;
    }

    renderOpenGraphMeta(openGraph: OpenGraphProps) {
        const {
            imageHeight,
            imageType,
            imageWidth,
            locale,
            siteName,
            type
        }: OpenGraphProps = openGraph || {};

        const result = [];

        imageHeight && result.push(<meta key="og:image:height" property="og:image:height" content={`${imageHeight}`} />);
        imageType && result.push(<meta key="og:image:type" property="og:image:type" content={`${imageType}`} />);
        imageWidth && result.push(<meta key="og:image:width" property="og:image:width" content={`${imageWidth}`} />);
        locale && result.push(<meta key="og:locale" property="og:locale" content={locale} />);
        siteName && result.push(<meta key="og:site_name" property="og:site_name" content={siteName} />);
        type && result.push(<meta key="og:type" property="og:type" content={type} />);

        return result;
    }

    renderTitleMeta(title?: string) {
        return [
            <title key="title">{title}</title>,
            <meta key="og:title" property="og:title" content={title} />
        ];
    }

    renderTwitterMeta(twitter?: TwitterProps) {
        const { card, site }: TwitterProps = twitter || {};
        const result = [];

        card && result.push(<meta key="twitter:card" name="twitter:card" content={card} />);
        site && result.push(<meta key="twitter:site" name="twitter:site" content={site} />);

        return result;
    }

    render() {
        const {
            canonical,
            description,
            imageAlt,
            imageUrl,
            openGraph,
            twitter,
            title
        } = this.props;

        return (
            <Helmet>
                {canonical && this.renderCanonicalMeta(canonical)}
                {description && this.renderDescriptionMeta(description)}
                {imageUrl && this.renderImageMeta(imageUrl, imageAlt)}
                {openGraph && this.renderOpenGraphMeta(openGraph)}
                {title && this.renderTitleMeta(title)}
                {twitter && this.renderTwitterMeta(twitter)}
                {this.props.children}
            </Helmet>
        );
    }
}
