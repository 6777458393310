import ReactGA from "react-ga";
import { combineEpics, Epic, ofType } from "redux-observable";
import { empty } from "rxjs";
import { switchMap } from "rxjs/operators";
import { PayloadAction } from "../actions/defs";
import { ServiceRequestActionTypes } from "../actions/serviceRequest.actions";

/**
 * Notify GA when a service request fails to submit.
 */
const handleServiceRequestFailure: Epic<PayloadAction<void | string | Error>> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.Send.FAILURE),
    switchMap(action => {
        // FIXME: re-implement these:
        //             // Send a Google Analytics event that a service request payment
        //             // failed (but the SR was still successfully created in this case)
        //             ReactGA.event({
        //                 category: "Service Request",
        //                 action: "Info",
        //                 label: "Payment Failed"
        //             });
        //
        // Send a Google Analytics event that a service request failed
        //         // due to an issue in the website/API
        //         ReactGA.event({
        //             category: "Service Request",
        //             action: "Failure",
        //             label: "Website Error"
        //         });
        return empty();
    }),
);

/**
 * Notify GA when a service request successfully submits.
 */
const handleServiceRequestSuccess: Epic<PayloadAction<void | string | Error>> = action$ => action$.pipe(
    ofType(ServiceRequestActionTypes.Send.SUCCESS),
    switchMap(action => {
        // Send a Google Analytics event that a service request was submitted successfully
        ReactGA.event({
            category: "Service Request",
            action: "Success"
        });
        return empty();
    }),
);

const epic = combineEpics(
    handleServiceRequestFailure,
    handleServiceRequestSuccess,
);
export default epic;
