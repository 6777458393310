import { Action } from "redux";
import * as Landmark from "models/landmark-api";

import { AsyncAction, appId, AsyncActionCreators, createAsyncActions } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const AccountNoticesActionTypes = {
    Load: AsyncAction(`account/notice/load`),
    DISMISS: `${appId}/account/notice/DISMISS`,
    DISMISS_ALL: `${appId}/account/notice/DISMISS_ALL`,
};

export interface NoticesActionCreators extends ActionCreatorMap {
    /**
     * Load account notices data from the server.
     */
    load: AsyncActionCreators<string, Landmark.NoticesResponse>;

    //TODO: These will need to be async so the info can be persisted in the database
    dismiss: (id: string) => Action;
    dismissAll: () => void;
}

export function createNoticesActions() {
    return {
        load: createAsyncActions(AccountNoticesActionTypes.Load),
        //These will need to be made async and persisted in the database
        dismiss: (id: string) => {
            return {
                type: AccountNoticesActionTypes.DISMISS,
                id,
            };
        },
        dismissAll: () => {
            return {
                type: AccountNoticesActionTypes.DISMISS_ALL,
            };
        },
    } as NoticesActionCreators;
}
