import { CSSProperties } from "react";

export const AutoSized = {
    overlay: {
        animationName: "fade-in",
        animationIterationCount: 1,
        animationTimingFunction: "ease-in-out",
        animationDuration: "0.2s",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(29, 79, 111, 0.9)",
        zIndex: 50
    } as CSSProperties,
    container: {
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 20,
    } as CSSProperties,
    content: {
        maxHeight: "90%",
        maxWidth: "90%",
        overflow: "auto",
        transform: "translate(-50%, -50%)",
        msTransform: "translate(-50%, -50%)",
        webKitTransform: "translate(-50%, -50%)",
        top: "50%",
        right: "initial",
        bottom: "initial",
        left: "50%",
    } as CSSProperties,
    close: {
        position: "absolute",
        top: 20,
        right: 20,
        display: "block",
        color: "#888",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "landmark,serif",
        zIndex: 1000,
    } as CSSProperties,
};

export const AutoSizedWillScroll = {
    overlay: {
        animationName: "fade-in",
        animationIterationCount: 1,
        animationTimingFunction: "ease-in-out",
        animationDuration: "0.2s",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(29, 79, 111, 0.9)",
        zIndex: 50
    } as CSSProperties,
    container: {
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        padding: 20,
        display: "flex"
    } as CSSProperties,
    content: {
        height: "80%",
        maxWidth: "80%",
    } as CSSProperties,
    close: {
        position: "absolute",
        top: 20,
        right: 30,
        display: "block",
        color: "#888",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "landmark,serif",
        zIndex: 1000,
    } as CSSProperties,
};

export const AutoSizedWillScrollAgentSearch = {
    overlay: {
        animationName: "fade-in",
        animationIterationCount: 1,
        animationTimingFunction: "ease-in-out",
        animationDuration: "0.2s",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(29, 79, 111, 0.9)",
        zIndex: 50
    } as CSSProperties,
    container: {
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        padding: 20,
        display: "flex"
    } as CSSProperties,
    content: {
        height: "80%",
        maxWidth: "400px",
        minWidth: "fit-content",
        width: "80vw"
    } as CSSProperties,
    close: {
        position: "absolute",
        top: 20,
        right: 30,
        display: "block",
        color: "#888",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "landmark,serif",
        zIndex: 1000,
    } as CSSProperties,
};

export const AutoSizedMightScroll = {
    overlay: {
        animationName: "fade-in",
        animationIterationCount: 1,
        animationTimingFunction: "ease-in-out",
        animationDuration: "0.2s",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(29, 79, 111, 0.9)",
        zIndex: 50
    } as CSSProperties,
    container: {
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        padding: 20,
    } as CSSProperties,
    content: {
        height: "90%",
        maxWidth: "80%",
        minWidth: "40%"
    } as CSSProperties,
    close: {
        position: "absolute",
        top: 20,
        right: 30,
        display: "block",
        color: "#888",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "landmark,serif",
        zIndex: 1000,
    } as CSSProperties,
};
