import * as Landmark from "models/landmark-api";

import { AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const BrochureActionTypes = {
    Request: AsyncAction(`lead/brochure/request`),
};
Object.freeze(BrochureActionTypes);

export interface BrochureActionCreators extends ActionCreatorMap {
    /**
     * Requests a brochure.
     */
    request: AsyncActionCreators<Landmark.BrochureRequest, Landmark.BrochureResponse>;
}

export function createBrochureActions(): BrochureActionCreators {
    return {
        request: createAsyncActions(BrochureActionTypes.Request),
    };
}
