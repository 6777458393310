

import * as Landmark from "models/landmark-api";

import { AsyncActionCreators, AsyncAction, createAsyncActions } from "../defs";
import { ActionCreatorMap } from "../../store/componentBindings";

export const ContractorProfileActionTypes = {
    AddFile: AsyncAction(`contractor/profile/file/add`),
    AgreeToServiceAgreement: AsyncAction("contractor/serviceAgreement/agree"),
    CheckContractorServiceAgreement: AsyncAction("contractor/serviceAgreement/check"),
    Load: AsyncAction(`contractor/profile/load`),
};

export function createContractorProfileActions(): ContractorProfileActionCreators {
    return {
        addFile: createAsyncActions(ContractorProfileActionTypes.AddFile),
        agreeToServiceAgreement: createAsyncActions(ContractorProfileActionTypes.AgreeToServiceAgreement),
        checkContractorServiceAgreement: createAsyncActions(ContractorProfileActionTypes.CheckContractorServiceAgreement),
        load: createAsyncActions(ContractorProfileActionTypes.Load),
    };
}

export interface ContractorProfileActionCreators extends ActionCreatorMap {
    /**
     * Adds a file to the contractor's profile.
     */
    addFile: AsyncActionCreators<FormData, void>;

    /**
     * Agrees to the service agreement.
     */
    agreeToServiceAgreement: AsyncActionCreators<void, void>;

    /**
     * Checks to ensure the contractor has agreed to the annual service agreement.
     */
    checkContractorServiceAgreement: AsyncActionCreators<void, Landmark.ContractorServiceAgreementResponse>;

    /**
     * Loads partner's contractor information given a user id,
     * @param {string} userId The user id to load
     * @returns a response with contractor
     */
    load: AsyncActionCreators<string, Landmark.Contractor>;
}
