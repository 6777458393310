import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../actions/defs";
import { ForumActionTypes } from "../actions/forum.actions";

export interface ForumState {
    forumEntries: Landmark.ForumEntry[];
    forumEntry: Landmark.ForumEntry;
    selectedForumId: number;
}

const defaultState: ForumState = {
    forumEntries: [],
    forumEntry: null,
    selectedForumId: null,
};

export function ForumReducer(
    state = defaultState,
    action:
        Action |
        PayloadAction<
            void |
            Landmark.ForumResponse
        >
) {
    switch (action.type) {
        case ForumActionTypes.CLEAR: {
            return update(state, {
                forumEntries: { $set: null },
                forumEntry: { $set: null },
                selectedForumId: { $set: 0 }
            });
        }
        case ForumActionTypes.LoadSummaries.SUCCESS: {
            const { payload: response } = action as PayloadAction<Landmark.ForumResponse>;

            return update(state, {
                forumEntries: { $set: response.entries },
                selectedForumId: { $set: response.forumId },
            });
        }
        case ForumActionTypes.Load.SUCCESS: {
            const { payload: response } = action as PayloadAction<Landmark.ForumResponse>;

            return update(state, {
                forumEntry: { $set: response.entries && response.entries.length ? response.entries[0] : null },
                selectedForumId: { $set: response.forumId },
            });
        }
        case ForumActionTypes.Load.FAILURE: {
            return update(state, {
                forumEntry: { $set: { id: -1 } as Landmark.ForumEntry},
            });
        }
    }

    // If nothing changed, return the original state
    return state;
}
