import * as Landmark from "models/landmark-api";

import { CategoryActionCreators, createCategoryActions } from "../category.actions";
import { appId, AsyncAction, AsyncActionCreators, createAsyncActions } from "../defs";
import { Areas } from "../../constants/Areas";
import { ActionCreatorMap } from "../../store/componentBindings";

export const ServiceRequestHistoryActionTypes = {
    Load: AsyncAction(`account/serviceRequest/history/load`),
    LoadNotes: AsyncAction(`account/serviceRequest/history/notes/load`),
    SELECT: `${appId}/account/serviceRequest/history/SELECT`,
};

export interface ServiceRequestHistoryActionCreators extends ActionCreatorMap {
    /**
     * Handles the selected category on the service request history.
     */
    categories: CategoryActionCreators;

    /**
     * Loads claim history in the account state.
     */
    load: AsyncActionCreators<string[], Landmark.ServiceRequest[]>;

    loadNotes: AsyncActionCreators<string, Landmark.NotesResponse>;

    /**
     * Selects a service request.
     * @param {string} serviceRequestId The ID of the service request to select.
     */
    select: (serviceRequestId: string) => void;
}

export function createServiceRequestHistoryActions() {
    return {
        categories: createCategoryActions(Areas.Account.ServiceRequest.History),
        load: createAsyncActions(ServiceRequestHistoryActionTypes.Load),
        loadNotes: createAsyncActions(ServiceRequestHistoryActionTypes.LoadNotes),
        select: (serviceRequestId: string) => {
            return {
                type: ServiceRequestHistoryActionTypes.SELECT,
                payload: serviceRequestId,
            };
        },
    } as ServiceRequestHistoryActionCreators;
}
