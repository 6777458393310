import * as Landmark from "models/landmark-api";
import * as React from "react";
import { toastr } from "react-redux-toastr";
import { RouteComponentProps, Redirect } from "react-router";

import { SocialShare } from "./components/social-share";
import { Resources } from "../HomePage/Resources";
import { createForumActions, ForumActionCreators } from "../../actions/forum.actions";
import { createRouterActions, RouterActionCreators } from "../../actions/router.actions";
import { ForumCategories, ForumCategory, forumCategoryNames } from "../../constants/ForumCategory";
import { Footer } from "../../components/Footer";
import { Meta } from "../../components/Meta";
import { BasicRegistration } from "../../components/sections/BasicRegistration";
import { Subscribe } from "../../components/Subscribe";
import { getForumEntry } from "../../selectors/forum.selectors";
import { bindActionCreatorsToProps, connect } from "../../store/componentBindings";
import { convertTextToUrlSafe } from "../../utils/text";

interface Props extends Partial<RouteComponentProps<any>> {
    store: {
        entry: Landmark.ForumEntry;
    };
    actions: {
        forum: ForumActionCreators;
        router: RouterActionCreators;
    };
}

interface State {
    redirect?: boolean;
}

// Wires up the component with 'store' and 'actions' props,
// to interact with the redux store.
@connect(
    // Items added to `this.props.store`
    state => ({
        store: {
            entry: getForumEntry(state),
        }}),
    bindActionCreatorsToProps({
        forum: createForumActions(),
        router: createRouterActions()
    })
)
// Wires up this component to be able to interact with react-router.
export class ForumPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { };

        props.actions.forum.load.begin(parseInt(props.match.params.id));
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps(nextProps: Props): void {
        const {
            store: { entry },
            match: { params: nextUrlParams = {} }
        } = nextProps;
        const { match: { params: prevUrlParams = {} } } = this.props;

        if (
            nextUrlParams.forumId !== prevUrlParams.forumId ||
            nextUrlParams.id !== prevUrlParams.id
        ) {
            this.props.actions.forum.load.begin(parseInt(nextUrlParams.id));
            window.scrollTo(0, 0);
        }

        if (entry === null) {
            return;
        }

        const forumCategory = ForumCategories.find(category => category.id === entry.categoryForumId );

        if (!forumCategory) {
            toastr.info("Could not load article", "Sorry - There was a problem loading the requested article. Please try again.");
            this.props.actions.forum.clear();
            this.setState({ redirect: true });
        }
    }

    disclaimer(title) {
        if (title !== "About Landmark Home Warranty" && title !== "Home Warranty Education") {
            const disclaimerText = <div className="container"><p style={{ textAlign:"center" }}><b style={{ fontSize: "12px" }}>THE MATERIAL CONTAINED IN THIS ARTICLE IS PROVIDED FOR GENERAL INFORMATION PURPOSES ONLY AND DOES NOT CONSTITUTE PROFESSIONAL ADVICE.LANDMARK HOME WARRANTY DOES NOT PURPORT TO BE A SUBJECT MATTER EXPERT WITH REGARD TO THIS MATERIAL,
                AND YOU SHOULD CONDUCT YOUR OWN RESEARCH AND/OR SEEK THE ADVICE OF APPROPRIATELY QUALIFIED PROFESSIONALS WITH REGARD TO YOUR SPECIFIC CIRCUMSTANCES BEFORE YOU TAKE ACTION. LANDMARK HOME WARRANTY ASSUMES NO RESPONSIBILITY, AND
                SPECIFICALLY DISCLAIMS ALL LIABILITY, FOR YOUR USE OF ANY AND ALL INFORMATION CONTAINED HEREIN.</b></p><hr /></div>;
            return disclaimerText;
        }
        return null;
    }

    handleCategoryClick(e: React.MouseEvent<EventTarget>, forumCategoryUrlName: string) {
        e.preventDefault();
        this.props.actions.router.push(`/resources/${forumCategoryUrlName}`);
    }

    /**
     *Gets the options to use for the background banner image associated with the forum category.
     *
     * @param {ForumCategory} { urlSafeName } - Name of the forum.
     * @returns - Options to be used for styling the banner background image.
     * @memberof ForumPage
     */
    getBannerClass({ urlSafeName }: ForumCategory) {
        let result = [];

        switch(urlSafeName) {
            case forumCategoryNames.buyingSellingHouse:
                result.push("position-x-left"); // Fall through to the next group, as this photo also should be placed at top.
            case forumCategoryNames.homeRepairsMaintainance:
            case forumCategoryNames.homeWarrantyEducation:
            case forumCategoryNames.hvac:
            case forumCategoryNames.propertyManagement:
            case forumCategoryNames.realEstateTips:
                result.push("position-y-top");
                break;
            default:
                result.push("position-y-center");
                break;
        }

        // Categories: Buying or Selling a House, Real Estate Tips, and Home Warranty Education, have better looking images if they aren't centered.
        return result.join(" ");
    }

    /**
     * A react standard method to render this component.
     */
    render() {
        const {
            store: { entry },
            match: { params = {} },
            location
        } = this.props;
        const { pathname = "" } = location;
        const { redirect } = this.state;

        if (!entry) {
            return <Meta
                canonical="/resources"
            />;
        }

        const categoryId = redirect ? parseInt(params.forumId) : entry.categoryForumId;
        // Find the category or fallback to a default category.
        const forumCategory = ForumCategories.find(category => category.id === categoryId) ||
            {
                id: 0,
                displayName: "Recent Home Warranty Resources",
                urlSafeName: "recent-home-warranty-resources",
                categoryText: "Whether you’re buying or selling a home, or you’re looking for some quick DIY tips, you can find numerous articles and infographics to help you get started. Be sure to read more and find out how to best use your Landmark Home Warranty.",
                backgroundImg: "../../images/bg-post.jpg",
            } as ForumCategory;

        // If the entry failed to be retrieved from the API or the category doesn't exist
        if (redirect) {
            return <Redirect to={`/resources/${forumCategory.urlSafeName}`} />;
        }

        const url = `/resources/${forumCategory.urlSafeName}/${convertTextToUrlSafe(entry.title)}/${forumCategory.id}/${entry.id}`;
        const hrefUrl = `/resources/${forumCategory.urlSafeName}`;
        const backgroundImage = forumCategory.backgroundImg;

        // If we are not really on the correct route, fix it.
        if (
            entry.categoryForumId !== parseInt(params.forumId) ||
            !pathname.includes(`/${forumCategory.urlSafeName}/`) ||
            !pathname.includes(convertTextToUrlSafe(entry.title))
        ) {
            return <Redirect to={url} />;
        }

        return (
            <main className="resources post">
                <Meta
                    title={entry.title}
                    description={entry.description}
                    canonical={url}
                    imageUrl={backgroundImage}
                />
                <section className={`section-post-title ${this.getBannerClass(forumCategory)}`} style={{ backgroundImage: `url(${backgroundImage})`} }>
                    <div className="container">
                        <div className="row intro-content">
                            <a href={hrefUrl}
                                onClick={e => this.handleCategoryClick(e, forumCategory.urlSafeName)} className="tag-keyword">{entry.forum}
                            </a>
                            <h1>{entry.title}</h1>
                            <figure>
                                <img src={entry.avatar} />
                            </figure>
                        </div>
                    </div>
                </section>
                {/**
                  * Row flex is used here to prevent the social share panel from every overlapping the content. Background is made white to prevent
                  * adding gray margins to the parent div.
                  */}
                <div style={{display: "flex", flexDirection: "row", backgroundColor: "white" }}>
                    <SocialShare
                        urlPath={url}
                        description={entry.description}
                        imageUrl={backgroundImage}
                        title={entry.title}
                    />
                    <div style={{ flex: "1 1 auto", overflowX: "hidden" }}>
                        <section className="section-posts">
                            {this.disclaimer(entry.forum) }

                            <div className="container"
                                dangerouslySetInnerHTML={{ __html: entry.content }} >
                            </div>
                        </section>
                    </div>
                    <div className="hiddenIfTablet" style={{ flex: "0 10 285px" }}></div>
                </div>
                <Resources
                    count={3}
                    location={location}/>
                <Subscribe />
                <BasicRegistration
                    largeTitle="Get a Free Home Warranty Quote!"
                    subTitle="Input your information and get a free, personalized home warranty quote. You can customize the coverage and pricing to fit your needs. Experience what over 70,000 homeowners already know: A Landmark Home Warranty helps provide protection for your home and budget!" />
                <Footer />
            </main>
        );
    }
}
