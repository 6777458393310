import update from "immutability-helper";
import * as Landmark from "models/landmark-api";

import { PayloadAction } from "../../actions/defs";
import { PaymentActionTypes } from "../../actions/account/payment.actions";

/**
 * Definition of the payment state.
 *
 * @export
 * @interface PaymentState
 */
export interface PaymentState {
    failedPaymentDetails: Landmark.FailedPaymentListResponse;
    paymentInfos: Landmark.PaymentInfo[];
    selectedPaymentInfoId: string;
    selectedPolicyId: string;
    selectedPolicyNumber: number;
}

/**
 * Default state of the payment information.
 */
const defaultState: PaymentState = {
    failedPaymentDetails: null,
    paymentInfos: [],
    selectedPaymentInfoId: null,
    selectedPolicyId: null,
    selectedPolicyNumber: null,
};

/**
 * Reduces results of actions that will update the store regarding payments, allowing the store to recieve those updates.
 *
 * @export
 * @param {PaymentState} [state=defaultState]
 * @param {(Action |
 *         PayloadAction<
 *             Landmark.FailedPaymentListResponse |
 *             Landmark.PaymentInfo[] |
 *             string
 *         >)} action
 * @returns {PaymentState} - The resulting state after the updates.
 */
export function PaymentReducer(
    state: PaymentState = defaultState,
    action:
        PayloadAction<
            Landmark.FailedPaymentListResponse |
            Landmark.PaymentInfo[] |
            string
        >
) {
    switch (action.type) {
        case PaymentActionTypes.GetPaymentInfos.SUCCESS: {
            const response = action.payload as Landmark.ApiResponse<Landmark.PaymentInfo[]>;
            return update(state, {
                paymentInfos: { $set: response.result},
                selectedPolicyId: { $set: null },
                selectedPolicyNumber: { $set: null }
            });
        }
        case PaymentActionTypes.GetFailedPayments.SUCCESS: {
            return update(state, {
                failedPaymentDetails: { $set: action.payload as Landmark.FailedPaymentListResponse}
            });
        }

        case PaymentActionTypes.SELECT_PAYMENT_INFO_ID: {
            const payload = action.payload as string;
            return update(state, {
                selectedPaymentInfoId: { $set: payload }
            });
        }

        case PaymentActionTypes.SELECT_POLICY_ID: {
            const payload = action.payload as string;
            return update(state, {
                selectedPolicyId: { $set: payload }
            });
        }

        case PaymentActionTypes.SELECT_POLICY_NUMBER: {
            const payload = action.payload as string;
            return update(state, {
                selectedPolicyNumber: { $set: payload }
            });
        }
 }

    return state;
}
