import * as React from 'react';
import Scroll from 'react-scroll';
import { getIsAuthenticated } from 'selectors/auth.selectors';

import { createDialogActions, DialogActionCreators } from '../../actions/dialog.actions';
import { createOrderActions, OrderActionCreators } from '../../actions/order.actions';
import { createRouterActions, RouterActionCreators } from '../../actions/router.actions';
import { Areas } from '../../constants/Areas';
import { PlanTypeName } from '../../constants/TransactionType';
import { bindActionCreatorsToProps, connect } from '../../store/componentBindings';
import { getCanOrder } from "selectors/order.selectors";

interface Props {
    store?: {
        isAuthenticated?: boolean;
        canOrder: boolean;
    };
    actions?: {
        loginDialog: DialogActionCreators;
        order: OrderActionCreators;
        router: RouterActionCreators;
        srDialog: DialogActionCreators;
    };
}

@connect(
    state => ({
        store: {
            isAuthenticated: getIsAuthenticated(state),
            canOrder: getCanOrder(state),
        }
    }),
    bindActionCreatorsToProps({
        loginDialog: createDialogActions(Areas.Account.Login),
        order: createOrderActions(),
        router: createRouterActions(),
        srDialog: createDialogActions(Areas.Account.ServiceRequest.Create),
    })
)
export class ProtectYourHome extends React.Component<Props, any> {

    componentDidMount() {
        if (!this.props.store.canOrder) {
            window.location.replace("https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/");
        }
    }

    handleOrder = (e) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            this.props.actions.order.newOrder(PlanTypeName.Homeowner);
            this.props.actions.router.push(`/order/${PlanTypeName.Homeowner}`);
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    handlePlanClick = (e) => {
        let scroller = Scroll.scroller;
        e.preventDefault();
        this.props.actions.router.push(`/home-warranty-plans`);
        scroller.scrollTo("home-warranty-plans", {
            duration: 500,
            delay: 0,
            offset: -75,
            smooth: true,
        });
    }

    handleQuoteClick = (e) => {
        e.preventDefault();
        if (this.props.store.canOrder) {
            let scroller = Scroll.scroller;
            this.props.actions.router.push(`/homeowner-warranty#quote`);
            scroller.scrollTo("quote", {
                duration: 500,
                delay: 0,
                offset: -75,
                smooth: true,
            });
        }
        else {
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    handleRepairClick = (e) => {
        e.preventDefault();
        this.props.actions.srDialog.open();
        this.props.actions.router.push(`/claim`);
    }
    handleRenewClick = (e) => {
        e.preventDefault();
        if (!this.props.store.isAuthenticated) {
            this.props.actions.loginDialog.open();
        }
        this.props.actions.router.push(`/account`);
    }

    render() {
        return (
            <section className="section-intro homeowner">
                <div className="container">
                    <div className="row intro-content">
                        <i className="section-icon-inverted icon-homeowner" title="Home icon for the homeowner warranty page"></i>
                        <h1>Homeowner Warranties Provide Protection</h1>
                        <p>
                            Did you know that your home’s appliances and system components will break down eventually? When they do, a home warranty can help you as a homeowner save money by repairing or replacing them.
                        </p>
                        <div className="btn-group">
                            <a className="btn-white btn-homeowner" href="/home-warranty-plans" onClick={e => this.handlePlanClick(e)}>Choose a Plan</a>
                            <a className="btn-white btn-homeowner" href={`/order/${PlanTypeName.Homeowner}`} onClick={e => this.handleOrder(e)}>Order Now</a>
                            <a className="btn-white btn-homeowner" href="/homeowner-warranty#quote" onClick={e => this.handleQuoteClick(e)}>Request Quote</a>
                            <a className="btn-white btn-homeowner" href="/claim" onClick={e => this.handleRepairClick(e)}>Request Repair</a>
                            <a className="btn-white btn-homeowner" href="/account" onClick={e => this.handleRenewClick(e)} style={{ marginBottom: 5 }}>Renew Your Warranty</a>
                        </div>
                    </div>
                    <div className="row intro-callouts">
                        <div className="column fourth">
                            <h4>What Is a Home Warranty?</h4>
                            <p>
                                Landmark's home warranty helps protect homeowners from having to pay the unexpected costs related to repairing or replacing a broken down appliance or essential system component.
                            </p>
                        </div>
                        <div className="column fourth">
                            <h4>Home Warranty Coverage</h4>
                            <p>
                                Landmark Home Warranty provides coverage plans for the systems in your house, like heating, cooling, electrical, and plumbing, as well as your major appliances.
                            </p>
                        </div>
                        <div className="column fourth">
                            <h4>Home Warranty Cost</h4>
                            <p>
                                Landmark has affordable home warranty plans starting as low as $35 a month. Service call fees, paid when you open a claim for a repair or replacement, can be customized to fit your budget.
                            </p>
                        </div>
                        <div className="column fourth">
                            <h4>The Best Home Warranty Company</h4>
                            <p>
                                When it comes to choosing the best home warranty company for your home, choose Landmark. We protect over 70,000 homeowners each year and have <a href="/about-us" target="_blank">award-winning service</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
