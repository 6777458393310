import { withFormik, InjectedFormikProps } from "formik";
import * as Landmark from "models/landmark-api";
import * as React from "react";

import ReactGA from "react-ga";
import { toastr } from "react-redux-toastr";
import * as Scroll from "react-scroll";
import { isEmail, isEmpty } from "validator";
import { createOrderActions, OrderActionCreators } from "actions/order.actions";
import { createReferenceActions, ReferenceActionCreators, ReferenceActionTypes } from "actions/reference.actions";
import { createRouterActions, RouterActionCreators } from "actions/router.actions";
import { PlanTypeAbbreviation } from "constants/TransactionType";
import { actionPromise } from "utils/redux-promise";
import { PayloadAction } from "actions/defs";
import { CallUs } from "../../CallUs";
import { DropDown } from "../../DropDown";
import { TextBox } from "../../TextBox";
import { ValidationModal } from "../../ValidationModal";
import { bindActionCreatorsToProps, connect } from "store/componentBindings";
import { getCanOrder } from "selectors/order.selectors";

interface Props {
    store?: {
        canOrder: boolean;
    };
    actions?: {
        order: OrderActionCreators;
        reference: ReferenceActionCreators;
        router: RouterActionCreators;
    };
}

interface State {
    hasAttemptedSubmit: boolean;
}

/**
 * Base class used for creating a request quote form. This provides all the components needed for that form and utilizes the functionality that is provided by Formik.
 *
 * @export
 * @abstract
 * @class RequestQuoteFormBase
 * @extends {React.Component<InjectedFormikProps<Props, Landmark.LeadRegistrationRequest>, State>}
 */
export abstract class RequestQuoteFormBase extends React.Component<InjectedFormikProps<Props, Landmark.LeadRegistrationRequest>, State> {
    state = {
        hasAttemptedSubmit: false
    };

    handleCanOrder = (e) => {
        if (this.props.store.canOrder) {
            this.setState({ hasAttemptedSubmit: true });
        }
        else {
            e.preventDefault();
            window.location.href = "https://www.ahs.com/home-warranty/landmark-american-home-shield-partnership/";
        }
    }

    /**
     * Gets the collection of components that are used in a Request Quote Form.
     *
     * @readonly
     * @memberof RequestQuoteFormBase
     */
    get components() {
        const {
            errors,
            handleChange,
            handleBlur,
            isSubmitting,
            isValid,
            touched,
            values,
        } = this.props;

        return {
            contactName: <TextBox
                error={touched.contactName && errors.contactName}
                name="contactName"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Name..." />,

            email: <TextBox
                error={touched.email && errors.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email..." />,

            address: <TextBox
                error={touched.address && errors.address}
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Address..." />,

            zip: <TextBox
                error={touched.postalCode && errors.postalCode}
                name="postalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Zip..."
                type="zip" />,

            phone: <TextBox
                error={touched.homePhone && errors.homePhone}
                name="homePhone"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Phone..."
                type="tel" />,

            orderType: <DropDown
                listItemsFormatted={[
                    <option key={0} value={PlanTypeAbbreviation.Homeowner.toString()}>{"No"}</option>,
                    <option key={1} value={PlanTypeAbbreviation.RealEstate.toString()}>{"Yes"}</option>
                ]}
                name="leadBrochureTypeId"
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ width: "100%", height: "43px" }}
                value={values.leadBrochureTypeId} />,

            submitButton: (content = <React.Fragment>Get Started<i data-icon="j"></i></React.Fragment>) => (
                <button
                    className="btn-orange"
                    disabled={isSubmitting}
                    onClick={e => this.handleCanOrder(e)}
                    type="submit">
                    {content}
                </button>
            ),

            validationModal: <ValidationModal
                errors={errors}
                isOpen={!isValid && this.state.hasAttemptedSubmit}
                onRequestClose={() => this.setState({ hasAttemptedSubmit: false })}
            />
        };
    }

    /**
     * Handles the state during submission of the form for this component.
     *
     * @param {InjectedFormikProps<Props, Landmark.LeadRegistrationRequest>} newProps
     * @returns
     * @memberof RequestQuoteFormBase
     */
    componentWillReceiveProps(newProps: InjectedFormikProps<Props, Landmark.LeadRegistrationRequest>) {
        if (newProps.isSubmitting && !this.props.isSubmitting) {
            return this.setState({ hasAttemptedSubmit: true });
        }
    }
}

/**
 * Creates the Formik and Redux wrapper for the Request Quote Form that is provided in the parameter. This allows the form to be styled any way that it needs to be,
 * without having to reimplement the work for handling submission, validation and utilizing the redux store.
 *
 * @export
 * @param {React.ComponentType<InjectedFormikProps<Props, Landmark.LeadRegistrationRequest>>} form
 * @returns - A Formik form with the redux store connected.
 */
export function createRequestQuoteFormik(form: React.ComponentType<InjectedFormikProps<Props, Landmark.LeadRegistrationRequest>>) {
    const requestQuoteForm = withFormik<Props, Landmark.LeadRegistrationRequest>({
        mapPropsToValues: () => ({
            leadBrochureTypeId: PlanTypeAbbreviation.Homeowner.toString()
        }),
        validate: (values: Landmark.LeadRegistrationRequest) => {
            let errors: any = {};

            if (!values.contactName || isEmpty(values.contactName)) {
                errors.contactName = "Name is required.";
            }

            if (!values.email) {
                errors.email = "Email address is required.";
            }
            else if (!isEmail(values.email)) {
                errors.email = "Email address is invalid.";
            }

            if (!values.address || isEmpty(values.address)) {
                errors.address = "Address is required.";
            }

            if (!values.postalCode || values.postalCode.length < 5) {
                errors.postalCode = "Zip code is required.";
            }

            if ((!values.homePhone || values.homePhone.replace(/\D/g, "").length < 10)) {
                errors.homePhone = "Phone number is required.";
            }
            return errors;
        },

        // Submission handler
        handleSubmit: async (
            values: Landmark.LeadRegistrationRequest,
            {
                props,
                resetForm,
                setSubmitting,
            }
        ) => {
            // TODO: the GA integration should happen in an epic
            // Send a Google Analytics event that a brochure was requested
            ReactGA.event({
                action: "Quote Request",
                category: "Lead",
                label: values.leadBrochureTypeId,
            });

            // Build the start of our customer record
            const request: Landmark.LeadRegistrationRequest = {
                email: values.email,
                contactName: values.contactName,
                address: values.address,
                postalCode: values.postalCode,
                homePhone: values.homePhone,
                leadBrochureTypeId: values.leadBrochureTypeId,
                type: Landmark.LeadRegistrationTypes.Brochure,
            };

            try {
                // Check to see if we even service that zip code.
                const statePromise = actionPromise<PayloadAction<Landmark.State>>(
                    () => props.actions.reference.getStateByZipCode.begin(request.postalCode),
                    ReferenceActionTypes.GetStateByZipCode.SUCCESS,
                    ReferenceActionTypes.GetStateByZipCode.FAILURE
                );

                const stateAction = await statePromise;
                props.actions.order.setStateCode(stateAction.payload.stateCode);
            }
            catch (err) {
                toastr.error(
                    "No Service", "We're sorry, but we don't (yet) provide service to your zip code.",
                    { showCloseButton: true, timeOut: 15000 });
                setSubmitting(false);
                return;
            }

            try {
                props.actions.order.registerLead.begin(request);

                switch (values.leadBrochureTypeId) {
                    case PlanTypeAbbreviation.RealEstate.toString():
                        // Move to the compare plans page
                        props.actions.router.push(`/home-buyers-warranty#home-warranty-plans`);
                        break;
                    default:
                        // Move to the compare plans page
                        props.actions.router.push(`/homeowner-warranty#home-warranty-plans`);
                        break;
                }
                let scroller = Scroll.scroller;
                scroller.scrollTo("home-warranty-plans", {
                    duration: 500,
                    delay: 0,
                    offset: -75,
                    smooth: true,
                });

                // Reset the form
                // TODO - Reset form, in this version of Formik does not return the values to their
                // TODO - initial state; It just flips the touched flags, which creates funky behavior;
                resetForm(values);
            }
            catch (err) {
                // TODO: the toastr integration should happen in an epic
                toastr.error(
                    "Quote Error",
                    "",
                    {
                        timeOut: 10000,
                        component:
                        <p>
                            Error occurred trying to start the quote process. Please call us for assistance at <CallUs />.
                        </p>
                    }
                );
            }
            finally {
                setSubmitting(false);
            }
        }
    })(form);

    return connect(
        state => ({
            store: {
                canOrder: getCanOrder(state),
            }
        }),
        bindActionCreatorsToProps({
            order: createOrderActions(),
            reference: createReferenceActions(),
            router: createRouterActions()
        })
    )(requestQuoteForm);
}
