import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as Scroll from "react-scroll";

//import { Awards } from "../../components/Awards";
import { LogoBanner } from "../../components/LogoBanner";
import { BasicRegistration } from "../../components/sections/BasicRegistration";
//import { Executives } from "../../components/about/Executives";
import { Footer } from "../../components/Footer";
import { Meta } from "../../components/Meta";

interface Props extends Partial<RouteComponentProps<any>> {
}

// Wires up this component to be able to interact with react-router.
export class AboutPage extends React.Component<Props, any> {
    componentDidMount() {
        let scroller = Scroll.animateScroll;
        if (!this.props.location.hash.length) {
            scroller.scrollToTop();
        }
    }

    /**
     * A react standard method to render this component.
     */
    render() {
        return (
            <div>
                <Meta
                    title="About Our Company | Landmark Home Warranty"
                    canonical="/about-us"
                    description="Learn more about Landmark, a home warranty company that provides service on homes in Arizona, Idaho, Nevada, Oregon, Texas, and Utah."
                />
                <main className="about">
                    <section className="section-intro about">
                        <div className="container">
                            <div className="row intro-content">
                                <h1>About Landmark Home Warranty</h1>
                                <h2>Giving You More For Your Home Warranty</h2>
                                <hr />
                                <p>Landmark Home Warranty provides affordable and comprehensive home warranties for residential properties in Arizona, Idaho, Nevada, Oregon, Texas, Utah, and (soon) nationwide.We have local representatives and independent licensed contractors in every region we service and currently employ over 200 full-time staff.</p>
                            </div>
                        </div>
                    </section>
                    <section className="section-about-social">
                        <div className="container">
                            <div className="row">
                                <div className="column fifth">
                                    <a href="https://twitter.com/LandmarkHW" target="_blank" data-icon="P"></a>
                                </div>
                                <div className="column fifth">
                                    <a href="https://www.facebook.com/LandmarkHomeWarranty/" target="_blank" data-icon="Q"></a>
                                </div>
                                <div className="column fifth">
                                    <a href="https://plus.google.com/+LandmarkHomeWarranty" target="_blank" data-icon="R"></a>
                                </div>
                                <div className="column fifth">
                                    <a href="https://www.linkedin.com/company/landmark-home-warranty" target="_blank" data-icon="S"></a>
                                </div>
                                <div className="column fifth">
                                    <a href="https://www.youtube.com/user/LandmarkHomeWarranty" target="_blank" data-icon="T"></a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <LogoBanner />
                    {/* <section className="section-about" style={{ backgroundColor: "rgb(251,251,251)" }}>
                        <div className="container">
                            <Awards />
                        </div>
                    </section>*/}
                    {/*<Executives />*/}
                    <BasicRegistration
                        largeTitle="Get a Free Home Warranty Quote!"
                        subTitle="Input your information and get a free, personalized home warranty quote. You can customize the coverage and pricing to fit your needs. Experience what over 70,000 homeowners already know: A Landmark Home Warranty helps provide protection for your home and budget!" />
                    <Footer />
                </main>
            </div>
        );
    }
}
