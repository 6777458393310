import * as Landmark from "models/landmark-api";

import { createSelector } from "./common.selectors";
import { getSelectedOfferId } from "./order.selectors";
import { ApplicationState } from "../store/app";

export const getOffersState = (state: ApplicationState) => state.offers;

/**
 * Gets all coverages available for the list of offers.
 */
export const getAvailableCoverages = createSelector(
    getOffersState,
    offers => {
        if (offers.response) {
            const coverages: Landmark.ContractCoverage[] = Array.prototype.concat
                .apply([], offers
                    .response
                    .offers
                    // Filter out offers that represent "keep my current coverage"
                    .filter(offer => !offer.isCurrentCoverage)
                    .map(offer => offer.coverages)
                );

            const uniqueCoverages = coverages
                .reduce(
                    (result, coverage) => {
                    const category = (coverage.category || "Other").trim();
                    if (!result.hasOwnProperty(category)) {
                        result[category] = [];
                    }
                    // Determine if we've already handled this coverage
                    if (result[category].findIndex(c => c.id === coverage.id) < 0) {
                        // if it's not optional or it is optional but it is included by default in the plan (e.g., a/c coverage)
                        if (!coverage.isOptional || coverage.isIncludedByDefault) {
                            // If not, add it to the list
                            // NOTE: remove any information that can't be guaranteed across all plans
                            result[category].push(Object.assign({}, coverage, {
                                cost: undefined,
                                isIncludedByDefault: undefined,
                                isOptional: undefined,
                                recurringCost: undefined,
                            }));
                        }
                    }
                    if (result[category].length === 0) {
                        delete result[category];
                    }
                    return result;
                },
                    {} as {
                        [key: string]: Landmark.ContractCoverage[]
                    }
                );

            // Sort the coverage descriptions
            const coverageKeys = Object
                .keys(uniqueCoverages)
                .sort((k1, k2) => k1.localeCompare(k2));

            return coverageKeys
                .reduce(
                    (result, key) => {
                        // Sort each category by 'description'
                        const arr = uniqueCoverages[key].sort((a1, a2) => a1.description.localeCompare(a2.description));
                        result[key] = arr;
                        return result;
                },
                    {} as {
                        [key: string]: Landmark.ContractCoverage[]
                    }
                );
        }
        return null;
    }
);

/**
 * Gets current contract when they are upgrading
 */
export const getCurrentUpgradeContract = createSelector(
    getOffersState,
    offers => {
        if (offers.response && offers.response.current) {
            return offers.response.current;
        }
        return null;
    }
);

/**
 * Gets current contract addons when they are upgrading
 * can't moved this above the selector above since we are using it for this one
 */
export const getCurrentContractAddonsMinimumQuantity = createSelector(
    getCurrentUpgradeContract,
    (currentContract) => {
        // make sure this add-on id is one of the already selected addons
        if (currentContract && currentContract.addonIds && currentContract.addonIds.length > 0)
        {
            // since it is, find the original items for it
            return currentContract.priceBreakdown.items.filter(item => item.coverageId);
        }
        return [];
    }
);

/**
 * Gets a list of offers.
 */
export const getOffers = createSelector(
    getOffersState,
    (state: ApplicationState) => state.order,
    (offers, order) => {
        if (offers.response) {
            // For listing coverage, we only allow selecting the cheapest coverage
            const list = offers.response.offers;
            if (order.isListingCoverage) {
                // filter the offers based on whether or not they can be offered as listing coverage.
                return list.filter(offer => offer.canBeListingCoverage);
            }
            return list;
        }
        return [];
    }
);

/**
 * Gets addons to the selected offer.
 */
export const getAvailableAddonsForFirstOffer = createSelector(
    getOffers,
    (offers) => {
        if (offers && offers.length > 0) {
            return offers[0]
                .coverages
                .filter(addon => !addon.isIncludedByDefault && addon.isOptional);
        }
        else {
            return [];
        }
    }
);

/**
 * Gets the selected offer for the order.
 */
export const getSelectedOffer = createSelector(
    getOffers,
    getSelectedOfferId,
    (offers, selectedOfferId) => {
        return offers.find(o => o.contractOfferId === selectedOfferId);
    }
);

/**
 * Gets addons to the selected offer.
 */
export const getOfferCoverages = createSelector(
    getSelectedOffer,
    (offer) => {
        if (offer && offer.coverages) {
            return offer
                .coverages;
        }
        else {
            return [];
        }
    }
);

export const getOfferAddons = createSelector(
    getOfferCoverages,
    coverages => coverages.filter(c => c.isOptional && !c.isIncludedByDefault)
);

