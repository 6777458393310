import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import moment from "moment";
import { AuthActionTypes } from "../actions/auth.actions";
import { PayloadAction } from "../actions/defs";
import { OffersActionTypes } from "../actions/offers.actions";
import { OrderActionTypes } from "../actions/order.actions";
import { createStoreObserver, HydrateAction, StoreActionTypes } from "../actions/store.actions";
import { Areas } from "../constants/Areas";
import { StorageKeys } from "../constants/Storage";
import { PlanType, PlanTypeName } from "../constants/TransactionType";
import makeMergeable from "../utils/merge";
import { CategoryState, createCategoryReducer, defaultCategoryState } from "./category.reducer";

/**
 * The shape of this area of the application.
 */
export interface OrderState {
    addons: Landmark.ContractAddon[];
    budgetAmount: number;
    buyersCredit: number;
    canOrder: boolean;
    categories: CategoryState;
    contacts: Landmark.Contact[];
    contactPreferences: Landmark.ContactPreferences;
    contractId: string;
    contractPaymentType: string;
    coupon: Landmark.ContractCoupon;
    coverageLength: number;
    creditAmountToBeConsumed: number;
    currentContract: Landmark.ContractSelections;
    estimatedCloseDate: string;
    expireDate: string;
    hasAcknowledgedWaitPeriod: boolean;
    homeServicesInterest: Landmark.HomeServicesInterest;
    isAutoRenew: boolean;
    isConvertingFromListingCoverage: boolean;
    isListingCoverage: boolean;
    isRecentPurchase: boolean;
    isKeepingCurrentCoverage: boolean;
    isRenewing: boolean;
    isUpgrading?: boolean;
    leadBrochureTypeId: string;
    leadRegistrationRequest?: Landmark.LeadRegistrationRequest;
    newlyOrderedContractId: string;
    orderingPartyType: Landmark.PartyType;
    owner: Landmark.Customer;
    payment: Landmark.OrderPayment;
    planId: string;
    planTypeId: string;
    marketingSourceId: string;
    prepaidSCFFees: number;
    priceBreakdown: Landmark.ContractItemResponse;
    property: Landmark.Property;
    removeACCoverage: boolean;
    searchResults: Landmark.PartnerSearchResponse[];
    selectedOfferId: string;
    selectedPartners: {
        [key: string]: Landmark.PartnerSearchResponse;
    };
    selectedServiceCallPrice: Landmark.ServiceCallPrice;
    seller: Landmark.User;
    shouldUseCredit: boolean;
    upgradeContractId?: string;
    usingSuggestedAddress?: boolean;
    authNetProfileId?: string;
    authNetPaymentProfileId?: string;
    isWebPolicy?: boolean;
    isAddressVerified?: boolean;

}

// Get a ISO-8601 friendly version of the date
let today = moment().format("YYYY-MM-DD");

/**
 * Sets default values for the state of the `Login` area of the application.
 */
const defaultState: OrderState = {
    addons: [],
    budgetAmount: 0,
    buyersCredit: 0,
    canOrder: true,
    categories: defaultCategoryState,
    contacts: [],
    contactPreferences: {
        isOptedInToSms: false,
        sendRecordedContractInfo: true,
        smsPhoneNumber: "",
        wantsWelcomeCall: false,
        welcomeCallDate: today,
    },
    contractId: null,
    contractPaymentType: null,
    coupon: null,
    coverageLength: 1,
    creditAmountToBeConsumed: null,
    currentContract: null,
    estimatedCloseDate: today,
    expireDate: null,
    hasAcknowledgedWaitPeriod: false,
    homeServicesInterest: {},
    isAutoRenew: false,
    isKeepingCurrentCoverage: false,
    isConvertingFromListingCoverage: false,
    isListingCoverage: null,
    isRecentPurchase: null,
    isRenewing: false,
    isUpgrading: false,
    leadBrochureTypeId: null,
    leadRegistrationRequest: null,
    newlyOrderedContractId: null,
    orderingPartyType: Landmark.PartyType.Unknown,
    owner: null,
    //Note - GuestHouse is a Package. When added the packageId is added to packageIds
    payment: {
        billingAddressSameAsProperty: true,
        isPayingNow: true,
        type: Landmark.PaymentType.Escrow
    },
    planId: null,
    planTypeId: null,
    marketingSourceId: null,
    prepaidSCFFees: 0,
    priceBreakdown: null,
    property: {
        // Set some defaults for the property
        address: {},
        dwellingTypeId: "C126046F-966F-437D-A0AF-A3AC6E35B165",
        location: {},
        squareFootage: 1,
    },
    removeACCoverage: false,
    searchResults: null,
    selectedOfferId: null,
    selectedPartners: {},
    selectedServiceCallPrice: null,
    seller: null,
    shouldUseCredit: false,
    upgradeContractId: null,
    usingSuggestedAddress: false,
    authNetProfileId: null,
    isAddressVerified: true
};

/**
 * A re-usable reducer for setting state code and dwelling kind.
 * @param {ContractState} state
 * @param {string} stateCode
 * @param {string} dwellingTypeId
 */
function reduceOfferPrerequisites(
    state: OrderState,
    stateCode: string,
    dwellingTypeId: string) {
    // Clear the selected offer and addons
    return update(state, {
        addons: { $set: [] },
        property: {
            address: {
                state: { $set: stateCode },
            },
            dwellingTypeId: { $set: dwellingTypeId },
        },
        removeACCoverage: { $set: false },
        selectedOfferId: { $set: null },
    });
}

/**
 * Selects a contract offer.
 * @param state
 * @param offer
 * @param serviceCallPrice
 * @param currentContract
 */
function reduceSelectOffer(
    state: OrderState,
    offer: Landmark.ContractOffer,
    feesSum: number,
    serviceCallPrice: Landmark.ServiceCallPrice = null,
    currentContract: Landmark.ContractSelections = null,
) {
    if (offer) {
        let prices = [...offer.serviceCallPrices];
        prices.sort((a, b) => a.priceOffset - b.priceOffset);
        serviceCallPrice = prices[0];
    }

    // Need to subtract and fee from the budget amount.
    const budgetAmount = state.budgetAmount - feesSum;
    const { coupon } = state;
    const { marketingSourceId } = state;

    // put in any addons when the current coverage is selected
    // or empty if there are none
    const addons = [];
    let passedInContract = currentContract;
    if (offer && offer.includedAddonIds) {
        offer.includedAddonIds.forEach(addOnId => {
            let quantity = 1;
            if (passedInContract == null ) {
                passedInContract = state.currentContract;
            }
            if (passedInContract && passedInContract.addonIds && passedInContract.addonIds.length > 0)
            {
                const currentQuantities = passedInContract.priceBreakdown.items.filter(item => item.coverageId);
                const currentItem = currentQuantities.filter(item => item.coverageId === addOnId);
                if (currentItem && currentItem[0] && currentItem[0].quantity)
                {
                    quantity = currentItem[0].quantity;
                }
            }
            addons.push(
                {
                    quantity: quantity,
                    salesItemId: addOnId
                }
            );
        });

    }

    // Set the selected package IDs on the request
    return update(state, {
        addons: { $set: addons },
        buyersCredit: { $set: offer ? Math.max(0, budgetAmount - offer.cost) : 0 },
        coupon: { $set: coupon && coupon.couponCode && offer ? coupon : null },
        currentContract: { $set: passedInContract },
        marketingSourceId: { $set: marketingSourceId ? marketingSourceId : null},
        isKeepingCurrentCoverage: { $set: offer ? offer.isCurrentCoverage : false },
        planId: { $set: offer ? offer.planId : null },
        prepaidSCFFees: { $set: null },
        removeACCoverage: { $set: false },
        selectedOfferId: { $set: offer ? offer.contractOfferId : null },
        selectedServiceCallPrice: { $set: serviceCallPrice },
    });
}

const categoryReducer = createCategoryReducer(Areas.Order.Page);

/**
 * A reducer, which takes results of actions and updates the application state accordingly.
 */
export function OrderReducer(
    state = defaultState,
    action:
        any |
        HydrateAction
) {
    // Handles changes to selected/completed categories
    state = update(state, {
        categories: { $set: categoryReducer(state.categories, action )}
    });

    switch (action.type) {
        case LOCATION_CHANGE: {
            const { payload } = action as LocationChangeAction;

            if (!payload || !/\/order\//.test(payload.location.pathname)){
                const newState = defaultState;
                // Clear our state if they go away from the order page
                return update(newState, {
                    owner: { $set: state.owner },
                    property: { $set: state.property }
                });
                //return defaultState;
            }
            break;
        }
        case StoreActionTypes.HYDRATE: {
            const hydrateAction = action as HydrateAction;
            if (hydrateAction.payload.key === StorageKeys.order) {
                // Return hydrated state
                return hydrateAction.payload.state;
            }
            break;
        }

        case OrderActionTypes.ADD_CONTACT: {
            return update(state, {
                // Add an empty contact to the property.contacts list,
                contacts: { $push: [{}] },
            });
        }

        case OrderActionTypes.ADD_ADDON: {
            const addon = action.payload as Landmark.ContractAddon;
            if (addon) {
                // Make sure we have an item to update/remove
                const index = state.addons.findIndex(a => a.salesItemId === addon.salesItemId);
                if (index >= 0) {
                    if (addon.quantity <= 0) {
                        // Remove the item
                        return update(state, { addons: { $splice: [[index, 1]] } });
                    }
                    else {
                        // Set the quantity & type in state
                        return update(state, {
                            addons: {
                                [index]: {
                                    quantity: { $set: Math.min(addon.quantity, 99) }
                                }
                            }
                        });
                    }
                }
                else {
                    // This item wasn't already in the list, add it!
                    return update(state, {
                        addons: { $push: [ addon ] }
                    });
                }
            }
        }

        case OrderActionTypes.ADD_PLAN: {
            // Push the plan id to the end of the list
            return update(state, {
                planIds: { $set: action.planId }
            });
        }

        case OrderActionTypes.ADD_BUYERS_CREDIT: {
            return update(state, {
                buyersCredit: { $set: action.amount }
            });
        }

        case OrderActionTypes.ADD_SERVICE_CALL_FEE: {
            return update(state, {
                prepaidSCFFees: { $set: action.quantity }
            });
        }

        case OrderActionTypes.SET_COVERAGE_LENGTH: {
            return update(state, {
                coverageLength: { $set: action.quantity }
            });
        }

        case OrderActionTypes.ConvertContract.SUCCESS: {
            const orderRequest = action.payload.data as Landmark.OrderRequest;
            return update(state, {
                contractId: { $set: orderRequest.contractId },
                estimatedCloseDate: { $set: orderRequest.estimatedCloseDate },
                isConvertingFromListingCoverage: { $set: action.payload.isConvertingFromListingCoverage },
                isListingCoverage: { $set: orderRequest.isListingCoverage },
                orderingPartyType: { $set: orderRequest.orderingPartyType },
                planTypeId: { $set: PlanType.RealEstate },
                marketingSourceId: { $set: orderRequest.marketingSourceId },
                property: { $set: orderRequest.property },
                selectedPartners: {
                    buyerAgent: { $set: orderRequest.buyerAgent && orderRequest.buyerAgent.agentId ? orderRequest.buyerAgent : "" },
                    sellerAgent: { $set: orderRequest.sellerAgent && orderRequest.sellerAgent.agentId ? orderRequest.sellerAgent : "" },
                    titleCompany: { $set: orderRequest.titleCompany && orderRequest.titleCompany.agentId ? orderRequest.titleCompany : "" },
                },
                authNetProfileId: { $set: orderRequest.payment.authNetCustomerProfileId},
                authNetPaymentProfileId: {$set: orderRequest.payment.authNetPayProfileId}

            });
        }

        case OrderActionTypes.CreatePartner.SUCCESS: {
            return update(state, {
                selectedPartners: {
                    [action.payload.partnerType]: { $set: action.payload.data },
                },
                searchResults: {
                    $set: []
                }
            });
        }

        case OrderActionTypes.GetPriceBreakdown.SUCCESS: {
            const response = action.payload as Landmark.ContractItemResponse;
            // Only replace the price breakdown if it was the most recent
            if (!state.priceBreakdown || response.timestamp > state.priceBreakdown.timestamp) {
                return update(state, {
                    priceBreakdown: { $set: response }
                });
            }
        }

        case OrderActionTypes.GetPriceBreakdown.FAILURE: {
            return update(state, {
                priceBreakdown: { $set: {}}
            });
        }

        case OrderActionTypes.NEW_ORDER: {
            const newOrderAction = action as PayloadAction<string>;

            // Reset state to default
            return update(defaultState, {
                payment: {
                    // Update whether we're paying now based on the plan type
                    isPayingNow: { $set: newOrderAction.payload === PlanTypeName.Homeowner },
                    payingParty: { $set: newOrderAction.payload === PlanTypeName.Homeowner ? "homeBuyer" : "unknown" },
                },
                property: {
                    address: {
                        state: { $set: state.property.address.state },
                    }
                },
            });
        }

        case OrderActionTypes.QUICK_ORDER: {
            return update(state, {
                budgetAmount: { $set: action.budgetAmount },
                property: {
                    dwellingTypeId: { $set: action.dwellingTypeId },
                    squareFootage: { $set: action.squareFootage },
                },
            });
        }

        case OrderActionTypes.REMOVE_CONTACT: {
            const index = action.index;
            const contactCount = state.contacts.length;
            if ((index >= 0) &&
                (contactCount > 0) &&
                (index < contactCount)) {
                // Remove the contact at the given index from both the contact list
                return update(state, {
                    contacts: { $splice: [[index, 1]] }
                });
            }
            break;
        }

        case OrderActionTypes.REMOVE_QUOTE_ADDONS: {
            return update(state, { addons: { $set: [] }});
        }

        case OrderActionTypes.REMOVE_QUOTE_ITEM: {
            const item = action.item;
            switch (item.type as Landmark.ContractItemType) {
                case "plan":
                    state = reduceSelectOffer(state, null, 0);
                    if (state.planId === null) {
                        // If no packages are selected, then remove all addons
                        return update(state, {
                            addons: { $set: [] }
                        });
                    }
                    return state;
                case "addon":
                    return update(state, { addons: { $set: [] }});
                case "coupon":
                    return update(state, { coupon: { $set: {} }});
                case "credit":
                    return update(state, { buyersCredit: { $set: 0 }});
                        // If you remove buyers credit and then select a new package it uses your budget
                        // amount to create a new buyers credit for you based on the remaining amount.
                        // Do we want this? It is annoying, but could drive more revenue?
                        //.set("budgetAmount", 0)
                case "prepaidService":
                    return update(state, { prepaidSCFFees: { $set: null }});
                case "unknown":
                case "discount":
                case "surcharge":
                case "payment":
                    return state;
            }
            return state;
        }

        case OrderActionTypes.REMOVE_AC: {
            return update(state, { removeACCoverage: { $set: action.remove }});
        }

        case OrderActionTypes.SELECT_OFFER: {
            // The selected offer changed
            const offer = action.payload as Landmark.ContractOffer;
            return reduceSelectOffer(state, offer ? offer : null, 0);
        }

        case OrderActionTypes.SELECT_SERVICE_CALL_PRICE: {
            // The service call price was selected
            const serviceCallPrice = action.payload as Landmark.ServiceCallPrice;
            return update(state, {
                selectedServiceCallPrice: { $set: serviceCallPrice }
            });
        }

        case OrderActionTypes.SET_CONTACT_VALUE: {
            return update(state, {
                contacts: {
                    [action.index]: { $merge: makeMergeable(state.contacts[action.index], action.values) }
                }
            });
        }

        case OrderActionTypes.SetQuickOrderPlanId.SUCCESS: {
            const loadAction = action as PayloadAction<Landmark.QuickOrderResponse>;

            return update(state, {
                buyersCredit: { $set: loadAction.payload.buyersCredit },
                planId: { $set: loadAction.payload.planId },
                selectedOfferId: { $set: loadAction.payload.planId },
            });
        }

        case OrderActionTypes.PARTNER_SELECT: {
            return update(state, {
                selectedPartners: {
                    [action.partnerType]: { $set: action.partner },
                },
                searchResults: {
                    $set: []
                }
            });
        }

        case OrderActionTypes.SET_STATE: {
            return reduceOfferPrerequisites(state, action.value, state.property.dwellingTypeId);
        }

        case OrderActionTypes.SET_PAYMENT: {
            return update(state, {
                payment: { $set: action.payment}
            });
        }

        case OrderActionTypes.SET_PAYMENT_EMAIL: {
            return update(state, {
                payment: {
                    confirmationEmailAddresses: {
                        [action.index]: { $set: action.email }
                    }
                }
            });
        }

        case OrderActionTypes.SET_PAYMENT_TYPE: {
            const paymentType = action.payload as Landmark.PaymentType;
            return update(state, {
                payment: {
                    type: { $set: paymentType }
                }
            });
        }

        case OrderActionTypes.SET_USER_AS_PARTNER: {
            return update(state, {
                selectedPartners: {
                    [action.payload.partnerType]: { $set: action.payload.partnerInfo },
                }
            });
        }

        case OrderActionTypes.Submit.SUCCESS: {
            return update(state, {
                newlyOrderedContractId: { $set: (action as PayloadAction<Landmark.OrderResponse>).payload.contractId } ,
                isListingCoverage: { $set: (action as PayloadAction<Landmark.OrderResponse>).payload.isListingCoverage },
                isWebPolicy: { $set: (action as PayloadAction<Landmark.OrderResponse>).payload.isWebPolicy}
            });
        }

        case OrderActionTypes.CLEAR: {
            return defaultState;
        }

        case OrderActionTypes.CLEAR_PARTNER_SEARCH_RESULTS: {
            return update(state, {
                searchResults: {
                    $set: []
                }
            });
        }

        case OrderActionTypes.CLEAR_SELECTED_ROLES: {
            return update(state, {
                selectedPartners: { $set: {} }
            });
        }

        case OrderActionTypes.ADD_PAYMENT_EMAIL: {
            // TODO: we shouldn't be adding empty items to the list -- this can be handled in the view
            return update(state, {
                payment: {
                    confirmationEmailAddresses: { $push: [""] },
                },
            });
        }

        case OrderActionTypes.REMOVE_PLAN: {
            // When we remove a plan, remove addons and "deselect"
            // the offer.  We need to remove addons because the addon IDs
            // are specific to the offer that was chosen.
            return update(state, {
                addons: { $set: [] },
                planId: { $set: action.payload },
                selectedOfferId: { $set: null },
            });
        }

        case OrderActionTypes.REMOVE_PAYMENT_EMAIL: {
            return update(state, {
                payment: {
                    confirmationEmailAddresses: { $splice: [[action.index, 1]] }
                }
            });
        }

        case OrderActionTypes.SET_DWELLING_TYPE_ID: {
            return reduceOfferPrerequisites(state, state.property.address.state, action.payload);
        }

        case OrderActionTypes.SET_PURCHASED_RECENTLY: {
            if (!state.isRecentPurchase && action.isRecent) {
                // If purchased recently, we allow consumers to use real estate pricing.
                return update(state, {
                    isRecentPurchase: { $set: true },
                    orderingPartyType: { $set: "homeBuyer" },
                    planTypeId: { $set: PlanType.RealEstate },
                    payment: {
                        payingParty: { $set: "homeBuyer" },
                        type: { $set: "full" },
                    }
                });
            }
            else {
                return update(state, {
                    isRecentPurchase: { $set: action.isRecent },
                    planTypeId: { $set: PlanType.Homeowner },
                    payment: {
                        payingParty: { $set: "homeBuyer" }
                    }
                });
            }
        }

        case OrderActionTypes.SET_PLAN_TYPE: {
            let type: Landmark.PaymentType = Landmark.PaymentType.Monthly;
            if (action.payload === PlanType.RealEstate) {
                type = Landmark.PaymentType.Escrow;
            }

            return update(state, {
                payment: { type: { $set: type }},
                planTypeId: { $set: action.payload },
            });
        }

        case OrderActionTypes.SearchPartner.SUCCESS: {
            const response = action.payload as Landmark.ApiResponse<Landmark.PartnerSearchResponse[]>;
            return update(state, {
                searchResults: { $set: response.result },
            });
        }

        case OrderActionTypes.SET_COUPON_CODE: {
            if (!state.coupon || state.coupon.couponCode !== action.payload) {
                // Only clear the coupon information if the coupon code has changed
                return update(state, {
                    coupon: {
                        $set: {
                            amount: 0,
                            couponCode: action.payload,
                            contractCouponId: null,
                        }
                    }
                });
            }
            break;
        }

        case OrderActionTypes.SET_MARKETING_SOURCE_ID: {

            return update(state, {
                marketingSourceId: { $set: action.payload },
            });

            
        }

        case OrderActionTypes.SET_ADDRESS: {
            const address = action.payload as Landmark.Address;

            if (address) {
                return update(state, {
                    $merge: {
                        property: {
                            address: address,
                        },
                    },
                });
            }
            return state;
        }

        case OrderActionTypes.SET_AUTO_RENEWAL: {
            return update(defaultState, {
                isAutoRenew: { $set: true },
            });
        }

        case OrderActionTypes.START_RENEWAL: {
            const contract = action.contract as Landmark.Contract;
            const user = action.user as Landmark.User;
            const isAutoRenew = state.isAutoRenew;

            if (contract && user) {
                return update(defaultState, {
                    $merge: {
                        owner: {
                            user
                        },
                        planTypeId: PlanType.Homeowner,
                        contacts: contract.additionalOwners,
                        contractId: contract.contractId,
                        expireDate: contract.expireDate,
                        property: {
                            address: contract.address,
                            dwellingTypeId: contract.propertyType.dwellingTypeId,
                            owner: {
                                user
                            },
                            squareFootage: contract.areaSqFt,
                        },
                    },
                    // Need to know if they have selected auto renewal.
                    isAutoRenew: { $set: isAutoRenew },
                    // since this is a renewal, block the "recently purchased" pop up
                    isRecentPurchase: { $set: true },
                    isRenewing: { $set: true },
                });
            }
        }

        case OrderActionTypes.START_UPGRADE: {
            const user = action.user as Landmark.User;
            const contract = action.contract as Landmark.Contract;

            if (contract && user) {
                return update(defaultState, {
                    $merge: {
                        address: contract.address,
                        expireDate: contract.expireDate,
                        owner: {
                            user
                        },
                        payment: {
                            isPayingNow: true,
                            payingParty: "homeBuyer",
                        },
                        property: {
                            address: contract.address,
                            dwellingTypeId: contract.propertyType.dwellingTypeId,
                            owner: {
                                user
                            },
                            squareFootage: contract.areaSqFt,
                        },
                    },
                    contractPaymentType: { $set: contract.paymentMethod },
                });
            }
        }

        case OrderActionTypes.ValidateCouponCode.SUCCESS: {
            const response = action.payload as Landmark.ApiResponse<Landmark.ContractCoupon>;
            if (response != null) {
                return update(state, {
                    coupon: { $set: response.result }
                });
            }
            else {
                return state;
            }
        }

        // If validation fails, clear the coupon code to prevent it being attempted again.
        case OrderActionTypes.ValidateCouponCode.FAILURE: {
            return update(state, {
                coupon: { $set: null }
            });
        }

        case OffersActionTypes.Load.SUCCESS: {
            const response = action.payload as Landmark.GetContractOffersResponse;
            const contractId = state.contractId;
            const quickOrderPlanId = state.selectedOfferId;

            // We need to know if they have any additional fees and subtract them.
            let feesSum = 0;
            if (state.priceBreakdown && state.priceBreakdown.items) {
                const sumOfItems = (sum, item) => sum + (item.cost * item.quantity);
                feesSum = state.priceBreakdown
                    .items
                    .filter(item => item.type === "surcharge")
                    .reduce(sumOfItems, 0);
            }

            const budgetAmount = contractId ? 10000000000 : (state.budgetAmount - feesSum);

            const offersWithinBudget = response.offers.filter(offer =>
                offer.cost <= budgetAmount
            );

            // Sort the offers by cost, in descending order
            offersWithinBudget.sort((offer1, offer2) => {
                if (offer1.cost < offer2.cost) {
                    return 1;
                }
                if (offer2.cost < offer1.cost) {
                    return -1;
                }
                return 0;
            });

            const renewalOffer = offersWithinBudget.find(off => off.isCurrentCoverage);

            let offer = renewalOffer;

            if (!offer) {
                offer = offersWithinBudget.length > 0 ? offersWithinBudget[0] : null;
            }
            if (offer) {
                if (quickOrderPlanId) {
                    offer.planId = quickOrderPlanId;
                    offer.contractOfferId = quickOrderPlanId;
                }
                // We found an offer for the renewal or within budget, select it!
                return reduceSelectOffer(state, offer, feesSum, null, response.current);
            }
            break;
        }


        case OrderActionTypes.SET_VALUES: {
            return update(state, { $merge: makeMergeable(state, action.payload) });
            // TODO: this shouldn't happen here!
            // We need to account for it somewhere else (an epic perhaps?)
            // if (values.isListingCoverage !== undefined) {
            //     if (!!values.isListingCoverage) {
            //         return state.withMutations(state => state
            //             .setIn(["payment", "payingParty"], "homeSeller")
            //             .setIn(["payment", "isPayingNow"], false)
            //         );
            //     }
            //     else {
            //         return state.withMutations(state => state
            //             .setIn(["payment", "payingParty"], "unknown")
            //             .setIn(["payment", "isPayingNow"], false)
            //         );
            //     }
            // }
        }

        case OrderActionTypes.UPGRADE: {
            return update(state, {
                isUpgrading: { $set: true },
                upgradeContractId: { $set: (action as PayloadAction<string>).payload },
                contractId: { $set: (action as PayloadAction<string>).payload },
            });
        }

        case AuthActionTypes.LOGOUT: {
            // Clear our state on logout
            return defaultState;
        }

        case OrderActionTypes.SET_IS_ADDRESS_VERIFIED: {
            return update(state, {
                isAddressVerified: { $set: action.value}
            });
        }
    }

    return state;
}

// A list of keys that are not stored (for security compliance)
const filterKeys = ["search", "creditCardNumber", "expiration", "securityCode"];

export const observer = createStoreObserver(
    state => state.order,
    StorageKeys.order,
    window.sessionStorage,
    (obj, key) => filterKeys.indexOf(key) === -1
);
