import update from "immutability-helper";
import * as Landmark from "models/landmark-api";
import { Action } from "redux";

import { PayloadAction } from "../actions/defs";
import { ExecutiveActionTypes } from "../actions/executive.actions";

export interface ExecutiveState {
    list: Landmark.Executive[];
    selectedId: any;
}

const defaultState: ExecutiveState = {
    list: [],
    selectedId: null,
};

export const ExecutiveReducer = (
    state = defaultState,
    action:
        Action |
        PayloadAction<any> |
        PayloadAction<Landmark.Executive[]>
) => {
    switch (action.type) {
        case ExecutiveActionTypes.SELECT: {
            const selectAction = action as PayloadAction<any>;

            return update(state, {
                selectedId: { $set: selectAction.payload },
            });
        }
        case ExecutiveActionTypes.Load.SUCCESS: {
            const loadAction = action as PayloadAction<any>;

            return update(state, {
                list: { $set: loadAction.payload },
                selectedId: { $set: null },
            });
        }
    }
    return state;
};
