import * as React from "react";

interface Props {
    style?: React.CSSProperties;
    className?: string;
}

export class FontIcon extends React.Component<Props, any> {
    render() {
        const style = Object.assign({}, this.props.style, { fontFamily: "landmark" });
        return (
            <span style={style} className={this.props.className}>{this.props.children}</span>
        );
    }
}
