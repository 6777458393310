import { AuthActionCreators, createAuthActions } from "actions/auth.actions";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { bindActionCreatorsToProps, connect } from "store/componentBindings";
import { getFingerprintAsync } from "utils/fingerprint";


interface Props extends Partial<RouteComponentProps<any>>{
    webUserId: string;
    wmUserId: string;
    actions?: {
        auth: AuthActionCreators;

    };
}

@connect(
    state => ({
    }),
    bindActionCreatorsToProps({
        auth: createAuthActions(),
    })
)

export class DirectLogin extends React.Component<Props> {

    componentDidMount = async() => {
        // Wait until we know that all the initialization has happened before showing dialog.
            let webuserId = this.props.match.params.webUserId;
            let wmUserId = this.props.match.params.wmUserId;
            console.log(webuserId);
            if(webuserId && wmUserId)
            {
                const fingerprint = await getFingerprintAsync();
                this.props.actions.auth.withUser(
                    webuserId,
                    wmUserId,
                    fingerprint
                );
            }
    }
    render() {
        return (
            <h1>{this.props.webUserId}</h1>
        );
    }

}
