import { InjectedFormikProps, withFormik } from "formik";
import * as React from "react";
import { isEmail, isEmpty } from "validator";

import { Modal } from "./Modal";
import { TextBox } from "./TextBox";
import { ValidationModal } from "./ValidationModal";
import { createPasswordActions, PasswordActionCreators, PasswordActionTypes } from "../actions/account/password.actions";
import { createDialogActions, DialogActionCreators } from "../actions/dialog.actions";
import { Areas } from "../constants/Areas";
import { createGetIsDialogOpen } from "../selectors/dialog.selectors";
import { createGetIsWaitingForArea } from "../selectors/wait.selectors";
import { bindActionCreatorsToProps, connect } from "../store/componentBindings";
import { actionPromise } from "../utils/redux-promise";
import * as Styles from "../styles";

import "./ForgotPassword.scss";

interface Props {
    actions?: {
        dialog?: DialogActionCreators;
        password: PasswordActionCreators;
    };
    isDialogOpen?: boolean;
    isLoading?: boolean;
}

interface FormProps {
    actions: PasswordActionCreators;
}

interface FormValues {
    email: string;
}

interface FormState {
    hasAttemptedSubmit: boolean;
}

class Form extends React.Component<InjectedFormikProps<FormProps, FormValues>, FormState> {
    constructor(props) {
        super(props);

        this.state = {
            hasAttemptedSubmit: false,
        };
    }

    componentWillReceiveProps(newProps: InjectedFormikProps<FormProps, FormValues>) {
        if (newProps.isSubmitting && !this.props.isSubmitting) {
            return this.setState({ hasAttemptedSubmit: false });
        }
    }

    render() {
        const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
        } = this.props;

        return (
            <div style={{ maxWidth: 500, height: 260 }}>
                <h3>Trouble Logging In?</h3>
                <p>Let us help! Please enter your email.</p>
                <form style={{ margin: "0 1em" }} onSubmit={handleSubmit}>
                    <fieldset>
                        <TextBox
                            autoFocus={true}
                            disabled={isSubmitting}
                            error={touched.email && errors.email}
                            name="email"
                            onEnterPressed={handleSubmit}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Email..."
                            value={values.email} />
                    </fieldset>
                    <fieldset style={{ margin: 20 }}>
                        <button
                            disabled={isSubmitting}
                            onClick={e => this.setState({ hasAttemptedSubmit: true })}
                            type="submit"
                            className="btn">Submit<i data-icon="j"></i></button>
                    </fieldset>
                </form>
                <ValidationModal
                    errors={errors}
                    isOpen={!isValid && this.state.hasAttemptedSubmit}
                    onRequestClose={() => this.setState({ hasAttemptedSubmit: false })} />
            </div>
        );
    }
}

const ForgotPasswordForm = withFormik<FormProps, FormValues>({
    // Add a custom validation function
    validate: (values: FormValues, props) => {
        let errors: any = {};

        if (!values.email || isEmpty(values.email)) {
            errors.email = "Please enter your email address.";
        }
        else if (!isEmail(values.email)) {
            errors.email = "The email address is incorrect.  Please verify your email and try again.";
        }

        return errors;
    },
    // Submission handler
    handleSubmit: async (
        values: FormValues,
        {
            props,
            setSubmitting,
        }
    ) => {
        try {
            await actionPromise(
                () => props.actions.forgot.begin(values.email),
                PasswordActionTypes.Forgot.SUCCESS,
                PasswordActionTypes.Forgot.FAILURE,
            );
        }
        finally {
            setSubmitting(false);
        }
    },
})(Form);

@connect(
    state => ({
        isDialogOpen: createGetIsDialogOpen(Areas.Account.Password.Forgot)(state),
        isLoading: createGetIsWaitingForArea(Areas.Account.Password.Forgot)(state),
    }),
    bindActionCreatorsToProps({
        dialog: createDialogActions(Areas.Account.Password.Forgot),
        password: createPasswordActions(),
    })
)
export class ForgotPassword extends React.Component<Props, any> {
    render() {
        return (
            <div style={{ display: "inline-block" }}>
                <a className="submitLink" onClick={this.props.actions.dialog.open}>{this.props.children}</a>
                <Modal
                    isOpen={this.props.isDialogOpen}
                    onRequestClose={this.props.actions.dialog.close}
                    style={Styles.Modal.AutoSized}>
                    <ForgotPasswordForm
                        actions={this.props.actions.password}
                    />
                </Modal>
            </div>
        );
    }
}
