import { combineEpics } from "redux-observable";

import accountEpic from "./account";
import authEpic from "./auth.epic";
import awardsEpic from "./awards.epic";
import chatEpic from "./chat.epic";
import contactUsEpic from "./contactUs.epic";
import contractorEpic from "./contractor";
import crrEpic from "./crr.epic";
import executiveEpic from "./executive.epic";
import featureEpic from "./feature.epic";
import forumEpic from "./forum.epic";
import googleAnalyticsEpic from "./googleAnalytics.epic";
import leadEpic from "./lead";
import limitsAndExclusionsEpic from "./limitsAndExclusions.epic";
import locationEpic from "./location.epic";
import geocodeDataEpic from "./geocode.epic";
import offersEpic from "./offers.epic";
import orderEpic from "./order.epic";
import paymentEpic from "./payment.epic";
import profileEpic from "./profile.epic";
import referenceEpic from "./reference.epic";
import reviewEpic from "./review.epic";
import salesRepEpic from "./salesRep.epic";
import serviceRequestEpic from "./serviceRequest.epic";
import storeEpic from "./store.epic";
import waitEpic from "./wait.epic";

const rootEpic = combineEpics(
    accountEpic,
    authEpic,
    awardsEpic,
    chatEpic,
    contactUsEpic,
    contractorEpic,
    crrEpic,
    executiveEpic,
    featureEpic,
    forumEpic,
    googleAnalyticsEpic,
    leadEpic,
    limitsAndExclusionsEpic,
    locationEpic,
    geocodeDataEpic,
    offersEpic,
    orderEpic,
    paymentEpic,
    profileEpic,
    referenceEpic,
    reviewEpic,
    salesRepEpic,
    serviceRequestEpic,
    storeEpic,
    waitEpic,
);
export default rootEpic;
