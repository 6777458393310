import * as React from "react";
import { Link } from "react-scroll";

const emailAddress = "contractors@landmarkhw.com";
const subject = "Application for Contracting with Landmark";
const body =
`Contractor Application
Fill out the following information

Company Name:

Contact Name:

Contact Number:

Trade(s) Serviced:

State:

Cities Serviced:

Hourly Rate:

License Numbers:

Describe your rate structure:

How many technicians do you employ?

What areas do you cover?Do you have an office staff?

How long have you been in business?

What brands of equipment do you service?

Do you offer emergency, weekend, and holiday service?

After hours over time rates:

Do you currently carry a $1,000,000 liability insurance policy?

Do you currently use a field service/dispatching software for your business?


`;

export const contractorApplicationEmailInfo = {
    emailAddress,
    subject,
    body
};

export class BecomeAContractor extends React.Component {
    render() {
        return (
            <section className="section-intro contractors">
                <div className="container">
                    <div className="row intro-content">
                        <i className="section-icon-inverted icon-contractor"></i>
                        <h1 style={{ fontSize: "54px" }}>Become a Home Warranty Contractor!</h1>
                        <p style={{ maxWidth: "800px", margin: "0 auto 30px auto" }}>Could you use some additional business? Are you interested in increasing your potential for revenue growth? If that sounds like what you’re looking for, sign up
                            to become a Landmark Home Warranty contractor today! We will connect you with home warranty customers who are in need of your expert skills and services. With an ever growing network of contractors, we offer numerous
                            benefits, such as providing major parts and paying you for labor.
                            Sign up today!</p>
                        <div className="btn-group">
                            <a className="btn-white btn-contractor" href={`mailto:${emailAddress}?subject=${subject}&body=${encodeURIComponent(body)}`}>Begin Application</a>
                            <Link to="benefits" className="btn btn-stroke-white" smooth={true} offset={-40} duration={500}>See Benefits</Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
